import { Issue } from 'app/core/rest-api';
import { SyncCommand } from 'app/main/sync/models/sync-command';

export class IssueCommand extends SyncCommand<Issue> {
    constructor(issue: Issue, issueChanges: Partial<Issue>) {
        const { id, projectId, updateDateTime } = issue;
        super();
        this.action = 'create';
        this.projectId = projectId;
        this.entityType = 'issue';
        this.waitForCreation = false;
        this.locked = false;
        this.entityId = 'NEW_' + new Date().getTime().toString();
        let base: any = { ...issue };
        if (id) {
            this.action = 'update';
            this.entityId = id;
            base = { updateDateTime };
        }
        this.changes = {
            ...base,
            ...issueChanges
        };
    }
}
