import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Issue, MarkedPlan } from 'app/core/rest-api';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import { Conflict } from 'app/main/models/conflict';
import { IssueCommand } from '../../main/issues/models/issue-command';
import { IssueFilters } from '../../main/issues/models/issue.filters';
import { IssuePost } from '../../main/issues/models/issue.post';
import { IssueSorts } from '../../main/issues/models/issue.sorts.enum';
import { IssuesActions, IssuesActionTypes } from './issues.actions';
import { IssuesStoreUtilsService } from './issues-store-utils.service';
import { SyncActionTypes, SyncActions } from '../sync/sync.actions';
import { CommandStoreUtils } from './../commands/commands-store-utils';
import { ConflictUtils } from './../../db/conflict/conflict-utils';
import { EntityAttachmentMap } from './models/entity-attachment-map';
import { AcceptUtils } from 'app/core/utils/accept-utils';

export const adapter: EntityAdapter<Issue> = createEntityAdapter<Issue>();

export interface IssuesState extends EntityState<Issue> {
    filters: IssueFilters;
    lastCreatedIssue: Partial<IssuePost>;
    loading: boolean;
    selected: string[];
    sortBy: IssueSorts;
    orderAsc: boolean;
    issueCommands: IssueCommand[];
    planCommands: PlanCommand[];
    conflicts: Conflict<Issue>[];
    // here we will hold plan changes for (new) non-existing issues
    // as we need an issue.id to persist these changes
    // after we have an issueId, the changes will be persistend in the backend

    initialized: {
        issueCommands: boolean;
        planCommands: boolean;
        conflicts: boolean;
    };
    loadingConflicts: boolean;

    attachmentMap: EntityAttachmentMap;
}

export const initialState: IssuesState = adapter.getInitialState({
    filters: new IssueFilters(),
    lastCreatedIssue: {},
    loading: false,
    selected: [],
    sortBy: IssueSorts.IMPORTANCE,
    orderAsc: true,
    issueCommands: [],
    planCommands: [],
    conflicts: [],
    initialized: {
        issueCommands: false,
        planCommands: false,
        conflicts: false,
    },
    loadingConflicts: false,
    attachmentMap: new Map(),
});

export function reducer(
    state = initialState,
    action: IssuesActions | SyncActions
): IssuesState {
    switch (action.type) {
        case IssuesActionTypes.CREATE_ISSUE_COMMAND: {
            let { lastCreatedIssue } = state;
            const applyConflict = action.payload.applyConflict;
            let command = action.payload.command;

            if (!applyConflict) {
                // user added changes to entity which was in conflict
                // => lock this commands directly
                command = ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    [command]
                )[0];
            }

            const { changes, entityId } = command;

            // const { command } = action.payload;
            if (command.action === 'create') {
                const {
                    craft,
                    position,
                    responsibleExternalId,
                    responsibleInternalId,
                } = changes;
                lastCreatedIssue = {
                    craft,
                    position,
                    responsibleExternalId,
                    responsibleInternalId,
                };
            }
            let currentCommands = [...state.issueCommands];
            if (applyConflict) {
                // filter commands with this entityId
                currentCommands = currentCommands.filter(
                    (c) => c.entityId !== command.entityId
                );
            }
            return {
                ...state,
                issueCommands: [...currentCommands, command],
                planCommands: state.planCommands.map((planCommand) => {
                    // planCommand that does not have an issue Id
                    // is the one created, while a new issue creation
                    // was in progress
                    if (!planCommand.issueId) {
                        return {
                            ...planCommand,
                            issueId: entityId,
                        };
                    }
                    return planCommand;
                }),
                lastCreatedIssue,
            };
        }

        case IssuesActionTypes.ADD_ISSUE_ATTACHMENT_MAPPINGS: {
            const combinedMaps = AcceptUtils.combineMaps(
                state.attachmentMap,
                action.payload.attachmentMap
            );
            return {
                ...state,
                attachmentMap: combinedMaps,
            };
        }

        case IssuesActionTypes.UPDATE_ISSUE_ATTACHMENT_IDS: {
            return {
                ...state,
                issueCommands: CommandStoreUtils.updateCommandsAttachmentIds(
                    state.issueCommands,
                    state.attachmentMap,
                    IssuesStoreUtilsService.replaceIssueAttachmentIdsInCommandChanges
                ),
            };
        }

        case IssuesActionTypes.CLEAR_ISSUE_ATTACHMENT_MAP: {
            return {
                ...state,
                attachmentMap: new Map(),
            };
        }

        case IssuesActionTypes.CREATE_PLAN_COMMAND:
            return {
                ...state,
                planCommands: [...state.planCommands, action.payload.command],
            };

        case IssuesActionTypes.LOAD_ISSUES_REQUEST:
            // TODO: this could be an issue with multiple concurrent requests...
            return {
                ...state,
                loading: true,
                lastCreatedIssue: {},
            };

        case IssuesActionTypes.LOAD_ISSUES_SUCCESS: {
            const {
                issues,
                currentProjectId,
                requestProjectId,
            } = action.payload;

            const issuesOfCurrentProject =
                currentProjectId === requestProjectId;

            // TODO: how to handle issues from multiple projects?

            if (issuesOfCurrentProject) {
                return adapter.setAll(issues, {
                    ...state,
                    loaded: true,
                    loading: false,
                });
            } else {
                return {
                    ...state,
                    loading: false,
                };
            }
        }

        case IssuesActionTypes.LOAD_ISSUES_ERROR:
            return { loading: false, ...state };

        case IssuesActionTypes.CREATE_ISSUES_SUCCESS: {
            const { issues, currentProjectId } = action.payload;
            const createdIssues = issues.map((i) => i.issue);
            const createSuccessIds = issues.map((i) => i.entityId);
            const createdIssuesToAdd = createdIssues.filter(
                (issue) => issue.projectId === currentProjectId
            );
            // CASE: We have a pending post request (= locked create command)
            // If in this time frame the user created a new update command for that issue
            // we are creating a command with "waitForCreation: true".
            // This is the place when the creation was successfull, so we will update all
            // update commands with that entityId
            const updatedWaitForCreation = state.issueCommands
                .filter((c) => c.waitForCreation && c.action === 'update')
                .map((command) => {
                    const issueCreate = issues.find(
                        (create) => create.entityId === command.entityId
                    );
                    if (issueCreate) {
                        const { issue } = issueCreate;
                        return {
                            ...command,
                            waitForCreation: false,
                            entityId: issue.id,
                            changes: {
                                ...command.changes,
                                updateDateTime: issue.updateDateTime,
                            },
                        };
                    }

                    // there are 'wait for creation' update commands which are not unlocked by the
                    // current successful creates
                    return command;
                });

            const commandsWithoutCreatedEntities = state.issueCommands.filter(
                (c) =>
                    !createSuccessIds.includes(c.entityId) ||
                    (createSuccessIds.includes(c.entityId) &&
                        c.entityType !== 'issue')
            );

            return adapter.addMany(createdIssuesToAdd, {
                ...state,
                issueCommands: [
                    ...commandsWithoutCreatedEntities,
                    ...updatedWaitForCreation,
                ],
                // change
                planCommands: state.planCommands.filter(
                    (c) => !createSuccessIds.includes(c.issueId)
                ),
            });
        }

        case IssuesActionTypes.CREATE_ISSUES_REQUEST: {
            const createIds = action.payload.issues.map((i) => i.entityId);
            const planIssueIds = action.payload.plansToUpdate.map(
                (p) => p.issueId
            );
            return {
                ...state,
                issueCommands: [...state.issueCommands].map((c) =>
                    createIds.includes(c.entityId) ? { ...c, locked: true } : c
                ),

                planCommands: state.planCommands.map((c) => {
                    const shouldLock =
                        createIds.includes(c.issueId) ||
                        planIssueIds.includes(c.issueId);

                    if (shouldLock) {
                        return { ...c, locked: true };
                    }

                    return c;
                }),
            };
        }

        case IssuesActionTypes.CREATE_ISSUES_ERROR: {
            const createErrorIds = action.payload.errors.map((e) => e.entityId);

            return {
                ...state,
                issueCommands: [...state.issueCommands].map((c) =>
                    createErrorIds.includes(c.entityId)
                        ? { ...c, locked: true }
                        : c
                ),
            };
        }

        case IssuesActionTypes.SET_ISSUE_LIST_SORT:
            return {
                ...state,
                sortBy: action.payload.sortBy,
                orderAsc: action.payload.orderAsc,
            };

        case IssuesActionTypes.UPDATE_ISSUES_REQUEST: {
            const ids = action.payload.updates.map((u) => u.id);
            const planIssueIds = action.payload.plansToUpdate.map(
                (p) => p.issueId
            );
            return {
                ...state,
                issueCommands: [...state.issueCommands].map((c) =>
                    ids.includes(c.entityId) && c.entityType === 'issue'
                        ? { ...c, locked: true }
                        : c
                ),
                planCommands: state.planCommands.map((c) => {
                    const shouldLock =
                        ids.includes(c.issueId) ||
                        planIssueIds.includes(c.issueId);

                    if (shouldLock) {
                        return { ...c, locked: true };
                    }

                    return c;
                }),
            };
        }

        case IssuesActionTypes.UPDATE_ISSUES_ERROR:
            const errorIds = action.payload.errors.map((e) => e.entityId);
            return {
                ...state,
                issueCommands: [...state.issueCommands].map((c) =>
                    errorIds.includes(c.entityId) ? { ...c, locked: true } : c
                ),
            };

        case IssuesActionTypes.CREATE_ISSUE_CONFLICTS: {
            return {
                ...state,
                conflicts: ConflictUtils.newestUniqueConflicts(
                    ...state.conflicts,
                    ...action.payload.conflicts
                ),
                // lock command on conflict
                issueCommands: ConflictUtils.lockConflictingCommands(
                    action.payload.conflicts,
                    state.issueCommands
                ),
            };
        }

        case IssuesActionTypes.LOAD_ISSUE_CONFLICTS: {
            return {
                ...state,
                loadingConflicts: true,
            };
        }

        case IssuesActionTypes.SET_ISSUE_CONFLICTS: {
            return {
                ...state,
                conflicts: ConflictUtils.newestUniqueConflicts(
                    ...action.payload.conflicts
                ),
                // re-lock commands for which a conflict is present
                // because locked state is not saved in DB but the
                // commands itself are (on devices)
                issueCommands: ConflictUtils.lockConflictingCommands(
                    action.payload.conflicts,
                    state.issueCommands
                ),
                initialized: {
                    ...state.initialized,
                    conflicts: true,
                },
                loadingConflicts: false,
            };
        }

        case IssuesActionTypes.LOAD_ISSUE_COMMANDS_SUCCESS: {
            if (action.payload.entityType === 'plan') {
                return {
                    ...state,
                    planCommands: ConflictUtils.lockConflictingCommands<
                        MarkedPlan,
                        PlanCommand
                    >(
                        state.conflicts,
                        action.payload.commands as PlanCommand[]
                    ),
                    initialized: {
                        ...state.initialized,
                        planCommands: true,
                    },
                };
            }
            return {
                ...state,
                issueCommands: ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    action.payload.commands
                ),
                initialized: {
                    ...state.initialized,
                    issueCommands: true,
                },
            };
        }

        case SyncActionTypes.StoreCommandSuccess: {
            const { command, databaseId } = action.payload;
            if (command.entityType === 'issue') {
                return {
                    ...state,
                    issueCommands: CommandStoreUtils.updateCommandDatabaseId(
                        state.issueCommands,
                        command,
                        databaseId
                    ),
                };
            } else if (command.entityType === 'plan') {
                return {
                    ...state,
                    planCommands: CommandStoreUtils.updateCommandDatabaseId<
                        MarkedPlan,
                        PlanCommand
                    >(state.planCommands, command as PlanCommand, databaseId),
                };
            } else {
                return {
                    ...state,
                };
            }
        }

        case IssuesActionTypes.REMOVE_PLAN_COMMANDS: {
            return {
                ...state,
                planCommands: state.planCommands.filter(
                    (planCommand) => planCommand.issueId
                ),
            };
        }

        case IssuesActionTypes.REMOVE_PLAN_COMMANDS: {
            return {
                ...state,
                planCommands: state.planCommands.filter(
                    (planCommand) => planCommand.issueId
                ),
            };
        }

        case IssuesActionTypes.UPDATE_ISSUES_SUCCESS: {
            const { updates, currentProjectId } = action.payload;
            const updateIds = updates.map((u) => u.id);
            const updatesToApply = updates.filter(
                (u) => u.changes.projectId === currentProjectId
            );

            const commandsWithoutUpdatedIssueIds = state.issueCommands.filter(
                (c) => !updateIds.includes(c.entityId)
            );

            // update commands that were created, when there was an
            // ongoing PATCH to the backend. These were on hold
            // by setting "waitForCreation" to true.
            // now we are removing the on hold, and setting
            // the updatedatetime of the update
            const updatedWaitForCreation = state.issueCommands
                .filter((c) => c.waitForCreation && c.action === 'update')
                .map((command) => {
                    const update = updates.find(
                        (u) => u.changes.id === command.entityId
                    );
                    if (update) {
                        return {
                            ...command,
                            waitForCreation: false,
                            entityId: update.changes.id,
                            changes: {
                                ...command.changes,
                                updateDateTime: update.changes.updateDateTime,
                            },
                        };
                    }
                });

            return adapter.updateMany(updatesToApply, {
                ...state,
                issueCommands: [
                    ...commandsWithoutUpdatedIssueIds,
                    ...updatedWaitForCreation,
                ],
                planCommands: state.planCommands.filter(
                    (c) => !updateIds.includes(c.issueId)
                ),
                conflicts: state.conflicts.filter(
                    (conflictItem) =>
                        !updateIds.includes(conflictItem.itemWantUpdate.id)
                ),
            });
        }

        case IssuesActionTypes.LOAD_REVISIONS_SUCCESS:
            const { issueId, revisions } = action.payload;
            return adapter.updateOne(
                { id: issueId, changes: { revisions } },
                {
                    ...state,
                }
            );

        case IssuesActionTypes.LOAD_PLAN_REVISIONS_SUCCESS:
            return adapter.updateOne(
                {
                    id: action.payload.issueId,
                    changes: { markedPlan: action.payload.markedPlan },
                },
                {
                    ...state,
                }
            );

        case IssuesActionTypes.SET_ISSUE_FILTERS:
            return {
                ...state,
                filters: action.payload.filters,
            };

        case IssuesActionTypes.TOGGLE_SELECT_ISSUE:
            if (state.selected.includes(action.payload.issueId)) {
                return {
                    ...state,
                    selected: state.selected.filter(
                        (id) => id !== action.payload.issueId
                    ),
                };
            }

            return {
                ...state,
                selected: [...state.selected, action.payload.issueId],
            };

        case IssuesActionTypes.SELECT_ISSUES:
            return {
                ...state,
                selected: action.payload.ids,
            };

        case SyncActionTypes.DiscardCommandsWithErrors: {
            const discardParameters = action.payload.discardParameters;

            const withoutFailedPlanUpdates = CommandStoreUtils.removeCommandsWithIds(
                state.planCommands,
                discardParameters
            );

            const withoutIssueErrors = CommandStoreUtils.removePlanCommandsWithIssueIds(
                withoutFailedPlanUpdates,
                discardParameters
            );

            return {
                ...state,
                issueCommands: CommandStoreUtils.removeCommandsWithIds(
                    state.issueCommands,
                    discardParameters
                ),
                planCommands: withoutIssueErrors,
            };
        }

        case SyncActionTypes.MarkCommandsInFlightForEntities: {
            return {
                ...state,
                issueCommands: CommandStoreUtils.markCommandsInFlightForEntities(
                    state.issueCommands,
                    action.payload.entityIds
                ),
                planCommands: CommandStoreUtils.markCommandsInFlightForEntities(
                    state.planCommands,
                    action.payload.entityIds
                ),
            };
        }

        case SyncActionTypes.ClearInFlightFlagOfCommands: {
            return {
                ...state,
                issueCommands: CommandStoreUtils.clearInFlightFlagOfCommands(
                    state.issueCommands
                ),
                planCommands: CommandStoreUtils.clearInFlightFlagOfCommands(
                    state.planCommands
                ),
            };
        }

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
