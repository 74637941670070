import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Issue } from 'app/core/rest-api';
import {
    AcceptUtils,
    missingOrEmpty,
    nullOrUndefined,
} from 'app/core/utils/accept-utils';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';

import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { IssueDocType } from './issue.document';
import { KeysWithArrayValue } from '../utils/type-utils';

@Injectable({
    providedIn: 'root',
})
export class IssueDbService extends EntityDbService<Issue, IssueDocType> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Issues
        );
    }

    getAttachmentIdsFromApiItem(issue: Partial<Issue>): string[] {
        let ids = [];
        const { photo, photos, files, markedPlan } = issue;
        if (photo) {
            if (!AcceptUtils.isDefaultGuid(photo)) {
                ids = [...ids, photo];
            }
        }

        if (photos) {
            ids = [...ids, ...photos];
        }
        if (files) {
            ids = [...ids, ...files];
        }

        if (markedPlan) {
            const { thumbnail } = markedPlan;
            if (thumbnail) {
                ids = [...ids, thumbnail];
            }
        }

        // only unique entries
        ids = [...new Set(ids)];

        return ids;
    }

    apiItemToSchema(issue: Issue): IssueDocType {
        const {
            id,
            boilerPlattes,
            craft,
            creationDate,
            creatorId,
            difficulty,
            dueDate,
            files,
            markedAsDelete,
            photo,
            photos,
            markedPlan,
            position,
            projectId,
            responsibleExternalId,
            responsibleInternalId,
            secondDueDate,
            state,
            title,
            updateDateTime,
            description,
            // store the custom attributes completly in offline DB
            customAttributes,
            // the same for checklists
            checklists,
            isUsingFreetextAsBoilerplate,
            freetext,
        } = issue;
        return {
            id,
            boilerPlattes,
            craft,
            creationDate,
            creatorId,
            difficulty,
            dueDate,
            files,
            markedAsDelete,
            photo,
            photos,
            markedPlan,
            position,
            projectId,
            responsibleExternalId,
            responsibleInternalId,
            secondDueDate,
            state,
            title,
            updateDateTime,
            description,
            customAttributes,
            checklists,
            isUsingFreetextAsBoilerplate,
            freetext,
        };
    }

    hasUntrackedChanges(
        doc: Issue & PouchDB.Core.AllDocsMeta,
        entity: IssueDocType
    ): boolean {
        return issueHasUntrackedChanges(doc, entity);
    }
}

/**
 * Compares if the array-attribute value with the given key
 * between documentfrom DB and converted entity from backend,
 * has been added or removed. Additionally it checks, if
 * the updateDateTime has been the same, indicated a mismatch of
 * data between offline db and backend. Only then it returns
 * true.
 */
function untrackedChangesCausedByMigration(
    doc: Issue & PouchDB.Core.AllDocsMeta,
    entity: IssueDocType,
    key: KeysWithArrayValue<Issue> | KeysWithArrayValue<IssueDocType>
): boolean {
    const missingOnDoc = missingOrEmpty(doc[key]);
    const missingOnEntity = missingOrEmpty(entity[key]);

    const bothMissing = missingOnDoc && missingOnEntity;
    if (bothMissing) {
        return false;
    }

    const sameUpdateDateTime = doc.updateDateTime === entity.updateDateTime;
    const untrackedChange = missingOnDoc && sameUpdateDateTime;
    if (untrackedChange) {
        // there are new array attributes on the entity
        return true;
    }

    return false;
}

function inDocButNotEntity(
    doc: Issue & PouchDB.Core.AllDocsMeta,
    entity: IssueDocType,
    // intersection of the sets of keys, i.e. all keys which are in both
    key: Extract<keyof Issue, keyof IssueDocType>
): boolean {
    return nullOrUndefined(doc[key]) && !nullOrUndefined(entity[key]);
}

export function issueHasUntrackedChanges(
    doc: Issue & PouchDB.Core.AllDocsMeta,
    entity: IssueDocType
): boolean {
    const noDoc = !doc;
    if (noDoc) {
        return true;
    }

    // the migration could introduce empty custom attribute arrays
    // after the user already downloaded the newest issues with the custom
    // attributes -- in this case the updateDateTime is the same, but
    // the custom attributes are old, this is fixed here
    if (untrackedChangesCausedByMigration(doc, entity, 'customAttributes')) {
        return true;
    }

    // checklists have to be handled the same: checklists are also tracked
    // as part of the issue, thus they are tracked by the issues
    // updateDateTime
    if (untrackedChangesCausedByMigration(doc, entity, 'checklists')) {
        return true;
    }

    const freetextUpdateAfterMigration =
        inDocButNotEntity(doc, entity, 'isUsingFreetextAsBoilerplate') ||
        inDocButNotEntity(doc, entity, 'freetext');
    if (freetextUpdateAfterMigration) {
        return true;
    }

    const noMarkedPlanInBoth = !doc.markedPlan && !entity.markedPlan;
    if (noMarkedPlanInBoth) {
        return false;
    }

    const markedPlanOnlyInOne = !doc.markedPlan || !entity.markedPlan;
    if (markedPlanOnlyInOne) {
        // added or deleted the marked plan => the issue's updateDateTime
        // is updated in that case, but return true here to be on the safe side
        return true;
    }

    // note: the id is tracked by the issue entity,
    //       so just compare the updateDateTime
    const markedPlanUpdated =
        doc.markedPlan.updateDateTime !== entity.markedPlan.updateDateTime;

    return markedPlanUpdated;
}
