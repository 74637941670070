import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { HubConnection } from '@aspnet/signalr';
import { Preferences } from '@capacitor/preferences';
import { ActionPerformed } from '@capacitor/push-notifications';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'app/core/services/device.service';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { SignalR } from 'app/main/notifications/signal-r';
import { navigation } from 'app/navigation/navigation';
import { UserSettingsDialogComponent } from 'app/shared/components/dialogs/user-settings-dialog/user-settings-dialog.component';
import { LogoutService } from 'app/shared/services/logout/logout.service';
import { AuthState } from 'app/store/auth/auth.reducer';
import {
    getCurrentUser,
    getIsAdminGlobal,
} from 'app/store/auth/auth.selectors';
import { SetSearch } from 'app/store/core/core.actions';
import { getSearch } from 'app/store/core/core.selectors';
import {
    getNotificationById,
    getTotalNotifications,
} from 'app/store/notifications/notifications.selectors';
import { environment } from 'environments/environment';
import find from 'lodash/find';
import { concat, of, Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { DemoService } from 'app/shared/services/demo/demo.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    assetUrl = environment.endpoints.asset;
    thumbnailSuffix = AcceptUtils.thumbnailSuffix();

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;

    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));
    totalNotifications$ = this.store.pipe(select(getTotalNotifications));
    currentUser$ = this.store.pipe(select(getCurrentUser));
    searchControl = new UntypedFormControl('');
    notificationBadgeCount: number;

    isDemoMode$ = concat(of(false), this.demoService.isDemoMode$);

    private _unsubscribeAll: Subject<void>;
    private webSocketConnection: HubConnection;

    @ViewChild(MatMenuTrigger, { static: true })
    notificationsMenuTrigger: MatMenuTrigger;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _dateAdapter: DateAdapter<any>,
        private deviceService: DeviceService,
        private dialog: MatDialog,
        private store: Store<AuthState>,
        private logoutService: LogoutService,
        private router: Router,
        private demoService: DemoService
    ) {
        this.languages = [
            {
                id: 'de',
                title: 'German',
                translate: 'APP.GERMAN',
                flag: 'de-de',
            },
            {
                id: 'en',
                title: 'English',
                translate: 'APP.ENGLISH',
                flag: 'en-us',
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = find(this.languages, {
            id: environment.defaults.language,
        });

        const token = await Preferences.get({ key: 'notificationToken' });
        if (token) {
            this.webSocketConnection = new SignalR(
                token.value,
                this.store
            ).listen();
        }

        this.store
            .pipe(
                select(getSearch),
                takeUntil(this._unsubscribeAll),
                tap((search) =>
                    this.searchControl.patchValue(search, {
                        emitEvent: false,
                    })
                )
            )
            .subscribe();

        this.searchControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                tap((search) => this.store.dispatch(new SetSearch({ search })))
            )
            .subscribe();

        this.listenOnPushNotifications();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        this.webSocketConnection.stop();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        this._dateAdapter.setLocale(lang.flag);
    }

    logout(): void {
        this.logoutService.logoutIfUserConfirms();
    }

    openUserSettingsDialog(): void {
        this.dialog.open(UserSettingsDialogComponent, {
            height: '660px',
            width: '400px',
        });
    }

    listenOnPushNotifications(): void {
        this.deviceService.pushNotifications$.subscribe(
            (pushNotification: ActionPerformed) => {
                if (pushNotification.notification.data.notification_link) {
                    const notificationId = pushNotification.notification.data.notification_link
                        .split('/')
                        .splice(6)[0];

                    setTimeout(() => {
                        this.store
                            .pipe(
                                select(getNotificationById(notificationId)),
                                take(1)
                            )
                            .subscribe((notification) => {
                                this.router.navigate(
                                    notification['link'].split('/').splice(5)
                                );
                            });
                    }, 1_000);
                }
            }
        );
    }

    clearNotificationBadge(): void {
        this.notificationBadgeCount = 0;
    }
}
