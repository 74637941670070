import { EntityType } from './entity-type';

export class SyncCommand<T> {
    action: 'create' | 'update';
    entityType: EntityType;
    entityId: string;
    projectId: string;
    changes: Partial<T>;
    issueId?: string;
    locked: boolean;
    waitForCreation: boolean;
    databaseId?: string;
    timestampCreated: number;

    /**
     * Set to true, if this command is currently being transferred
     * (potentially combined with other commands) to the backend.
     *
     * Note, that this attribute is not saved in the offline DB because
     * stopping the application would also cancel the currently outstanding
     * requests.
     */
    inFlight: boolean;

    constructor() {
        this.timestampCreated = new Date().getTime();
        this.inFlight = false;
    }

    // NOTE: Be careful extending this class with e.g. methods, it is
    //       copied with the spread operator throughout the codebase
    //       which removes e.g. the instance type.
}
