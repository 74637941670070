import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    Update,
} from '@ngrx/entity';
import { Project } from 'app/core/rest-api';
import {
    ProjectTemplatesActions,
    ProjectTemplatesActionTypes,
} from './project-templates.actions';

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export interface ProjectTemplatesState extends EntityState<Project> {
    selected: string[];
    loading: boolean;
    loaded: boolean;
    withArchived: boolean;
}

export const initialState: ProjectTemplatesState = adapter.getInitialState({
    selected: [],
    loading: false,
    loaded: false,
    withArchived: false,
});

export function reducer(
    state = initialState,
    action: ProjectTemplatesActions
): ProjectTemplatesState {

    switch (action.type) {
        case ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_SUCCESS:
            return adapter.setAll(action.payload.projectTemplates, {
                ...state,
                loading: false,
                loaded: true,
            });

        case ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ProjectTemplatesActionTypes.CREATE_PROJECT_TEMPLATE_SUCCESS:
            return adapter.addOne(action.payload.template, { ...state });

        case ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATE_SUCCESS:
            return adapter.updateOne(
                {
                    id: action.payload.template.id,
                    changes: action.payload.template,
                },
                state
            );

        case ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATES_SUCCESS:
            const templates = action.payload.templates;
            const updates: Update<Project>[] = templates.map((template) => 
                ({ id: template.id, changes: template })
            );

            return adapter.updateMany(updates, state);

        case ProjectTemplatesActionTypes.TOGGLE_SELECT_PROJECT_TEMPLATE: {
            const selectedTemplateId: string = action.payload.templateId;

            let selected: string[] = state.selected; // Default: No change

            if ( state.selected.includes(selectedTemplateId) ) {
                // If the template was selected, remove from selection
                selected = state.selected.filter( (it) => it !== selectedTemplateId);
            } else if ( !state.entities[selectedTemplateId].isReadOnlyForCustomers ) {
                // If it the template can be changed, add into selection
                selected = [...state.selected, selectedTemplateId];
            }

            return { ...state, selected };
        }

        case ProjectTemplatesActionTypes.TOGGLE_SELECT_ALL_PROJECT_TEMPLATES: {
            const userCreatedTemplatesIds = (state.ids as string[]).filter(
                (templateId) => !state.entities[templateId].isReadOnlyForCustomers, // TODO: What is `templateId` isn't in `entities`?
            );
            const activeUserCreatedTemplatesIds = userCreatedTemplatesIds.filter(
                (templateId) => !state.entities[templateId].markedAsDelete, // TODO: What is `templateId` isn't in `entities`?
            );
            const selectedItems = state.withArchived
                ? userCreatedTemplatesIds
                : activeUserCreatedTemplatesIds;

            // If more items are selected than ?
            const selected = state.selected?.length >= selectedItems?.length ? [] : [...selectedItems];

            return { ...state, selected };
        }

        case ProjectTemplatesActionTypes.DESELECT_ALL_PROJECT_TEMPLATES:
            return {
                ...state,
                selected: [],
            };

        case ProjectTemplatesActionTypes.TOGGLE_ARCHIVED_PROJECT_TEMPLATES:
            return {
                ...state,
                withArchived: !(state.withArchived),
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
