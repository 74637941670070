import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location as FlinkLocation } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { CraftToPlanLocationDocType } from './craft-to-plan-locations.document';

@Injectable({
    providedIn: 'root',
})
export class CraftToPlanLocationDbService extends EntityDbService<
    FlinkLocation,
    CraftToPlanLocationDocType
> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.CraftToPlanLocations
        );
    }

    getAttachmentIdsFromApiItem(location: FlinkLocation): string[] {
        const allPlanIds = [];
        if (!location.craftToPlan) {
            return [];
        }
        for (const planIds of Object.values(location.craftToPlan)) {
            if (planIds && planIds.length > 0) {
                // Download all plans for the craft, so that older issues
                // can refer to older plans if needed. A future enhancement
                // would be to check which plans are actually needed and only
                // store them in offline mode.
                //
                // This was changed from only downloading the first plan,
                // as that introduced issues in offline mode and using newer
                // plans.
                allPlanIds.push(...planIds);
            }
        }

        const onlyUnique = [...new Set(allPlanIds)];
        const withThumbnailIds = [...onlyUnique];
        for (const id of onlyUnique) {
            const planThumbnail = id + AcceptUtils.thumbnailSuffix();
            withThumbnailIds.push(planThumbnail);
        }

        return withThumbnailIds;
    }

    apiItemToSchema(location: FlinkLocation): CraftToPlanLocationDocType {
        const {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
            craftToPlan,
        } = location;
        return {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
            craftToPlan,
        };
    }
}
