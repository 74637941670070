// // PathsToStringProps and Join from https://stackoverflow.com/questions/47057649/typescript-string-dot-notation-of-nested-object
// type PathsToStringProps<T> = T extends string ? [] : {
//     [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>]
// } [Extract<keyof T, string>];

// /**
//  * D - Delimiter
//  */
// type Join<T extends string[], D extends string> =
//     T extends [] ? never :
//     T extends [infer F] ? F :
//     T extends [infer G, ...infer R] ?
//         G extends string ?  `${G}${D}${Join<Extract<R, string[]>, D>}` :
//         never :
//     string;

// export type LocaleKey = Join<PathsToStringProps<typeof locale['data']>, '.'>;

// Above requries a newer tyepscript (tested on 4.4, but probably requires 4.1)

export type LocaleKey = string;


export const locale = {
    lang: 'de',
    data: {
        APP: {
            UPLOAD_CHANGES: 'Änderungen hochladen',
            SYNC_PROJECTS: 'Projekte herunterladen',
            MARK_FOR_SYNC: 'Zum synchronisieren auswählen',
            UNMARK_FOR_SYNC: 'Nicht mehr synchronisieren',
            PULL_TO_REFRESH: 'Runterziehen zum aktualisieren',
            RELEASE_TO_REFRESH: 'Loslassen zum aktualisieren',
            REFRESHING: 'Aktualisieren',
            SELECT_PROJECT: 'Projekt auswählen',
            EXTERNAL_CONTACTS: 'Sync Adressen übernehmen',
            DELETE_OFFLINE_DATA: 'Offline-Daten löschen',
            TEMPLATES: 'Vorlagen',
            CSV_UPLOAD_SUCCESS: 'CSV wurde hochgeladen',
            SHOW_TICKETS: 'Tickets anzeigen',
            IMPORTANCE: 'Wichtigkeit',
            HISTORY_NO_OFFLINE: 'Die Historie ist nur online verfügbar',
            PLAN_MISSING: 'Kein Plan gefunden',
            NOTE: 'Notiz/Bemerkung',
            INPUT_COMMENT: 'Kommentar eingeben',
            COMMENT: 'Kommentar',
            INPUT: 'eingeben',
            CHOOSE_CRAFT_LOCATION: 'Gewerk und Ort auswählen',
            CONFLICT: 'Konflikt',
            CONFLICTS: 'Konflikte',
            SYNC: 'Synchronisieren',
            NO_SYNC: 'Nicht synchronisieren',
            NO_ENTRIES: 'Keine Einträge vorhanden',
            SYNCED_ENTRIES: 'Synchronisierte Einträge',
            UNSYNCED_ENTRIES: 'Noch ausstehende Einträge',
            APPLY: 'Übernehmen',
            DAYS: 'Tage',
            FORCE_OFFLINE: 'Offline-Modus',
            SHOW: 'Anzeigen',
            ALL: 'Alle',
            RECOMMENDATIONS: 'Vorschläge',
            RECOMMENDED: 'Vorschlag',
            SELECT_SNIPPET: 'Vorlage auswählen',
            NO_SNIPPET: 'Keine Vorlage',
            LOCATION_SELECTOR_LABEL: 'Verortung',
            BOILERPLATES: 'Textbausteine',
            COPY: 'kopieren',
            TITLE: 'Titel',
            ARCHIVE_VERB: 'Löschen',
            DELETE_TICKET_VERB: 'als gelöscht kennzeichnen?',
            ISSUES: 'Tickets',
            FORM_LETTER: 'Formbrief auswählen',
            FORM_LETTER_CONTENT: 'Freitextfeld',
            PRINT: 'Drucken',
            PRINT_DISABLED_NO_SELECTION:
                'Keine Einträge zum Drucken ausgewählt.',
            PRINT_DISABLED_DIARY_BAD_SELECTION:
                'Nur Begehungen können gedruckt werden. Bitte passen Sie Ihre Auswahl an, sodass nur Begehungen ausgewählt sind.',
            EXPORT: 'Exportieren',
            NEXT: 'Weiter',
            DIARY_PRINT_BUTTON: {
                ENABLED: 'Drucken',
                DISABLED_NO_SELECTION: 'Keine Einträge zum Drucken ausgewählt.',
                DISABLED_BAD_SELECTION:
                    'Nur Begehungen können gedruckt werden. Bitte passen Sie Ihre Auswahl an, sodass nur Begehungen ausgewählt sind.',
            },
            GERMAN: 'Deutsch',
            BRAZILIAN_PORTUGUESE: 'Brasilianisches Portugiesisch',
            SELECT_ISSUE: 'Ticket auswählen',
            SELECT_ISSUE_OR_FILTER_BY_PLAN:
                'Wählen Sie ein Ticket oder wählen Sie über den Filter die Plan-Ansicht aus',
            NO_ISSUES: 'Keine Tickets vorhanden',
            NO_PROJECTS: 'Keine Projekte vorhanden',
            NO_REVISIONS: 'Keine Revisionen vorhanden',
            ENGLISH: 'Englisch',
            HIGH: 'Hoch',
            MEDIUM: 'Mittel',
            LOW: 'Niedrig',
            ADD: 'Hinzufügen',
            SAVE: 'Speichern',
            CANCEL: 'Abbrechen',
            DELETE: 'Löschen',
            PREVIEW: 'Vorschau',
            DOWNLOAD: 'Herunterladen',
            SEARCH: 'Suchen',
            LOGOUT: 'Abmelden',
            LOGOUT_DIALOG: {
                TITLE: 'Wollen Sie sich wirklich abmelden?',
                DEVICE_WARNING:
                    'Sie haben Änderungen, die noch nicht mit dem Server synchronisiert wurden. Wenn Sie sich abmelden, werden diese Änderungen verworfen.',
            },
            EDIT: 'Bearbeiten',
            SETTINGS: 'Einstellungen',
            CRAFT: 'Gewerk',
            CRAFTS: 'Gewerke',
            ISSUE: 'Ticket',
            ROLE: 'Rolle',
            ROLES: 'Rollen',
            COMPANY: 'Firma',
            CONFIRM: 'Bestätigen',
            BACK: 'Zurück',
            ATTENDEES: 'Teilnehmer',
            COMPANIES: 'Firmen',
            CATEGORY: 'Kategorie',
            EDITING: 'In Bearbeitung',
            FILTER: 'Filtern',
            RESET: 'Zurücksetzen',
            OPEN: 'Öffnen',
            BROWSE: 'durchsuchen',
            ARCHIVE: 'Gelöschte anzeigen',
            UPLOAD: 'Hochladen',
            PROFILE_SETTINGS: 'Profil bearbeiten',
            COUNTRY: 'Land',
            LANGUAGE: 'Sprache',
            DATE: 'Datum',
            TIME: 'Zeit',
            AS: 'Als',
            AT: 'um',
            TEXT: 'Text',
            MANAGEMENT: 'Leitung',
            WEATHERENTRY: 'Witterung',
            INSPECTIONENTRY: 'Begehung',
            APPROVALENTRY: 'Abnahme',
            NOTEENTRY: 'Notizen',
            PROGRESSREPORTENTRY: 'Bautenstand',
            ATTENDANCEENTRY: 'Anwesende',
            PROJECT: 'Projekt',
            BROWSE_IMG_TITLE: 'Bild hochladen',
            BROWSE_IMG: 'Bild reinziehen oder durchsuchen...',
            CREATE_ACCOUNT: 'Konto erstellen',
            NOT_ACTIVE_ANYMORE: 'Nicht mehr aktiv',
            PERSON: 'Person',
            PERSONS: 'Personen',
            TYPE: 'Typ',
            TIMEZONE: 'Zeitzone',
            ADD_NEW_ITEM: 'Neues Element hinzufügen',
            SHOW_MORE: 'Mehr anzeigen',
            SHOW_LESS: 'Weniger anzeigen',
            SYNCING: 'Daten abgleichen',
            FORM_LETTER_DIALOG: {
                PREDEFINED_TEMPLATES: 'Vorgefertigte Vorlagen',
                CUSTOM_TEMPLATES: 'Eigene Vorlagen',
                SUCCESS_CREATE_LETTER_TEMPLATE: 'Vorlage wurde gespeichert',
                RTF_TEMPLATES: 'RTF Vorlagen',
                ADD_ISSUE_DETAILS_TO_FORM_LETTER:
                    'Möchten Sie die Ticketdetails zum Formbrief hinzufügen?',
                WITH_ISSUE_DETAILS: 'Mit Ticketdetails',
                WITHOUT_ISSUE_DETAILS: 'Ohne Ticketdetails',
                ADD_SIGNATURE_TO_FORM_LETTER:
                    'Möchten Sie ein Unterschriftsfeld zum Formbrief hinzufügen?',
                WITH_SIGNATURE: 'Mit Unterschrift',
                WITHOUT_SIGNATURE: 'Ohne Unterschrift',
                FORM_LETTER_READY_FOR_DOWNLOAD:
                    'Der Bericht ist nun bereit für den Export.',
                SAVE_INPUT_AS_TEMPLATE:
                    'Möchten Sie Ihre Eingaben als neue Vorlage speichern?',
                CREATE_PDF: 'PDF erstellen',
            },
        },
        CRAFTS: {
            0: '000 Sicherheitseinrichtungen, Baustelleneinrichtungen',
            1: '001 Gerüstarbeiten',
            2: '002 Erdarbeiten',
            3: '003 Landschaftsbauarbeiten',
            4: '004 Landschaftsbauarbeiten - Pflanzen',
            5: '005 Brunnenbauarbeiten und Aufschlussbohrungen',
            6: '006 Spezialtiefbauarbeiten',
            7: '007 Untertagebauarbeiten',
            8: '008 Wasserhaltungsarbeiten',
            9: '009 Entwässerungskanalarbeiten',
            10: '010 Drän- und Versickerarbeiten',
            11: '011 Abscheider- und Kleinkläranlagen',
            12: '012 Mauerarbeiten',
            13: '013 Betonarbeiten',
            14: '014 Natur-, Betonwerksteinarbeiten',
            16: '016 Zimmer- und Holzbauarbeiten',
            17: '017 Stahlbauarbeiten',
            18: '018 Abdichtungsarbeiten',
            19: '019 Kampfmittelräumdienst',
            20: '020 Dachdeckungsarbeiten',
            21: '021 Dachabdichtungsarbeiten',
            22: '022 Klempnerarbeiten',
            23: '023 Putz- und Stuckarbeiten, Wärmedämmsysteme',
            24: '024 Fliesen- und Plattenarbeiten',
            25: '025 Estricharbeiten',
            26: '026 Fenster, Außentüren',
            27: '027 Tischlerarbeiten',
            28: '028 Parkett-, Holzpflasterarbeiten',
            29: '029 Beschlagarbeiten',
            30: '030 Rollladenarbeiten',
            31: '031 Metallbauarbeiten',
            32: '032 Verglasungsarbeiten',
            33: '033 Baureinigungsarbeiten',
            34: '034 Maler- und Lackierarbeiten - Beschichtungen',
            35: '035 Korrosionsschutzarbeiten an Stahlbauten',
            36: '036 Bodenbelagarbeiten',
            37: '037 Tapezierarbeiten',
            38: '038 Vorgehängte hinterlüftete Fassaden',
            39: '039 Trockenbauarbeiten',
            40: '040 Wärmeversorgungsanlagen - Betriebseinrichtungen',
            41: '041 Wärmeversorgungsanlagen - Leitungen, Armaturen, Heizflächen',
            42: '042 Gas- und Wasseranlagen - Leitungen, Armaturen',
            43: '043 Druckrohrleitungen für Gas, Wasser und Abwasser',
            44: '044 Abwasseranlagen - Leitungen, Abläufe, Armaturen',
            45: '045 Gas-, Wasser- und Entwässerungsanlagen - Ausstattung, Elemente, Fertigbäder',
            46: '046 Gas-, Wasser- und Entwässerungsanlagen - Betriebseinrichtungen',
            47: '047 Dämm- und Brandschutzarbeiten an technischen Anlagen',
            49: '049 Feuerlöschanlagen, Feuerlöschgeräte',
            50: '050 Blitzschutz- / Erdungsanlagen, Überspannungsschutz',
            51: '051 Kabelleitungstiefbauarbeiten',
            52: '052 Mittelspannungsanlagen',
            53: '053 Niederspannungsanlagen - Kabel / Leitungen, Verlegesysteme, Installationsgeräte',
            54: '054 Niederspannungsanlagen - Verteilersysteme und Einbaugeräte',
            55: '055 Ersatzstromversorgungsanlagen',
            57: '057 Gebäudesystemtechnik',
            58: '058 Leuchten und Lampen',
            59: '059 Sicherheitsbeleuchtungsanlagen',
            60: '060 Elektroakustische Anlagen, Sprechanlagen, Personenrufanlagen',
            61: '061 Kommunikationsnetze',
            62: '062 Kommunikationsanlagen',
            63: '063 Gefahrenmeldeanlagen',
            64: '064 Zutrittskontroll-, Zeiterfassungssysteme',
            69: '069 Aufzüge',
            70: '070 Gebäudeautomation',
            75: '075 Raumlufttechnische Anlagen',
            78: '078 Kälteanlagen für raumlufttechnische Anlagen',
            80: '080 Straßen, Wege, Plätze',
            81: '081 Betonerhaltungsarbeiten',
            82: '082 Bekämpfender Holzschutz',
            83: '083 Sanierungsarbeiten an schadstoffhaltigen Bauteilen',
            84: '084 Abbruch- und Rückbauarbeiten',
            85: '085 Rohrvortriebsarbeiten',
            87: '087 Abfallentsorgung, Verwertung und Beseitigung',
            90: '090 Baulogistik',
            91: '091 Stundenlohnarbeiten',
            96: '096 Bauarbeiten an Bahnübergängen',
            97: '097 Bauarbeiten an Gleisen und Weichen',
            98: '098 Witterungsschutzmaßnahmen',

            491: '491 Baustelleneinrichtung, Revisionsklappen',
            430: '430 RLT Raumlufttechnik',
            490: '490 Ausführungsplanung',
            300: '300 Rohbau',

            400: '400 Elektro',
            410: '410 Gas- und Wasseranlagen - Leitungen, Armaturen',
            420: '420 Wärmeversorgungsanlagen',
            // TODO - 444 is included in the English translation, but not here
            // 444: 'Low-voltage systems - cables / wires, installation systems, installation equipment',
            450: '450 SHK',
            900: '900 Außenansicht',
            910: '910 Außenanlagen',

            6000: 'Werkstatt',
            6010: 'Lager',
            6020: 'Büro',
            6030: 'Gebäude',
            6031: 'Teeküche',
            6032: 'Sanitäranlagen',
            6040: 'Küche / Kantine',
            6050: 'Kraftwerksanlagen',
            6060: 'Stationen / Umspannwerke',
            6070: 'Wassergewinnung',
            6110: 'Gefahrstoffe',
            6120: 'Erste Hilfe',
            6140: 'Prüfungen',
            6150: 'Dokumentation',

            8000: 'Windkraftanlagen',
            8001: 'Rotorblätter',
            8002: 'Turm',
            8003: 'Gondel',

            8010: 'WKA Allgemein',
            8020: 'WKA Kennzeichnung',
            8030: 'WKA Dokumentation',
            8040: 'WKA Trafostation Netzanbindung',
            8050: 'WKA Zuwegung Aussenbereich',
            8060: 'WKA Fundamentkonstruktion',
            8070: 'WKA Turmkonstruktion',
            8080: 'WKA Elektrische Systeme',
            8090: 'WKA Maschinenhaus',
            8100: 'WKA Triebstrang',
            8110: 'WKA Rotornabe',
            8120: 'WKA Rotorblatt A',
            8121: 'WKA Rotorblatt B',
            8122: 'WKA Rotorblatt C',

            9020: 'Fachplaner',

            9996: 'Checkliste',
            9997: 'Auftraggeber',
            9998: 'Nicht zugewiesen',
            9999: 'Allgemein',
        },
        SYNC: {
            TYPES: {
                ISSUE: 'Tickets',
                ATTACHMENT: 'Anhänge',
                DIARY: 'Tagebucheinträge',
                PROJECT: 'Projekte',
            },
            ATTACHMENT_NOT_AVAILABLE_OFFLINE:
                'Dieser Anhang ist aktuell nicht verfügbar.',
            ENTITY_ERRORS: 'Fehler bei Änderungen',
            ERROR_DISCARD_CREATE: 'Änderungen verwerfen',
            ERROR_DISCARD_UPDATE: 'Änderungen verwerfen',
            ENTITY_ERROR_DETAILS: 'Informationen zum Fehler',
            NO_ENTITY_ERROR_DETAILS:
                'Keine Informationen zum Fehler vorhanden.',
            GO_TO_ERRORS: 'Fehler anzeigen',
        },
        COUNTRIES: {
            GERMANY: 'Deutschland',
            UNITED_KINGDOM: 'England',
        },
        FILEPOND: {
            LABEL: 'Foto/Dokument hinzufügen',
        },
        CAPACITOR: {
            CAMERA_LABELS: {
                HEADER: 'Foto hinzufügen',
                FROM_GALLERY: 'Aus Fotogalerie',
                FROM_CAMERA: 'Foto aufnehmen',
            },
        },
        PAGINATOR: {
            FIRST_PAGE: 'Erste Seite',
            ITEMS_PER_PAGE: 'Elemente pro Seite',
            LAST_PAGE: 'Letzte Seite',
            NEXT_PAGE: 'Weiter',
            PREVIOUS_PAGE: 'Zurück',
            OF: 'von',
        },
        DATA: {
            STREET: 'Straße',
            ZIP: 'PLZ',
            CITY: 'Ort',
            STATE: 'Bundesland',
            PHONE: 'Tel',
            MOBILE_PHONE: 'Mobiltelefon',
            CONTACT_PERSON: 'Ansprechpartner',
            RESPONSIBLE: 'Verantwortlich',
            CREATED_AT: 'Erstellt am',
            NAME: 'Name',
            FIRST_NAME: 'Vorname',
            COMPLETION: 'Fertigstellung',
            ATTENDEES: 'Anwesende',
            FAX: 'Fax',
            WEBSITE: 'Webseite',
            SUBSCRIBED_SERVICES: 'Abonnement',
        },
        PROJECT: {
            CREATE_PROJECT: 'Neues Projekt',
            EDIT_PROJECT: 'Projekt Bearbeiten',
            CREATE_TEMPLATE: 'Neue Vorlage',
            EDIT_TEMPLATE: 'Vorlage Bearbeiten',
            PRINT_PROJECTS: 'Projektbericht drucken',
            COPY_PROJECTS: 'Projekte kopieren',
            BEGINNING: 'Beginn',
            END: 'Ende',
            ARCHIVE_PROJECT: 'Löschen',
            ARCHIVE_PROJECT_QUESTION: 'Projekt löschen?',
            ARCHIVE_PROJECT_CONFIRM:
                'Möchten Sie das Projekt als gelöscht kennzeichnen?',
            ARCHIVE_PROJECTS: 'Projekte löschen',
            ARCHIVE_PROJECTS_QUESTION: 'Projekte löschen?',
            ARCHIVE_PROJECTS_CONFIRM:
                'Wollen Sie wirklich {{ length }} Projekte löschen?',
            STATUS_DONE: 'Erledigt',
            STATUS_OPEN: 'Offen',
            STATUS_ACCEPTED: 'Angenommen',
            STATUS_EXTENDED_DEADLINE: 'Nachfrist',
            TICKETS: 'Tickets',
            TOTAL: 'Total',
            LIST: {
                WELCOME: 'Herzlich Willkommen',
                WELCOME_MOBILE: 'Willkommen',
                YOUR_DUE_DATES: 'Sie haben heute {{ length }} Fälligkeiten',
                FORM: {
                    UPLOAD_PROJECT_IMAGE: 'Projektbild hochladen',
                    BROWSE_PROJECT_IMAGE:
                        'Projektbild reinziehen oder <u>durchsuchen...</u>',
                    PROJECT: 'Projekt',
                    BEGIN: 'Projektbeginn',
                    END: 'Projektende',
                    STREET: 'Straße + Hausnummer',
                    ZIP: 'PLZ',
                    COUNTRY: 'Land',
                    ADD_PROJECT_IMAGE: 'Projektbild hinzufügen oder suchen',
                    BUILDING_OWNER: 'Auftraggeber',
                },
                TABLE: {
                    NAME: 'Projektname',
                    BEGIN: 'Beginnt am',
                    END: 'Endet am',
                    CREATED_AT: 'Erstellt am',
                },
            },
            DETAIL: {
                CREATED: 'erstellt',
                DEACTIVATE: 'Projekt deaktivieren',
                ARCHIVE: 'Projekt archivieren',
            },
        },
        ISSUES: {
            SELECT_PRIMARY_PHOTO: 'als Hauptbild setzen',
            NO_PLAN: 'Kein Plan vorhanden',
            CONFLICT_TITLE: 'Achtung, ein Konflikt ist aufgetreten!',
            CONFLICT_TEXT:
                'Wählen Sie aus, welches Ticket Sie aktuell übernehmen möchten.',
            CREATE_ISSUE: 'Ticket erstellen',
            PRINT_DIALOG_TITLE: 'Formbrief auswählen',
            PRINT_DIALOG_TITLE_FORM: 'Formbrief ausfüllen',
            SELECT_LETTER: 'Vorlage auswählen',
            CUSTOM_TEXT: 'Freitext',
            INTERNAL_PERSON: 'Intern',
            EXTERNAL_PERSON: 'Extern',
            STATES: {
                Draft: 'Entwurf',
                Open: 'Offen',
                Accepted: 'Angenommen',
                Rejected: 'Abgelehnt',
                DoneWithoutInspection: 'Freimelden',
                ExtendedDeadline: 'Nachfrist',
                FinalDeadline: 'Letzte Frist',
                MailedExtendedDeadline: 'Versand Nachfrist',
                MailedFinalDeadline: 'Versand Letzte Frist',
                Done: 'Erledigt',
                Failed: 'Fehlgeschlagen',
            },
            LIST: {
                DUE_FROM: 'Fälligkeit von',
                DUE_TO: 'Fälligkeit bis',
                STATUS: 'Status',
                SHOW_ON_PLAN: 'Plan anzeigen',
                TOGGLE_LIST: 'Liste',
                TOGGLE_PLAN: 'Plan',
            },
            DETAIL: {
                CREATE_ISSUE: 'Titel',
                DETAIL: 'Detail',
                PHOTOS: 'Fotos',
                DOCUMENTS: 'Dokumente',
                HISTORY: 'Historie',
                FILES: 'Dateien',

                FORM: {
                    ISSUE_DESC: {
                        BLOCK: 'Ticketbeschreibung (Textbausteine)',
                        FREE: 'Ticketbeschreibung (Freitext)',
                    },
                    DUE_DATE: 'Fälligkeit',
                    RAISE_DUE_DAYS: 'Fälligkeit erhöhen',
                    LOCATION: 'Verortung',
                    ADD_LOCATION: 'Ort hinzufügen',
                    EXTERNAL_RESPONSIBLE: 'Extern Verantwortlicher',
                    SEVERITY: 'Schwere des Tickets',
                    INTERNAL_RESPONSIBLE: 'Intern Verantwortlicher',
                    CREATED_BY: 'Erstellt von',
                    ADD_PHOTO: 'Foto hinzufügen',
                    FLOOR_PLAN: 'Grundriss',
                    DISABLE_BOILERPLATES: 'Freitextmodus aktivieren',
                    ENABLE_BOILERPLATES: 'Textbausteine aktivieren',
                    BOILERPLATES_UNAVAILABLE:
                        'Keine Textbausteine im aktuell ausgewählten Gewerk angelegt. Diese können in den Projekteinstellungen hinzugefügt werden.',
                },
                PREVIEW: 'Vorschau',
                DOWNLOAD: 'Download',
                DELETE_IMAGE: 'Bild löschen',
            },
            PLAN_OVERVIEW: {
                PLAN_SELECTOR_PLACEHOLDER: 'Plan auswählen',
                NO_PLAN_AVAILABLE:
                    'Es kann aktuell kein Plan angezeigt werden. Überprüfen Sie bitte die Filtereinstellungen und die Gebäudekonfiguration.',
            },
            TOOLBAR: {
                SELECT_ALL: 'Alle auswählen',
                SORT: 'Sortieren',
                FILTER: 'Filter',
                ARCHIVE: 'Gelöschte anzeigen',
            },
            DIALOGS: {
                DISCARD_CHANGES: {
                    TITLE: 'Änderungen verwerfen?',
                    QUESTION:
                        'Das Ticket hat nicht gespeicherte Änderungen, möchten Sie die Änderungen verwerfen?',
                    CONFIRM_LABEL: 'Verwerfen',
                },
                COMMENT_FOR_REJECTED: {
                    TITLE: 'Ticket ablehnen',
                    REJECT: 'Ablehnen',
                },
            },
            ERROR_DISCARD_CREATE: 'Ticket verwerfen',
            ERROR_DISCARD_UPDATE: 'Änderungen verwerfen',
            PLAN_OF: 'Plan von',
            CUSTOM_ATTRIBUTES: {
                FROM: 'von',
                TO: 'bis',
            },
            CHECKLIST: {
                TAB_TITLE: 'Checkliste',
                ADD_CHECKLIST: 'Checkliste hinzufügen',
                ADD_CHECKLIST_DIALOG: {
                    TITLE: 'Checkliste hinzufügen',
                    SELECTOR_PLACEHOLDER: 'Checkliste',
                    NO_CHECKLISTS_CONFIGURED:
                        'Es sind aktuell keine Checklisten in diesem Projekt konfiguriert. Sie können in den Einstellungen des Projekts neue Checklisten hinzugefügen.',
                    ADD_BUTTON: 'Hinzufügen',
                    CANCEL_BUTTON: 'Abbrechen',
                },
                REMOVE_CHECKLIST: 'Checkliste entfernen',
                NO_CHECKLIST_ADDED:
                    'Es ist keine Checkliste konfiguriert. Klicken Sie auf den „PLUS Button“, um eine Checkliste hinzuzufügen.',
                NO_CHECKLIST_ADDED_AND_NO_RIGHTS:
                    'Es ist keine Checkliste konfiguriert.',
            },
        },

        NOTIFICATIONS: {
            LAST_ACTIVITIES: 'Letzte Aktivitäten',
            NO_ACTIVITIES: 'Keine neuen Aktivitäten',
            ISSUE_CREATED:
                '{{user}} hat ein neues Ticket erstellt:  {{ticket}}',
            ISSUE_UPDATED: '{{user}} hat ein Ticket bearbeitet: {{ticket}}',
            INSPECTION_ENTRY_FINISHED:
                '{{user}} hat eine Begehung abgeschlossen',
            MESSAGE_CREATED: '{{user}} hat {{title}} erstellt',
            MESSAGE_UPDATE: '{{user}} hat {{title}} bearbeitet',
            MARK_ALL_AS_READ: 'Alle als gelesen markieren',
        },

        /** This should conform to having a title, and a description template for Running, Success, and Failed. See `ActivitiesService` */
        ACTIVITIES: {
            FORM_LETTER: {
                TITLE: 'Formbrief „{{formLetterTitle}}“',
                DESCRIPTION_RUNNING:
                    'Der Download wird in wenigen Sekunden gestartet...',
                DESCRIPTION_SUCCESS: 'Der Download ist beendet',
                DESCRIPTION_FAILED: 'Die Erstellung ist fehlgeschlagen',
            },
        } as {
                [K in 'FORM_LETTER']: {
                    TITLE: string;
                    DESCRIPTION_RUNNING: string;
                    DESCRIPTION_SUCCESS: string;
                    DESCRIPTION_FAILED: string;
                };
            },

        ATTENDEES: {
            ASSIGN_USERS: 'Teilnehmer dem Projekt zuweisen',
            ASSIGN_TO_PROJECT: 'Zuweisen',
        },

        BUILDINGS: {
            TITLE: 'Gebäude',
            ADD_PLANS: 'Grundrisse/Pläne hinzufügen oder',
            BROWSE_PLANS: 'Plan reinziehen oder durchsuchen...',
            DELETE_PLAN_TITLE: 'Plan löschen?',
            DELETE_PLAN_QUESTION:
                'Wollen Sie wirklich den Plan für „{{ craftName }}“ löschen?',
            ADD_LEVEL: 'Ebene hinzufügen',
            ADD_PLAN_DIALOG: 'Plan für {{ locationTitle }} hinzufügen',
            DELETE_LOCATION_TITLE: 'Ort löschen?',
            DELETE_LOCATION_QUESTION: 'Wollen Sie den Ort wirklich löschen?',
            ADD_LOCATION: 'Ebene hinzufügen',
            ERRORS: {
                DELETE_LOCATION_ERROR_IS_USED:
                    'Der Ort oder eine Ebene innerhalb des Ortes wird verwendet. Löschen nicht möglich.',
            },
        },
        USER: {
            LIST: {
                TABLE: {
                    TITLE: 'Titel',
                    FIRST_NAME: 'Vorname',
                    LAST_NAME: 'Nachname',
                    CONTACT_TITLE: 'Anrede',
                    CONTACT_TITLE_MALE: 'Herr',
                    CONTACT_TITLE_FEMALE: 'Frau',
                    CONTACT_TITLE_UNSPECIFIED: 'nicht spezifiziert',
                    COMPANY: 'Firma',
                    EMAIL: 'E-Mail',
                },
                ASSIGN_USER: 'Teilnehmer zuweisen',
                CREATE_USER: 'Teilnehmer anlegen',
                FORM: {
                    USER_DATA: 'Teilnehmerdaten',
                    COMPANY_DATA: 'Firmendaten',
                    SAVE: 'SPEICHERN',
                    CELL_PHONE: 'Mobil Nr.',
                    PHONE: 'Telefon Nr.',
                    USERNAME: 'Benutzername',
                    PASSWORD: 'Passwort',
                    PASSWORD_REPEAT: 'Passwort wiederholen',
                    ADD_AVATAR: 'Profilbild hinzufügen oder durchsuchen',
                    CONTACT_TITLE: 'Anrede',
                    CONTACT_TITLES: {
                        0: 'Herr',
                        1: 'Frau',
                    },
                },
                DELETE: {
                    ATTENDEE: {
                        TITLE: 'Teilnehmer löschen?',
                        QUESTION: 'Wollen Sie den Teilnehmer wirklich löschen?',
                    },
                    ATTENDEES: {
                        TITLE: 'Teilnehmer löschen?',
                        QUESTION:
                            'Wollen Sie wirklich {{ attendeeCount }} Teilnehmer löschen?',
                    },
                    COMPANY: {
                        TITLE: 'Firma löschen?',
                        QUESTION: 'Wollen Sie die Firma wirklich löschen?',
                    },
                    COMPANIES: {
                        TITLE: 'Firmen löschen?',
                        QUESTION:
                            'Wollen Sie wirklich {{ companyCount }} Firmen löschen?',
                    },
                },
            },
        },
        SETTINGS: {
            PROJECT_SETTINGS: {
                TITLE: 'Projekteinstellungen',
                DEADLINE: 'Frist',
                DEADLINE_DAYS: 'Fristtage',
                DAY_SETTING_TITLE:
                    'Projekt nach Werktage (VOB) oder Kalendertage (BGB)',
                DAY_SETTING_PLACEHOLDER:
                    'Projekt nach Kalendertage oder Werktage auswählen',
                SYNC_SETTING_TITLE:
                    'Wann sollen die Tickets synchronisiert und verschickt werden?',
                ON_INPUT: 'Nach Eingabe',
                END_OF_DAY: 'Am Ende des Tages, spätestens um 20 Uhr',
            },
            FORM_LETTERS: {
                FORM_LETTERS: 'Formbriefe',

                DELETE_FORM_LETTER_TITLE: 'Vorlage archivieren?',
                DELETE_FORM_LETTER_QUESTION:
                    'Wollen Sie die Vorlage „{{formLetterTitle}}“ wirklich archivieren?',

                PREDEFINED_TEMPLATES: 'Vorgefertigte Vorlagen',
                CUSTOM_TEMPLATES: 'Eigene Vorlagen',
                SHOW_IN_EXPORT_DIALOG: 'Im Export-Dialog anzeigen',
                EDIT_TABLE_HEADER: 'Bearbeiten',

                PREVIEW_TOOLTIP: 'Vorschau',
                CREATE_CUSTOM_TEMPLATE_TOOLTIP: 'Eigene Vorlage erstellen',

                COPY_AND_EDIT_TEMPLATE_TOOLTIP: 'Vorlage kopieren & bearbeiten',
                EDIT_TEMPLATE_TOOLTIP: 'Vorlage bearbeiten',
                ARCHIVE_TEMPLATE_TOOLTIP: 'Vorlage archivieren',

                PREVIOUS: 'Zurück',
                NEXT: 'Weiter',
                SAVE: 'Speichern',

                NEW_CUSTOM_FROM_PREDEFINED_HELPER_TEXT:
                    'Verwenden Sie häufig wiederkehrende Texte? Dann können Sie diese hier als Standard-Werte vorausfüllen, als Vorlage speichern und bei Bedarf vor dem Drucken anpassen.',

                SETTINGS_DIALOG_TITLE_PREFIXES: {
                    NEW_CUSTOM_TEMPLATE: 'Neue eigene Vorlage:',
                    EDIT_CUSTOM_TEMPLATE: 'Eigene Vorlage bearbeiten:',
                },

                DIALOG_FINAL_PAGE: {
                    TEMPLATE_TITLE_LABEL:
                        'Legen Sie einen Titel für die Vorlage fest:',
                    TEMPLATE_TITLE_PLACEHOLDER: 'Titel der Vorlage',
                },
            },
            ROLES: {
                FOUR_EYES: 'Vieraugenprinzip',
                APPLY_FOUR_EYES: 'Vieraugenprinzip anwenden',
                INTERNAL_RESPONSIBLE: 'int. Verantwortlicher',
                EXTERNAL_RESPONSIBLE: 'ext. Verantwortlicher',
                CHOOSE_ROLE: 'Rolle auswählen',
                CHOOSE_COMPANY: 'Firma auswählen',
                ROLES_AND_RIGHTS: 'Rollen & Rechte',
                ARCHIVE_PROJECT_PARTNER_TITLE: 'Projektpartner archivieren?',
                ARCHIVE_PROJECT_PARTNER_QUESTION:
                    'Wollen Sie den Projektpartner wirklich archivieren?',
                ARCHIVE_PROJECT_PARTNERS_TITLE: 'Projektpartner archivieren?',
                ARCHIVE_PROJECT_PARTNERS_QUESTION:
                    'Wollen Sie wirklich {{ count }} Projektpartner archivieren?',
                ROLE_NORMAL: 'Normal',
                ROLE_CRAFTRESPONSIBLE: 'Gewerksverantwortlicher',
                ROLE_PROJECTMANAGER: 'Projekt Manager',
                ROLE_ADMIN: 'Administrator',
                SELECT_CRAFT: 'Gewerk',
                SELECT_EXT_RESPONSIBLE: 'ext. Verantwortlicher',
                SELECT_INT_RESPONSIBLE: 'int. Verantwortlicher',
                ROLE_SUPERADMIN: 'Super-Administrator',
            },
            TICKETS: {
                TICKET_SETTINGS: 'Ticketzusatzdaten',
                TITLE: 'Ticketattribut',
                TYPE: 'Typ',
                SELECT_TYPE: 'Typ auswählen',
                TYPE_DESCRIPTION: 'Beschreibung hinzufügen',
                TICKET_SETTINGS_DIALOG: 'Ticketzusatzdaten bearbeiten',
                DIALOG_ATTRIBUTENAME: 'Attributname',
                DELETE_ATTRIBUTE_TITLE: 'Ticketattribut löschen?',
                DELETE_ATTRIBUTE_QUESTION:
                    'Wollen Sie das Ticketattribut wirklich löschen?',
            },
            CHECKLIST: {
                CHECKLIST_SETTINGS: 'Checklisten',
                TITLE: 'Checkliste',
                SETTINGS_DIALOG: 'Checkliste bearbeiten',
                DIALOG_CHECKLIST_NAME: 'Checklisten Name',
                DIALOG_ITEM: 'Beschreibung hinzufügen',
                DIALOG_TYPE: 'Typ auswählen',
                ADD_TYPE: 'Weiteren Typ hinzufügen',
                DELETE_CHECKLIST_TITLE: 'Checkliste löschen?',
                DELETE_CHECKLIST_QUESTION:
                    'Wollen Sie die Checkliste wirklich löschen?',
            },
            TYPES: {
                Text: 'Text',
                Number: 'Nummer',
                Contact: 'Kontakt',
                Date: 'Datum',
                Url: 'Url',
                Boolean: 'Ja/Nein',
                Label: 'Zwischenüberschrift',
            },
        },
        ADMIN_LOGO: {
            COMPANY_LOGO: 'Unternehmenslogo',
            NO_LOGO: 'Momentan ist kein Logo hochgeladen.',
            UPLOAD_LOGO: 'Logo hochladen',
            CHANGE_LOGO: 'Logo ändern',
            NOTE_EVALUATE:
                'Ihr Logo wird beim Erstellen von Auswertungen angezeigt.',
            NOTE_SIZE_FIRST: {
                START: 'Sie können hier ihr Unternehmenslogo in der Größe von ',
                PIXELS: /*<strong>*/ '{{x}}x{{y}} Pixel',
                END: /*</strong>*/ ' Pixel hochladen.',
            },
            NOTE_SIZE_CHANGE: {
                START: 'Bitte beachten Sie: Ihr Logo benötigt eine Größe von ',
                PIXELS: '{{x}}x{{y}} Pixel.',
            },

            DELETE_LOGO: {
                TITLE: 'Unternehmenslogo löschen',
                QUESTION: 'Wollen Sie das Unternehmenslogo wirklich löschen?',
            },

            RESIZE_TITLE: 'Bildgröße ändern',
            SCALED: 'Seitenverhältnis beibehalten',
            SCALE: 'Beibehalten',
            SQUISHED: 'Seitenverhältnis ignorieren',
            SQUISH: 'Ignorieren',
        },
        GLOBAL_SETTINGS: {
            ADDRESS_BOOK: {
                TITLE: 'Adressbuch',
                ADD_ATTENDEE: 'Kontakte hinzufügen',
                ADD_ONE_ATTENDEE: 'Einen Kontakt hinzufügen',
                ADD_COMPANY: 'Firma hinzufügen',
                ELEMENTS_SELECTED: 'Elemente ausgewählt',
                PRINT_ATTENDEES: 'Teilnehmer drucken',
                ARCHIVE_ATTENDEES: 'Teilnehmer archivieren',
                ARCHIVE_ATTENDEE: 'Teilnehmer archivieren',
                EDIT_ATTENDEE: 'Teilnehmer bearbeiten',
                DELETE_ATTENDEES: 'Teilnehmer entfernen?',
                PRINT_COMPANIES: 'Firmen drucken',
                ARCHIVE_COMPANIES: 'Firmen archivieren',
                ARCHIVE_COMPANY: 'Firma archivieren',
                EDIT_COMPANY: 'Firma bearbeiten',

                CONTACT_IMPORT: {
                    OPEN_DIALOG_BUTTON: 'Kontakte importieren',
                    NOT_AVAILABLE:
                        'Das Importieren von Kontakten ist aktuell nicht möglich. Bitte legen Sie eine Firma an, um Kontakte importieren zu können.',

                    COMPANY_CSV_STEP: 'Firma und Datei auswählen',
                    COMPANY_HINT:
                        'Bitte wählen Sie die Firma aus, für welche die Kontakte importiert werden sollen:',
                    CSV_HINT:
                        'Bitte wählen Sie die unter Windows aus Microsoft Outlook exportierte CSV-Datei mit den Kontakten aus.',
                    CSV_FILE_POND_LABEL:
                        'Bitte hier klicken, um die CSV-Datei auszuwählen.',
                    CSV_FILE_POND_LABEL_LOADING: 'Datei wird geladen...',
                    CSV_DOWNLOAD_EXAMPLE:
                        'Hier können Sie eine Vorlage herunterladen, in der das Format ersichtlich ist.',
                    TITLE_HINT:
                        'Die Andrede des jeweiligen Kontaktes wird basierend auf dem Vornamen bestimmt. Bitte überprüfen Sie diese Einstellung nach dem Import.',
                    CANCEL: 'Abbrechen',
                    IMPORT: 'Importieren',

                    IMPORT_RESULT_STEP: 'Ergebnis des Imports',
                    IMPORT_FAILED:
                        'Beim Import ist ein Fehler aufgetreten. Bitte prüfen Sie das Format Ihrer CSV-Datei.',
                    CONTACTS_IMPORTED: {
                        '=0': 'Es wurden keine Kontakte importiert.',
                        '=1': 'Es wurde ein Kontakt importiert.',
                        other: 'Es wurden # Kontakte importiert.',
                    },
                    PROBLEMS_OCCURRED: {
                        '=1': 'Beim Import ist ein Problem aufgetreten.',
                        other: 'Beim Import sind # Probleme aufgetreten.',
                    },
                    PLEASE_CHECK_LINES:
                        'Bitte beachten Sie die folgenden Informationen zu den jeweiligen Zeilen Ihrer Outlook CSV-Datei.',
                    CLOSE: 'Schließen',

                    ERRORS: {
                        SKIPPED:
                            'Ein Kontakt mit dieser E-Mail-Adresse ist bereits vorhanden.',
                        CSV_FORMAT_INVALID:
                            'Diese Zeile besitzt ein ungültiges Format.',
                        FIRSTNAME: 'Vorname',
                        NAME: 'Nachname',
                        EMAIL: 'E-Mail-Adresse',
                        NO_VALUE: 'Feld „{{ field }}“ hat keinen Wert.',
                        INVALID_FORMAT:
                            'Feld „{{ field }}“ hat ein ungültiges Format.',
                    },
                },
            },
            EXTERNAL_CONTACTS: {
                TABLE_TITLE: 'untermStrich Adressen',
                SEARCH_BY: 'Suche nach',
                SEARCH_TERM: 'Suche',
                SEARCH_TYPES: {
                    EMAIL: 'Email',
                    NAME: 'Name',
                    FIRSTNAME: 'Vorname',
                },
                NO_TABLE_RECORDS: 'Bitte nutzen Sie die Suche um untermStrich Adressen zu finden',
            },
        },
        ERRORS: {
            REQUIRED: {
                EMAIL: 'Bitte E-Mail-Adresse eingeben',
                PASSWORD: 'Bitte Passwort eingeben',
            },
            VALIDATE: {
                EMAIL: 'Bitte gültige E-Mail-Adresse eingeben',
            },
        },

        BANNER: {
            PROJECT_BEGIN: 'Projektbeginn',
            PROJECT_END: 'Fertigstellung',
            OPEN: 'Offen',
            ACCEPTED: 'Angenommen',
            EXTENDED_DEADLINE: 'Nachfrist',
            DONE: 'Erledigt',
        },

        DIALOG: {
            USER_SETTINGS: {
                TITLE: 'Benutzer-Einstellungen',
                EMAIL_ADDRESS: 'E-Mail Addresse',
                EMAIL_ADDRESS_REPEAT: 'Neue E-Mail-Addresse wiederholen',
                NEW_PASSWORD: 'Neues Passwort',
                NEW_PASSWORD_REPEAT: 'Neues Passwort wiederholen',
                TWO_FACTOR_AUTH: '2-Faktor Authentifizierung aktivieren',
            },
            PLAN_PREVIEW: {
                SAVE: 'Änderungen speichern?',
                DISCARD: 'Änderungen verwerfen?',
                DISCARD_MESSGAE:
                    'Es wurden Änderungen an der aktuellen Revision vorgenommen. Sollen diese verworfen werden?',
                DISCARD_BUTTON: 'Änderungen verwerfen',
                SAVEWITHOUTSAVING: 'Schließen ohne zu speichern?',
            },
        },

        DIARY: {
            SELECT_ENTRY: 'Eintrag auswählen',
            ARCHIVE_ENTRY: 'Eintrag archivieren',
            ADD_ENTRY: 'Eintrag hinzufügen',
            NOW: 'jetzt',
            ONE_MINUTE_AGO: 'vor 1 Minute',
            MINUTES_AGO: 'vor {{ minutes }} Minuten',
            ONE_HOUR_AGO: 'vor 1 Stunde',
            HOURS_AGO: 'vor {{ hours }} Stunden',
            ARCHIVE_ENTRY_TITLE: 'Eintrag archivieren?',
            ARCHIVE_ENTRIES_TITLE: 'Einträge archivieren?',
            ARCHIVE_ENTRY_QUESTION:
                'Wollen Sie den Eintrag wirklich archivieren?',
            ARCHIVE_ENTRIES_QUESTION:
                'Wollen Sie wirklich {{ count }} Einträge archivieren?',
            DATE_FROM: 'Datum von',
            DATE_TILL: 'Datum bis',
            NUMBER_OF_ENTRIES: 'Anzahl',
            ENTRIES_NOT_FOUND: 'Keine Einträge gefunden',
            CONTACT: 'Kontakt',

            APPROVAL: {
                SERVICE: 'Leistung',
                APPROVED_BY: 'Abgenommen von',
                APPROVAL_DATE: 'Abnahmedatum',
                COMMENTS: 'Notizen/Bemerkungen',
            },

            ATTENDANCE: {
                ATTENDENT: 'Anwesend',
                ABSENT: 'Abwesend',
                NUMBER_OF_ATTENDEES: 'Anzahl der anwesenden Personen',
                WORKING_TIME_FROM: 'Zeit von',
                TILL: 'bis',
                PERSONS: 'Personen',
            },

            COMMENTS: {
                TITLE: 'Notizen',
                COMMENT: 'Notiz',
            },

            PROGRESS: {
                TITLE: 'Bautenstand',
                PROGRESS_TO: 'Bautenstand zu',
                COMPLETED: 'abgeschlossen',
                DESCRIPTION: 'Beschreibung',
            },

            WEATHER: {
                TITLE: 'Witterung',
                MEASURING_TIME: 'Messzeitpunkt',
                RAINFALL: 'Niederschlag',
                WIND: 'Wind',
                TEMPERATURE: 'Temperatur',
                CALM: 'Windstill',
                LIGHTBREEZE: 'Leichte Brise',
                MODERATEGALE: 'Leichter Sturm',
                HURRICANE: 'Orkan',
                CLEAR: 'Klarer Himmel',
                PARTLYCLOUDY: 'Teils bewölkt',
                CLOUDY: 'Bewölkt',
                LIGHTRAIN: 'Leichter Regen',
                RAIN: 'Regen',
                HEAVYRAIN: 'Starkregen',
                THUNDERSTORMS: 'Gewitter',
                SLEET: 'Graupelschauer',
                SNOW: 'Schneefall',
                HEAVYSNOW: 'Starker Schneefall',
                FOG: 'Nebel',
                BLIZZARD: 'Schneesturm',
            },
            INSPECTION: {
                OPEN: 'offen',
                LOCKED: 'abgeschlossen',
                INSPECTION_DATA: 'Begehungsdaten',
                LOCK_TEXT:
                    'Haben Sie alle Tickets der Begehung eingetragen, dann schließen Sie jetzt Ihre Begehung ab.',
                LOCK_INSPECTION: 'Begehung abschließen',
                INSPECTION_LOCKED: 'Begehung abgeschlossen',
                LOCK_INSPECTION_QUESTION:
                    'Wollen Sie die Begehung wirklich abschließen?',
                UNLOCK_INSPECTION: 'Inspektion entsperren',
                UNLOCK_INSPECTION_QUESTION:
                    'Sind Sie sicher, dass Sie diese Inspektion freischalten möchten? Möglicherweise wurde sie bereits an den zuständigen Teilnehmer/Handwerker gesendet.',
                RECOMMENDATIONS: 'Vorschläge',
            },
            ACTIONS: {
                APPROVALENTRY: '', // 1. Abnahme
                INSPECTIONENTRY: 'Begehung',
                PROGRESSREPORTENTRY: '', // 3. Bautenstand
                NOTEENTRY: 'Notizen',
                ATTENDANCEENTRY: 'Anwesende',
                WEATHERENTRY: '', // 6. Witterung
            },
            CONFLICTS: {
                VIEWJOURNALCONFLICTS: 'Bautagebuch Konflikte anzeigen',
                ACCEPT: 'Akzeptieren',
                OPEN: 'Öffnen',
                NO_ENTRIES: 'Keine Einträge vorhanden.',
                OFFLINE_MODE: 'Offline Modus',
                DOWNLOAD_PROJECTS: 'Projekte herunterladen',
                UPLOAD_CHANGES: 'Änderungen hochladen',
                DELETE_OFFLINE_DATA: 'Offline-Daten löschen',
                CONFLICTS: 'Konflikte',
                SYNCHRONIZED_ENTRIES: 'Abgeschlossene Einträge',
                PENDING_ENTRIES: 'Ausstehende Einträge',
                JOURNALCONFLICTS: 'Bautagebuch Konflikte',
            },
            ERROR_DISCARD_CREATE: 'Bautagebucheintrag verwerfen',
            ERROR_DISCARD_UPDATE: 'Änderungen verwerfen',
        },
        TIME: {
            SECONDS: {
                SINGULAR: {
                    TEXT: 'Sekunde',
                    FUTURE: 'in *** Sekunde',
                    PAST: 'vor *** Sekunde',
                    PRESENT: '*** Sekunde',
                },
                PLURAL: {
                    TEXT: 'Sekunden',
                    FUTURE: 'in *** Sekunden',
                    PAST: 'vor *** Sekunden',
                    PRESENT: '*** Sekunden',
                },
            },
            MINUTES: {
                SINGULAR: {
                    TEXT: 'Minute',
                    FUTURE: 'in *** Minute',
                    PAST: 'vor *** Minute',
                    PRESENT: '*** Minute',
                },
                PLURAL: {
                    TEXT: 'Minuten',
                    FUTURE: 'in *** Minuten',
                    PAST: 'vor *** Minuten',
                    PRESENT: '*** Minuten',
                },
            },
            HOURS: {
                SINGULAR: {
                    TEXT: 'Stunde',
                    FUTURE: 'in *** Stunde',
                    PAST: 'vor *** Stunde',
                    PRESENT: '*** Stunde',
                },
                PLURAL: {
                    TEXT: 'Stunden',
                    FUTURE: 'in *** Stunden',
                    PAST: 'vor *** Stunden',
                    PRESENT: '*** Stunden',
                },
            },
            DAYS: {
                SINGULAR: {
                    TEXT: 'Tag',
                    FUTURE: 'in *** Tag',
                    PAST: 'vor *** Tag',
                    PRESENT: '*** Tag',
                },
                PLURAL: {
                    TEXT: 'Tage',
                    FUTURE: 'in *** Tagen',
                    PAST: 'vor *** Tagen',
                    PRESENT: '*** Tage',
                },
            },
            MONTHS: {
                SINGULAR: {
                    TEXT: 'Monat',
                    FUTURE: 'in *** Monat',
                    PAST: 'vor *** Monat',
                    PRESENT: '*** Monat',
                },
                PLURAL: {
                    TEXT: 'Monate',
                    FUTURE: 'in *** Monaten',
                    PAST: 'vor *** Monaten',
                    PRESENT: '*** Monate',
                },
            },
            YEARS: {
                SINGULAR: {
                    TEXT: 'Jahr',
                    FUTURE: 'in *** Jahr',
                    PAST: 'vor *** Jahr',
                    PRESENT: '*** Jahr',
                },
                PLURAL: {
                    TEXT: 'Jahre',
                    FUTURE: 'in *** Jahren',
                    PAST: 'vor *** Jahren',
                    PRESENT: '*** Jahre',
                },
            },
        },
        GLOBAL_BOILERPLATE: 'Global Boilerplate',
        BOILERPLATE: {
            TITLE: 'Textbausteine',
            NO_CRAFT_SELECTED: 'Kein Gewerk ausgewählt',
            DELETE: {
                TITLE: 'Textbaustein „{{ boilerplateTitle }}“ löschen?',
                CONFIRMATION: 'Wollen Sie den Textbaustein wirklich löschen?',
                MESSAGES: {
                    SUCCESS: 'Textbaustein erfolgreich gelöscht',
                    ERROR: 'Textbaustein löschen fehlgeschlagen',
                },
            },
            CREATE: {
                MESSAGES: {
                    SUCCESS: 'Textbaustein erfolgreich erstellt',
                    ERROR: 'Textbaustein erstellen fehlgeschlagen',
                },
            },
            UPDATE: {
                MESSAGES: {
                    SUCCESS: 'Textbaustein erfolgreich aktualisiert',
                    ERROR: 'Textbaustein aktualisiert fehlgeschlagen',
                },
            },
            IMPORT_FROM_CSV: {
                BUTTON: 'Import aus CSV-Datei',
                MESSAGES: {
                    SUCCESS: 'Textbausteine erfolgreich importiert',
                    ERROR: 'Textbausteine erfolgreich fehlgeschlagen',
                },
            },
            LOAD_MESSAGES: {
                ERROR: 'Textbausteine laden fehlgeschlagen',
            },
        },
        DEFAULT_ERROR_MESSAGES: {
            AUTH: {
                LOGIN: 'Beim Login ist ein Fehler aufgetreten!',
                REGISTER:
                    'Bei der Erstellung des Kontos ist ein Fehler aufgetreten!',
                REGISTER_COMPLETE:
                    'Bei der Erstellung des Kontaktkontos ist ein Fehler aufgetreten!',
                UPDATE_PASSWORD:
                    'Beim Aktualisieren des Passworts ist ein Fehler aufgetreten!',
            },
            SYNC: {
                STORE_COMMAND:
                    'Beim Speichern eines Befehls ist ein Fehler aufgetreten!',
                DELETE_COMMAND:
                    'Beim Löschen eines Befehls ist ein Fehler aufgetreten!',
                DOWNLOAD_ATTACHMENTS:
                    'Beim Herunterladen von einer oder mehreren Dateien ist ein Fehler aufgetreten!',
            },
            PROJECTS: {
                LOAD: 'Beim Laden der Projekte ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Projekts ist ein Fehler aufgetreten!',
                UPDATE:
                    'Bei der Aktualisierung des Projekts ist ein Fehler aufgetreten!',
                UPDATE_MANY:
                    'Bei der Aktualisierung der Projekte ist ein Fehler aufgetreten!',
            },
            DIARY: {
                LOAD:
                    'Beim Laden der Tagebucheinträge ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Tagebucheintrags ist ein Fehler aufgetreten!',
                UPDATE:
                    'Beim Aktualisieren des Tagebucheintrags ist ein Fehler aufgetreten!',
                UPDATE_MANY:
                    'Bei der Aktualisierung der Tagebucheinträge ist ein Fehler aufgetreten!',
            },
            CRAFTS: {
                LOAD: 'Beim Laden der Gewerke ist ein Fehler aufgetreten!',
            },
            ISSUES: {
                LOAD: 'Beim Laden der Tickets ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Tickets ist ein Fehler aufgetreten!',
                UPDATE:
                    'Bei der Aktualisierung des Tickets ist ein Fehler aufgetreten!',
                STORE_ATTACHMENT:
                    'Beim Verarbeiten des Fotos oder der Datei ist ein Fehler aufgetreten!',
                UPDATE_ISSUE_ID_OF_COMMANDS_IN_DB:
                    'Beim Speichern der Änderungen vom Ticket für die Offline-Funktionalität ist ein Fehler aufgetreten. Bitte gehen Sie online ohne die App neuzustarten, um Ihre Änderungen dennoch zu speichern.',
            },
            PLAN_OVERVIEW: {
                LOAD_PLAN_ERROR:
                    'Beim Laden der Planübersicht ist ein Fehler aufgetreten!',
            },
            LETTERS: {
                LOAD: 'Beim Laden der Briefe ist ein Fehler aufgetreten!',
                // Note: Currently, it's intentional to use the same message
                // for both keys. This allows them to change independently.
                ERROR_CREATE_LETTER_TEMPLATE:
                    'Vorlage konnte nicht gespeichert werden!',
                ERROR_UPDATE_LETTER_TEMPLATE:
                    'Vorlage konnte nicht gespeichert werden!',
                ERROR_DELETE_LETTER_TEMPLATE:
                    'Vorlage konnte nicht gelöscht werden!',
            },
            CONTACTS: {
                LOAD: 'Beim Laden der Kontakte ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Kontakts ist ein Fehler aufgetreten!',
                UPDATE:
                    'Bei der Aktualisierung des Kontakts ist ein Fehler aufgetreten!',
                DELETE:
                    'Beim Löschen des Kontaktes ist ein Fehler aufgetreten!',
                IMPORT:
                    'Beim Importieren der Kontakte ist ein Fehler aufgetreten!',
            },
            SETTINGS: {
                LOAD_PROJECT_CRAFT_TO_PLAN:
                    'Beim Laden der zu planenden Gewerk ist ein Fehler aufgetreten!',
                PROJECT_ROLES: {
                    LOAD: 'Beim Laden der Rollen ist ein Fehler aufgetreten!',
                    CREATE:
                        'Bei der Erstellung der Rolle ist ein Fehler aufgetreten!',
                    UPDATE:
                        'Bei der Aktualisierung der Rolle ist ein Fehler aufgetreten!',
                    DELETE:
                        'Beim Löschen der Rolle ist ein Fehler aufgetreten!',
                },
                LOAD_PROJECT_CRAFTS:
                    'Beim Laden der Projektgewerk ist ein Fehler aufgetreten',
                LOAD_PROJECT_CONTACT:
                    'Beim Laden der Kontakte des Projekts ist ein Fehler aufgetreten!',
                PROJECT_PARTNERS: {
                    LOAD: 'Beim Laden der Partner ist ein Fehler aufgetreten!',
                    CREATE:
                        'Bei der Erstellung des Partners ist ein Fehler aufgetreten!',
                    UPDATE:
                        'Bei der Aktualisierung des Partners ist ein Fehler aufgetreten!',
                    DELETE:
                        'Beim Löschen des Partners ist ein Fehler aufgetreten!',
                },
                LOAD_LANGUAGES:
                    'Beim Laden der Sprachen ist ein Fehler aufgetreten!',
                LOAD_TIMEZONES:
                    'Beim Laden der Zeitzonen ist ein Fehler aufgetreten!',
            },
            LOCATIONS: {
                LOAD: 'Beim Laden der Standorte ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Ortes ist ein Fehler aufgetreten!',
                UPDATE:
                    'Bei der Aktualisierung des Ortes ist ein Fehler aufgetreten!',
                DELETE: 'Beim Löschen des Ortes ist ein Fehler aufgetreten!',
            },
            BOILERPLATES: {
                LOAD:
                    'Beim Laden der Textbausteine ist ein Fehler aufgetreten!',
                CREATE:
                    'Bei der Erstellung des Textbausteins ist ein Fehler aufgetreten!',
                UPDATE:
                    'Bei der Aktualisierung der Textbausteine ist ein Fehler aufgetreten!',
                DELETE:
                    'Beim Löschen der Textbausteine ist ein Fehler aufgetreten!',
            },
            NOTIFICATIONS: {
                LOAD:
                    'Beim Laden der Benachrichtigungen ist ein Fehler aufgetreten!',
                MARK_AS_READ:
                    'Markierung der Benachrichtigung als gelesen fehlgeschlagen!',
                MARK_ALL_AS_READ:
                    'Markierung aller Benachrichtigungen als gelesen fehlgeschlagen!',
            },
        },
        RESET_PASSWORD: {
            RESET_PASSWORD_TEXT:
                'Geben Sie bitte Ihre E-Mail Adresse zum Zurücksetzen des Passworts ein',
            SEND_MAIL: 'Mail senden',
            RESET_PASSWORD: 'Passwort zurücksetzen',
            RESET_PASSWORD_CONFIRM:
                'Bitte geben Sie ein neues Passwort für Ihren Account ein.',
            RESET_PASSWORD_BUTTON: 'Passwort zurücksetzen',
            NEW_PASSWORD: 'Neues Passwort',
            E_MAIL_ENTRY_SUCCESS: 'E-Mail gesendet an ',
            PASSWORD_RESET_SUCCESS: 'Password erfolgreich geändert',
            PASSWORDS_DO_NOT_MATCH: 'Die Passwörter stimmen nicht überein',
        },
        GENERIC_BUTTONS: {
            OK: 'OK',
        },
        GENERIC_LABELS: {
            YES: 'Ja',
            NO: 'Nein',
        },
        DEMO_MODE: {
            POPUP: {
                HEADER_START: 'Herzlich Willkommen bei Ihrer',
                HEADER_END: '-Demo!',
                HINT: 'Bitte beachten Sie die folgenden wichtigen Hinweise:',
                NO_CONFIDENTIAL_INFO:
                    'Tragen Sie bitte keine vertraulichen Informationen ein.',
                SAVING_IS_DISABLED:
                    'Das Speichern von Änderungen ist nicht möglich.',

                CTA_GET_HELP: 'Hilfe benötigt?',

                CONFIRM: 'Alles klar',
            },
            SAVE_DIALOG: {
                TITLE: 'Demo',
                TEXT:
                    'Speichern ist in dieser Demo nicht möglich. Für eine personalisierte Demo kontaktieren Sie uns gerne.',
                CTA: 'Personalisierte Demo anfordern',
                CONFIRM: 'Alles klar',
            },
        },
    },
};
