/**
 * eTeacher API
 * Last Build: 12/10/2021 11:21:39 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TemplateAttribute { 
    title?: string;
    description?: string;
    type?: TemplateAttribute.TypeEnum;
    defaultValue?: string;
    shownInPrintDialog?: boolean;
    isRequired?: boolean;
}
export namespace TemplateAttribute {
    export type TypeEnum = 'Number' | 'Text' | 'Contact' | 'Color' | 'Date' | 'Boolean';
    export const TypeEnum = {
        Number: 'Number' as TypeEnum,
        Text: 'Text' as TypeEnum,
        Contact: 'Contact' as TypeEnum,
        Color: 'Color' as TypeEnum,
        Date: 'Date' as TypeEnum,
        Boolean: 'Boolean' as TypeEnum
    };
}


