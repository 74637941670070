import { Injectable, OnDestroy, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'hammerjs'; // Needed for HammerManager

import {
    ArgumentMissingError,
    InvalidOperationError,
    ArgumentError,
} from 'app/core/utils/exceptions';

import { Vec2D } from './transform.model';
import { PinchPanState } from './pinch-pan-state.model';

/**
 * The service can be used to register pinch-to-zoom and
 * pan handlers to a given HTML or SVG element.
 *
 * Provide this service via a component's provider array
 * to ensure, that the ngOnDestroy-hook of this service
 * runs when your component is destroyed.
 *
 * To register call the init method.
 *
 * See documentation on the methods for details.
 */
@Injectable()
export class PinchPanService implements OnDestroy {
    private _previewPinchPan$ = new Subject<PinchPanState>();
    /**
     * This observable emits the state of the pinch and pan
     * during the events. This can be used to preview the
     * effects of the transformations.
     */
    public get previewPinchPan$(): Observable<PinchPanState> {
        return this._previewPinchPan$;
    }

    private _pinchPan$ = new Subject<PinchPanState>();
    /**
     * This observable emits the state of the pinch and
     * pan after events were completed.
     */
    public get pinchPan$(): Observable<PinchPanState> {
        return this._pinchPan$;
    }

    private _pinchPanInProgress$ = new Subject<boolean>();
    /**
     * This observable emits true if there is currently a pinch
     * or pan gesture being done. It emits false after such a
     * gesture was completed or canceled.
     */
    public get pinchPanInProgress$(): Observable<boolean> {
        return this._pinchPanInProgress$;
    }

    /** Track if "pinching" is active. Used to calcluate pinchPanInProgress */
    private isPinching: boolean;
    /** Track if "pinching" is active. Used to calcluate pinchPanInProgress */
    private isPanning: boolean;

    private _cancelPreview$ = new Subject<void>();
    /**
     * This observable emits if the pinch or pan gesture was
     * cancelled and that the preview should be reset without
     * applying any real transformations.
     */
    public get cancelPreview$(): Observable<void> {
        return this._cancelPreview$;
    }

    private pinchArea?: ElementRef;
    // note: HammerManager type seems to be global
    private hammer?: HammerManager;

    private initialized: boolean;

    private static getInitialPositionInViewportCoordinates(
        event: HammerInput,
        pinchAreaRect: DOMRect
    ): Vec2D {
        // note: event.center is in global coordinates
        // the global center at pinch start without any panning movement since then
        const xGlobalInit = event.center.x - event.deltaX;
        const yGlobalInit = event.center.y - event.deltaY;

        // the center of the pinch in viewport coordinates (the plan preview)
        return {
            x: xGlobalInit - pinchAreaRect.x,
            y: yGlobalInit - pinchAreaRect.y,
        };
    }

    constructor() {
        this.initialized = false;
    }

    /**
     * Initialize this service for a given HTML or SVG
     * element.
     *
     * @param pinchArea The reference to the target element. Its size and
     *                  location are also used as the viewport dimensions.
     * @param isMobile  Indicates if to either use default handlers or mobile ones.
     *                  The former have issues on iOS.
     */
    init(pinchArea: ElementRef, isMobile: boolean): void {
        if (!pinchArea) {
            throw new ArgumentMissingError('pinchArea');
        }

        // Hammer.js only supports these elements
        const pinchAreaValid =
            !(pinchArea.nativeElement instanceof HTMLElement) &&
            !(pinchArea.nativeElement instanceof SVGElement);
        if (pinchAreaValid) {
            throw new ArgumentError(
                'pinchArea',
                'nativeElement of pinchArea is not a valid element'
            );
        }

        // prevent re-initialization, makes this initialization simpler
        // not having to handle subsequent initializations
        if (this.initialized) {
            throw new InvalidOperationError(
                'ZoomService was already initialized'
            );
        }

        this.pinchArea = pinchArea;
        // TODO: remove this parameter!
        if (isMobile) {
            // Force Hammer to use touch events on mobile, otherwise
            // a slightly wrong position is used (about 5-7px in one
            // direction) on iOS for the panend event. It looks like
            // by default PointerEvents are used, which work in non-
            // mobile browsers and Chrome Android, but not in Safari
            // on iOS.
            this.hammer = new Hammer(this.pinchArea.nativeElement, {
                // inputClass: TouchInput,
            });
        } else {
            this.hammer = new Hammer(this.pinchArea.nativeElement, {
                // inputClass: MouseInput,
            });
        }

        this.connectEventHandlers();

        this.initialized = true;
    }

    /**
     * Compute the pinch state including the center of the pinch, the pan and
     * scale to be used for actually applying the transformation.
     *
     * @param event The original event from Hammer.
     * @param includePan Should the state include panning?
     */
    private computePinchStateFromEvent(
        event: HammerInput,
        includePan: boolean
    ): PinchPanState {
        // this rect describes the visible viewport (plan view)
        // and stays always at the same place in the ui
        const pinchAreaRect: DOMRect = this.pinchArea.nativeElement.getBoundingClientRect();

        const centerInViewport = PinchPanService.getInitialPositionInViewportCoordinates(
            event,
            pinchAreaRect
        );

        const pinchState: PinchPanState = {
            center: centerInViewport,
            pan: {
                x: includePan ? event.deltaX : 0,
                y: includePan ? event.deltaY : 0,
            },
            scale: event.scale,
            // the viewport's size is the pinch area, because it removes overflow
            // and the size of the translated element (wrapper-translation) is
            // kept the same as the pinch-area, because only the nested elements
            // are scaled
            viewportRect: pinchAreaRect,
        };

        return pinchState;
    }

    /**
     * Compute a simple pinch state only including the given pan movement.
     *
     * @param pan The pan movement in both dimensions.
     */
    private computePinchStateFromPan(event: HammerInput): PinchPanState {
        // see documentation in computePinchStateFromEvent and getInitialPositionInViewportCoordinates
        // for details
        const pinchAreaRect: DOMRect = this.pinchArea.nativeElement.getBoundingClientRect();
        const centerInViewport = PinchPanService.getInitialPositionInViewportCoordinates(
            event,
            pinchAreaRect
        );

        return {
            center: centerInViewport,
            pan: {
                x: event.deltaX,
                y: event.deltaY,
            },
            scale: 1,
            viewportRect: pinchAreaRect,
        };
    }

    /**
     * Set up Hammer's event listeners.
     */
    private connectEventHandlers(): void {
        // Enable pinching and panning at the same time. Otherwise, we can't seem to get from Pan to Pinch
        const pinch = this.hammer.get('pinch');
        const pan = this.hammer.get('pan');
        pinch.recognizeWith(pan);

        pinch.set({ enable: true });

        this.hammer.on('pinchstart', (_event: HammerInput) => {
            console.log('pinchstart');
            this.setIsPinching(true);
        });

        this.hammer.on('pinch', (event: HammerInput) => {
            console.log('pinch');
            const pinchState = this.computePinchStateFromEvent(event, true);
            this._previewPinchPan$.next(pinchState);
        });

        this.hammer.on('pinchend', (event: HammerInput) => {
            console.log('pinchend event');
            const pinchState = this.computePinchStateFromEvent(event, false);
            this._pinchPan$.next(pinchState);
            this.setIsPinching(false);
        });

        this.hammer.on('pinchcancel', (_event: HammerInput) => {
            console.log('pinchcancel');
            this._cancelPreview$.next();
            this.setIsPinching(false);
        });

        pan.set({
            enable: true,
            // override defaults to properly pan
            threshold: 0,
            direction: Hammer.DIRECTION_ALL,
            // TODO: remove comment, because obsolete
            // For multi-touch pan Hammer is firing pinch
            // events, thus it is handled by the pinch event
            // handlers. Additionally Hammer is firing spurious
            // pan events, but not always! Thus pinch event handler
            // has to handle the multi-touch pan to actually get
            // all cases
            // pointers: 1,
        });

        this.hammer.on('panstart', (_event: HammerInput) => {
            console.log('panstart');
            // if (event.pointers.length > 1) {
            //     // TODO
            //     return;
            // }

            this.setIsPanning(true);
        });

        this.hammer.on('pan', (event: HammerInput) => {
            // if (event.pointers.length > 1) {
            //     // TODO
            //     return;
            // }

            // this preview is required if the user is only panning, e.g. with
            // only one finger
            const pinchState = this.computePinchStateFromPan(event);
            this._previewPinchPan$.next(pinchState);
        });

        this.hammer.on('panend', (event: HammerInput) => {
            console.log(
                'panend event',
                event,
                this.pinchArea.nativeElement.getBoundingClientRect()
            );
            // if (event.pointers.length > 1) {
            //     // TODO
            //     return;
            // }

            const pinchState = this.computePinchStateFromPan(event);
            this._pinchPan$.next(pinchState);

            this.setIsPanning(false);
        });

        this.hammer.on('pancancel', (_event: HammerInput) => {
            console.log('pancancel');
            this._cancelPreview$.next();
            this.setIsPanning(false);
        });
    }

    setIsPinching(val: boolean): void {
        this.isPinching = val;
        this._pinchPanInProgress$.next(this.isPinching || this.isPanning);
    }
    setIsPanning(val: boolean): void {
        this.isPanning = val;
        this._pinchPanInProgress$.next(this.isPinching || this.isPanning);
    }

    ngOnDestroy(): void {
        // prevent destroying the Hammer instance twice
        if (this.initialized) {
            this.pinchArea = undefined;
            this.hammer.destroy();
            this.hammer = undefined;
        }

        this._previewPinchPan$.complete();
        this._pinchPan$.complete();
        this._cancelPreview$.complete();
        this._pinchPanInProgress$.complete();
    }
}
