import { RxJsonSchema } from 'rxdb';

export const fileMetadataSchema: RxJsonSchema = {
    title: 'file metadata schema',
    description: 'describes file metadata',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true,
        },
        ownerId: {
            type: ['string', 'null'],
        },
        updaterId: {
            type: ['string', 'null'],
        },
        creatorId: {
            type: ['string', 'null'],
        },
        creationDate: {
            type: ['string', 'null'],
        },
        markedAsDelete: {
            type: ['boolean', 'null'],
        },
        deleteDateTime: {
            type: ['string', 'null'],
        },
        updateDateTime: {
            type: ['string', 'null'],
        },
        customTitle: {
            type: ['string', 'null'],
        },
        fileDescription: {
            type: ['string', 'null'],
        },
        fileName: {
            type: ['string', 'null'],
        },
        fileSize: {
            type: ['number', 'null'],
        },
        contentType: {
            type: ['string', 'null'],
        },
        oldVersions: {
            type: ['array', 'null'],
        },
        documentType: {
            type: ['string', 'null'],
        },
        oldReferences: {
            type: ['object', 'null'],
        },
        // just using 'language' does not work in current RxDB configuration,
        // because this field name is not allowed
        fileLanguage: {
            type: ['string', 'null'],
        },
        references: {
            type: ['array', 'null'],
        },
        projectIdReferences: {
            type: ['object', 'null'],
        },
    },
    indexes: ['id'],
    additionalProperties: false,
    attachments: {
        encrypted: false,
    },
};
