import { Collections, Database } from './database';
import { DB_COLLECTIONS } from './database.collections';

let dbMigrationsRan: { [key in Collections]?: boolean } = {};

export function migrationRanFor(collection: Collections): void {
    dbMigrationsRan[collection] = true;
}

export function clearMigrationsRan(): void {
    dbMigrationsRan = {};
}

function didMigrationRunFor(collection: Collections): boolean {
    return dbMigrationsRan[collection];
}

/**
 * This function runs a fix over all collections for which a migration has run.
 *
 * Due to using PouchDB directly at one place in the application and inserting
 * data ignoring the schema, the data stored in the DB has an additional but
 * required _id attribute.
 *
 * This _id attribute causes issues during migration (see src/app/db/issues/issue.migrations.ts)
 * and is removed temporarily. This function restores this attribute.
 *
 * See `createCollections` in src/app/db/database.service.ts for more details.
 */
export async function fixCollections(db: Database): Promise<void> {
    for (const collection of DB_COLLECTIONS) {
        const fixingNeeded = didMigrationRunFor(collection.name);
        if (!fixingNeeded) {
            continue;
        }

        // get all documents, re-add id attribute, store all documents

        const result = await db[collection.name].pouch.allDocs({
            include_docs: true,
        });
        const docs = result.rows.map(
            (row) =>
                row.doc as {
                    id: string;
                    _id: string;
                }
        );

        const docsWithoutId = docs.filter((doc) => !doc.id);

        if (docsWithoutId.length === 0) {
            continue;
        }

        const docsWithId = docsWithoutId.map((doc) => {
            doc.id = doc._id;
            return doc;
        });

        await db[collection.name].pouch.bulkDocs(docsWithId);
    }
}
