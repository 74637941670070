import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

/**
 * Note: The template will "re-export" the content as a template, bound here to `innerTemplate`
 * This template can be rendered with another template, with `ngTemplateOutlet` set to the `innerTemplate` variable
 */
@Component({
  selector: 'asc-auto-selector-no-match',
  templateUrl: './auto-selector-no-match.component.html',
  styleUrls: ['./auto-selector-no-match.component.scss']
})
export class AutoSelectorNoMatchComponent implements OnInit {

  constructor() { }

  @ViewChild('innerTemplate', {static: true}) 
  public innerTemplate!: TemplateRef<any>;

  ngOnInit(): void {
  }

}
