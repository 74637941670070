import { createSelector } from '@ngrx/store';

import {
    getIssueCommands,
    getPlanCommands,
    getIssuesLoading,
} from '../issues/issues.selectors';
import {
    getDiaryCommands,
    getDiaryEntriesLoading,
} from '../diary/diary.selectors';
import { SyncCommand } from '../../main/sync/models/sync-command';
import {
    getIsSyncingToDb,
    getIsCommandSyncAfterOnlineInProgress,
    getIsSyncing,
} from './sync.selectors';
import { getIsOnline } from '../core/core.selectors';

export const getAllCommands = createSelector(
    getIssueCommands,
    getPlanCommands,
    getDiaryCommands,
    (issueCommands, planCommands, diaryCommands): SyncCommand<any>[] => {
        return [...issueCommands, ...planCommands, ...diaryCommands];
    }
);

export const getAllCommandsLength = createSelector(
    getAllCommands,
    (commands) => commands.length
);

export const getAreAllCommandsLocked = createSelector(
    getAllCommands,
    (commands) => commands.every((command) => command.locked)
);

export const getIsNotDownloading = createSelector(
    getIssuesLoading,
    getDiaryEntriesLoading,
    (issuesLoading, diaryEntriesLoading) =>
        !issuesLoading && !diaryEntriesLoading
);

export const getIsDownloadNotInConflictWithSyncing = createSelector(
    getIsSyncing,
    getIsSyncingToDb,
    getAreAllCommandsLocked,
    (syncing, syncingToDb, allCommandsLocked) => {
        const notSyncing = !syncing;
        const notSyncingToDb = !syncingToDb;

        return notSyncing || (notSyncingToDb && allCommandsLocked);
    }
);

export const getCanPerformSyncAfterOffline = createSelector(
    getIsOnline,
    getIsNotDownloading,
    getIsDownloadNotInConflictWithSyncing,
    getIsCommandSyncAfterOnlineInProgress,
    (
        online,
        notDownloading,
        downloadNotInConflictWithSyncing,
        commandSyncAfterOnlineInProgress
    ) =>
        online &&
        notDownloading &&
        downloadNotInConflictWithSyncing &&
        commandSyncAfterOnlineInProgress
);
