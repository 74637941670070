import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Contact } from 'app/core/rest-api';
import { ContactsActions, ContactsActionTypes } from './contacts.actions';
import { AuthActions, AuthActionTypes } from '../auth/auth.actions';

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
    sortComparer: (a: Contact, b: Contact) => a.name.localeCompare(b.name),
});

export interface ContactsState extends EntityState<Contact> {
    loaded: boolean;
    loading: boolean;

    // information whether the application is currently communicated
    // with the server
    currentlyCreating: number;
    currentlyUpdating: number;
    currentlyDeleting: number;
}

export const initialState: ContactsState = adapter.getInitialState({
    loaded: false,
    loading: false,
    currentlyCreating: 0,
    currentlyUpdating: 0,
    currentlyDeleting: 0,
});

export function reducer(
    state = initialState,
    action: ContactsActions | AuthActions
): ContactsState {
    switch (action.type) {
        case ContactsActionTypes.LOAD_CONTACTS_REQUESTED:
            return { ...state, loading: true };

        case ContactsActionTypes.LOAD_CONTACTS_SUCCESS:
            const loaded = action.payload.contacts?.length > 0;
            return adapter.setAll(action.payload.contacts, {
                ...state,
                loaded,
                loading: false,
            });

        case ContactsActionTypes.LOAD_CONTACTS_ERROR:
            return { ...state, loading: false };

        // a complete registering also changes the contacts by adding a new contact
        case AuthActionTypes.RegisterCompleteRequested:
        case ContactsActionTypes.CREATE_CONTACT_REQUEST:
            return { ...state, currentlyCreating: state.currentlyCreating + 1 };

        // note: this also dispatched if RegisterCompleteRequested is successful
        case ContactsActionTypes.CREATE_CONTACT_SUCCESS:
            return adapter.addOne(action.payload.contact, {
                ...state,
                currentlyCreating: state.currentlyCreating - 1,
            });

        case AuthActionTypes.RegisterCompleteError:
        case ContactsActionTypes.CREATE_CONTACT_ERROR:
            return { ...state, currentlyCreating: state.currentlyCreating - 1 };

        case ContactsActionTypes.UPDATE_CONTACT_REQUEST:
            return { ...state, currentlyUpdating: state.currentlyUpdating + 1 };

        case ContactsActionTypes.UPDATE_CONTACT_SUCCESS:
            return adapter.updateOne(
                {
                    id: action.payload.contact.id,
                    changes: action.payload.contact,
                },
                {
                    ...state,
                    currentlyUpdating: state.currentlyUpdating - 1,
                }
            );

        case ContactsActionTypes.UPDATE_CONTACT_ERROR:
            return {
                ...state,
                currentlyUpdating: state.currentlyUpdating - 1,
            };

        case ContactsActionTypes.DELETE_CONTACT_REQUEST:
            return { ...state, currentlyDeleting: state.currentlyDeleting + 1 };

        case ContactsActionTypes.DELETE_CONTACT_SUCCESS:
            return adapter.removeOne(action.payload.contactId, {
                ...state,
                currentlyDeleting: state.currentlyDeleting - 1,
            });

        case ContactsActionTypes.DELETE_CONTACT_ERROR:
            return {
                ...state,
                currentlyDeleting: state.currentlyDeleting - 1,
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
