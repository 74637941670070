import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {
    Contact,
    FormLetterTemplate,
    ResponseModelPDFFile,
    SerialLetterService,
    StandardSerialLetterService,
    TemplateAttribute,
    TemplateInfo,
} from 'app/core/rest-api';
import { ActivitiesService, IActivity } from 'app/shared/services/activities/activities.service';
import { DeselectLetter, LettersActionTypes } from 'app/store/letters/letters.actions';
import { LettersState } from 'app/store/letters/letters.reducers.ts';
import { getDiaryFormLetters, getIssueFormLetters, getSelectedLetter } from 'app/store/letters/letters.selectors';
import { getCurrentProjectId } from 'app/store/router/router.selectors';
import { getProjectContacts } from 'app/store/settings/settings.selectors';
import { environment } from 'environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { PrintDialogData } from './print-dialog.data';
import { CommonTemplate, isFormLetterTemplateOrPredefined } from './common-template';
import { Actions, ofType } from '@ngrx/effects';
import { ExpandedFormLetterList, PrintStep } from './print-dialog-util';
import { FormLetterService } from 'app/shared/services/form-letter.service';


@Component({
    selector: 'acc-print-dialog',
    templateUrl: './print-dialog.component.html',
    styleUrls: ['./print-dialog.component.scss'],
})
export class PrintDialogComponent implements OnInit, OnDestroy, AfterViewChecked {

    selectedLetter$ = this.store.pipe(select(getSelectedLetter));

    // PRINT DIALOG FIELDS COMP
    printForm: UntypedFormGroup;

    contacts$: Observable<Contact[]>;

    selectedLetter: CommonTemplate;

    unsubscribe$ = new Subject<void>();

    skipStepBaseAttributes = true;
    currentStep: PrintStep = PrintStep.LetterSelection;

    currentExpandedFormLetterList: ExpandedFormLetterList = ExpandedFormLetterList.AllClosed;

    showSaveTemplateSuccess = false;

    private printFormValueChangesSubscription: Subscription | null = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PrintDialogData,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private store: Store<LettersState>,
        private dialogRef: MatDialogRef<PrintDialogComponent>,
        private fb: UntypedFormBuilder,
        private letterService: SerialLetterService,
        private activityService: ActivitiesService,
        private standardSerialLetterService: StandardSerialLetterService,
        private formLetterService: FormLetterService,
        private updates$: Actions,
    ) {
    }



    ngOnInit(): void {
        this.setDefaultExpandedFormLetterList();
        this.selectedLetter$ = this.store.pipe(select(getSelectedLetter));
        this.selectedLetter$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(letter => {
                if (letter) {
                    this.selectedLetter = letter;
                    this.printForm = this.fb.group({});

                    // check if there are any attributes on the first page or if to skip the page
                    this.skipStepBaseAttributes = this.formLetterService.hasNoBaseAttributes(letter);

                    this.printForm.addControl('customTemplateTitle', this.fb.control('', {updateOn: 'blur'}));

                    this.onChanges();
                }
            });

        this.contacts$ = this.store.pipe(select(getProjectContacts));

        this.subscribeToCustomFormLetterSaveSuccess();
    }

    subscribeToCustomFormLetterSaveSuccess(): void{
        this.updates$.pipe(
            ofType(LettersActionTypes.CREATE_LETTER_SUCCESS),
            takeUntil(this.unsubscribe$)
        )
            .subscribe(() => {
                this.showSaveTemplateSuccess = true;
            });
    }

    onChanges(): void {
        // Prevent leaking subscriptions if a new form letter is selected. The
        // final clean up is done by takeUntil.
        if (this.printFormValueChangesSubscription) {
            this.printFormValueChangesSubscription.unsubscribe();
            this.printFormValueChangesSubscription = null;
        }

        this.printFormValueChangesSubscription = this.printForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.showSaveTemplateSuccess = false;
            });
    }


    export(): void {
        const content = this.printForm.value;

        const activity: IActivity = this.activityService.create('FORM_LETTER', {formLetterTitle: this.selectedLetter.title});

        /** This might be called from different sources, i.e., if the API call `meta` field has an error, or if the HTTP fails outright */
        const pdfFailed: (errMsg: string) => void = (errorMessage: string) => {
            this.activityService.extendData(activity, {errorMessage});
            this.activityService.setStatus(activity, 'FAILED');
            this.activityService.addAction(activity, {
                title: 'GENERIC_BUTTONS.OK',
                type: 'CLOSE',
            });
        };

        this.store
            .pipe(
                select(getCurrentProjectId),
                take(1),
                mergeMap((projectId) => {
                    if (isFormLetterTemplateOrPredefined(this.selectedLetter)) { // isFormLetterTemplate
                       return this.createStandardFormletter(projectId, content, this.selectedLetter, this.dialogData.itemIds);
                    }

                    return this.createRTFFormletter(projectId, content, this.dialogData.itemIds);
                }
                ),
                tap({
                    next: json => {
                        const fileId = json.data?.file?.id;
                        if ( fileId ) {
                            this.openFileAndTriggerActivityServiceSuccess(fileId, activity, json);
                        } else {
                            pdfFailed(json.meta.error.customerMessage || json.meta.error.message);
                        }
                    },
                    error: (err: Error&{error: ResponseModelPDFFile}) => pdfFailed(
                        err.error?.meta?.error?.customerMessage || // `error` could contain the HTTP response `ResponseModelPDFFILe`, attempt to extract message
                        err.error?.meta?.error?.message || // Second choice message from HTTP response
                        err.message || // General error?
                        err + '' // Use default string conversion
                    ),
                })
            )
            .subscribe();

        this.dialogRef.close();
    }

    private createStandardFormletter(
        projectId: string,
        content: TemplateInfo['customData'],
        selectedLetter: FormLetterTemplate,
        itemIds: string[]
    ): Observable<ResponseModelPDFFile> {
        const arg: TemplateInfo = {
                templateId: selectedLetter.id,
                ids: itemIds,
                customData: {
                    ...content,
                    Issues: itemIds,
                    InspectionEntry: itemIds,
                },
            };
        return this.standardSerialLetterService.standardserialletterCreatepdfByProjectIdPost(
            projectId,
            arg,
        );
    }
    createRTFFormletter(projectId: string, content: TemplateInfo['customData'], itemIds: string[]): Observable<ResponseModelPDFFile>{


        return this.letterService.serialletterCreatepdfByProjectIdByTemplateIdPost(
            projectId,
            this.selectedLetter.id,
            false,
            {
                customData: {
                    ...content,
                    Issues: itemIds,
                    // TODO: fill only one of them!
                    InspectionEntry: itemIds,
                    Project: projectId as any
                }
            }
        );
    }

    openFileAndTriggerActivityServiceSuccess(fileId, activity, json): void {
        const url = `${environment.endpoints.api}/files/direct/${fileId}`;
        // Download the file in a new window. This will probably be caught by a pop-up blocker
        window.open(url, '_blank');
        this.activityService.setStatus(activity, 'SUCCESS');
        this.activityService.addAction(activity, {
            title: 'GENERIC_BUTTONS.OK',
            type: 'CLOSE',
        });
        this.activityService.addAction(activity, {
            title: 'APP.DOWNLOAD',
            type: 'HREF',
            url,
            filename: json.data.file.fileName,
            icon: 'file_download',
        });
    }

    setDefaultExpandedFormLetterList(): void {
        // if user has custom formLetter Templates, expand these when opening the dialog
        // else expand predefinedTemplates
        let selector: typeof getIssueFormLetters | typeof getDiaryFormLetters;
        if (this.dialogData.purpose === 'Issues') {
            selector = getIssueFormLetters;
        } else if (this.dialogData.purpose === 'DailyLog') {
            selector = getDiaryFormLetters;
        } else {
            console.warn(`purpose of dialog is set to unsupported '${this.dialogData.purpose}'`);
            this.currentExpandedFormLetterList = ExpandedFormLetterList.PredefinedFormLetters;
            return;
        }

        this.store
            .pipe(
                take(1),
                select(selector),
                tap(letters => {
                    if  (letters.length > 0) {
                        this.currentExpandedFormLetterList = ExpandedFormLetterList.FormLetters;
                    } else {
                        this.currentExpandedFormLetterList = ExpandedFormLetterList.PredefinedFormLetters;
                    }
                })
            )
            .subscribe();
    }


    public get printStep(): typeof PrintStep {
        return PrintStep;
    }

    continueToForm(): void {
        if (this.selectedLetter) {
            if (!this.skipStepBaseAttributes) {
                this.currentStep = PrintStep.EnterBaseAttributes;
            } else { // skip page1, if there are no attributes for that page
                this.currentStep = PrintStep.FinalPage;
            }
        }
    }

    continueToFinalPage(): void {
        this.currentStep = PrintStep.FinalPage;
    }

    goBack(): void {
        if (this.currentStep === PrintStep.FinalPage
            && !this.skipStepBaseAttributes
        ) {
            this.currentStep = PrintStep.EnterBaseAttributes;

        } else { // back to letter selection, either from page 1 "EnterBaseAttributes"
                // or from final page 2, if page 1 is skipped
            this.currentStep = PrintStep.LetterSelection;

            // reset values to initial state
            this.showSaveTemplateSuccess = false;
            this.skipStepBaseAttributes = true;
            this.store.dispatch(new DeselectLetter());
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        // do not remove
        this.store.dispatch(new DeselectLetter());
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
