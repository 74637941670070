import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GlobalBoilerplate } from 'app/core/rest-api';
import {
    GlobalBoilerplatesActions,
    GlobalBoilerplatesActionTypes,
} from './global-boilerplates.actions';

export const adapter: EntityAdapter<GlobalBoilerplate> = createEntityAdapter<
    GlobalBoilerplate
>({
    sortComparer: sortByName,
});

export function sortByName(a: GlobalBoilerplate, b: GlobalBoilerplate): number {
    return a.title.localeCompare(b.title);
}

export interface GlobalBoilerplatesState
    extends EntityState<GlobalBoilerplate> {
    loading: boolean;
    loaded: boolean;
    selectedBoilerplatesId: string[];
}

export const initialState: GlobalBoilerplatesState = adapter.getInitialState({
    loading: false,
    loaded: false,
    selectedBoilerplatesId: [],
});

export function reducer(
    state = initialState,
    action: GlobalBoilerplatesActions
): GlobalBoilerplatesState {
    switch (action.type) {
        case GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_REQUESTED:
            return { loading: true, loaded: false, ...state };

        case GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_SUCCESS: {
            return adapter.setAll(action.payload.boilerplates, {
                ...state,
                selectedBoilerplatesId: [...state.selectedBoilerplatesId],
                loaded: true,
            });
            // return { ...state, loaded: true };
        }

        case GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_ERROR:
            return { ...state, loading: false };

        case GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_REQUEST:
            return { ...state };

        case GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_SUCCESS:
            return adapter.addOne(action.payload.boilerplate, {
                ...state,
                selectedBoilerplatesId: state.selectedBoilerplatesId.slice(),
            });

        case GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_ERROR:
            return { ...state };

        case GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_REQUEST:
            return { ...state };

        case GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_SUCCESS:
            return adapter.updateOne(action.payload.update, {
                ...state,
                selectedBoilerplatesId: state.selectedBoilerplatesId.slice(),
            });

        case GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_ERROR:
            return { ...state };

        case GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_REQUEST:
            return { ...state };

        case GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_SUCCESS:
            const removedItems = selectAll(state).filter(
                (item) =>
                    item.id === action.payload.boilerplate.id ||
                    item.parentID === action.payload.boilerplate.id
            );
            const removedItemsIds = removedItems.map((item) => item.id);

            const deleteBoilerplateResult = removeSelectedBoilerPlate(
                state,
                action.payload.boilerplate.id
            );
            return adapter.removeMany([...removedItemsIds], {
                ...state,
                selectedBoilerplatesId: [...deleteBoilerplateResult],
            });

        case GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_ERROR:
            return { ...state };

        case GlobalBoilerplatesActionTypes.ADD_SELECTED_GLOBAL_CRAFT:
            return {
                ...state,
                selectedBoilerplatesId: [action.payload.boilerplate.id],
            };

        case GlobalBoilerplatesActionTypes.REMOVE_SELECTED_GLOBAL_CRAFT:
            return {
                ...state,
                selectedBoilerplatesId: [],
            };

        case GlobalBoilerplatesActionTypes.ADD_SELECTED_GLOBAL_BOILERPLATE:
            const parentIndex = state.selectedBoilerplatesId.findIndex(
                (item) => item === action.payload.boilerplate.parentID
            );

            const addBoilerPlateResult = removeSelectedBoilerplatesAfterIndex(
                state.selectedBoilerplatesId,
                parentIndex
            );

            addBoilerPlateResult.push(action.payload.boilerplate.id);

            return {
                ...state,
                selectedBoilerplatesId: addBoilerPlateResult,
            };

        case GlobalBoilerplatesActionTypes.REMOVE_SELECTED_GLOBAL_BOILERPLATE:
            const removeBoilerplateResult = removeSelectedBoilerPlate(
                state,
                action.payload.boilerplate.id
            );

            return {
                ...state,
                selectedBoilerplatesId: [...removeBoilerplateResult],
            };

        default:
            return state;
    }
}

function removeSelectedBoilerPlate(state, boilerplateId): string[] {
    const boilerplateIndex = state.selectedBoilerplatesId.findIndex(
        (item) => item === boilerplateId
    );

    const removeBoilerPlateResult = removeSelectedBoilerplatesAfterIndex(
        state.selectedBoilerplatesId,
        boilerplateIndex - 1
    );

    return removeBoilerPlateResult;
}

function removeSelectedBoilerplatesAfterIndex(
    selectedBoilerplatesId: string[],
    index: number
): string[] {
    let resultList: string[] = selectedBoilerplatesId.slice();

    if (index >= 0) {
        resultList = selectedBoilerplatesId.filter(
            (value, listIndex) => listIndex <= index
        );
    }

    return resultList;
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
