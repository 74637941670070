import { InjectionToken } from '@angular/core';

import { SafeName } from './cordova-plugin-file.utils';

export const ATTACHMENT_DIRECTORY = new InjectionToken<SafeName>(
    'AttachmentDirectory'
);

export const ATTACHMENT_THUMBNAIL_CONTENT_TYPE = new InjectionToken<string>(
    'AttachmentThumbnailContentType'
);
