import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { unreachable_safe } from 'app/core/utils/ts-utils';
import { IActivity, IActivityAction } from 'app/shared/services/activities/activities.service';



const animationDurationMs = 500;

@Component({
    selector: 'acc-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    animations: [
    trigger('openClose', [
      state('open', style({
        'max-height': 'initial',
        'min-height': '6em',
        opacity: 1,
      })),
      state('closed', style({
        'max-height': '0px',
        'min-height': '0px',
        opacity: 0,
      })),
      transition('open => closed', [
        animate(animationDurationMs)
      ]),
      transition('closed => open', [
        animate(animationDurationMs)
      ]),
    ]),
  ],

})
export class ActivityComponent implements OnInit {
    @Input()
    activity: IActivity;

    /**
     * Used to signal that the (inputed) activity should be removed
     */
    @Output()
    requestDelete: EventEmitter<IActivity> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    clickClose(): void {
        this.activity.show = false;
        // Wait for animations to finish before deleting the activity
        setTimeout( () => {
            this.requestDelete.emit(this.activity);
        }, animationDurationMs);
    }

    activateAction(action: IActivityAction): void {
        if (action.type === 'HREF') {
            // Handled as link
        } else if (action.type === 'CLOSE') {
            this.clickClose();
        } else {
            unreachable_safe(action.type);
        }
    }

}
