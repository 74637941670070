import { Component, OnInit, Input } from '@angular/core';
import { FilterByAutoSelector } from './filter-by-auto-selector.model';

@Component({
    selector: 'acc-filter-by-auto-selector',
    templateUrl: './filter-by-auto-selector.component.html',
    styleUrls: ['./filter-by-auto-selector.component.scss']
})
export class FilterByAutoSelectorComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() filterItem: FilterByAutoSelector;
}
