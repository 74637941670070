import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SetSearch } from 'app/store/core/core.actions';
import { CoreState } from 'app/store/core/core.reducer';
import { getSearch } from 'app/store/core/core.selectors';
import { Observable } from 'rxjs';

@Component({
    selector: 'acc-search-dialog',
    templateUrl: 'search-dialog.component.html',
    styleUrls: ['search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {
    search$: Observable<string>;

    constructor(private store: Store<CoreState>) {}

    ngOnInit(): void {
        this.search$ = this.store.pipe(select(getSearch));
    }

    search(search: string): void {
        this.store.dispatch(new SetSearch({ search }));
    }

    resetSearch(): void {
        this.store.dispatch(new SetSearch({ search: null }));
    }
}
