/**
 * eTeacher API
 * Last Build: 02/08/2022 10:21:42 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TemplateInfo { 
    /**
     * FormLetterTemplate or PredefinedFormLetterTemplate
     */
    templateId?: string;
    ids?: Array<string>;
    customData?: { [key: string]: object; };
}

