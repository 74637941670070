import { Action } from '@ngrx/store';
import { Location as FlinkLocation, Location } from 'app/core/rest-api';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { LocationDocType } from 'app/db/locations/location.document';
import { DeleteCommandError } from '../sync/sync.actions';

export enum LocationsActionTypes {
    LOAD_LOCATIONS_REQUESTED = '[Locations] Load Locations Requested',
    LOAD_LOCATIONS_SUCCESS = '[API] Load Locations Success',
    LOAD_LOCATIONS_ERROR = '[API] Load Locations Error',

    CREATE_LOCATION_REQUESTED = '[Locations] Create Location Requested',
    CREATE_LOCATION_SUCCESS = '[API] Create Location Success',
    CREATE_LOCATION_ERROR = '[API] Create Location Error',

    // LOCATIONS
    UPSERT_LOCATIONS_START = '[APP] Upsert Locations Start',
    UPSERT_LOCATIONS_FINISH = '[DB] Upsert Locations Finish',

    UPDATE_LOCATION_REQUESTED = '[Locations] Update Location Requested',
    UPDATE_LOCATION_SUCCESS = '[API] Update Location Success',
    UPDATE_LOCATION_ERROR = '[API] Update Location Error',

    DELETE_LOCATION_REQUESTED = '[Locations] Delete Location Requested',
    DELETE_LOCATION_SUCCESS = '[API] Delete Location Success',
    DELETE_LOCATION_ERROR = '[API] Delete Location Error',

    CLEAN_UP_TEMP_ENTRIES = '[Locations] Clean Up Temp Entries',
}

export class UpsertLocationsStart implements Action {
    readonly type = LocationsActionTypes.UPSERT_LOCATIONS_START;
    constructor(public payload: { locations: FlinkLocation[] }) {}
}

export class UpsertLocationsFinish implements Action {
    readonly type = LocationsActionTypes.UPSERT_LOCATIONS_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<LocationDocType> }
    ) {}
}

export class LoadLocationsRequested implements Action {
    readonly type = LocationsActionTypes.LOAD_LOCATIONS_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadLocationsSuccess implements Action {
    readonly type = LocationsActionTypes.LOAD_LOCATIONS_SUCCESS;
    constructor(
        public payload: {
            locations: FlinkLocation[];
            fromDb: boolean;
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadLocationsError implements Action {
    readonly type = LocationsActionTypes.LOAD_LOCATIONS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateLocationRequest implements Action {
    readonly type = LocationsActionTypes.CREATE_LOCATION_REQUESTED;
    constructor(
        public payload: {
            projectId: string;
            location: Location;
            tmpLocationId?: string;
        }
    ) {}
}

export class CreateLocationSuccess implements Action {
    readonly type = LocationsActionTypes.CREATE_LOCATION_SUCCESS;
    constructor(
        public payload: { location: Location; tmpLocationId?: string }
    ) {}
}

export class CreateLocationError implements Action {
    readonly type = LocationsActionTypes.CREATE_LOCATION_ERROR;
    constructor(public payload: { error: any }) {}
}

export class UpdateLocationRequested implements Action {
    readonly type = LocationsActionTypes.UPDATE_LOCATION_REQUESTED;
    constructor(public payload: { projectId: string; location: Location }) {}
}

export class UpdateLocationSuccess implements Action {
    readonly type = LocationsActionTypes.UPDATE_LOCATION_SUCCESS;
    constructor(public payload: { location: Location }) {}
}

export class UpdateLocationError implements Action {
    readonly type = LocationsActionTypes.UPDATE_LOCATION_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteLocationRequested implements Action {
    readonly type = LocationsActionTypes.DELETE_LOCATION_REQUESTED;
    constructor(public payload: { location: Location }) {}
}

export class DeleteLocationSuccess implements Action {
    readonly type = LocationsActionTypes.DELETE_LOCATION_SUCCESS;
    constructor(public payload: { location: Location }) {}
}

export class DeleteLocationError implements Action {
    readonly type = LocationsActionTypes.DELETE_LOCATION_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CleanUpTempEntries implements Action {
    readonly type = LocationsActionTypes.CLEAN_UP_TEMP_ENTRIES;
}

export type LocationsActions =
    | LoadLocationsRequested
    | LoadLocationsSuccess
    | LoadLocationsError
    | CreateLocationRequest
    | CreateLocationSuccess
    | CreateLocationError
    | UpdateLocationRequested
    | UpdateLocationSuccess
    | UpdateLocationError
    | UpsertLocationsStart
    | UpsertLocationsFinish
    | DeleteLocationRequested
    | DeleteLocationSuccess
    | DeleteCommandError
    | CleanUpTempEntries;
