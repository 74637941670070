import { CoreActions, CoreActionTypes, SetNetworkStatus } from './core.actions';
import { environment } from 'environments/environment';
import { ConnectionStatus } from '@capacitor/network';

export interface CoreState {
    search: string;
    language: string;
    isDevice: boolean;
    networkStatus: ConnectionStatus;
}

export const initialState: CoreState = {
    search: '',
    networkStatus: { connected: true, connectionType: 'wifi' },
    isDevice: false,
    language: environment.defaults.language
};

export function reducer(state = initialState, action: CoreActions): CoreState {
    switch (action.type) {
        case CoreActionTypes.SetSearch:
            return {
                ...state,
                search: action.payload.search
            };

        case CoreActionTypes.SetLanguage:
            return {
                ...state,
                language: action.payload.language
            };

        case CoreActionTypes.SetNetworkStatus:
            return {
                ...state,
                networkStatus: action.payload.status
            };

        case CoreActionTypes.SetIsDevice:
            return {
                ...state,
                isDevice: action.payload.isDevice
            };

        default:
            return state;
    }
}
