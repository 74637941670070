import { Collections } from '../database';
import { migrationRanFor } from '../fix-collections.util';
import {
    IssueDocTypeV0,
    IssueDocTypeV1,
    IssueDocTypeV2,
    IssueDocTypeV3,
} from './issue.document';

function addCustomAttributes(oldDoc: IssueDocTypeV0): IssueDocTypeV1 {
    const newDoc: IssueDocTypeV1 = {
        ...oldDoc,
        customAttributes: [],
    };

    // this attribute was added with PouchDB without
    // being compatible to the schema
    delete (newDoc as any)._id;

    // deleting the above also deletes the id attribute
    // when PouchDB is used directly to get the documents,
    // thus mark, that migrations ran and some fixing is
    // needed for this
    migrationRanFor(Collections.Issues);

    return newDoc;
}

function addChecklists(oldDoc: IssueDocTypeV1): IssueDocTypeV2 {
    const newDoc: IssueDocTypeV2 = {
        ...oldDoc,
        checklists: [],
    };

    // note: see previous migration
    delete (newDoc as any)._id;
    migrationRanFor(Collections.Issues);

    return newDoc;
}

function addFreetextAsBoilerplates(oldDoc: IssueDocTypeV2): IssueDocTypeV3 {
    const newDoc: IssueDocTypeV3 = {
        ...oldDoc,
        // explicitly setting null to be able to check against
        // new issues better
        isUsingFreetextAsBoilerplate: null,
        freetext: null,
    };

    // note: see first migration
    delete (newDoc as any)._id;
    migrationRanFor(Collections.Issues);

    return newDoc;
}

export const issueMigrations = {
    1: addCustomAttributes,
    2: addChecklists,
    3: addFreetextAsBoilerplates,
};
