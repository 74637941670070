import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';

import { FormLetterTemplate, PredefinedFormLetterTemplate } from 'app/core/rest-api';
import { FormLetterService } from 'app/shared/services/form-letter.service';
import { SelectLetter } from 'app/store/letters/letters.actions';
import { LettersState } from 'app/store/letters/letters.reducers.ts';
import {
    getDiaryFormLetters,
    getDiaryPredefinedFormLetters,
    getDiaryRTFLetters,
    getIssueFormLetters,
    getIssuePredefinedFormLetters,
    getIssueRTFLetters,
    getSelectedLetter,
} from 'app/store/letters/letters.selectors';

import { CommonTemplate, NewFormLetterTemplate } from '../common-template';

// TODO: proper type
interface Selectors {
    getPredefinedFormletters: any;
    getFormletters: any;
    getRTFLetters: any;
}

const issueSelectors: Selectors = {
    getPredefinedFormletters: getIssuePredefinedFormLetters,
    getFormletters: getIssueFormLetters,
    getRTFLetters: getIssueRTFLetters,
};

const diarySelectors: Selectors = {
    getPredefinedFormletters: getDiaryPredefinedFormLetters,
    getFormletters: getDiaryFormLetters,
    getRTFLetters: getDiaryRTFLetters,
};

@Component({
  selector: 'acc-print-dialog-list',
  templateUrl: './print-dialog-list.component.html',
  styleUrls: ['./print-dialog-list.component.scss']
})
export class PrintDialogListComponent implements OnInit, OnDestroy {

    selectedLetter$: Observable<CommonTemplate>;

    // TODO: extract this purpose type to somewhere
    @Input()
    purpose: 'Issues' | 'DailyLog' | 'InspectionEntryLock';

    @Input()
    templateType: string;

    issueLettersData: MatTableDataSource<CommonTemplate>;
    @ViewChild('issueTable', { static: true })
    issueSort: MatSort;
    displayedColumns: string[] = ['title' , 'edit'];
    selectedRowIndex = -1;

    unsubscribe$ = new Subject<void>();


    constructor(
        private letterStore: Store<LettersState>,
        private formLetterService: FormLetterService
    ){}

    ngOnInit(): void {
        this.selectedLetter$ = this.letterStore.pipe(select(getSelectedLetter));

        let selectors: Selectors;
        if (this.purpose === 'Issues') {
            selectors = issueSelectors;
        } else if (this.purpose === 'DailyLog') {
            selectors = diarySelectors;
        } else {
            // TODO: proper error
            throw new Error(`unsupported purpose: ${this.purpose}`);
        }

        let myselector;
        if (this.templateType === 'PredefinedFormLetterTemplate') {
            // TODO: check if we need tto filter for issue vs diary with
            //  this.predefinedFormLetterTemplateService.predefinedformlettertemplatePredefinedFormLetterTemplatesGet(this.dialogData.purpose)
            myselector = selectors.getPredefinedFormletters;
        } else if (this.templateType === 'FormLetterTemplate') {
            myselector = selectors.getFormletters;
        } else {
            myselector = selectors.getRTFLetters;
        }

        this.letterStore
            .pipe(
                takeUntil(this.unsubscribe$),
                select(myselector),
                tap(letters => {
                    this.issueLettersData = new MatTableDataSource<CommonTemplate>(letters);
                })
            )
            .subscribe();

    }


    selectLetter(letter: CommonTemplate): void {
        this.letterStore.dispatch(new SelectLetter({ letter: letter }));
    }

    onOpenPreview(letter: NewFormLetterTemplate): void {
        this.formLetterService.openPreview(letter);
    }

    deleteFormLetter(template: NewFormLetterTemplate): void {
        this.formLetterService.deleteWithConfirmation(template);
    }

    highlight(row): void{
        this.selectedRowIndex = row.id;
    }

    ngOnDestroy(): void {
        // do not remove
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
