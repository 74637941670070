import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { CustomerCraftsService } from '../../core/rest-api';

import { Craft } from './craft';
import { CoreState } from '../core/core.state';
import { getIsDevice, getIsOffline } from '../core/core.selectors';

const CUSTOMER_CRAFTS_STORAGE_KEY = 'customer-crafts';

@Injectable({
    providedIn: 'root',
})
export class CraftsService {
    constructor(
        private translate: TranslateService,
        private customerCraftsService: CustomerCraftsService,
        private store: Store<CoreState>
    ) {}

    getCraftsLocal(): Observable<Craft[]> {
        return this.translate.get('CRAFTS').pipe(
            map((craftsObj) => {
                const crafts: Craft[] = [];
                for (const [key, value] of Object.entries(craftsObj)) {
                    crafts.push({
                        id: parseInt(key, 10),
                        title: value.toString(),
                    });
                }
                return crafts;
            })
        );
    }

    getCustomerCrafts(): Observable<Craft[]> {
        const craftsFromServer$ = this.customerCraftsService
            .customercraftsGet()
            .pipe(
                map((resp): Craft[] => {
                    return resp.data.map(
                        (customerCraft): Craft => {
                            return {
                                id: customerCraft.number,
                                title: customerCraft.title,
                            };
                        }
                    );
                }),
                mergeMap((crafts) => {
                    return from(
                      Preferences.set({
                            key: CUSTOMER_CRAFTS_STORAGE_KEY,
                            value: JSON.stringify(crafts),
                        })
                    ).pipe(map(() => crafts));
                })
            );

        const craftsFromLocalStorage$ = from(
          Preferences.get({
                key: CUSTOMER_CRAFTS_STORAGE_KEY,
            })
        ).pipe(
            map((rawStorageCrafts): Craft[] => {
                if (
                    !rawStorageCrafts ||
                    !rawStorageCrafts.value ||
                    rawStorageCrafts.value.length === 0
                ) {
                    return [];
                }

                return JSON.parse(rawStorageCrafts.value);
            })
        );

        return this.store.pipe(
            select(getIsDevice),
            withLatestFrom(this.store.pipe(select(getIsOffline))),
            mergeMap(([isDevice, isOffline]) => {
                // on web or online
                if (!isDevice || !isOffline) {
                    return craftsFromServer$;
                }

                return craftsFromLocalStorage$;
            }),
            take(1)
        );
    }

    getCraftsLocalByIds(craftIds: number[]): Observable<Craft[]> {
        return this.translate.get('CRAFTS').pipe(
            map((craftsObj) => {
                const crafts: Craft[] = [];
                for (const [key, value] of Object.entries(craftsObj)) {
                    const id = parseInt(key, 10);
                    if (craftIds.includes(id) || id === 9999) {
                        crafts.push({
                            id,
                            title: value.toString(),
                        });
                    }
                }
                return crafts;
            })
        );
    }
}
