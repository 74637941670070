import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChecklistsEffects } from './checklists.effects';
import * as checklists from './checklists.reducer';

@NgModule({
    declarations: [],
    imports: [
        MatSnackBarModule,
        StoreModule.forFeature('checklists', checklists.reducer),
        EffectsModule.forFeature([ChecklistsEffects])
    ]
})
export class ChecklistStoreModule {}
