/**
 * eTeacher API
 * Last Build: 02/08/2022 10:21:42 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ProblemDetails } from '../model/models';
import { RTFTemplate } from '../model/models';
import { ResponseModelListPDFFile } from '../model/models';
import { ResponseModelListRTFTemplate } from '../model/models';
import { ResponseModelPDFFile } from '../model/models';
import { ResponseModelRTFTemplate } from '../model/models';
import { SerialLetterPostData } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SerialLetterService {

    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param projectId 
     * @param templateId 
     * @param postData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectByProjectIdSerialletterByTemplateIdCreatertfPost(projectId: string, templateId: string, postData?: SerialLetterPostData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelPDFFile>;
    public projectByProjectIdSerialletterByTemplateIdCreatertfPost(projectId: string, templateId: string, postData?: SerialLetterPostData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelPDFFile>>;
    public projectByProjectIdSerialletterByTemplateIdCreatertfPost(projectId: string, templateId: string, postData?: SerialLetterPostData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelPDFFile>>;
    public projectByProjectIdSerialletterByTemplateIdCreatertfPost(projectId: string, templateId: string, postData?: SerialLetterPostData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectByProjectIdSerialletterByTemplateIdCreatertfPost.');
        }
        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectByProjectIdSerialletterByTemplateIdCreatertfPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelPDFFile>(`${this.configuration.basePath}/project/${encodeURIComponent(String(projectId))}/serialletter/${encodeURIComponent(String(templateId))}/creatertf`,
            postData,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param projectId 
     * @param rtfAvailableContext 
     * @param postData 
     * @param shouldReturnPdf 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectByProjectIdSerialletterCreatedocfromfilePost(projectId: string, rtfAvailableContext?: object, postData?: object, shouldReturnPdf?: boolean, files?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelPDFFile>;
    public projectByProjectIdSerialletterCreatedocfromfilePost(projectId: string, rtfAvailableContext?: object, postData?: object, shouldReturnPdf?: boolean, files?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelPDFFile>>;
    public projectByProjectIdSerialletterCreatedocfromfilePost(projectId: string, rtfAvailableContext?: object, postData?: object, shouldReturnPdf?: boolean, files?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelPDFFile>>;
    public projectByProjectIdSerialletterCreatedocfromfilePost(projectId: string, rtfAvailableContext?: object, postData?: object, shouldReturnPdf?: boolean, files?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling projectByProjectIdSerialletterCreatedocfromfilePost.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (rtfAvailableContext !== undefined && rtfAvailableContext !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rtfAvailableContext, 'rtfAvailableContext');
        }
        if (postData !== undefined && postData !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>postData, 'postData');
        }
        if (shouldReturnPdf !== undefined && shouldReturnPdf !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shouldReturnPdf, 'shouldReturnPdf');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (files !== undefined) {
            formParams = formParams.append('files', <any>files) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelPDFFile>(`${this.configuration.basePath}/project/${encodeURIComponent(String(projectId))}/serialletter/createdocfromfile`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param letterid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterByLetteridDelete(letterid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelRTFTemplate>;
    public serialletterByLetteridDelete(letterid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelRTFTemplate>>;
    public serialletterByLetteridDelete(letterid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelRTFTemplate>>;
    public serialletterByLetteridDelete(letterid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {
        if (letterid === null || letterid === undefined) {
            throw new Error('Required parameter letterid was null or undefined when calling serialletterByLetteridDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<ResponseModelRTFTemplate>(`${this.configuration.basePath}/serialletter/${encodeURIComponent(String(letterid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterCreatedPdfsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelListPDFFile>;
    public serialletterCreatedPdfsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelListPDFFile>>;
    public serialletterCreatedPdfsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelListPDFFile>>;
    public serialletterCreatedPdfsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListPDFFile>(`${this.configuration.basePath}/serialletter/created/pdfs`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterCreatedRtfsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelListPDFFile>;
    public serialletterCreatedRtfsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelListPDFFile>>;
    public serialletterCreatedRtfsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelListPDFFile>>;
    public serialletterCreatedRtfsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListPDFFile>(`${this.configuration.basePath}/serialletter/created/rtfs`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param projectId 
     * @param templateId 
     * @param runAsync 
     * @param postData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterCreatepdfByProjectIdByTemplateIdPost(projectId: string, templateId: string, runAsync?: boolean, postData?: SerialLetterPostData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelPDFFile>;
    public serialletterCreatepdfByProjectIdByTemplateIdPost(projectId: string, templateId: string, runAsync?: boolean, postData?: SerialLetterPostData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelPDFFile>>;
    public serialletterCreatepdfByProjectIdByTemplateIdPost(projectId: string, templateId: string, runAsync?: boolean, postData?: SerialLetterPostData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelPDFFile>>;
    public serialletterCreatepdfByProjectIdByTemplateIdPost(projectId: string, templateId: string, runAsync?: boolean, postData?: SerialLetterPostData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling serialletterCreatepdfByProjectIdByTemplateIdPost.');
        }
        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling serialletterCreatepdfByProjectIdByTemplateIdPost.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (runAsync !== undefined && runAsync !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>runAsync, 'runAsync');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelPDFFile>(`${this.configuration.basePath}/serialletter/createpdf/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(templateId))}`,
            postData,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelListRTFTemplate>;
    public serialletterGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelListRTFTemplate>>;
    public serialletterGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelListRTFTemplate>>;
    public serialletterGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListRTFTemplate>(`${this.configuration.basePath}/serialletter`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param template 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterPatch(template?: RTFTemplate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelRTFTemplate>;
    public serialletterPatch(template?: RTFTemplate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelRTFTemplate>>;
    public serialletterPatch(template?: RTFTemplate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelRTFTemplate>>;
    public serialletterPatch(template?: RTFTemplate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelRTFTemplate>(`${this.configuration.basePath}/serialletter`,
            template,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pdfId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterPdfByPdfIdGet(pdfId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelPDFFile>;
    public serialletterPdfByPdfIdGet(pdfId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelPDFFile>>;
    public serialletterPdfByPdfIdGet(pdfId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelPDFFile>>;
    public serialletterPdfByPdfIdGet(pdfId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {
        if (pdfId === null || pdfId === undefined) {
            throw new Error('Required parameter pdfId was null or undefined when calling serialletterPdfByPdfIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelPDFFile>(`${this.configuration.basePath}/serialletter/pdf/${encodeURIComponent(String(pdfId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param files 
     * @param purpose 
     * @param customKeyValuesPairsAsJsonString 
     * @param customtitle 
     * @param filedescription 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serialletterPost(files?: Blob, purpose?: string, customKeyValuesPairsAsJsonString?: string, customtitle?: string, filedescription?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<ResponseModelRTFTemplate>;
    public serialletterPost(files?: Blob, purpose?: string, customKeyValuesPairsAsJsonString?: string, customtitle?: string, filedescription?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpResponse<ResponseModelRTFTemplate>>;
    public serialletterPost(files?: Blob, purpose?: string, customKeyValuesPairsAsJsonString?: string, customtitle?: string, filedescription?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<HttpEvent<ResponseModelRTFTemplate>>;
    public serialletterPost(files?: Blob, purpose?: string, customKeyValuesPairsAsJsonString?: string, customtitle?: string, filedescription?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'text/csv' | 'application/xml' | 'text/xml'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (files !== undefined) {
            formParams = formParams.append('files', <any>files) as any || formParams;
        }
        if (purpose !== undefined) {
            formParams = formParams.append('purpose', <any>purpose) as any || formParams;
        }
        if (customKeyValuesPairsAsJsonString !== undefined) {
            formParams = formParams.append('customKeyValuesPairsAsJsonString', <any>customKeyValuesPairsAsJsonString) as any || formParams;
        }
        if (customtitle !== undefined) {
            formParams = formParams.append('customtitle', <any>customtitle) as any || formParams;
        }
        if (filedescription !== undefined) {
            formParams = formParams.append('filedescription', <any>filedescription) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelRTFTemplate>(`${this.configuration.basePath}/serialletter`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
