import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { mergeMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';

import { ErrorUtils } from 'app/core/utils/error-util';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import {
    IssuesActionTypes,
    SetIssueIdOfPlanCommandsInDbError,
    SetIssueIdOfPlanCommandsInDbRequest,
    SetIssueIdOfPlanCommandsInDbSuccess,
} from './issues.actions';
import { select, Store } from '@ngrx/store';
import { CoreState } from '../core/core.reducer';
import { getIsOffline } from '../core/core.selectors';

@Injectable()
export class IssueIdUpdateEffects {
    @Effect()
    setIssueIdOfPlanCommandsRequest$ = this.actions$.pipe(
        ofType<SetIssueIdOfPlanCommandsInDbRequest>(
            IssuesActionTypes.SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_REQUEST
        ),
        // note: Make sure not to add any waiting before setIssueIdOfPlanCommands
        // is called so that it can start running before the effect
        // SyncIssueCommands runs. This ensures that the plan commands can be
        // properly updated before the command deletion logic runs.
        mergeMap((action) => {
            const { issueId, planCommands } = action.payload;
            return from(
                this.commandDbService.setIssueIdOfPlanCommandsWithoutOne(
                    issueId,
                    planCommands
                )
            ).pipe(
                map(() => new SetIssueIdOfPlanCommandsInDbSuccess()),
                catchError((error) =>
                    of(
                        new SetIssueIdOfPlanCommandsInDbError({
                            error,
                        })
                    )
                )
            );
        })
    );

    @Effect({ dispatch: false })
    setIssueIdOfPlanCommandsError$ = this.actions$.pipe(
        ofType<SetIssueIdOfPlanCommandsInDbError>(
            IssuesActionTypes.SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_ERROR
        ),
        withLatestFrom(this.store.pipe(select(getIsOffline))),
        tap(([action, isOffline]) => {
            console.error(
                'set issue id of plan commands error',
                action.payload.error
            );

            if (isOffline) {
                // plan changes could be lost, thus inform the user to go online before application restart
                this.errorUtils.showSingleMessageOrDefault(
                    null,
                    'ISSUES.UPDATE_ISSUE_ID_OF_COMMANDS_IN_DB',
                    {
                        autohide: false,
                    }
                );
            }
        })
    );

    constructor(
        private actions$: Actions,
        private store: Store<CoreState>,
        private commandDbService: CommandDbService<PlanCommand>,
        private errorUtils: ErrorUtils
    ) {}
}
