import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { MarkedPlan, Issue } from 'app/core/rest-api';
import { select, Store } from '@ngrx/store';
import { IssuesState } from 'app/store/issues/issues.reducer';
import { getIssueById } from 'app/store/issues/issues.selectors';
import { LoadPlanRevisionsRequest } from 'app/store/issues/issues.actions';
import { getIsOffline } from 'app/store/core/core.selectors';
import { first, tap } from 'rxjs/operators';

export interface PlanPreviewDialogData {
    // as parameter to initialDrawPlan
    documentPath: string;
    // set isPDF attribute
    isPdf: boolean;
    // used for the plan which is returned when saving changes (explicit / not discarding) is selected
    planId: string;
    // unsaved marked plan changes (i.e. issue is not saved yet)
    // TODO: make this optional
    markedPlanChanges: Observable<Partial<MarkedPlan>>;
    // its id is used in getCurrentIssueById
    issue: Issue;
}

@Component({
    selector: 'acc-plan-preview-dialog',
    templateUrl: 'plan-preview-dialog.component.html',
    styleUrls: ['./plan-preview-dialog.component.scss'],
})
export class PlanPreviewDialogComponent implements OnInit {
    // get issue from store to get fetched revisions as well
    issue$ = this.store.pipe(select(getIssueById, { id: this.data.issue.id }));

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: PlanPreviewDialogData,
        public dialogRef: MatDialogRef<PlanPreviewDialogComponent>,
        private store: Store<IssuesState>
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(getIsOffline),
                first(),
                tap((isOffline) => {
                    const issue = this.data.issue;
                    const shouldDownloadRevisions = !isOffline && issue.id;
                    if (shouldDownloadRevisions) {
                        this.store.dispatch(
                            new LoadPlanRevisionsRequest({
                                issueId: issue.id,
                                projectId: issue.projectId,
                            })
                        );
                    }
                })
            )
            .subscribe();
    }

    onSave(markedPlanToPost: MarkedPlan): void {
        this.dialogRef.close({
            ...markedPlanToPost,
            plan: this.data.planId,
        });
    }

    onExit(): void {
        this.dialogRef.close();
    }
}
