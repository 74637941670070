import { Action } from '@ngrx/store';
import {
    Contact,
    Location as FlinkLocation,
    ProjectPartner,
    ProjectRole,
    TimeZoneShortInfo,
} from 'app/core/rest-api';
import { ContactDocType } from 'app/db/contacts/contact.document';
import { CraftToPlanLocationDocType } from 'app/db/craft-to-plan-locations/craft-to-plan-locations.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { ProjectCraftIdsDocType } from 'app/db/project-craft-ids/project-craft-ids.document';
import { ProjectRoleDocType } from 'app/db/project-roles/project-roles.document';
import { ProjectRoleFilters } from 'app/main/settings/role-settings/models/project-role-filters.model';

export enum SettingsActionTypes {
    // Timezones
    LOAD_TIMEZONES_REQUESTED = '[Global Settings] Load Timezones Requested',
    LOAD_TIMEZONES_SUCCESS = '[API] Load Timezones Success',
    LOAD_TIMEZONES_ERROR = '[API] Load Timezones Failed',
    // Languages
    LOAD_LANGUAGES_REQUESTED = '[Global Settings] Load Languages Requested',
    LOAD_LANGUAGES_SUCCESS = '[API] Load Languages Success',
    LOAD_LANGUAGES_ERROR = '[API] Load Languages Error',
    // Project Partners
    LOAD_PROJECT_PARTNERS_REQUESTED = '[Settings] Load Project Partners Requested',
    LOAD_PROJECT_PARTNERS_SUCCESS = '[API] Load Project Partners Success',
    LOAD_PROJECT_PARTNERS_ERROR = '[API] Load Project Partners Error',
    UPSERT_PROJECT_PARTNERS_START = '[API] Upsert Project Partners Start',
    UPSERT_PROJECT_PARTNERS_FINISH = '[API] Upsert Project Partners Finish',
    CREATE_PROJECT_PARTNER_REQUESTED = '[Settings] Create Project Partner Requested',
    CREATE_PROJECT_PARTNER_SUCCESS = '[API] Create Project Partner Success',
    CREATE_PROJECT_PARTNER_ERROR = '[API] Create Project Partner Error',
    UPDATE_PROJECT_PARTNER_REQUESTED = '[Settings] Update Project Partner Requested',
    UPDATE_PROJECT_PARTNER_SUCCESS = '[API] Update Project Partner Success',
    UPDATE_PROJECT_PARTNER_ERROR = '[API] Update Project Partner Error',
    DELETE_PROJECT_PARTNER_REQUESTED = '[Settings] Delete Project Partner Requested',
    DELETE_PROJECT_PARTNER_SUCCESS = '[API] Delete Project Partner Success',
    DELETE_PROJECT_PARTNER_ERROR = '[API] Delete Project Partner Error',
    // Project Crafts
    LOAD_PROJECT_CRAFTS_REQUESTED = '[ProjectResourceGuard] Load Project Crafts Requested',
    LOAD_PROJECT_CRAFTS_SUCCESS = '[API] Load Project Crafts Success',
    LOAD_PROJECT_CRAFTS_ERROR = '[API] Load Project Crafts Error',
    UPSERT_PROJECT_CRAFTS_START = '[APP] Upsert PROJECT_CRAFTS Start',
    UPSERT_PROJECT_CRAFTS_FINISH = '[DB] Upsert PROJECT_CRAFTS Finish',
    // Project Roles
    LOAD_PROJECT_ROLES_REQUESTED = '[ProjectResourceGuard] Load Project Roles',
    LOAD_PROJECT_ROLES_SUCCESS = '[API] Load Project Roles Success',
    LOAD_PROJECT_ROLES_ERROR = '[API] Load Project Roles Error',
    UPSERT_PROJECT_ROLES_START = '[DB] Upsert Project Roles Start',
    UPSERT_PROJECT_ROLES_FINISH = '[DB] Upsert Project Roles Finish',
    CREATE_PROJECT_ROLE_REQUESTED = '[Role Settings] Create Project Role Requested',
    CREATE_PROJECT_ROLE_SUCCESS = '[Role Settings] Create Project Role SUCCESS',
    CREATE_PROJECT_ROLE_ERROR = '[Role Settings] Create Project Role ERROR',
    UPDATE_PROJECT_ROLE_REQUESTED = '[Role Settings] UPDATE Project Role Requested',
    UPDATE_PROJECT_ROLE_SUCCESS = '[Role Settings] UPDATE Project Role SUCCESS',
    UPDATE_PROJECT_ROLE_ERROR = '[Role Settings] UPDATE Project Role ERROR',
    DELETE_PROJECT_ROLE_REQUESTED = '[Role Settings] DELETE Project Role Request',
    DELETE_PROJECT_ROLE_SUCCESS = '[Role Settings] DELETE Project Role SUCCESS',
    DELETE_PROJECT_ROLE_ERROR = '[Role Settings] DELETE Project Role ERROR',
    SET_PROJECT_ROLE_FILTERS = '[Role Settings] Set Project Role Filters',
    CLEAN_PROJECT_ROLE_FILTERS = '[Role Settings] Clean Project Role Filters',

    // Project Craft2Plan
    LOAD_PROJECT_CRAFT_TO_PLAN_REQUESTED = '[ProjectResourceGuard] Load Project Craft To Plan Request',
    LOAD_PROJECT_CRAFT_TO_PLAN_SUCCESS = '[ProjectResourceGuard] Load Project Craft To Plan Success',
    LOAD_PROJECT_CRAFT_TO_PLAN_ERROR = '[ProjectResourceGuard] Load Project Craft To Plan Error',
    UPSERT_PROJECT_CRAFT_TO_PLAN_START = '[DB] Upsert Project Craft To Plan Start',
    UPSERT_PROJECT_CRAFT_TO_PLAN_FINISH = '[DB] Upsert Project Craft To Plan Finish',
    // Project Contacts
    LOAD_PROJECT_CONTACTS_REQUEST = '[Contact] Load Project Contacts',
    LOAD_PROJECT_CONTACTS_SUCCESS = '[API] Load Project Contacts Success ',
    LOAD_PROJECT_CONTACTS_ERROR = '[API] Load Project Contacts Error',
    UPSERT_PROJECT_CONTACTS_START = '[DB] Upsert Project Contacts Start',
    UPSERT_PROJECT_CONTACTS_FINISH = '[DB] Upsert Project Contacts Finish',
    // Upload Boilerplate CSV
    UPLOAD_BOILERPLATE_CSV_REQUEST = '[Global Settings] Upload Boilerplate CSV Request',
    UPLOAD_BOILERPLATE_CSV_SUCCESS = '[Global Settings] Upload Boilerplate CSV Request',
    UPLOAD_BOILERPLATE_CSV_ERROR = '[Global Settings] Upload Boilerplate CSV Request',
}

export class LoadLanguagesRequested implements Action {
    readonly type = SettingsActionTypes.LOAD_LANGUAGES_REQUESTED;
}

export class LoadLanguagesSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_LANGUAGES_SUCCESS;

    constructor(public payload: { languages: string[] }) {}
}

export class LoadLanguagesError implements Action {
    readonly type = SettingsActionTypes.LOAD_LANGUAGES_ERROR;
    constructor(public payload: { error: any }) {}
}

export class LoadTimezonesRequested implements Action {
    readonly type = SettingsActionTypes.LOAD_TIMEZONES_REQUESTED;
}

export class LoadTimezonesSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_TIMEZONES_SUCCESS;

    constructor(public payload: { timezones: TimeZoneShortInfo[] }) {}
}

export class LoadTimezonesError implements Action {
    readonly type = SettingsActionTypes.LOAD_TIMEZONES_ERROR;
    constructor(public payload: { error: any }) {}
}

export class LoadProjectContactsRequest implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CONTACTS_REQUEST;

    constructor(public payload: { projectId: string }) {}
}

export class LoadProjectContactsSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CONTACTS_SUCCESS;

    constructor(
        public payload: {
            contacts: Contact[];
            fromDb: boolean;
            projectId: string;
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadProjectContactsError implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CONTACTS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class UpsertProjectContactsStart implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CONTACTS_START;
    constructor(public payload: { contacts: Contact[] }) {}
}

export class UpsertProjectContactsFinish implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CONTACTS_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<ContactDocType>;
        }
    ) {}
}

export class LoadProjectPartnersRequested implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_PARTNERS_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadProjectPartnersSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_PARTNERS_SUCCESS;
    constructor(
        public payload: {
            projectPartners: ProjectPartner[];
            fromDb: boolean;
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadProjectPartnersError implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_PARTNERS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class UpsertProjectPartnersStart implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_PARTNERS_START;
    constructor(public payload: { projectPartners: ProjectPartner[] }) {}
}

export class UpsertProjectPartnersFinish implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_PARTNERS_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<ProjectCraftIdsDocType>;
        }
    ) {}
}

export class UpdateProjectPartnerRequested implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_PARTNER_REQUESTED;
    constructor(public payload: { projectPartner: ProjectPartner }) {}
}

export class UpdateProjectPartnerSuccess implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_PARTNER_SUCCESS;
    constructor(public payload: { projectPartner: ProjectPartner }) {}
}

export class UpdateProjectPartnerError implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_PARTNER_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateProjectPartnerRequested implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_PARTNER_REQUESTED;

    constructor(
        public payload: { projectId: string; projectPartner: ProjectPartner }
    ) {}
}

export class CreateProjectPartnerSuccess implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_PARTNER_SUCCESS;

    constructor(public payload: { projectPartner: ProjectPartner }) {}
}

export class CreateProjectPartnerError implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_PARTNER_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteProjectPartnerRequested implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_PARTNER_REQUESTED;

    constructor(public payload: { projectPartner: ProjectPartner }) {}
}

export class DeleteProjectPartnerSuccess implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_PARTNER_SUCCESS;

    constructor(public payload: { projectPartner: ProjectPartner }) {}
}

export class DeleteProjectPartnerError implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_PARTNER_ERROR;
    constructor(public payload: { error: any }) {}
}

export class LoadProjectCraftsRequest implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFTS_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadProjectCraftsSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFTS_SUCCESS;
    constructor(
        public payload: {
            craftIds: number[];
            projectId: string;
            fromDb: boolean;
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadProjectCraftsError implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFTS_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class UpsertProjectCraftsStart implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CRAFTS_START;
    constructor(public payload: { craftIds: number[]; projectId: string }) {}
}

export class UpsertProjectCraftsFinish implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CRAFTS_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<ProjectCraftIdsDocType>;
        }
    ) {}
}

export class LoadProjectRolesRequest implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_ROLES_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadProjectRolesSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_ROLES_SUCCESS;
    constructor(
        public payload: {
            fromDb: boolean;
            projectRoles: ProjectRole[];
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadProjectRolesError implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_ROLES_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class UpsertProjectRolesStart implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_ROLES_START;
    constructor(public payload: { projectRoles: ProjectRole[] }) {}
}

export class UpsertProjectRolesFinish implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_ROLES_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<ProjectRoleDocType>;
        }
    ) {}
}

export class LoadProjectCraftToPlanRequested implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadProjectCraftToPlanSuccess implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_SUCCESS;
    constructor(
        public payload: {
            craftToPlanLocations: FlinkLocation[];
            fromDb: boolean;
            currentProjectId: string;
            requestProjectId: string;
        }
    ) {}
}

export class LoadProjectCraftToPlanError implements Action {
    readonly type = SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_ERROR;
    constructor(public payload?: { error: any }) {}
}

export class UpsertProjectCraftToPlanStart implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CRAFT_TO_PLAN_START;
    constructor(public payload: { craftToPlanLocations: FlinkLocation[] }) {}
}

export class UpsertProjectCraftToPlanFinish implements Action {
    readonly type = SettingsActionTypes.UPSERT_PROJECT_CRAFT_TO_PLAN_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<CraftToPlanLocationDocType>;
        }
    ) {}
}

export class CreateProjectRoleRequested implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_ROLE_REQUESTED;
    constructor(
        public payload: { projectId: string; projectRole: ProjectRole }
    ) {}
}

export class CreateProjectRolesSuccess implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_ROLE_SUCCESS;
    constructor(public payload: { projectRole: ProjectRole }) {}
}

export class CreateProjectRolesError implements Action {
    readonly type = SettingsActionTypes.CREATE_PROJECT_ROLE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class UpdateProjectRoleRequested implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_ROLE_REQUESTED;
    constructor(
        public payload: { projectId: string; projectRole: ProjectRole }
    ) {}
}

export class UpdateProjectRolesSuccess implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_ROLE_SUCCESS;
    constructor(public payload: { projectRole: ProjectRole }) {}
}

export class UpdateProjectRolesError implements Action {
    readonly type = SettingsActionTypes.UPDATE_PROJECT_ROLE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class DeleteProjectRoleRequested implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_ROLE_REQUESTED;
    constructor(public payload: { projectId: string; id: string }) {}
}

export class DeleteProjectRoleSuccess implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_ROLE_SUCCESS;
    constructor(public payload: { id: string }) {}
}

export class DeleteProjectRoleError implements Action {
    readonly type = SettingsActionTypes.DELETE_PROJECT_ROLE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class SetProjectRoleFilters implements Action {
    readonly type = SettingsActionTypes.SET_PROJECT_ROLE_FILTERS;
    constructor(public payload: { filters: ProjectRoleFilters }) {}
}

export class CleanProjectRoleFilters implements Action {
    readonly type = SettingsActionTypes.CLEAN_PROJECT_ROLE_FILTERS;
    constructor() {}
}

export type SettingsActions =
    | LoadProjectPartnersRequested
    | LoadProjectPartnersSuccess
    | LoadProjectPartnersError
    | UpsertProjectPartnersFinish
    | UpsertProjectPartnersStart
    | UpdateProjectPartnerRequested
    | UpdateProjectPartnerSuccess
    | UpdateProjectPartnerError
    | CreateProjectPartnerRequested
    | CreateProjectPartnerSuccess
    | CreateProjectPartnerError
    | DeleteProjectPartnerRequested
    | DeleteProjectPartnerSuccess
    | DeleteProjectPartnerSuccess
    | LoadProjectCraftsRequest
    | LoadProjectCraftsSuccess
    | LoadProjectCraftsError
    | UpsertProjectCraftsStart
    | UpsertProjectCraftsFinish
    | LoadProjectRolesRequest
    | LoadProjectRolesSuccess
    | LoadProjectRolesError
    | UpsertProjectRolesStart
    | UpsertProjectRolesFinish
    | LoadProjectCraftToPlanRequested
    | LoadProjectCraftToPlanSuccess
    | LoadProjectCraftToPlanError
    | UpsertProjectCraftToPlanStart
    | UpsertProjectCraftToPlanFinish
    | CreateProjectRoleRequested
    | CreateProjectRolesSuccess
    | CreateProjectRolesError
    | UpdateProjectRoleRequested
    | UpdateProjectRolesError
    | UpdateProjectRolesSuccess
    | DeleteProjectPartnerRequested
    | DeleteProjectRoleSuccess
    | DeleteProjectRoleError
    | LoadProjectContactsRequest
    | LoadProjectContactsSuccess
    | LoadProjectContactsError
    | UpsertProjectContactsStart
    | UpsertProjectContactsFinish
    | LoadTimezonesRequested
    | LoadTimezonesSuccess
    | LoadTimezonesError
    | LoadLanguagesRequested
    | LoadLanguagesSuccess
    | LoadLanguagesError
    | SetProjectRoleFilters
    | CleanProjectRoleFilters;
