import { Action } from '@ngrx/store';
import {
    Contact,
    ExternalContact,
    ExternalContactSearchType,
} from 'app/core/rest-api';
import { Craft } from 'app/core/rest-api/model/craft';
import { ContactDocType } from 'app/db/contacts/contact.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';

export enum ExternalContactsActionTypes {
    LOAD_EXTERNAL_CONTACTS_REQUESTED = '[External Contacts] Load External Contacts Requested',
    LOAD_EXTERNAL_CONTACTS_SUCCESS = '[API] Load External Contacts Success',
    LOAD_EXTERNAL_CONTACTS_ERROR = '[API] Load External Contacts Error',

    CREATE_CONTACT_FROM_EXTERNAL_CONTACT_REQUEST = '[Contacts] Create External Contact Request',
    CREATE_CONTACT_FROM_EXTERNAL_CONTACT_SUCCESS = '[API] Create External Contact Success',
    CREATE_CONTACT_FROM_EXTERNAL_CONTACT_ERROR = '[API] Create External Contact Error',

    UPSERT_EXTERNAL_CONTACTS_START = '[API] Upsert External Contact Start',
    UPSERT_EXTERNAL_CONTACTS_FINISH = '[API] Upsert External Contact Finish',

    SET_EXTERNAL_CONTACT_SEARCH = '[API] Set External Contact Search',

    RELOAD_EXTERNAL_CONTACTS_REQUESTED = '[API] Reload External Contacts Reqested'
}

export class UpsertExternalContactsStart implements Action {
    readonly type = ExternalContactsActionTypes.UPSERT_EXTERNAL_CONTACTS_START;
    constructor(public payload: { contacts: ExternalContact[] }) {}
}

export class UpsertExternalContactsFinish implements Action {
    readonly type = ExternalContactsActionTypes.UPSERT_EXTERNAL_CONTACTS_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<ContactDocType> }
    ) {}
}

export class LoadExternalContactsRequested implements Action {
    readonly type =
        ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_REQUESTED;
    constructor(
        public payload: {
            searchTerm: string;
            searchType: ExternalContactSearchType;
            craftId?: string;
        }
    ) {}
}

export class LoadExternalContactsSuccess implements Action {
    readonly type = ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_SUCCESS;
    constructor(public payload: { contacts: ExternalContact[] }) {}
}

export class LoadExternalContactsError implements Action {
    readonly type = ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateContactFromExternalContactRequest implements Action {
    readonly type =
        ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_REQUEST;

    constructor(public payload: { contact: Partial<ExternalContact> }) {}
}

export class CreateContactFromExternalContactSuccess implements Action {
    readonly type =
        ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_SUCCESS;

    constructor(public payload: { contact: ExternalContact }) {}
}

export class CreateContactFromExternalContactError implements Action {
    readonly type =
        ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_ERROR;
    constructor(public payload: { error: any }) {}
}

export class SetSearchForExternalContacts implements Action {
    readonly type = ExternalContactsActionTypes.SET_EXTERNAL_CONTACT_SEARCH;

    constructor(
        public payload: {
            searchTerm: string;
            searchType: ExternalContactSearchType;
            craftId?: string;
        }
    ) {}
}

export class ReloadExternalContactsRequested implements Action {
    readonly type = ExternalContactsActionTypes.RELOAD_EXTERNAL_CONTACTS_REQUESTED;
    constructor(
        public payload: {
            searchTerm: string;
            searchType: ExternalContactSearchType;
            craftId?: string;
        }
    ) {}
}

export type ExternalContactsActions =
    | LoadExternalContactsRequested
    | LoadExternalContactsSuccess
    | LoadExternalContactsError
    | CreateContactFromExternalContactRequest
    | CreateContactFromExternalContactSuccess
    | CreateContactFromExternalContactError
    | UpsertExternalContactsStart
    | UpsertExternalContactsFinish
    | ReloadExternalContactsRequested;
