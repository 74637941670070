import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe to shorten long text to a specific length. Additionally,
 * this pipe can be turned off to for example implement
 * "Show more" / "Show less" functionality.
 *
 * Three dots are added the end of shortened text.
 */
@Pipe({
    name: 'shortenText',
})
export class ShortenTextPipe implements PipeTransform {
    transform(
        text: string,
        targetLength: number,
        shortened: boolean = true
    ): string {
        if (!shortened || text.length <= targetLength) {
            return text;
        }

        return text.substr(0, targetLength - 3) + '...';
    }
}
