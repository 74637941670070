import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Project, TimeZoneShortInfo } from 'app/core/rest-api';
import { environment } from 'environments/environment';
import { MatSelect } from '@angular/material/select';
import { select, Store } from '@ngrx/store';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { ProjectsState } from 'app/store/projects/projects.reducer';
import { TooltipUtilService } from 'app/shared/services/tooltip-util/tooltip-util.service';
import { AutoSelectorConfig } from 'app/shared/components/auto-selector/auto-selector.config';

@Component({
    selector: 'acc-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
    @ViewChild(MatSelect)
    templateSelect: MatSelect;

    @Input()
    form: UntypedFormGroup;

    @Input()
    timezones: TimeZoneShortInfo[];

    timezoneAutoSelectorConfiguration = new AutoSelectorConfig({
        properties: { title: 'title' },
    });

    @Input()
    updateMode: boolean;

    @Input()
    templates: Project[];

    @Input()
    currentProject: Project;

    @Input()
    hasProjectChanges: boolean;

    @Input() isTemplate: boolean;

    @Output()
    openImgDialog: EventEmitter<Project> = new EventEmitter();

    @Output()
    changeTemplate: EventEmitter<Project> = new EventEmitter();

    @Output()
    updateProject: EventEmitter<void> = new EventEmitter();

    @Output()
    createProject: EventEmitter<void> = new EventEmitter();

    @Output()
    toggleSidenav: EventEmitter<void> = new EventEmitter();

    assetUrl = environment.endpoints.asset;

    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));

    constructor(
        private store: Store<ProjectsState>,
        private tooltipUtilService: TooltipUtilService,
    ) {}

    ngOnInit(): void {}

    cleanSelectTemplateValue(): void {
        if (this.templateSelect) {
            this.templateSelect.writeValue(null);
        }
    }
}
