<mat-form-field class="w-100-p">
    <input
        matInput
        [placeholder]="placeholder"
        type="number"
        [(ngModel)]="value"
        (ngModelChange)="updateChanges()"
        (keypress)="setValueBefore()"
        (keyup)="setValidatedValue($event)"
        [max]="max"
        [min]="min"
        [step]="step"
        [autocomplete]="autocomplete"
        [required]="required"
    />
</mat-form-field>
