import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'app/core/rest-api';

@Pipe({ name: 'notificationDetailsInnerHtml' })
export class NotificationDetailsInnerHtmlPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private translationService: TranslateService
    ) {}

    transform(notification: Notification): SafeHtml {
        let html;

        const userHtml =
            '<span class="username">' + notification?.creator?.name + '</span>';

        switch (notification.type) {
            case 'Issue':
                html = this.getIssueInnerHtml(userHtml, notification);
                break;
            case 'InspectionEntry':
                html = this.getInspectionInnerHtml(userHtml, notification);
                break;
            case 'DailyLogEntry':
            case 'Message':
                html = this.getMessageInnerHtml(userHtml, notification);
                break;
        }

        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    getIssueInnerHtml(userHtml: string, notification: Notification): string {
        const titleHtml =
            '<span class="title">„' +
            notification.notificationContext['title'] +
            '“</span>';

        const translationIdentifier =
            notification.reason === 'Post'
                ? 'NOTIFICATIONS.ISSUE_CREATED'
                : 'NOTIFICATIONS.ISSUE_UPDATED';

        return this.translationService.instant(translationIdentifier, {
            user: userHtml,
            ticket: titleHtml
        });
    }

    getInspectionInnerHtml(
        userHtml: string,
        notification: Notification
    ): string {
        const titleHtml =
            '<span class="title">„' +
            notification.notificationContext['title'] +
            '“</span>';

        const translationIdentifier =
            notification.reason === 'Finished'
                ? 'NOTIFICATIONS.INSPECTION_ENTRY_FINISHED'
                : '';

        return this.translationService.instant(translationIdentifier, {
            user: userHtml
        });
    }

    getMessageInnerHtml(userHtml: string, notification: Notification): string {
        const title = this.translationService.instant(
            'APP.' + notification.contextType.toUpperCase()
        );

        const titleHtml = '<span class="title">„' + title + '“</span>';

        const translationIdentifier =
            notification.reason === 'Post'
                ? 'NOTIFICATIONS.MESSAGE_CREATED'
                : 'NOTIFICATIONS.MESSAGE_UPDATE';

        return this.translationService.instant(translationIdentifier, {
            user: userHtml,
            title: titleHtml
        });
    }
}
