import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Notification } from 'app/core/rest-api';
import { getAllLocations } from 'app/store/locations/locations.selectors';
import { selectAllProjectEntities } from 'app/store/projects/projects.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
    LoadNotificationsRequest,
    MarkNotificationAsReadRequest,
    MarkNotificationsAsReadRequest
} from '../../../store/notifications/notifications.actions';
import { getUnreadNotifications } from '../../../store/notifications/notifications.selectors';

@Component({
    selector: 'acc-notification',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
    @ViewChild('appMenu', { static: true })
    matMenu: MatMenu;

    notifications$: Observable<Notification[]>;
    locations$ = this.store.pipe(select(getAllLocations));
    projectsEntities = {};

    timeNow: Date = new Date();
    unsubscribe$ = new Subject<void>();

    private intervalId: any;

    constructor(private router: Router, private store: Store<Notification>) {
        // will update the time difference from the time of notification to the time now only every second
        // note: cast interval id to any, because something brings in NodeJS types for setInterval here...
        this.intervalId = setInterval(() => {
            this.timeNow = new Date();
        }, 1000);
    }

    ngOnInit(): void {
        this.store.dispatch(new LoadNotificationsRequest());

        this.notifications$ = this.store.pipe(select(getUnreadNotifications));

        this.store
            .pipe(
                select(selectAllProjectEntities),
                takeUntil(this.unsubscribe$),
                tap(entities => (this.projectsEntities = entities))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        // do not remove
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        clearInterval(this.intervalId);
    }

    markNotificationAsRead(notification: Notification): void {
        this.store.dispatch(
            new MarkNotificationAsReadRequest({ notification })
        );
        this.navigateToNotificationSource(notification);
    }

    private navigateToNotificationSource(notification: Notification): void {
        switch (notification.type) {
            case Notification.TypeEnum.Issue:
                this.router.navigate([
                    'projects',
                    notification.notificationContext['projectId'],
                    'issues',
                    notification.notificationContext['id']
                ]);
                break;

            case Notification.TypeEnum.InspectionEntry:
            case Notification.TypeEnum.DailyLogEntry:
            case Notification.TypeEnum.Message:
                this.router.navigate(
                    [
                        'projects',
                        notification.notificationContext['projectId'],
                        'diary'
                    ],
                    {
                        queryParams: {
                            diaryEntryId: notification.notificationContext['id']
                        }
                    }
                );
                break;
        }
    }

    markNotificationsAsRead(): void {
        this.store.dispatch(new MarkNotificationsAsReadRequest());
    }
}
