import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { User } from 'app/core/auth/models/user';
import { AccountService } from 'app/core/rest-api';
import { SyncService } from 'app/main/sync/sync.service';
import { CreateContactSuccess } from 'app/store/contacts/contacts.actions';
import { forkJoin, from, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
} from 'rxjs/operators';
import {
    AuthActionTypes,
    LoginByToken,
    LoginError,
    LoginRequested,
    LoginSuccess,
    Logout,
    RegisterCompleteError,
    RegisterCompleteRequested,
    RegisterError,
    RegisterRequested,
    RegisterSuccess,
    ResetPasswordError,
    ResetPasswordRequested,
    ResetPasswordSuccess,
} from './auth.actions';
import { ErrorUtils } from 'app/core/utils/error-util';
import { DatabaseService } from 'app/db/database.service';
import { DeletionState } from 'app/db/database';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { DemoService } from 'app/shared/services/demo/demo.service';

@Injectable()
export class AuthEffects {
    @Effect()
    loginRequested$ = this.actions$.pipe(
        ofType<LoginRequested>(AuthActionTypes.LoginRequested),
        map((action) => action.payload.login),
        switchMap((login) => {
            if (this.demoService.isDemoUser(login)) {
                this.demoService.switchToDemoBackend();
            } else {
                // Make sure, that the correct backend is used in case it's not
                // a demo user => this is done here as well to be on the safe
                // side.
                this.demoService.resetBackendConfiguration();
            }

            return this.accountService.accountLoginPost(login).pipe(
                map((response) => response.data),
                map((data) => {
                    return new LoginSuccess({
                        user: new User(data.accessToken),
                        token: data.accessToken,
                        notificationToken: data.notificationToken,
                    });
                }),
                catchError((error) =>
                    of(
                        new LoginError({
                            login,
                            error,
                        })
                    )
                )
            );
        })
    );

    @Effect({ dispatch: false })
    loginSuccess$ = this.actions$.pipe(
        ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
        map((action) => action.payload),
        switchMap((payload) => {
            return forkJoin([
                from(Preferences.set({ key: 'token', value: payload.token })),
                from(
                  Preferences.set({
                        key: 'notificationToken',
                        value: payload.notificationToken,
                    })
                ),
            ]).pipe(
                switchMap(() => {
                    // the user has just logged in via login against backend,
                    // disable forceOffline to prevent the user from seeing nothing
                    // note: forceOffline should already be disabled
                    return from(this.syncService.disableForceOffline());
                }),
                switchMap(() => from(this.syncService.init()))
            );
        }),
        tap(() => this.router.navigateByUrl('/projects')),
        tap(() => this.demoService.showDemoModeStartPopup())
    );

    @Effect({ dispatch: false })
    loginByToken$ = this.actions$.pipe(
        ofType<LoginByToken>(AuthActionTypes.LoginByToken),
        tap((action) => {
            // Note: showDemoModeStartPopup checks for demo mode itself and is
            //       therefore not included in the if below.
            this.demoService.showDemoModeStartPopup();

            if (this.demoService.isDemoUserFromToken(action.payload.token)) {
                this.demoService.switchToDemoBackend();
            } else {
                // Ensure correct backend for normal, non-demo user.
                this.demoService.resetBackendConfiguration();
            }
        })
    );

    @Effect({ dispatch: false })
    loginError$ = this.actions$.pipe(
        ofType<LoginError>(AuthActionTypes.LoginError),
        tap((action) => {
            const { error, login } = action.payload;

            // Ensure correct backend is used for normal users in case of login
            // errors => This is just an additional safety precaution.
            this.demoService.resetBackendConfiguration();

            this.errorUtils.showSingleMessageOrDefault(error, 'AUTH.LOGIN');
        })
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        mergeMap(() => from(Preferences.get({ key: 'token' }))),
        mergeMap((token) =>
            from(Preferences.clear()).pipe(
                // forward token
                map(() => token)
            )
        ),
        tap((_token) => {
            // Ensure that the correct backend is used after logout.
            this.demoService.resetBackendConfiguration();
        }),
        mergeMap((token) => {
            let obs$: Observable<any> = of({});

            // was the user logged in before this effect is processed?
            if (token.value) {
                obs$ = obs$.pipe(
                    mergeMap(() => from(this.databaseService.deleteDb())),
                    // prevent navigation before a deletion is complete if there
                    // is a concurrent deletion in progress
                    filter(
                        (deletionState) =>
                            deletionState === DeletionState.Completed ||
                            deletionState === DeletionState.NoDatabaseExists
                    )
                );
            }

            return obs$;
        }),
        mergeMap(() => {
            return from(this.fileStorageService.clear());
        }),
        tap(() => {
            this.router.navigate(['login']);
            // location.href = '/login';
            // TODO: show splash screen when using the location.href above?
        })
    );

    @Effect()
    register$ = this.actions$.pipe(
        ofType<RegisterRequested>(AuthActionTypes.RegisterRequested),
        mergeMap((action) =>
            this.accountService
                .accountRegisterPost(action.payload.registerViewModel)
                .pipe(
                    map(
                        (response) =>
                            new RegisterSuccess({ bearerToken: response.data })
                    ),
                    catchError((error) => of(new RegisterError({ error })))
                )
        )
    );

    @Effect({ dispatch: false })
    registerError$ = this.actions$.pipe(
        ofType<RegisterError>(AuthActionTypes.RegisterError),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'AUTH.REGISTER');

            return of();
        })
    );

    @Effect()
    registerComplete$ = this.actions$.pipe(
        ofType<RegisterCompleteRequested>(
            AuthActionTypes.RegisterCompleteRequested
        ),
        tap((_action) => {
            // hide previous error messages
            this.errorUtils.dismissSnackbar();
        }),
        mergeMap((action) =>
            this.accountService
                .accountRegisterCompletePost(
                    action.payload.completeRegisterViewModel
                )
                .pipe(
                    map((response) => response.data),
                    map((data) => {
                        return new CreateContactSuccess({
                            contact: data,
                        });
                    }),
                    catchError((error) =>
                        of(new RegisterCompleteError({ error }))
                    )
                )
        )
    );

    @Effect({ dispatch: false })
    registerCompleteError$ = this.actions$.pipe(
        ofType<RegisterCompleteError>(AuthActionTypes.RegisterCompleteError),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showMultipleMessagesOrDefault(
                error,
                'AUTH.REGISTER_COMPLETE',
                {
                    autohide: false,
                }
            );

            return of();
        })
    );

    @Effect()
    resetPasswordRequested = this.actions$.pipe(
        ofType<ResetPasswordRequested>(AuthActionTypes.ResetPasswordRequested),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.accountService.accountResetpasswordPost(payload).pipe(
                map((response) => new ResetPasswordSuccess()),
                catchError((error) => {
                    return of(
                        new ResetPasswordError({
                            error,
                        })
                    );
                })
            );
        })
    );

    @Effect({ dispatch: false })
    resetPasswordError$ = this.actions$.pipe(
        ofType<ResetPasswordError>(AuthActionTypes.ResetPasswordError),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'AUTH.UPDATE_PASSWORD'
            );

            return of();
        })
    );

    constructor(
        private accountService: AccountService,
        private databaseService: DatabaseService,
        private demoService: DemoService,
        private fileStorageService: FileStorageService,
        private actions$: Actions,
        private router: Router,
        private syncService: SyncService,
        private errorUtils: ErrorUtils
    ) {}
}
