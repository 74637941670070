<mat-form-field class="w-100-p" data-test="subscribed-service-selector">
    <mat-label translate>
        DATA.SUBSCRIBED_SERVICES
    </mat-label>

    <mat-select
        [(ngModel)]="value"
        [compareWith]="compareFunc"
        (selectionChange)="onSelectionChange($event)"
        multiple
    >
        <mat-option
            *ngFor="let subscribedService of subscribedServices"
            [value]="subscribedService"
        >
            {{ subscribedService }}
        </mat-option>
    </mat-select>
</mat-form-field>
