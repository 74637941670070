import { RxJsonSchema } from 'rxdb';

export const projectRoleSchema: RxJsonSchema = {
    title: 'projectRole schema',
    description: 'describes a projectRole',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true
        },
        creationDate: {
            type: ['string', 'null']
        },
        markedAsDelete: {
            type: ['boolean', 'null']
        },
        updateDateTime: {
            type: ['string', 'null']
        },
        projectId: {
            type: 'string',
        },
        roles: {
            type: ['string', 'null']
        },
        craft: {
            type: ['integer', 'null']
        },

        internalResponsible: {
            type: ['string', 'null']
        },
        externalResponsible: {
            type: ['string', 'null']
        },
        fourEyesPrinciple: {
            type: ['boolean', 'null']
        }
    },
    indexes: [
        'projectId'
    ],
    additionalProperties: false
};
