import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { CustomSerializer } from '../router/custom-route-serializer';
import { CoreEffects } from './core.effects';
import { metaReducers, reducers } from './core.state';

@NgModule({
    imports: [
        // ngrx/store modules
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: !environment.production,
                strictActionImmutability: !environment.production,
            },
        }),
        EffectsModule.forRoot([CoreEffects]),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 100, // Retains last 100 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class CoreStoreModule {}
