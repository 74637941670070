import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControlOptions, ControlContainer, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
    CommonTemplate,
    isFormLetterTemplateOrPredefined,
} from '../common-template';
import { Contact, RTFTemplate, TemplateAttribute } from '../../../../core/rest-api';
import { keepOriginalOrder } from 'app/core/utils/keyvalue-utils';
import { AutoSelectorConfig } from '../../auto-selector/auto-selector.config';

@Component({
  selector: 'acc-print-dialog-page-base-attributes',
  templateUrl: './print-dialog-page-base-attributes.component.html',
  styleUrls: ['./print-dialog-page-base-attributes.component.scss']
})
export class PrintDialogPageBaseAttributesComponent implements OnInit, OnChanges {
    autoSelectorConfigDict: { [key: string]: AutoSelectorConfig } = {};

    @Input()
    form: UntypedFormGroup;

    @Input()
    template: CommonTemplate;

    @Input()
    contacts$: Observable<Contact[]>;

    /**
     * Indicates if the required setting on custom attributes should
     * be honored. For settings, it makes sense to ignore it, to be
     * able to specify the custom attributes partially. True by
     * default.
     */
    @Input()
    honorRequiredSetting = true;

    keepOriginalOrder = keepOriginalOrder;

    constructor(
        private fb: UntypedFormBuilder, 
    ) { }

    isRTFTemplate(val): val is RTFTemplate{
       return !isFormLetterTemplateOrPredefined(val);
    }

    ngOnInit(): void{
    }

    ngOnChanges(changes: SimpleChanges): void {
        const letterOrFormChanged = !!changes.template || !!changes.form;
        const letterAndFormAvailable = !!this.template && !!this.form;
        if (letterOrFormChanged && letterAndFormAvailable) {
            const customMap = isFormLetterTemplateOrPredefined(
                this.template
            )
                ? this.template.customAttributes
                : this.template.customKeyValuePairs;

            if (customMap) {
                Object.keys(customMap).forEach((key) => {
                    const defaultValue = customMap[key]['defaultValue']
                        ? customMap[key]['defaultValue']
                        : '';

                    // The AscAutoSelect doesn't seem to work with "blur", use "change"
                    const updateOn: AbstractControlOptions['updateOn'] =
                        (customMap[key] as TemplateAttribute).type === 'Contact'
                            ? 'change'
                            : 'blur';
                    const formControl = this.fb.control(defaultValue, {
                        updateOn,
                    });

                    this.autoSelectorConfigDict[key] = new AutoSelectorConfig({
                        label: key,
                        properties: { title: 'fullname' },
                    });
                    this.form.addControl(key, formControl);
                });
            }
        }
    }

}
