import { NgModule } from '@angular/core';
import { PrintDialogListComponent } from './print-dialog-list/print-dialog-list.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { PrintDialogLetterSelectionComponent } from './print-dialog-letter-selection/print-dialog-letter-selection.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PrintDialogPageBaseAttributesComponent } from './print-dialog-page-base-attributes/print-dialog-page-base-attributes.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PrintDialogFinalPageComponent } from './print-dialog-final-page/print-dialog-final-page.component';
import { MatRadioModule } from '@angular/material/radio';
import { PrintDialogComponent } from './print-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { AdditionalFormLetterSettingsComponent } from './print-dialog-final-page/additional-form-letter-settings/additional-form-letter-settings.component';
import { AutoSelectorModule } from '../auto-selector/auto-selector.module';


@NgModule({
    declarations: [
        PrintDialogComponent,
        PrintDialogListComponent,
        PrintDialogLetterSelectionComponent,
        PrintDialogPageBaseAttributesComponent,
        PrintDialogFinalPageComponent,
        AdditionalFormLetterSettingsComponent,
    ],
    imports: [
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatButtonModule,
        MatSortModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        TranslateModule.forChild(),
        FlexModule,
        FlexLayoutModule,
        AutoSelectorModule,
    ],
    exports: [
        PrintDialogComponent,
        PrintDialogListComponent,
        PrintDialogLetterSelectionComponent,
        PrintDialogPageBaseAttributesComponent,
        PrintDialogFinalPageComponent,
        AdditionalFormLetterSettingsComponent,
    ],
})
export class PrintDialogModule {}
