<h2 mat-dialog-title>{{ 'APP.LOGOUT_DIALOG.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">
    <p *ngIf="showUnsynchronizedWarning" data-test="logout-dialog-device-warning">
        {{ 'APP.LOGOUT_DIALOG.DEVICE_WARNING' | translate }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        mat-dialog-close
        cdkFocusInitial
        data-test="logout-dialog-cancel"
    >
        {{ 'APP.CANCEL' | translate }}
    </button>
    <button
        mat-button
        color="warn"
        [mat-dialog-close]="true"
        data-test="logout-dialog-confirm"
    >
        {{ 'APP.LOGOUT' | translate }}
    </button>
</mat-dialog-actions>
