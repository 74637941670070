import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { tap, debounceTime, withLatestFrom } from 'rxjs/operators';

import { getAllConflicts } from './conflict.selectors';
import { ConflictSnackbarComponent } from '../../shared/components/conflict-snackbar/conflict-snackbar.component';
import { IssuesState } from '../../store/issues/issues.reducer';
import { DiaryState } from '../../store/diary/diary.reducer';

import {
    CreateDiaryConflicts,
    SetDiaryConflicts,
    DiaryActionTypes,
} from './../diary/diary.actions';
import {
    CreateIssueConflicts,
    SetIssueConflicts,
    IssuesActionTypes,
} from './../issues/issues.actions';

@Injectable()
export class ConflictEffects {
    @Effect({ dispatch: false })
    conflictSnackbar$ = this.actions$.pipe(
        ofType<
            | CreateIssueConflicts
            | SetIssueConflicts
            | CreateDiaryConflicts
            | SetDiaryConflicts
        >(
            IssuesActionTypes.CREATE_ISSUE_CONFLICTS,
            IssuesActionTypes.SET_ISSUE_CONFLICTS,
            DiaryActionTypes.CREATE_DIARY_CONFLICTS,
            DiaryActionTypes.SET_DIARY_CONFLICTS
        ),
        debounceTime(1000),
        withLatestFrom(this.store.pipe(select(getAllConflicts))),
        tap(([action, allConflicts]) => {
            if (allConflicts.length > 0) {
                this.snackBar.openFromComponent(ConflictSnackbarComponent, {
                    panelClass: ['conflict-snackbar', 'warn'],
                    data: allConflicts,
                });
            }
        })
    );

    constructor(
        private actions$: Actions,
        private store: Store<IssuesState | DiaryState>,
        private snackBar: MatSnackBar
    ) {}
}
