import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Issue, Project } from 'app/core/rest-api';
import { environment } from 'environments/environment';

import { IssuesUtils } from '../../main/issues/issues-utils.service';
import { IssuePost } from '../../main/issues/models/issue.post';
import { getContactsEntities } from '../contacts/contacts.selectors';
import { getAllLocations } from '../locations/locations.selectors';
import { getCurrentProject } from '../projects/projects.selectors';
import {
    getCurrentIssueId,
    getCurrentIssueIdQuery,
    getCurrentProjectId,
    getCurrentRevisionDate,
    getIsConflict,
    getSelectedConflictItem,
} from '../router/router.selectors';
import { IssuesStoreUtilsService } from './issues-store-utils.service';
import * as fromIssues from './issues.reducer';
import { IssuesState } from './issues.reducer';
import {
    createCustomAttributesFromProject,
    issueStatesAllowedForUser,
} from './issues.selectors.utils';

export const selectIssuesState = createFeatureSelector<IssuesState>('issues');

export const getIssueCommands = createSelector(
    selectIssuesState,
    (state) => state.issueCommands
);

export const getPlanCommands = createSelector(
    selectIssuesState,
    (state) => state.planCommands
);

export const getTotalNumberOfCommands = createSelector(
    getIssueCommands,
    getPlanCommands,
    (issueCommands, planCommands) => issueCommands.length + planCommands.length
);

export const getHasCurrentIssueLockedCommands = createSelector(
    getIssueCommands,
    getCurrentIssueId,
    (commands, currentIssueId) => {
        const lockedCommands = commands.filter(
            (c) => c.entityId === currentIssueId && c.locked
        );
        return lockedCommands.length > 0;
    }
);

export const getHasUndoneCommands = createSelector(
    getIssueCommands,
    getPlanCommands,
    (issueCommands, planCommands) =>
        issueCommands.length > 0 || planCommands.length > 0
);

export const getPlanCommandsForUnsaved = createSelector(
    getPlanCommands,
    (commands) => commands.filter((c) => !c.issueId)
);

export const getAllIssuesWithoutCommands = createSelector(
    selectIssuesState,
    fromIssues.selectAll
);

export const getIssueEntitiesWithoutCommands = createSelector(
    selectIssuesState,
    fromIssues.selectEntities
);

export const getIssueEntities = createSelector(
    getIssueEntitiesWithoutCommands,
    getCurrentProjectId,
    getIssueCommands,
    getPlanCommands,
    (entityMap, projectId, issueCommands, planCommands) =>
        IssuesStoreUtilsService.mergeIssuesEntitiesWithCommands({
            entityMap,
            issueCommands,
            planCommands,
            projectId,
        })
);

export const getAllIssues = createSelector(
    getAllIssuesWithoutCommands,
    getCurrentProjectId,
    getIssueCommands,
    getPlanCommands,
    (issues, projectId, issueCommands, planCommands) =>
        IssuesStoreUtilsService.mergeIssuesWithCommands({
            entities: issues,
            issueCommands,
            planCommands,
            projectId,
        })
);

export const getActiveIssues = createSelector(getAllIssues, (issues) =>
    issues.filter((issue) => !issue.markedAsDelete)
);

export const getIssueIds = createSelector(
    selectIssuesState,
    fromIssues.selectIds
);

export const getLastIssueId = createSelector(
    getIssueIds,
    (ids) => ids[ids.length - 1]
);

export const getTotalIssues = createSelector(getIssueIds, (ids) => ids.length);

export const getSelectedIssueIds = createSelector(
    selectIssuesState,
    (state) => state.selected
);

export const getIssuesByIds = createSelector(
    getAllIssues,
    (issues: Issue[], props: { ids: string[] }) =>
        issues.filter((issue) => props.ids && props.ids.includes(issue.id))
);

export const getLastCreatedIssue = createSelector(
    selectIssuesState,
    (state) => state.lastCreatedIssue
);

export const getIssuesConflicts = createSelector(
    selectIssuesState,
    (state) => state.conflicts
);

export const getNextIssueConflict = createSelector(
    getIssuesConflicts,
    (conflicts) => (conflicts.length > 0 ? conflicts[0] : null)
);

export const getCurrentIssueConflict = createSelector(
    getIssuesConflicts,
    getCurrentIssueId,
    (conflicts, issueId) => conflicts.find((e) => e.itemOnServer.id === issueId)
);

export const getCurrentIssueConflictWithCommands = createSelector(
    getCurrentIssueConflict,
    getCurrentProjectId,
    getIssueCommands,
    getPlanCommands,
    (conflict, projectId, issueCommands, planCommands) => {
        const noConflict = !conflict;
        const noIssueCommands = !issueCommands || issueCommands.length === 0;
        const noPlanCommands = !planCommands || planCommands.length === 0;
        const noCommands = noIssueCommands && noPlanCommands;

        if (noConflict) {
            return undefined;
        }

        if (noCommands) {
            return conflict;
        }

        const mergedItemWantUpdate = IssuesStoreUtilsService.mergeIssuesWithCommands(
            {
                entities: [conflict.itemWantUpdate],
                issueCommands,
                planCommands,
                projectId,
            }
        )[0];

        return {
            ...conflict,
            itemWantUpdate: mergedItemWantUpdate,
        };
    }
);

export const getIssueById = createSelector(
    getIssueEntities,
    getCurrentProjectId,
    getLastCreatedIssue,
    getPlanCommandsForUnsaved,
    (entities, projectId, lastCreatedIssue, planCommands, props) => {
        if (!props.id || props.id === 'new') {
            let markedPlan = {};
            for (const command of planCommands) {
                markedPlan = {
                    ...markedPlan,
                    ...command.changes,
                };
            }
            // pre-fill the current, new issue from the last created one
            // to e.g. have the same location and craft for repeated issues
            return {
                ...new IssuePost(projectId, lastCreatedIssue),
                markedPlan,
            };
        }

        return entities[props.id];
    }
);

const getCurrentProjectAndItsId = createSelector(
    // in addition to getCurrentProject the
    // getCurrentProjectId selector is used here:
    // it provides the project id based on the url
    // so its directly available instead of after
    // the project request finishes
    getCurrentProjectId,
    getCurrentProject,
    (currentProjectId, currentProject) => [currentProjectId, currentProject]
);

export const getCurrentIssue = createSelector(
    getIssueEntities,
    getCurrentIssueId,
    getCurrentProjectAndItsId,
    getLastCreatedIssue,
    getIsConflict,
    getCurrentIssueConflictWithCommands,
    getSelectedConflictItem,
    getPlanCommandsForUnsaved,
    (
        entities,
        issueId,
        [projectId, project]: [string, Project],
        lastCreatedIssue,
        isConflict,
        currentConflict,
        selectedConflictItem,
        planCommands
    ) => {
        if (issueId === 'new') {
            let markedPlan = {};
            for (const command of planCommands) {
                markedPlan = {
                    ...markedPlan,
                    ...command.changes,
                };
            }
            return {
                // here, the projectId is used directly instead
                // of the later available project.id to keep
                // the previous behaviour
                ...new IssuePost(projectId, lastCreatedIssue),
                markedPlan,
                customAttributes: createCustomAttributesFromProject(project),
            };
        }

        if (isConflict && currentConflict) {
            return currentConflict[selectedConflictItem];
        } else if (currentConflict) {
            // normal issue list: show the itemWantUpdate there, if the
            // currently selected issue is part of a conflict
            return currentConflict.itemWantUpdate;
        }

        return entities[issueId];
    }
);

export const getCurrentRevision = createSelector(
    getCurrentRevisionDate,
    getCurrentIssue,
    (revisionDate, issue: any) =>
        ((issue || {}).revisions || []).find(
            (r) => r.createTime === revisionDate
        )
);

export const getCurrentDiaryIssue = createSelector(
    getIssueEntities,
    getCurrentIssueIdQuery,
    getCurrentProjectAndItsId,
    getLastCreatedIssue,
    getPlanCommandsForUnsaved,
    (entities, issueId, [projectId, project]: [string, Project], lastCreatedIssue, planCommands) => {
        if (issueId === 'new') {
            let markedPlan = {};
            for (const command of planCommands) {
                markedPlan = {
                    ...markedPlan,
                    ...command.changes,
                };
            }
            return {
                ...new IssuePost(projectId, lastCreatedIssue),
                markedPlan,
                customAttributes: createCustomAttributesFromProject(project),
            };
        } else {
            return entities[issueId];
        }
    }
);

export const getIssueFilters = createSelector(
    selectIssuesState,
    (state) => state.filters
);

export const getSortIssuesBy = createSelector(
    selectIssuesState,
    (state) => state.sortBy
);

export const getIssuesOrderAsc = createSelector(
    selectIssuesState,
    (state) => state.orderAsc
);

export const getIssuesLoading = createSelector(
    selectIssuesState,
    (state) => state.loading
);

export const getFilteredIssues = createSelector(
    getAllIssues,
    getIssuesOrderAsc,
    getSortIssuesBy,
    getContactsEntities,
    getIssueFilters,
    getAllLocations,
    (issues, orderAsc, sortBy, contacts, filters, locations) =>
        IssuesUtils.filterIssues({
            contacts,
            filters,
            sortBy,
            orderAsc,
            issues,
            locations,
        })
);

export const getIssueStatistics = createSelector(getFilteredIssues, (issues) =>
    IssuesUtils.generateIssueStatistics(issues)
);

export const getIssueStatesAllowedForUser = () =>
    createSelector(getIssueById, issueStatesAllowedForUser);

export const getIssuesInitialized = createSelector(
    selectIssuesState,
    (state) => state.initialized
);

export const getIssuesFullyInitialized = createSelector(
    getIssuesInitialized,
    (initialized) => Object.values(initialized).every((condition) => condition)
);

export const getIssuesConflictsInitialized = createSelector(
    getIssuesInitialized,
    (initialized) => initialized.conflicts
);

export const getIssueLoadingConflicts = createSelector(
    selectIssuesState,
    (state) => state.loadingConflicts
);

export const getIssueAttachmentMap = createSelector(
    selectIssuesState,
    (state) => state.attachmentMap
);

export const getCustomAttributesOfCurrentProject = createSelector(
    getCurrentProject,
    (project) => {
        return createCustomAttributesFromProject(project);
    }
);

export const getIssueMarkedPlan = createSelector(
    getIssueEntities,
    getCurrentIssueId,
    (issueEntites, issueId) => {
        return issueEntites[issueId]?.markedPlan
    }
)

export const getFileTypeForMarkedPlanInfo = createSelector(
    getIssueEntities,
    getCurrentIssueId,
    (issueEntites, issueId) => {
        return issueEntites[issueId]?.markedPlan?.plan;
    }
)
