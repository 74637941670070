import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { select, Store } from '@ngrx/store';
import {
    defer,
    from,
    identity,
    MonoTypeOperatorFunction,
    Observable,
    OperatorFunction,
    pipe,
} from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

import { AcceptUtils } from 'app/core/utils/accept-utils';
import { getIsDevice } from 'app/store/core/core.selectors';
import { IssuesState } from 'app/store/issues/issues.reducer';
import { environment } from 'environments/environment';

import { AddAsset } from './models/add-asset.model';
import { AddAssetResult } from './models/add-asset-result.model';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';

@Injectable({
    providedIn: 'root',
})
/**
 *
 * This service handles (high-level) files management
 * See also:
 *  * `FileService` for (low-level) communication with the API.
 *  * `FileStorageservice` for (low-level) operations with the local database,
 */
export class AddAssetService {
    private static optionalCancel(
        cancelBackendUpload$?: Observable<void>
    ): MonoTypeOperatorFunction<AddAssetResult[]> {
        const cancelAvailable = !!cancelBackendUpload$;
        if (cancelAvailable) {
            return takeUntil(cancelBackendUpload$);
        } else {
            return map(identity);
        }
    }

    private static mapApiResponse(): OperatorFunction<any, AddAssetResult> {
        return pipe(
            map((response) => response[0]),
            map((response) => {
                return {
                    id: response.id,
                    fileName: response.fileName,
                    fileSize: response.fileSize,
                    contentType: response.contentType,
                };
            })
        );
    }

    constructor(
        private http: HttpClient,
        private store: Store<IssuesState>,
        private fileStorageService: FileStorageService
    ) {}

    private storeAttachment(addAsset: AddAsset): Promise<AddAssetResult> {
        const { file } = addAsset;

        const tempId = 'NEW_' + AcceptUtils.generateGuid();

        const dbService$ = defer(() =>
            from(
                this.fileStorageService.storeAttachment({
                    type: 'blob-to-store',
                    attachmentId: tempId,
                    blob: file,
                    metadata: {
                        id: tempId,
                        contentType: file.type,
                        fileName: file.name,
                        fileSize: file.size,
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        id: tempId,
                        fileName: file.name,
                        contentType: file.type,
                        fileSize: file.size,
                    };
                })
            )
        );

        return dbService$.toPromise();
    }

    private prepareRequest(token: string, addAsset: AddAsset): Observable<any> {
        const { file } = addAsset;

        const formData = new FormData();
        formData.append('files', file, file.name);
        const headers = {
            Authorization: 'Bearer ' + token,
        };
        const url = `${environment.endpoints.api}/files/direct`;

        return this.http.post(url, formData, { headers });
    }

    private async uploadAsset(addAsset: AddAsset): Promise<AddAssetResult> {
        const token = await Preferences.get({ key: 'token' });
        const request$ = this.prepareRequest(token.value, addAsset);

        return request$
            .pipe(
                AddAssetService.optionalCancel(addAsset.cancelBackendUpload$),
                AddAssetService.mapApiResponse()
            )
            .toPromise();
    }

    async uploadAssetDbProxy(addAsset: AddAsset): Promise<AddAssetResult> {
        let entityId = addAsset.entityId;

        if (AcceptUtils.isLocalGuid(entityId)) {
            // the user added a new ticket and saved it completly in offline mode
            entityId = 'attachmentcache';
        }

        const withAdjustedEntityId: AddAsset = {
            ...addAsset,
            entityId,
        };

        const isDevice = await this.store
            .pipe(select(getIsDevice), first())
            .toPromise();

        if (isDevice) {
            return this.storeAttachment(withAdjustedEntityId);
        }

        return this.uploadAsset(withAdjustedEntityId);
    }
}
