import { LettersActions, LettersActionTypes } from './letters.actions';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { CommonTemplate } from 'app/shared/components/print-dialog/common-template';

export const adapter: EntityAdapter<CommonTemplate> = createEntityAdapter<CommonTemplate>();

export interface LettersState extends EntityState<CommonTemplate> {
    loading: boolean;
    selectedLetter: CommonTemplate;
}

export const initialState: LettersState = adapter.getInitialState({
    loading: false,
    selectedLetter: null,
});

export function reducer(state = initialState, action: LettersActions): LettersState {
    switch (action.type) {
        case LettersActionTypes.LOAD_LETTERS_REQUESTED:
            return { ...state, loading: true };

        case LettersActionTypes.LOAD_LETTERS_SUCCESS:
            return adapter.setAll(action.payload.letters, {
                ...state,
                loading: false
            });

        case LettersActionTypes.LOAD_LETTERS_ERROR:
            return { loading: false, ...state };

        case LettersActionTypes.SELECT_LETTER:
            return { ...state, selectedLetter: action.payload.letter };

        case LettersActionTypes.DESELECT_LETTER:
            return { ...state, selectedLetter: null };

        case LettersActionTypes.CREATE_LETTER_SUCCESS:
            return adapter.addOne(action.payload.letter, state);

        case LettersActionTypes.DELETE_LETTER_SUCCESS:
            return adapter.removeOne(action.payload.letter.id, state);

        case LettersActionTypes.UPDATE_LETTER_SUCCESS:
            return adapter.setOne(action.payload.letter, state);

        default:
            return state;
    }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
