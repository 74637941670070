import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    AfterViewInit,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { cloneDeep, map as _map } from 'lodash';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import {
    AutoSelectorDialogComponent,
    AutoSelectorDialogResult,
} from './auto-selector-dialog/auto-selector-dialog.component';
import { AutoSelectorDialogData } from './auto-selector-dialog/auto-selector-dialog.data';
import { AutoSelectorNoMatchComponent } from './auto-selector-no-match/auto-selector-no-match.component';
import { AutoSelectorConfig } from './auto-selector.config';
import { AutoSelectorService } from './auto-selector.service';

@Component({
    selector: 'asc-auto-selector',
    templateUrl: './auto-selector.component.html',
    styleUrls: ['./auto-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AutoSelectorComponent,
            multi: true,
        },
    ],
})
export class AutoSelectorComponent
    implements
        OnInit,
        OnChanges,
        AfterViewInit,
        ControlValueAccessor,
        OnDestroy {
    value: string | string[];
    onChange: (value: string | string[]) => void;
    isDisabled = false;

    @ContentChild(AutoSelectorNoMatchComponent, { static: true })
    noMatchComponent?: AutoSelectorNoMatchComponent;

    @Input()
    appearance = 'standard';

    @Input()
    items: any[];

    @Input()
    fallbackItems = [];

    @Input()
    required = false;

    @Input()
    dialogMode = false;

    @Input()
    config: AutoSelectorConfig = new AutoSelectorConfig({
        properties: { title: 'title' },
    });

    @Input()
    label: string;

    @Input()
    groupLabelNgClass = [];

    @Output()
    filteredItemsChange: EventEmitter<any[]> = new EventEmitter();

    @Output()
    finish: EventEmitter<void> = new EventEmitter();

    @ViewChild(MatAutocompleteTrigger, { static: true })
    autocompleteTrigger: MatAutocompleteTrigger;

    @ViewChild(MatInput, { static: true })
    filterInputElement: MatInput;

    @ViewChild(MatChipList, { static: true })
    chipList: MatChipList;

    filterControl = new FormControl('');

    selectedItems: any[];

    filteredItems = [];

    // tslint:disable-next-line: variable-name
    _isMobile = false;
    // will open a dialog instead of focus input
    mobileMode = false;

    hasGroups = false;

    floatLabel = this.dialogMode ? 'always' : 'auto';

    private unsubscribe: Subject<void> = new Subject();

    // Needed to close the dialog externally (when toggling modes)
    dialogRef: MatDialogRef<
        AutoSelectorDialogComponent,
        AutoSelectorDialogResult
    >;
    // Cache the last inputed filter value. (Needs to persist past a `blur` event)
    lastFilterInput: string = '';

    constructor(
        private autoSelectorService: AutoSelectorService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((result) => {
                this.isMobile = result.matches;
            });

        if (!this.config.properties.title) {
            console.error('Please provide titleProperty input');
        }

        this.filterControl.valueChanges
            .pipe(
                startWith<string>(''),
                map((value) => this.filterItems(cloneDeep(this.items), value)),
                tap((filteredItems) => (this.filteredItems = filteredItems)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.dialogMode) {
                this.filterInputElement.focus();
            }
        }, 400);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByFn = (index, item) => item[this.config.properties.id];

    trackByFnGroups = (index, group) => {
        return index;
    };

    openDialogOrFocus(input): void {
        if (this.mobileMode) {
            this.openDialog();
        } else {
            input.focus();
        }
    }

    openDialogOrRemove(item: any): void {
        if (this.mobileMode) {
            this.openDialog();
        } else {
            this.onRemove(item);
        }
    }

    get isMobile(): boolean {
        return this._isMobile;
    }

    set isMobile(isMobile: boolean) {
        this._isMobile = isMobile;
        this.mobileMode = isMobile && !this.dialogMode;
    }

    noop(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dialogMode) {
            this.floatLabel = this.dialogMode ? 'always' : 'auto';
        }
        if (changes.items) {
            this.hasGroups =
                this.items &&
                this.items.length > 0 &&
                this.items[0].hasOwnProperty('label') &&
                this.items[0].hasOwnProperty('items');
            if (this.hasGroups) {
                this.items.map((group) =>
                    _map(group, (value, key) => {
                        if (key === 'items') {
                            group.items = group.items.map((item) => {
                                item = { ...item, groupLabel: group.label };

                                return item;
                            });
                        }
                        return group;
                    })
                );
            }
        }
        this.mobileMode = this.isMobile && !this.dialogMode;
        switch (this.config.mode) {
            case 'multi':
                this.selectedItems = [];
                if ((!this.items && !this.fallbackItems) || !this.value) {
                    return;
                }

                for (const id of this.value) {
                    const groupItems = this.flatGroups(this.items);
                    let item = groupItems.find(
                        (i) => i?.[this.config.properties.id] === id
                    );
                    if (!item) {
                        if (this.fallbackItems.length > 0) {
                            const fallbackItems = this.flatGroups(
                                this.fallbackItems
                            );
                            item = fallbackItems.find(
                                // see few lines above
                                // tslint:disable-next-line: triple-equals
                                (i) =>
                                    i?.[this.config.properties.id] == this.value
                            );
                        }
                    }
                    if (item) {
                        this.selectedItems.push(item);
                    } else {
                        this.selectedItems.push({
                            [this.config.properties.title]: '...',
                        });
                    }
                }
                break;
            case 'single':
                const items = this.flatGroups(this.items);
                if (this.config.tree) {
                    this.selectedItems = this.autoSelectorService.buildTree(
                        items,
                        this.value
                    );
                } else {
                    let item = items.find(
                        // fixes a lot of trouble with queryParam to number id transforming
                        (i) => i?.[this.config.properties.id] === this.value
                    );
                    if (!item) {
                        if (this.fallbackItems.length > 0) {
                            const fallbackItems = this.flatGroups(
                                this.fallbackItems
                            );
                            item = fallbackItems.find(
                                // see few lines above
                                (i) =>
                                    i?.[this.config.properties.id] ===
                                    this.value
                            );
                        }
                    }
                    if (item) {
                        this.selectedItems = [item];
                    }
                }
        }
        this.filterControl.patchValue('');
        this.syncErrorState();
    }

    openDialog(): void {
        if (this.dialogMode || this.isDisabled) {
            return;
        }

        const data: AutoSelectorDialogData = {
            items: cloneDeep(this.items),
            fallbackItems: this.fallbackItems,
            value: this.value,
            config: this.config,
            noMatchComponent: this.noMatchComponent,
        };

        const dialogRef = this.dialog.open(AutoSelectorDialogComponent, {
            panelClass: 'auto-selector-dialog',
            data,
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            autoFocus: false,
        });
        this.dialogRef = dialogRef;

        dialogRef.afterClosed().subscribe((result) => {
            this.dialogRef = null;
            this.writeValue(result); // tell auto-selector result of dialog
            this.onChange(result); // tell "outer world" (= form) result of dialog
            this.syncErrorState();
        });
    }

    flatGroups(items: any[]): any[] {
        let flatItems = [];
        if (
            items &&
            items.length &&
            items.length > 0 &&
            items[0].hasOwnProperty('label') &&
            items[0].hasOwnProperty('items')
        ) {
            for (const group of items) {
                flatItems = [...flatItems, ...group.items];
            }
        } else {
            flatItems = items;
        }
        return flatItems;
    }

    writeValue(value: string): void {
        this.value = value;
        const items = this.flatGroups(this.items);
        let item = items.find((i) => i?.[this.config.properties.id] === value);

        if (!item) {
            if (this.fallbackItems.length > 0) {
                const fallbackItems = this.flatGroups(this.fallbackItems);
                item = fallbackItems.find(
                    // see few lines above
                    // tslint:disable-next-line: triple-equals
                    (i) => i?.[this.config.properties.id] == this.value
                );
            }
        }
        if (!item) {
            if (value) {
                item = {
                    [this.config.properties.title]: value,
                };
            }
        }
        switch (this.config.mode) {
            case 'single':
                if (this.config.tree) {
                    this.selectedItems = this.autoSelectorService.buildTree(
                        items,
                        this.value
                    );
                } else {
                    this.selectedItems = [];
                    if (item) {
                        this.selectedItems = [item];
                    }
                }
                break;
            case 'multi':
                this.selectedItems = [];
                if (value && value.length) {
                    for (const id of value) {
                        let selectedItem = items.find(
                            (i) => i?.[this.config.properties.id] === id
                        );
                        if (!selectedItem) {
                            selectedItem = this.fallbackItems.find(
                                (i) => i?.[this.config.properties.id] === id
                            );
                        }
                        if (selectedItem) {
                            this.selectedItems.push(selectedItem);
                        } else {
                            this.selectedItems.push({
                                [this.config.properties.title]: '...',
                            });
                        }
                    }
                }
                break;
        }

        this.filterControl.patchValue('');
        this.chipList.errorState = false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // user interacted with a control or not
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        setTimeout(() => {
            this.autocompleteTrigger.setDisabledState(isDisabled);
        });
    }

    onBlur(event, filterInput): string | undefined {
        function isParentMatOption(e: Element | undefined | null): boolean {
            if (e && e.localName === 'mat-option') {
                return true;
            }
            if (e && e.parentElement) {
                return isParentMatOption(e.parentElement);
            }
            return false;
        }
        const fromClick: boolean = isParentMatOption(
            event && event.relatedTarget
        );

        this.syncErrorState();

        // Extract current dialog input, to capture (and return) the input
        const dialogInput: string | undefined =
            this.dialogRef &&
            this.dialogRef.componentInstance &&
            this.dialogRef.componentInstance.getInput();
        let ret: string | undefined =
            this.filterControl.value || dialogInput || this.lastFilterInput;

        // do not reset on click, this conflicts with setItem
        if (!fromClick) {
            if (filterInput) {
                filterInput.value = '';
            }
            this.lastFilterInput = this.filterControl.value;
            this.filterControl.patchValue('');
            if (!this.dialogMode) {
                this.autocompleteTrigger.closePanel();
            }
        }

        if (this.dialogRef) {
            // (Forcefully) close out the dialog
            this.dialogRef.close();
        }

        return ret;
    }

    onRemove(item: any): void {
        switch (this.config.mode) {
            case 'single':
                if (this.config.tree) {
                    const index = this.selectedItems.findIndex(
                        (i) =>
                            i?.[this.config.properties.id] ===
                            item[this.config.properties.id]
                    );
                    this.selectedItems = this.selectedItems.slice(0, index);
                    const lastItem = this.selectedItems[
                        this.selectedItems.length - 1
                    ];
                    let newValue = '';

                    if (lastItem) {
                        newValue = lastItem[this.config.properties.id];
                    }

                    this.onChange(newValue);
                    setTimeout(() => this.autocompleteTrigger.openPanel());
                    this.filterControl.patchValue('');
                } else {
                    this.selectedItems = [];
                    this.onChange('');
                    // if missbehaviour, change back to 240
                    setTimeout(() => this.autocompleteTrigger.openPanel());
                }
                break;
            case 'multi':
                this.selectedItems = this.selectedItems.filter(
                    (i) =>
                        i?.[this.config.properties.id] !==
                        item[this.config.properties.id]
                );
                this.onChange(
                    this.selectedItems.map(
                        (i) => i?.[this.config.properties.id]
                    )
                );

                setTimeout(() => this.autocompleteTrigger.openPanel());
                this.filterControl.patchValue('');
                break;
        }

        this.syncErrorState();
    }

    syncErrorState(): void {
        if (this.required) {
            if (this.selectedItems) {
                this.chipList.errorState = this.selectedItems.length === 0;
            } else {
                this.chipList.errorState = true;
            }
        }
    }

    setItem(value: any): void {
        switch (this.config.mode) {
            case 'single':
                if (this.config.tree) {
                    this.selectedItems.push(value);
                    this.onChange(value[this.config.properties.id]);
                    if (!this.hasChild(value[this.config.properties.id])) {
                        this.finish.emit();
                    }
                    if (this.hasChild(value[this.config.properties.id])) {
                        setTimeout(() => this.autocompleteTrigger.openPanel());
                    }
                } else {
                    const items = this.flatGroups(this.items);
                    const item = items.find(
                        (i) =>
                            i?.[this.config.properties.id] ===
                            value[this.config.properties.id]
                    );
                    this.selectedItems = [item];
                    this.onChange(value[this.config.properties.id]);
                    this.finish.emit();
                }

                break;
            case 'multi':
                this.selectedItems.push(value);
                const currentIds = this.selectedItems.map(
                    (i) => i?.[this.config.properties.id]
                );
                this.onChange([...currentIds]);

                if (
                    this.config.tree &&
                    !this.hasChild(value[this.config.properties.id])
                ) {
                    this.finish.emit();
                }

                if (
                    !this.config.tree ||
                    this.hasChild(value[this.config.properties.id])
                ) {
                    setTimeout(() => this.autocompleteTrigger.openPanel());
                }
                break;
        }
        this.filterInputElement.value = '';
        this.filterControl.patchValue('');

        this.syncErrorState();
    }

    hasChild(itemId: string): boolean {
        const items = this.flatGroups(this.items);
        if (this.config.mode === 'single') {
            const hasChild = items.find(
                (l) => l[this.config.properties.parentId] === itemId
            );
            return hasChild ? true : false;
        }
        const child = items.find(
            (item) =>
                item[this.config.properties.parentIds] &&
                item[this.config.properties.parentIds].includes(itemId)
        );
        return child ? true : false;
    }

    filterItems(items: any[], value: string, optionsFilter?: string): any[] {
        /**
         * if items EMPTY or
         * input value is NOT a STRING
         */
        if (typeof value !== 'string' || items.length === 0) {
            this.filteredItemsChange.emit(items);
            return items;
        }

        const filterValue = (value || '').toLowerCase();
        const selectedItems = this.selectedItems;
        const idProperty = this.config.properties.id;
        // prepare config
        const { mode, tree, duplicates } = this.config;
        const params = {
            mode,
            selectedItems,
            duplicates,
            tree,
            idProperty,
        };

        let filteredItems;
        if (this.hasGroups) {
            filteredItems = items.map((group) => {
                group.items = group.items
                    .filter((item) =>
                        this.autoSelectorService.filterByLocationTree({
                            ...params,
                            item,
                        })
                    )
                    .filter((item) =>
                        this.autoSelectorService.filterByParent({
                            ...params,
                            item,
                        })
                    )
                    .filter((item) =>
                        this.autoSelectorService.filterDuplicates({
                            ...params,
                            item,
                        })
                    )
                    .filter((item) => {
                        if (!item || !item[this.config.properties.title]) {
                            return false;
                        }

                        return this.checkIfInputContainsFilteredValue(
                            item[this.config.properties.title].toLowerCase(),
                            filterValue
                        );
                    });
                return group;
            });
        } else {
            filteredItems = items
                .filter((item) =>
                    this.autoSelectorService.filterByLocationTree({
                        ...params,
                        item,
                    })
                )
                .filter((item) =>
                    this.autoSelectorService.filterByParent({
                        ...params,
                        item,
                    })
                )
                .filter((item) =>
                    this.autoSelectorService.filterDuplicates({
                        ...params,
                        item,
                    })
                )
                .filter((item) => {
                    if (!item || !item[this.config.properties.title]) {
                        return false;
                    }

                    return this.checkIfInputContainsFilteredValue(
                        item[this.config.properties.title].toLowerCase(),
                        filterValue
                    );
                });
        }
        this.filteredItemsChange.emit(filteredItems);
        return filteredItems;
    }

    private checkIfInputContainsFilteredValue(str, search) {
        let searchIndex = 0;

        return !search.split('').some((char) => {
            if (char === ' ') {
                return false;
            }
            const charIndexInSearch = str.indexOf(char, searchIndex);
            if (charIndexInSearch === -1) {
                return true;
            }
            searchIndex = charIndexInSearch + 1;

            return false;
        });
    }
}
