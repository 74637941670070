import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgLetModule } from '@ngrx-utils/store';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsModule } from 'app/main/notifications/notifications.module';
import { ResponsiveToolbarComponent } from './responsive-toolbar.component';

@NgModule({
    imports: [
        FuseSharedModule,
        TranslateModule,
        NgLetModule,
        MatBadgeModule,
        MatInputModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        NotificationsModule
    ],
    declarations: [ResponsiveToolbarComponent],
    exports: [ResponsiveToolbarComponent]
})
export class ResponsiveToolbarModule {}
