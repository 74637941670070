import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivitiesService, IActivity } from 'app/shared/services/activities/activities.service';

@Component({
    selector: 'acc-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, AfterViewInit {

    activities: IActivity[] = [];

    constructor(
        private activitiesService: ActivitiesService,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.activitiesService.activitiesList$.subscribe( newList => {
            this.activities = newList;
        });
    }


    deleteActivity(activity: IActivity): void {
        this.activitiesService.deleteActivity(activity);
    }
}
