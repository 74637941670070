import { Injectable, NgZone } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationGerman } from 'app/navigation/i18n/de';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationBrazilianPortuguese } from 'app/navigation/i18n/pt-br';
import { DemoService } from 'app/shared/services/demo/demo.service';
import { adminNavigation } from './admin-navigation';
import { navigation } from './navigation';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getCurrentProject } from 'app/store/projects/projects.selectors';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    constructor(
        private store: Store,
        private fuseNavigationService: FuseNavigationService,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private demoService: DemoService,
        private zone: NgZone
    ) {}

    init(): void {
        // Register main navigation
        this.fuseNavigationService.register('main', navigation);
        // Register admin navigation
        this.fuseNavigationService.register('admin', adminNavigation);
        // Set main navigation
        this.fuseNavigationService.setCurrentNavigation('main');

        this.store
            .select(getCurrentProject)
            .pipe(
                tap((project) => {
                    if (project) {
                        const backlinkItem = this.fuseNavigationService.getNavigationItem(
                            'backlink'
                        );
                        this.setBacklinkNavigationItemUrl(project.backlink);
                        if (backlinkItem) {
                            this.fuseNavigationService.updateNavigationItem(
                                'backlink',
                                { hidden: !project?.backlink }
                            );
                        }
                    }
                })
            )
            .subscribe();
    }

    setNavigation(section: string): void {
        this.zone.run(() => {
            setTimeout(() => {
                this.fuseNavigationService.setCurrentNavigation(section);

                this.demoService.isDemoMode$
                    .pipe(
                        take(1),
                        tap((isDemoMode) => {
                            this.fuseNavigationService.getNavigationItem(
                                'order'
                            ).hidden = !isDemoMode;
                            this.fuseNavigationService.getNavigationItem(
                                'help'
                            ).hidden = !isDemoMode;
                        })
                    )
                    .subscribe();
            });

            setTimeout(() => {
                this.fuseTranslationLoaderService.loadTranslations(
                    navigationEnglish,
                    navigationGerman,
                    navigationBrazilianPortuguese
                );
            });
        });
    }

    setBacklinkNavigationItemUrl(url: string) {
        const itemRef = this.fuseNavigationService.getNavigationItem(
            'backlink'
        );
        if (!!itemRef) {
            itemRef.url = url;
        }
    }
}
