import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Logout } from 'app/store/auth/auth.actions';
import { AuthState } from 'app/store/auth/auth.reducer';
import { getCurrentUser, getIsLoggedIn } from 'app/store/auth/auth.selectors';
import { SetLanguage } from 'app/store/core/core.actions';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AuthState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(getIsLoggedIn),
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.store.dispatch(new Logout());
                } else {
                    this.store
                        .pipe(select(getCurrentUser))
                        .subscribe((user) => {
                            if (user?.contact?.language) {
                                const langCode = user.contact.language.toLowerCase();

                                const languageEntry = environment.languages.find(
                                    (l) => l.langCode === langCode
                                );

                                const language = languageEntry
                                    ? languageEntry.code
                                    : 'en';

                                this.store.dispatch(
                                    new SetLanguage({ language })
                                );
                            }
                        });
                }
            })
        );
    }
}
