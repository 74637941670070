import { Action } from '@ngrx/store';
import { Craft } from './craft';

export enum CraftsActionTypes {
    LOAD_CRAFTS_REQUESTED = '[Crafts] Load Crafts Requested',
    LOAD_CRAFTS_SUCCESS = '[API] Load Crafts Success',
    LOAD_CRAFTS_ERROR = '[API] Load Crafts Error',
}

export class LoadCraftsRequested implements Action {
    readonly type = CraftsActionTypes.LOAD_CRAFTS_REQUESTED;
}

export class LoadCraftsSuccess implements Action {
    readonly type = CraftsActionTypes.LOAD_CRAFTS_SUCCESS;
    constructor(public payload: { crafts: Craft[] }) {}
}

export class LoadCraftsError implements Action {
    readonly type = CraftsActionTypes.LOAD_CRAFTS_ERROR;
    constructor(public payload: { error: any }) {}
}

export type CraftsActions =
    | LoadCraftsRequested
    | LoadCraftsSuccess
    | LoadCraftsError;
