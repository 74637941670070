import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { getIsDevice } from 'app/store/core/core.selectors';
import { SyncState } from 'app/store/sync/sync.reducer';
import { getProjectsToSync } from 'app/store/sync/sync.selectors';
import { environment } from 'environments/environment';

@Component({
    selector: 'acc-offline-project-grid',
    templateUrl: 'offline-project-grid.component.html',
    styleUrls: ['offline-project-grid.component.scss'],
})
export class OfflineProjectGridComponent {
    assetUrl = environment.endpoints.asset;

    constructor(private store: Store<SyncState>) {}

    @Input()
    public cols: number;

    @Output()
    public archived = new EventEmitter<Project>();

    @Output()
    public updated = new EventEmitter<Project>();

    public orderedProjects: Project[];
    projectsToSync$ = this.store.pipe(select(getProjectsToSync));
    isDevice$ = this.store.pipe(select(getIsDevice));

    @Input()
    set projects(projects: Project[]) {
        this.orderedProjects = [];
        const activeProjects = projects.filter(
            (project) => !project.markedAsDelete
        );
        const archivedProjects = projects.filter(
            (project) => project.markedAsDelete
        );

        this.orderedProjects.push(...activeProjects, ...archivedProjects);
    }
}
