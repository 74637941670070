import { Collections } from '../database';
import { migrationRanFor } from '../fix-collections.util';
import {
    ProjectDocTypeV0,
    ProjectDocTypeV1,
    ProjectDocTypeV2,
} from './project.document';

function addCustomAttributes(oldDoc: ProjectDocTypeV0): ProjectDocTypeV1 {
    // see notes on the issue migration for custom attributes

    const newDoc: ProjectDocTypeV1 = {
        ...oldDoc,
        customAttributeTemplateIds: [],
        customAttributeTemplates: [],
    };

    delete (newDoc as any)._id;

    migrationRanFor(Collections.Projects);
    migrationRanFor(Collections.ProjectTemplates);

    return newDoc;
}

function addChecklistTemplates(oldDoc: ProjectDocTypeV1): ProjectDocTypeV2 {
    // see notes on the issue migration for checklists / custom attributes

    const newDoc: ProjectDocTypeV2 = {
        ...oldDoc,
        checklistTemplatesIds: [],
        checklistTemplates: [],
    };

    delete (newDoc as any)._id;

    migrationRanFor(Collections.Projects);
    migrationRanFor(Collections.ProjectTemplates);

    return newDoc;
}

export const projectMigrations = {
    1: addCustomAttributes,
    2: addChecklistTemplates,
};
