import { PredefinedFormLetterTemplate } from 'app/core/rest-api';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpandedFormLetterList } from '../print-dialog-util';

@Component({
  selector: 'acc-print-dialog-letter-selection',
  templateUrl: './print-dialog-letter-selection.component.html',
  styleUrls: ['./print-dialog-letter-selection.component.scss']
})
export class PrintDialogLetterSelectionComponent implements OnInit {
  @Input()
  purpose: PredefinedFormLetterTemplate.PurposeEnum;

    @Input()
    currentExpandedList: ExpandedFormLetterList;
    @Output()
    currentExpandedListChange = new EventEmitter<ExpandedFormLetterList>();

    constructor(){ }

  ngOnInit(): void {

  }

   public get expandedFormLetterList(): typeof ExpandedFormLetterList {
        return ExpandedFormLetterList;
   }

   setCurrentExpandedList(expandedList: ExpandedFormLetterList ): void {
       this.currentExpandedListChange.emit(expandedList);
       this.currentExpandedList = expandedList;
   }


    toggleExpandedList(parameterExpandedList: ExpandedFormLetterList ): void{

        if (this.currentExpandedList === ExpandedFormLetterList.AllClosed
            || this.currentExpandedList !== parameterExpandedList
        )  { // expand the one, that the user clicked on
          this.setCurrentExpandedList(parameterExpandedList);

        } else { // current === param, toggle to close
            this.setCurrentExpandedList(ExpandedFormLetterList.AllClosed);
        }
    }
}
