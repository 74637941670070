import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import PullToRefresh from 'pulltorefreshjs';

@Component({
    selector: 'ngx-pull-to-refresh',
    templateUrl: './pull-to-refresh.component.html',
    styleUrls: ['./pull-to-refresh.component.scss']
})
export class PullToRefreshComponent implements OnInit, OnDestroy {
    instance: any;

    @Output()
    refresh = new EventEmitter<void>();

    @Input()
    className: string;

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        if (!this.className) {
            console.log('must provide same className as class!');
            return;
        }
        let mainElement = this.className;
        if (mainElement.substring(0, 1) !== '.') {
            mainElement = '.' + this.className;
        }
        const instructionsPullToRefresh = this.translate.instant(
            'APP.PULL_TO_REFRESH'
        );
        const instructionsReleaseToRefresh = this.translate.instant(
            'APP.RELEASE_TO_REFRESH'
        );
        const instructionsRefreshing = this.translate.instant('APP.REFRESHING');
        this.instance = PullToRefresh.init({
            mainElement,
            instructionsPullToRefresh,
            instructionsReleaseToRefresh,
            instructionsRefreshing,

            onRefresh: () => this.refresh.emit()
        });
    }

    ngOnDestroy(): void {
        if (this.instance) {
            this.instance.destroy();
        }
    }
}
