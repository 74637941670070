<acc-project-sync-status
    *ngIf="isDevice"
    [markedForSync]="markedForSync$ | async"
    class="project-sync-status"
></acc-project-sync-status>

<acc-asset-tile
    fxFlex
    fxLayout="row"
    [hideMenuButton]="true"
    *ngIf="!loadingGallery"
    [src]="galleryItems[0].data.src"
    [thumb]="galleryItems[0].data.thumb"
    [routerLink]="['/projects', project.id, 'issues']"
    [label]="''"
    fxLayoutAlign="center start"
>
    <div class="w-100-p" fxLayoutAlign="space-between center" fxLayout="row">
        <p class="m-0" data-test="project-name">{{ project.title }}</p>

        <div fxLayout="row">
            <button
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu"
                data-test="project-more-option-button"
            >
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button
                    [disabled]="
                        !(isOnline$ | async) ||
                        (isBacklinkSetInProject$(project.id) | async)
                    "
                    mat-menu-item
                    (click)="updated.next(project)"
                    data-test="project-edit-option-button"
                >
                    <mat-icon>edit</mat-icon>
                    <span translate>PROJECT.EDIT_PROJECT</span>
                </button>
                <button
                    mat-menu-item
                    (click)="archived.next(project)"
                    *ngIf="(isAdmin$ | async) && !project.markedAsDelete"
                    data-test="project-archive-project-option-button"
                >
                    <mat-icon>archive</mat-icon>
                    <span translate>PROJECT.ARCHIVE_PROJECT</span>
                </button>

                <button
                    *ngIf="isDevice"
                    mat-menu-item
                    (click)="toggleSync()"
                    [disabled]="!(isOnline$ | async)"
                >
                    <mat-icon>{{
                        (markedForSync$ | async) ? 'close' : 'check'
                    }}</mat-icon>
                    <span>
                        {{
                            (markedForSync$ | async)
                                ? ('APP.UNMARK_FOR_SYNC' | translate)
                                : ('APP.MARK_FOR_SYNC' | translate)
                        }}
                    </span>
                </button>
            </mat-menu>
        </div>
    </div>
</acc-asset-tile>

<div *ngIf="loadingGallery" fxLayout="row" fxLayoutAlign="center center" fxFlex>
    <mat-progress-spinner
        color="accent"
        diameter="40"
        mode="indeterminate"
    ></mat-progress-spinner>
</div>
