<div class="file-dialog">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="mat-typography m-0"
                        fxLayout="column"
                        fxLayoutAlign="center">
        <file-pond (onaddfile)="pondHandleAddFile($event)"
                   [options]="pondOptions"></file-pond>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button
                mat-dialog-close>{{ 'APP.CANCEL' | translate }}</button>
        <button mat-button
                (click)="upload()"
                [disabled]="!imgSelected || uploading">{{ 'APP.UPLOAD' | translate}}</button>
    </mat-dialog-actions>
</div>