import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger } from '@angular/material/menu';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { select, Store } from '@ngrx/store';
import { Notification } from 'app/core/rest-api';
import { SetSearch } from 'app/store/core/core.actions';
import { getSearch } from 'app/store/core/core.selectors';
import { MarkNotificationsAsReadRequest } from 'app/store/notifications/notifications.actions';
import { NotificationsState } from 'app/store/notifications/notifications.reducer';
import { getUnreadNotifications } from 'app/store/notifications/notifications.selectors';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'acc-responsive-toolbar',
    templateUrl: 'responsive-toolbar.component.html',
    styleUrls: ['responsive-toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResponsiveToolbarComponent implements OnDestroy, OnInit {
    notifications$: Observable<Notification[]>;
    notificationBadgeCount: number;
    showSearch = false;

    searchControl = new UntypedFormControl('');

    private _unsubscribeAll = new Subject<void>();

    @ViewChild(MatMenuTrigger, { static: true })
    notificationsMenuTrigger: MatMenuTrigger;

    @ViewChild(MatInput, { static: false })
    searchInput: MatInput;

    constructor(
        private fuseSidebarService: FuseSidebarService,
        private store: Store<NotificationsState>
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(getSearch),
                takeUntil(this._unsubscribeAll),
                tap(search =>
                    this.searchControl.patchValue(search, {
                        emitEvent: false
                    })
                )
            )
            .subscribe();

        this.searchControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                tap(search => this.store.dispatch(new SetSearch({ search })))
            )
            .subscribe();
        this.notifications$ = this.store.pipe(select(getUnreadNotifications));
        this.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(notifications => {
                this.notificationBadgeCount = notifications.length;
            });
    }

    clearSearch(): void {
        this.searchControl.patchValue('');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key: string): void {
        this.fuseSidebarService.getSidebar(key).toggleOpen();
    }

    markNotificationsAsRead(): void {
        this.store.dispatch(new MarkNotificationsAsReadRequest());
    }

    activateSearch(): void {
        this.showSearch = true;
        setTimeout(() => {
            this.searchInput.focus();
        });
    }

    disableSearch(): void {
        this.showSearch = false;
    }

    temporaryFixIOS(): void {
        // Related to the Angular bug from https://github.com/angular/components/issues/11677
        // When this bug is fixed, this should be removed
        const styleNode = document.createElement('style');
        styleNode.type = 'text/css';
        styleNode.id = 'panel-fix';
        styleNode.appendChild(
            document.createTextNode(
                '.mat-menu-panel{overflow: initial !important;}'
            )
        );
        document.getElementsByTagName('head')[0].appendChild(styleNode);
        setTimeout(() => {
            styleNode.remove();
        }, 300);
    }
}
