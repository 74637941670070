import { Pipe, PipeTransform, ɵConsole } from '@angular/core';
import { Location as FlinkLocation } from 'app/core/rest-api';

@Pipe({ name: 'getLocationTreeByPosition' })
export class GetLocationTreeByPositionPipe implements PipeTransform {
    transform(locations: FlinkLocation[], position: string): FlinkLocation[] {
        let locationTree = [];
        let currentItem = locations.find(l => l.id === position);
        if (!currentItem) {
            return;
        }
        while (currentItem.parentID) {
            locationTree = [currentItem, ...locationTree];
            const parent = locations.find(l => l.id === currentItem.parentID);
            if (parent) {
                currentItem = parent;
            } else {
                currentItem = {
                    projectId: currentItem.projectId,
                    title: 'P-ID? ' + currentItem.parentID
                };
            }
        }
        locationTree = [currentItem, ...locationTree];

        return locationTree;
    }
}
