import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectPartner } from 'app/core/rest-api';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { ProjectPartnerDocType } from './project-partners.document';

@Injectable({
    providedIn: 'root',
})
export class ProjectPartnerDbService extends EntityDbService<
    ProjectPartner,
    ProjectPartnerDocType
> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.ProjectPartners
        );
    }

    getAttachmentIdsFromApiItem(projectPartner: ProjectPartner): string[] {
        return [];
    }

    apiItemToSchema(projectPartner: ProjectPartner): ProjectPartnerDocType {
        const {
            id,
            creationDate,
            markedAsDelete,
            updateDateTime,
            projectId,
            roles,
            creatorId,
            craft,
            contactId,
            fourEyesPrinciple,
            externalResponsible,
            externalResponsibleForProjectPartnerId,
        } = projectPartner;
        return {
            id,
            creationDate,
            markedAsDelete,
            updateDateTime,
            projectId,
            roles,
            creatorId,
            craft,
            contactId,
            fourEyesPrinciple,
            externalResponsible,
            externalResponsibleForProjectPartnerId,
        };
    }
}
