import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { TranslateService } from '@ngx-translate/core';

import {
    FormLetterTemplate,
    PredefinedFormLetterTemplate,
    RTFTemplate,
} from 'app/core/rest-api';
import { unreachable_safe } from 'app/core/utils/ts-utils';
import {
    CreateLetter,
    CreateLetterError,
    CreateLetterSuccess,
    DeleteLetter,
    LettersActionTypes,
    UpdateLetter,
    UpdateLetterError,
    UpdateLetterSuccess,
} from 'app/store/letters/letters.actions';
import { LettersState } from 'app/store/letters/letters.reducers.ts';
import { getTemplateSourceLetter } from 'app/store/letters/letters.selectors';
import { environment } from 'environments/environment';

import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    CommonTemplate,
    isFormLetterTemplate,
    isFormLetterTemplateOrPredefined,
    isPredefinedFormLetterTemplate,
    NewFormLetterTemplate,
    NewFormLetterTemplateType,
} from '../components/print-dialog/common-template';
import { Actions, ofType } from '@ngrx/effects';
import { delay, map, take } from 'rxjs/operators';

/**
 * Utility constant for the default guid from the backend.
 */
const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

/**
 * A utility type for the custom attribute maps of both new and old form
 * letters.
 */
type CustomMap =
    | PredefinedFormLetterTemplate['customAttributes']
    | FormLetterTemplate['customAttributes']
    | RTFTemplate['customKeyValuePairs'];

/**
 * Parameter object for the {@link FormLetterService.saveAsCustomTemplate}
 * method.
 */
export interface SaveAsCustomTemplate {
    /**
     * The template to use as source for the copy.
     */
    sourceTemplate: NewFormLetterTemplate;

    /**
     * The new title for the template. It will be shown in the form letter
     * lists in both the print dialog and the form letter settings.
     */
    templateTitle: string;

    /**
     * The new custom attributes default values for the template. Please note,
     * that the keys from sourceTemplate's customAttributes map are used to
     * access this customAttributes map.
     */
    newCustomAttributeValues: { [key: string]: any };
}

/**
 * Parameter object for the {@link FormLetterService.updateCustomTemplate}
 * method.
 */
export interface UpdateCustomTemplate {
    /**
     * The template to update. It should usually be a custom template.
     */
    template: FormLetterTemplate;

    /**
     * The new title for the template.
     */
    updatedTemplateTitle: string;

    /**
     * See note on {@link SaveAsCustomTemplate.newCustomAttributeValues}.
     */
    updatedCustomAttributeValues: { [key: string]: any };
}

/**
 * The form letter service provides utility methods used by both the print
 * dialogs and the form letter settings. This includes showing previews,
 * saving copies and updating existing form letters.
 */
@Injectable({ providedIn: 'root' })
export class FormLetterService {
    constructor(
        private store: Store<LettersState>,
        private actions$: Actions,
        // for previews
        private gallery: Gallery,
        private lightbox: Lightbox,
        // for dialogs (e.g. confirmation for delete)
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {}

    /**
     * Generate the preview URL pointing to the preview image. If the preview
     * file id is not set or is the default guid.
     *
     * @param previewId The file id of the preview image.
     */
    private static generatePreviewURL(previewId?: string): string | null {
        const previewIdValid: boolean = !!previewId && previewId !== EMPTY_GUID;
        return previewIdValid
            ? `${environment.endpoints.api}/files/direct/${previewId}`
            : null;
    }

    /**
     * Determine if the attribute behind the given key is a base attribute and
     * for example should be shown on the first page of the dialogs.
     *
     * @param template The template whose attribute to check.
     * @param key The key of the attribute.
     * @param customMap The map containing the custom attributes of the form
     * letter.
     */
    private static isKeyBaseAttribute(
        template: CommonTemplate,
        key: string,
        customMap: CustomMap
    ): boolean {
        // for predefined and (custom) formLetters
        if (template.templateType !== 'RTFTemplate') {
            // hidden attributes
            if (!customMap[key]['shownInPrintDialog']) {
                return false;
            }

            // most of the attributes for these templates are so-called base
            // attributes and appear on the first page on the dialog,
            // except these:
            const isNotBaseAttribute: string[] = [
                'IncludeSignature',
                'IncludeProtocol',
            ];

            return !isNotBaseAttribute.includes(key);

            // RTF formLetters
        } else {
            // rtf object includes mixed elements
            // most elements in it are not displayed in the dialogs at all
            // except of these: (which are also shown on the first page)
            const isOnFirstPage: string[] = ['Contact', 'Text'];

            const obj = customMap[key];
            const type = obj[Object.keys(obj)[0]];

            return isOnFirstPage.includes(type);
        }
    }

    /**
     * Open the preview image of the given new HTML based form letter template
     * using the gallery & lightbox dialog.
     *
     * @param formLetter The form letter to show the preview for.
     */
    public openPreview(formLetter: NewFormLetterTemplate): void {
        if (isPredefinedFormLetterTemplate(formLetter)) {
            const previewUrl = FormLetterService.generatePreviewURL(
                (formLetter as PredefinedFormLetterTemplate)?.previewFileId
            );
            this.openImageGallery(previewUrl);
        } else if (isFormLetterTemplate(formLetter)) {
            // if "FormLetter" -> get FileID from the predefinedTemplate that it is based on
            this.store
                .select(
                    getTemplateSourceLetter(
                        (formLetter as FormLetterTemplate)?.templateSourceId
                    )
                )
                .subscribe((template) => {
                    const previewUrl = FormLetterService.generatePreviewURL(
                        (template as PredefinedFormLetterTemplate)
                            ?.previewFileId
                    );
                    this.openImageGallery(previewUrl);
                });
        } else {
            unreachable_safe(formLetter);
        }
    }

    /**
     * Open the image gallery dialog using the given preview URL.
     *
     * @param previewUrl The preview URL of the image to use in the dialog.
     */
    private openImageGallery(previewUrl: string | null): void {
        const items: GalleryItem[] = [
            new ImageItem({ src: previewUrl || '', thumb: previewUrl || '' }),
        ];
        const lightboxRef = this.gallery.ref('lightbox');
        lightboxRef.setConfig({
            loadingMode: 'indeterminate',
            loadingStrategy: 'lazy',
        });
        lightboxRef.load(items);
        this.lightbox.open(0);
    }

    /**
     * Delete the given form letter after asking the user for confirmation
     * via an Angular Material dialog.
     *
     * @param template The template to delete after the user's confirmation.
     */
    public deleteWithConfirmation(template: CommonTemplate): void {
        const title = this.translateService.instant(
            'SETTINGS.FORM_LETTERS.DELETE_FORM_LETTER_TITLE'
        );
        const question = this.translateService.instant(
            'SETTINGS.FORM_LETTERS.DELETE_FORM_LETTER_QUESTION',
            {
                formLetterTitle: template.title,
            }
        );

        this.dialog
            .open(ConfirmDialogComponent, {
                data: { title, question },
            })
            .afterClosed()
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.store.dispatch(
                        new DeleteLetter({
                            letter: template,
                        })
                    );
                }
            });
    }

    /**
     * Determine if the given template does not have any base attribute which
     * could for example be shown on the first page of the dialogs.
     *
     * @param template The template to check.
     */
    public hasNoBaseAttributes(template: CommonTemplate): boolean {
        const customMap = isFormLetterTemplateOrPredefined(template)
            ? template.customAttributes
            : template.customKeyValuePairs;

        if (!customMap) {
            return true;
        }

        const hasBaseAttributes = [];
        Object.keys(customMap).forEach((key) => {
            if (
                FormLetterService.isKeyBaseAttribute(template, key, customMap)
            ) {
                hasBaseAttributes.push(key);
            }
        });

        return hasBaseAttributes.length <= 0;
    }

    /**
     * Update the target custom form letter template data structure based on
     * the source template and the updating parameters like title and custom
     * attribute values.
     *
     * The source template can be the target template as well to perform the
     * update in-place, for example if an existing form letter template is
     * updated. Please keep in mind using a copy if the form letter template
     * instance was obtained from the NgRx store.
     *
     * @param targetTemplate The target custom form letter template.
     * @param sourceTemplate The source form letter template, either custom
     * or predefined.
     * @param templateTitle The updated form letter template title.
     * @param updatedCustomAttributeValues The updated custom attribute values,
     * for example default values for longer text inputs.
     * @private
     */
    private updateTargetTemplate(
        targetTemplate: FormLetterTemplate,
        sourceTemplate: NewFormLetterTemplate,
        templateTitle: string,
        updatedCustomAttributeValues: { [p: string]: any }
    ): void {
        targetTemplate.title = templateTitle;
        // If the selected letter isn't already a user template, specify the predefined ID
        if (
            isFormLetterTemplateOrPredefined(sourceTemplate) &&
            !isFormLetterTemplate(sourceTemplate)
        ) {
            targetTemplate.templateSourceId = (sourceTemplate as PredefinedFormLetterTemplate).id;
        }
        // map custom attributes from the form
        const customAttributes: FormLetterTemplate['customAttributes'] = {};
        const customMap = sourceTemplate.customAttributes;
        if (customMap) {
            Object.keys(customMap).forEach((key) => {
                customAttributes[key] = {
                    ...customMap[key],
                    defaultValue: updatedCustomAttributeValues[key],
                };
            });
        }
        targetTemplate.customAttributes = customAttributes;
    }

    /**
     * Create a copy of the given form letter template (either custom or
     * predefined) and save it as a new custom template.
     *
     * @param saveAsCustomTemplate The parameter object for this method, see
     * attributes of {@link SaveAsCustomTemplate} for details.
     *
     * @return The returned promise resolves after the custom form letter
     * template was successfully created or is rejected if an error occurred.
     */
    public saveAsCustomTemplate(
        saveAsCustomTemplate: SaveAsCustomTemplate
    ): Promise<void> {
        const {
            sourceTemplate,
            templateTitle,
            newCustomAttributeValues,
        } = saveAsCustomTemplate;

        // use selectedLetter as base and modify it
        // according to the new values for the new template
        const targetTemplate: FormLetterTemplate = {
            ...sourceTemplate,
            // The target template must be a custom template, this needs
            // to be overridden when creating from predefined form letter
            // templates.
            templateType: NewFormLetterTemplateType.FormLetterTemplate,
        };

        // we are creating a new template, thus remove id of source to
        // later obtain the correct id from the server
        delete targetTemplate.id;

        this.updateTargetTemplate(
            targetTemplate,
            sourceTemplate,
            templateTitle,
            newCustomAttributeValues
        );

        // In some cases its possible, that the action is handled before the
        // toPromise can subscribe. By reordering them here, its ensured, that
        // the task related to the subscription is processed first.

        const resultPromise = this.actions$
            .pipe(
                ofType<CreateLetterSuccess | CreateLetterError>(
                    LettersActionTypes.CREATE_LETTER_SUCCESS,
                    LettersActionTypes.CREATE_LETTER_ERROR
                ),
                take(1),
                map((action) => {
                    if (
                        action.type === LettersActionTypes.CREATE_LETTER_ERROR
                    ) {
                        throw action.payload.error;
                    }
                })
            )
            .toPromise();

        this.store.dispatch(new CreateLetter({ letter: targetTemplate }));

        return resultPromise;
    }

    /**
     * Update the given custom form letter template.
     *
     * @param updateCustomTemplate The parameter object for this method, see
     * attributes of {@link UpdateCustomTemplate} for details.
     *
     * @return The returned promise resolves after the custom form letter
     * template was successfully updated or is rejected if an error occurred.
     */
    public updateCustomTemplate(
        updateCustomTemplate: UpdateCustomTemplate
    ): Promise<void> {
        const {
            template,
            updatedTemplateTitle,
            updatedCustomAttributeValues,
        } = updateCustomTemplate;

        // Prevent modifying the instance from the store.
        const updatedTemplate: FormLetterTemplate = {
            ...template,
        };

        // update template in place, thus `template` is used for both source and
        // target
        this.updateTargetTemplate(
            updatedTemplate,
            template,
            updatedTemplateTitle,
            updatedCustomAttributeValues
        );

        // See note in saveAsCustomTemplate regarding the reordering here.

        const resultPromise = this.actions$
            .pipe(
                ofType<UpdateLetterSuccess | UpdateLetterError>(
                    LettersActionTypes.UPDATE_LETTER_SUCCESS,
                    LettersActionTypes.UPDATE_LETTER_ERROR
                ),
                take(1),
                map((action) => {
                    if (
                        action.type === LettersActionTypes.UPDATE_LETTER_ERROR
                    ) {
                        throw action.payload.error;
                    }
                })
            )
            .toPromise();

        this.store.dispatch(new UpdateLetter({ letter: updatedTemplate }));

        return resultPromise;
    }
}
