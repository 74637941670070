export const locale = {
    lang: 'br',
    data: {
        APP: {
            UPLOAD_CHANGES: 'Carregar alterações',
            SYNC_PROJECTS: 'Sincronizar Projetos',
            PULL_TO_REFRESH: 'Puxe para baixo para atualizar',
            RELEASE_TO_REFRESH: 'Liberar para atualizar',
            REFRESHING: 'Atualizando',
            DELETE_OFFLINE_DATA: 'Excluir dados offline',
            SELECT_PROJECT: 'Selecionar Projeto',

            TEMPLATES: 'Modelos',
            CSV_UPLOAD_SUCCESS: 'CSV carregado',
            SHOW_TICKETS: 'Mostrar ticket',
            IMPORTANCE: 'Importância',
            HISTORY_NO_OFFLINE: 'O histórico só está disponível online',
            PLAN_MISSING: 'Nenhum plano encontrado',
            NOTE: 'Nota',
            INPUT_COMMENT: 'Insira Comentário',
            COMMENT: 'Comentário',
            CHOOSE_CRAFT_LOCATION: 'Selecionar Tipo de Serviço e Localização',
            CONFLICT: 'Conflito',
            CONFLICTS: 'Conflitos',
            SYNC: 'Ativar Sincronização',
            NO_SYNC: 'Desativar sincronização',
            APPLY: 'Aplicar',
            DAYS: 'Dias',
            FORCE_OFFLINE: 'Modo offline',
            SHOW: 'Mostrar',
            ALL: 'Todos',
            RECOMMENDATIONS: 'Recomendações',
            RECOMMENDED: 'Recomendado',
            SELECT_SNIPPET: 'Selecionar modelo',
            NO_SNIPPET: 'Sem modelo',
            LOCATION_SELECTOR_LABEL: 'Localização',
            BOILERPLATES: 'Sugestões de Texto',
            ISSUES: 'Tickets',
            TITLE: 'Título',
            COPY: 'copiar',
            ARCHIVE_VERB: 'arquivar',
            FORM_LETTER: 'Selecione o Modelo de Relatório',
            FORM_LETTER_CONTENT: 'Conteúdo do relatório',
            PRINT: 'Imprimir',
            PRINT_DISABLED_NO_SELECTION: 'Nenhuma entrada é selecionada para impressão.',
            PRINT_DISABLED_DIARY_BAD_SELECTION: 'Somente as entradas de inspeção de tipo podem ser impressas. Por favor, ajuste sua seleção, para que ela contenha apenas entradas de inspeção.',
            EXPORT: 'Exportar',
            NEXT: 'Próximo',
            DIARY_PRINT_BUTTON: {
                ENABLED: 'Imprimir',
                DISABLED_NO_SELECTION: 'Nenhuma entrada é selecionada para impressão.',
                DISABLED_BAD_SELECTION: 'Somente as entradas de inspeção de tipo podem ser impressas. Por favor, ajuste sua seleção, para que ela contenha apenas entradas de inspeção.',
            },
            GERMAN: 'Alemão',
            BRAZILIAN_PORTUGUESE: 'Português do Brasil',
            SELECT_ISSUE: 'Selecionar Ticket',
            NO_ISSUES: 'Nenhum Ticket presente',
            ENGLISH: 'Inglês',
            HIGH: 'Alto',
            MEDIUM: 'Médio',
            LOW: 'Baixo',
            ADD: 'Adicionar',
            SAVE: 'Salvar',
            CANCEL: 'Cancelar',
            DELETE: 'Excluir',
            PREVIEW: 'Preview',
            DOWNLOAD: 'Download',
            SEARCH: 'Pesquisar',
            LOGOUT: 'Sair',
            LOGOUT_DIALOG: {
                TITLE: 'Você realmente quer terminar a sessão?',
                DEVICE_WARNING:
                    'Os dados neste dispositivo não estão totalmente sincronizados com o servidor. Se você continuar com todos estes dados, perderá os mesmos.',
            },
            EDIT: 'Editar',
            SETTINGS: 'Configurações',
            CRAFT: 'Tipo de Serviço',
            CRAFTS: 'Tipos de Serviço',
            ISSUE: 'Ticket',
            ROLE: 'Função',
            ROLES: 'Funções',
            COMPANY: 'Empresa',
            CONFIRM: 'Confirmar',
            BACK: 'Voltar',
            ATTENDEES: 'Participantes',
            COMPANIES: 'Empresas',
            CATEGORY: 'Categoria',
            EDITING: 'Editando',
            FILTER: 'Filtro',
            RESET: 'Limpar',
            OPEN: 'Abrir',
            BROWSE: 'navegar',
            ARCHIVE: 'Arquivo',
            UPLOAD: 'Carregar',
            PROFILE_SETTINGS: 'Editar Perfil',
            COUNTRY: 'País',
            LANGUAGE: 'Idioma',
            DATE: 'Data',
            TIME: 'Hora',
            AS: 'Como',
            AT: 'às',
            TEXT: 'Texto',
            MANAGEMENT: 'Gestão',

            WEATHERENTRY: 'Tempo',
            INSPECTIONENTRY: 'Inspeção',
            APPROVALENTRY: 'Aprovação',
            NOTEENTRY: 'Notas',
            PROGRESSREPORTENTRY: 'Progresso',
            ATTENDANCEENTRY: 'Participantes',

            PROJECT: 'Projeto',

            NO_ENTRIES: 'Nenhuma entrada presente',
            SYNCED_ENTRIES: 'Sincronizado',
            UNSYNCED_ENTRIES: 'Sincronizar',
            NO_REVISIONS: 'Nenhuma Revisão presente',

            BROWSE_IMG_TITLE: 'Carregar imagem',
            BROWSE_IMG: 'Arraste e solte uma imagem ou pesquise ...',
            CREATE_ACCOUNT: 'Criar conta',
            NOT_ACTIVE_ANYMORE: 'Não está mais ativo',
            PERSON: 'Pessoa',
            PERSONS: 'Pessoas',
            TYPE: 'Tipo',
            TIMEZONE: 'Fuso horário',
            ADD_NEW_ITEM: 'Adicionar novo item',
            SHOW_MORE: 'Mostrar mais',
            SHOW_LESS: 'Mostrar menos',
            SYNCING: 'Sincronizando Dados',
            INPUT: 'Entrada',
            FORM_LETTER_DIALOG: {
                PREDEFINED_TEMPLATES: 'Modelos pré-definidos',
                CUSTOM_TEMPLATES: 'Modelos personalizados',
                SUCCESS_CREATE_LETTER_TEMPLATE: 'O modelo foi salvo',
                RTF_TEMPLATES: 'Modelos RTF',
                ADD_ISSUE_DETAILS_TO_FORM_LETTER: 'Você gostaria de adicionar os detalhes do bilhete à carta do formulário?',
                WITH_ISSUE_DETAILS: 'Com detalhes do bilhete',
                WITHOUT_ISSUE_DETAILS: 'Sem detalhes do bilhete',
                ADD_SIGNATURE_TO_FORM_LETTER: 'Você gostaria de acrescentar um campo de assinatura à carta do formulário?',
                WITH_SIGNATURE: 'Com assinatura',
                WITHOUT_SIGNATURE: 'Sem assinatura',
                FORM_LETTER_READY_FOR_DOWNLOAD: 'A carta de formulário está agora pronta para exportação.',
                SAVE_INPUT_AS_TEMPLATE: 'Você gostaria de salvar suas entradas como um novo modelo?',
                CREATE_PDF: 'Criar PDF',
            }
        },
        CRAFTS: {
            0: 'Equipamento de segurança, equipamento de canteiro de obras',
            1: 'Trabalho de andaime',
            2: 'Terraplanagem',
            3: 'Serviços de paisagismo',
            4: 'Trabalho de paisagismo - plantas',
            5: 'Construção de poço e perfuração exploratória',
            6: 'Obras especiais de engenharia civil',
            7: 'Obras de mineração subterrânea',
            8: 'Obras de drenagem de água',
            9: 'Obras de canal de drenagem',
            10: 'Obras de drenagem e percolação',
            11: 'Separador e pequenas estações de tratamento de esgoto',
            12: 'Obras de alvenaria',
            13: 'Obras de concreto',
            14: 'Obras em pedra natural e de concreto',
            16: 'Carpintaria',
            17: 'Construção em aço',
            18: 'Obras de impermeabilização',
            20: 'Obras de telhado',
            21: 'Obras de impermeabilização de telhados',
            22: 'Obras de encanamento',
            23: 'Gesso e reboco, sistemas de isolamento térmico',
            24: 'Obras de revestimento',
            25: 'Obras de contrapiso',
            26: 'Janelas, portas externas',
            27: 'Obras de carpintaria',
            28: 'Obras de parquet',
            29: 'Obras de montagem',
            30: 'Obras com persianas',
            31: 'Obras de construção metálica',
            32: 'Obras de envidraçamento',
            33: 'Obras de limpeza de edifícios',
            34: 'Obras de pintura',
            35: 'Proteção contra corrosão',
            36: 'Obras de revestimento de piso',
            37: 'Aplicação de Papel de parede',
            38: 'Fachada',
            39: 'Construção seca',
            40: 'Aquecimento sanitário',
            41: 'Sistemas de aquecimento - tubos, acessórios, superfícies de aquecimento',
            42: 'Instalações de gás e água - tubos, acessórios',
            43: 'Dutos de pressão para gás, água e esgoto',
            44: 'Sistemas de esgoto - canos, ralos, acessórios',
            45: 'Instalações de gás, água e drenagem - equipamentos, elementos, banheiros pré-fabricados',
            46: 'Instalações de gás, água e drenagem - equipamento operacional',
            47: 'Proteção contra incêndio',
            49: 'Extintores de incêndio, equipamentos de combate a incêndio',
            50: 'Proteção contra raios',
            51: 'Obras de instalação de cabos subterrâneos',
            52: 'Sistemas de média tensão',
            53: 'Elétrico(a)',
            54: 'Instalações de baixa tensão - sistemas de distribuição e dispositivos embutidos',
            55: 'Sistemas de alimentação reserva',
            57: 'Engenharia de sistemas de construção',
            58: 'Luzes e lâmpadas',
            59: 'Sistemas de iluminação de segurança',
            60: 'Sistemas eletroacústicos, sistemas de intercomunicação, sistemas de paging',
            61: 'Redes de comunicação',
            62: 'Equipamento de comunicação',
            63: 'Sistemas de alarme',
            64: 'Controle de acesso, sistemas de registro de tempo',
            69: 'Elevadores',
            70: 'Automação predial',
            75: 'Ventilação e ar condicionado - clima',
            78: 'Sistemas de refrigeração para sistemas de ar condicionado',
            80: 'Estradas, caminhos, praças',
            81: 'Trabalho de manutenção de concreto',
            82: 'Combate à proteção da madeira',
            83: 'Trabalho de renovação em componentes contendo poluentes',
            84: 'Demolição e desmontagem',
            85: 'Trabalhos de Pipe jacking',
            87: 'Gestão, recuperação e eliminação de resíduos',
            90: 'Logística de construção',
            91: 'Trabalho remunerado por hora',
            96: 'Trabalhos de construção em passagens de nível',
            97: 'Obras de construção em trilhos e interruptores',
            98: 'Medidas de proteção climática',

            491: 'Equipamento de canteiro de obras, abas de inspeção',
            430: 'Ventilação e ar condicionado',
            490: 'Projeto detalhado',
            300: 'Exterior do Edifício',

            400: 'Elétrico(a)',
            410: 'Instalações de gás e água - tubos, acessórios',
            420: 'Sistemas de aquecimento',
            444: 'Sistemas de baixa tensão - cabos / fios, sistemas de instalação, equipamento de instalação',
            450: 'Canalização, aquecimento, ar condicionado',
            900: 'Vista externa',
            910: 'Instalações externas',

            8000: 'Turbinas eólicas',
            8001: 'Pás do rotor',
            8002: 'Torre',
            8003: 'Gôndola',

            9998: 'Não atribuído',
            9999: 'Geral',
        },
        SYNC: {
            TYPES: {
                ISSUE: 'Tickets',
                ATTACHMENT: 'Anexos',
                DIARY: 'Items do Diário',
                PROJECT: 'Projetos',
            },
            ATTACHMENT_NOT_AVAILABLE_OFFLINE:
                'Este anexo não está disponível no momento.',
            ENTITY_ERRORS: 'Erros nas alterações',
            ERROR_DISCARD_CREATE: 'Descartar alterações',
            ERROR_DISCARD_UPDATE: 'Descartar alterações',
            ENTITY_ERROR_DETAILS: 'Informações sobre o erro',
            NO_ENTITY_ERROR_DETAILS: 'Nenhuma informação do erro disponível.',
            GO_TO_ERRORS: 'Mostrar erros',
        },
        COUNTRIES: {
            GERMANY: 'Germany',
            UNITED_KINGDOM: 'Reino Unido',
        },
        FILEPOND: {
            LABEL: 'Adicionar imagens',
        },
        CAPACITOR: {
            CAMERA_LABELS: {
                HEADER: 'Adicionar Imagem',
                FROM_GALLERY: 'Da Galeria',
                FROM_CAMERA: 'Tirar foto',
            },
        },
        PAGINATOR: {
            FIRST_PAGE: 'Primeira página',
            ITEMS_PER_PAGE: 'Itens por página',
            LAST_PAGE: 'Última página',
            NEXT_PAGE: 'Próxima página',
            PREVIOUS_PAGE: 'Página Anterior',
            OF: 'de',
        },
        DATA: {
            STREET: 'Rua',
            ZIP: 'CEP',
            CITY: 'Cidade',
            STATE: 'Estado',
            PHONE: 'Telefone',
            CONTACT_PERSON: 'Pessoa de contato',
            RESPONSIBLE: 'Responsável',
            CREATED_AT: 'Criado em',
            NAME: 'Nome',
            ATTENDEES: 'Participantes',
            FAX: 'Fax',
            WEBSITE: 'Site',
            SUBSCRIBED_SERVICES: 'Serviços inscritos',
        },
        PROJECT: {
            CREATE_PROJECT: 'Criar Projeto',
            EDIT_PROJECT: 'Editar Projeto',
            CREATE_TEMPLATE: 'Criar modelo',
            EDIT_TEMPLATE: 'Editar modelo',
            PRINT_PROJECTS: 'Imprimir um Relatório de Projeto',
            COPY_PROJECTS: 'Copiar Projetos',
            BEGINNING: 'Começo',
            END: 'Fim',
            ARCHIVE_PROJECT: 'Arquivar Projeto',
            ARCHIVE_PROJECT_QUESTION: 'Arquivar Projeto?',
            ARCHIVE_PROJECT_CONFIRM:
                'Tem certeza que deseja arquivar este projeto?',
            ARCHIVE_PROJECTS: 'Arquivar Projetos',
            ARCHIVE_PROJECTS_QUESTION: 'Arquivar projetos?',
            ARCHIVE_PROJECTS_CONFIRM:
                'Tem certeza que deseja arquivar {{ length }} projetos?',
            STATUS_DONE: 'Feito',
            STATUS_OPEN: 'Abrir',
            STATUS_ACCEPTED: 'Aceitaram',
            STATUS_EXTENDED_DEADLINE: 'Prazo estendido',
            TICKETS: 'Ingressos',
            TOTAL: 'Total',
            LIST: {
                WELCOME: 'Bem-vindo',
                WELCOME_MOBILE: 'Bem-vindo',
                YOUR_DUE_DATES: 'Você tem {{ length }} itens vencendo',
                FORM: {
                    UPLOAD_PROJECT_IMAGE: 'Carregar imagem do projeto',
                    BROWSE_PROJECT_IMAGE:
                        'Arrastar e soltar a imagem do projeto ou <u> Buscar </u>',
                    PROJECT: 'Projeto',
                    BEGIN: 'Início do projeto',
                    END: 'Fim do Projeto',
                    STREET: 'Logradouro e Número',
                    ZIP: 'CEP',
                    COUNTRY: 'País',
                    ADD_PROJECT_IMAGE: 'Adicionar ou buscar imagem do projeto',
                    BUILDING_OWNER: 'Proprietário do edifício',
                },
                TABLE: {
                    NAME: 'Nome do Projeto',
                    BEGIN: 'Data de início',
                    END: 'Data de conclusão',
                    CREATED_AT: 'Criado em',
                },
            },
            DETAIL: {
                CREATED: 'criado',
                DEACTIVATE: 'Desativar Projeto',
                ARCHIVE: 'Arquivar Projeto',
            },
        },
        ISSUES: {
            SELECT_PRIMARY_PHOTO: 'selecione a imagem principal',
            NO_PLAN: 'Nenhum plano disponível',
            CONFLICT_TITLE: 'Cuidado, conflito de Ticket detectado!',
            CONFLICT_TEXT: 'Por favor, escolha qual Ticket você deseja manter.',
            CREATE_ISSUE: 'Criar Ticket',
            PRINT_DIALOG_TITLE: 'Selecionar Relatório',
            SELECT_LETTER: 'Selecione um Relatório',
            CUSTOM_TEXT: 'Texto personalizado',
            STATES: {
                Draft: 'Rascunho',
                Open: 'Abrir',
                Accepted: 'Aceito',
                Rejected: 'Rejeitado',
                DoneWithoutInspection: 'Feito sem inspeção',
                ExtendedDeadline: 'Prazo prorrogado',
                FinalDeadline: 'Prazo Final',
                MailedExtendedDeadline: 'Prazo prorrogado por e-mail/correio',
                MailedFinalDeadline: 'Prazo prorrogado por e-mail/correio',
                Done: 'Concluído',
                Failed: 'Fracassado',
            },
            LIST: {
                DUE_FROM: 'Prazo De',
                DUE_TO: 'Prazo Até',
                STATUS: 'Situação',
                TOGGLE_LIST: 'Lista',
                TOGGLE_PLAN: 'Plano',
            },
            DETAIL: {
                CREATE_ISSUE: 'Título',
                DETAIL: 'Detalhe',
                PHOTOS: 'Fotos',
                DOCUMENTS: 'Documentos',
                HISTORY: 'Histórico',
                FILES: 'Arquivos',
                FORM: {
                    RAISE_DUE_DAYS: 'Prorrogar data de vencimento',
                    ISSUE_DESC: {
                        BLOCK: 'Descrição do Ticket (componentes de texto)',
                        FREE: 'Descrição do Ticket (texto livre)',
                    } ,
                    DUE_DATE: 'Data de vencimento',
                    LOCATION: 'Localização',
                    ADD_LOCATION: 'Adicionar localização',
                    EXTERNAL_RESPONSIBLE: 'Responsável Externo',
                    SEVERITY: 'Severidade',
                    INTERNAL_RESPONSIBLE: 'Responsável Interno',
                    CREATED_BY: 'Criado por',
                    ADD_PHOTO: 'Adicionar fotos',
                    FLOOR_PLAN: 'Planta baixa',
                    DISABLE_BOILERPLATES: 'Desativar componentes de texto',
                    ENABLE_BOILERPLATES: 'Ativar componentes de texto',
                },
                PREVIEW: 'Preview',
                DOWNLOAD: 'Download',
                DELETE_IMAGE: 'Excluir imagem',
            },
            PLAN_OVERVIEW: {
                PLAN_SELECTOR_PLACEHOLDER: 'Selecione o plano',
                NO_PLAN_AVAILABLE:
                    'Nenhum plano pode ser mostrado atualmente. Por favor, verifique suas configurações de filtro e de construção.',
            },
            TOOLBAR: {
                SELECT_ALL: 'Selecionar todos',
                SORT: 'Ordenar',
                FILTER: 'Filtro',
                ARCHIVE: 'Arquivados',
            },
            DIALOGS: {
                DISCARD_CHANGES: {
                    TITLE: 'Modificações não salvas',
                    QUESTION:
                        'O Ticket tem modificações não salvas, deseja descartar as alterações?',
                    CONFIRM_LABEL: 'Descartar',
                },
                COMMENT_FOR_REJECTED: {
                    TITLE: 'Rejeitar Ticket',
                    REJECT: 'Rejeitar',
                },
            },
            ERROR_DISCARD_CREATE: 'Descartar problema',
            ERROR_DISCARD_UPDATE: 'Descartar alterações',
            PLAN_OF: 'Plano de',

            CHECKLIST: {
                TAB_TITLE: 'Lista de controle',
                ADD_CHECKLIST: 'Adicionar lista de controle',
                ADD_CHECKLIST_DIALOG: {
                    TITLE: 'Adicionar lista de controle',
                    SELECTOR_PLACEHOLDER: 'Lista de controle',
                    NO_CHECKLISTS_CONFIGURED:
                        'Atualmente não há listas de controle configuradas neste projeto. Você pode adicionar novas listas de verificação nas configurações do projeto atual.',
                    ADD_BUTTON: 'Adicionar',
                    CANCEL_BUTTON: 'Cancelar',
                },
                REMOVE_CHECKLIST: 'Remover lista de controle',
                NO_CHECKLIST_ADDED:
                    'Não há uma lista de controle configurada. Use o botão “PLUS” para adicionar uma.',
                NO_CHECKLIST_ADDED_AND_NO_RIGHTS:
                    'Não há uma lista de controle configurada.',
            },
        },
        NOTIFICATIONS: {
            LAST_ACTIVITIES: 'Últimas Atividades',
            NO_ACTIVITIES: 'Nenhuma atividade recente',
            ISSUE_CREATED: '{{user}} criou um novo ticket: {{ticket}}',
            ISSUE_UPDATED: '{{user}} atualizou um ticket: {{ticket}}',
            INSPECTION_ENTRY_FINISHED: '{{user}} bloqueou uma inspeção',
            MESSAGE_CREATED: '{{user}} criou um novo {{title}}',
            MESSAGE_UPDATE: '{{user}} atualizou {{title}}',
            MARK_ALL_AS_READ: 'Marcar todos como lido',
        },
        ATTENDEES: {
            ASSIGN_USERS: 'Atribuir participantes ao projeto',
            ASSIGN_TO_PROJECT: 'Atribuir ao Projeto',
        },

        BUILDINGS: {
            TITLE: 'Edifícios',
            ADD_PLANS: 'Adicionar plantas baixas ou',
            BROWSE_PLANS: 'Arraste e solte um plano ou busque ...',
            DELETE_PLAN_TITLE: 'Excluir plano?',
            DELETE_PLAN_QUESTION:
                'Tem certeza de que deseja excluir o plano {{craftName}}?',
            ADD_LEVEL: 'Adicionar um nível',
            ADD_PLAN_DIALOG: 'Adicionar um plano à {{locationTitle}}',
            DELETE_LOCATION_TITLE: 'Excluir localização?',
            DELETE_LOCATION_QUESTION:
                'Tem certeza de que deseja excluir a localização?',
            ADD_LOCATION: 'Adicionar uma localização',
            ERRORS: {
                DELETE_LOCATION_ERROR_IS_USED: 'O local ou um nível dentro do local é usado. Não é possível excluir.',
            },
        },

        USER: {
            LIST: {
                TABLE: {
                    TITLE: 'Título',
                    FIRST_NAME: 'Nome',
                    LAST_NAME: 'Sobrenome',
                    CONTACT_TITLE: 'Saudação',
                    CONTACT_TITLE_MALE: 'Senhor',
                    CONTACT_TITLE_FEMALE: 'Senhora',
                    CONTACT_TITLE_UNSPECIFIED: 'não especificado',
                    COMPANY: 'Empresa',
                    EMAIL: 'E-Mail',
                },
                ASSIGN_USER: 'Atribuir Participante',
                CREATE_USER: 'Criar Participante',
                FORM: {
                    USER_DATA: 'Dados do participante',
                    COMPANY_DATA: 'Dados da empresa',
                    SAVE: 'SALVAR',
                    CELL_PHONE: 'Celular',
                    PHONE: 'Telefone',
                    USERNAME: 'Nome de usuário',
                    PASSWORD: 'Senha',
                    PASSWORD_REPEAT: 'Confirme a senha',
                    ADD_AVATAR: 'Adicionar ou procurar uma imagem de avatar',
                    CONTACT_TITLE: 'Saudação',
                    CONTACT_TITLES: {
                        0: 'Senhor',
                        1: 'Senhora',
                    },
                },
                DELETE: {
                    ATTENDEE: {
                        TITLE: 'Excluir participante?',
                        QUESTION:
                            'Tem certeza de que deseja excluir o participante?',
                    },
                    ATTENDEES: {
                        TITLE: 'Excluir participantes?',
                        QUESTION:
                            'Tem certeza de que deseja excluir {{attendeeCount}} participantes?',
                    },
                    COMPANY: {
                        TITLE: 'Excluir empresa?',
                        QUESTION:
                            'Tem certeza de que deseja excluir a empresa?',
                    },
                    COMPANIES: {
                        TITLE: 'Excluir empresas?',
                        QUESTION:
                            'Tem certeza de que deseja excluir {{companyCount}} empresas?',
                    },
                },
            },
        },

        SETTINGS: {
            PROJECT_SETTINGS: {
                TITLE: 'Configurações do projeto',
                DEADLINE: 'Prazo',
                DEADLINE_DAYS: 'Prazo (Dias)',
                DAY_SETTING_TITLE:
                    'Projeto em dias úteis (VOB) ou dias corridos (BGB)',
                DAY_SETTING_PLACEHOLDER: 'Escolha a duração (dias) do projeto',
                SYNC_SETTING_TITLE:
                    'Quando os Tickets devem ser sincronizados e enviados?',
                ON_INPUT: 'Ao criar',
                END_OF_DAY: 'No final do dia, não depois das 20h',
            },
            FORM_LETTERS: {
                FORM_LETTERS: 'Modelo de Relatório',

                DELETE_FORM_LETTER_TITLE: 'Excluir Modelo de Relatório?',
                DELETE_FORM_LETTER_QUESTION:
                    'Tem certeza de que deseja excluir o Modelo de Relatório "{{formLetterTitle}}"?',

                PREDEFINED_TEMPLATES: 'Modelos predefinidos',
                CUSTOM_TEMPLATES: 'Modelos personalizados',
                SHOW_IN_EXPORT_DIALOG: 'Mostrar na janela de exportação',
                EDIT_TABLE_HEADER: 'Editar',

                PREVIEW_TOOLTIP: 'Visualizar',
                CREATE_CUSTOM_TEMPLATE_TOOLTIP: 'Criar modelo personalizado',

                COPY_AND_EDIT_TEMPLATE_TOOLTIP: 'Copie e edite o modelo',
                EDIT_TEMPLATE_TOOLTIP: 'Editar modelo',
                ARCHIVE_TEMPLATE_TOOLTIP: 'Excluir modelo',

                PREVIOUS: 'Anterior',
                NEXT: 'Próximo',
                SAVE: 'Salve',

                NEW_CUSTOM_FROM_PREDEFINED_HELPER_TEXT:
                    'Você costuma usar textos recorrentes? Aqui, você pode adicionar esses textos como valores padrão e criar um modelo personalizado. Se necessário, os valores neste modelo personalizado também podem ser ajustados durante a exportação de PDF.',

                SETTINGS_DIALOG_TITLE_PREFIXES: {
                    NEW_CUSTOM_TEMPLATE: 'Novo modelo personalizado:',
                    EDIT_CUSTOM_TEMPLATE: 'Editar modelo personalizado:',
                },

                DIALOG_FINAL_PAGE: {
                    TEMPLATE_TITLE_LABEL: 'Insira o título deste modelo personalizado:',
                    TEMPLATE_TITLE_PLACEHOLDER: 'Título do modelo personalizado',
                },
            },
            ROLES: {
                FOUR_EYES: 'Princípio dos Quatro Olhos',
                APPLY_FOUR_EYES: 'Aplicar o princípio dos quatro olhos',
                INTERNAL_RESPONSIBLE: 'Responsável interno',
                EXTERNAL_RESPONSIBLE: 'Responsável externo',
                CHOOSE_ROLE: 'Escolha a função',
                CHOOSE_COMPANY: 'Escolha a empresa',
                ROLES_AND_RIGHTS: 'Funções e permissões',
                ARCHIVE_PROJECT_PARTNER_TITLE: 'Arquivar Parceiro do Projeto?',
                ARCHIVE_PROJECT_PARTNER_QUESTION:
                    'Tem certeza de que deseja arquivar o parceiro do projeto?',
                ARCHIVE_PROJECT_PARTNERS_TITLE:
                    'Arquivar parceiros do projeto?',
                ARCHIVE_PROJECT_PARTNERS_QUESTION:
                    'Tem certeza que deseja arquivar {{count}} parceiros do projeto?',
                ROLE_NORMAL: 'Normal',
                ROLE_CRAFTRESPONSIBLE: 'Responsável Técnico',
                ROLE_PROJECTMANAGER: 'Gerente de Projeto',
                ROLE_ADMIN: 'Administrador',
                SELECT_CRAFT: 'Tipo de Serviço',
                SELECT_EXT_RESPONSIBLE: 'Resp. externo',
                SELECT_INT_RESPONSIBLE: 'Resp. interno',
                ROLE_SUPERADMIN: 'Super Administrador',
            },
            TICKETS: {
                TICKET_SETTINGS: 'Configurações de bilhetes',
                TITLE: 'Atributo',
                TYPE: 'Tipo',
                SELECT_TYPE: 'Selecionar tipo',
                TYPE_DESCRIPTION: 'Adicionar descrição',
                TICKET_SETTINGS_DIALOG: 'Editar Configurações de Bilhetes',
                DIALOG_ATTRIBUTENAME: 'Nome do atributo',
                DELETE_ATTRIBUTE_TITLE: 'Eliminar Atributo Personalizado?',
                DELETE_ATTRIBUTE_QUESTION:
                    'Você tem certeza de que deseja excluir o atributo personalizado?',
            },
            CHECKLIST: {
                CHECKLIST_SETTINGS: 'Listas de verificação',
                TITLE: 'Lista de verificação',
                SETTINGS_DIALOG: 'Editar lista de verificação',
                DIALOG_CHECKLIST_NAME: 'Nome da lista de verificação',
                DIALOG_ITEM: 'Adicionar Descrição',
                DIALOG_TYPE: 'Selecione o tipo',
                ADD_TYPE: 'Acrescentar tipo adicional',
                DELETE_CHECKLIST_TITLE: 'Eliminar lista de verificação?',
                DELETE_CHECKLIST_QUESTION:
                    'Você tem certeza de que deseja excluir a lista de verificação?',
            },
        },

        GLOBAL_SETTINGS: {
            ADDRESS_BOOK: {
                TITLE: 'Catálogo de endereços',
                ADD_ATTENDEE: 'Adicionar participante',
                ADD_ONE_ATTENDEE: 'Adicionar um único contato',
                ADD_COMPANY: 'Adicionar empresa',
                ELEMENTS_SELECTED: 'elementos selecionados',
                PRINT_ATTENDEES: 'imprimir participantes',
                ARCHIVE_ATTENDEES: 'arquivar participantes',
                ARCHIVE_ATTENDEE: 'arquivar participante',
                EDIT_ATTENDEE: 'editar participante',
                DELETE_ATTENDEES: 'Excluir participantes?',
                PRINT_COMPANIES: 'imporimir empresas',
                ARCHIVE_COMPANIES: 'arquivar empresas',
                ARCHIVE_COMPANY: 'arquivar empresa',
                EDIT_COMPANY: 'editar empresa',

                CONTACT_IMPORT: {
                    OPEN_DIALOG_BUTTON: 'Importar contatos',
                    NOT_AVAILABLE:
                        'A importação de contatos não é possível no momento. Por favor, crie uma empresa para poder importar contatos.',

                    COMPANY_CSV_STEP: 'Selecione a empresa e o arquivo',
                    COMPANY_HINT:
                        'Selecione a empresa que deve ser usada para os contatos importados:',
                    CSV_HINT:
                        'Selecione o arquivo CSV exportado do Microsoft Outlook no Windows, que contém os contatos:',
                    CSV_FILE_POND_LABEL: 'Clique aqui para selecionar um arquivo CSV.',
                    CSV_FILE_POND_LABEL_LOADING: 'Carregando...',
                    CSV_DOWNLOAD_EXAMPLE:
                        'Clique aqui para baixar um modelo para o arquivo CSV.',
                    TITLE_HINT:
                        'A saudação do contato é determinada automaticamente com base no primeiro nome. Verifique esta configuração após a importação.',
                    CANCEL: 'Cancelar',
                    IMPORT: 'Importar',

                    IMPORT_RESULT_STEP: 'Os resultados da importação',
                    IMPORT_FAILED:
                        'Ocorreu um erro durante a importação. Verifique o formato do seu arquivo CSV.',
                    CONTACTS_IMPORTED: {
                        '=0': 'Nenhum contato foi importado.',
                        '=1': 'Um contato foi importado.',
                        'other': '# contatos foram importados.',
                    },
                    PROBLEMS_OCCURRED: {
                        '=1': 'Ocorreu um problema durante a importação.',
                        'other': '# problemas ocorreram durante a importação.',
                    },
                    PLEASE_CHECK_LINES:
                        'Por favor, verifique as seguintes notas sobre as linhas do seu arquivo CSV do Outlook.',
                    CLOSE: 'Concluir',

                    ERRORS: {
                        SKIPPED: 'Já existe um contato com este endereço de e-mail.',
                        CSV_FORMAT_INVALID: 'Esta linha tem um formato inválido.',
                        FIRSTNAME: 'Primeiro nome',
                        NAME: 'Sobrenome',
                        EMAIL: 'E-mail',
                        NO_VALUE: 'O campo “{{ field }}“ não tem valor.',
                        INVALID_FORMAT: 'O campo “{{ field }}“ tem um formato inválido.',
                    },
                }
            },
        },
        ERRORS: {
            REQUIRED: {
                EMAIL: 'E-mail obrigatório',
                PASSWORD: 'A senha é obrigatória',
            },
            VALIDATE: {
                EMAIL: 'Email inválido',
            },
        },

        BANNER: {
            PROJECT_BEGIN: 'Início do Projeto',
            PROJECT_END: 'Fim do Projeto',
            OPEN: 'Abrir',
            ACCEPTED: 'Aceito',
            EXTENDED_DEADLINE: 'Prazo Prorrogado',
            DONE: 'Concluído',
        },

        DIALOG: {
            USER_SETTINGS: {
                TITLE: 'Configurações do usuário',
                EMAIL_ADDRESS: 'Endereço de e-mail',
                EMAIL_ADDRESS_REPEAT: 'Confirme o novo endereço de e-mail',
                NEW_PASSWORD: 'Nova senha',
                NEW_PASSWORD_REPEAT: 'Confirme nova senha',
                TWO_FACTOR_AUTH: 'Ativar autenticação de 2 fatores',
            },
            PLAN_PREVIEW: {
                SAVE: 'Salvar alterações?',
                DISCARD: 'Descartar alterações?',
                DISCARD_MESSGAE:
                    'Alterações foram feitas na versão atual. Deseja descartá-los? ',
                DISCARD_BUTTON: 'Descartar alterações',
                SAVEWITHOUTSAVING: 'Fechar sem salvar?',
            },
        },

        DIARY: {
            SELECT_ENTRY: 'Selecione um item',
            ARCHIVE_ENTRY: 'Arquivar item',
            ADD_ENTRY: 'Adicionar item',
            NOW: 'agora',
            ONE_MINUTE_AGO: '1 minuto atrás',
            MINUTES_AGO: '{{minutes}} minutos atrás',
            ONE_HOUR_AGO: '1 hora atrás',
            HOURS_AGO: '{{hours}} horas atrás',
            ARCHIVE_ENTRY_TITLE: 'Arquivar item?',
            ARCHIVE_ENTRIES_TITLE: 'Arquivar itens?',
            ARCHIVE_ENTRY_QUESTION:
                'Tem certeza que deseja arquivar este item?',
            ARCHIVE_ENTRIES_QUESTION:
                'Tem certeza de que deseja arquivar {{count}} items?',
            DATE_FROM: 'Data de',
            DATE_TILL: 'Data até',
            NUMBER_OF_ENTRIES: 'Itens',
            ENTRIES_NOT_FOUND: 'Nenhum item encontrado',
            CONTACT: 'Contato',

            APPROVAL: {
                SERVICE: 'Serviço',
                APPROVED_BY: 'Aprovado por',
                APPROVAL_DATE: 'Data de aprovação',
                COMMENTS: 'Comentários',
            },

            ATTENDANCE: {
                ATTENDENT: 'Participante',
                ABSENT: 'Ausente',
                NUMBER_OF_ATTENDEES: 'Número de participantes',
                WORKING_TIME_FROM: 'Hora de',
                TILL: 'até',
                PERSONS: 'Pessoas',
            },

            COMMENTS: {
                TITLE: 'Notas',
                COMMENT: 'Nota',
            },

            PROGRESS: {
                TITLE: 'Progresso',
                PROGRESS_TO: 'Concluído:',
                COMPLETED: '',
                DESCRIPTION: 'Descrição',
            },

            WEATHER: {
                TITLE: 'Clima',
                MEASURING_TIME: 'Hora das medidas',
                RAINFALL: 'Chuva',
                WIND: 'Vento',
                TEMPERATURE: 'Temperatura',

                CALM: 'Calmo',
                LIGHTBREEZE: 'Brisa leve',
                MODERATEGALE: 'Ventania',
                HURRICANE: 'Furacão',

                CLEAR: 'Céu claro',
                PARTLYCLOUDY: 'Parcialmente nublado',
                CLOUDY: 'Nublado',
                LIGHTRAIN: 'Chuva fraca',
                RAIN: 'Chuva',
                HEAVYRAIN: 'Chuva forte',
                THUNDERSTORMS: 'Tempestades',
                SLEET: 'granizo',
                SNOW: 'Neve',
                HEAVYSNOW: 'Neve forte',
                FOG: 'Névoa',
                BLIZZARD: 'Nevasca',
            },

            INSPECTION: {
                OPEN: 'aberto',
                LOCKED: 'bloqueado',
                INSPECTION_DATA: 'Dados da inspeção',
                LOCK_TEXT:
                    'Bloqueie a inspeção depois de atribuir todos os Tickets.',
                LOCK_INSPECTION: 'Bloquear inspeção',
                INSPECTION_LOCKED: 'Inspeção bloqueada',
                LOCK_INSPECTION_QUESTION:
                    'Tem certeza de que deseja bloquear a inspeção?',
                RECOMMENDATIONS: 'Recomendações',
            },
            ACTIONS: {
                WEATHERENTRY: '6. Clima',
                INSPECTIONENTRY: '2. Inspeção',
                APPROVALENTRY: '1. Aprovação',
                NOTEENTRY: '4. Notas',
                PROGRESSREPORTENTRY: '3. Progresso',
                ATTENDANCEENTRY: '5. Participantes',
            },
            CONFLICTS: {
                VIEWJOURNALCONFLICTS: 'Mostrar conflitos do diário',
                ACCEPT: 'Aceitar',
                OPEN: 'Abrir',
                NO_ENTRIES: 'Sem itens.',
                OFFLINE_MODE: 'Modo offline',
                DOWNLOAD_PROJECTS: 'Baixar Projetos',
                UPLOAD_CHANGES: 'Carregar alterações',
                DELETE_OFFLINE_DATA: 'Apagar Dados Offline',
                CONFLICTS: 'Conflitos',
                SYNCHRONIZED_ENTRIES: 'Itens Concluídos',
                PENDING_ENTRIES: 'Itens Pendentes',
                JOURNALCONFLICTS: 'Conflitos do Diário',
            },
            ERROR_DISCARD_CREATE: 'Descartar itens do diário',
            ERROR_DISCARD_UPDATE: 'Descartar alterações',
        },
        TIME: {
            SECONDS: {
                SINGULAR: {
                    TEXT: 'Segundo',
                    FUTURE: 'em *** segundo',
                    PAST: '*** segundo atrás',
                    PRESENT: '*** segundo',
                },
                PLURAL: {
                    TEXT: 'Segundos',
                    FUTURE: 'em *** segundos',
                    PAST: '*** segundos atrás',
                    PRESENT: '*** segundos',
                },
            },
            MINUTES: {
                SINGULAR: {
                    TEXT: 'Minuto',
                    FUTURE: 'em *** minuto',
                    PAST: '*** minuto atrás',
                    PRESENT: '*** minuto',
                },
                PLURAL: {
                    TEXT: 'Minutos',
                    FUTURE: 'em *** minutos',
                    PAST: '*** minutos atrás',
                    PRESENT: '*** minutos',
                },
            },
            HOURS: {
                SINGULAR: {
                    TEXT: 'Hora',
                    FUTURE: 'em *** hora',
                    PAST: '*** hora atrás',
                    PRESENT: '*** hora',
                },
                PLURAL: {
                    TEXT: 'Horas',
                    FUTURE: 'em *** horas',
                    PAST: '*** horas atrás',
                    PRESENT: '*** horas',
                },
            },
            DAYS: {
                SINGULAR: {
                    TEXT: 'Dia',
                    FUTURE: 'em *** dia',
                    PAST: '*** dia atrás',
                    PRESENT: '*** dia',
                },
                PLURAL: {
                    TEXT: 'Dias',
                    FUTURE: 'em *** dias',
                    PAST: '*** dias atrás',
                    PRESENT: '*** dias',
                },
            },
            MONTHS: {
                SINGULAR: {
                    TEXT: 'Mês',
                    FUTURE: 'em *** mês',
                    PAST: '*** mês atrás',
                    PRESENT: '*** mês',
                },
                PLURAL: {
                    TEXT: 'Meses',
                    FUTURE: 'em *** meses',
                    PAST: '*** meses atrás',
                    PRESENT: '*** meses',
                },
            },
            YEARS: {
                SINGULAR: {
                    TEXT: 'Ano',
                    FUTURE: 'em *** ano',
                    PAST: '*** ano atrás',
                    PRESENT: '*** ano',
                },
                PLURAL: {
                    TEXT: 'Anos',
                    FUTURE: 'em *** ano',
                    PAST: '*** ano atrás',
                    PRESENT: '*** ano',
                },
            },
        },
        BOILERPLATE: {
            TITLE: 'Sugestões de Texto',
            NO_CRAFT_SELECTED: 'Nenhumo tipo de serviço selecionado',
            DELETE: {
                TITLE: 'Excluir Sugestão de Texto “{{ boilerplateTitle }}”?',
                CONFIRMATION:
                    'Tem certeza de que deseja excluir a sugestão de texto?',
                MESSAGES: {
                    SUCCESS: 'Sugestão de Texto excluída com sucesso',
                    ERROR: 'Exclusão da Sugestão de Texto Falhou',
                },
            },
            CREATE: {
                MESSAGES: {
                    SUCCESS: 'Sugestão de Texto criada com sucesso',
                    ERROR: 'Falha ao criar sugestão de Texto',
                },
            },
            UPDATE: {
                MESSAGES: {
                    SUCCESS: 'Sugestão de Texto atualizada com Sucesso',
                    ERROR: 'Atualização da Sugestão de Texto Falhou',
                },
            },
            IMPORT_FROM_CSV: {
                BUTTON: 'Importar de arquivo CSV',
                MESSAGES: {
                    X_SUCCESS: 'Sugestões de Texto importadas com sucesso',
                    X_ERROR: 'Falha ao importar sugestões de texto',
                },
            },
            LOAD_MESSAGES: {
                X_ERROR: 'Carregamento das Sugestões de Texto Falhou',
            },
        },
        DEFAULT_ERROR_MESSAGES: {
            AUTH: {
                LOGIN: 'Ocorreu um erro no login!',
                REGISTER: 'Ocorreu um erro ao criar a conta!',
                REGISTER_COMPLETE:
                    'Ocorreu um erro ao criar a conta do contato!',
                UPDATE_PASSWORD: 'Ocorreu um erro ao atualizar a senha!',
            },
            SYNC: {
                STORE_COMMAND: 'Ocorreu um erro ao armazenar um comando!',
                DELETE_COMMAND: 'Ocorreu um erro ao deletar um comando!',
                DOWNLOAD_ATTACHMENTS:
                    'Ocorreu um erro ao baixar um ou mais arquivos!',
            },
            PROJECTS: {
                LOAD: 'Ocorreu um erro ao carregar os projetos!',
                CREATE: 'Ocorreu um erro ao criar o projeto!',
                UPDATE: 'Ocorreu um erro ao atualizar o projeto!',
                UPDATE_MANY: 'Ocorreu um erro ao atualizar os projetos!',
            },
            DIARY: {
                LOAD: 'Ocorreu um erro ao carregar os itens do diário!',
                CREATE: 'Ocorreu um erro ao criar o item do diário!',
                UPDATE: 'Ocorreu um erro ao atualizar o item do diário!',
                UPDATE_MANY: 'Ocorreu um erro ao atualizar os itens do diário!',
            },
            CRAFTS: {
                LOAD: 'Ocorreu um erro ao carregar os tipos de serviço!',
            },
            ISSUES: {
                LOAD: 'Ocorreu um erro ao carregar os tickets!',
                CREATE: 'Ocorreu um erro ao criar o Ticket!',
                UPDATE: 'Ocorreu um erro ao atualizar o Ticket!',
                STORE_ATTACHMENT:
                    'Ocorreu um erro ao processar a imagem ou arquivo!',
                UPDATE_ISSUE_ID_OF_COMMANDS_IN_DB:
                    'Ocorreu um ao salvar as alterações para a funcionalidade offline. Fique online para salvar as alterações. ',
            },
            LETTERS: {
                LOAD: 'Ocorreu um erro ao carregar os modelos de relatório!',
                // Note: Currently, it's intentional to use the same message
                // for both keys. This allows them to change independently.
                ERROR_CREATE_LETTER_TEMPLATE: 'O modelo não pôde ser salvo!',
                ERROR_UPDATE_LETTER_TEMPLATE: 'O modelo não pôde ser salvo!',
                ERROR_DELETE_LETTER_TEMPLATE: 'O modelo não pôde ser excluído!',
            },
            CONTACTS: {
                LOAD: 'Ocorreu um erro ao carregar os contatos!',
                CREATE: 'Ocorreu um erro ao criar o contato!',
                UPDATE: 'Ocorreu um erro ao atualizar o contato!',
                DELETE: 'Ocorreu um erro ao deletar o contato!',
                IMPORT: 'Ocorreu um erro ao importar contatos!',
            },
            SETTINGS: {
                LOAD_PROJECT_CRAFT_TO_PLAN:
                    'Ocorreu um erro ao carregar os tipos de serviço de acordo com o plano!',
                PROJECT_ROLES: {
                    LOAD: 'Ocorreu um erro ao carregar as funções!',
                    CREATE: 'Ocorreu um erro ao criar a função!',
                    UPDATE: 'Ocorreu um erro ao atualizar a função!',
                    DELETE: 'Ocorreu um erro ao deletar a função!',
                },
                LOAD_PROJECT_CRAFTS:
                    'Ocorreu um erro ao carregar os tipos de serviço do projeto',
                LOAD_PROJECT_CONTACT:
                    'Ocorreu um erro ao carregar os contatos do projeto!',
                PROJECT_PARTNERS: {
                    LOAD: 'Ocorreu um erro ao carregar os parceiros!',
                    CREATE: 'Ocorreu um erro ao criar o parceiro!',
                    UPDATE: 'Ocorreu um erro ao atualizar o parceiro!',
                    DELETE: 'Ocorreu um erro ao deletar o parceiro!',
                },
                LOAD_LANGUAGES: 'Ocorreu um erro ao carregar os idiomas!',
                LOAD_TIMEZONES:
                    'Ocorreu um erro ao carregar os fusos horários!',
            },
            LOCATIONS: {
                LOAD: 'Ocorreu um erro ao carregar as localizações!',
                CREATE: 'Ocorreu um erro ao criar a localização!',
                UPDATE: 'Ocorreu um erro ao atualizar a localização!',
                DELETE: 'Ocorreu um erro ao deletar a localização!',
            },
            X_BOILERPLATES: {
                X_LOAD: 'Ocorreu um erro ao carregar as sugestões de texto!',
                X_CREATE: 'Ocorreu um erro ao criar a sugestão de texto!',
                X_UPDATE: 'Ocorreu um erro ao atualizar a sugestão de texto!',
                X_DELETE: 'Ocorreu um erro ao deletar a sugestão de texto!',
            },
            NOTIFICATIONS: {
                LOAD: 'Ocorreu um erro ao carregar as notificações!',
                MARK_AS_READ: 'Falha ao marcar a notificação como lida!',
                MARK_ALL_AS_READ:
                    'Falha ao marcar todas as notificações como lidas!',
            },
        },

        RESET_PASSWORD: {
            RESET_PASSWORD_TEXT:
                'Por favor, digite seu endereço de e-mail para redefinir sua senha',
            SEND_MAIL: 'Enviar e-Mail',
            RESET_PASSWORD: 'Redefinir senha',
            RESET_PASSWORD_CONFIRM:
                'Por favor, insira uma nova senha para sua conta.',
            RESET_PASSWORD_BUTTON: 'Redefinir senha',
            NEW_PASSWORD: 'Nova senha',
            E_MAIL_ENTRY_SUCCESS: 'E-mail enviado para ',
            PASSWORD_RESET_SUCCESS: 'Senha alterada com sucesso',
            PASSWORDS_DO_NOT_MATCH: 'As senhas não são iguais',
        },
        GENERIC_BUTTONS: {
            OK: 'OK',
        },
        GENERIC_LABELS: {
            YES: 'Sim',
            NO: 'Não',
        },
        // For now the demo mode is only available in English and German. It's
        // added here for completeness.
        DEMO_MODE: {
            POPUP: {
                HEADER_START: 'Welcome to your',
                HEADER_END: ' demonstration!',
                HINT: 'Please note the following:',
                NO_CONFIDENTIAL_INFO:
                    'Please do not enter confidential information.',
                SAVING_IS_DISABLED:
                    'Saving changes is not possible.',

                CTA_GET_HELP: 'Get Help',

                CONFIRM: 'I understand',
            }
        },
    },
};
