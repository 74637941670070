import { getEnvCommon } from './env-common';
import { F2GUIConf } from './f2g-ui-conf';

const defaultBasePath = 'https://dev1-api.ustrich2go.com';

export const environment: F2GUIConf = {
    defaultBasePath,
    production: false,
    hmr: false,
    ...getEnvCommon(defaultBasePath),
};
