import { RxJsonSchema } from 'rxdb';

export const boilerplateSchema: RxJsonSchema = {
    title: 'boilerplate schema',
    description: 'describes a boilerplate',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true
        },
        title: {
            type: 'string'
        },
        projectId: {
            type: 'string',
        },
        creationDate: {
            type: 'string'
        },
        markedAsDelete: {
            type: ['boolean', 'null']
        },
        updateDateTime: {
            type: 'string'
        },
        parentID: {
            type: ['string', 'null']
        }
    },
    indexes: [
        'projectId'
    ],
    additionalProperties: false
};
