<div class="w-100-p" fxLayout="row">
    <div fxFlex fxLayout="column" class="w-100-p" *ngIf="filterItem.showSlider">
        <mat-label class="slider-label">
            {{ filterItem.labelTranslationIdentifier | translate }}
        </mat-label>
        <mat-slider
            (change)="updateValue()"
            color="accent"
            [min]="filterItem.minValue"
            [max]="filterItem.maxValue"
            [step]="filterItem.step"
            [(ngModel)]="filterItem.value"
            thumbLabel
            class="adjust-slider"
        ></mat-slider>
    </div>
    <mat-form-field fxflex *ngIf="!filterItem.showSlider" class="w-100-p">
        <mat-label class="slider-label">
            {{ filterItem.labelTranslationIdentifier | translate }}
        </mat-label>
        <input
            matInput
            type="number"
            [min]="filterItem.minValue"
            [max]="filterItem.maxValue"
            [step]="filterItem.step"
            (change)="updateValue()"
            [(ngModel)]="filterItem.value"
            class="text-align-right"
        />
    </mat-form-field>
</div>
