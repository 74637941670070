import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { ErrorUtils } from 'app/core/utils/error-util';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { SyncService } from 'app/main/sync/sync.service';
import { from, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
    filter,
    tap,
} from 'rxjs/operators';
import { LoadBoilerplatesRequested } from '../boilerplates/boilerplates.actions';
import { LoadContactsRequested } from '../contacts/contacts.actions';
import {
    LoadDiaryEntriesRequest,
    SyncDiaryCommands,
    UpdateDiaryEntriesAttachmentIds,
} from '../diary/diary.actions';
import {
    LoadIssuesRequest,
    SyncIssueCommands,
    UpdateIssueAttachmentIds,
} from '../issues/issues.actions';
import { LoadLocationsRequested } from '../locations/locations.actions';
import { LoadProjectTemplatesRequest } from '../project-templates/project-templates.actions';
import { LoadProjectsRequest } from '../projects/projects.actions';
import {
    LoadProjectContactsRequest,
    LoadProjectCraftsRequest,
    LoadProjectCraftToPlanRequested,
    LoadProjectPartnersRequested,
    LoadProjectRolesRequest,
} from '../settings/settings.actions';
import {
    DeleteCommandError,
    DeleteCommandRequest,
    DeleteCommandSuccess,
    SetForceOffline,
    SetProjectsToSync,
    StoreCommandError,
    StoreCommandRequest,
    StoreCommandSuccess,
    SyncActions,
    SyncActionTypes,
    TriggerFullSync,
    SetCommandSyncAfterOnlineInProgress,
    SyncAfterOffline,
    FailedToDownloadAttachments,
    DiscardCommandsWithErrors,
    NavigateToSync,
} from './sync.actions';
import { getProjectsToSync } from './sync.selectors';
import { getCanPerformSyncAfterOffline } from './sync-after-offline.selectors';
import { getIsDevice } from '../core/core.selectors';
import { Router } from '@angular/router';
import { getCurrentProjectId } from '../router/router.selectors';

@Injectable()
export class SyncEffects {
    @Effect()
    $storeCommand = this.actions$.pipe(
        ofType<StoreCommandRequest>(SyncActionTypes.StoreCommandRequest),
        map((action) => action.payload.command),
        mergeMap((command) =>
            from(this.commandDb.insert(command)).pipe(
                mergeMap((commandDoc) => [
                    new StoreCommandSuccess({
                        command,
                        databaseId: commandDoc.primary,
                    }),
                    new UpdateIssueAttachmentIds(),
                    new UpdateDiaryEntriesAttachmentIds(),
                ]),
                catchError((error) => of(new StoreCommandError({ error })))
            )
        )
    );

    @Effect({ dispatch: false })
    storeCommandError$ = this.actions$.pipe(
        ofType<StoreCommandError>(SyncActionTypes.StoreCommandError),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SYNC.STORE_COMMAND'
            );

            return of();
        })
    );

    @Effect()
    $deleteCommand = this.actions$.pipe(
        ofType<DeleteCommandRequest>(SyncActionTypes.DeleteCommandRequest),
        map((action) => action.payload.query),
        mergeMap((query) =>
            from(this.commandDb.delete(query)).pipe(
                map(() => new DeleteCommandSuccess(query)),
                catchError((error) => of(new DeleteCommandError({ error })))
            )
        )
    );

    @Effect({ dispatch: false })
    deleteCommandError$ = this.actions$.pipe(
        ofType<DeleteCommandError>(SyncActionTypes.DeleteCommandError),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SYNC.DELETE_COMMAND'
            );

            return of();
        })
    );

    @Effect()
    triggerFullSync = this.actions$.pipe(
        ofType<TriggerFullSync>(SyncActionTypes.TriggerFullSync),
        withLatestFrom(this.store.pipe(select(getProjectsToSync))),
        switchMap(([action, projectsToSync]) => {
            let actions: Action[] = [];

            if (action.payload.withGlobalResources) {
                actions = [
                    new LoadProjectsRequest({ withArchived: false }),
                    new LoadProjectTemplatesRequest(),
                    new LoadContactsRequested(),
                ];
            }
            for (const projectId of projectsToSync) {
                const projectActions = [
                    new LoadIssuesRequest({ projectId, archived: false }),
                    new LoadDiaryEntriesRequest({ projectId, archived: false }),
                    new LoadLocationsRequested({ projectId }),
                    new LoadBoilerplatesRequested({ projectId }),
                    new LoadProjectPartnersRequested({ projectId }),
                    new LoadProjectCraftsRequest({ projectId }),
                    new LoadProjectRolesRequest({ projectId }),
                    new LoadProjectCraftToPlanRequested({ projectId }),
                    new LoadProjectContactsRequest({ projectId }),
                ];
                actions = [...actions, ...projectActions];
            }

            return actions;
        })
    );

    @Effect()
    setForceOffline$ = this.actions$.pipe(
        ofType<SetForceOffline>(SyncActionTypes.SetForceOffline),
        map((action) => action.payload.forceOffline),
        switchMap((forceOffline) =>
            from(
              Preferences.set({
                    key: 'forceOffline',
                    value: forceOffline.toString(),
                })
            ).pipe(
                switchMap(() => [
                    new SyncIssueCommands(),
                    new SyncDiaryCommands(),
                ])
            )
        )
    );

    @Effect()
    setProjectsToSync$ = this.actions$.pipe(
        ofType<SetProjectsToSync>(SyncActionTypes.SetProjectsToSync),
        map((action) => action.payload.projectsToSync),
        switchMap((projectsToSync) =>
            from(
              Preferences.set({
                    key: 'projectsToSync',
                    value: JSON.stringify(projectsToSync),
                })
            ).pipe(
                switchMap(() => [
                    new TriggerFullSync({ withGlobalResources: true }),
                ])
            )
        )
    );

    @Effect()
    syncAfterOffline$ = this.actions$.pipe(
        ofType<SyncAfterOffline>(SyncActionTypes.SyncAfterOffline),
        withLatestFrom(this.store.pipe(select(getCanPerformSyncAfterOffline))),
        filter(
            ([action, canPerformSyncAfterOffline]) => canPerformSyncAfterOffline
        ),
        switchMap(() => [
            new SetCommandSyncAfterOnlineInProgress({
                inProgress: false,
            }),
            new TriggerFullSync({
                withGlobalResources: true,
            }),
        ])
    );

    @Effect({ dispatch: false })
    failedToDownloadAttachments$ = this.actions$.pipe(
        ofType<FailedToDownloadAttachments>(
            SyncActionTypes.FailedToDownloadAttachments
        ),
        tap(() => {
            this.errorUtils.showSingleMessageOrDefault(
                null,
                'SYNC.DOWNLOAD_ATTACHMENTS'
            );
        })
    );

    @Effect()
    discardCommandsWithErrors$ = this.actions$.pipe(
        ofType<DiscardCommandsWithErrors>(
            SyncActionTypes.DiscardCommandsWithErrors
        ),
        withLatestFrom(this.store.select(getIsDevice)),
        filter(([action, device]) => device),
        mergeMap(([action, device]) => {
            const deletes: Action[] = [];
            const discardParameters = action.payload.discardParameters;

            for (const discardParameter of discardParameters) {
                const diaryOrIssue =
                    discardParameter.entityType === 'issue' ||
                    discardParameter.entityType === 'diary';
                if (diaryOrIssue) {
                    deletes.push(
                        new DeleteCommandRequest({
                            query: {
                                entityId: discardParameter.entityId,
                            },
                        })
                    );

                    // delete plan commands where the issue they belong
                    // to had an error (e.g. creating an issue failed)
                    deletes.push(
                        new DeleteCommandRequest({
                            query: {
                                entityType: 'plan',
                                issueId: discardParameter.entityId,
                            },
                        })
                    );
                } else if (discardParameter.entityType === 'plan') {
                    // only a plan failed and not its issue
                    deletes.push(
                        new DeleteCommandRequest({
                            query: {
                                entityType: 'plan',
                                issueId: discardParameter.entityId,
                            },
                        })
                    );
                } else {
                    console.error(
                        'trying to discard commands with unknown entityType: ' +
                            discardParameter.entityType
                    );
                }
            }

            return deletes;
        })
    );

    @Effect({ dispatch: false })
    navigateToSync$ = this.actions$.pipe(
        ofType<NavigateToSync>(SyncActionTypes.NavigateToSync),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        tap(([action, projectId]) => {
            if (!projectId) {
                this.router.navigate(['/sync']);
            } else {
                this.router.navigate(['/projects', projectId, 'sync']);
            }
        })
    );

    constructor(
        private router: Router,
        private actions$: Actions<SyncActions>,
        private commandDb: CommandDbService<any>,
        private syncService: SyncService,
        private store: Store,
        private errorUtils: ErrorUtils
    ) {}
}
