import { Component, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Contact } from 'app/core/rest-api';

@Component({
    selector: 'acc-subscribed-service-selector',
    templateUrl: './subscribed-service-selector.component.html',
    styleUrls: ['./subscribed-service-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SubscribedServiceSelectorComponent,
            multi: true
        }
    ]
})
export class SubscribedServiceSelectorComponent
    implements OnInit, ControlValueAccessor {
    value: string[] = [];
    isDisabled = false;
    formControl = new UntypedFormControl([]);
    onChange: (value: string) => void;
    subscribedServices = Object.keys(Contact.SubscribedServicesEnum);
    constructor() {}

    ngOnInit(): void {}

    onSelectionChange(change: MatSelectChange): void {
        const newValue =
            change.value.length > 0 ? change.value.toString() : null;
        this.onChange(newValue);
    }

    writeValue(value: string): void {
        this.value = value ? value.split(',') : [];
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // user interacted with a control or not
    }

    compareFunc(o1: any, o2: any): boolean {
        return o1.trim() === o2.trim();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        setTimeout(() => {
            this.formControl.disable();
        });
    }
}
