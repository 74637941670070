<ng-container [formGroup]="form">
    <ng-container *ngIf="template.customAttributes">
        <ng-container
            *ngFor="
                let customField of template.customAttributes
                    | keyvalue: keepOriginalOrder;
                let i = index
            "
            [ngSwitch]="customField.key"
        >
            <ng-container *ngIf="customField.value.shownInPrintDialog">
                <div
                    *ngSwitchCase="'IncludeProtocol'"
                    fxLayout="column"
                    class="mb-32"
                >
                    <mat-label class="print-dialog-label">
                        {{ 'APP.FORM_LETTER_DIALOG.ADD_ISSUE_DETAILS_TO_FORM_LETTER' | translate }}
                    </mat-label>

                    <mat-radio-group
                        aria-label="Select an option"
                        [formControlName]="customField.key"
                    >
                        <mat-radio-button value="true">{{
                            'APP.FORM_LETTER_DIALOG.WITH_ISSUE_DETAILS'
                                | translate
                        }}</mat-radio-button>
                        <mat-radio-button value="false">{{
                            'APP.FORM_LETTER_DIALOG.WITHOUT_ISSUE_DETAILS'
                                | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div
                    *ngSwitchCase="'IncludeSignature'"
                    fxLayout="column"
                    class="mb-32"
                >
                    <mat-label class="print-dialog-label">
                        {{ 'APP.FORM_LETTER_DIALOG.ADD_SIGNATURE_TO_FORM_LETTER' | translate }}
                    </mat-label>

                    <mat-radio-group
                        aria-label="Select an option"
                        [formControlName]="customField.key"
                    >
                        <mat-radio-button value="true">{{
                            'APP.FORM_LETTER_DIALOG.WITH_SIGNATURE' | translate
                        }}</mat-radio-button>
                        <mat-radio-button value="false">{{
                            'APP.FORM_LETTER_DIALOG.WITHOUT_SIGNATURE'
                                | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
