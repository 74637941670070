<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
    <p>{{ data.question }}</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close>{{ 'APP.CANCEL' | translate }}</button>
    <button
        mat-button
        color="accent"
        [mat-dialog-close]="true"
        cdkFocusInitial
        data-test="confirm-dialog-button"
    >
        {{ data.confirmLabel }}
    </button>
</mat-dialog-actions>
