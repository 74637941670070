import { Injectable } from '@angular/core';
import { MongoFile } from 'app/core/rest-api';

import { DatabaseService } from 'app/db/database.service';
import { FileMetadataCollection } from 'app/db/file-metadata/file-metadata.collection';
import {
    FileMetadataDocType,
    FileMetadataDocument,
} from 'app/db/file-metadata/file-metadata.document';
import { RxError, RxQuery, uncacheRxQuery } from 'rxdb';

import { FileMetadataStorage } from './file-metadata-storage';

function apiToSchema(file: MongoFile): FileMetadataDocType {
    const schemaItem: FileMetadataDocType & MongoFile = {
        ...file,
    };

    // can be obtain via id reference
    delete schemaItem.owner;
    delete schemaItem.updater;
    delete schemaItem.creator;

    // 'language' field name is not allowed in current RxDB
    // configuration
    schemaItem.fileLanguage = schemaItem.language;
    delete schemaItem.language;

    return schemaItem;
}

function schemaToApi(doc: FileMetadataDocument | null): MongoFile | null {
    if (!doc) {
        return null;
    }

    const json = doc.toJSON();

    return schemaToApiRaw(json);
}

function schemaToApiRaw(doc: FileMetadataDocType | null): MongoFile | null {
    if (!doc) {
        return null;
    }

    const apiItem: FileMetadataDocType & MongoFile = { ...doc };

    delete apiItem.owner;
    delete apiItem.updater;
    delete apiItem.creator;

    if (apiItem.fileLanguage) {
        apiItem.language = apiItem.fileLanguage;
    }
    delete apiItem.fileLanguage;

    return apiItem;
}

@Injectable()
export class RxDBFileMetadataStorage extends FileMetadataStorage {
    private collection: FileMetadataCollection;

    constructor(private databaseService: DatabaseService) {
        super();
    }

    private createFindOneByIdQuery(
        attachmentId: string
    ): RxQuery<FileMetadataDocType, FileMetadataDocument> {
        return this.collection.findOne({
            selector: {
                id: attachmentId,
            },
        });
    }

    private getById(
        attachmentId: string
    ): Promise<FileMetadataDocument | null> {
        // note: this check can be removed, if we turn on strictNullChecks some time
        //       in the future
        if (!attachmentId) {
            // if the id is undefined the query matches a
            // document and returns something completly unrelated
            throw Error('attachmentId is undefined or null');
        }

        return this.createFindOneByIdQuery(attachmentId).exec();
    }

    /**
     * Fetch and remove file metadata for the given attachment id.
     *
     * Note: This method will just return null, if the attachment does not exist,
     *       e.g. if it was already removed.
     */
    private async fetchAndRemove(
        id: string
    ): Promise<FileMetadataDocType | null> {
        const rxDocument = await this.getById(id);

        if (!rxDocument) {
            return null;
        }

        const document = rxDocument.toJSON(false);

        await rxDocument.remove();

        return document;
    }

    async init(): Promise<void> {
        // actual initialization of collection is handled by DatabaseService
        this.collection = this.databaseService.db.collections['file_metadata'];
    }

    async attachmentExists(attachmentId: string): Promise<boolean> {
        const document = await this.getById(attachmentId);
        return !!document;
    }

    async getMetadata(attachmentId: string): Promise<MongoFile | null> {
        const apiItem = schemaToApi(await this.getById(attachmentId));
        return apiItem;
    }

    async getMetadataForIds(
        attachmentIds: string[]
    ): Promise<(MongoFile | null)[]> {
        if (!attachmentIds) {
            throw Error('attachmentIds are undefined or null');
        }

        if (attachmentIds.length === 0) {
            return [];
        }

        // explicitly check each attachment id
        for (let i = 0; i < attachmentIds.length; i++) {
            const attachmentId = attachmentIds[i];
            if (!attachmentId) {
                throw Error(
                    `attachmentId with index ${i} is undefined or null`
                );
            }
        }

        // Using PouchDB directly: RxDB caching for this query does not have to
        // be reset on changes. Because we can't know which subset of the attachments
        // will be fetched

        const options = {
            include_docs: true,
            keys: attachmentIds,
        };
        const allDocs = await this.collection.pouch.allDocs(options);

        return allDocs.rows.map((row) => {
            type Document = FileMetadataDocType &
                PouchDB.Core.IdMeta &
                PouchDB.Core.RevisionIdMeta;

            const doc: Document = row.doc;
            if (!doc) {
                console.warn(`no doc for attachment id ${row.id} in database`);
                return null;
            }

            // fix schema mismatch (_id in PouchDB, id in RxDB)
            const docWithFixedId: Document = {
                ...doc,
                id: doc._id,
            };
            delete docWithFixedId._id;

            // remove not needed revision information
            delete docWithFixedId._rev;

            return schemaToApiRaw(docWithFixedId);
        });
    }

    async upsertMetadata(file: MongoFile): Promise<void> {
        const schemaItem = apiToSchema(file);
        await this.collection.upsert(schemaItem);
    }

    async removeMetadataFor(attachmentId: string): Promise<void> {
        const document = await this.getById(attachmentId);
        await document.remove();
    }

    async bulkStoreMeta(files: MongoFile[]): Promise<void> {
        const results = await this.collection.pouch.bulkDocs(
            files.map((file) => {
                return {
                    ...apiToSchema(file),
                    _id: file.id,
                };
            })
        );

        for (const result of results) {
            if (!result.ok) {
                console.warn(
                    'failed to store metadata for attachment',
                    result.id
                );
            }

            // otherwise RxDB does not recognize the change in the underlying document
            uncacheRxQuery(
                this.collection._queryCache,
                this.createFindOneByIdQuery(result.id)
            );
            this.collection._docCache.delete(result.id);
        }

        // invalidate RxDB cache for the query returning all docs
        uncacheRxQuery(this.collection._queryCache, this.createFindAllQuery());
    }

    async getStoredAttachmentIds(): Promise<Set<string>> {
        const allDocs = await this.createFindAllQuery().exec();

        return new Set(allDocs.map((doc) => doc.id));
    }

    private createFindAllQuery(): RxQuery<
        FileMetadataDocType,
        FileMetadataDocument[]
    > {
        return this.collection.find();
    }

    async moveMetadata(from: string, to: string): Promise<void> {
        try {
            const fromDocument = await this.fetchAndRemove(from);

            if (!fromDocument) {
                // document already removed, ignoring
                return;
            }

            fromDocument.id = to;

            await this.collection.insert(fromDocument);
        } catch (error) {
            const updateConflict = (error as RxError).code === 'COL19';
            if (updateConflict) {
                // occurs if the document was already inserted
                // => the file was already downloaded by the sync logic
                // => the external file is newer because it is always a new file
                // => do not try to overwrite it and ignore this error
                return;
            }

            throw error;
        }
    }

    async clear(): Promise<void> {
        // this is handled by DatabaseService
    }
}
