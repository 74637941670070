import { EntityType } from './entity-type';

type Target = 'db' | 'server';

interface SyncMessagePayload {
    entityId: string;
    projectId: string;
    entityType: EntityType;
    target: Target;
    done?: boolean;
}

export class SyncMessage implements SyncMessagePayload {
    done: boolean;
    entityId: string;
    projectId: string;
    entityType: EntityType;
    target: Target;
    constructor(payload: SyncMessagePayload) {
        this.done = false;
        for (const [key, value] of Object.entries(payload)) {
            this[key] = value;
        }
    }
}
