/**
 * eTeacher API
 * Last Build: 10/11/2022 10:53:20 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The result of importing a row. Gives the status, and if there was               a problem, a reason, and (if applicable), the field
 */
export interface CsvImportRowResult { 
    status?: CsvImportRowResult.StatusEnum;
    field?: CsvImportRowResult.FieldEnum;
    reason?: CsvImportRowResult.ReasonEnum;
}
export namespace CsvImportRowResult {
    export type StatusEnum = 'SKIPPED' | 'CREATED' | 'FAILED';
    export const StatusEnum = {
        SKIPPED: 'SKIPPED' as StatusEnum,
        CREATED: 'CREATED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum
    };
    export type FieldEnum = 'Firstname' | 'Name' | 'Email';
    export const FieldEnum = {
        Firstname: 'Firstname' as FieldEnum,
        Name: 'Name' as FieldEnum,
        Email: 'Email' as FieldEnum
    };
    export type ReasonEnum = 'NoValue' | 'InvalidFormat' | 'CsvFormatInvalid';
    export const ReasonEnum = {
        NoValue: 'NoValue' as ReasonEnum,
        InvalidFormat: 'InvalidFormat' as ReasonEnum,
        CsvFormatInvalid: 'CsvFormatInvalid' as ReasonEnum
    };
}


