<div class="activity mat-elevation-z8" [@openClose]="activity.show ? 'open' : 'closed'" >
    <button mat-icon-button class="close-button" (click)="clickClose()"><mat-icon svgIcon="f2g_close"></mat-icon></button>
    <div fxFlex fxLayout="row"> 
        <div class="icon-area" fxLayoutAlign="center start">
            <mat-icon *ngIf="activity.status==='FAILED'"   svgIcon="f2g_warning"></mat-icon>
            <mat-icon *ngIf="activity.status==='SUCCESS'"  svgIcon="f2g_check"></mat-icon> 
            <mat-spinner diameter="50" *ngIf="activity.status==='RUNNING'"></mat-spinner>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
            <h4 class="title">{{'ACTIVITIES.' + activity.translationTemplateKey + '.TITLE'| translate:activity.translationParams}}</h4>
            <div class="desc">{{'ACTIVITIES.' + activity.translationTemplateKey + '.DESCRIPTION_'+activity.status|translate:activity.translationParams}}</div>
            <div class="button-bar" *ngIf="activity.actions.length" fxFlexAlign="end" fxLayout="row" fxLayoutGap="4px">
                <ng-container *ngFor="let action of activity.actions">
                    <button mat-button color="primary" class="action-button" *ngIf="action.type !== 'HREF'" (click)="activateAction(action)"><mat-icon class="mr-8" *ngIf="action.icon">{{action.icon}}</mat-icon>{{action.title | translate}}</button>
                    <a      mat-button color="accent" class="action-button" *ngIf="action.type === 'HREF'" [href]="action.url" target="_blank" [download]="action.filename||''"><mat-icon class="mr-8" *ngIf="action.icon">{{action.icon}}</mat-icon>{{action.title | translate}}</a> 
                </ng-container>
            </div>
        </div>
    </div>
</div> 