import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from './custom-route-serializer';

export const selectRouterState = createFeatureSelector<
    RouterReducerState<RouterStateUrl>
>('router');

export const getPath = createSelector(
    selectRouterState,
    (router) => router && router.state && router.state.url
);

export const getRouterParams = createSelector(
    selectRouterState,
    (router) => router && router.state && router.state.params
);

export const getQueryParams = createSelector(
    selectRouterState,
    (router) => router && router.state && router.state.queryParams
);

export const getCurrentProjectId = createSelector(
    getRouterParams,
    (params) => params?.projectId as string|null|undefined,
);

export const getCurrentIssueId = createSelector(
    getRouterParams,
    (params) => params && params.issueId
);

export const getSelectedConflictItem = createSelector(
    getQueryParams,
    (params) => params && params.selectedConflictItem
);

export const getCurrentRevisionDate = createSelector(
    getQueryParams,
    (query) => query && query.revision
);

export const getIsConflict = createSelector(
    getQueryParams,
    (query) => query && query.conflict
);

export const getCurrentIssueIdQuery = createSelector(
    getQueryParams,
    (query) => query && query.issueId
);

export const getCurrentDiaryEntriesDate = createSelector(
    getQueryParams,
    (params) => params && params.diaryEntriesIsoDate
);

export const getCurrentDiaryEntriesType = createSelector(
    getQueryParams,
    (params) => params && params.diaryEntriesType
);

export const getDiaryTypeToCreate = createSelector(
    getQueryParams,
    (params) => params && params.typeToCreate
);

export const getshowInspectionList = createSelector(
    getQueryParams,
    (params) => params && params.showInspectionList
);

export const getCurrentDiaryEntryId = createSelector(
    getQueryParams,
    (params) => params && params.diaryEntryId
);

export const getCurrentLocationId = createSelector(
    getQueryParams,
    (params) => params && params.locationId
);

export const getCurrentDiaryInspectionSelectedTab = createSelector(
    getQueryParams,
    (params) => params && params.diaryInspectionSelectedTab
);

export const getShowAllOnPlan = createSelector(
    getQueryParams,
    (params) => !!params?.showAllOnPlan
);
