<div
    class="navbar-top"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
    <div class="logo">
        <img
            class="logo-icon h-40 logo-big"
            src="assets/images/logos/flink.svg"
        />
        <h1 class="logo-text  pl-4">uStrich2go</h1>
    </div>

    <img class="logo-small w-40" src="assets/images/logos/flink.svg" />

    <div class="buttons">
        <button
            mat-icon-button
            class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()"
            fxHide.lt-lg
        >
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button
            mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md
        >
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>
    </div>
</div>

<div
    *ngIf="currentProject$ | async as currentProject"
    class="current-project"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
    fxHide.gt-xs
>
    <h3>{{ currentProject.title }}</h3>
    <div *ngIf="language$ | async as language">
        <p>
            {{ 'PROJECT.LIST.FORM.BEGIN' | translate }}:
            {{
                currentProject.startDate | date: 'shortDate':undefined:language
            }}
        </p>
        <p>
            {{ 'DATA.COMPLETION' | translate }}:
            {{ currentProject.dueDate | date: 'shortDate':undefined:language }}
        </p>
    </div>
</div>

<div
    class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
    <div
        class="user"
        fxLayout="column"
        [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
    >
        <div
            *ngLet="currentProject$ | async as currentProject"
            class="avatar-container"
            [ngClass]="fuseConfig.layout.navbar.primaryBackground"
        >
            <ng-container *ngIf="currentProject">
                <img
                    *ngIf="projectThumb; else thumbLoading"
                    class="avatar"
                    [src]="projectThumb | safe: 'url'"
                />

                <ng-template #thumbLoading>
                    <mat-progress-spinner
                        diameter="72"
                        mode="indeterminate"
                        color="accent"
                    ></mat-progress-spinner>
                </ng-template>
            </ng-container>

            <ng-container *ngLet="currentCompany$ | async as currentCompany">
                <ng-container *ngIf="!currentProject">
                    <img
                        *ngIf="!currentCompany?.photo; else withPhoto"
                        class="avatar"
                        src="assets/images/custom/company.svg"
                    />

                    <ng-template #withPhoto>
                        <ng-container
                            *ngIf="
                                currentCompany.photo &&
                                    assetPath + currentCompany?.photo
                                    | secure
                                    | async as avatarImg
                            "
                        >
                            <img
                                *ngIf="(currentCompany$ | async).photo"
                                class="avatar"
                                [src]="avatarImg | safe: 'url'"
                            />
                        </ng-container>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

    <div class="user-info-container" fxHide.gt-xs>
        <div
            class="user-info"
            *ngLet="currentUser$ | async as currentUser"
            (click)="openUserSettingsDialog()"
        >
            <img
                class="user-image ml-0 ml-sm-12"
                src="/assets/images/custom/avatar.svg"
                *ngIf="!currentUser.contact?.photo; else withPhoto"
            />
            <ng-template #withPhoto>
                <ng-container
                    *ngIf="
                        assetPath + currentUser.contact.photo + thumbnailSuffix
                            | secure
                            | async as backgroundImage
                    "
                >
                    <img
                        class="user-image ml-0 ml-sm-12"
                        [src]="backgroundImage | safe: 'url'"
                    />
                </ng-container>
            </ng-template>
            <div class="username">{{ currentUser.username }}</div>
        </div>
    </div>

    <div class="logout-container" fxHide.gt-xs>
        <div class="logout" (click)="logout()" data-test="logout-button-xs">
            <mat-icon class="s-30">logout</mat-icon
            ><span>
                {{ 'APP.LOGOUT' | translate }}
            </span>
        </div>
    </div>
</div>
