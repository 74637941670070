import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { PlanPreviewComponent } from './plan-preview.component';
import { PlanPreviewDialogComponent } from './plan-preview-dialog.component';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';

@NgModule({
    declarations: [PlanPreviewComponent, PlanPreviewDialogComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatDialogModule,
        MatSnackBarModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        FormsModule,
        SharedPipesModule,
    ],
    exports: [PlanPreviewComponent, PlanPreviewDialogComponent],
})
export class PlanPreviewDialogModule {}
