/**
 * Color used to highlight annotations if the related issue is selected.
 * Note: This is only relevant for acc-combined-plan.
 */
export const ISSUE_SELECTED_COLOR = '#0066cc';

/**
 * Color used to display annotations if the related issue is not selected.
 * Note: This is only relevant for acc-combined-plan.
 */
export const ISSUE_NOT_SELECTED_COLOR = 'rgb(100%,9.019608%,26.666667%)';
