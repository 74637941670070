<div>
  <mat-form-field
    class="autosel-mat-form-field"
    [appearance]="appearance"
    [floatLabel]="floatLabel"
  >
    <mat-label translate>{{ label ? label : config.label }}</mat-label>
    <mat-chip-list
      (click)="openDialogOrFocus(filterInput)"
      #chipList
      [required]="required"
    >
      <mat-chip
        [ngClass]="{ 'chip-with-autosel-group': hasGroups }"
        (click)="openDialogOrFocus(filterInput)"
        *ngFor="let item of selectedItems; trackBy: trackByFn"
        [removable]="!filterInput.disabled"
        (removed)="openDialogOrRemove(item)"
      >
        <div class="autosel-group">
          <span *ngIf="hasGroups" class="autosel-group-label">
            {{ item.groupLabel }}
          </span>
          {{ item[config.properties.title] }}
        </div>
        <mat-icon
          class="autosel-remove-icon"
          *ngIf="!filterInput.disabled"
          matChipRemove
          >close</mat-icon
        >
      </mat-chip>

      <input
        type="text"
        matInput
        class="autosel-input"
        #filterInput
        [style.visibility]="mobileMode ? 'hidden' : 'visible'"
        (blur)="onBlur($event, filterInput)"
        [matAutocomplete]="accAutoComplete"
        [matChipInputFor]="chipList"
        [formControl]="filterControl"
      />
    </mat-chip-list>
  </mat-form-field>

  <mat-autocomplete
    #accAutoComplete="matAutocomplete"
    autoActiveFirstOption
    [classList]="['asc-autocomlete-custom']"
    (optionSelected)="setItem($event.option.value)"
    (opened)="filterInput.select()"
  >
    <div *ngIf="!isMobile && !dialogMode">
      <div *ngIf="hasGroups">
        <div
          *ngFor="
            let group of filteredItems | keyvalue;
            trackBy: trackByFnGroups
          "
        >
          <div class="group-container" *ngIf="group.value.items.length > 0">
            <div class="autosel-label-container">
              <label
                class="autosel-label-text mat-elevation-z6"
                [ngClass]="groupLabelNgClass"
              >
                {{ group.value.label }}
              </label>
            </div>
            <mat-option
              [ngClass]="{ 'autosel-h-60': config.avatar }"
              *ngFor="let item of group.value.items; trackBy: trackByFn"
              [value]="item"
            >
              <asc-auto-selector-item
                [ngClass]="{ 'avatar-rounded': config.avatarRounded }"
                [title]="item[config.properties.title]"
                [avatar]="item[config.properties.avatar]"
              ></asc-auto-selector-item>
            </mat-option>
          </div>
        </div>
      </div>

      <div *ngIf="!hasGroups">
        <mat-option
          [ngClass]="{ 'autosel-h-60': config.avatar }"
          *ngFor="let item of filteredItems; trackBy: trackByFn"
          [value]="item"
        >
          <asc-auto-selector-item
            [ngClass]="{ 'avatar-rounded': config.avatarRounded }"
            [title]="item[config.properties.title]"
            [avatar]="item[config.properties.avatar]"
          ></asc-auto-selector-item>
        </mat-option>
      </div>
      <ng-container *ngIf="noMatchComponent && !filteredItems.length">
        <mat-option value="{}" disabled class="no-match" (click)="$event.stopPropagation();">
          <ng-template [ngTemplateOutlet]="noMatchComponent.innerTemplate"></ng-template>
        </mat-option>
      </ng-container>
    </div>
  </mat-autocomplete>
</div>
