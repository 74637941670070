// for details about this implementations, see
// https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work

/**
 * Occurs if an argument is invalid.
 */
export class ArgumentError extends Error {
    constructor(parameter: string, reason: string) {
        super(`${parameter}: ${reason}`);
        Object.setPrototypeOf(this, ArgumentError.prototype);
    }
}

/**
 * Occurs if an argument is null or undefined, but is required.
 */
export class ArgumentMissingError extends ArgumentError {
    constructor(parameter: string) {
        super(parameter, 'null/undefined not allowed');
        Object.setPrototypeOf(this, ArgumentMissingError.prototype);
    }
}

/**
 * Occurs if an operation is invalid for the current state of the object.
 * 
 * An example is trying to run initialization logic more than one time.
 */
export class InvalidOperationError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, InvalidOperationError.prototype);
    }
}
