import { InjectionToken } from '@angular/core';

/**
 * Options for allowed event checking in the plan preview.
 */
export interface EventAllowedCheckOptions {
    /**
     * The time after which the event is allowed in all cases in
     * milliseconds.
     */
    timeThreshold: number;

    /**
     * The distance of the center of the event beginning from which
     * the event should be allowed in case it's below the timeThreshold
     * in pixels.
     */
    distanceThreshold: number;
}

/**
 * The default options for EventAllowedCheck.
 */
export const defaultEventAllowedCheckOptions: EventAllowedCheckOptions = {
    timeThreshold: 100,
    distanceThreshold: 5,
};

/**
 * Injection token to use when providing custom options
 * for the EventAllowedService.
 */
export const EVENT_ALLOWED_OPTIONS = new InjectionToken<
    EventAllowedCheckOptions
>('EVENT_ALLOWED_OPTIONS');
