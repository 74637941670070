import { Action } from '@ngrx/store';
import { CustomAttributeTemplate, Project } from 'app/core/rest-api';
import { MarkedPlanInfo } from 'app/core/rest-api/model/markedPlanInfo';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { ProjectDocType } from 'app/db/projects/project.document';

export enum ProjectsActionTypes {
    LOAD_PROJECTS_REQUEST = '[Projects] Load Projects Request',
    LOAD_PROJECTS_SUCCESS = '[API] Load Projects Success',
    LOAD_PROJECTS_ERROR = '[API] Load Projects Error',

    CREATE_PROJECT_REQUEST = '[Projects] Create Project Request',
    CREATE_PROJECT_SUCCESS = '[API] Create Project Success',
    CREATE_PROJECT_ERROR = '[API] Create Project Error',

    CREATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST = '[Projects] Create Project Custom Attribute Request',
    CREATE_PROJECT_CUSTOM_ATTRIBUTES_ERROR = '[API] Create Project Custom Attribute Error',

    // PROJECTS
    UPSERT_PROJECTS_START = '[APP] Upsert Projects Start',
    UPSERT_PROJECT_FINISH = '[DB] Upsert Projects Finish',

    UPDATE_PROJECT_REQUEST = '[Projects] Update Project Request',
    UPDATE_PROJECT_SUCCESS = '[API] Update Project Success',
    UPDATE_PROJECT_ERROR = '[API] Update Project Error',

    UPDATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST = '[Projects] Update Project Custom Attribute Request',
    UPDATE_PROJECT_CUSTOM_ATTRIBUTES_ERROR = '[API] Update Project Custom Attribute Error',

    DELETE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST = '[Projects] Delete Project Custom Attribute Request',
    DELETE_PROJECT_CUSTOM_ATTRIBUTES_ERROR = '[API] Delete Project Custom Attribute Error',

    UPDATE_PROJECTS_REQUEST = '[Projects] Update Projects Request',
    UPDATE_PROJECTS_SUCCESS = '[API] Update Projects Success',
    UPDATE_PROJECTS_ERROR = '[API] Update Projects Error',

    TOGGLE_SELECT_PROJECT = '[Projects] Toggle Select Project',
    TOGGLE_SELECT_ALL_PROJECTS = '[Projects] Toggle Select all Projects',
    DESELECT_ALL_PROJECTS = '[Projects] Deselect all Projects',

    LOAD_MARKED_PLANS_INFO_REQUEST = '[API] Load Marked plans info Request',
    LOAD_MARKED_PLANS_INFO_SUCCESS = '[API] Load Marked plans info Success',

    TOGGLE_ARCHIVED = '[Projects] Toggle Archived',
}


/////////////
// Datatypes

/** Shared between {Create|Update|Delete}ProjectCustomAttributesRequest */
export interface ProjectCustomAttributeActionPayload {
    projectId: string;
    template: CustomAttributeTemplate;
    /** Controlls which action (LoadProjectsRequest or LoadProjectTemplatesRequest) will be emitted */
    projectType: 'PROJECT' | 'PROJECT_TEMPLATE';
}


/////////////
// Actions

export class LoadMarkedPlansInfoRequest implements Action {
    readonly type = ProjectsActionTypes.LOAD_MARKED_PLANS_INFO_REQUEST;

    constructor(public payload: { projectId: string }) { }
}

export class LoadMarkedPlansInfoSuccess implements Action {
    readonly type = ProjectsActionTypes.LOAD_MARKED_PLANS_INFO_SUCCESS;

    constructor(public payload: { markedPlansInfo: MarkedPlanInfo[] }) { }
}

export class LoadProjectsRequest implements Action {
    readonly type = ProjectsActionTypes.LOAD_PROJECTS_REQUEST;

    constructor(
        public payload: { withArchived?: boolean } = {
            withArchived: false,
        }
    ) { }
}

export class UpsertProjectsStart implements Action {
    readonly type = ProjectsActionTypes.UPSERT_PROJECTS_START;
    constructor(public payload: { projects: Project[] }) { }
}

export class UpsertProjectsFinish implements Action {
    readonly type = ProjectsActionTypes.UPSERT_PROJECT_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<ProjectDocType> }
    ) { }
}

export class LoadProjectsSuccess implements Action {
    readonly type = ProjectsActionTypes.LOAD_PROJECTS_SUCCESS;

    constructor(public payload: { projects: Project[]; fromDb: boolean }) { }
}

export class LoadProjectsError implements Action {
    readonly type = ProjectsActionTypes.LOAD_PROJECTS_ERROR;
}

export class CreateProjectRequest implements Action {
    readonly type = ProjectsActionTypes.CREATE_PROJECT_REQUEST;

    constructor(
        public payload: { project: Project; templateProjectId?: string }
    ) { }
}

export class CreateProjectSuccess implements Action {
    readonly type = ProjectsActionTypes.CREATE_PROJECT_SUCCESS;

    constructor(public payload: { project: Project }) { }
}

export class CreateProjectError implements Action {
    readonly type = ProjectsActionTypes.CREATE_PROJECT_ERROR;
}

export class CreateProjectCustomAttributesRequest implements Action {
    readonly type = ProjectsActionTypes.CREATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST;

    constructor(public payload: ProjectCustomAttributeActionPayload) { }
}

export class CreateProjectCustomAttributesError implements Action {
    readonly type = ProjectsActionTypes.CREATE_PROJECT_CUSTOM_ATTRIBUTES_ERROR;
}

export class UpdateProjectRequest implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECT_REQUEST;

    constructor(public payload: { project: Project }) { }
}

export class UpdateProjectSuccess implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECT_SUCCESS;

    constructor(public payload: { project: Project }) { }
}

export class UpdateProjectError implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECT_ERROR;
}

export class UpdateProjectCustomAttributesRequest implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST;

    constructor(public payload: ProjectCustomAttributeActionPayload) { }
}

export class UpdateProjectCustomAttributesError implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECT_CUSTOM_ATTRIBUTES_ERROR;
}

export class DeleteProjectCustomAttributesRequest implements Action {
    readonly type = ProjectsActionTypes.DELETE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST;

    constructor(public payload: ProjectCustomAttributeActionPayload) { }
}

export class DeleteProjectCustomAttributesError implements Action {
    readonly type = ProjectsActionTypes.DELETE_PROJECT_CUSTOM_ATTRIBUTES_ERROR;
}

export class UpdateProjectsRequest implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECTS_REQUEST;

    constructor(public payload: { projects: Project[] }) { }
}

export class UpdateProjectsSuccess implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECTS_SUCCESS;

    constructor(public payload: { projects: Project[] }) { }
}

export class UpdateProjectsError implements Action {
    readonly type = ProjectsActionTypes.UPDATE_PROJECTS_ERROR;
}

export class ToggleSelectProject implements Action {
    readonly type = ProjectsActionTypes.TOGGLE_SELECT_PROJECT;

    constructor(public payload: { projectId: string }) { }
}

export class ToggleSelectAllProjects implements Action {
    readonly type = ProjectsActionTypes.TOGGLE_SELECT_ALL_PROJECTS;
}

export class DeselectAllProjects implements Action {
    readonly type = ProjectsActionTypes.DESELECT_ALL_PROJECTS;
}

export class ToggleArchived implements Action {
    readonly type = ProjectsActionTypes.TOGGLE_ARCHIVED;
}

export type ProjectsActions =
    LoadMarkedPlansInfoRequest
    | LoadMarkedPlansInfoSuccess
    | LoadProjectsRequest
    | LoadProjectsSuccess
    | LoadProjectsError
    | CreateProjectRequest
    | CreateProjectSuccess
    | CreateProjectError
    | CreateProjectCustomAttributesRequest
    | CreateProjectCustomAttributesError
    | UpdateProjectRequest
    | UpdateProjectSuccess
    | UpdateProjectError
    | UpdateProjectCustomAttributesRequest
    | UpdateProjectCustomAttributesError
    | DeleteProjectCustomAttributesRequest
    | DeleteProjectCustomAttributesError
    | UpdateProjectsRequest
    | UpdateProjectsSuccess
    | UpdateProjectsError
    | ToggleSelectProject
    | ToggleSelectAllProjects
    | UpsertProjectsStart
    | UpsertProjectsFinish
    | DeselectAllProjects
    | ToggleArchived;
