import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { IssuesEffects } from './issues.effects';
import { IssueIdUpdateEffects } from './issue-id-update.effects';
import * as fromIssues from './issues.reducer';
@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        StoreModule.forFeature('issues', fromIssues.reducer),
        EffectsModule.forFeature([IssuesEffects, IssueIdUpdateEffects]),
    ],
    exports: [],
    providers: [],
})
export class IssuesStoreModule {}
