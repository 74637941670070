import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { GlobalBoilerplatesEffects } from './global-boilerplates.effects';
import * as fromGlobalBoilerplates from './global-boilerplates.reducer';
import { GlobalBoilerplatesService } from 'app/core/rest-api/api/globalBoilerplates.service';
@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        StoreModule.forFeature(
            'global-boilerplates',
            fromGlobalBoilerplates.reducer
        ),
        EffectsModule.forFeature([GlobalBoilerplatesEffects]),
    ],
    exports: [],
    providers: [GlobalBoilerplatesService],
})
export class GlobalBoilerplatesStoreModule {}
