import { ArgumentError, ArgumentMissingError } from 'app/core/utils/exceptions';

/**
 * Interface to abstract await both the mouse and the touch event
 * client target points. For documentation about clientX/clientY
 * refer to the documentation on the events.
 */
export interface ClientTarget {
    clientX: number;
    clientY: number;
}

/**
 * A simple type guard to check if the given event is a TouchEvent. Safe to
 * call when running in Safari (desktop).
 *
 * @param event The event to check.
 */
export function isTouchEvent(
    event: TouchEvent | MouseEvent
): event is TouchEvent {
    return window.TouchEvent && event instanceof TouchEvent;
}

/**
 * Check if this event is a multitouch event. Safe to call when running
 * in Safari (desktop).
 *
 * @param event The event to check.
 */
export function isMultitouchEvent(event: TouchEvent | MouseEvent): boolean {
    if (!isTouchEvent(event)) {
        return false;
    }

    if (event.type === 'touchend') {
        // check if the end event is part of a multitouch gesture
        const totalTouches = event.touches.length + event.changedTouches.length;
        console.warn(
            'checking touch end',
            event,
            'total touches',
            totalTouches
        );
        return totalTouches > 1;
    } else {
        return event.touches.length > 1;
    }
}

/**
 * Extract the client coordinates of the given single-touch or mouse event.
 *
 * Note: This also handles the case, that touchend only provides
 * the position as part of changed touches and uses that instead.
 *
 * @param event The event to extract the target.
 *
 * @throws `ArgumentError` If the passed event is a multi-touch event.
 * @throws `ArgumentMissingError` If the passed event is undefined/null.
 */
export function extractSingleClientTarget(
    event: TouchEvent | MouseEvent
): ClientTarget {
    if (!event) {
        throw new ArgumentMissingError('event');
    }

    if (window.TouchEvent && event instanceof TouchEvent) {
        if (isMultitouchEvent(event)) {
            throw new ArgumentError(
                'event',
                'touch event has more than one touch point'
            );
        }

        console.log('event.touches', event.touches);

        if (event.type === 'touchend') {
            return event.changedTouches.item(0);
        }

        return event.touches.item(0);
    } else if (event instanceof MouseEvent) {
        return event;
    } else {
        // Relying on a compile error from TypeScript is not possible
        // due to the "complex" if condition.
        throw new ArgumentError('event', 'unsupported event: ' + event.type);
    }
}
