import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Notification, NotificationsService } from 'app/core/rest-api';
import { ErrorUtils } from 'app/core/utils/error-util';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { getIsOnline } from '../core/core.selectors';
import {
    LoadNotificationsError,
    LoadNotificationsRequest,
    LoadNotificationsSuccess,
    MarkNotificationAsReadError,
    MarkNotificationAsReadRequest,
    MarkNotificationAsReadSuccess,
    MarkNotificationsAsReadError,
    MarkNotificationsAsReadRequest,
    MarkNotificationsAsReadSuccess,
    NotificationsActionTypes,
} from './notifications.actions';
import { getNotificationsLoaded } from './notifications.selectors';

@Injectable()
export class NotificationsEffects {
    @Effect()
    loadNotifications$ = this.actions$.pipe(
        ofType<LoadNotificationsRequest>(
            NotificationsActionTypes.LOAD_NOTIFICATIONS_REQUEST
        ),
        withLatestFrom(
            this.store.pipe(select(getNotificationsLoaded)),
            this.store.pipe(select(getIsOnline))
        ),
        filter(([action, loaded, isOnline]) => !loaded && isOnline),
        mergeMap(() =>
            this.notificationsService.notificationsGet().pipe(
                map(
                    (response) =>
                        new LoadNotificationsSuccess({
                            notifications: response.data,
                        })
                ),
                catchError((error) => of(new LoadNotificationsError({ error })))
            )
        )
    );

    @Effect({ dispatch: false })
    loadNotificationsError$ = this.actions$.pipe(
        ofType<LoadNotificationsError>(
            NotificationsActionTypes.LOAD_NOTIFICATIONS_ERROR
        ),
        map((action) => action.payload.error),
        withLatestFrom(this.store.pipe(select(getIsOnline))),
        switchMap(([error, isOnline]) => {
            if (isOnline) {
                this.errorUtils.showSingleMessageOrDefault(
                    error,
                    'NOTIFICATIONS.LOAD'
                );
            }
            return of();
        })
    );

    @Effect()
    markNotificationsAsRead$ = this.actions$.pipe(
        ofType<MarkNotificationsAsReadRequest>(
            NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_REQUEST
        ),
        mergeMap(() =>
            this.notificationsService.notificationsReadPost().pipe(
                map(
                    (response) =>
                        new MarkNotificationsAsReadSuccess({
                            notifications: response.data,
                        })
                ),
                catchError((error) =>
                    of(new MarkNotificationsAsReadError({ error }))
                )
            )
        )
    );

    @Effect({ dispatch: false })
    errorMarkNotificationsAsRead$ = this.actions$.pipe(
        ofType<MarkNotificationsAsReadError>(
            NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'NOTIFICATIONS.MARK_ALL_AS_READ'
            );

            return of();
        })
    );

    @Effect()
    markNotificationAsRead$ = this.actions$.pipe(
        ofType<MarkNotificationAsReadRequest>(
            NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_REQUEST
        ),
        mergeMap((action) =>
            this.notificationsService
                .notificationsByNotificationIdDelete(
                    action.payload.notification.id
                )
                .pipe(
                    map(
                        (response) =>
                            new MarkNotificationAsReadSuccess({
                                deletedNotification:
                                    action.payload.notification,
                            })
                    ),
                    catchError((error) => {
                        return of(new MarkNotificationAsReadError({ error }));
                    })
                )
        )
    );

    @Effect({ dispatch: false })
    errorMarkNotificationAsRead$ = this.actions$.pipe(
        ofType<MarkNotificationAsReadError>(
            NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'NOTIFICATIONS.MARK_AS_READ'
            );
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private store: Store<Notification>,
        private errorUtils: ErrorUtils
    ) {}
}
