import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExternalContact, ExternalContactSearchType } from 'app/core/rest-api';
import {
    ExternalContactsActions,
    ExternalContactsActionTypes,
} from './external-contacts.actions';
import { AuthActions } from '../auth/auth.actions';

export const adapter: EntityAdapter<ExternalContact> = createEntityAdapter<
    ExternalContact
>({
    sortComparer: (a: ExternalContact, b: ExternalContact) => {
        if (a && a.name && b && b.name) {
            return a?.name.localeCompare(b?.name)
        }
        return 0;
    },
});

export interface ExternalContactsState extends EntityState<ExternalContact> {
    loaded: boolean;
    loading: boolean;
    search: {
        searchType: ExternalContactSearchType;
        craftId?: string;
        searchTerm: string;
    };
}

export const initialState: ExternalContactsState = adapter.getInitialState({
    loaded: false,
    loading: false,
    search: {
        searchType: null,
        craftId: null,
        searchTerm: '',
    },
});

export function reducer(
    state = initialState,
    action: ExternalContactsActions | AuthActions
): ExternalContactsState {
    switch (action.type) {
        case ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_REQUESTED:
            return {
                ...state,
                search: { ...action.payload }, loading: true
            };

        case ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_SUCCESS:
            return adapter.setAll(action.payload.contacts, {
                ...state,
                loaded: true,
                loading: false,
            });

        case ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_ERROR:
            return { ...state, loading: false };

        // a complete registering also changes the contacts by adding a new contact
        case ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_REQUEST:
            return { ...state };

        // note: this also dispatched if RegisterCompleteRequested is successful
        case ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_SUCCESS:
            return adapter.addOne(action.payload.contact, {
                ...state,
            });

        case ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_ERROR:
            return { ...state };

        case ExternalContactsActionTypes.RELOAD_EXTERNAL_CONTACTS_REQUESTED:
            return {
                ...state, loading: true
            }

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
