<mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="start stretch"
    class="mat-typography fullscreen-content"
>
    <acc-plan-preview
        fxFlex="1 0"
        [planAsset]="{
            documentPath: data.documentPath,
            isPDF: data.isPdf
        }"
        [issue]="issue$ | async"
        [markedPlanChanges]="data.markedPlanChanges | async"
        (save)="onSave($event)"
        (exit)="onExit()"
        class="plan-preview"
    >
    </acc-plan-preview>
</mat-dialog-content>
