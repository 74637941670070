import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFilterMenuComponent } from './generic-filter-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FilterByAutoSelectorComponent } from './generic-filter-options/filter-by-auto-selector/filter-by-auto-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilterByTextComponent } from './generic-filter-options/filter-by-text/filter-by-text.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { FilterByNumberComponent } from './generic-filter-options/filter-by-number/filter-by-number.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FilterByDateRangeComponent } from './generic-filter-options/filter-by-date-range/filter-by-date-range.component';
import { FilterByDateComponent } from './generic-filter-options/filter-by-date/filter-by-date.component';
import { AutoSelectorModule } from '../auto-selector/auto-selector.module';

const materialModules = [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSliderModule,
    MatSelectModule,
    MatDatepickerModule,
];

const components = [
    GenericFilterMenuComponent,
    FilterByAutoSelectorComponent,
    FilterByTextComponent,
    FilterByNumberComponent,
    FilterByDateRangeComponent,
    FilterByDateComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        FormsModule,
        ...materialModules,
        AutoSelectorModule,
        TranslateModule.forChild(),
    ],
    exports: [...components],
})
export class GenericFilterMenuModule {}
