import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
    Contact,
    Location as FlinkLocation,
    ProjectPartner,
    TimeZoneShortInfo,
} from 'app/core/rest-api';
import { ProjectRole } from 'app/core/rest-api/model/projectRole';
import { SettingsActions, SettingsActionTypes } from './settings.actions';
import { ProjectRoleFilters } from 'app/main/settings/role-settings/models/project-role-filters.model';
import { settings } from 'cluster';

export const projectPartnerAdapter = createEntityAdapter<ProjectPartner>();
export const locationCraftToPlanAdapter = createEntityAdapter<FlinkLocation>();
export const projectRolesAdapter = createEntityAdapter<ProjectRole>();
export const craftToPlanLocations = createEntityAdapter<FlinkLocation>();
export const projectContactsAdapter = createEntityAdapter<Contact>();

export interface ProjectPartnerState extends EntityState<ProjectPartner> {}

export interface ProjectRoleState extends EntityState<ProjectRole> {}

export interface CraftToPlanLocationsState extends EntityState<FlinkLocation> {}

export interface ProjectContactsState extends EntityState<Contact> {}

export interface SettingsState {
    languages: string[];
    timezones: TimeZoneShortInfo[];

    projectPartners: ProjectPartnerState;
    craftToPlanLocations: CraftToPlanLocationsState;
    projectRoles: ProjectRoleState;
    projectRolesFilters: ProjectRoleFilters;

    projectCraftIds: number[];
    projectContacts: ProjectContactsState;
}

export const initialState: SettingsState = {
    languages: ['en-GB', 'de-DE'],
    timezones: [],
    projectPartners: projectPartnerAdapter.getInitialState(),
    craftToPlanLocations: locationCraftToPlanAdapter.getInitialState(),
    projectRoles: projectRolesAdapter.getInitialState(),
    projectRolesFilters: null,
    projectContacts: projectContactsAdapter.getInitialState(),
    projectCraftIds: [],
};

export function reducer(
    state = initialState,
    action: SettingsActions
): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.LOAD_LANGUAGES_SUCCESS:
            return { ...state, languages: action.payload.languages };

        case SettingsActionTypes.LOAD_TIMEZONES_SUCCESS:
            return { ...state, timezones: action.payload.timezones };

        case SettingsActionTypes.LOAD_PROJECT_CONTACTS_SUCCESS: {
            const {
                contacts,
                currentProjectId,
                requestProjectId,
            } = action.payload;
            const forCurrentPoject = currentProjectId === requestProjectId;
            if (!forCurrentPoject) {
                return { ...state };
            }
            return {
                ...state,
                projectContacts: projectContactsAdapter.setAll(
                    contacts,
                    state.projectContacts
                ),
            };
        }

        case SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_SUCCESS: {
            const { currentProjectId, requestProjectId } = action.payload;
            const forCurrentProject = currentProjectId === requestProjectId;
            if (!forCurrentProject) {
                return { ...state };
            }
            return {
                ...state,
                craftToPlanLocations: locationCraftToPlanAdapter.setAll(
                    action.payload.craftToPlanLocations,
                    state.craftToPlanLocations
                ),
            };
        }

        case SettingsActionTypes.LOAD_PROJECT_ROLES_SUCCESS: {
            const {
                projectRoles,
                currentProjectId,
                requestProjectId,
            } = action.payload;
            const forCurrentProject = currentProjectId === requestProjectId;
            if (!forCurrentProject) {
                return { ...state };
            }
            return {
                ...state,
                projectRoles: projectRolesAdapter.setAll(
                    projectRoles,
                    state.projectRoles
                ),
            };
        }

        case SettingsActionTypes.LOAD_PROJECT_CRAFTS_SUCCESS: {
            const {
                craftIds,
                currentProjectId,
                requestProjectId,
            } = action.payload;
            const forCurrentProject = currentProjectId === requestProjectId;
            if (!forCurrentProject) {
                return { ...state };
            }
            return {
                ...state,
                projectCraftIds: [...craftIds],
            };
        }

        case SettingsActionTypes.LOAD_PROJECT_PARTNERS_SUCCESS: {
            const {
                projectPartners,
                currentProjectId,
                requestProjectId,
            } = action.payload;
            const forCurrentProject = currentProjectId === requestProjectId;
            if (!forCurrentProject) {
                return { ...state };
            }

            return {
                ...state,
                projectPartners: projectPartnerAdapter.setAll(
                    projectPartners,
                    state.projectPartners
                ),
            };
        }

        case SettingsActionTypes.UPDATE_PROJECT_PARTNER_SUCCESS: {
            const changes = action.payload.projectPartner;
            const { id } = changes;
            const update = { id, changes };

            return {
                ...state,
                projectPartners: projectPartnerAdapter.updateOne(
                    update,
                    state.projectPartners
                ),
            };
        }

        case SettingsActionTypes.CREATE_PROJECT_PARTNER_SUCCESS:
            return {
                ...state,
                projectPartners: projectPartnerAdapter.addOne(
                    action.payload.projectPartner,
                    state.projectPartners
                ),
            };

        case SettingsActionTypes.DELETE_PROJECT_PARTNER_SUCCESS: {
            let changes = action.payload.projectPartner;
            changes = { ...changes, markedAsDelete: true };
            const { id } = changes;
            const update = { id, changes };

            return {
                ...state,
                projectPartners: projectPartnerAdapter.updateOne(
                    update,
                    state.projectPartners
                ),
            };
        }

        case SettingsActionTypes.SET_PROJECT_ROLE_FILTERS:
            return { ...state, projectRolesFilters: action.payload.filters };

        case SettingsActionTypes.CLEAN_PROJECT_ROLE_FILTERS:
            return {
                ...state,
                projectRolesFilters: null,
            };

        default:
            return state;
    }
}

export const {
    selectAll: selectAllProjectPartners,
} = projectPartnerAdapter.getSelectors();

export const projectRolesEntity = {
    selectAll: projectRolesAdapter.getSelectors().selectAll,
    selectEntities: projectRolesAdapter.getSelectors().selectEntities,
    selectIds: projectRolesAdapter.getSelectors().selectIds,
    selectTotal: projectRolesAdapter.getSelectors().selectTotal,
};

export const projectContactsEntity = {
    selectAll: projectContactsAdapter.getSelectors().selectAll,
    selectEntities: projectContactsAdapter.getSelectors().selectEntities,
    selectIds: projectContactsAdapter.getSelectors().selectIds,
    selectTotal: projectContactsAdapter.getSelectors().selectTotal,
};

export const craftToPlanEntity = locationCraftToPlanAdapter.getSelectors();
