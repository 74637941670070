/// <reference types="cordova-plugin-file" />

import { FileStorageError } from './file-storage.service';

export class CordovaFileStorageError extends FileStorageError {
    constructor(public message: string, public cause: FileError) {
        super(message, cause);
    }
}

export class SafeName {
    private _name: string;

    public get name(): string {
        return this._name;
    }

    private constructor(name: string) {
        this._name = name;
    }

    private static escapeSlash(id: string): string {
        return id.replace('/', '_');
    }

    public static create(attachmentId: string): SafeName {
        const escapedSlash = SafeName.escapeSlash(attachmentId);
        return new SafeName(escapedSlash);
    }
}

// small Promise wrapper around the cordova-plugin-file API

export function requestFileSystem(type: LocalFileSystem): Promise<FileSystem> {
    return new Promise<FileSystem>((resolve, reject) => {
        (window as any).requestFileSystem(
            type,
            0,
            (fs) => {
                resolve(fs);
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to get filesystem of type ${type}`,
                        error
                    )
                );
            }
        );
    });
}

/**
 * Create the given directory. If the directory already exists, this
 * function just returns the existing directory entry.
 *
 * @param fs The FileSystem to use, can be obtained from the Cordova File Plugin.
 * @param baseUrl The base directory, e.g. the persistent data directory of the device.
 * @param name The name of the directory to create.
 */
export function createDirectory(
    fs: FileSystem,
    baseUrl: string,
    name: SafeName
): Promise<FileSystemEntry> {
    return new Promise<FileSystemEntry>((resolve, reject) => {
        (window as any).resolveLocalFileSystemURL(
            baseUrl,
            (baseEntry: Entry) => {
                const path = baseEntry.fullPath + '/' + name.name;

                fs.root.getDirectory(
                    path,
                    {
                        create: true,
                        exclusive: false,
                    },
                    (entry) => {
                        resolve(entry);
                    },
                    (error) => {
                        reject(
                            new CordovaFileStorageError(
                                `failed to create directory ${path}`,
                                error
                            )
                        );
                    }
                );
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to resolve URL ${baseUrl}`,
                        error
                    )
                );
            }
        );
    });
}

function isDirectory(
    directory: FileSystemEntry
): directory is FileSystemDirectoryEntry {
    return directory?.isDirectory;
}

export function getFile(
    directory: FileSystemEntry,
    path: SafeName,
    options?: Flags
): Promise<FileSystemEntry> {
    return new Promise<FileSystemEntry>((resolve, reject) => {
        if (!isDirectory(directory)) {
            reject(
                new CordovaFileStorageError(
                    `failed to get file ${path.name} in ${directory?.fullPath}`,
                    new FileError(FileError.TYPE_MISMATCH_ERR)
                )
            );
            return;
        }

        directory.getFile(
            path.name,
            options,
            (fileEntry) => {
                resolve(fileEntry);
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to get file ${path.name} in ${directory.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}

/** A wrapper to centralize casting new https://developer.mozilla.org/en-US/docs/Web/API/File_and_Directory_Entries_API/Introduction API to the old one used by Cordova */
export function getOldFileEntry(file: FileSystemEntry): FileEntry {
    console.log('getOldFileEntry: casting', file?.fullPath);
    return (file as unknown) as FileEntry;
}
/** A wrapper to centralize casting new https://developer.mozilla.org/en-US/docs/Web/API/File_and_Directory_Entries_API/Introduction API to the old one used by Cordova */
export function getOldDirectoryEntry(file: FileSystemEntry): DirectoryEntry {
    console.log('getOldDirectoryEntry: casting', file?.fullPath);
    return (file as unknown) as DirectoryEntry;
}

export async function writeFile(
    directory: FileSystemEntry,
    path: SafeName,
    data: Blob
): Promise<void> {
    const child: FileSystemEntry = await getFile(directory, path, {
        create: true,
        exclusive: false,
    });

    return new Promise<void>((resolve, reject) => {
        getOldFileEntry(child).createWriter(
            (fileWriter) => {
                fileWriter.onwriteend = () => {
                    resolve();
                };
                const writingFailed = (error: any) => {
                    reject(
                        new CordovaFileStorageError(
                            `failed to write file ${path} in ${directory.fullPath}`,
                            error
                        )
                    );
                };

                fileWriter.onerror = writingFailed;
                fileWriter.onabort = writingFailed;

                fileWriter.write(data);
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to create writer for file ${path} in ${directory.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}

export async function readFile(
    directory: FileSystemEntry,
    path: SafeName,
    contentType: string
): Promise<Blob> {
    const fileEntry = await getFile(directory, path, {
        create: false,
    });

    return await new Promise<Blob>((resolve, reject) => {
        getOldFileEntry(fileEntry).file(
            (file) => {
                // note: even though the file parameter is typed
                //       as a File (which is a Blob) in cordova-plugin-file, it does
                //       not support all operations and also not creating
                //       an objectURL from it, thus manually use FileReader
                //       to convert to real Blob

                let reader = new FileReader();

                // pry proper FileReader from Angular's hands
                // Angular's Zone breaks FileReader in combination with Cordova's
                // File Plugin
                // see: https://github.com/ionic-team/capacitor/issues/1564
                if (reader['__zone_symbol__originalInstance']) {
                    reader = reader['__zone_symbol__originalInstance'];
                } else {
                    console.warn(
                        '__zone_symbol__originalInstance is not set, please check, that this function is really running outside of Angular'
                    );
                }

                const readingFailed = (error: any) => {
                    reject(
                        new CordovaFileStorageError(
                            `failed to turn file ${path} in ${directory.fullPath} into blob`,
                            error
                        )
                    );
                };

                reader.onerror = readingFailed;
                reader.onabort = readingFailed;
                reader.onloadend = () => {
                    const blob = new Blob([reader.result], {
                        type: contentType,
                    });

                    resolve(blob);
                };

                reader.readAsArrayBuffer(file);
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to read file ${path} in ${directory.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}

export function removeRecursively(directory: FileSystemEntry): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        getOldDirectoryEntry(directory).removeRecursively(
            () => {
                resolve();
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to remove directory ${directory.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}

export function removeFile(entry: FileSystemEntry): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        getOldFileEntry(entry).remove(
            () => {
                resolve();
            },
            (error) => {
                reject(
                    new CordovaFileStorageError(
                        `failed to remove ${entry.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}

export function notFoundError(ex: Error): boolean {
    return (
        ex instanceof CordovaFileStorageError &&
        ex.cause?.code === FileError.NOT_FOUND_ERR
    );
}

export interface MoveInDirectory {
    directory: FileSystemEntry;
    from: SafeName;
    to: SafeName;
    skipExisting?: boolean;
    skipMissing?: boolean;
}

export async function moveInDirectory({
    directory,
    from,
    to,
    skipExisting = false,
    skipMissing = false,
}: MoveInDirectory): Promise<void> {
    try {
        await getFile(directory, to, {
            create: false,
        });

        if (skipExisting) {
            // target file already exists => do not overwrite!
            return;
        }
    } catch (ex) {
        const otherError = !notFoundError(ex);

        if (otherError) {
            throw ex;
        }
    }

    // 'from' is fetched after 'to' to prevent
    // that the file was moved during the 'to'-await
    let fromFile: FileSystemEntry;

    try {
        fromFile = await getFile(directory, from, {
            create: false,
        });
    } catch (ex) {
        if (notFoundError(ex) && skipMissing) {
            return;
        }

        throw ex;
    }

    return new Promise<void>((resolve, reject) => {
        getOldFileEntry(fromFile).moveTo(
            getOldDirectoryEntry(directory),
            to.name,
            (_entry) => {
                resolve();
            },
            (error) => {
                if (error.code === FileError.NOT_FOUND_ERR && skipMissing) {
                    resolve();
                }

                reject(
                    new CordovaFileStorageError(
                        `failed to move file ${from.name} to ${to.name} in ${directory.fullPath}`,
                        error
                    )
                );
            }
        );
    });
}
