import { Injectable } from '@angular/core';

import { Mutex } from 'async-mutex';
import { RxDocument, MangoQuerySelector } from 'rxdb';

import { SyncCommand } from 'app/main/sync/models/sync-command';
import { DatabaseService } from '../database.service';
import { CommandDocType } from './command.document';

import {
    UpdateCommandError,
    UpdateCommandErrorCause,
} from 'app/main/sync/models/update-command-error';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import { SetIssueIdOfPlanCommandsError } from '../../store/issues/set-issue-id.error';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { EntityType } from 'app/main/sync/models/entity-type';

@Injectable({
    providedIn: 'root',
})
export class CommandDbService<T> {
    private updateExistingMutex = new Mutex();

    constructor(private dbService: DatabaseService) {}

    static itemToSchema<T>(command: SyncCommand<T>): CommandDocType {
        return {
            action: command.action,
            entityType: command.entityType,
            entityId: command.entityId,
            projectId: command.projectId,
            changes: command.changes,
            issueId: command.issueId,
            timestampCreated: command.timestampCreated,
        };
    }

    async insert(command: SyncCommand<T>): Promise<RxDocument<CommandDocType>> {
        return await this.dbService.db.commands.insert(
            CommandDbService.itemToSchema(command)
        );
    }

    async getCommandsByEntityType(
        entityType: EntityType
    ): Promise<SyncCommand<T>[]> {
        const docs = await this.dbService.db.commands
            .find()
            .where('entityType')
            .eq(entityType)
            .sort('timestampCreated')
            .exec();
        const data = (docs.map((d) =>
            d.toJSON()
        ) as unknown) as SyncCommand<T>[];

        const withDatabaseId = data.map((command) => {
            return {
                ...command,
                // the DB returns an additional _id attribute containing the database id
                databaseId: (command as any)?._id,
            };
        });

        return withDatabaseId;
    }

    async delete(query: Partial<T>): Promise<boolean> {
        // secure against concurrent access, otherwise there is a race
        // condition between getting and deleting the documents:
        // - A: fetch docs
        // - B: fetch docs
        // - A: delete fetched docs
        // - B: delete fetched docs => docs were already deleted => pouchDB conflict
        const release = await this.updateExistingMutex.acquire();

        try {
            const docs = await this.dbService.db.commands
                .find({
                    selector: query,
                })
                .exec();

            if (!docs || docs.length === 0) {
                return false;
            }

            const removes = [];
            for (const doc of docs) {
                removes.push(doc.remove());
            }

            await Promise.all(removes);
            return true;
        } catch (ex) {
            console.error(
                'failed to delete command (with query:',
                query,
                ') error:',
                ex
            );
            throw ex;
        } finally {
            release();
        }
    }

    async updateByPrimaryId(
        primaryId: string,
        commandUpdate: SyncCommand<T>,
        options?: {
            ignoreMissing?: boolean;
        }
    ): Promise<void> {
        if (!primaryId) {
            console.error('primaryId missing');
            return;
        }
        const release = await this.updateExistingMutex.acquire();

        try {
            const query = {
                selector: {
                    _id: primaryId,
                },
            };
            const doc = await this.dbService.db.commands.findOne(query).exec();

            if (!doc) {
                if (!options?.ignoreMissing) {
                    throw new UpdateCommandError(
                        query,
                        commandUpdate,
                        UpdateCommandErrorCause.DOCUMENT_MISSING
                    );
                } else {
                    // nothing to update
                    return;
                }
            }

            const schemaDoc = CommandDbService.itemToSchema(commandUpdate);
            await doc.update({
                $set: schemaDoc,
            });
        } catch (ex) {
            console.error(
                'failed to update command (with id:',
                primaryId,
                ') (with update:',
                commandUpdate,
                ') error:',
                ex
            );
            throw ex;
        } finally {
            release();
        }
    }

    private async setIssueIdOfUnassignedPlanCommand(
        issueId: string,
        planCommand: PlanCommand
    ): Promise<void> {
        const commandDocs = await this.dbService.db.commands
            .find({
                selector: {
                    entityId: planCommand.entityId,
                    entityType: 'plan',
                },
            })
            .exec();

        const noPlanCommandInDB = commandDocs.length === 0;
        if (noPlanCommandInDB) {
            throw SetIssueIdOfPlanCommandsError.withMessage(
                `no plan command with id '${planCommand.entityId}' in DB`
            );
        }

        const multiplePlanCommandsInDB = commandDocs.length > 1;
        if (multiplePlanCommandsInDB) {
            throw SetIssueIdOfPlanCommandsError.withMessage(
                `multiple plan commands with id '${planCommand.entityId}' in DB`
            );
        }

        const commandDoc = commandDocs[0];

        // this check can not be done on the plan commands passed
        // to this function because the reducer already ran and assigned
        // an id
        if (commandDoc.issueId) {
            if (commandDoc.issueId === issueId) {
                console.warn(
                    'issue id of command',
                    planCommand,
                    'is already in DB'
                );
                return;
            } else {
                throw SetIssueIdOfPlanCommandsError.withMessage(
                    `plan command with id '${planCommand.entityId}' should have issueId '${issueId}', but has '${commandDoc.issueId}'`
                );
            }
        }

        await commandDoc.update({
            $set: {
                issueId: issueId,
            },
        });
    }

    private throwIfInvalidPlanCommandEntityId(planCommand: PlanCommand): void {
        const entityIdMissing = !planCommand.entityId;
        if (entityIdMissing) {
            throw SetIssueIdOfPlanCommandsError.withMessage(
                `plan command '${JSON.stringify(
                    planCommand
                )}' is missing an entityId`
            );
        }

        const nonLocalId = !AcceptUtils.isLocalGuid(planCommand.entityId);
        if (nonLocalId) {
            // a plan update with a non local id can only be on an already
            // existing and uploaded issue, this method should not run in this case
            throw SetIssueIdOfPlanCommandsError.withMessage(
                `non-local entityId of plan command: ${planCommand.entityId}`
            );
        }
    }

    async setIssueIdOfPlanCommandsWithoutOne(
        issueId: string,
        planCommands: PlanCommand[]
    ): Promise<void> {
        if (!issueId) {
            throw SetIssueIdOfPlanCommandsError.withMessage(
                'issueId is undefined'
            );
        }

        const nothingToUpdate = planCommands.length === 0;
        if (nothingToUpdate) {
            return;
        }

        const release = await this.updateExistingMutex.acquire();
        try {
            const errors: Error[] = [];

            for (const planCommand of planCommands) {
                const unrelatedPlanCommand = planCommand.issueId !== issueId;
                if (unrelatedPlanCommand) {
                    continue;
                }

                // The plan commands are identified using their entityId,
                // which is generated upon creation of a command and temporarily
                // unique. Note: a non-local entityId is not unique anymore, thus
                // not supported by this method.

                try {
                    this.throwIfInvalidPlanCommandEntityId(planCommand);
                    await this.setIssueIdOfUnassignedPlanCommand(
                        issueId,
                        planCommand
                    );
                } catch (error) {
                    errors.push(
                        SetIssueIdOfPlanCommandsError.withCause(
                            `failed to set issueId '${issueId}' of plan command '${JSON.stringify(
                                planCommand
                            )}'`,
                            error
                        )
                    );
                }
            }

            if (errors.length > 0) {
                throw SetIssueIdOfPlanCommandsError.withCauses(
                    `failed to set issueId '${issueId}' of some plan commands`,
                    errors
                );
            }
        } finally {
            release();
        }
    }
}
