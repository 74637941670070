<asc-auto-selector
    #component
    [config]="filterItem.autoSelectorConfig"
    [items]="filterItem.listObservable$ | async"
    [required]="filterItem.required"
    [fallbackItems]="filterItem.fallbackItems$ | async"
    titleProperty="title"
    [(ngModel)]="filterItem.value"
    [label]="filterItem.labelTranslationIdentifier | translate"
></asc-auto-selector>
