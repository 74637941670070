import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class TooltipUtilService {


  private _matTooltipDisabled: boolean = true;
  /** Hide toolips if the CDK might overlay fixec buttons (on small screens) */
  public get matTooltipDisabled(): boolean {
    return this._matTooltipDisabled;
  }

  constructor(media$: MediaObserver) {
    media$.asObservable()
      .subscribe((changes: MediaChange[]) => {
        this._matTooltipDisabled = changes.some( (change) => change.mqAlias === 'xs' );
      });

  }

}
