import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

import { FormLetterService } from 'app/shared/services/form-letter.service';

import { CommonTemplate, isFormLetterTemplateOrPredefined } from '../common-template';

@Component({
    selector: 'acc-print-dialog-final-page',
    templateUrl: './print-dialog-final-page.component.html',
    styleUrls: ['./print-dialog-final-page.component.scss'],
})
export class PrintDialogFinalPageComponent implements OnInit {
    printForm: UntypedFormGroup;

    @Input()
    selectedLetter: CommonTemplate;

    @Input()
    showSaveTemplateSuccess: boolean;

    constructor(
        private controlContainer: ControlContainer,
        private formLetterService: FormLetterService
    ) {}

    ngOnInit(): void {
        // Set form property to the parent control (FormGroup) that was passed to the child,
        // so the view can data bind to it
        this.printForm = this.controlContainer.control as UntypedFormGroup;
    }

    // save input values as default values in new custom template
    saveAsCustomTemplate(): void {
        if (!isFormLetterTemplateOrPredefined(this.selectedLetter)) {
            throw new Error(`tried to save letter ${this.selectedLetter.id} as new custom template`);
        }

        this.formLetterService.saveAsCustomTemplate({
            sourceTemplate: this.selectedLetter,
            templateTitle: this.printForm.get('customTemplateTitle').value,
            // The custom attributes are at the top level of the form, just
            // use it here.
            newCustomAttributeValues: this.printForm.value
        });
    }
}
