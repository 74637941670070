import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { ErrorUtils } from 'app/core/utils/error-util';

import { AuthState } from '../auth/auth.reducer';
import { getIsLoggedIn } from '../auth/auth.selectors';
import {
    CraftsActionTypes,
    LoadCraftsError,
    LoadCraftsSuccess,
} from './crafts.actions';
import { CraftsService } from './crafts.service';

@Injectable()
export class CraftsEffects {
    @Effect()
    loadCrafts$ = this.actions$.pipe(
        ofType(CraftsActionTypes.LOAD_CRAFTS_REQUESTED),
        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([_action, isLoggedIn]) => isLoggedIn),
        mergeMap(() => {
            const customerCrafts$ = this.craftsService.getCustomerCrafts();

            return forkJoin([
                this.craftsService.getCraftsLocal(),
                customerCrafts$,
            ]).pipe(
                map(([localCrafts, customerCrafts]) => {
                    return new LoadCraftsSuccess({
                        crafts: [...localCrafts, ...customerCrafts],
                    });
                }),
                catchError((error) => of(new LoadCraftsError({ error })))
            );
        })
    );

    @Effect()
    errorLoadCrafts$ = this.actions$.pipe(
        ofType<LoadCraftsError>(CraftsActionTypes.LOAD_CRAFTS_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'CRAFTS.LOAD');
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private craftsService: CraftsService,
        private errorUtils: ErrorUtils,
        private store: Store<AuthState>
    ) {}
}
