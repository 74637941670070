<div class="password-reset-container">
    <mat-card class="password-reset-card">
        <mat-card-content>
            <div style="display: flex; flex-direction: row">
                <div class="construction-worker-img-container">
                    <img
                        class="construction-worker-img"
                        mat-card-image
                        src="assets/images/construction-worker.jpg"
                        alt="construction worker"
                    />
                </div>
                <div
                    *ngIf="resetPassword?.email; else noEmail"
                    class="card-separator"
                >
                    <div class="card-separator-header">
                        <acc-language-switcher
                            [language]="language$ | async"
                            [labels]="true"
                        ></acc-language-switcher>
                    </div>
                    <div class="reset-password">
                        <img
                            class="make-it-easy-image"
                            alt="uStrich2Go logo"
                            [src]="'/assets/images/custom/u-strich-logo.png'"
                        />
                        <h2 class="reset-password-text">
                            {{ 'RESET_PASSWORD.RESET_PASSWORD' | translate }}
                        </h2>
                        <p class="reset-password-text-detail">
                            {{
                                'RESET_PASSWORD.RESET_PASSWORD_CONFIRM'
                                    | translate
                            }}
                        </p>

                        <form [formGroup]="passwordForm" class="password-form">
                            <div fxLayout="column">
                                <mat-form-field>
                                    <mat-label>{{
                                        'RESET_PASSWORD.NEW_PASSWORD'
                                            | translate
                                    }}</mat-label>
                                    <input
                                        formControlName="newPassword"
                                        matInput
                                        type="password"
                                    />
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{
                                        'RESET_PASSWORD.NEW_PASSWORD'
                                            | translate
                                    }}</mat-label>
                                    <input
                                        formControlName="confirmPassword"
                                        matInput
                                        type="password"
                                    />
                                </mat-form-field>
                            </div>
                        </form>
                        <div class="row-button-container">
                            <button
                                mat-flat-button
                                color="accent"
                                (click)="confirmPasswordReset()"
                            >
                                {{
                                    'RESET_PASSWORD.RESET_PASSWORD_BUTTON'
                                        | translate
                                }}
                            </button>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="company"></ng-container>
                </div>
                <ng-template #noEmail>
                    <div class="card-separator">
                        <div class="card-separator-header">
                            <acc-language-switcher
                                [language]="language$ | async"
                                [labels]="true"
                            ></acc-language-switcher>
                        </div>
                        <div class="reset-password " >
                            <div
                            fXlayout fxLayoutAlign="center center"
                            class="w-100-p h-100"
                            >

                                <img
                                class="make-it-easy-image"
                                alt="uStrich2Go logo"
                                [src]="'/assets/images/custom/u-strich-logo.png'"
                                />
                            </div>
                            <h2 class="reset-password-text">
                                {{
                                    'RESET_PASSWORD.RESET_PASSWORD_TEXT'
                                        | translate
                                }}
                            </h2>
                            <form [formGroup]="emailForm" class="password-form">
                                <div fxLayout="column">
                                    <mat-form-field>
                                        <mat-label>E-Mail</mat-label>
                                        <input
                                            formControlName="email"
                                            matInput
                                            type="email"
                                        />
                                    </mat-form-field>
                                </div>
                            </form>
                            <div class="row-button-container">
                                <button
                                    mat-flat-button
                                    color="accent"
                                    (click)="sendEmail()"
                                >
                                    {{ 'RESET_PASSWORD.SEND_MAIL' | translate }}
                                </button>
                            </div>
                        </div>
                        <ng-container
                            *ngTemplateOutlet="company"
                        ></ng-container>
                    </div>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #company>
    <div class="card-separator-footer">
        <div fxLayout="column">
            <img
                class="logo"
                src="assets/images/logos/untermstrich-markenlogo-mit-claim-signet.png"
                alt="untermStrich software logo"
            />
            <div fxLayout="row">
                <p>&copy; untermStrich software GmbH -&nbsp;</p>
                <a class="website-link" href="https://www.untermstrich.com"
                    >www.untermstrich.com</a
                >
            </div>
        </div>
    </div>
</ng-template>
