import { CustomAttribute } from 'app/core/rest-api';

export interface FilterCustomAttribute extends CustomAttribute {
    from?: string;
    to?: string;
}

export function fromAvailable(customAttribute: FilterCustomAttribute): boolean {
    return customAttribute.from && customAttribute.from !== '';
}

export function toAvailable(customAttribute: FilterCustomAttribute): boolean {
    return customAttribute.to && customAttribute.to !== '';
}

export function valueAvailable(
    customAttribute: FilterCustomAttribute
): boolean {
    return customAttribute.value && customAttribute.value !== '';
}
