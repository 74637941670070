import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    Update,
} from '@ngrx/entity';
import { Project } from 'app/core/rest-api';
import { ProjectsActions, ProjectsActionTypes } from './projects.actions';
import { MarkedPlanInfo } from 'app/core/rest-api/model/markedPlanInfo';

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export interface ProjectsState extends EntityState<Project> {
    selected: string[];
    loading: boolean;
    loaded: boolean;
    withArchived: boolean;
    markedPlansInfo: MarkedPlanInfo[]
}

export const initialState: ProjectsState = adapter.getInitialState({
    selected: [],
    loading: false,
    loaded: false,
    withArchived: false,
    markedPlansInfo: []
});

export function reducer(
    state = initialState,
    action: ProjectsActions
): ProjectsState {
    switch (action.type) {
        case ProjectsActionTypes.LOAD_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ProjectsActionTypes.LOAD_PROJECTS_SUCCESS:
            return adapter.setAll(action.payload.projects, {
                ...state,
                loading: false,
                loaded: true,
            });

        case ProjectsActionTypes.CREATE_PROJECT_SUCCESS:
            return adapter.addOne(action.payload.project, { ...state });

        case ProjectsActionTypes.LOAD_MARKED_PLANS_INFO_SUCCESS:
            return {
                ...state,
                markedPlansInfo: action.payload.markedPlansInfo
            };

        case ProjectsActionTypes.UPDATE_PROJECT_SUCCESS:
            return adapter.updateOne(
                {
                    id: action.payload.project.id,
                    changes: action.payload.project,
                },
                state
            );

        case ProjectsActionTypes.UPDATE_PROJECTS_SUCCESS:
            const projects = action.payload.projects;
            const updates: Update<Project>[] = [];

            projects.forEach((project) => {
                updates.push({ id: project.id, changes: project });
            });

            return adapter.updateMany(updates, state);

        case ProjectsActionTypes.TOGGLE_SELECT_PROJECT:
            if (state.selected.includes(action.payload.projectId)) {
                return {
                    ...state,
                    selected: state.selected.filter(
                        (projectId) => projectId !== action.payload.projectId
                    ),
                };
            } else {
                return {
                    ...state,
                    selected: [...state.selected, action.payload.projectId],
                };
            }

        case ProjectsActionTypes.TOGGLE_SELECT_ALL_PROJECTS:
            if (state.withArchived) {
                return {
                    ...state,
                    selected:
                        state.selected.length === state.ids.length
                            ? []
                            : (state.ids as string[]),
                };
            } else {
                const activeProjects = (state.ids as string[]).filter(
                    (projectId) => {
                        return !state.entities[projectId].markedAsDelete;
                    }
                );
                return {
                    ...state,
                    selected:
                        state.selected.length === activeProjects.length
                            ? []
                            : activeProjects,
                };
            }

        case ProjectsActionTypes.DESELECT_ALL_PROJECTS:
            return {
                ...state,
                selected: [],
            };

        case ProjectsActionTypes.TOGGLE_ARCHIVED:
            return {
                ...state,
                withArchived: state.withArchived ? false : true,
            };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
