import { Action } from '@ngrx/store';
import { Contact, ContactCSVResponse } from 'app/core/rest-api';
import { ContactDocType } from 'app/db/contacts/contact.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';

export enum ContactsActionTypes {
    LOAD_CONTACTS_REQUESTED = '[Contacts] Load Contacts Requested',
    LOAD_CONTACTS_SUCCESS = '[API] Load Contacts Success',
    LOAD_CONTACTS_ERROR = '[API] Load Contacts Error',

    CREATE_CONTACT_REQUEST = '[Contacts] Create Contact Request',
    CREATE_CONTACT_SUCCESS = '[API] Create Contact Success',
    CREATE_CONTACT_ERROR = '[API] Create Contact Error',

    // CONTACTS
    UPSERT_CONTACTS_START = '[APP] Upsert Contacts Start',
    UPSERT_CONTACTS_FINISH = '[DB] Upsert Contacts Finish',

    UPDATE_CONTACT_REQUEST = '[Contacts] Update Contact Request',
    UPDATE_CONTACT_SUCCESS = '[API] Update Contact Success',
    UPDATE_CONTACT_ERROR = '[API] Update Contact Error',

    DELETE_CONTACT_REQUEST = '[Contacts] Delete Contact Request',
    DELETE_CONTACT_SUCCESS = '[API] Delete Contact Success',
    DELETE_CONTACT_ERROR = '[API] Delete Contact Error',

    // Actions for contact import, see action classes for documentation.
    IMPORT_CONTACTS_FROM_CSV_REQUEST = '[Address Book] Import Contacts From CSV Request',
    IMPORT_CONTACTS_FROM_CSV_SUCCESS = '[Address Book] Import Contacts From CSV Success',
    IMPORT_CONTACTS_FROM_CSV_ERROR = '[Address Book] Import Contacts From CSV Error',
}

export class UpsertContactsStart implements Action {
    readonly type = ContactsActionTypes.UPSERT_CONTACTS_START;
    constructor(public payload: { contacts: Contact[] }) {}
}

export class UpsertContactsFinish implements Action {
    readonly type = ContactsActionTypes.UPSERT_CONTACTS_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<ContactDocType> }
    ) {}
}

export class LoadContactsRequested implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS_REQUESTED;
}

export class LoadContactsSuccess implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS_SUCCESS;
    constructor(public payload: { contacts: Contact[]; fromDb: boolean }) {}
}

export class LoadContactsError implements Action {
    readonly type = ContactsActionTypes.LOAD_CONTACTS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateContactRequest implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_REQUEST;

    constructor(public payload: { contact: Contact }) {}
}

export class CreateContactSuccess implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_SUCCESS;

    constructor(public payload: { contact: Contact }) {}
}

export class CreateContactError implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_ERROR;
    constructor(public payload: { error: any }) {}
}

export class UpdateContactRequest implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_REQUEST;

    constructor(public payload: { contact: Partial<Contact> }) {}
}

export class UpdateContactSuccess implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_SUCCESS;

    constructor(public payload: { contact: Contact }) {}
}

export class UpdateContactError implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteContactRequest implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_REQUEST;

    constructor(public payload: { contactId: string }) {}
}

export class DeleteContactSuccess implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_SUCCESS;

    constructor(public payload: { contactId: string }) {}
}

export class DeleteContactError implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_ERROR;
    constructor(public payload: { error: any }) {}
}

/**
 * Dispatch this action to start the import process of contacts for
 * a specific company. The `actionId` in the payload is used by the
 * ImportContactService to properly find out which pair of import
 * request and import success/error actions belong together.
 *
 * Prefer to not use these actions directly and use the
 * `ImportContactService` from
 * `app/main/admin/address-book/shared/import-csv-dialog/import-contact.service.ts`.
 */
export class ImportContactsFromCsvRequest implements Action {
    readonly type = ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_REQUEST;

    constructor(
        public payload: {
            actionId: number;
            companyId: string;
            csvFile: File;
        }
    ) {}
}

/**
 * This action is dispatched if {@link ImportContactsFromCsvRequest} was
 * successful. Please note, that only part of the file may have been imported
 * successfully. The payload of this action contains details about it.
 *
 * Prefer to not use these actions directly and use the
 * `ImportContactService` from
 * `app/main/admin/address-book/shared/import-csv-dialog/import-contact.service.ts`.
 */
export class ImportContactsFromCsvSuccess implements Action {
    readonly type = ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_SUCCESS;

    constructor(
        public payload: {
            actionId: number;
            importResult: ContactCSVResponse;
        }
    ) {}
}

/**
 * This action is dispatched if {@link ImportContactsFromCsvRequest} was
 * successful. Please note, that this action is only dispatched in case of
 * fatal errors during the import.
 *
 * Prefer to not use these actions directly and use the
 * `ImportContactService` from
 * `app/main/admin/address-book/shared/import-csv-dialog/import-contact.service.ts`.
 */
export class ImportContactsFromCsvError implements Action {
    readonly type = ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_ERROR;

    constructor(
        public payload: {
            actionId: number;
            error: any;
        }
    ) {}
}

export type ContactsActions =
    | LoadContactsRequested
    | LoadContactsSuccess
    | LoadContactsError
    | CreateContactRequest
    | CreateContactSuccess
    | CreateContactError
    | UpdateContactRequest
    | UpdateContactSuccess
    | UpdateContactError
    | DeleteContactRequest
    | DeleteContactSuccess
    | DeleteContactError
    | UpsertContactsStart
    | UpsertContactsFinish
    | ImportContactsFromCsvRequest
    | ImportContactsFromCsvSuccess
    | ImportContactsFromCsvError;
