import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthActionTypes } from 'app/store/auth/auth.actions';
import * as coreReducer from './core.reducer';

export interface CoreState {
    router: RouterReducerState;
    core: coreReducer.CoreState;
}

export const reducers: ActionReducerMap<CoreState> = {
    router: routerReducer,
    core: coreReducer.reducer
};

export function logout(reducer: any): any {
    // tslint:disable-next-line: only-arrow-functions
    return function(state: CoreState, action: Action): any {
        return reducer(
            action.type === AuthActionTypes.Logout ? undefined : state,
            action
        );
    };
}

// for debug purposes
export function logAction(reducer: any): any {
    return (state, action) => {
        console.log('action:', action.type, '\n  payload:', action.payload);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<CoreState>[] = [logout];
