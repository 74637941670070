import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { BoilerPlate } from 'app/core/rest-api';
import { BoilerplateDocType } from 'app/db/boilerplates/boilerplate.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';

export enum BoilerplatesActionTypes {
    LOAD_BOILERPLATES_REQUESTED = '[Boilerplates] Load Boilerplates Requested',
    LOAD_BOILERPLATES_SUCCESS = '[API] Load Boilerplates Success',
    LOAD_BOILERPLATES_ERROR = '[API] Load Boilerplates Error',

    LOAD_LOCAL_BOILERPLATES_REQUESTED = '[Boilerplates] Load Local Boilerplates Requested',
    LOAD_LOCAL_BOILERPLATES_SUCCESS = '[API] Load Local Boilerplates Success',
    LOAD_LOCAL_BOILERPLATES_ERROR = '[API] Load Local Boilerplates Error',

    // BOILERPLATES
    UPSERT_BOILERPLATES_START = '[APP] Upsert Boilerplates Request',
    UPSERT_BOILERPLATES_FINISH = '[DB] Upsert Boilerplates Success',

    IMPORT_BOILERPLATE_CSV_REQUEST = '[Settings] Import Boilerplate CSV Request',
    IMPORT_BOILERPLATE_CSV_SUCCESS = '[Settings] Import Boilerplate CSV Success',
    IMPORT_BOILERPLATE_CSV_ERROR = '[Settings] Import Boilerplate CSV Error',

    CREATE_BOILERPLATE_REQUEST = '[Boilerplates] Create Boilerplate Requested',
    CREATE_BOILERPLATE_SUCCESS = '[Boilerplates] Create Boilerplate Success',
    CREATE_BOILERPLATE_ERROR = '[Boilerplates] Create Boilerplate Error',

    UPDATE_BOILERPLATE_REQUEST = '[Boilerplates] Update Boilerplate Requested',
    UPDATE_BOILERPLATE_SUCCESS = '[Boilerplates] Update Boilerplate Success',
    UPDATE_BOILERPLATE_ERROR = '[Boilerplates] Update Boilerplate Error',

    DELETE_BOILERPLATE_REQUEST = '[Boilerplates] Delete Boilerplate Requested',
    DELETE_BOILERPLATE_SUCCESS = '[Boilerplates] Delete Boilerplate Success',
    DELETE_BOILERPLATE_ERROR = '[Boilerplates] Delete Boilerplate Error',

    ADD_SELECTED_CRAFT = '[Boilerplates] Add Selected Craft',
    REMOVE_SELECTED_CRAFT = '[Boilerplates] Remove Selected Craft',

    ADD_SELECTED_BOILERPLATE = '[Boilerplates] Add Selected Boilerplate',
    REMOVE_SELECTED_BOILERLATE = '[Boilerplates] Remove Selected Boilerplate',
}

export class UpsertBoilerplatesStart implements Action {
    readonly type = BoilerplatesActionTypes.UPSERT_BOILERPLATES_START;
    constructor(public payload: { boilerplates: BoilerPlate[] }) {}
}

export class UpsertBoilerplatesFinish implements Action {
    readonly type = BoilerplatesActionTypes.UPSERT_BOILERPLATES_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<BoilerplateDocType> }
    ) {}
}

export class LoadBoilerplatesRequested implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_BOILERPLATES_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadBoilerplatesSuccess implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_BOILERPLATES_SUCCESS;
    constructor(
        public payload: {
            fromDb: boolean;
            boilerplates: BoilerPlate[];
            requestProjectId: string;
            currentProjectId: string;
        }
    ) {}
}

export class LoadBoilerplatesError implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_BOILERPLATES_ERROR;
    constructor(public payload: { error: any }) {}
}

export class LoadLocalBoilerplatesError implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_LOCAL_BOILERPLATES_ERROR;
    constructor(public payload: { error: any }) {}
}
export class LoadLocalBoilerplatesRequested implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_LOCAL_BOILERPLATES_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadLocalBoilerplatesSuccess implements Action {
    readonly type = BoilerplatesActionTypes.LOAD_LOCAL_BOILERPLATES_SUCCESS;
    constructor(
        public payload: {
            fromDb: boolean;
            boilerplates: BoilerPlate[];
            requestProjectId: string;
            currentProjectId: string;
        }
    ) {}
}

export class CreateBoilerplateRequested implements Action {
    readonly type = BoilerplatesActionTypes.CREATE_BOILERPLATE_REQUEST;
    constructor(
        public payload: { projectId: string; parentId: string; title: string }
    ) {}
}

export class CreateBoilerplateSuccess implements Action {
    readonly type = BoilerplatesActionTypes.CREATE_BOILERPLATE_SUCCESS;
    constructor(public payload: { boilerplate: BoilerPlate }) {}
}

export class CreateBoilerplateError implements Action {
    readonly type = BoilerplatesActionTypes.CREATE_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class UpdateBoilerplateRequested implements Action {
    readonly type = BoilerplatesActionTypes.UPDATE_BOILERPLATE_REQUEST;
    constructor(
        public payload: {
            projectId: string;
            update: Update<BoilerPlate>;
        }
    ) {}
}

export class UpdateBoilerplateSuccess implements Action {
    readonly type = BoilerplatesActionTypes.UPDATE_BOILERPLATE_SUCCESS;
    constructor(public payload: { update: Update<BoilerPlate> }) {}
}

export class UpdateBoilerplateError implements Action {
    readonly type = BoilerplatesActionTypes.UPDATE_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class DeleteBoilerplateRequested implements Action {
    readonly type = BoilerplatesActionTypes.DELETE_BOILERPLATE_REQUEST;
    constructor(
        public payload: { projectId: string; boilerplate: BoilerPlate }
    ) {}
}

export class DeleteBoilerplateSuccess implements Action {
    readonly type = BoilerplatesActionTypes.DELETE_BOILERPLATE_SUCCESS;
    constructor(
        public payload?: { projectId: string; boilerplate: BoilerPlate }
    ) {}
}

export class DeleteBoilerplateError implements Action {
    readonly type = BoilerplatesActionTypes.DELETE_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}
export class AddSelectedCraft implements Action {
    readonly type = BoilerplatesActionTypes.ADD_SELECTED_CRAFT;
    constructor(
        public payload: {
            boilerplate: Partial<BoilerPlate>;
        }
    ) {}
}

export class RemoveSelectedCraft implements Action {
    readonly type = BoilerplatesActionTypes.REMOVE_SELECTED_CRAFT;
    constructor() {}
}

export class AddSelectedBoilerplate implements Action {
    readonly type = BoilerplatesActionTypes.ADD_SELECTED_BOILERPLATE;
    constructor(
        public payload: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export class RemoveSelectedBoilerplate implements Action {
    readonly type = BoilerplatesActionTypes.REMOVE_SELECTED_BOILERLATE;
    constructor(
        public payload: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export type BoilerplatesActions =
    | LoadBoilerplatesRequested
    | LoadBoilerplatesSuccess
    | LoadBoilerplatesError
    | CreateBoilerplateRequested
    | CreateBoilerplateSuccess
    | CreateBoilerplateError
    | UpdateBoilerplateRequested
    | UpdateBoilerplateSuccess
    | UpdateBoilerplateError
    | UpsertBoilerplatesStart
    | UpsertBoilerplatesFinish
    | DeleteBoilerplateRequested
    | DeleteBoilerplateSuccess
    | DeleteBoilerplateError
    | AddSelectedCraft
    | RemoveSelectedCraft
    | AddSelectedBoilerplate
    | RemoveSelectedBoilerplate;
