import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FilterByNumber } from './filter-by-number.model';

@Component({
    selector: 'acc-filter-by-number',
    templateUrl: './filter-by-number.component.html',
    styleUrls: ['./filter-by-number.component.scss']
})
export class FilterByNumberComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() filterItem: FilterByNumber;

    updateValue() {
        this.applyMaxValue();
        this.applyMinValue();
    }

    applyMaxValue() {
        if (
            this.filterItem.maxValue &&
            this.filterItem.value > this.filterItem.maxValue
        ) {
            this.filterItem.value = this.filterItem.maxValue;
        }
    }

    applyMinValue() {
        if (
            (this.filterItem.minValue || this.filterItem.minValue === 0) &&
            this.filterItem.value < this.filterItem.minValue
        ) {
            this.filterItem.value = this.filterItem.minValue;
        }
    }
}
