import { User } from 'app/core/auth/models/user';
import { AuthActions, AuthActionTypes } from './auth.actions';

export interface AuthState {
    isLoggedIn: boolean;
    currentUser: User;
}

export const initialState: AuthState = {
    isLoggedIn: false,
    currentUser: undefined
};

export function reducer(state = initialState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.LoginByToken:
        case AuthActionTypes.LoginSuccess:
            const currentUser = new User(action.payload.token);

            return {
                ...state,
                isLoggedIn: true,
                currentUser
            };

        case AuthActionTypes.Logout:
            return {
                isLoggedIn: false,
                currentUser: null
            };

        default:
            return state;
    }
}
