import { Action } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { ProjectDocType } from 'app/db/projects/project.document';

export enum ProjectTemplatesActionTypes {
    LOAD_PROJECT_TEMPLATES_REQUEST = '[Project Templates] Load Project Templates Request',
    LOAD_PROJECT_TEMPLATES_SUCCESS = '[API] Load Project Templates Success',
    LOAD_PROJECT_TEMPLATES_ERROR = '[API] Load Project Templates Error',

    CREATE_PROJECT_TEMPLATE_REQUEST = '[Project Templates] Create Project Template Request',
    CREATE_PROJECT_TEMPLATE_SUCCESS = '[Project Templates] Create Project Template Success',
    CREATE_PROJECT_TEMPLATE_ERROR = '[Project Templates] Create Project Template Error',

    UPDATE_PROJECT_TEMPLATE_REQUEST = '[Project Templates] Update Project Template Request',
    UPDATE_PROJECT_TEMPLATE_SUCCESS = '[Project Templates] Update Project Template Success',
    UPDATE_PROJECT_TEMPLATE_ERROR = '[Project Templates] Update Project Template Error',

    UPSERT_PROJECT_TEMPLATES_START = '[APP] Upsert Project Templates Start',
    UPSERT_PROJECT_TEMPLATES_FINISH = '[DB] Upsert Project Templates Finish',

    UPDATE_PROJECT_TEMPLATES_REQUEST = '[Project Templates] Update Project Templates Request',
    UPDATE_PROJECT_TEMPLATES_SUCCESS = '[API] Update Project Templates Success',
    UPDATE_PROJECT_TEMPLATES_ERROR = '[API] Update Project Templates Error',

    TOGGLE_SELECT_PROJECT_TEMPLATE = '[Project Templates] Toggle Select Project Template',
    TOGGLE_SELECT_ALL_PROJECT_TEMPLATES = '[Project Templates] Toggle Select all Project Templates',
    DESELECT_ALL_PROJECT_TEMPLATES = '[Project Templates] Deselect all Project Templates',

    TOGGLE_ARCHIVED_PROJECT_TEMPLATES = '[Project Templates] Toggle Archived Project Templates',
}

export class UpsertProjectTemplatesStart implements Action {
    readonly type = ProjectTemplatesActionTypes.UPSERT_PROJECT_TEMPLATES_START;
    constructor(public payload: { projectTemplates: Project[] }) {}
}

export class UpsertProjectTemplatesFinish implements Action {
    readonly type = ProjectTemplatesActionTypes.UPSERT_PROJECT_TEMPLATES_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<ProjectDocType> }
    ) {}
}

export class ToggleSelectProjectTemplate implements Action {
    readonly type = ProjectTemplatesActionTypes.TOGGLE_SELECT_PROJECT_TEMPLATE;

    constructor(public payload: { templateId: string }) {}
}

export class ToggleSelectAllProjectTemplates implements Action {
    readonly type =
        ProjectTemplatesActionTypes.TOGGLE_SELECT_ALL_PROJECT_TEMPLATES;
}

export class DeselectAllProjectTemplates implements Action {
    readonly type = ProjectTemplatesActionTypes.DESELECT_ALL_PROJECT_TEMPLATES;
}

export class ToggleArchivedProjectTemplates implements Action {
    readonly type =
        ProjectTemplatesActionTypes.TOGGLE_ARCHIVED_PROJECT_TEMPLATES;
}

export class LoadProjectTemplatesRequest implements Action {
    readonly type = ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_REQUEST;

    constructor(
        public payload: {
            withArchived?: boolean;
        } = {
            withArchived: false,
        }
    ) {}
}

export class LoadProjectTemplatesSuccess implements Action {
    readonly type = ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_SUCCESS;

    constructor(
        public payload: { projectTemplates: Project[]; fromDb: boolean }
    ) {}
}

export class LoadProjectTemplatesError implements Action {
    readonly type = ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_ERROR;
}

export class CreateProjectTemplateRequest implements Action {
    readonly type = ProjectTemplatesActionTypes.CREATE_PROJECT_TEMPLATE_REQUEST;
    constructor(
        public payload: { template: Project; templateProjectId?: string }
    ) {}
}

export class CreateProjectTemplateSuccess implements Action {
    readonly type = ProjectTemplatesActionTypes.CREATE_PROJECT_TEMPLATE_SUCCESS;
    constructor(public payload: { template: Project }) {}
}
export class CreateProjectTemplateError implements Action {
    readonly type = ProjectTemplatesActionTypes.CREATE_PROJECT_TEMPLATE_ERROR;
    constructor() {}
}

export class UpdateProjectTemplateRequest implements Action {
    readonly type = ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATE_REQUEST;
    constructor(public payload: { template: Project }) {}
}

export class UpdateProjectTemplateSuccess implements Action {
    readonly type = ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATE_SUCCESS;
    constructor(public payload: { template: Project }) {}
}
export class UpdateProjectTemplateError implements Action {
    readonly type = ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATE_ERROR;
    constructor() {}
}

export class UpdateProjectTemplatesRequest implements Action {
    readonly type =
        ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATES_REQUEST;

    constructor(public payload: { templates: Project[] }) {}
}

export class UpdateProjectTemplatesSuccess implements Action {
    readonly type =
        ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATES_SUCCESS;

    constructor(public payload: { templates: Project[] }) {}
}

export class UpdateProjectTemplatesError implements Action {
    readonly type = ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATES_ERROR;
}

export type ProjectTemplatesActions =
    | LoadProjectTemplatesRequest
    | LoadProjectTemplatesSuccess
    | LoadProjectTemplatesError
    | CreateProjectTemplateRequest
    | CreateProjectTemplateSuccess
    | CreateProjectTemplateError
    | UpdateProjectTemplateRequest
    | UpdateProjectTemplateSuccess
    | UpdateProjectTemplateError
    | UpdateProjectTemplatesRequest
    | UpdateProjectTemplatesSuccess
    | UpdateProjectTemplatesError
    | UpsertProjectTemplatesStart
    | UpsertProjectTemplatesFinish
    | ToggleSelectProjectTemplate
    | ToggleSelectAllProjectTemplates
    | DeselectAllProjectTemplates
    | ToggleArchivedProjectTemplates;
