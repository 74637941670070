import { SelectionModel } from '@angular/cdk/collections';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { getIsOnline } from 'app/store/core/core.selectors';
import {
    ToggleSelectAllProjects,
    ToggleSelectProject,
} from 'app/store/projects/projects.actions';
import { ProjectsState } from 'app/store/projects/projects.reducer';

export interface ProjectElement {
    name: string;
    dateCreated: string;
    begin: string;
    end: string;
}

@Component({
    selector: 'acc-project-table',
    templateUrl: 'project-table.component.html',
    styleUrls: ['project-table.component.scss'],
})
export class ProjectTableComponent implements OnInit {
    selection = new SelectionModel<Project>(true, []);

    isOnline$ = this.store.pipe(select(getIsOnline));
    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));

    @Input()
    dataSource: MatTableDataSource<Project>;

    @Input()
    language: string;

    @Input()
    displayedColumns: string[];

    @Input()
    selectedProjectsIds: string[];

    @Input()
    totalProjectsCount: number;

    @Output()
    updated = new EventEmitter<Project>();

    @Output()
    archived = new EventEmitter<Project>();

    @Output()
    toggleSelect = new EventEmitter<string>();

    @Output()
    toggleSelectAll = new EventEmitter<void>();

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(private store: Store<ProjectsState>) {}

    ngOnInit(): void {
        this.dataSource.sort = this.sort;
    }
}
