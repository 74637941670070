/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarkerAnnotation { 
    type?: MarkerAnnotation.TypeEnum;
    id?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    x1?: number;
    y1?: number;
    color?: string;
}
export namespace MarkerAnnotation {
    export type TypeEnum = 'Arrow' | 'Rectangle' | 'Circle' | 'Line' | 'Marker';
    export const TypeEnum = {
        Arrow: 'Arrow' as TypeEnum,
        Rectangle: 'Rectangle' as TypeEnum,
        Circle: 'Circle' as TypeEnum,
        Line: 'Line' as TypeEnum,
        Marker: 'Marker' as TypeEnum
    };
}


