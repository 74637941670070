<div fxLayout="column">

    <div *ngIf="isLoading ;else table" fxLayout="row" fxLayoutAlign="center center" fxFlex class="py-32">
        <mat-spinner
            diameter="40"
            color="accent"
            mode="indeterminate"
        ></mat-spinner>
    </div>

    <ng-template #table>
        <div
        class="entity-table-wrapper pl-24"
        fxLayout="row"
        fxLayoutAlign="space-between center"
    >
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="h1">{{ title | translate }}</span>
            <button
                *ngIf="deletable"
                (click)="deleteSelected.emit(selection.selected)"
                mat-button
                color="warn"
                [disabled]="selection.isEmpty()"
            >
                <mat-icon class="mr-4">archive</mat-icon>
                <span translate>APP.ARCHIVE_VERB</span>
            </button>
            <span
                class="ml-12 mt-4 secondary-text"
                *ngIf="selection.selected?.length > 0"
            >
                {{ selection.selected?.length }}
                {{
                    'GLOBAL_SETTINGS.ADDRESS_BOOK.ELEMENTS_SELECTED' | translate
                }}
            </span>
        </div>

        <mat-paginator
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
        ></mat-paginator>
    </div>

    <mat-table class="entity-table" matSort [dataSource]="dataSource">
        <!-- Checkbox Column -->
        <ng-container *ngIf="selectable" matColumnDef="select">
            <mat-header-cell class="w-80" *matHeaderCellDef>
                <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell class="w-80" *matCellDef="let row">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <!-- Generic column definition -->
        <ng-container
            *ngFor="let column of columns"
            [matColumnDef]="column.columnDef"
        >
            <mat-header-cell mat-sort-header *matHeaderCellDef>{{
                column.header | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </ng-container>

        <!-- Edit -->
        <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                *matCellDef="let element"
                fxLayout="row"
                fxLayoutAlign="end center"
            >
                <button
                    (click)="updated.emit(element.id)"
                    mat-icon-button
                    class="secondary-text"
                    [matTooltip]="'APP.EDIT' | translate"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="deleted.emit(element.id)"
                    mat-icon-button
                    class="secondary-text"
                    [matTooltip]="'APP.ARCHIVE_VERB' | translate | titlecase"
                >
                    <mat-icon>archive</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="import">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                *matCellDef="let element"
                fxLayout="row"
                fxLayoutAlign="end center"
            >
                <button
                    (click)="import.emit(element.id)"
                    mat-icon-button
                    class="secondary-text"
                    [matTooltip]="'APP.IMPORT_UNTERMSTRICH' | translate"
                >
                    <mat-icon>add_box</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div class="py-32" fxLayout="row" fxFlexAlign="center" *ngIf="!dataSource.data.length">{{ (noRecordsTranslation? noRecordsTranslation : 'APP.TABLE_NO_RECORDS_TO_SHOW') | translate}}</div>
</ng-template>

</div>
