import { Entity } from './../entity/entity';
import { DiaryDocType } from './../diary/diary.document';
import { IssueDocType } from './../issues/issue.document';
import { Conflict } from './../../main/models/conflict';
import { RxDocument } from 'rxdb';

export enum ConflictTypeDocType {
    Issue = 'issue',
    DailyLogEntry = 'dailyLogEntry',
}

export interface ConflictDocTypeBase<T extends Entity> {
    id: string;
    type: ConflictTypeDocType;
    itemOnServer: T;
    itemWantUpdate: T;
    requiredUpdateDateTime: string;
}

export class IssueConflictDocType implements ConflictDocTypeBase<IssueDocType> {
    type = ConflictTypeDocType.Issue;

    constructor(
        public id: string,
        public itemOnServer: IssueDocType,
        public itemWantUpdate: IssueDocType,
        public requiredUpdateDateTime: string
    ) {}
}

export class DailyLogEntryBaseConflictDocType
    implements ConflictDocTypeBase<DiaryDocType> {
    type = ConflictTypeDocType.DailyLogEntry;

    constructor(
        public id: string,
        public itemOnServer: DiaryDocType,
        public itemWantUpdate: DiaryDocType,
        public requiredUpdateDateTime: string
    ) {}
}

export type ConflictDocType =
    | IssueConflictDocType
    | DailyLogEntryBaseConflictDocType;
