<div fxLayout="row" fxLayoutAlign="space-between center" class="toolbar-wrapper mt-16">
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-slide-toggle (change)="toggleArchived.emit($event)" [checked]="showArchived" class="ml-16">{{ 'APP.ARCHIVE'
            | translate | uppercase }}</mat-slide-toggle>

        <mat-slide-toggle *ngIf="isAdmin$ | async" [checked]="showTemplates" class="ml-16"
            (change)="toggleTemplates.emit($event)" data-test="projects-show-templates">
            {{ 'APP.TEMPLATES' | translate | uppercase }}
        </mat-slide-toggle>

        <button mat-icon-button (click)="toggleRefresh.emit()">
            <mat-icon>refresh</mat-icon>
        </button>

        <button *ngIf="(isAdmin$ | async) && !gridView" (click)="openArchiveSelectedProjectsDialog.emit()" mat-button
            color="warn" [disabled]="selectedProjectsIds.length === 0" data-test="project-table-archive-button">
            <mat-icon class="mr-4">archive</mat-icon>
            <span translate>PROJECT.ARCHIVE_PROJECTS</span>
        </button>
    </div>

    <button (click)="gridView ? setListView.emit() : setGridView.emit()" class="view-btn" mat-icon-button fxHide.xs
        data-test="project-switch-grid-table">
        <mat-icon>{{ gridView ? 'list' : 'view_module' }}</mat-icon>
    </button>
</div>