import { Issue } from 'app/core/rest-api';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export class IssuePost implements Issue {
    boilerPlattes: string[];
    craft: number;
    difficulty: number;
    creationDate?: Date;
    dueDate: Date;
    position: string;
    responsibleExternalId: string;
    responsibleInternalId: string;
    state: Issue.StateEnum;
    projectId: string;
    title: string;
    description?: string;
    markedAsDelete: boolean;
    files: string[];
    photo: string;
    photos: string[];
    constructor(projectId: string, initValues: Partial<IssuePost>) {
        this.projectId = projectId;
        this.state = Issue.StateEnum.Open;
        this.dueDate = moment(new Date()).add(14,'d').toDate();
        this.difficulty = 1;
        this.boilerPlattes = [];
        this.position = '';
        this.craft = null;
        this.responsibleExternalId = null;
        this.responsibleInternalId = null;
        this.markedAsDelete = false;
        this.files = [];
        this.photos = [];
        this.photo = '';

        // match the initial issue state of a just initialized issue details
        // component to have the same state on new issue if the issue
        // details component is reused
        this.creationDate = null;
        this.description = null;

        const hasInitValues = isEmpty(initValues) === false;
        if (hasInitValues) {
            const {
                position,
                craft,
                responsibleExternalId,
                responsibleInternalId
            } = initValues;

            if (position) {
                this.position = position;
            }
            this.craft = craft || null;

            this.responsibleExternalId = responsibleExternalId || null;
            this.responsibleInternalId = responsibleInternalId || null;
        }
    }
}
