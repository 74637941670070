import { Collections } from '../database';
import { migrationRanFor } from '../fix-collections.util';
import { LocationDocTypeV0, LocationDocTypeV1 } from './location.document';

function addCraftToPlan(oldDoc: LocationDocTypeV0): LocationDocTypeV1 {
    const newDoc: LocationDocTypeV1 = {
        ...oldDoc,
        craftToPlan: undefined,
        ownerId: undefined,
        updaterId: undefined,
        creatorId: undefined,
        deleteDateTime: undefined,
        order: undefined,
    };

    // see issue migration for details about this
    delete (newDoc as any)._id;
    migrationRanFor(Collections.Locations);

    return newDoc;
}

export const locationMigrations = {
    1: addCraftToPlan,
};
