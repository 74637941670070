import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { DiaryEffects } from './diary.effects';
import * as fromDiary from './diary.reducer';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        StoreModule.forFeature('diary', fromDiary.reducer),
        EffectsModule.forFeature([DiaryEffects])
    ],
    exports: [],
    providers: []
})
export class DiaryStoreModule {}
