import { Entity } from './../entity/entity';
import { Conflict } from './../../main/models/conflict';
import { SyncCommand } from './../../main/sync/models/sync-command';

export class ConflictUtils {
    static lockConflictingCommands<
        T extends Entity,
        CommandType extends SyncCommand<T> = SyncCommand<T>
    >(conflicts?: Conflict<T>[], commands?: CommandType[]): CommandType[] {
        if (!conflicts || !commands) {
            return commands ? commands : [];
        }

        const locked = true;
        const conflictIds = conflicts.map((c) => c.itemOnServer.id);

        return [...commands].map((command) =>
            conflictIds.includes(command.entityId)
                ? { ...command, locked }
                : command
        );
    }

    static newestUniqueConflicts<T extends Entity>(
        ...conflicts: Conflict<T>[]
    ): Conflict<T>[] {
        const uniqueConflicts = new Map<string, Conflict<T>>();
        for (const conflict of conflicts) {
            const key = conflict.itemOnServer.id;

            const entry = uniqueConflicts.get(key);
            const conflictNewer =
                entry &&
                conflict.requiredUpdateDateTime > entry.requiredUpdateDateTime;
            if (!entry || conflictNewer) {
                uniqueConflicts.set(key, conflict);
            }
        }
        return Array.from(uniqueConflicts.values());
    }
}
