import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Action, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { CoreState } from 'app/store/core/core.reducer';

const DEFAULT_ERROR_SNACKBAR_DURATION = 6000;

export interface MessageOptions {
    autohide: boolean;
    buttonLabelKey: string;
    dispatchAction?: Action;
}

const defaultOptions: MessageOptions = {
    autohide: true,
    buttonLabelKey: 'GENERIC_BUTTONS.OK',
};

@Injectable({
    providedIn: 'root',
})
export class ErrorUtils {
    constructor(
        private translationService: TranslateService,
        private snackbar: MatSnackBar,
        private store: Store<CoreState>
    ) {}

    /** True if a message notification supression is in effect   */
    get isSuppressed(): boolean {
        return this.suppressedCount > 0;
    }
    /** Track how many instances of supression are in effect */
    suppressedCount = 0;

    /** Disable message (showSingleMessageOrDefault) for a short time. Intended to avoid warnings when the demo API won't be used. */
    public suppressForTime(ms = 200): void {
        this.suppressedCount++;
        setTimeout( () => {
            this.suppressedCount--;
        }, ms);
    }

    private getSingleErrorMessageFromServerError(errorData: any): string|null {
        const error = this.getErrorObjectFromServerError(errorData);

        if (!error) {
            return null;
        }

        if (error.allMessages && error.allMessages?.length) {
            return error.allMessages[0];
        }

        if (error.customerMessage) {
            return error.customerMessage;
        }

        return error.message;
    }

    private getErrorObjectFromServerError(errorData: any): any {
        return errorData?.error?.meta?.error;
    }

    private getSingleErrorMessageFromServerErrorOrDefault(
        error: any,
        actionIdentification: string
    ): string {
        let errorMessage = this.getSingleErrorMessageFromServerError(error);

        if (errorMessage && errorMessage.indexOf('ERRORS.') >= 0) {
            return this.translationService.instant(errorMessage);
        }

        errorMessage = !errorMessage
            ? this.translationService.instant(
                  'DEFAULT_ERROR_MESSAGES.' + actionIdentification
              )
            : errorMessage;

        return errorMessage;
    }

    showSingleMessageOrDefault(
        error: any,
        actionIdentification: string,
        options?: Partial<MessageOptions>
    ): void {
        // Skip message if suppressing warning notifications
        if ( this.isSuppressed ) {
            return;
        }
        const allOptions: MessageOptions = {
            ...defaultOptions,
            ...options,
        };

        const errorMessage = this.getSingleErrorMessageFromServerErrorOrDefault(
            error,
            actionIdentification
        );
        console.error(JSON.stringify(error));
        console.log(JSON.stringify(error));

        this.showSnackbarWithMessage(errorMessage, allOptions);
    }

    private showSnackbarWithMessage(
        errorMessage: string,
        allOptions: MessageOptions
    ): void {
        const snackBarOptions: MatSnackBarConfig = {
            panelClass: 'error-snackbar',
            verticalPosition: 'top',
        };

        if (allOptions.autohide) {
            snackBarOptions.duration = DEFAULT_ERROR_SNACKBAR_DURATION;
        }

        const optionallyDispatchAction = () => {
            if (allOptions.dispatchAction) {
                this.store.dispatch(allOptions.dispatchAction);
            }
        };

        const buttonLabel = this.translationService.instant(
            allOptions.buttonLabelKey
        );

        this.snackbar
            .open(errorMessage, buttonLabel, snackBarOptions)
            .afterDismissed()
            .pipe(
                tap((dismiss) => {
                    if (dismiss.dismissedByAction) {
                        optionallyDispatchAction();
                    }
                })
            )
            .subscribe();
    }

    showMultipleMessagesOrDefault(
        error: any,
        actionIdentification: string,
        options?: Partial<MessageOptions>
    ): void {
        const allOptions: MessageOptions = {
            ...defaultOptions,
            ...options,
        };

        const errorObject = this.getErrorObjectFromServerError(error);

        let errorMessage: string;

        const allMessages = errorObject?.allMessages as string[] | undefined;
        if (allMessages?.length > 0) {
            errorMessage = allMessages.join(' ');
        }

        if (!errorMessage) {
            // fallback if no allMessages is present
            errorMessage = this.getSingleErrorMessageFromServerErrorOrDefault(
                error,
                actionIdentification
            );
        }

        this.showSnackbarWithMessage(errorMessage, allOptions);
    }

    dismissSnackbar(): void {
        this.snackbar.dismiss();
    }
}
