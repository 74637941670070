import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCrafts from './crafts.reducer';
import { CraftsState } from './crafts.reducer';

export const selectCraftsState = createFeatureSelector<CraftsState>('crafts');

export const getAllCrafts = createSelector(
    selectCraftsState,
    fromCrafts.selectAll
);

export const getCraftsEntities = createSelector(
    selectCraftsState,
    fromCrafts.selectEntities
);

export const getCraftsIds = createSelector(
    selectCraftsState,
    fromCrafts.selectIds
);

export const getCraftsLoaded = createSelector(
    selectCraftsState,
    state => state.loaded
);

export const getCraftById = createSelector(
    getCraftsEntities,
    (entities, props) => entities[props.id]
);
