import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BoilerPlate } from 'app/core/rest-api';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { BoilerplateDocType } from './boilerplate.document';

@Injectable({
    providedIn: 'root',
})
export class BoilerplateDbService extends EntityDbService<
    BoilerPlate,
    BoilerplateDocType
> {
    apiItemToSchema(bp: BoilerPlate): BoilerplateDocType {
        const {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
        } = bp;
        return {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
        };
    }
    getAttachmentIdsFromApiItem(bp: BoilerPlate): string[] {
        return [];
    }
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Boilerplates
        );
    }
}
