import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { NotificationsEffects } from './notifications.effects';
import * as fromNotifications from './notifications.reducer';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        StoreModule.forFeature('notifications', fromNotifications.reducer),
        EffectsModule.forFeature([NotificationsEffects])
    ],
    exports: [],
    providers: []
})
export class NotificationsStoreModule {}
