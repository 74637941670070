import { Pipe, PipeTransform } from '@angular/core';
import { BoilerPlate } from 'app/core/rest-api';

@Pipe({ name: 'boilerplateText' })
export class BoilerplateTextPipe implements PipeTransform {
    transform(boilerplateIds: string[], boilerplates: BoilerPlate[]): string {
        let result = '';
        for (const id of boilerplateIds) {
            const bpItem = boilerplates.find(b => b.id === id);
            if (bpItem) {
                result += bpItem.title + ' ';
            }
        }

        return result;
    }
}
