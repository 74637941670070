<mat-menu #appMenu="matMenu" class="notifications">
    <div
        class="notification-header"
        fxLayout="row"
        fxLayoutAlign="space-between"
        *ngLet="(notifications$ | async).length as notificationCount"
    >
        <p *ngIf="notificationCount > 0">
            {{ 'NOTIFICATIONS.LAST_ACTIVITIES' | translate }}
        </p>
        <p *ngIf="notificationCount === 0">
            {{ 'NOTIFICATIONS.NO_ACTIVITIES' | translate }}
        </p>
        <!-- <a href="#" class="all-notifications">Alle Aktivitäten anzeigen</a> -->
    </div>

    <cdk-virtual-scroll-viewport
        itemSize="10"
        [ngClass]="{
            'notifications-container-single-item':
                (notifications$ | async).length === 1,
            'notifications-container-multiple-items':
                (notifications$ | async).length > 1
        }"
    >
        <ng-container *ngLet="notifications$ | async as notifications">
            <div
                *cdkVirtualFor="
                    let notification of notifications;
                    let i = index
                "
            >
                <div *ngIf="notification.notificationContext">
                    <button
                        mat-menu-item
                        class="w-100-p notification"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        (click)="markNotificationAsRead(notification)"
                    >
                        <img src="assets/images/custom/avatar.svg" />

                        <div
                            [ngSwitch]="notification.type"
                            class="information-container"
                        >
                            <p>
                                {{ 'APP.PROJECT' | translate }}:
                                {{
                                    projectsEntities[
                                        notification.notificationContext[
                                            'projectId'
                                        ]
                                    ]?.title
                                }}
                            </p>

                            <p
                                [innerHtml]="
                                    notification | notificationDetailsInnerHtml
                                "
                            ></p>

                            <p>
                                <span
                                    *ngFor="
                                        let location of locations$
                                            | async
                                            | getLocationTreeByPosition
                                                : notification
                                                      .notificationContext
                                                      .position;
                                        let last = last
                                    "
                                    class="secondary-text mr-4"
                                >
                                    {{ location.title }} {{ last ? '' : ' | ' }}
                                </span>
                            </p>

                            <p class="datetime text-right secondary-text">
                                {{
                                    notification.updateDateTime
                                        | timeDifferenceText: timeNow:'PAST'
                                }}
                            </p>
                        </div>
                    </button>
                    <hr *ngIf="i !== notifications.length - 1" />
                </div>
            </div>
        </ng-container>
    </cdk-virtual-scroll-viewport>

    <div
        class="w-100-p all-read p-8"
        fxLayout="column"
        fxLayoutAlign="center center"
        *ngIf="(notifications$ | async).length > 0"
    >
        <button
            (click)="markNotificationsAsRead()"
            mat-button
            class="all-read-btn text-center"
        >
            {{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}
        </button>
    </div>
</mat-menu>
