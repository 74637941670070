import { AutoSelectorNoMatchComponent } from '../auto-selector-no-match/auto-selector-no-match.component';
import { AutoSelectorConfig } from '../auto-selector.config';

export class AutoSelectorDialogData {
  items: any[];
  fallbackItems: any[];
  value: string | string[];
  config: AutoSelectorConfig;
  noMatchComponent?: AutoSelectorNoMatchComponent;
}
