import { Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroy } from '@ngrx-utils/store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/core/auth/models/user';
import { Project, TimeZoneShortInfo } from 'app/core/rest-api';
import { DeviceService } from 'app/core/services/device.service';
import { ConfirmDialogComponent } from 'app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { FileDialogComponent } from 'app/shared/components/dialogs/file-dialog/file-dialog.component';
import {
    getCurrentUser,
    getIsAdminGlobal,
} from 'app/store/auth/auth.selectors';
import { getIsOnline, getLanguage } from 'app/store/core/core.selectors';
import {
    CreateProjectRequest,
    DeselectAllProjects,
    LoadProjectsRequest,
    ToggleArchived,
    UpdateProjectRequest,
    UpdateProjectsRequest,
    ToggleSelectProject,
    ToggleSelectAllProjects,
} from 'app/store/projects/projects.actions';
import { ProjectsState } from 'app/store/projects/projects.reducer';
import {
    getLoading,
    getProjectsByIds,
    getSelectedProjects,
    getTotalProjectCount,
    selectActiveFilteredProjects,
    selectFilteredProjects,
    selectProjectById,
} from 'app/store/projects/projects.selectors';
import { getTimezones } from 'app/store/settings/settings.selectors';
import { environment } from 'environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ProjectModel } from '../project.model';
import { ProjectFormComponent } from './project-form/project-form.component';
import {
    LoadProjectTemplatesRequest,
    CreateProjectTemplateRequest,
    UpdateProjectTemplateRequest,
    DeselectAllProjectTemplates,
    ToggleArchivedProjectTemplates,
    ToggleSelectProjectTemplate,
    ToggleSelectAllProjectTemplates,
} from 'app/store/project-templates/project-templates.actions';
import {
    selectFilteredUserCreatedProjectTemplates,
    selectAllActiveProjectTemplates,
    selectActiveFilteredUserCreatedProjectTemplates,
    getSelectedProjectTemplates,
    getProjectTemplatesByIds,
    getTotalProjectTemplateCount,
} from 'app/store/project-templates/project-templates.selectors';
import { ProjectStatisticsService } from './project-statistics/project-statistics.service';
import { ProjectWithStatistics } from './project-statistics/project-with-statistics.model';
import { TooltipUtilService } from 'app/shared/services/tooltip-util/tooltip-util.service';

@Component({
    selector: 'acc-project-list',
    templateUrl: 'project-list.component.html',
    styleUrls: ['project-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListComponent implements OnDestroy, OnInit {
    displayedColumns: string[] = [
        'select',
        'title',
        'startDate',
        'dueDate',
        'creationDate',
        'edit',
    ];
    cols = 5;
    gridView = true;

    showArchived = false;
    showTemplates = false;

    loading$: Observable<boolean>;

    user$: Observable<User>;
    projects$: Observable<Project[]>;
    language$: Observable<string>;
    timezones$: Observable<TimeZoneShortInfo[]>;

    isOnline$ = this.store.pipe(select(getIsOnline));

    projectsWithStatistics$: Observable<ProjectWithStatistics[]>;

    currentProject: Project;
    currentTemplate: Project;

    assetUrl = environment.endpoints.asset;
    private projectChanges: Partial<Project>;
    hasProjectChanges = false;

    form: UntypedFormGroup;
    updateMode = false;
    dataSource = new MatTableDataSource<Project>();

    selectedProjectsIds$: Observable<string[]>;
    totalProjectsCount$: Observable<number>;
    private selectedProjects: Project[];

    private projectsSub: Subscription;

    projectTemplates$: Observable<Project[]>;

    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));

    unsubscribe$ = new Subject<void>();

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSidenav)
    sideNav: MatSidenav;

    @ViewChild(ProjectFormComponent) projectForm: ProjectFormComponent;

    constructor(
        private changeDetetorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private media: MediaObserver,
        private store: Store<ProjectsState>,
        private translateService: TranslateService,
        private deviceService: DeviceService,
        private location: Location,
        private locationStrategy: LocationStrategy,
        private tooltipUtilService: TooltipUtilService,
        private projectStatisticsService: ProjectStatisticsService
    ) {
        this.createForm();

        // will only be enabled, if the current user is allowed to edit
        this.form.disable();

        this.isAdmin$
            .pipe(
                take(1),
                tap((isAdmin) => {
                    if (isAdmin) {
                        this.form.enable();
                    }
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.timezones$ = this.store.pipe(select(getTimezones));
        this.language$ = this.store.pipe(select(getLanguage));

        this.media.asObservable()
            .pipe(untilDestroy(this))
            .subscribe(bp => bp.forEach(it => this.resizeGrid(it)));

        this.loading$ = this.store.pipe(select(getLoading));

        this.user$ = this.store.pipe(select(getCurrentUser));

        this.projects$ = this.store.pipe(select(selectFilteredProjects));
        this.projectsSub = this.projects$.subscribe((projects) => {
            this.refreshDataSource(projects);
        });

        this.form.valueChanges
            .pipe(untilDestroy(this))
            .subscribe((change) => this.onFormChange(change));

        this.setSelectedProjectsSubscriber();

        this.projectTemplates$ = this.store.pipe(
            select(selectAllActiveProjectTemplates)
        );

        this.deviceService.setBodyClass('indigo-700-bg');

        //FIXME: might want to look at the auth guard here
        //seems to be a bug with Fuse/NgRx
        this.location.subscribe((res) => {
            if (res.url === '/login') {
                this.locationStrategy.forward();
            }
        });

        this.updateStatistics();
    }

    ngOnDestroy(): void {
        this.projectsSub.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        // do not remove
    }

    toggleRefresh(): void {
        const withArchived = this.showArchived;
        this.store.dispatch(new LoadProjectsRequest({ withArchived }));
        this.store.dispatch(
            new LoadProjectTemplatesRequest({
                withArchived,
            })
        );
    }

    createForm(): void {
        this.form = this.fb.group({
            title: ['', Validators.required],
            startDate: [''],
            dueDate: [''],
            address: [''],
            postCode: [''],
            city: [''],
            country: [''],
            timeZoneId: ['', Validators.required],
        });
    }

    refreshDataSource(projects: Project[]): void {
        this.dataSource.data = projects;
        if (this.dataSource.paginator) {
            this.updatePagination();
        }
    }

    updatePagination(): void {
        while (this.isLessDataThanFitsOnPage()) {
            this.dataSource.paginator.previousPage();
        }
    }

    private isLessDataThanFitsOnPage(): boolean {
        return (
            this.dataSource.paginator.pageIndex > 0 &&
            this.dataSource.data.length <=
                this.dataSource.paginator.pageSize *
                    this.dataSource.paginator.pageIndex
        );
    }

    changeTemplate(project: Project): void {
        if (project) {
            this.form.patchValue(project);
            this.currentTemplate = project;

            this.setCurrentProjectPhoto(project.photo);
        } else {
            this.form.reset();
            delete this.currentTemplate;
        }
    }

    onFormChange(formChange: any): void {
        const currentChanges: Partial<Project> = {};
        if (this.currentProject) {
            for (const key in formChange) {
                if (formChange[key] !== this.currentProject[key]) {
                    currentChanges[key] = formChange[key];
                }
            }
        } else {
            for (const key in formChange) {
                if (formChange.hasOwnProperty(key)) {
                    currentChanges[key] = formChange[key];
                }
            }
        }
        this.projectChanges = currentChanges;
        this.hasProjectChanges = Object.keys(this.projectChanges).length > 0;
    }

    setClientCurrentTimeZone(): void {
        this.form
            .get('timeZoneId')
            .setValue(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }

    createDialog(): void {
        this.updateMode = false;
        this.form.reset();

        this.setClientCurrentTimeZone();

        delete this.currentProject;
        delete this.currentTemplate;

        if (this.projectForm) {
            this.projectForm.cleanSelectTemplateValue();
        }

        this.hasProjectChanges = false;
        this.sideNav.open();
    }

    updateDialog(project: Project): void {
        this.updateMode = true;
        this.form.reset();

        this.currentProject = project;

        this.form.patchValue(project);
        this.hasProjectChanges = false;
        this.sideNav.open();
    }

    createProject(): void {
        if (this.form.invalid) {
            return;
        }

        const newProject: Project = {
            ...new ProjectModel(),
            ...this.currentProject,
            ...this.currentTemplate,
            ...this.form.value,
        };
        if (!newProject.startDate) {
            newProject.startDate = undefined;
        }

        if (!newProject.dueDate) {
            newProject.dueDate = undefined;
        }

        if (this.showTemplates) {
            this.store.dispatch(
                new CreateProjectTemplateRequest({
                    template: newProject,
                    templateProjectId: this.currentTemplate?.id,
                })
            );
        } else {
            this.store.dispatch(
                new CreateProjectRequest({
                    project: newProject,
                    templateProjectId: this.currentTemplate?.id,
                })
            );
        }
        this.form.reset();
        this.sideNav.close();
    }

    updateProject(): void {
        if (this.form.invalid) {
            return;
        }
        if (this.showTemplates) {
            const template: Project = {
                ...this.currentProject,
                ...this.form.value,
            };
            this.store.dispatch(new UpdateProjectTemplateRequest({ template }));
            this.form.reset();
            this.sideNav.close();
            return;
        }

        if (this.hasProjectChanges) {
            this.store.dispatch(
                new UpdateProjectRequest({
                    project: {
                        id: this.currentProject.id,
                        timeZoneId: this.currentProject.timeZoneId,
                        updateDateTime: this.currentProject.updateDateTime,
                        ...this.projectChanges,
                    },
                })
            );
            this.form.reset();
        }
        this.sideNav.close();
    }

    archiveProjectDialog(project: Project): void {
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant(
                        'PROJECT.ARCHIVE_PROJECT_QUESTION'
                    ),
                    question: this.translateService.instant(
                        'PROJECT.ARCHIVE_PROJECT_CONFIRM'
                    ),
                },
            })
            .afterClosed()
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.archiveProject(project);
                }
            });
    }

    archiveProject(project: Project): void {
        const archivedProject = {
            timeZoneId: project.timeZoneId,
            id: project.id,
            markedAsDelete: true,
            updateDateTime: project.updateDateTime,
        };
        this.store.dispatch(
            new UpdateProjectRequest({ project: archivedProject })
        );
    }

    archiveSelectedProjectsDialog(): void {
        if (this.selectedProjects.length === 1) {
            this.archiveProjectDialog(this.selectedProjects[0]);
            return;
        }

        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant(
                        'PROJECT.ARCHIVE_PROJECTS_QUESTION'
                    ),
                    question: this.translateService.instant(
                        'PROJECT.ARCHIVE_PROJECTS_CONFIRM',
                        { length: this.selectedProjects.length }
                    ),
                },
            })
            .afterClosed()
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.archiveSelectedProjects();
                }
            });
    }

    archiveSelectedProjects(): void {
        const archivedProjects: Project[] = [];
        this.selectedProjects.forEach((project) => {
            const archivedProject = {
                id: project.id,
                timeZoneId: project.timeZoneId,
                markedAsDelete: true,
                updateDateTime: project.updateDateTime,
            };
            archivedProjects.push(archivedProject);
        });
        this.store.dispatch(
            new UpdateProjectsRequest({ projects: archivedProjects })
        );
        this.store.dispatch(new DeselectAllProjects());
    }

    setProjectsArchivedData(): void {
        this.store.dispatch(new LoadProjectsRequest({ withArchived: true }));
        this.store.dispatch(new ToggleArchived());
    }

    setProjectTemplatesArchivedData(): void {
        this.store.dispatch(
            new LoadProjectTemplatesRequest({
                withArchived: true,
            })
        );
        this.store.dispatch(new ToggleArchivedProjectTemplates());
    }

    toggleArchived(event: MatSlideToggleChange): void {
        this.setProjectsArchivedData();
        this.setProjectTemplatesArchivedData();

        this.showArchived = event.checked;

        this.updateListData();

        this.updateStatistics();
    }

    toggleTemplates(event: MatSlideToggleChange): void {
        this.showTemplates = event.checked;

        this.updateSelectedProjectsSubscriber();
        this.updateListData();

        this.projectsSub.unsubscribe();
        this.projectsSub = this.projects$.subscribe((projects) =>
            this.refreshDataSource(projects)
        );

        this.updateStatistics();
    }

    private updateStatistics(): void {
        if (this.showTemplates) {
            this.projectsWithStatistics$ = this.projectStatisticsService.projectsWithoutStatisticsFor(
                this.projects$
            );
        } else {
            this.projectsWithStatistics$ = this.projectStatisticsService.projectsWithStatisticsFor(
                this.projects$
            );
        }
    }

    updateSelectedProjectsSubscriber(): void {
        // this.unsubscribe$.next();
        // this.unsubscribe$.complete();
        // this.unsubscribe$ = new Subject<void>();
        if (this.showTemplates) {
            this.setSelectedProjectTemplatesSubscriber();
            return;
        }

        this.setSelectedProjectsSubscriber();
    }

    setSelectedProjectsSubscriber(): void {
        this.totalProjectsCount$ = this.store.pipe(
            select(getTotalProjectCount)
        );

        this.selectedProjectsIds$ = this.store.pipe(
            select(getSelectedProjects)
        );

        this.selectedProjectsIds$
            .pipe(
                takeUntil(this.unsubscribe$),
                mergeMap((projectIds) =>
                    this.store.pipe(select(getProjectsByIds(projectIds)))
                )
            )
            .subscribe((projects) => (this.selectedProjects = projects));
    }

    setSelectedProjectTemplatesSubscriber(): void {
        this.totalProjectsCount$ = this.store.pipe(
            select(getTotalProjectTemplateCount)
        );

        this.selectedProjectsIds$ = this.store.pipe(
            select(getSelectedProjectTemplates)
        );

        this.selectedProjectsIds$
            .pipe(
                takeUntil(this.unsubscribe$),
                mergeMap((templateIds) => {
                    return this.store.pipe(
                        select(getProjectTemplatesByIds(templateIds))
                    );
                })
            )
            .subscribe((templates) => (this.selectedProjects = templates));
    }

    updateListData(): void {
        this.store.dispatch(new DeselectAllProjectTemplates());
        this.store.dispatch(new DeselectAllProjects());

        if (this.showTemplates) {
            this.updateDisplayedTemplates();
            return;
        }

        this.updateDisplayedProjects();
    }

    updateDisplayedProjects(): void {
        if (this.showArchived) {
            this.projects$ = this.store.pipe(select(selectFilteredProjects));
            return;
        }

        this.projects$ = this.store.pipe(select(selectActiveFilteredProjects));
    }

    updateDisplayedTemplates(): void {
        if (this.showArchived) {
            this.projects$ = this.store.pipe(
                select(selectFilteredUserCreatedProjectTemplates)
            );
            return;
        }
        this.projects$ = this.store.pipe(
            select(selectActiveFilteredUserCreatedProjectTemplates)
        );
    }

    imgDialog(project: Project): void {
        this.dialog
            .open(FileDialogComponent, {
                data: {
                    title: this.translateService.instant(
                        'PROJECT.LIST.FORM.UPLOAD_PROJECT_IMAGE'
                    ),
                    browse: this.translateService.instant(
                        'PROJECT.LIST.FORM.BROWSE_PROJECT_IMAGE'
                    ),
                },
            })
            .afterClosed()
            .subscribe((imgId) => {
                if (imgId && this.updateMode) {
                    const updatedProject: Project = {
                        timeZoneId: project.timeZoneId,
                        id: project.id,
                        photo: imgId,
                        updateDateTime: project.updateDateTime,
                    };
                    this.store.dispatch(
                        new UpdateProjectRequest({ project: updatedProject })
                    );
                    this.store
                        .pipe(
                            untilDestroy(this),
                            select(selectProjectById(this.currentProject.id))
                        )
                        .subscribe(
                            (newProject) => (this.currentProject = newProject)
                        );
                } else if (imgId) {
                    this.setCurrentProjectPhoto(imgId);

                    this.changeDetetorRef.markForCheck();
                }
            });
    }

    resizeGrid(bp: MediaChange): void {
        switch (bp.mqAlias) {
            case 'xl':
                this.cols = 8;
                this.displayedColumns = [
                    'select',
                    'title',
                    'startDate',
                    'dueDate',
                    'creationDate',
                    'edit',
                ];
                break;

            case 'lg':
                this.cols = 6;
                this.displayedColumns = [
                    'select',
                    'title',
                    'startDate',
                    'dueDate',
                    'creationDate',
                    'edit',
                ];
                break;

            case 'md':
                this.cols = 5;
                this.displayedColumns = [
                    'select',
                    'title',
                    'startDate',
                    'dueDate',
                    'creationDate',
                    'edit',
                ];
                break;

            case 'sm':
                this.cols = 4;
                this.displayedColumns = [
                    'select',
                    'title',
                    'creationDate',
                    'edit',
                ];
                break;

            case 'xs':
                this.cols = 2;
                this.displayedColumns = ['select', 'title', 'edit'];
                this.gridView = true;
                break;
        }
        this.changeDetetorRef.markForCheck();
    }

    setGridView(): void {
        this.gridView = true;
    }

    setListView(): void {
        this.gridView = false;
        setTimeout(() => (this.dataSource.paginator = this.paginator));
    }

    setCurrentProjectPhoto(photoId: string): void {
        if (!this.currentProject)
            this.currentProject = {
                timeZoneId: undefined,
            };

        this.currentProject.photo = photoId;
    }

    toggleSelect(projectId: string): void {
        if (this.showTemplates) {
            this.store.dispatch(
                new ToggleSelectProjectTemplate({ templateId: projectId })
            );
            return;
        }

        this.store.dispatch(new ToggleSelectProject({ projectId: projectId }));
    }

    toggleSelectAll(): void {
        if (this.showTemplates) {
            this.store.dispatch(new ToggleSelectAllProjectTemplates());
            return;
        }

        this.store.dispatch(new ToggleSelectAllProjects());
    }
}
