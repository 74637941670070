<div class="w-100-p" fxFlex>
    <mat-label class="w-100-p" fxLayout="row">
        {{ filterItem.labelTranslationIdentifier | translate }}
    </mat-label>
    <div class="w-100-p" fxLayout="row">
        <mat-form-field fxFlex class="mr-4">
            <mat-label> {{ 'APP.FROM' | translate }}</mat-label>
            <input
                #dateFrom
                (dateChange)="updateValue()"
                (focus)="datePickerFrom.open()"
                (click)="datePickerFrom.open()"
                matInput
                [matDatepicker]="datePickerFrom"
                [min]="filterItem.minValue"
                [max]="dateFromMaxValue"
                [(ngModel)]="filterItem.stringValueFrom"
            />
            <mat-datepicker-toggle
                matPrefix
                [for]="datePickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="true" #datePickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex class="ml-4">
            <mat-label> {{ 'APP.TO' | translate }}</mat-label>
            <input
                #dateTo
                (dateChange)="updateValue()"
                (focus)="datePickerTo.open()"
                (click)="datePickerTo.open()"
                matInput
                [matDatepicker]="datePickerTo"
                [min]="dateToMinValue"
                [max]="filterItem.maxValue"
                [(ngModel)]="filterItem.stringValueTo"
            />
            <mat-datepicker-toggle
                matPrefix
                [for]="datePickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="true" #datePickerTo></mat-datepicker>
        </mat-form-field>
    </div>
</div>
