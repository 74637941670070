/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Contact } from './contact';
import { IDBItem } from './iDBItem';


export interface Notification { 
    id?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    type?: Notification.TypeEnum;
    readDateTime?: Date;
    context?: IDBItem;
    contextType?: string;
    reason?: Notification.ReasonEnum;
    link?: string;
    recipient?: Contact;
    notificationContext?: object;
}
export namespace Notification {
    export type TypeEnum = 'Issue' | 'Project' | 'ApplicationUser' | 'Contact' | 'Message' | 'Broadcast' | 'Undefined' | 'InspectionEntry' | 'DailyLogEntry';
    export const TypeEnum = {
        Issue: 'Issue' as TypeEnum,
        Project: 'Project' as TypeEnum,
        ApplicationUser: 'ApplicationUser' as TypeEnum,
        Contact: 'Contact' as TypeEnum,
        Message: 'Message' as TypeEnum,
        Broadcast: 'Broadcast' as TypeEnum,
        Undefined: 'Undefined' as TypeEnum,
        InspectionEntry: 'InspectionEntry' as TypeEnum,
        DailyLogEntry: 'DailyLogEntry' as TypeEnum
    };
    export type ReasonEnum = 'Created' | 'Deleted' | 'Message' | 'Updated' | 'Post' | 'Patch' | 'Finished';
    export const ReasonEnum = {
        Created: 'Created' as ReasonEnum,
        Deleted: 'Deleted' as ReasonEnum,
        Message: 'Message' as ReasonEnum,
        Updated: 'Updated' as ReasonEnum,
        Post: 'Post' as ReasonEnum,
        Patch: 'Patch' as ReasonEnum,
        Finished: 'Finished' as ReasonEnum
    };
}


