import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { NotificationComponent } from './notification/notification.component';
import { NotificationDetailsInnerHtmlPipe } from './pipes/notification-details-inner-html.pipe';
@NgModule({
    imports: [
        SharedModule,
        MatBadgeModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        RouterModule,
        ScrollingModule
    ],
    declarations: [NotificationComponent, NotificationDetailsInnerHtmlPipe],
    providers: [],
    exports: [NotificationComponent]
})
export class NotificationsModule {}
