export enum UpdateCommandErrorCause {
    DOCUMENT_MISSING,
}

export class UpdateCommandError extends Error {
    constructor(
        public query: any,
        public update: any,
        public cause: UpdateCommandErrorCause
    ) {
        super(
            `Failed to perform update '${JSON.stringify(
                update
            )}' on document(s) with query '${JSON.stringify(
                query
            )}', cause: ${UpdateCommandError.causeMessage(cause)}`
        );
    }

    static causeMessage(cause: UpdateCommandErrorCause): string {
        switch (cause) {
            case UpdateCommandErrorCause.DOCUMENT_MISSING:
                return 'the document to update could not be found';
            default:
                return 'unknown';
        }
    }
}
