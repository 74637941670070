import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { select, Store, Action } from '@ngrx/store';
import { flatMap } from 'lodash';
import {
    Issue,
    IssuesService,
    MarkedPlan,
    MarkedPlansService,
    ResponseModelMarkedPlan,
} from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { ErrorUtils } from 'app/core/utils/error-util';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { IssueDbService } from 'app/db/issues/issue.db.service';
import { IssueConflictStorageService } from 'app/db/conflict/conflict-storage.service';
import { defer, forkJoin, from, Observable, of } from 'rxjs';
import {
    catchError,
    concatMap,
    debounceTime,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { HasConflict } from '../../main/models/has-conflict';
import { HasError } from '../../main/models/has-error';
import { SyncService } from '../../main/sync/sync.service';
import { getIsDevice, getIsOnline } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import {
    AddEntityErrors,
    DeleteCommandRequest,
    MarkCommandsInFlightForEntities,
    NavigateToSync,
    StoreCommandRequest,
    SyncAfterOffline,
} from '../sync/sync.actions';
import { IssuesStoreUtilsService } from './issues-store-utils.service';
import {
    CreateIssueCommand,
    CreateIssueConflicts,
    CreateIssuesError,
    CreateIssuesRequest,
    CreateIssuesSuccess,
    CreatePlanCommand,
    IssuesActionTypes,
    LoadIssueCommandsError,
    LoadIssueCommandsRequest,
    LoadIssueCommandsSuccess,
    LoadIssuesError,
    LoadIssuesRequest,
    LoadIssuesSuccess,
    LoadPlanRevisionsRequest,
    LoadPlanRevisionsSuccess,
    LoadRevisionsRequest,
    LoadRevisionsSuccess,
    SyncIssueCommands,
    UpdateIssuesError,
    UpdateIssuesRequest,
    UpdateIssuesSuccess,
    UpsertIssuesFinish,
    UpsertIssuesStart,
    LoadIssueConflicts,
    SetIssueConflicts,
    StoreIssueConflicts,
    UpdateIssueAttachmentIds,
    AddIssueAttachmentMappings,
    ClearIssueAttachmentMap,
    SetIssueIdOfPlanCommandsInDbRequest,
} from './issues.actions';
import { IssuesState } from './issues.reducer';
import {
    getIssueCommands,
    getPlanCommands,
    getIssuesConflicts,
    getIssuesFullyInitialized,
    getIssueAttachmentMap,
} from './issues.selectors';
import { CommandStoreUtils } from '../commands/commands-store-utils';
import { ConflictUtils } from 'app/db/conflict/conflict-utils';
import {
    EntityError,
    IssueEntityError,
    IssueEntityResponse,
    IssueEntitySuccess,
    PlanEntityError,
} from '../sync/entity-response.model';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { IssueToCreate } from './models/issue-to-create';
import { IssueToUpdate } from './models/issue-to-update';
import { PlanToUpdate } from './models/plan-to-update';

@Injectable()
export class IssuesEffects {
    @Effect()
    $upsertIssuesStart = this.actions$.pipe(
        ofType<UpsertIssuesStart>(IssuesActionTypes.UPSERT_ISSUES_START),
        map((action) => action.payload.issues),
        mergeMap((issues) =>
            from(this.issueDb.bulkInsert(issues)).pipe(
                map((results) => new UpsertIssuesFinish({ results }))
            )
        )
    );

    @Effect()
    $upsertIssuesFinish = this.actions$.pipe(
        ofType<UpsertIssuesFinish>(IssuesActionTypes.UPSERT_ISSUES_FINISH),
        map((action) => new SyncAfterOffline())
    );

    @Effect()
    loadCommands$ = this.actions$.pipe(
        ofType<LoadIssueCommandsRequest>(
            IssuesActionTypes.LOAD_ISSUE_COMMANDS_REQUEST
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        mergeMap(([payload, isDevice]) => {
            return !isDevice
                ? of(
                      new LoadIssueCommandsSuccess({
                          commands: [],
                          entityType: payload.entityType,
                      })
                  )
                : from(
                      this.commandDb.getCommandsByEntityType(payload.entityType)
                  ).pipe(
                      map(
                          (commands) =>
                              new LoadIssueCommandsSuccess({
                                  commands,
                                  entityType: payload.entityType,
                              })
                      ),
                      catchError((error) =>
                          of(new LoadIssueCommandsError({ error }))
                      )
                  );
        })
    );

    @Effect()
    loadIssues$ = this.actions$.pipe(
        ofType<LoadIssuesRequest>(IssuesActionTypes.LOAD_ISSUES_REQUEST),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([payload, currentProjectId]) => {
            const { projectId, archived } = payload;
            const server$ = this.issueService.projectsByProjectIdIssuesGet(
                projectId,
                archived
            );
            /**
             * defer() => cold observable
             */
            const db$ = defer(() =>
                from(this.issueDb.getByProjectId(projectId))
            );

            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadIssuesSuccess({
                                issues: response.data as Issue[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) => of(new LoadIssuesError({ error })))
                );
        })
    );

    @Effect({ dispatch: false })
    loadIssuesError$ = this.actions$.pipe(
        ofType<LoadIssuesError>(
            IssuesActionTypes.LOAD_ISSUES_ERROR,
            IssuesActionTypes.LOAD_ISSUE_COMMANDS_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'ISSUES.LOAD');
            return of();
        })
    );

    @Effect()
    loadIssuesSuccess$ = this.actions$.pipe(
        ofType<LoadIssuesSuccess>(IssuesActionTypes.LOAD_ISSUES_SUCCESS),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.issues),
        concatMap((issues) => of(new UpsertIssuesStart({ issues })))
    );

    @Effect()
    createIssues$ = this.actions$.pipe(
        ofType<CreateIssuesRequest>(IssuesActionTypes.CREATE_ISSUES_REQUEST),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([payload, currentProjectId]) => {
            const { issues, plansToUpdate } = payload;
            // TODO, PATCH PLAN REQUEST AFTER SUCCESSFUL CREATION,
            // TODO AND REFETCH ISSUE
            const requests: Observable<IssueEntityResponse>[] = issues.map(
                (entry) => {
                    const { issue, entityId } = entry;
                    return this.issueService
                        .projectsByProjectIdIssuesPost(issue.projectId, issue)
                        .pipe(
                            map((response) => response.data),
                            switchMap((createdIssue) => {
                                // apply in-flight plan update (which could not
                                // be applied earlier, because the issue did not
                                // exist yet)

                                // are there actually any plan updates?
                                const planUpdate = plansToUpdate.find(
                                    (u) => u.issueId === entry.entityId
                                );
                                if (!planUpdate) {
                                    return of(
                                        new IssueEntitySuccess(
                                            entityId,
                                            createdIssue
                                        )
                                    );
                                } else {
                                    // apply plan update
                                    return this.markedPlanService
                                        .projectsByProjectIdIssuesByIssueIdMarkedplanPatch(
                                            planUpdate.projectId,
                                            createdIssue.id,
                                            planUpdate.markedPlan
                                        )
                                        .pipe(
                                            // fetch the new issue with the updated plan, because
                                            // the plans are integrated in the issue
                                            switchMap(() =>
                                                this.issueService
                                                    .projectsByProjectIdIssuesByIssueIdGet(
                                                        createdIssue.projectId,
                                                        createdIssue.id
                                                    )
                                                    .pipe(
                                                        map(
                                                            (response) =>
                                                                new IssueEntitySuccess(
                                                                    entityId,
                                                                    response.data
                                                                )
                                                        ),
                                                        // getting the issue failed
                                                        catchError((error) =>
                                                            of(
                                                                new IssueEntityError(
                                                                    entityId,
                                                                    'update',
                                                                    {
                                                                        ...error,
                                                                    }
                                                                )
                                                            )
                                                        )
                                                    )
                                            ),
                                            // plan patch failed
                                            catchError((error) =>
                                                of(
                                                    new PlanEntityError(
                                                        planUpdate.issueId,
                                                        'update',
                                                        {
                                                            ...error,
                                                        }
                                                    )
                                                )
                                            )
                                        );
                                }
                            }),
                            // prevent errors of each request from introducing
                            // errors in the forkJoin-Observable below
                            catchError((error) =>
                                of(
                                    new IssueEntityError(entityId, 'create', {
                                        ...error,
                                    })
                                )
                            )
                        );
                }
            );

            return forkJoin(requests).pipe(
                mergeMap((responses: IssueEntityResponse[]) => {
                    // tslint:disable-next-line: no-shadowed-variable
                    const issues = responses
                        .filter((r) => r.type === 'success')
                        .map((r) => r as IssueEntitySuccess);
                    const actions = [];
                    if (issues.length > 0) {
                        actions.push(
                            new CreateIssuesSuccess({
                                issues,
                                currentProjectId,
                            })
                        );
                    }

                    const errors = responses
                        .filter((r) => r.type === 'error')
                        .map((e) => e as EntityError);
                    if (errors.length > 0) {
                        actions.push(new CreateIssuesError({ errors }));
                    }

                    const onlyErrors = issues.length === 0 && errors.length > 0;
                    if (onlyErrors) {
                        actions.push(new SyncAfterOffline());
                    }

                    return actions;
                })
            );
        })
    );

    @Effect()
    createIssuesSuccess$ = this.actions$.pipe(
        ofType<CreateIssuesSuccess>(IssuesActionTypes.CREATE_ISSUES_SUCCESS),
        map((action) => action.payload),
        withLatestFrom(
            this.fuseMatchMedia.onMediaChange.pipe(
                map((media) => media === 'xs' || media === 'sm')
            ),
            this.store.pipe(select(getIsDevice))
        ),
        tap(([payload, isMobileViewport, isDevice]) => {
            // currenty navigation = command, whose issue now got created
            // is yes, navigate to new created issue
            let currentlyViewingCreatedIssue = false;
            let id;
            let projectId;
            for (const entry of payload.issues) {
                if (this.router.url.includes(entry.entityId)) {
                    currentlyViewingCreatedIssue = true;
                    id = entry.issue.id;
                    projectId = entry.issue.projectId;
                }
            }
            if (currentlyViewingCreatedIssue) {
                const isDiary = this.router.url.includes('diary');
                const base = ['/projects', projectId];
                const issueId = isMobileViewport ? '' : id;
                if (isDiary) {
                    this.router.navigate([...base, 'diary'], {
                        queryParams: { issueId },
                        queryParamsHandling: 'merge',
                    });
                } else {
                    this.router.navigate([...base, 'issues', issueId]);
                }
            }
        }),
        mergeMap(([payload, isMobileViewport, isDevice]) => {
            const actions: any[] = [];
            // for commands waiting for creation, to retrigger sync
            actions.push(new SyncIssueCommands());
            if (isDevice) {
                // Store new Issue State in DB
                // todo: delete all commands in one go
                for (const issue of payload.issues) {
                    const issueQuery = {
                        entityId: issue.entityId as string,
                        entityType: 'issue',
                    };
                    // todo: need to merge in one db command do delete
                    actions.push(
                        new DeleteCommandRequest({
                            query: issueQuery,
                        })
                    );
                    actions.push(
                        new DeleteCommandRequest({
                            query: {
                                entityType: 'plan',
                                issueId: issue.entityId,
                            },
                        })
                    );
                }
                const issues = payload.issues.map((i) => i.issue);
                actions.push(
                    new UpsertIssuesStart({
                        issues,
                    })
                );
            }

            return actions;
        })
    );

    @Effect()
    updateIssues$ = this.actions$.pipe(
        ofType<UpdateIssuesRequest>(IssuesActionTypes.UPDATE_ISSUES_REQUEST),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([payload, currentProjectId]) => {
            const { updates, projectId, plansToUpdate } = payload;
            const issueUpdateIds = updates.map((u) => u.id);
            const planUpdatesWithoutIssueUpdate = plansToUpdate.filter(
                (u) => !issueUpdateIds.includes(u.issueId)
            );
            // do update requests all in one go
            const planOnlyRequests = planUpdatesWithoutIssueUpdate.map(
                (update) => {
                    return this.markedPlanService
                        .projectsByProjectIdIssuesByIssueIdMarkedplanPatch(
                            update.projectId,
                            update.issueId,
                            update.markedPlan
                        )
                        .pipe(
                            switchMap(() =>
                                this.issueService
                                    .projectsByProjectIdIssuesByIssueIdGet(
                                        update.projectId,
                                        update.issueId
                                    )
                                    .pipe(
                                        map((response) => response.data),
                                        map((issue) => ({
                                            id: issue.id,
                                            changes: issue,
                                        }))
                                    )
                            ),
                            catchError((error) =>
                                of(
                                    new PlanEntityError(
                                        update.issueId,
                                        'update',
                                        {
                                            ...error,
                                        }
                                    )
                                )
                            )
                        );
                }
            );

            const requests = updates.map((update) => {
                const planUpdate = plansToUpdate.find(
                    (u) => u.issueId === update.id
                );
                let planUpdate$: Observable<ResponseModelMarkedPlan> = of(null);
                if (planUpdate) {
                    planUpdate$ = this.markedPlanService.projectsByProjectIdIssuesByIssueIdMarkedplanPatch(
                        planUpdate.projectId,
                        planUpdate.issueId,
                        planUpdate.markedPlan
                    );
                }
                return planUpdate$.pipe(
                    switchMap(() =>
                        this.issueService
                            .projectsByProjectIdIssuesByIssueIdPatch(
                                projectId,
                                update.id.toString(),
                                update.changes
                            )
                            .pipe(
                                map((response) => response.data),
                                map((issue) => ({
                                    id: issue.id,
                                    changes: issue,
                                })),
                                catchError((error) => {
                                    if (error.status === 409) {
                                        return of({
                                            conflict: error.error.data[0],
                                            isConflict: true,
                                        });
                                    }
                                    return of({
                                        error: {
                                            ...error,
                                            entityId: update.id as string,
                                        },
                                        isError: true,
                                    });
                                })
                            )
                    ),
                    // note: inner errors were already caught using the above catchError,
                    //       only planUpdateErrors reach this point
                    // TODO: Handle Plan Conflicts here, these are currently regarded as errors
                    catchError((error) =>
                        of(
                            new PlanEntityError(planUpdate.issueId, 'update', {
                                ...error,
                            })
                        )
                    )
                );
            });
            return forkJoin([...planOnlyRequests, ...requests]).pipe(
                mergeMap((responses: any) => {
                    const actions = [];
                    // tslint:disable-next-line: no-shadowed-variable
                    const updates: Update<Issue>[] = responses.filter(
                        (r) => !r.isConflict && !r.isError
                    );
                    if (updates.length > 0) {
                        actions.push(
                            new UpdateIssuesSuccess({
                                updates,
                                currentProjectId,
                            })
                        );
                    }

                    const haveConflicts: HasConflict<
                        Issue
                    >[] = responses.filter((r) => r.isConflict);
                    const conflicts = haveConflicts.map((c) => c.conflict);
                    if (conflicts.length > 0) {
                        actions.push(new CreateIssueConflicts({ conflicts }));
                    }

                    const haveErrors: HasError[] = responses.filter(
                        (r) => r.isError
                    );
                    const errors = haveErrors.map((e) => {
                        const isPlanEntityError =
                            (e as any).entityType &&
                            (e as any).entityType === 'plan';
                        if (isPlanEntityError) {
                            // this error was already converted into EntityError
                            return e as PlanEntityError;
                        }

                        const innerError = e.error;

                        if (!innerError.entityId) {
                            // note: remove this after adapting this effect to
                            // IssueEntitySuccess etc.
                            console.error('error has no entityId');
                        }

                        return new IssueEntityError(
                            innerError.entityId,
                            'update',
                            innerError
                        );
                    });
                    if (errors.length > 0) {
                        actions.push(new UpdateIssuesError({ errors }));
                    }

                    // there were only conflicts and / or errors, in this case,
                    // we can safely dispatch SyncAfterOffline and don't have
                    // to wait for the offline DB
                    const noUpdates = updates.length === 0;
                    const conflictsOrErrors =
                        conflicts.length > 0 || errors.length > 0;
                    if (noUpdates && conflictsOrErrors) {
                        actions.push(new SyncAfterOffline());
                    }

                    actions.push(new SyncIssueCommands());
                    return actions;
                })
            );
        })
    );

    @Effect()
    updateIssuesSuccess$ = this.actions$.pipe(
        ofType<UpdateIssuesSuccess>(IssuesActionTypes.UPDATE_ISSUES_SUCCESS),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        mergeMap(([payload, isDevice]) => {
            const actions: any[] = [];
            if (isDevice) {
                for (const update of payload.updates) {
                    const query = {
                        entityId: update.id as string,
                        entityType: 'issue',
                    };
                    actions.push(
                        new DeleteCommandRequest({
                            query,
                        })
                    );
                    actions.push(
                        new DeleteCommandRequest({
                            query: {
                                entityType: 'plan',
                                issueId: update.id,
                            },
                        })
                    );
                }
                const changes = payload.updates.map((u) => u.changes);
                actions.push(
                    new UpsertIssuesStart({
                        issues: [...changes],
                    })
                );
            }

            actions.push(new StoreIssueConflicts());

            return actions;
        })
    );

    @Effect()
    loadRevisions$ = this.actions$.pipe(
        ofType<LoadRevisionsRequest>(IssuesActionTypes.LOAD_REVISIONS_REQUEST),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsOnline))),
        filter(([payload, isOnline]) => isOnline),
        map(([payload, isOnline]) => payload),
        switchMap((payload) =>
            this.issueService
                .projectsByProjectIdIssuesByIssueIdRevisionsGet(
                    payload.projectId,
                    payload.issueId
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (revisions) =>
                            new LoadRevisionsSuccess({
                                issueId: payload.issueId,
                                revisions,
                            })
                    )
                )
        )
    );

    @Effect()
    createIssueConflicts$ = this.actions$.pipe(
        ofType<CreateIssueConflicts>(IssuesActionTypes.CREATE_ISSUE_CONFLICTS),
        map((action) => action.payload),
        map(({ conflicts }) => new StoreIssueConflicts())
    );

    @Effect()
    loadIssueConflicts$ = this.actions$.pipe(
        ofType<LoadIssueConflicts>(IssuesActionTypes.LOAD_ISSUE_CONFLICTS),
        switchMap(() => this.issueConflictStorageService.getAll()),
        map(
            (conflicts) =>
                new SetIssueConflicts({
                    conflicts,
                })
        )
    );

    @Effect({ dispatch: false })
    storeIssueConflicts$ = this.actions$.pipe(
        ofType<StoreIssueConflicts>(IssuesActionTypes.STORE_ISSUE_CONFLICTS),
        withLatestFrom(this.store.pipe(select(getIssuesConflicts))),
        mergeMap(([action, conflicts]) =>
            this.issueConflictStorageService.setAll(conflicts)
        )
    );

    @Effect()
    loadPlanRevisions$ = this.actions$.pipe(
        ofType<LoadPlanRevisionsRequest>(
            IssuesActionTypes.LOAD_PLAN_REVISIONS_REQUEST
        ),
        map((action) => action.payload),
        switchMap((payload) =>
            this.markedPlanService
                .projectsByProjectIdIssuesByIssueIdMarkedplanGet(
                    payload.projectId,
                    payload.issueId
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (markedPlan) =>
                            new LoadPlanRevisionsSuccess({
                                issueId: payload.issueId,
                                markedPlan,
                            })
                    )
                )
        )
    );

    @Effect()
    createIssueCommand$ = this.actions$.pipe(
        ofType<CreateIssueCommand>(IssuesActionTypes.CREATE_ISSUE_COMMAND),
        map((action) => action.payload),
        withLatestFrom(
            this.fuseMatchMedia.onMediaChange.pipe(
                map((media) => media === 'xs' || media === 'sm')
            ),
            this.store.pipe(select(getIsDevice)),
            this.store.pipe(select(getIssuesConflicts)),
            this.store.pipe(select(getPlanCommands))
        ),
        tap(
            ([
                payload,
                isMobileViewport,
                isDevice,
                conflicts,
                planCommands,
            ]) => {
                const { command } = payload;
                if (
                    command.action === 'create' &&
                    AcceptUtils.isLocalGuid(command.entityId)
                ) {
                    // after command creation, if it is a create command, redirect to command after creation
                    const isDiary =
                        this.router.url.includes('diary') &&
                        !this.router.url.includes('issues');

                    const projectId = command.projectId;
                    const base = ['/projects', projectId];
                    const issueId = isMobileViewport ? '' : command.entityId;
                    if (isDiary) {
                        this.router.navigate([...base, 'diary'], {
                            queryParams: {
                                issueId,
                                diaryInspectionSelectedTab: 2,
                            },
                            queryParamsHandling: 'merge',
                        });
                    } else {
                        this.router.navigate([...base, 'issues', issueId]);
                    }
                }
            }
        ),
        mergeMap(
            ([
                payload,
                isMobileViewport,
                isDevice,
                conflicts,
                planCommands,
            ]) => {
                let { command } = payload;
                const actions = [];
                if (isDevice) {
                    if (payload.applyConflict) {
                        const query = {
                            entityId: payload.command.entityId,
                            entityType: 'issue',
                        };
                        actions.push(new DeleteCommandRequest({ query }));
                    } else {
                        // note: see reducer for this action about the reason for this
                        // note: currently the `locked` attribute is not saved in the DB
                        //       and reapplied after conflicts have been loaded
                        command = ConflictUtils.lockConflictingCommands(
                            conflicts,
                            [command]
                        )[0];
                    }
                    actions.push(new StoreCommandRequest({ command }));

                    // if a new issue was created the stored plan commands in the DB do not
                    // have an assigned issuedId yet, only in the store
                    if (command.action === 'create') {
                        actions.push(
                            new SetIssueIdOfPlanCommandsInDbRequest({
                                issueId: command.entityId,
                                planCommands: planCommands,
                            })
                        );
                    }
                }
                actions.push(new SyncIssueCommands());
                return actions;
            }
        )
    );

    @Effect()
    createPlanCommand$ = this.actions$.pipe(
        ofType<CreatePlanCommand>(IssuesActionTypes.CREATE_PLAN_COMMAND),
        map((action) => action.payload.command),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        mergeMap(([command, isDevice]) => {
            const actions = [];
            if (isDevice) {
                actions.push(new StoreCommandRequest({ command }));
            }
            actions.push(new SyncIssueCommands());
            return actions;
        })
    );

    @Effect()
    updateIssueAttachmentIds$ = this.actions$.pipe(
        ofType<UpdateIssueAttachmentIds>(
            IssuesActionTypes.UPDATE_ISSUE_ATTACHMENT_IDS
        ),
        withLatestFrom(
            this.store.pipe(select(getIssueAttachmentMap)),
            this.store.pipe(select(getIssueCommands)),
            this.store.pipe(select(getIsDevice))
        ),
        filter(([action, attachmentMap, issueCommands, isDevice]) => isDevice),
        mergeMap(([action, attachmentMap, issueCommands, isDevice]) => {
            return CommandStoreUtils.updateAttachmentIdsInDb({
                commands: issueCommands,
                attachmentMap,
                entityUpdater:
                    IssuesStoreUtilsService.replaceIssueAttachmentIdsInCommandChanges,
                commandDbService: this.commandDb,
                fileStorageService: this.fileStorageService,
                clearAction: new ClearIssueAttachmentMap(),
            });
        })
    );

    @Effect()
    setIssueConflicts$ = this.actions$.pipe(
        ofType<SetIssueConflicts>(IssuesActionTypes.SET_ISSUE_CONFLICTS),
        map(() => new SyncIssueCommands())
    );

    @Effect()
    loadIssueCommandsSuccess$ = this.actions$.pipe(
        ofType<LoadIssueCommandsSuccess>(
            IssuesActionTypes.LOAD_ISSUE_COMMANDS_SUCCESS
        ),
        map(() => new SyncIssueCommands())
    );

    @Effect()
    syncIssueCommands$ = this.actions$.pipe(
        ofType<SyncIssueCommands>(IssuesActionTypes.SYNC_ISSUE_COMMANDS),
        debounceTime(20),
        concatMap((action) => {
            // sequentialize action processing for SyncIssueCommands to
            // prevent multiple effects running concurrently for the same
            // commands, see comments on SyncDiaryCommands for details

            return of(action).pipe(
                withLatestFrom(
                    this.store.pipe(select(getIssueCommands)),
                    this.store.pipe(select(getPlanCommands)),
                    this.store.pipe(select(getIsOnline)),
                    this.store.pipe(select(getIssuesFullyInitialized))
                ),
                filter(
                    ([
                        action,
                        issueCommands,
                        planCommands,
                        isOnline,
                        issuesInitialized,
                    ]) => isOnline && issuesInitialized
                ),
                mergeMap(
                    ([
                        action,
                        issueCommands,
                        planCommands,
                        isOnline,
                        issuesInitialized,
                    ]) => {
                        const noCommandsToSend =
                            issueCommands.length === 0 &&
                            planCommands.length === 0;

                        if (noCommandsToSend) {
                            return [new SyncAfterOffline()];
                        }
                        // TODO: block user from doing anything but switching modes during commandSync after being offline?

                        return from(
                            this.issueStoreUtils.prepareIssueCommands(
                                issueCommands,
                                planCommands
                            )
                        ).pipe(
                            mergeMap((preparedCommands) => {
                                const actions: Action[] = [];

                                actions.push(
                                    IssuesEffects.createMarkCommandsInFlightAction(
                                        preparedCommands.issuesToCreate,
                                        preparedCommands.issuesToUpdate,
                                        preparedCommands.plansToUpdate
                                    )
                                );

                                const {
                                    issuesToCreate,
                                    issuesToUpdate,
                                    plansToUpdate,
                                    attachmentMap,
                                } = preparedCommands;

                                // note: at this point the attachments have been uploaded and
                                //       the prepared commands contain the server/api ids,
                                //       but the ids were only replaced in this commands and not
                                //       in the real commands stored in DB and NgRx which is
                                //       an issue during conflicts where the issue is constructed
                                //       from the non-updated commands
                                actions.push(
                                    new AddIssueAttachmentMappings({
                                        attachmentMap,
                                    })
                                );
                                actions.push(new UpdateIssueAttachmentIds());

                                // plan commands for entities without server id
                                const plansForCreateCommands = plansToUpdate.filter(
                                    (c) => AcceptUtils.isLocalGuid(c.issueId)
                                );
                                // plan commands for issues with server id
                                const plansForUpdateCommands = plansToUpdate.filter(
                                    (c) => !AcceptUtils.isLocalGuid(c.issueId)
                                );
                                // if we have create
                                if (issuesToCreate.length > 0) {
                                    actions.push(
                                        new CreateIssuesRequest({
                                            issues: issuesToCreate,
                                            plansToUpdate: plansForCreateCommands,
                                        })
                                    );
                                }
                                // if we have plan updates and no issue updates
                                if (
                                    plansForUpdateCommands.length > 0 &&
                                    issuesToUpdate.length === 0
                                ) {
                                    const projectIds = [
                                        ...new Set(
                                            plansToUpdate.map(
                                                (u) => u.projectId
                                            )
                                        ),
                                    ];
                                    for (const projectId of projectIds) {
                                        const toUpdateForProject = plansForUpdateCommands.filter(
                                            (u) => u.projectId === projectId
                                        );
                                        actions.push(
                                            new UpdateIssuesRequest({
                                                projectId,
                                                updates: [],
                                                plansToUpdate: toUpdateForProject,
                                            })
                                        );
                                    }
                                } else {
                                    const projectIds = [
                                        ...new Set(
                                            issuesToUpdate.map(
                                                (u) => u.projectId
                                            )
                                        ),
                                    ];
                                    for (const projectId of projectIds) {
                                        const toUpdateForProject = issuesToUpdate.filter(
                                            (u) => u.projectId === projectId
                                        );
                                        const updates = toUpdateForProject.map(
                                            (u) => u.update
                                        );
                                        actions.push(
                                            new UpdateIssuesRequest({
                                                projectId,
                                                updates,
                                                plansToUpdate: plansForUpdateCommands,
                                            })
                                        );
                                    }
                                }

                                return actions;
                            })
                        );
                    }
                )
            );
        })
    );

    @Effect()
    createIssueError$ = this.actions$.pipe(
        ofType<CreateIssuesError>(IssuesActionTypes.CREATE_ISSUES_ERROR),
        switchMap((action) => {
            this.errorUtils.showSingleMessageOrDefault(null, 'ISSUES.CREATE', {
                autohide: false,
                buttonLabelKey: 'SYNC.GO_TO_ERRORS',
                dispatchAction: new NavigateToSync(),
            });
            return of(
                new AddEntityErrors({
                    errors: action.payload.errors,
                })
            );
        })
    );

    @Effect()
    updateIssueError$ = this.actions$.pipe(
        ofType<UpdateIssuesError>(IssuesActionTypes.UPDATE_ISSUES_ERROR),
        switchMap((action) => {
            this.errorUtils.showSingleMessageOrDefault(null, 'ISSUES.UPDATE', {
                autohide: false,
                buttonLabelKey: 'SYNC.GO_TO_ERRORS',
                dispatchAction: new NavigateToSync(),
            });

            return of(
                new AddEntityErrors({
                    errors: action.payload.errors,
                })
            );
        })
    );

    @Effect()
    removeEmptyPlanCommands$ = this.actions$.pipe(
        ofType(IssuesActionTypes.REMOVE_PLAN_COMMANDS),
        withLatestFrom(this.store.select(getIsDevice)),
        filter(([action, device]) => device),
        map(([action, device]) => {
            return new DeleteCommandRequest({
                query: {
                    issueId: { $exists: false },
                    entityType: 'plan',
                },
            });
        })
    );

    constructor(
        private actions$: Actions,
        private fuseMatchMedia: FuseMatchMediaService,
        private issueService: IssuesService,
        private store: Store<IssuesState>,
        private issueStoreUtils: IssuesStoreUtilsService,
        private router: Router,
        private issueDb: IssueDbService,
        private fileStorageService: FileStorageService,
        private commandDb: CommandDbService<Issue>,
        private issueConflictStorageService: IssueConflictStorageService,
        private syncService: SyncService,
        private markedPlanService: MarkedPlansService,
        private errorUtils: ErrorUtils
    ) {}

    private static createMarkCommandsInFlightAction(
        issuesToCreate: IssueToCreate[],
        issuesToUpdate: IssueToUpdate[],
        plansToUpdate: PlanToUpdate[]
    ): Action {
        return new MarkCommandsInFlightForEntities({
            entityIds: [
                ...issuesToCreate.map(
                    (issueToCreate) => issueToCreate.entityId
                ),
                ...issuesToUpdate.map(
                    // this is the entity id, see IssueStoreUtils
                    (issueToUpdate) => {
                        // note: see method with same name in DiaryEffects
                        const id = issueToUpdate.update.id;

                        if (typeof id !== 'string') {
                            throw new Error('non-string id for issue entity');
                        }

                        return id;
                    }
                ),
                ...flatMap(
                    plansToUpdate,
                    (planToUpdate) => planToUpdate.planEntityIds
                ),
            ],
        });
    }
}
