<ng-container *ngIf="!item.hidden && !item.disabled">
    <!-- item.url -->
    <a
        class="nav-link"
        matRipple
        [fxHide.xs]="item.hideXs"
        [fxHide.gt-xs]="item.hideGtXs"
        [ngClass]="item.classes"
        *ngIf="showItem && !(item.hideMobile && isMobile)"
        [routerLink]="routerLink"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
        class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && !item.function"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span
        class="nav-link"
        [ngClass]="item.classes"
        *ngIf="userHasPermissionToSee && !item.url && item.function"
        (click)="item.function(router)"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
        class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function"
        (click)="item.function()"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
        class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && item.function"
        (click)="item.function()"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{
            item.icon
        }}</mat-icon>
        <img *ngIf="item.imagePath" [src]="item.imagePath" />
        <span class="nav-link-title" [translate]="item.translate">{{
            item.title
        }}</span>
        <span
            class="nav-link-badge"
            *ngIf="item.badge"
            [translate]="item.badge.translate"
            [ngStyle]="{
                'background-color': item.badge.bg,
                color: item.badge.fg
            }"
        >
            {{ item.badge.title }}
        </span>
    </ng-template>
</ng-container>

<ng-container *ngIf="!item.hidden && item.disabled">
    <!-- item.url -->
    <a
        class="nav-link"
        [class.disabled]="item.disabled"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{
            item.icon
        }}</mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{
            item.title
        }}</span>
        <span
            class="nav-link-badge"
            *ngIf="item.badge"
            [translate]="item.badge.translate"
            [ngStyle]="{
                'background-color': item.badge.bg,
                color: item.badge.fg
            }"
        >
            {{ item.badge.title }}
        </span>
    </ng-template>
</ng-container>
