
export enum PrintStep {
    LetterSelection,
    EnterBaseAttributes,
    FinalPage,
    // FinalPage: page for export and save, plus special inputs as includeSignature-radio,
    // which have an impact of the specific construction of the template
}

export enum ExpandedFormLetterList {
    AllClosed,
    PredefinedFormLetters,
    FormLetters,
    RTF
}
