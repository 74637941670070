import { environment } from '../../../../environments/environment';

/**
 * The source of CTA-related events.
 */
export type DemoEventSource = 'popup' | 'navigation-menu';

/**
 * Create the "Get Help" CTA URL.
 *
 * @param _eventSource The (currently unused) source of the event.
 */
export function createGetHelpCTAUrlFrom(_eventSource: DemoEventSource): string {
    return environment.getHelpCTAURL;
}

/**
 * Create the "Contact Sales" CTA URL.
 *
 * @param eventSource The (currently unused) source of the event (e.g. popup or navigation menu)
 */
export function createContactSalesCTAUrlFrom(eventSource: DemoEventSource): string {
    // return `${environment.contactSalesCTAUrl}?source=${eventSource}`;
    return environment.contactSalesCTAURL;
}

/**
 * Create the "Request Personal Demo" CTA URL.
 *
 * @param eventSource The (currently unused) source of the event.
 */
export function createRestrictedFunctionalityCTAURL(eventSource: DemoEventSource): string {
    return environment.restrictedFunctionalityCTAURL;
}
