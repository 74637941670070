import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { select, Store } from '@ngrx/store';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { getIsDevice } from 'app/store/core/core.selectors';
import { ProjectsState } from 'app/store/projects/projects.reducer';

@Component({
    selector: 'acc-project-list-toolbar',
    templateUrl: './project-list-toolbar.component.html',
    styleUrls: ['./project-list-toolbar.component.scss'],
})
export class ProjectListToolbarComponent implements OnInit {
    constructor(private store: Store<ProjectsState>) {}

    @Input()
    gridView: boolean;

    @Input()
    selectedProjectsIds: string[];

    @Input()
    showArchived: boolean;

    @Input()
    showTemplates: boolean;

    @Output()
    setListView = new EventEmitter<unknown>();

    @Output()
    setGridView = new EventEmitter<unknown>();

    @Output()
    toggleArchived: EventEmitter<MatSlideToggleChange> = new EventEmitter();

    @Output()
    toggleTemplates: EventEmitter<MatSlideToggleChange> = new EventEmitter();

    @Output()
    openArchiveSelectedProjectsDialog = new EventEmitter<unknown>();

    @Output()
    toggleRefresh = new EventEmitter<unknown>();

    isDevice$ = this.store.pipe(select(getIsDevice));
    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));

    ngOnInit(): void {}

    refreshProjects(): void {}
}
