import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FilterItem } from './models/filter-item.model';

@Component({
    selector: 'acc-generic-filter-menu',
    templateUrl: './generic-filter-menu.component.html',
    styleUrls: ['./generic-filter-menu.component.scss'],
})
export class GenericFilterMenuComponent implements OnInit {
    @Input() filterList: FilterItem[] = [];

    @Output() applyFilters: EventEmitter<{}> = new EventEmitter();
    @Output() clearFilters: EventEmitter<void> = new EventEmitter();

    apply(): void {
        const filter = {};

        for (const item of this.filterList) {
            if (item.key !== undefined && item.value !== undefined)
                filter[item.key] = item.value;
        }

        if (filter !== {}) this.applyFilters.emit(filter);
    }

    resetFilters(): void {
        for (const item of this.filterList) {
            item.value = undefined;
        }
        this.clearFilters.emit();
    }

    constructor() {}

    ngOnInit(): void {}
}
