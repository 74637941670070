/**
 * eTeacher API
 * Last Build: 02/08/2022 10:21:42 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MongoFile } from './mongoFile';
import { TemplateAttribute } from './templateAttribute';

export interface PredefinedFormLetterTemplate {
    id?: string;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    language?: string;
    title?: string;
    description?: string;
    file?: MongoFile;

    headerFile?: MongoFile;
    footerFile?: MongoFile;

    previewFileId?: string;
    purpose?: PredefinedFormLetterTemplate.PurposeEnum;
    /**
     * The key order of `customAttributes` whens serialized to JSON is dependant on ES2015. Note that numeric keys will be first in key order.  The intention is to preserve key order.
     */
    customAttributes?: { [key: string]: TemplateAttribute };
    isPublished?: boolean;

    onlyForCustomer?: string;

    readonly templateType?: string;
}
export namespace PredefinedFormLetterTemplate {
    export type PurposeEnum = 'Issues' | 'DailyLog' | 'InspectionEntryLock';
    export const PurposeEnum = {
        Issues: 'Issues' as PurposeEnum,
        DailyLog: 'DailyLog' as PurposeEnum,
        InspectionEntryLock: 'InspectionEntryLock' as PurposeEnum,
    };
}
