import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { BoilerplatesEffects } from './boilerplates.effects';
import * as fromBoilerplates from './boilerplates.reducers';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        MatSnackBarModule,
        StoreModule.forFeature('boilerplates', fromBoilerplates.reducer),
        EffectsModule.forFeature([BoilerplatesEffects])
    ]
})
export class BoilerplatesStoreModule {}
