import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroy } from '@ngrx-utils/store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from 'app/core/rest-api';
import { AuthState } from 'app/store/auth/auth.reducer';
import { getCurrentUser } from 'app/store/auth/auth.selectors';
import { UpdateContactRequest } from 'app/store/contacts/contacts.actions';
import { getContactById } from 'app/store/contacts/contacts.selectors';
import { getLanguage } from 'app/store/core/core.selectors';
import { environment } from 'environments/environment';
import { concat, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Language } from '../../language-switcher/language.model';
import { FileDialogComponent } from '../file-dialog/file-dialog.component';
import { ResetPasswordRequested } from 'app/store/auth/auth.actions';
import isEqual from 'lodash/isEqual';
import { LanguageSwitcherComponent } from 'app/shared/components/language-switcher/language-switcher.component';
import { DemoService } from 'app/shared/services/demo/demo.service';

@Component({
    selector: 'acc-user-settings-dialog',
    templateUrl: 'user-settings-dialog.component.html',
    styleUrls: ['user-settings-dialog.component.scss'],
})
export class UserSettingsDialogComponent implements OnDestroy, OnInit {
    assetUrl = environment.endpoints.asset;

    contact: Contact;

    form: UntypedFormGroup;
    entryChanges: Partial<Contact>;
    hasEntryChanges = false;
    isFormValid = true;

    languages = ['APP.GERMAN', 'APP.ENGLISH'];

    defaultLanguage = 'APP.GERMAN';

    email: string;
    password: string;

    confirmEmail = false;
    confirmPassword = false;

    language$: Observable<string>;
    email$: Observable<string>;
    unsubscribe$ = new Subject<void>();

    isDemoMode$ = concat(of(false), this.demoService.isDemoMode$);

    @ViewChild('languageSelector') languageSelector: LanguageSwitcherComponent;

    constructor(
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private store: Store<AuthState>,
        private translateService: TranslateService,
        private demoService: DemoService
    ) {
        this.form = this.fb.group({
            photo: [],
            email: [{ value: '', disabled: true }],
            confirmEmail: [],
            password: [],
            confirmPassword: [],
            language: [],
            firstname: '',
            name: '',
            addressStreet: '',
            addressPostCode: '',
            addressState: '',
            addressCity: '',
            phone: '',
            mobilePhone: '',
            fax: '',
            website: '',
        });
    }

    ngOnInit(): void {
        this.language$ = this.store.pipe(select(getLanguage));

        this.email$ = this.store.pipe(
            select(getCurrentUser),
            map((user) => user.email)
        );

        this.store
            .pipe(
                select(getCurrentUser),
                takeUntil(this.unsubscribe$),
                filter((user) => !!user),
                tap((user) => {
                    this.store
                        .pipe(select(getContactById, { id: user.contactId }))
                        .subscribe((contact) => {
                            if (contact) {
                                this.form.patchValue(contact);
                                this.contact = contact;
                            }
                        });
                })
            )
            .subscribe((user) => {
                if (user) {
                    this.email = user.email;
                }
            });

        this.form.valueChanges
            .pipe(untilDestroy(this))
            .subscribe((formChange) => this.onFormChange(formChange));
    }

    languageChangeWasNotSaved(): boolean {
        if (!this.entryChanges || !this.entryChanges['language']) return false;

        return (
            this.contact['language'].toLowerCase() !==
            this.entryChanges['language'].toLowerCase()
        );
    }

    setLanguageFromSavedContact(): void {
        if (!this.contact?.language) return;

        const contactLanguage = environment.languages.find(
            (language) =>
                language.langCode === this.contact.language.toLowerCase()
        );

        if (!contactLanguage) return;

        this.languageSelector.setLanguage(contactLanguage);
    }

    ngOnDestroy(): void {
        if (this.languageChangeWasNotSaved()) {
            this.setLanguageFromSavedContact();
        }

        // do not remove
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    imgDialog(): void {
        this.dialog
            .open(FileDialogComponent, {
                data: {
                    title: this.translateService.instant(
                        'APP.BROWSE_IMG_TITLE'
                    ),
                    browse: this.translateService.instant('APP.BROWSE_IMG'),
                },
            })
            .afterClosed()
            .subscribe((imgId) => {
                if (imgId) {
                    this.form.patchValue({ photo: imgId });
                }
            });
    }

    onFormChange(formChange: any): void {
        const currentChanges: Partial<Contact> = {};
        for (const key in formChange) {
            if (formChange.hasOwnProperty(key)) {
                // TODO: Implement ability to change password here
                if (key === 'password' && formChange['password'] === null) {
                    continue;
                }

                if (key === 'confirmEmail' && !this.form.value.confirmEmail) {
                    continue;
                }

                if (
                    key === 'confirmPassword' &&
                    !this.form.value.confirmPassword
                ) {
                    continue;
                }

                // This if-statement with toLowerCase() is needed because the case of the language
                // stored in the contact is different than the one coming from the backend
                if (key === 'language') {
                    if (
                        formChange[key].toLowerCase() !==
                        this.contact[key].toLowerCase()
                    ) {
                        currentChanges[key] = formChange[key];
                    }
                } else if (formChange[key] !== this.contact[key]) {
                    currentChanges[key] = formChange[key];
                }
            }
        }
        this.entryChanges = currentChanges;
        this.hasEntryChanges = Object.keys(this.entryChanges).length > 0;

        // Email input validation
        this.confirmEmail = !!this.entryChanges.email;
        if (
            (this.entryChanges.email &&
                this.entryChanges.email.trim().length === 0) ||
            this.entryChanges.email !== this.entryChanges['confirmEmail']
        ) {
            this.form.controls['confirmEmail'].setErrors({ incorrect: true });
        } else {
            this.form.controls['confirmEmail'].setErrors(null);
        }

        // Password input validation
        this.confirmPassword = !!this.entryChanges['password'];
        if (
            (this.entryChanges['password'] &&
                this.entryChanges['password'].trim().length === 0) ||
            this.entryChanges['password'] !==
                this.entryChanges['confirmPassword']
        ) {
            this.form.controls['confirmPassword'].setErrors({
                incorrect: true,
            });
        } else {
            this.form.controls['confirmPassword'].setErrors(null);
        }

        this.isFormValid = this.form.valid ? true : false;
    }

    removePasswordPropertiesFromEntryChanges(): void {
        delete this.entryChanges['password'];
        delete this.entryChanges['confirmPassword'];
    }

    updateContact(): void {
        if (this.entryChanges['password']) {
            this.store.dispatch(
                new ResetPasswordRequested({
                    email: this.contact.email,
                    password: this.entryChanges['password'],
                })
            );
        }
        this.removePasswordPropertiesFromEntryChanges();

        const hasNoChanges = isEqual(this.entryChanges, {});

        if (hasNoChanges) return;

        this.store.dispatch(
            new UpdateContactRequest({
                contact: {
                    id: this.contact.id,
                    updateDateTime: this.contact.updateDateTime,
                    ...this.entryChanges,
                } as Contact,
            })
        );
    }

    languageChange(language: Language): void {
        this.form.patchValue({
            language: language['langCode'],
        });
    }
}
