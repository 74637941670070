import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BoilerPlate } from 'app/core/rest-api';
import {
    BoilerplatesActions,
    BoilerplatesActionTypes
} from './boilerplates.actions';

export const adapter: EntityAdapter<BoilerPlate> = createEntityAdapter<
    BoilerPlate
>({
    sortComparer: sortByName
});

export function sortByName(a: BoilerPlate, b: BoilerPlate): number {
    return a.title.localeCompare(b.title);
}

export interface BoilerplatesState extends EntityState<BoilerPlate> {
    loading: boolean;
    loaded: boolean;
    selectedBoilerplatesId: string[];
}

export const initialState: BoilerplatesState = adapter.getInitialState({
    loading: false,
    loaded: false,
    selectedBoilerplatesId: []
});

export function reducer(
    state = initialState,
    action: BoilerplatesActions
): BoilerplatesState {
    switch (action.type) {
        case BoilerplatesActionTypes.LOAD_BOILERPLATES_REQUESTED:
            return { loading: true, loaded: false, ...state };

        case BoilerplatesActionTypes.LOAD_BOILERPLATES_SUCCESS: {
            const { currentProjectId, requestProjectId } = action.payload;
            const forCurrentProject = currentProjectId === requestProjectId;
            if (forCurrentProject) {
                // a request for boilerplates succeeded for the currently open
                // project => set boilerplates to the result of this request
                return adapter.setAll(action.payload.boilerplates, {
                    ...state,
                    selectedBoilerplatesId: [...state.selectedBoilerplatesId],
                    loaded: true
                });
            } else {
                return { ...state, loaded: true };
            }
        }

        case BoilerplatesActionTypes.LOAD_BOILERPLATES_ERROR:
            return { ...state, loading: false };

        case BoilerplatesActionTypes.CREATE_BOILERPLATE_REQUEST:
            return { ...state };

        case BoilerplatesActionTypes.CREATE_BOILERPLATE_SUCCESS:
            return adapter.addOne(action.payload.boilerplate, {
                ...state,
                selectedBoilerplatesId: state.selectedBoilerplatesId.slice()
            });

        case BoilerplatesActionTypes.CREATE_BOILERPLATE_ERROR:
            return { ...state };

        case BoilerplatesActionTypes.UPDATE_BOILERPLATE_REQUEST:
            return { ...state };

        case BoilerplatesActionTypes.UPDATE_BOILERPLATE_SUCCESS:
            return adapter.updateOne(action.payload.update, {
                ...state,
                selectedBoilerplatesId: state.selectedBoilerplatesId.slice()
            });

        case BoilerplatesActionTypes.UPDATE_BOILERPLATE_ERROR:
            return { ...state };

        case BoilerplatesActionTypes.DELETE_BOILERPLATE_REQUEST:
            return { ...state };

        case BoilerplatesActionTypes.DELETE_BOILERPLATE_SUCCESS:
            const removedItems = selectAll(state).filter(
                item =>
                    item.id === action.payload.boilerplate.id ||
                    item.parentID === action.payload.boilerplate.id
            );
            const removedItemsIds = removedItems.map(item => item.id);

            const deleteBoilerplateResult = removeSelectedBoilerPlate(
                state,
                action.payload.boilerplate.id
            );
            return adapter.removeMany([...removedItemsIds], {
                ...state,
                selectedBoilerplatesId: [...deleteBoilerplateResult]
            });

        case BoilerplatesActionTypes.DELETE_BOILERPLATE_ERROR:
            return { ...state };

        case BoilerplatesActionTypes.ADD_SELECTED_CRAFT:
            return {
                ...state,
                selectedBoilerplatesId: [action.payload.boilerplate.id]
            };

        case BoilerplatesActionTypes.REMOVE_SELECTED_CRAFT:
            return {
                ...state,
                selectedBoilerplatesId: []
            };

        case BoilerplatesActionTypes.ADD_SELECTED_BOILERPLATE:
            const parentIndex = state.selectedBoilerplatesId.findIndex(
                item => item === action.payload.boilerplate.parentID
            );

            const addBoilerPlateResult = removeSelectedBoilerplatesAfterIndex(
                state.selectedBoilerplatesId,
                parentIndex
            );

            addBoilerPlateResult.push(action.payload.boilerplate.id);

            return {
                ...state,
                selectedBoilerplatesId: addBoilerPlateResult
            };

        case BoilerplatesActionTypes.REMOVE_SELECTED_BOILERLATE:
            const removeBoilerplateResult = removeSelectedBoilerPlate(
                state,
                action.payload.boilerplate.id
            );

            return {
                ...state,
                selectedBoilerplatesId: [...removeBoilerplateResult]
            };

        default:
            return state;
    }
}

function removeSelectedBoilerPlate(state, boilerplateId): string[] {
    const boilerplateIndex = state.selectedBoilerplatesId.findIndex(
        item => item === boilerplateId
    );

    const removeBoilerPlateResult = removeSelectedBoilerplatesAfterIndex(
        state.selectedBoilerplatesId,
        boilerplateIndex - 1
    );

    return removeBoilerPlateResult;
}

function removeSelectedBoilerplatesAfterIndex(
    selectedBoilerplatesId: string[],
    index: number
): string[] {
    let resultList: string[] = selectedBoilerplatesId.slice();

    if (index >= 0) {
        resultList = selectedBoilerplatesId.filter(
            (value, listIndex) => listIndex <= index
        );
    }

    return resultList;
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
