import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SyncMessage } from '../../main/sync/models/sync-message';
import { getCurrentProjectId } from '../router/router.selectors';
import { SyncState } from './sync.reducer';

export const selectSyncState = createFeatureSelector<SyncState>('sync');

export const getSyncMessages = createSelector(
    selectSyncState,
    state => state.syncMessages
);

export const getDoneSyncs = createSelector(selectSyncState, state =>
    state.syncMessages.filter(m => m.done === true)
);

export const getDoneSyncsByProjectId = createSelector(
    getDoneSyncs,
    (messages, props: { id: string }) =>
        messages.filter(
            (message: SyncMessage) => message.projectId === props.id
        )
);

export const getInSyncToDb = createSelector(
    selectSyncState,
    state => state.inSyncToDb
);

export const getInSyncToServer = createSelector(
    selectSyncState,
    state => state.inSyncToServer
);

export const getIsSyncingToDb = createSelector(getInSyncToDb, toDb => {
    const entityToDb = Object.values(toDb);
    for (const entity of entityToDb) {
        if (entity > 0) {
            return true;
        }
    }
    return false;
});

export const getIsSyncingToServer = createSelector(
    getInSyncToServer,
    toServer => {
        const toServerValues = Object.values(toServer);
        return toServerValues.includes(true) || toServerValues.some(value => typeof value === 'number' && value > 0);
    }
);

export const getIsSyncing = createSelector(
    getIsSyncingToDb,
    getIsSyncingToServer,
    (syncingToDb, syncingToServer) => syncingToDb || syncingToServer
);

export const getDoneSyncsTotal = createSelector(
    getDoneSyncs,
    syncs => syncs.length
);

export const getUndoneSyncs = createSelector(selectSyncState, state =>
    state.syncMessages.filter(m => m.done === false)
);

export const getUndoneSyncsByProjectId = createSelector(
    getUndoneSyncs,
    (messages, props: { id: string }) =>
        messages.filter(
            (message: SyncMessage) => message.projectId === props.id
        )
);

export const getUndoneSyncsTotalByProjectId = createSelector(
    getUndoneSyncsByProjectId,
    undoneSyncs => undoneSyncs.length
);

export const getUndoneSyncsCurrentProject = createSelector(
    getCurrentProjectId,
    getUndoneSyncs,
    (projectId, undoneSyncMessages) =>
        undoneSyncMessages.filter(
            (message: SyncMessage) => message.projectId === projectId
        )
);

export const getDoneSyncsCurrentProject = createSelector(
    getCurrentProjectId,
    getDoneSyncs,
    (projectId, doneSyncMessages) =>
        doneSyncMessages.filter(
            (message: SyncMessage) => message.projectId === projectId
        )
);

export const getUndoneSyncsTotal = createSelector(
    getUndoneSyncs,
    syncs => syncs.length
);

export const getForceOffline = createSelector(
    selectSyncState,
    state => state.forceOffline
);

export const getProjectsToSync = createSelector(
    selectSyncState,
    state => state.projectsToSync
);

export const getIsProjectToSync = createSelector(
    selectSyncState,
    (state, props: { id: string }) => state.projectsToSync.includes(props.id)
);

export const getIsCommandSyncAfterOnlineInProgress = createSelector(
    selectSyncState,
    (state) => state.commandSyncAfterOnlineInProgress,
);
