/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConstructionPlan } from './constructionPlan';
import { Project } from './project';
import { MarkedPlan } from './markedPlan';
import { Revision } from './revision';
import { ChecklistTemplate } from './checklistTemplate';
import { CustomAttribute } from './customAttribute';
import { MongoFile } from './mongoFile';
import { Contact } from './contact';


export interface Issue {
    id?: string;
    title?: string;
    description?: string;
    location?: Array<string>;
    position?: string;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    dueDate?: Date;
    state?: Issue.StateEnum;
    jsonPosition?: object;
    photo?: string;
    markedPlanId?: string;
    markedPlan?: MarkedPlan;
    files?: Array<string>;
    fileInfos?: Array<MongoFile>;
    photos?: Array<string>;
    category?: string;
    revisions?: Array<Revision>;
    bimId?: string;
    creatorId?: string;
    creator?: Contact;
    craft?: number;
    difficulty?: number;
    responsible?: object;
    responsibleInternalId?: string;
    responsibleInternal?: Contact;
    responsibleExternalId?: string;
    responsibleExternal?: Contact;
    specificLocation?: ConstructionPlan;
    projectId?: string;
    project?: Project;
    secondDueDate?: Date;
    boilerPlattes?: Array<string>;
    boilerPlattesAsString?: string;
    comment?: string;
    customAttributes?: Array<CustomAttribute>;
    checklists?: Array<ChecklistTemplate>;
    isUsingFreetextAsBoilerplate?: boolean;
    freetext?: string;
    issueNumber?: number;
}
export namespace Issue {
    export type StateEnum = 'Draft' | 'Open' | 'Accepted' | 'Rejected' | 'DoneWithoutInspection' | 'ExtendedDeadline' | 'FinalDeadline' | 'MailedExtendedDeadline' | 'MailedFinalDeadline' | 'Done' | 'Failed';
    export const StateEnum = {
        Draft: 'Draft' as StateEnum,
        Open: 'Open' as StateEnum,
        Accepted: 'Accepted' as StateEnum,
        Rejected: 'Rejected' as StateEnum,
        DoneWithoutInspection: 'DoneWithoutInspection' as StateEnum,
        ExtendedDeadline: 'ExtendedDeadline' as StateEnum,
        FinalDeadline: 'FinalDeadline' as StateEnum,
        MailedExtendedDeadline: 'MailedExtendedDeadline' as StateEnum,
        MailedFinalDeadline: 'MailedFinalDeadline' as StateEnum,
        Done: 'Done' as StateEnum,
        Failed: 'Failed' as StateEnum
    };
}


