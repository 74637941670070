import { Component, Input, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'acc-empty-info',
    templateUrl: './empty-info.component.html',
    styleUrls: ['./empty-info.component.scss'],
    animations: fuseAnimations,
})
export class EmptyInfoComponent implements OnInit {
    show = true;

    _icons: string[];

    @Input()
    set icon(value: string | string[]) {
        if (typeof value === 'string') {
            this._icons = [value];
        } else {
            this._icons = value;
        }
    }

    @Input()
    translateCode: string;

    @Input()
    small = false;

    @Input()
    center = false;

    constructor() {}

    ngOnInit(): void {}
}
