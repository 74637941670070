<mat-grid-list [cols]="cols" rowHeight="1:1" gutterSize="16" class="mb-56">
    <mat-grid-tile
        class="project-grid-item"
        matRipple
        *ngFor="let project of orderedProjects"
        data-test="project-grid-tile"
    >
        <acc-offline-project-grid-item
            (updated)="updated.next($event)"
            (archived)="archived.next($event)"
            [project]="project"
            [isDevice]="isDevice$ | async"
            [projectsToSync]="projectsToSync$ | async"
            [class.archived]="project.markedAsDelete"
        ></acc-offline-project-grid-item>
    </mat-grid-tile>
</mat-grid-list>
