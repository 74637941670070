import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Configuration for the logout dialog.
 */
export interface LogoutDialogComponentData {
    /**
     * Include a warning, that the logout out may include deleting
     * data, which was not syncronized yet.
     *
     * The default value is undefined (which is used as false by the component).
     */
     showUnsynchronizedWarning?: boolean;
}

/**
 * A dialog prompting the user if they really want to log out. Use it
 * by injecting the `LogoutService` and calling its method
 * `logoutIfUserConfirms` instead of creating it directly.
 */
@Component({
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent implements OnInit {
    showUnsynchronizedWarning: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) data: LogoutDialogComponentData) {
        this.showUnsynchronizedWarning = !!data.showUnsynchronizedWarning;
    }

    ngOnInit(): void {}
}
