import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Contact } from 'app/core/rest-api';
import * as fromContacts from './contacts.reducer';
import { ContactsState } from './contacts.reducer';
import { getSearch } from '../core/core.selectors';

export const selectContactsState = createFeatureSelector<ContactsState>(
    'contacts'
);

export const getAllContacts = createSelector(
    selectContactsState,
    fromContacts.selectAll
);

export const getContactsEntities = createSelector(
    selectContactsState,
    fromContacts.selectEntities
);

export const getContactById = createSelector(
    getContactsEntities,
    (entities: Dictionary<Contact>, props) => entities[props.id]
);

export const getContactsIds = createSelector(
    selectContactsState,
    fromContacts.selectIds
);

export const getContactsLoaded = createSelector(
    selectContactsState,
    (state) => state.loaded
);

export const getAllCompanies = createSelector(getAllContacts, (contacts) =>
    contacts.filter((c) => c.companyId === null)
);

export const getAllPersons = createSelector(getAllContacts, (contacts) =>
    contacts.filter((c) => c.companyId !== null)
);

function filterContactByFullName(
    contact: Contact,
    searchText: string
): boolean {
    if (!searchText && searchText !== '0') {
        return true;
    }
    return contact.fullname?.toLowerCase().includes(searchText.toLowerCase());
}

export const getFilteredCompanies = createSelector(
    getAllCompanies,
    getSearch,
    (contacts, searchText) =>
        contacts.filter((contact) =>
            filterContactByFullName(contact, searchText)
        )
);

export const getFilteredPersons = createSelector(
    getAllPersons,
    getSearch,
    (contacts, searchText) =>
        contacts.filter((contact) =>
            filterContactByFullName(contact, searchText)
        )
);

export const getAllPersonsByCompanyId = createSelector(
    getAllPersons,
    (persons, props) =>
        persons.filter((person) => person.companyId === props.id)
);

export const getContactChangeInProgress = createSelector(
    selectContactsState,
    (state) => {
        const downloading = state.loading;
        const creating = state.currentlyCreating > 0;
        const updating = state.currentlyUpdating > 0;
        const deleting = state.currentlyDeleting > 0;

        return downloading || creating || updating || deleting;
    }
);
