import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/types';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class FuseNavVerticalItemComponent
    implements OnInit, OnChanges, OnDestroy {
    withoutProjectId = false;
    showItem = true;
    userHasPermissionToSee = true;
    isMobile: boolean;

    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    @Input()
    projectId: string;

    @Input()
    currentRole: number;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _platform: Platform,
        public router: Router
    ) {
        this.isMobile = false;
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
    }
    setIsMobile(): void {
        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.setIsMobile();

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.item.url = this.removeLeadingSlash(this.item.url);
        this.withoutProjectId =
            // this.item.url.startsWith('projects') ||
            this.item.url.startsWith('admin') ||
            this.item.url.startsWith('about');
    }

    ngOnChanges(changes: SimpleChanges): void {
        const userHasMinimumRole =
            !this.item.minimumRole || this.item.minimumRole <= this.currentRole;
        this.userHasPermissionToSee = userHasMinimumRole;
        const hideWithoutProject =
            !this.projectId && this.item.hideWithoutProject;

        const hideWithProject = this.projectId && this.item.hideWithProject;

        this.showItem =
            userHasMinimumRole &&
            !hideWithoutProject &&
            !hideWithProject &&
            this.item.url &&
            !this.item.externalUrl &&
            !this.item.function;
    }

    get routerLink(): any[] {
        let routerLink = [];
        const { url, id } = this.item;
        if (this.withoutProjectId) {
            return [url];
        } else {
            if (this.projectId) {
                routerLink = ['/' + 'projects'];
            } else {
                routerLink = ['/' + url];
            }

            if (!this.projectId) {
                return routerLink;
            }
            routerLink = [...routerLink, this.projectId];
            if (id !== 'projects') {
                routerLink = [...routerLink, url];
            }
        }
        return routerLink;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private removeLeadingSlash(url: string): string {
        return this.item.url
            ? this.item?.url[0] === '/'
                ? this.item.url.substring(1)
                : this.item.url
            : '';
    }
}
