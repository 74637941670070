<div fxLayout="row" fxLayoutAlign="center center">
    <app-fulfilling-square-spinner
        *ngIf="spinner"
        [animationDuration]="4000"
        [size]="15"
        [color]="'#ffffff'"
    ></app-fulfilling-square-spinner>

    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>

    <span class="ml-8">{{ message | translate }}</span>
</div>
