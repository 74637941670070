import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'asc-auto-selector-item',
  templateUrl: './auto-selector-item.component.html',
  styleUrls: ['./auto-selector-item.component.scss']
})
export class AutoSelectorItemComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  avatar: string;

  constructor() {}

  ngOnInit() {}
}
