import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as de } from 'app/i18n/de';
import { locale as en } from 'app/i18n/en';
import { locale as ptBr } from 'app/i18n/pt-br';
import { SyncService } from 'app/main/sync/sync.service';
import { locale as navigationGerman } from 'app/navigation/i18n/de';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationBrazilianPortuguese } from 'app/navigation/i18n/pt-br';
import { LoginByToken } from 'app/store/auth/auth.actions';
import { SetLanguage } from 'app/store/core/core.actions';
import { CoreState } from 'app/store/core/core.reducer';
import { SetForceOffline } from 'app/store/sync/sync.actions';
import { environment } from 'environments/environment';
import { DeviceService } from './device.service';

import { LoadDiaryConflicts } from './../../store/diary/diary.actions';
import { LoadIssueConflicts } from './../../store/issues/issues.actions';

@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        private deviceService: DeviceService,
        private store: Store<CoreState>,
        private translate: TranslateService,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private syncService: SyncService
    ) {}

    async init(): Promise<void> {
        // initializes local database
        await this.deviceService.init();
        await Promise.all([
            this.initTranslateService(),
            this.initLanguage(),
            this.initForceOffline(),
            this.loginIfToken(),
            this.initConflicts(),
        ]);

        await this.syncService.init();
    }

    async initTranslateService(): Promise<void> {
        // Add languages
        this.translate.addLangs(['en', 'de', 'br']);

        // Set the default language
        this.translate.setDefaultLang(environment.defaults.language);

        // Set the navigation translations
        this.fuseTranslationLoaderService.loadTranslations(
            // NOTE: if you add a new navigation language, also add
            // it to the navigation.service.ts!
            navigationEnglish,
            navigationGerman,
            navigationBrazilianPortuguese,
            de,
            en,
            ptBr
        );
        return;
    }

    async initLanguage(): Promise<string> {
        const languageString = await Preferences.get({
            key: 'language',
        });
        const language = languageString.value || environment.defaults.language;
        this.store.dispatch(new SetLanguage({ language }));
        return language;
    }

    async initForceOffline(): Promise<boolean> {
        const forceOfflineString = await Preferences.get({
            key: 'forceOffline',
        });
        const forceOffline = forceOfflineString.value === 'true' || false;
        this.store.dispatch(new SetForceOffline({ forceOffline }));
        return forceOffline;
    }

    async loginIfToken(): Promise<void> {
        const token = await Preferences.get({ key: 'token' });
        if (token.value) {
            this.store.dispatch(new LoginByToken({ token: token.value }));
        }
    }

    async initConflicts(): Promise<void> {
        this.store.dispatch(new LoadIssueConflicts());
        this.store.dispatch(new LoadDiaryConflicts());
    }
}
