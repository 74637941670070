import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import { ProjectListModule } from './project-list/project-list.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [SharedModule, ProjectListModule, MatIconModule, MatMenuModule],
    exports: [],
    declarations: [],
    providers: [],
})
export class ProjectsModule {}
