import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { EntityAttachmentMap } from 'app/store/issues/models/entity-attachment-map';
import { FilesService } from 'app/core/rest-api';

@Injectable({ providedIn: 'root' })
export class AttachmentUploadFromDbService {
    constructor(
        private fileStorageService: FileStorageService,
        private filesService: FilesService
    ) {}

    async uploadAttachmentsAndReplaceLocalIds(
        entityAttachmentMap: EntityAttachmentMap
    ): Promise<EntityAttachmentMap> {
        const uploadRequests: Promise<{
            entityId: string;
            localId: string;
            apiId: string;
        }>[] = [];
        for (const [entityId, attachmentMap] of entityAttachmentMap) {
            for (const [localId, apiId] of attachmentMap) {
                const attachment = await this.fileStorageService.loadAttachment(
                    localId
                );

                if (!attachment) {
                    console.error(
                        'NO ATTACHMENT STORED FOR COMMAND',
                        'localId',
                        localId
                    );
                }

                uploadRequests.push(
                    this.uploadLocalAttachment(entityId, localId, attachment)
                );
            }
        }

        const results = await Promise.all(uploadRequests);

        for (const [entityId, attachmentMap] of entityAttachmentMap) {
            const uploadsForEntity = results.filter(
                (r) => r.entityId === entityId
            );
            for (const upload of uploadsForEntity) {
                attachmentMap.set(upload.localId, upload.apiId);
            }
        }

        // what does this comment mean?
        // here is a good place for that.

        return entityAttachmentMap;
    }

    /**
     * upload attachmen to api
     * return generated api id
     * @param data
     */
    async uploadLocalAttachment(
        entityId: string,
        localId: string,
        data: Blob
    ): Promise<{ entityId: string; localId: string; apiId: string }> {
        return this.filesService
            .filesDirectPost(data)
            .pipe(
                map((response) => response[0].id),
                map((apiId) => {
                    return {
                        entityId,
                        localId,
                        apiId,
                    };
                })
            )
            .toPromise();
    }
}
