import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'app/store/auth/auth.reducer';
import { getMaxRoleCurrentProject } from 'app/store/auth/auth.selectors';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProjectManagerGuard implements CanActivate {
    private PM_ROLE = environment.roleNumbers.PROJECT_MANAGER;
    constructor(private store: Store<AuthState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(getMaxRoleCurrentProject),
            map(maxRole => maxRole >= this.PM_ROLE)
        );
    }
}
