import { Component } from '@angular/core';

@Component({
    selector: 'acc-empty-banner',
    templateUrl: 'empty-banner.component.html',
    styleUrls: ['empty-banner.component.scss']
})

export class EmptyBannerComponent {

}
