import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { CoreState } from 'app/store/core/core.reducer';
import { getLanguage } from 'app/store/core/core.selectors';
import {
    getCurrentProject,
    selectAllActiveProjects
} from 'app/store/projects/projects.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'acc-banner',
    templateUrl: 'banner.component.html',
    styleUrls: ['banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent implements OnInit, OnDestroy {
    projects$ = this.store.pipe(select(selectAllActiveProjects));
    currentProject$: Observable<Project>;
    currentProjectId: string;
    language$ = this.store.pipe(select(getLanguage));
    unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private store: Store<CoreState>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentProject$ = this.store.pipe(
            select(getCurrentProject),
            takeUntil(this.unsubscribe$),
            tap(currentProject => {
                this.currentProjectId = currentProject && currentProject.id;
            })
        );
    }

    switchToProject(project: Project): void {
        const locationArray = window.location.pathname.split('/');
        // e.g. /projects/{id}/  => issues
        const section = locationArray[3] || 'sync';
        const routerLink = ['/projects', project.id, section];
        this.router.navigate(routerLink);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
