import { AuthGuard } from 'app/core/auth/auth.guard';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeBr from '@angular/common/locales/br';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import 'hammerjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { ApiModule, Configuration } from './core/rest-api';
import { InitService } from './core/services/init.service';
import { MatPaginatorI18nService } from './core/services/mat-paginator-i18n.service';
import { ProjectsModule } from './main/projects/projects.module';
import { AuthStoreModule } from './store/auth/auth-store.module';
import { BoilerplatesStoreModule } from './store/boilerplates/boilerplates-store.module';
import { ContactsStoreModule } from './store/contacts/contacts-store.module';
import { CoreStoreModule } from './store/core/core-store.module';
import { CraftsStoreModule } from './store/crafts/crafts-store.module';
import { DiaryStoreModule } from './store/diary/diary-store.module';
import { IssuesStoreModule } from './store/issues/issues-store.module';
import { LettersStoreModule } from './store/letters/letters-store.module';
import { LocationsStoreModule } from './store/locations/locations-store.module';
import { NotificationsStoreModule } from './store/notifications/notifications-store.module';
import { ProjectsStoreModule } from './store/projects/projects-store.module';
import { SettingsStoreModule } from './store/settings/settings-store.module';
import { SyncStoreModule } from './store/sync/sync-store.module';
import { ChecklistStoreModule } from './store/checklists/checklists-store.module';
import { ConflictEffectsModule } from './store/conflict/conflict-effects.module';
import { ProjectTemplatesStoreModule } from './store/project-templates/project-templates-store.module';
import { ATTACHMENT_PROVIDERS } from './shared/services/attachment-storage/attachment-configuration';
import { ActivitiesModule } from './layout/components/activities/activities.module';
import { DemoInterceptor } from './shared/services/demo/demo.interceptor';
import { GlobalBoilerplatesStoreModule } from './store/global-bolierplates/global-boilerplates-store.module';
import { ExternalContactsStoreModule } from './store/external-contacts/external-contacts-store.module';

registerLocaleData(localeDe);
registerLocaleData(localeBr);

export function apiConfigFactory(): Configuration {
    return new Configuration({
        apiKeys: {},
        basePath: environment.endpoints.api,
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatMomentDateModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        LayoutModule,
        ApiModule.forRoot(apiConfigFactory),
        CoreStoreModule,
        AppRoutingModule,
        ProjectsModule,
        ActivitiesModule,
        // Store Modules
        ProjectsStoreModule,
        ProjectTemplatesStoreModule,
        LocationsStoreModule,
        BoilerplatesStoreModule,
        GlobalBoilerplatesStoreModule,
        LettersStoreModule,
        IssuesStoreModule,
        DiaryStoreModule,
        SettingsStoreModule,
        ContactsStoreModule,
        ExternalContactsStoreModule,
        AuthStoreModule,
        CraftsStoreModule,
        NotificationsStoreModule,
        SyncStoreModule,
        ChecklistStoreModule,
        // Effects Modules (without their own state)
        ConflictEffectsModule,
    ],
    providers: [
        AuthGuard,
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (initService: InitService) => () => initService.init(),
            deps: [InitService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: environment.defaults.locale,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DemoInterceptor,
            multi: true,
        },
        ...ATTACHMENT_PROVIDERS,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
