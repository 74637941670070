import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SetLanguage } from 'app/store/core/core.actions';
import { CoreState } from 'app/store/core/core.reducer';
import { environment } from 'environments/environment';
import { Language } from './language.model';

@Component({
    selector: 'acc-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit, OnChanges {
    @Input()
    labels = true;

    @Input()
    language: string;

    @Output()
    languageChange = new EventEmitter<Language>();

    languages: Language[] = environment.languages;
    langObj: Language;

    constructor(private store: Store<CoreState>) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        this.langObj = this.getLanguageByCode(this.language);
        this.languageChange.emit(this.langObj);
    }

    setLanguage(lang: Language): void {
        this.store.dispatch(new SetLanguage({ language: lang.code }));
    }

    getLanguageByCode(code: string): Language {
        return this.languages.find(lang => lang.code === code);
    }
}
