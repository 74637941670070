import { Component, OnInit, Input } from '@angular/core';
import { FilterByDate } from './filter-by-date.model';

@Component({
    selector: 'acc-filter-by-date',
    templateUrl: './filter-by-date.component.html',
    styleUrls: ['./filter-by-date.component.scss']
})
export class FilterByDateComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() filterItem: FilterByDate;
}
