import { locale as DE_Locale } from './de';

export const locale: typeof DE_Locale = {
    lang: 'en',
    data: {
        NAV: {
            ORDER: {
                TITLE: 'Contact sales!',
            },
            PROJECTS: {
                TITLE: 'Projects',
            },
            ISSUES: {
                TITLE: 'Tickets',
            },
            DIARY: {
                TITLE: 'Construction Log',
            },
            ARCHIVE: {
                TITLE: 'Archive',
            },
            BUILDINGS: {
                TITLE: 'Buildings',
            },
            USERS: {
                TITLE: 'Participants',
            },
            SETTINGS: {
                TITLE: 'Settings',
            },
            SYNC: {
                TITLE: 'Synchronization',
            },
            BACKLINK: {
                TITLE: 'untermStrich',
            },
            SHARED_BOILERPLATES: 'Shared Boilerplates',
            GLOBAL_SETTINGS: {
                ADDRESS_BOOK: {
                    TITLE: 'Address Book',
                },
                COMPANY_LOGO: {
                    TITLE: 'Company Logo',
                },
                TEXT_COMPONENTS: {
                    TITLE: 'Text Components',
                },
                ISSUE_CATEGORIES: {
                    TITLE: 'Ticket Categories',
                },
            },
            ABOUT: {
                TITLE: 'About',
            },
            HELP: {
                TITLE: 'Need help?',
            },
        },
    },
};
