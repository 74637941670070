<div *ngIf="selectedLetter.templateType!=='RTFTemplate'" fxLayout="column">
    <ng-container [formGroup]="printForm">
        <acc-additional-form-letter-settings
            [form]="printForm"
            [template]='selectedLetter'
        >
        </acc-additional-form-letter-settings>
        <div>
            <div>
                <mat-label class="print-dialog-label" translate>APP.FORM_LETTER_DIALOG.SAVE_INPUT_AS_TEMPLATE</mat-label>
                <mat-form-field class="w-328 ">
                    <mat-label translate>APP.TITLE</mat-label>
                    <input formControlName="customTemplateTitle" matInput />
                </mat-form-field>
            </div>
            <div  fxLayout="row" fxLayoutAlign="start center">
                <button mat-flat-button
                        type="button"
                        (click)="saveAsCustomTemplate()"
                        class="saveTemplateButton"
                >
                    <span translate>APP.SAVE</span>
                </button>
                <div *ngIf="showSaveTemplateSuccess" fxLayoutAlign="start center">
                    <mat-icon class="mr-8 ml-16 ">check</mat-icon>
                    <span translate>APP.FORM_LETTER_DIALOG.SUCCESS_CREATE_LETTER_TEMPLATE</span>
                </div>
            </div>
        </div>
    </ng-container>

</div>

<div *ngIf="selectedLetter.templateType==='RTFTemplate'" >
    <div  >
        <h3 class="print-dialog-label" translate>APP.FORM_LETTER_DIALOG.FORM_LETTER_READY_FOR_DOWNLOAD</h3>
    </div>
</div>