import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { ProjectsEffects } from './projects.effects';
import * as fromProjects from './projects.reducer';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature('projects', fromProjects.reducer),
        EffectsModule.forFeature([ProjectsEffects])
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProjectsStoreModule {}
