import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectRole } from 'app/core/rest-api';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { ProjectRoleDocType } from './project-roles.document';

@Injectable({
    providedIn: 'root',
})
export class ProjectRolesDbService extends EntityDbService<
    ProjectRole,
    ProjectRoleDocType
> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.ProjectRoles
        );
    }

    getAttachmentIdsFromApiItem(projectRole: ProjectRole): string[] {
        return [];
    }

    apiItemToSchema(projectRole: ProjectRole): ProjectRoleDocType {
        const {
            id,
            creationDate,
            markedAsDelete,
            updateDateTime,
            projectId,
            roles,
            craft,
            internalResponsible,
            externalResponsible,
            fourEyesPrinciple,
        } = projectRole;
        return {
            id,
            creationDate,
            markedAsDelete,
            updateDateTime,
            projectId,
            roles,
            craft,
            internalResponsible,
            externalResponsible,
            fourEyesPrinciple,
        };
    }
}
