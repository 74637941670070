import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
} from '@angular/material/snack-bar';
import { PluginListenerHandle } from '@capacitor/core';
import {
    PushNotificationSchema,
    ActionPerformed,
    Token,
    PushNotifications,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { select, Store } from '@ngrx/store';
import { DBAdapter, DBAdapters, initDatabase } from 'app/db/database.service';
import { SnackbarData } from 'app/shared/components/snackbar/snackbar-data';
import { SnackbarComponent } from 'app/shared/components/snackbar/snackbar.component';
import { SetIsDevice, SetNetworkStatus } from 'app/store/core/core.actions';
import { CoreState } from 'app/store/core/core.reducer';
import { getIsOffline, getIsOnline } from 'app/store/core/core.selectors';
import { getIsSyncingToDb } from 'app/store/sync/sync.selectors';
import { isString } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContactsService } from '../rest-api';
import { SetCommandSyncAfterOnlineInProgress } from 'app/store/sync/sync.actions';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { FileMetadataStorage } from 'app/shared/services/attachment-storage/file-metadata-storage';
import { AttachmentGarbageCollectorService } from 'app/shared/services/attachment-storage/attachment-garbage-collector.service';


@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    private develop = {
        SIMULATE_DEVICE: false,
        DELETE_DB_ON_INIT: false,
    };
    private renderer: Renderer2;
    private networkHandler: PluginListenerHandle;
    private offlineSnackbar: MatSnackBarRef<SnackbarComponent>;
    private syncSnackbar: MatSnackBarRef<SnackbarComponent>;

    pushNotifications$ = new Subject<ActionPerformed>();

    private possibleBodyClasses = [
        'black-bg',
        'indigo-700-bg',
        'fuse-white-bg',
        'issue-state-bg--Draft',
        'issue-state-bg--Open',
        'issue-state-bg--Accepted',
        'issue-state-bg--Rejected',
        'issue-state-bg--DoneWithoutInspection',
        'issue-state-bg--ExtendedDeadline',
        'issue-state-bg--FinalDeadline',
        'issue-state-bg--MailedExtendedDeadline',
        'issue-state-bg--MailedFinalDeadline',
        'issue-state-bg--Done',
        'issue-state-bg--Failed',
    ];

    constructor(
        rendererFactory: RendererFactory2,
        private contactsService: ContactsService,
        private store: Store<CoreState>,
        private snackbar: MatSnackBar,
        private fileStorageService: FileStorageService,
        private fileMetadataStorage: FileMetadataStorage,
        private attachmentGarbageCollectorService: AttachmentGarbageCollectorService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    async init(): Promise<void> {
        const isDevice = await this.isDevice();
        this.store.dispatch(new SetIsDevice({ isDevice }));

        const notSimulatingDevice = !this.develop.SIMULATE_DEVICE;

        if (isDevice) {
            const adapter: DBAdapter = DBAdapters.idb;

            if (notSimulatingDevice) {
                // note: explicitly not waiting until push notification initialization
                //       has finished, because they are standalone
                this.initPushNotifications().catch((error) => {
                    console.error(
                        'failed to initialize push notifications, they will not be available: ' +
                            JSON.stringify(error)
                    );
                });
            }

            this.showSnackbarWhenOffline();
            this.showSnackbarWhenSyncingToDb();
            this.initSyncAfterOffline();
            await initDatabase(this.develop.DELETE_DB_ON_INIT, adapter);
            await this.fileMetadataStorage.init();
            await this.fileStorageService.init();
            await this.enablePlugins();
            await this.initNetworkHandler();

            // explicitly not waiting for completion
            this.attachmentGarbageCollectorService.garbageCollectAttachments();
        }
    }

    async enablePlugins(): Promise<void> {
        const { platform } = await Device.getInfo();
        if (platform === 'ios') {
            // Capacitor.Plugins.IosSwipeBack.enable();
        }
    }

    async initNetworkHandler(): Promise<void> {
        const currentStatus = await Network.getStatus();
        this.store.dispatch(new SetNetworkStatus({ status: currentStatus }));
        this.networkHandler = Network.addListener(
            'networkStatusChange',
            (status) => {
                this.store.dispatch(new SetNetworkStatus({ status }));
            }
        );
    }

    showSnackbarWhenOffline(): void {
        const data = {
            message: 'Offline',
            icon: 'offline_bolt',
        };
        const config: MatSnackBarConfig<SnackbarData> = {
            data,
            panelClass: 'fuse-white-fg',
        };
        this.showSnackbarIfTrue(
            this.store.pipe(select(getIsOffline)),
            this.offlineSnackbar,
            config
        ).subscribe();
    }

    showSnackbarWhenSyncingToDb(): void {
        const data = { message: 'APP.SYNCING', spinner: true };
        const config: MatSnackBarConfig<SnackbarData> = {
            data,
            panelClass: 'mat-success',
        };
        this.showSnackbarIfTrue(
            this.store.pipe(select(getIsSyncingToDb)),
            this.syncSnackbar,
            config
        ).subscribe();
    }

    showSnackbarIfTrue(
        condition$: Observable<boolean>,
        snackbarRef: MatSnackBarRef<SnackbarComponent>,
        config: MatSnackBarConfig<SnackbarData>
    ): Observable<boolean> {
        // merge panelClass of config with root css class of snackbarcomponent
        let panelClass = ['acc-snackbar'];
        if (config.panelClass) {
            if (Array.isArray(config.panelClass)) {
                panelClass = [...panelClass, ...config.panelClass];
            }
            if (isString(config.panelClass)) {
                panelClass = [...panelClass, config.panelClass];
            }
        }
        if (!config.horizontalPosition) {
            config.horizontalPosition = 'left';
        }
        config.panelClass = panelClass;

        return condition$.pipe(
            tap((isTrue) => {
                if (!isTrue) {
                    if (snackbarRef) {
                        snackbarRef.dismiss();
                        snackbarRef = null;
                    }
                } else {
                    if (!snackbarRef) {
                        snackbarRef = this.snackbar.openFromComponent(
                            SnackbarComponent,
                            config
                        );
                    }
                }
            })
        );
    }

    initSyncAfterOffline(): void {
        this.store
            .pipe(
                select(getIsOnline),
                tap((online) => {
                    this.store.dispatch(
                        new SetCommandSyncAfterOnlineInProgress({
                            inProgress: online,
                        })
                    );
                })
            )
            .subscribe();
    }

    async initPushNotifications(): Promise<void> {
        PushNotifications.addListener(
            'registration',
            (fcmToken: Token) => {
                this.contactsService
                    .contactsFcmPost(`"${fcmToken.value}"`)
                    .subscribe();
            }
        );

        PushNotifications.addListener('registrationError', (error: any) => {
            console.error('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {}
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (pushNotification: ActionPerformed) => {
                this.pushNotifications$.next(pushNotification);
            }
        );

        const notificationPermissionResponse = await PushNotifications.requestPermissions();

        if (notificationPermissionResponse.receive !== 'granted') {
            console.warn(
                'user denied push notifications, they will not be available'
            );
            return;
        }

        await PushNotifications.register();
    }

    async isDevice(): Promise<boolean> {
        if (this.develop.SIMULATE_DEVICE) {
            return true;
        }
        const { platform } = await Device.getInfo();
        return platform === 'ios' || platform === 'android';
    }

    /**
     * sets the body class of the body element
     * needed for iPhone X Notch background color
     * @param className
     */
    setBodyClass(className: string): void {
        this.removeBodyClasses();
        this.renderer.addClass(document.body, className);
    }

    removeBodyClasses(): void {
        for (const bodyClass of this.possibleBodyClasses) {
            this.renderer.removeClass(document.body, bodyClass);
        }
    }
}
