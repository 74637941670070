import { TimeZoneShortInfo } from '../../core/rest-api/model/timeZoneShortInfo';

export class Timezone implements TimeZoneShortInfo {
    public title?: string;

    constructor(
        public id?: string,
        public displayName?: string,
        public standardName?: string
    ) {
        this.title = '(' + this.displayName + ') ' + this.id;
    }
}
