<div class="formletter-selection" >
    <mat-accordion multi="false">

        <mat-expansion-panel [expanded]="(currentExpandedList === expandedFormLetterList.PredefinedFormLetters)" >
            <mat-expansion-panel-header (click)="toggleExpandedList(expandedFormLetterList.PredefinedFormLetters)">
                <p translate>APP.FORM_LETTER_DIALOG.PREDEFINED_TEMPLATES</p>
            </mat-expansion-panel-header>
            <acc-print-dialog-list
                [purpose]='purpose'
                [templateType]="'PredefinedFormLetterTemplate'"
            ></acc-print-dialog-list>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="(currentExpandedList === expandedFormLetterList.FormLetters)" >
            <mat-expansion-panel-header (click)="toggleExpandedList(expandedFormLetterList.FormLetters)">
                <p translate>APP.FORM_LETTER_DIALOG.CUSTOM_TEMPLATES</p>
            </mat-expansion-panel-header>
            <acc-print-dialog-list
                [purpose]='purpose'
                [templateType]="'FormLetterTemplate'"
            ></acc-print-dialog-list>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="(currentExpandedList === expandedFormLetterList.RTF)" >
            <mat-expansion-panel-header (click)="toggleExpandedList(expandedFormLetterList.RTF)">
                <p translate>APP.FORM_LETTER_DIALOG.RTF_TEMPLATES</p>
            </mat-expansion-panel-header>
            <acc-print-dialog-list
                [purpose]='purpose'
                [templateType]="'RTFTemplate'"
            ></acc-print-dialog-list>
        </mat-expansion-panel>

    </mat-accordion>
</div>