<button mat-button="mat-button" [matMenuTriggerFor]="langMenu">
    <img class="mr-8" [src]="langObj.flag" /><span *ngIf="labels">{{
        langObj.translateCode | translate
    }}</span>
</button>
<mat-menu #langMenu="matMenu">
    <button
        mat-menu-item="mat-menu-item"
        *ngFor="let lang of languages"
        (click)="setLanguage(lang)"
    >
        <div fxLayout="row" fxLayoutAlign="begin center">
            <img class="mr-8" [src]="lang.flag" /><span *ngIf="labels">{{
                lang.translateCode | translate
            }}</span>
        </div>
    </button>
</mat-menu>
