import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { createGetHelpCTAUrlFrom } from '../cta-links';

/**
 * This popup dialog is shown in demo mode after the first login
 * and contains crucial information as well as first CTAs.
 */
@Component({
    selector: 'acc-demo-popup',
    templateUrl: './demo-popup.component.html',
    styleUrls: ['./demo-popup.component.scss'],
})
export class DemoPopupComponent implements OnInit {
    getHelpCTAUrl = createGetHelpCTAUrlFrom('popup');

    constructor(
        private dialogRef: MatDialogRef<DemoPopupComponent>
    ) {}

    ngOnInit(): void {}

    onConfirm(): void {
        this.dialogRef.close();
    }
}
