import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot
} from '@angular/router';
import { NavigationService } from 'app/navigation/navigation.service';

@Injectable({ providedIn: 'root' })
export class AdminSetNavigationGuard implements CanActivate {
    constructor(private navigationService: NavigationService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        this.navigationService.setNavigation('admin');
        return true;
    }
}
