import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProjectSettingsService } from 'app/core/rest-api';
import { Location as FlinkLocation } from 'app/core/rest-api/model/location';
import { LocationDbService } from 'app/db/locations/location.db.service';
import { defer, from, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { SyncService } from '../../main/sync/sync.service';
import { getIsDevice } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import {
    CreateLocationError,
    CreateLocationRequest,
    CreateLocationSuccess,
    DeleteLocationError,
    DeleteLocationRequested,
    DeleteLocationSuccess,
    LoadLocationsError,
    LoadLocationsRequested,
    LoadLocationsSuccess,
    LocationsActionTypes,
    UpdateLocationError,
    UpdateLocationRequested,
    UpdateLocationSuccess,
    UpsertLocationsFinish,
    UpsertLocationsStart,
} from './locations.actions';
import { LocationsState } from './locations.reducers';
import { ErrorUtils } from 'app/core/utils/error-util';

@Injectable()
export class LocationsEffects {
    @Effect()
    $upsertLocationsStart = this.actions$.pipe(
        ofType<UpsertLocationsStart>(
            LocationsActionTypes.UPSERT_LOCATIONS_START
        ),
        map((action) => action.payload.locations),
        mergeMap((locations) =>
            from(this.locationDb.bulkInsert(locations)).pipe(
                map((results) => new UpsertLocationsFinish({ results }))
            )
        )
    );

    @Effect()
    loadLocations$ = this.actions$.pipe(
        ofType<LoadLocationsRequested>(
            LocationsActionTypes.LOAD_LOCATIONS_REQUESTED
        ),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([action, currentProjectId]) => {
            const { projectId } = action.payload;
            const server$ = this.projectSettingsService.projectsByProjectIdSettingsLocationGet(
                projectId
            );
            const db$ = defer(() =>
                from(this.locationDb.getByProjectId(projectId))
            );

            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadLocationsSuccess({
                                locations: response.data as FlinkLocation[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) => of(new LoadLocationsError({ error })))
                );
        })
    );

    @Effect()
    successLoadLocations$ = this.actions$.pipe(
        ofType<LoadLocationsSuccess>(
            LocationsActionTypes.LOAD_LOCATIONS_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.locations),
        switchMap((locations) => of(new UpsertLocationsStart({ locations })))
    );

    @Effect()
    errorLoadLocations$ = this.actions$.pipe(
        ofType<LoadLocationsError>(LocationsActionTypes.LOAD_LOCATIONS_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'LOCATIONS.LOAD');
            return of();
        })
    );

    @Effect()
    createLocationRequest$ = this.actions$.pipe(
        ofType<CreateLocationRequest>(
            LocationsActionTypes.CREATE_LOCATION_REQUESTED
        ),
        mergeMap((action) => {
            const location = action.payload.location;

            if (location.id && location.id.startsWith('new-location-')) {
                return of(new CreateLocationSuccess({ location }));
            }

            return this.projectSettingsService
                .projectsByProjectIdSettingsLocationPost(
                    action.payload.projectId,
                    action.payload.location
                )
                .pipe(
                    map(
                        (response) =>
                            new CreateLocationSuccess({
                                location: response.data,
                                tmpLocationId: action.payload.tmpLocationId,
                            })
                    ),
                    catchError((error) =>
                        of(new CreateLocationError({ error }))
                    )
                );
        })
    );

    @Effect({ dispatch: false })
    errorCreateLocation$ = this.actions$.pipe(
        ofType<CreateLocationError>(LocationsActionTypes.CREATE_LOCATION_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LOCATIONS.CREATE'
            );
            return of();
        })
    );

    @Effect()
    updateLocation$ = this.actions$.pipe(
        ofType<UpdateLocationRequested>(
            LocationsActionTypes.UPDATE_LOCATION_REQUESTED
        ),
        mergeMap((action) =>
            this.projectSettingsService
                .projectsByProjectIdSettingsLocationPatch(
                    action.payload.projectId,
                    action.payload.location
                )
                .pipe(
                    map(
                        (response) =>
                            new UpdateLocationSuccess({
                                location: response.data,
                            })
                    ),
                    catchError((error) =>
                        of(new UpdateLocationError({ error }))
                    )
                )
        )
    );

    @Effect({ dispatch: false })
    errorUpdateLocation$ = this.actions$.pipe(
        ofType<UpdateLocationError>(LocationsActionTypes.UPDATE_LOCATION_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LOCATIONS.UPDATE'
            );
            return of();
        })
    );

    @Effect()
    deleteLocation$ = this.actions$.pipe(
        ofType<DeleteLocationRequested>(
            LocationsActionTypes.DELETE_LOCATION_REQUESTED
        ),
        mergeMap((action) =>
            this.projectSettingsService
                .projectsByProjectIdSettingsLocationLocationidDelete(
                    action.payload.location.projectId,
                    action.payload.location.id
                )
                .pipe(
                    map(
                        (response) =>
                            new DeleteLocationSuccess({
                                location: action.payload.location,
                            })
                    ),
                    catchError((error) =>
                        of(new DeleteLocationError({ error }))
                    )
                )
        )
    );

    @Effect({ dispatch: false })
    errorDeleteLocation$ = this.actions$.pipe(
        ofType<DeleteLocationError>(LocationsActionTypes.DELETE_LOCATION_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LOCATIONS.DELETE'
            );
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private projectSettingsService: ProjectSettingsService,
        private syncService: SyncService,
        private locationDb: LocationDbService,
        private store: Store<LocationsState>,
        private errorUtils: ErrorUtils
    ) {}
}
