export interface ICustomAttributeTemplate {
    id?: string;
    title?: string;
    type?: string;
}

export interface CustomAttributeTemplate extends ICustomAttributeTemplate {
    ownerId?: string;
    updaterId?: string;
    creatorId?: string;

    creationDate?: string;
    markedAsDelete?: boolean;
    deleteDateTime?: string;
    updateDateTime?: string;
    projectId?: string;
}

export interface WithCustomAttributeTemplates {
    customAttributeTemplateIds?: string[];
    customAttributeTemplates?: CustomAttributeTemplate[];
}

export interface CustomAttribute {
    template?: ICustomAttributeTemplate;
    value?: string;
}

export interface WithCustomAttributes {
    customAttributes?: CustomAttribute[];
}

export const customAttributeTemplateIdsSchema = {
    type: ['array', 'null'],
    items: {
        type: 'string',
    },
};

export const customAttributeTemplatesSchema = {
    type: ['array', 'null'],
    items: {
        type: 'object',
        properties: {
            id: {
                type: ['string', 'null'],
            },
            title: {
                type: ['string', 'null'],
            },
            type: {
                type: ['string', 'null'],
            },
            ownerId: {
                type: ['string', 'null'],
            },
            updaterId: {
                type: ['string', 'null'],
            },
            creatorId: {
                type: ['string', 'null'],
            },
            creationDate: {
                type: ['string', 'null'],
            },
            markedAsDelete: {
                type: ['boolean', 'null'],
            },
            deleteDateTime: {
                type: ['string', 'null'],
            },
            updateDateTime: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
        },
    },
};

export const customAttributesSchema = {
    type: ['array', 'null'],
    items: {
        type: 'object',
        properties: {
            template: {
                type: ['object', 'null'],
                properties: {
                    id: {
                        type: ['string', 'null'],
                    },
                    title: {
                        type: ['string', 'null'],
                    },
                    type: {
                        type: ['string', 'null'],
                    },
                },
            },
            value: {
                type: ['string', 'null'],
            },
        },
    },
};
