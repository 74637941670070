import { Injectable } from '@angular/core';
import { Actions, Effect, createEffect, ofType } from '@ngrx/effects';
import { GlobalBoilerplatesService } from 'app/core/rest-api/api/globalBoilerplates.service';
import {
    catchError,
    defer,
    from,
    map,
    mergeMap,
    of,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import {
    CreateGlobalBoilerplateError,
    CreateGlobalBoilerplateRequested,
    CreateGlobalBoilerplateSuccess,
    DeleteGlobalBoilerplateError,
    DeleteGlobalBoilerplateRequested,
    DeleteGlobalBoilerplateSuccess,
    GlobalBoilerplatesActionTypes,
    LoadGlobalBoilerplatesError,
    LoadGlobalBoilerplatesSuccess,
    UpdateGlobalBoilerplateError,
    UpdateGlobalBoilerplateRequested,
    UpdateGlobalBoilerplateSuccess,
} from './global-boilerplates.actions';
import { Store, select } from '@ngrx/store';
import { GlobalBoilerplatesState } from './global-boilerplates.reducer';
import { GlobalBoilerplate } from 'app/core/rest-api';
import { SyncService } from 'app/main/sync/sync.service';
import { BoilerplateDbService } from 'app/db/boilerplates/boilerplate.db.service';
import { getCurrentUser } from '../auth/auth.selectors';
import { ErrorUtils } from 'app/core/utils/error-util';

@Injectable()
export class GlobalBoilerplatesEffects {
    constructor(
        private actions$: Actions,
        private globalBoilerplatesService: GlobalBoilerplatesService,
        private store: Store<GlobalBoilerplatesState>,
        private syncService: SyncService,
        private boilerplateDb: BoilerplateDbService,
        private errorUtils: ErrorUtils
    ) {}

    @Effect()
    loadGlobalBoilerplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_REQUESTED
            ),
            withLatestFrom(this.store.pipe(select(getCurrentUser))),
            switchMap(([_action, currentUser]) => {
                const server$ = this.globalBoilerplatesService.getGlobalBoilerplates();

                const db$ = defer(() =>
                    from(this.boilerplateDb.getByOwnerId(currentUser.id))
                );

                return this.syncService
                    .fetchFromServerOrDb({
                        server$,
                        db$,
                    })
                    .pipe(
                        map(
                            (response) =>
                                new LoadGlobalBoilerplatesSuccess({
                                    boilerplates: response.data as GlobalBoilerplate[],
                                    fromDb: response.fromDb,
                                })
                        ),
                        catchError((error) =>
                            of(new LoadGlobalBoilerplatesError({ error }))
                        )
                    );
            })
        );
    });

    @Effect()
    createBoilerplate$ = this.actions$.pipe(
        ofType<CreateGlobalBoilerplateRequested>(
            GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.globalBoilerplatesService
                .projectsByProjectIdSettingsBoilerplatePost({
                    title: payload.title,
                    parentID: payload.parentId,
                })
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new CreateGlobalBoilerplateSuccess({
                                boilerplate,
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new CreateGlobalBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect()
    deleteBoilerplate$ = this.actions$.pipe(
        ofType<DeleteGlobalBoilerplateRequested>(
            GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.globalBoilerplatesService
                .projectsByProjectIdSettingsBoilerplateByIdDelete(
                    payload.boilerplate.id,
                    true
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new DeleteGlobalBoilerplateSuccess({
                                boilerplate: payload.boilerplate,
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new DeleteGlobalBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    errorDeleteBoilerplate$ = this.actions$.pipe(
        ofType<DeleteGlobalBoilerplateError>(
            GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.DELETE'
            );
            return of();
        })
    );

    @Effect()
    updateBoilerplate$ = this.actions$.pipe(
        ofType<UpdateGlobalBoilerplateRequested>(
            GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.globalBoilerplatesService
                .projectsByProjectIdSettingsBoilerplatePatch({
                    id: payload.update.id + '',
                    ...payload.update.changes,
                })
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new UpdateGlobalBoilerplateSuccess({
                                update: {
                                    id: boilerplate.id,
                                    changes: boilerplate,
                                },
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new UpdateGlobalBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    errorUpdateBoilerplate$ = this.actions$.pipe(
        ofType<UpdateGlobalBoilerplateError>(
            GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.UPDATE'
            );
            return of();
        })
    );
}
