<div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="conflictCount$ | async as conflictCount"
>
    <span
        >{{ conflictCount }}
        {{
            (conflictCount === 1 ? 'APP.CONFLICT' : 'APP.CONFLICTS') | translate
        }}</span
    >
    <button (click)="showConflicts()" mat-button>
        <span translate>APP.SHOW</span>
    </button>
</div>
