import { RxJsonSchema } from 'rxdb';

export const contactSchema: RxJsonSchema = {
    title: 'contact schema',
    description: 'describes a contact',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true
        },
        creationDate: {
            type: 'string'
        },
        markedAsDelete: {
            type: ['boolean', 'null']
        },
        updateDateTime: {
            type: 'string'
        },
        name: {
            type: 'string'
        },
        fullname: {
            type: ['string', 'null']
        },
        addressStreet: {
            type: ['string', 'null']
        },
        addressPostCode: {
            type: ['string', 'null']
        },
        addressState: {
            type: ['string', 'null']
        },
        phone: {
            type: ['string', 'null']
        },
        fax: {
            type: ['string', 'null']
        },
        email: {
            type: ['string', 'null']
        },
        website: {
            type: ['string', 'null']
        },
        userId: {
            type: ['string', 'null']
        },
        photo: {
            type: ['string', 'null']
        },
        companyId: {
            type: ['string', 'null']
        }
    },
    additionalProperties: false,
    attachments: {
        encrypted: false
    }
};
