import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Contact, ContactsService } from 'app/core/rest-api';
import { ContactDbService } from 'app/db/contacts/contact.db.service';
import { defer, from, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { SyncService } from '../../main/sync/sync.service';
import { getIsDevice } from '../core/core.selectors';
import {
    ContactsActionTypes,
    CreateContactError,
    CreateContactRequest,
    CreateContactSuccess,
    DeleteContactError,
    DeleteContactRequest,
    DeleteContactSuccess,
    ImportContactsFromCsvError,
    ImportContactsFromCsvRequest,
    ImportContactsFromCsvSuccess,
    LoadContactsError,
    LoadContactsRequested,
    LoadContactsSuccess,
    UpdateContactError,
    UpdateContactRequest,
    UpdateContactSuccess,
    UpsertContactsFinish,
    UpsertContactsStart,
} from './contacts.actions';
import { ContactsState } from './contacts.reducer';
import { ErrorUtils } from 'app/core/utils/error-util';
import { getIsLoggedIn } from '../auth/auth.selectors';

@Injectable()
export class ContactsEffects {
    @Effect()
    $upsertContactStart = this.actions$.pipe(
        ofType<UpsertContactsStart>(ContactsActionTypes.UPSERT_CONTACTS_START),
        map((action) => action.payload.contacts),
        mergeMap((contacts) =>
            from(this.contactDb.bulkInsert(contacts)).pipe(
                map((results) => new UpsertContactsFinish({ results }))
            )
        )
    );

    @Effect()
    loadContacts$ = this.actions$.pipe(
        ofType<LoadContactsRequested>(
            ContactsActionTypes.LOAD_CONTACTS_REQUESTED
        ),
        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([action, isLoggedIn]) => isLoggedIn),
        mergeMap(([action, isLoggedIn]) => {
            const server$ = this.contactsService.contactsGet();
            const db$ = defer(() => from(this.contactDb.getAll()));
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map((response) => {
                        const contacts = (response.data as Contact[]).map(
                            (c: Contact) => {
                                return {
                                    ...c,
                                    fullnameWithCompany: c.companyName
                                        ? `${c.fullname}, ${c.companyName}`
                                        : c.fullname,
                                };
                            }
                        );

                        return new LoadContactsSuccess({
                            contacts: contacts,
                            fromDb: response.fromDb,
                        });
                    }),
                    catchError((error) => of(new LoadContactsError({ error })))
                );
        })
    );

    @Effect()
    loadContactsSuccess$ = this.actions$.pipe(
        ofType<LoadContactsSuccess>(ContactsActionTypes.LOAD_CONTACTS_SUCCESS),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.contacts),
        switchMap((contacts) => of(new UpsertContactsStart({ contacts })))
    );

    @Effect()
    errorLoadContacts$ = this.actions$.pipe(
        ofType<LoadContactsError>(ContactsActionTypes.LOAD_CONTACTS_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'CONTACTS.LOAD');
            return of();
        })
    );

    @Effect()
    createContact$ = this.actions$.pipe(
        ofType<CreateContactRequest>(
            ContactsActionTypes.CREATE_CONTACT_REQUEST
        ),
        mergeMap((action) =>
            this.contactsService.contactsPost(action.payload.contact).pipe(
                map(
                    (response) =>
                        new CreateContactSuccess({ contact: response.data })
                ),
                catchError((error) => of(new CreateContactError({ error })))
            )
        )
    );

    @Effect({ dispatch: false })
    createContactError$ = this.actions$.pipe(
        ofType<CreateContactError>(ContactsActionTypes.CREATE_CONTACT_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'CONTACTS.CREATE'
            );
            return of();
        })
    );

    @Effect()
    updateContact$ = this.actions$.pipe(
        ofType<UpdateContactRequest>(
            ContactsActionTypes.UPDATE_CONTACT_REQUEST
        ),
        mergeMap((action) =>
            this.contactsService
                .contactsByContactIdPatch(
                    action.payload.contact.id,
                    action.payload.contact as Contact
                    // parse Partial<Contact> to Contact
                )
                .pipe(
                    map(
                        (response) =>
                            new UpdateContactSuccess({ contact: response.data })
                    ),
                    catchError((error) => of(new UpdateContactError({ error })))
                )
        )
    );

    @Effect({ dispatch: false })
    updateContactError$ = this.actions$.pipe(
        ofType<UpdateContactError>(ContactsActionTypes.UPDATE_CONTACT_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'CONTACTS.UPDATE'
            );
            return of();
        })
    );

    @Effect()
    deleteContact$ = this.actions$.pipe(
        ofType<DeleteContactRequest>(
            ContactsActionTypes.DELETE_CONTACT_REQUEST
        ),
        mergeMap((action) =>
            this.contactsService
                .contactsByContactIdDelete(action.payload.contactId)
                .pipe(
                    map(
                        (response) =>
                            new DeleteContactSuccess({
                                contactId: action.payload.contactId,
                            })
                    ),
                    catchError((error) => of(new DeleteContactError({ error })))
                )
        )
    );

    @Effect({ dispatch: false })
    deleteContactError$ = this.actions$.pipe(
        ofType<DeleteContactError>(ContactsActionTypes.DELETE_CONTACT_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'CONTACTS.DELETE'
            );
            return of();
        })
    );

    @Effect()
    importContactsFromCsvRequest$ = this.actions$.pipe(
        ofType<ImportContactsFromCsvRequest>(
            ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_REQUEST
        ),
        switchMap((action) => {
            const { actionId, companyId, csvFile } = action.payload;

            return this.contactsService
                .contactsCsvPost(companyId, csvFile)
                .pipe(
                    map(
                        (response) =>
                            new ImportContactsFromCsvSuccess({
                                actionId,
                                importResult: response.data,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new ImportContactsFromCsvError({
                                actionId,
                                error,
                            })
                        )
                    )
                );
        })
    );

    /**
     * Reload contacts after a successful import.
     */
    @Effect()
    importContactsFromCsvSuccess$ = this.actions$.pipe(
        ofType<ImportContactsFromCsvSuccess>(
            ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_SUCCESS
        ),
        switchMap((_action) => {
            return of(new LoadContactsRequested());
        })
    );

    @Effect({ dispatch: false })
    importContactsFromCsvError$ = this.actions$.pipe(
        ofType<ImportContactsFromCsvError>(
            ContactsActionTypes.IMPORT_CONTACTS_FROM_CSV_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((_error) => {
            // Note: Currently, the returned error does not contain a customer
            //       facing error message and the default message is always
            //       shown by passing null as error.
            this.errorUtils.showSingleMessageOrDefault(null, 'CONTACTS.IMPORT');
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private contactsService: ContactsService,
        private store: Store<ContactsState>,
        private contactDb: ContactDbService,
        private syncService: SyncService,
        private errorUtils: ErrorUtils
    ) {}
}
