/// <reference types="cordova-plugin-file" />
/// <reference types="cordova-plugin-file-transfer" />

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { FilesService } from 'app/core/rest-api';

import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
    ATTACHMENT_DIRECTORY,
    ATTACHMENT_THUMBNAIL_CONTENT_TYPE,
} from './attachment-configuration.tokens';
import {
    AttachmentDownloaderService,
    DownloadResult,
    StoreRequest,
} from './attachment-downloader.service';
import {
    createDirectory,
    getOldDirectoryEntry,
    requestFileSystem,
    SafeName,
} from './cordova-plugin-file.utils';

class DownloadSuccess {
    type: 'success';
    blob?: Blob;
}

class DownloadError {
    type: 'error';
    error: any;
}

type InternalDownloadResult = DownloadSuccess | DownloadError;

const THUMBNAIL_SUFFIX = '/thumbnail';

interface DownloadParameters {
    token: string;
    attachmentDirectoryEntry: FileSystemEntry;
    fileTransfer: FileTransfer;
}

@Injectable()
export class CordovaAttachmentDownloaderService extends AttachmentDownloaderService {
    constructor(
        private filesService: FilesService,
        private httpClient: HttpClient,
        @Inject(ATTACHMENT_DIRECTORY) private attachmentDirectory: SafeName,
        @Inject(ATTACHMENT_THUMBNAIL_CONTENT_TYPE)
        private thumbnailContentType: string
    ) {
        super();
    }

    private static extractThumbnailIds(
        ids: Set<string>
    ): { preProcessedIds: Set<string>; thumbnailIds: Set<string> } {
        const thumbnailIds = new Set<string>();
        const preProcessedIds = new Set<string>();

        for (const id of ids.values()) {
            const isThumbnail = id.includes(THUMBNAIL_SUFFIX);

            if (isThumbnail) {
                thumbnailIds.add(id);

                const nonThumbnailId = id.substr(
                    0,
                    id.length - THUMBNAIL_SUFFIX.length
                );
                preProcessedIds.add(nonThumbnailId);
            } else {
                preProcessedIds.add(id);
            }
        }

        return { preProcessedIds, thumbnailIds };
    }

    private integrateThumbnails(
        thumbnailIds: Set<string>,
        metadataResults: DownloadResult[]
    ): void {
        for (const thumbnailId of thumbnailIds.values()) {
            const nonThumbnailId = thumbnailId.substr(
                0,
                thumbnailId.length - THUMBNAIL_SUFFIX.length
            );
            const nonThumbnailResult = metadataResults.find(
                (result) => result.id === nonThumbnailId
            );

            metadataResults.push({
                ...nonThumbnailResult,
                id: thumbnailId,
                storeRequest: {
                    ...nonThumbnailResult.storeRequest,
                    metadata: {
                        ...nonThumbnailResult.storeRequest.metadata,
                        contentType: this.thumbnailContentType,
                    },
                },
            });
        }
    }

    private async downloadAttachment(downloadAttachment: {
        id: string;
        token: string;
        attachmentDirectoryEntry: FileSystemEntry;
        fileTransfer: FileTransfer;
    }): Promise<InternalDownloadResult> {
        const {
            id,
            token,
            attachmentDirectoryEntry,
            fileTransfer,
        } = downloadAttachment;

        const url = environment.endpoints.asset + id;

        const target =
            getOldDirectoryEntry(attachmentDirectoryEntry).toURL() +
            SafeName.create(id).name;

        try {
            await new Promise<void>((resolve, reject) => {
                fileTransfer.download(
                    url,
                    target,
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    },
                    false,
                    {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                    }
                );
            });
            return {
                type: 'success',
            };
        } catch (ex) {
            return {
                type: 'error',
                error: ex,
            };
        }
    }

    private createMetadataRequest(id: string): Promise<DownloadResult> {
        return this.filesService
            .filesByIdGet(id)
            .pipe(
                map(
                    (response): DownloadResult => {
                        return {
                            id,
                            storeRequest: {
                                type: 'metadata-to-store',
                                attachmentId: id,
                                metadata: response.data,
                            },
                        };
                    }
                ),
                catchError(
                    (error): Observable<DownloadResult> => {
                        return of({
                            id,
                            error,
                        });
                    }
                )
            )
            .toPromise();
    }

    private async downloadMetadata(
        preProcessedIds: Set<string>
    ): Promise<DownloadResult[]> {
        const requests: Promise<DownloadResult>[] = [];

        for (const id of preProcessedIds.values()) {
            const metadataRequest = this.createMetadataRequest(id);
            requests.push(metadataRequest);
        }

        return Promise.all(requests);
    }

    async downloadAttachmentMetadata(
        ids: Set<string>
    ): Promise<DownloadResult[]> {
        // the thumbnail variants are regarded as their own assets, thus this method
        // has also to handle this
        const {
            preProcessedIds,
            thumbnailIds,
        } = CordovaAttachmentDownloaderService.extractThumbnailIds(ids);

        const metadataResults = await this.downloadMetadata(preProcessedIds);

        this.integrateThumbnails(thumbnailIds, metadataResults);

        return metadataResults;
    }

    private async prepareDownloadParameters(): Promise<DownloadParameters> {
        const token = await Preferences.get({ key: 'token' });

        const fs = await requestFileSystem(LocalFileSystem.PERSISTENT);

        const cordova = (window as any).cordova as Cordova;
        const dataDir = cordova.file.dataDirectory;
        const attachmentDirectoryEntry = await createDirectory(
            fs,
            dataDir,
            this.attachmentDirectory
        );

        const fileTransfer = new FileTransfer();

        return { token: token.value, attachmentDirectoryEntry, fileTransfer };
    }

    private async downloadAttachmentWithHttpClient(
        id: string,
        token: string
    ): Promise<InternalDownloadResult> {
        // this.filesService.filesDirectByIdGet cannot be used because
        // responseType has to be set to Blob

        const url = environment.endpoints.asset + id;
        return this.httpClient
            .get(url, {
                responseType: 'blob',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .pipe(
                map(
                    (blob): DownloadSuccess => {
                        return {
                            type: 'success',
                            blob,
                        };
                    }
                ),
                catchError(
                    (error): Observable<DownloadError> => {
                        return of({
                            type: 'error',
                            error,
                        });
                    }
                )
            )
            .toPromise();
    }

    private async downloadAttachmentsWithoutMetadata(
        ids: Set<string>
    ): Promise<DownloadResult[]> {
        const downloadParameters = await this.prepareDownloadParameters();

        const blobResults: DownloadResult[] = [];

        const iOS = (await Device.getInfo()).platform === 'ios';

        console.log('downloading', ids.size, 'attachments');

        for (const id of ids.values()) {
            let storeRequestType: 'blob-to-store' | 'metadata-to-store';
            let downloadResult: InternalDownloadResult;

            if (iOS) {
                storeRequestType = 'blob-to-store';
                downloadResult = await this.downloadAttachmentWithHttpClient(
                    id,
                    downloadParameters.token
                );
            } else {
                storeRequestType = 'metadata-to-store';
                downloadResult = await this.downloadAttachment({
                    ...downloadParameters,
                    id,
                });
            }

            switch (downloadResult.type) {
                case 'success':
                    const storeRequest: StoreRequest = {
                        type: storeRequestType,
                        attachmentId: id,
                        metadata: null,
                        blob: downloadResult.blob,
                    };

                    blobResults.push({
                        id,
                        storeRequest,
                    });
                    break;
                case 'error':
                    blobResults.push({
                        id,
                        error: downloadResult.error,
                    });
                    break;
            }
        }

        return blobResults;
    }

    private combineAttachmentsWithMetadata(
        metadataResults: DownloadResult[],
        downloadResults: DownloadResult[]
    ): DownloadResult[] {
        const metadataResultMap = new Map<string, DownloadResult>();
        for (const result of metadataResults) {
            metadataResultMap.set(result.id, result);
        }

        const combinedResults = downloadResults.map(
            (result): DownloadResult => {
                const metadataResult = metadataResultMap.get(result.id);
                const metadataError = metadataResult.error;
                const resultError = result.error;

                if (metadataError && resultError) {
                    return {
                        id: result.id,
                        error: [metadataError, resultError],
                    };
                } else if (metadataError) {
                    return metadataResult;
                } else if (resultError) {
                    return result;
                }

                return {
                    id: result.id,
                    storeRequest: {
                        ...result.storeRequest,
                        attachmentId: result.id,
                        metadata: metadataResult.storeRequest.metadata,
                    },
                };
            }
        );

        return combinedResults;
    }

    async downloadAttachments(ids: Set<string>): Promise<DownloadResult[]> {
        const metadataDownload = this.downloadAttachmentMetadata(ids);

        const downloadResults = await this.downloadAttachmentsWithoutMetadata(
            ids
        );

        const metadataResults = await metadataDownload;

        const combinedResults = this.combineAttachmentsWithMetadata(
            metadataResults,
            downloadResults
        );

        return combinedResults;
    }
}
