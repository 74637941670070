import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EntityTableColumn } from './entity-table-colum';

@Component({
    selector: 'acc-entity-table',
    templateUrl: './entity-table.component.html',
    styleUrls: ['./entity-table.component.scss'],
})
export class EntityTableComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    entities: any[];

    @Input()
    columns: EntityTableColumn<any>[];

    @Input()
    displayedColumns: string[];

    @Input()
    title: string;

    @Input()
    selectable = true;

    @Input()
    deletable = true;

    @Input()
    noRecordsTranslation;

    @Input()
    isLoading = false;

    selection = new SelectionModel(true, []);

    dataSource = new MatTableDataSource();

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @Output()
    updated = new EventEmitter<string>();

    @Output()
    import = new EventEmitter<string>();

    @Output()
    deleted = new EventEmitter<string>();

    @Output()
    deleteSelected = new EventEmitter<any[]>();

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    sortFunction = (data: any, sortHeaderId: string): string | number => {
        let value;

        const column = this.columns?.find(
            (item) => item.columnDef === sortHeaderId
        );

        if (column) value = column?.cell(data);

        return value;
    };

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entities) {
            this.dataSource.data = this.entities;
            this.dataSource.sortingDataAccessor = this.sortFunction;
            this.dataSource.sort = this.sort;
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected >= numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    isPageSelected(): boolean {
        const selectionRange: [number, number] = [
            this.paginator.pageIndex * this.paginator.pageSize,
            (this.paginator.pageIndex + 1) * this.paginator.pageSize,
        ];

        let pageSelected = true;
        for (let i = selectionRange[0]; i < selectionRange[1]; i++) {
            if (!this.selection.selected.includes(this.dataSource.data[i])) {
                pageSelected = false;
            }
        }

        return pageSelected;
    }

    selectPage(): void {
        const selectionRange: [number, number] = [
            this.paginator.pageIndex * this.paginator.pageSize,
            (this.paginator.pageIndex + 1) * this.paginator.pageSize,
        ];

        if (!this.isPageSelected()) {
            for (let i = selectionRange[0]; i < selectionRange[1]; i++) {
                this.selection.select(this.dataSource.data[i]);
            }
        } else {
            for (let i = selectionRange[0]; i < selectionRange[1]; i++) {
                this.selection.deselect(this.dataSource.data[i]);
            }
        }
    }

    // FROM ENTITY TABLE CONTAINER

    updatePagination(): void {
        while (this.isLessDataThanFitsOnPage()) {
            this.dataSource.paginator.previousPage();
        }
    }

    private isLessDataThanFitsOnPage(): boolean {
        return (
            this.dataSource.paginator.pageIndex > 0 &&
            this.dataSource.data.length <=
            this.dataSource.paginator.pageSize *
            this.dataSource.paginator.pageIndex
        );
    }

    clearSelection(): void {
        this.selection.clear();
    }

    select(row: any[]): void {
        this.selection.toggle(row);
    }
}
