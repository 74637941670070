import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Contact } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { ContactDocType } from './contact.document';

@Injectable({
    providedIn: 'root',
})
export class ContactDbService extends EntityDbService<Contact, ContactDocType> {
    apiItemToSchema(contact: Contact): ContactDocType {
        const {
            addressPostCode,
            addressState,
            addressStreet,
            companyId,
            creationDate,
            email,
            fax,
            id,
            markedAsDelete,
            fullname,
            name,
            phone,
            updateDateTime,
            userId,
            website,
            photo,
        } = contact;
        return {
            addressPostCode,
            addressState,
            addressStreet,
            companyId,
            creationDate,
            email,
            fax,
            id,
            markedAsDelete,
            fullname,
            name,
            phone,
            updateDateTime,
            userId,
            website,
            photo,
        };
    }
    getAttachmentIdsFromApiItem(contact: Contact): string[] {
        let ids = [];
        const { photo } = contact;
        if (photo && !AcceptUtils.isDefaultGuid(photo)) {
            ids = [...ids, photo];
        }
        return ids;
    }
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Contacts
        );
    }
}
