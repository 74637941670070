import { Action } from '@ngrx/store';
import { User } from 'app/core/auth/models/user';
import {
    BearerToken,
    CompleteRegisterViewModel,
    LoginViewModel,
    RegisterViewModel,
    ResetPasswordViewModel,
} from 'app/core/rest-api';

export enum AuthActionTypes {
    LoginRequested = '[Auth] Login',
    LoginSuccess = '[API] Login Success',
    LoginByToken = '[API] Login By Token',
    LoginError = '[API] Login Error',
    Logout = '[Settings] Logout',

    RegisterRequested = '[Auth] Register Requested',
    RegisterSuccess = '[API] Register Success',
    RegisterError = '[API] Register Error',

    RegisterCompleteRequested = '[Auth] Register Complete Requested',
    RegisterCompleteError = '[API]Register Complete Error',

    ResetPasswordRequested = '[Auth] Reset Password Requested',
    ResetPasswordSuccess = '[Auth] Reset Password Success',
    ResetPasswordError = '[Auth] Reset Password Error',
}

export class LoginRequested implements Action {
    readonly type = AuthActionTypes.LoginRequested;
    constructor(public payload: { login: LoginViewModel }) {}
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LoginSuccess;
    constructor(
        public payload: { user: User; token: string; notificationToken: string }
    ) {}
}

/**
 * Payload of {@link LoginError}.
 */
export interface LoginErrorPayload {
    /**
     * The login credentials used for the failed log in process.
     */
    login: LoginViewModel;
    error: any;
}

export class LoginError implements Action {
    readonly type = AuthActionTypes.LoginError;
    constructor(public payload: LoginErrorPayload) {}
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
    constructor() {}
}

export class LoginByToken implements Action {
    readonly type = AuthActionTypes.LoginByToken;
    constructor(public payload: { token: string }) {}
}

export class RegisterRequested implements Action {
    readonly type = AuthActionTypes.RegisterRequested;

    constructor(public payload: { registerViewModel: RegisterViewModel }) {}
}

export class RegisterSuccess implements Action {
    readonly type = AuthActionTypes.RegisterSuccess;

    constructor(public payload: { bearerToken: BearerToken }) {}
}

export class RegisterError implements Action {
    readonly type = AuthActionTypes.RegisterError;
    constructor(public payload: { error: any }) {}
}

export class RegisterCompleteRequested implements Action {
    readonly type = AuthActionTypes.RegisterCompleteRequested;

    constructor(
        public payload: { completeRegisterViewModel: CompleteRegisterViewModel }
    ) {}
}

export class RegisterCompleteError implements Action {
    readonly type = AuthActionTypes.RegisterCompleteError;
    constructor(public payload: { error: any }) {}
}

export class ResetPasswordRequested implements Action {
    readonly type = AuthActionTypes.ResetPasswordRequested;

    constructor(
        public payload: {
            email: string;
            password: string;
        }
    ) {}
}

export class ResetPasswordSuccess implements Action {
    readonly type = AuthActionTypes.ResetPasswordSuccess;
}

export class ResetPasswordError implements Action {
    readonly type = AuthActionTypes.ResetPasswordError;

    constructor(public payload: { error: any }) {}
}

export type AuthActions =
    | LoginRequested
    | LoginSuccess
    | LoginError
    | Logout
    | LoginByToken
    | RegisterRequested
    | RegisterSuccess
    | RegisterError
    | RegisterCompleteRequested
    | RegisterCompleteError
    | ResetPasswordRequested
    | ResetPasswordSuccess
    | ResetPasswordError;
