import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { DailyLogEntryBase } from 'app/core/rest-api';
import { DiaryDocType } from 'app/db/diary/diary.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { DiaryCommand } from 'app/main/diary/models/diary-command';
import { DiaryEntryPost } from 'app/main/diary/models/diary-entry.post';
import { DiaryFilters } from 'app/main/diary/models/diary.filters';
import { Conflict } from 'app/main/models/conflict';
import { EntityType } from 'app/main/sync/models/entity-type';
import { SyncCommand } from 'app/main/sync/models/sync-command';
import { EntityAttachmentMap } from '../issues/models/entity-attachment-map';
import { EntityError } from '../sync/entity-response.model';

export enum DiaryActionTypes {
    CREATE_DIARY_COMMAND = '[APP] Create Diary Command',

    UPSERT_DIARIES_START = '[APP] Upsert DIARIES Request',
    UPSERT_DIARIES_FINISH = '[DB] Upsert DIARIES Success',

    SYNC_DIARY_COMMANDS = '[APP] Sync Diary Commands',
    LOAD_DIARY_COMMANDS_REQUEST = '[APP] Load Diary Commands Request',
    LOAD_DIARY_COMMANDS_SUCCESS = '[DB] Load Diary Commands Success',
    LOAD_DIARY_COMMANDS_ERROR = '[DB] Load Diary Commands Error',

    ADD_DIARY_ATTACHMENT_MAPPINGS = '[SyncDiaryCommands] Add Diary Attachment Mappings',
    UPDATE_DIARY_ENTRIES_ATTACHMENT_IDS = '[SyncDiaryCommand Effect] Update Diary Entries Attachment Ids',
    CLEAR_DIARY_ATTACHMENT_MAP = '[UpdateDiaryEntriesAttachmentIds] Clear Diary Attachment Map',

    CREATE_DIARY_CONFLICTS = '[API] Create Diary Conflicts',
    LOAD_DIARY_CONFLICTS = '[CoreService Init (StartUp)] Load Diary Conflicts',
    SET_DIARY_CONFLICTS = '[Issue Effects] Set Diary Conflicts',
    STORE_DIARY_CONFLICTS = '[Diary Effects] Store Diary Conflicts',

    LOAD_DIARY_ENTRIES_REQUEST = '[Diary] Load Diary Entries Request',
    LOAD_DIARY_ENTRIES_SUCCESS = '[API] Load Diary Entries Success',
    LOAD_DIARY_ENTRIES_ERROR = '[API] Load Diary Entries Error',

    CREATE_DIARY_ENTRIES_REQUEST = '[Diary] Create Diary Entries Request',
    CREATE_DIARY_ENTRIES_SUCCESS = '[API] Create Diary Entries Success',
    CREATE_DIARY_ENTRIES_ERROR = '[API] Create Diary Entries Error',

    UPDATE_DIARY_ENTRIES_REQUEST = '[Diary] Update Diary Entries Request',
    UPDATE_DIARY_ENTRIES_SUCCESS = '[API] Update Diary Entries Success',
    UPDATE_DIARY_ENTRIES_ERROR = '[API] Update Diary Entries Error',

    TOGGLE_SELECT_DIARY_ENTRY = '[Diary] Toggle Select Entry',
    TOGGLE_SELECT_MONTH = '[Diary] Toggle Select Month',
    TOGGLE_SELECT_DATE = '[Diary] Toggle Select Date',
    TOGGLE_SELECT_ALL_DIARY_ENTRIES = '[Diary] Toggle Select all Diary Entries',
    DESELECT_ALL_DIARY_ENTRIES = '[Diary] Toggle Select All Diary Entries',

    TOGGLE_ARCHIVED_DIARY_ENTRIES = '[Diary] Toggle Archived',

    SET_DIARY_FILTERS = '[Diary Filter Sidenav] Set Diary Filters',

    CLEAN_UP_TEMP_ENTRIES = '[Diary] Clean Up temp Entries',

    SET_SELECTED_COMPANY_ID = '[Diary] Set Selected Company ID',

    SET_INSPECTION_CRAFT = '[Diary] Set Inspection Craft',

    ADD_TO_ISSUE_IDS_FOR_NEW_INSPECTION = '[Diary] Add To Issues For New Inspection',

    CLEAR_ISSUE_IDS_FOR_NEW_INSPECTION = '[Diary] Clear Issue IDs for New Inspection',
}

export class ClearIssueIdsForNewInspection implements Action {
    readonly type = DiaryActionTypes.CLEAR_ISSUE_IDS_FOR_NEW_INSPECTION;
    constructor() {}
}

export class AddToIssueIdsForNewInspection implements Action {
    readonly type = DiaryActionTypes.ADD_TO_ISSUE_IDS_FOR_NEW_INSPECTION;
    constructor(public payload: { id: string }) {}
}

export class UpsertDiariesStart implements Action {
    readonly type = DiaryActionTypes.UPSERT_DIARIES_START;
    constructor(public payload: { diaries: DailyLogEntryBase[] }) {}
}

export class UpsertDiariesFinish implements Action {
    readonly type = DiaryActionTypes.UPSERT_DIARIES_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<DiaryDocType>;
        }
    ) {}
}

export class CreateDiaryCommand implements Action {
    readonly type = DiaryActionTypes.CREATE_DIARY_COMMAND;
    constructor(
        public payload: {
            command: DiaryCommand;
            // used to apply conflicts
            applyConflict?: boolean;
        }
    ) {}
}

export class CreateDiaryConflicts implements Action {
    readonly type = DiaryActionTypes.CREATE_DIARY_CONFLICTS;
    constructor(public payload: { conflicts: Conflict<DailyLogEntryBase>[] }) {}
}

export class LoadDiaryConflicts implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_CONFLICTS;
}

export class SetDiaryConflicts implements Action {
    readonly type = DiaryActionTypes.SET_DIARY_CONFLICTS;

    constructor(public payload: { conflicts: Conflict<DailyLogEntryBase>[] }) {}
}

export class StoreDiaryConflicts implements Action {
    readonly type = DiaryActionTypes.STORE_DIARY_CONFLICTS;
}

export class SyncDiaryCommands implements Action {
    readonly type = DiaryActionTypes.SYNC_DIARY_COMMANDS;
}

export class LoadDiaryCommandsRequest implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_COMMANDS_REQUEST;
    constructor(
        public payload: { projectId: string; entityType: EntityType }
    ) {}
}

export class LoadDiaryCommandsSuccess implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_COMMANDS_SUCCESS;
    constructor(
        public payload: { commands: SyncCommand<any>[]; entityType: EntityType }
    ) {}
}

export class LoadDiaryCommandsError implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_COMMANDS_ERROR;
    constructor(public payload: { error?: any }) {}
}

export class AddDiaryAttachmentMappings implements Action {
    readonly type = DiaryActionTypes.ADD_DIARY_ATTACHMENT_MAPPINGS;
    constructor(
        public payload: {
            attachmentMap: EntityAttachmentMap;
        }
    ) {}
}

export class UpdateDiaryEntriesAttachmentIds implements Action {
    readonly type = DiaryActionTypes.UPDATE_DIARY_ENTRIES_ATTACHMENT_IDS;
    constructor() {}
}

export class ClearDiaryAttachmentMap implements Action {
    readonly type = DiaryActionTypes.CLEAR_DIARY_ATTACHMENT_MAP;
    constructor() {}
}

export class SetSelectedCompanyId implements Action {
    readonly type = DiaryActionTypes.SET_SELECTED_COMPANY_ID;
    constructor(public payload: { companyId: string }) {}
}

export class SetInspectionCraft implements Action {
    readonly type = DiaryActionTypes.SET_INSPECTION_CRAFT;
    constructor(public payload: { craftId: string }) {}
}

export class LoadDiaryEntriesRequest implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_ENTRIES_REQUEST;

    constructor(public payload: { projectId: string; archived: boolean }) {}
}

export class LoadDiaryEntriesSuccess implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_ENTRIES_SUCCESS;

    constructor(
        public payload: {
            diaries: DailyLogEntryBase[];
            currentProjectId: string;
            requestProjectId: string;
            fromDb: boolean;
        }
    ) {}
}

export class LoadDiaryEntriesError implements Action {
    readonly type = DiaryActionTypes.LOAD_DIARY_ENTRIES_ERROR;

    constructor(public payload: { error: any }) {}
}

export class CreateDiaryEntriesRequest implements Action {
    readonly type = DiaryActionTypes.CREATE_DIARY_ENTRIES_REQUEST;

    constructor(
        public payload: {
            diaries: {
                diary: DiaryEntryPost;
                entityId: string;
            }[];
        }
    ) {}
}

export class CreateDiaryEntriesSuccess implements Action {
    readonly type = DiaryActionTypes.CREATE_DIARY_ENTRIES_SUCCESS;

    constructor(
        public payload: {
            diaries: {
                diary: DailyLogEntryBase;
                entityId: string;
            }[];
            currentProjectId: string;
        }
    ) {}
}

export class CreateDiaryEntriesError implements Action {
    readonly type = DiaryActionTypes.CREATE_DIARY_ENTRIES_ERROR;
    constructor(public payload: { errors: EntityError[] }) {}
}

export class UpdateDiaryEntriesRequest implements Action {
    readonly type = DiaryActionTypes.UPDATE_DIARY_ENTRIES_REQUEST;

    constructor(
        public payload: {
            updates: Update<DailyLogEntryBase>[];
            projectId: string;
        }
    ) {}
}

export class UpdateDiaryEntriesSuccess implements Action {
    readonly type = DiaryActionTypes.UPDATE_DIARY_ENTRIES_SUCCESS;

    constructor(
        public payload: {
            updates: Update<DailyLogEntryBase>[];
            currentProjectId: string;
        }
    ) {}
}

export class UpdateDiaryEntriesError implements Action {
    readonly type = DiaryActionTypes.UPDATE_DIARY_ENTRIES_ERROR;
    constructor(public payload: { errors: EntityError[] }) {}
}

export class ToggleSelectDiaryEntry implements Action {
    readonly type = DiaryActionTypes.TOGGLE_SELECT_DIARY_ENTRY;

    constructor(public payload: { diaryEntry: DailyLogEntryBase }) {}
}

export class ToggleSelectMonth implements Action {
    readonly type = DiaryActionTypes.TOGGLE_SELECT_MONTH;

    constructor(public payload: { date: Date }) {}
}

export class ToggleSelectDate implements Action {
    readonly type = DiaryActionTypes.TOGGLE_SELECT_DATE;

    constructor(public payload: { date: Date }) {}
}

export class ToggleSelectAllDiaryEntries implements Action {
    readonly type = DiaryActionTypes.TOGGLE_SELECT_ALL_DIARY_ENTRIES;
}

export class DeselectAllDiaryEntries implements Action {
    readonly type = DiaryActionTypes.DESELECT_ALL_DIARY_ENTRIES;
}

export class ToggleArchivedDiaryEntries implements Action {
    readonly type = DiaryActionTypes.TOGGLE_ARCHIVED_DIARY_ENTRIES;
}

export class SetDiaryFilters implements Action {
    readonly type = DiaryActionTypes.SET_DIARY_FILTERS;

    constructor(public payload: { filters: DiaryFilters }) {}
}

export class CleanUpTempEntries implements Action {
    readonly type = DiaryActionTypes.CLEAN_UP_TEMP_ENTRIES;
}

export type DiaryActions =
    | LoadDiaryEntriesRequest
    | LoadDiaryEntriesSuccess
    | LoadDiaryEntriesError
    | CreateDiaryEntriesRequest
    | CreateDiaryEntriesSuccess
    | CreateDiaryEntriesError
    | UpdateDiaryEntriesRequest
    | UpdateDiaryEntriesSuccess
    | UpdateDiaryEntriesError
    | UpdateDiaryEntriesRequest
    | UpdateDiaryEntriesSuccess
    | UpdateDiaryEntriesError
    | ToggleSelectDiaryEntry
    | ToggleSelectMonth
    | ToggleSelectDate
    | ToggleSelectAllDiaryEntries
    | DeselectAllDiaryEntries
    | ToggleArchivedDiaryEntries
    | SetDiaryFilters
    | CleanUpTempEntries
    | SetSelectedCompanyId
    | SetInspectionCraft
    | UpsertDiariesStart
    | UpsertDiariesFinish
    | SyncDiaryCommands
    | CreateDiaryCommand
    | CreateDiaryConflicts
    | LoadDiaryConflicts
    | SetDiaryConflicts
    | StoreDiaryConflicts
    | LoadDiaryCommandsRequest
    | LoadDiaryCommandsSuccess
    | LoadDiaryCommandsError
    | AddDiaryAttachmentMappings
    | ClearDiaryAttachmentMap
    | UpdateDiaryEntriesAttachmentIds
    | AddToIssueIdsForNewInspection
    | ClearIssueIdsForNewInspection;
