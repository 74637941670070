import { Action } from '@ngrx/store';
import { Notification } from 'app/core/rest-api';

export enum NotificationsActionTypes {
    LOAD_NOTIFICATIONS_REQUEST = '[Notifications] Load Notifications',
    LOAD_NOTIFICATIONS_SUCCESS = '[API] Load Notifications Success',
    LOAD_NOTIFICATIONS_ERROR = '[API] Load Notifications Error',

    ADD_NOTIFICATION = '[Notifications] Add Notification',

    MARK_NOTIFICATIONS_AS_READ_REQUEST = '[Notifications] Mark Notifications as Read Request',
    MARK_NOTIFICATIONS_AS_READ_SUCCESS = '[API] Mark Notifications as Read Success',
    MARK_NOTIFICATIONS_AS_READ_ERROR = '[API] Mark Notifications as Read Error',

    MARK_NOTIFICATION_AS_READ_REQUEST = '[Notifications] Mark Notification as Read Request',
    MARK_NOTIFICATION_AS_READ_SUCCESS = '[API] Mark Notification as Read Success',
    MARK_NOTIFICATION_AS_READ_ERROR = '[API] Mark Notification as Read Error',
}

export class LoadNotificationsRequest implements Action {
    readonly type = NotificationsActionTypes.LOAD_NOTIFICATIONS_REQUEST;
}

export class LoadNotificationsSuccess implements Action {
    readonly type = NotificationsActionTypes.LOAD_NOTIFICATIONS_SUCCESS;

    constructor(public payload: { notifications: Notification[] }) {}
}

export class LoadNotificationsError implements Action {
    readonly type = NotificationsActionTypes.LOAD_NOTIFICATIONS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class AddNotification implements Action {
    readonly type = NotificationsActionTypes.ADD_NOTIFICATION;

    constructor(public payload: { notification: Notification }) {}
}

export class MarkNotificationsAsReadRequest implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_REQUEST;
}

export class MarkNotificationsAsReadSuccess implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_SUCCESS;

    constructor(public payload: { notifications: Notification[] }) {}
}

export class MarkNotificationsAsReadError implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_ERROR;
    constructor(public payload: { error: any }) {}
}

export class MarkNotificationAsReadRequest implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_REQUEST;
    constructor(public payload: { notification: Notification }) {}
}

export class MarkNotificationAsReadSuccess implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS;

    constructor(public payload: { deletedNotification: Notification }) {}
}

export class MarkNotificationAsReadError implements Action {
    readonly type = NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_ERROR;
    constructor(public payload: { error: any }) {}
}

export type NotificationsActions =
    | LoadNotificationsRequest
    | LoadNotificationsSuccess
    | LoadNotificationsError
    | AddNotification
    | MarkNotificationsAsReadRequest
    | MarkNotificationsAsReadSuccess
    | MarkNotificationsAsReadError
    | MarkNotificationAsReadRequest
    | MarkNotificationAsReadSuccess
    | MarkNotificationAsReadError;
