import { Platform } from '@angular/cdk/platform';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import {
    Issue,
    MarkedPlan,
    MarkerAnnotation,
    Revision,
} from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { PlanAsset } from 'app/shared/models/plan-asset.model';
import { AssetDownloadService } from 'app/shared/services/asset-download.service';
import * as jsPDF from 'jspdf';
import cloneDeep from 'lodash/cloneDeep';
import * as PDFJS from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageViewport, PDFRenderTask } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { Subject } from 'rxjs';
import { AddAssetService } from '../../add-asset/add-asset.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ISSUE_SELECTED_COLOR } from './color-constants';
import { PinchPanService } from './pinch-pan.service';
import { PinchPanTransformService } from './pinch-pan-transform.service';
import { takeUntil } from 'rxjs/operators';
import { Rectangle, Transform, Vec2D } from './transform.model';
import {
    extractSingleClientTarget,
    isMultitouchEvent,
    isTouchEvent,
} from './event-target';
import {
    EventAllowedCheckService,
    LastPinchPan,
} from './event-allowed-check.service';
import { DrawShape, DrawShapeArgs } from './draw-shape';
import { HitTest, HitTestResult, MarkerInfo } from './hit-test';
import { generateAnnotationID } from './utils';

import {
    ArrowToolStrategy,
    OvalToolStrategy,
    RectangleToolStrategy,
    TwoPointToolStrategy,
} from './two-point-tools/two-point-tool-strategy';
import { unreachable_safe } from 'app/core/utils/ts-utils';
import { MatSelectChange } from '@angular/material/select';

pdfjsLib.GlobalWorkerOptions.workerSrc =
    './assets/images/plans/pdf.worker.min.js';

type MarkedPlanChanges = Partial<MarkedPlan>;

/**
 * Please note, in case you are testing touches and using Chrome Dev Tools
 * to simulate an iPad: Pull-To-Refresh is still active behind a dialog causing
 * refreshed issues and thus the underlying issue to update. This in turn
 * causes this component to update the shown markers, which causes the
 * currently added markers to disappear. Took some time to figure out...
 */
@Component({
    selector: 'acc-plan-preview',
    templateUrl: './plan-preview.component.html',
    styleUrls: ['./plan-preview.component.scss'],
    providers: [
        // Provide these in this component instead of globally,
        // so that they get created and destroyed together with
        // the plan component and they make no sense when used
        // globally
        EventAllowedCheckService,
        PinchPanService,
        PinchPanTransformService,
    ],
})
export class PlanPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    private static readonly _markerPath: string = 'assets/images/plans/marker.svg';
    private static readonly _markerPathHighlighted: string =
        'assets/images/plans/marker_highlighted.svg';
    private static readonly _scalling: number = 1.05;
    private static readonly _scallingDblclick: number = 1.4;
    private static readonly _scallingMaxConst: number = 5;
    private static readonly _scallingMinConst: number = 0.05;
    private static readonly _scallingFont: string = '18px Tahoma';
    private static readonly _scallingFontColor: string = 'black';
    private static readonly _scallingTextAlign: CanvasTextAlign = 'end';
    private static readonly _strokeColor = '#0066cc';
    private static readonly _selectedColor = '#cc0000';
    private static readonly _lineWidth = 10;
    private static readonly _thumbnailMaxSize = {
        width: 2048,
        height: 2048
    };
    private cordovaPlatform: string;

    private _planAsset: PlanAsset;
    @Input()
    set planAsset(value: PlanAsset) {
        const notFirstChange = !!this._planAsset;

        const differentPlan =
            this._planAsset?.documentPath !== value?.documentPath;
        if (notFirstChange && differentPlan) {
            // user changed the plan => reset zoom to prevent issues with markers
            // being drawn with zoom and not the plan (it is somehow only applied
            // after moving the view to the latter)
            //
            // the plan redrawing algorithm recalculates the proper ratios, but
            // the following variable is not reset properly which used for zoom
            // if its set
            //
            // the resetScaling function would also redraw the plan and set this
            // value too late to prevent the bug, so it is set here directly
            this._planRatioCurrentValue = undefined;
            this.cdr.detectChanges();
        }

        this._planAsset = value;

        // the first draw is done after initializing the view, only
        // redraw on subsequent changes of the document path
        if (notFirstChange) {
            this.initialDrawPlan(this._planAsset.documentPath);
        }
    }


    private preventAnnotationClick = false;

    private _issue: Issue;
    @Input()
    set issue(value: Issue) {
        this._issue = value;
        this.refreshMarkedPlan();
    }

    get issue(): Issue {
        return this._issue;
    }

    private _markedPlanChanges: MarkedPlanChanges = {};
    @Input()
    set markedPlanChanges(value: MarkedPlanChanges) {
        this._markedPlanChanges = value;
        this.refreshMarkedPlan();
    }
    get markedPlanChanges(): MarkedPlanChanges {
        return this._markedPlanChanges;
    }

    @Input()
    showHeader = true;

    @Input()
    readOnly = false;

    @Input()
    hideLeftBorderOfReadOnlyTools = false;

    /**
     * Adjust marker image for highlighted markers. Currently
     * used by acc-combined-plan to highlight annotations of
     * a selected issue. This enables this behaviour.
     */
    @Input()
    highlightMarkers = false;

    /** Exit, with changes to be saved */
    @Output()
    save = new EventEmitter<MarkedPlan>();

    /** Exit, without saving changes */
    @Output()
    exit = new EventEmitter<void>();

    /**
     * An event which will be emitted if the user clicks on
     * an annotation. The event parameter is the marker id.
     */
    @Output()
    annotationClicked = new EventEmitter<string>();

    constructor(
        // show different dialogs if: revision change (unsaved changes), leaving (unsaved changes), export (reset zoom)
        private dialog: MatDialog,

        // shows untranslated 'No object is selected' if one tried to delete
        // something when nothing is selected => how can this occur? just throw
        // an exception and remove this snack bar dependency
        public snackBar: MatSnackBar,

        // current language for date pipe
        public translate: TranslateService,

        // store thumbnail
        private addAssetService: AddAssetService,

        // sets isMobile
        private _platform: Platform,

        // enabling / disabling export button
        public assetDownloadService: AssetDownloadService,

        // TODO: hotfix for hiding the loading spinner / applying the changes made in functions
        //       called from ngAfterViewInit
        private cdr: ChangeDetectorRef,

        private eventAllowedCheckService: EventAllowedCheckService,
        private pinchPanService: PinchPanService,
        private pinchPanTransformService: PinchPanTransformService
    ) {
        this.isMobile = !!(this._platform.ANDROID || this._platform.IOS) || this.cordovaPlatform === 'ios';
    }

    //#region Global Getters

    /** Could be `_planRatioCurrentValue` or `_planRatioInitialValue */
    private get _currentRatio(): number {
        return this._planRatioCurrentValue || this._planRatioInitialValue;
    }

    private get _scallingMax(): number {
        if (!this._scallingMaxValue) {
            this._scallingMaxValue = PlanPreviewComponent._scallingMaxConst;
        }
        return this._scallingMaxValue;
    }

    private get _scallingMin(): number {
        if (!this._scallingMinValue) {
            this._scallingMinValue = Math.min(
                this._planRatioInitialValue,
                PlanPreviewComponent._scallingMinConst
            );
        }
        return this._scallingMinValue;
    }
    //#endregion

    //#region vars

    private _plan: HTMLImageElement | null = null;
    private _marker: HTMLImageElement;
    private _markerHighlighted: HTMLImageElement;
    private _canvasPlan: HTMLCanvasElement;
    private _ctxPlan: CanvasRenderingContext2D;
    private _planRatioInitialValue: number;
    private _constPlanRatioInitialValue: number;
    private _constinitialshiftX: number;
    private _constinitialshiftY: number;
    private _planRatioCurrentValue: number;
    private _canvasAnnotater: HTMLCanvasElement;
    private _ctxAnnotater: CanvasRenderingContext2D;

    events = [];

    /**
     * The current x offset of the plan.
     */
    private _shiftX: number;
    /**
     * The current y offset of the plan.
     */
    private _shiftY: number;
    /**
     *  I found the following comment, which I think applies here:
     *  We are dragging the object or the second part of a tool. *
     */
    private _dragging = false;
    private _objectDragging = false;
    public _isLoading = true;
    private _draggedShapeIndex: number | undefined;
    private _dragStart: Vec2D = Vec2D.of(0, 0);
    private _scallingMaxValue: number;
    private _scallingMinValue: number;

    // Annotations Array
    private _savedannotations: MarkerAnnotation[] = [];
    // used in revisions and `trackChanges`
    private _fetchedAnnotations: MarkerAnnotation[] = [];

    private _fetchedRevisions: Revision[] = [];

    // front Annotation Array
    public fetchedMatSelectRevision: ISelectRevision[] = [];
    public selectedRevision: ISelectRevision;

    // Fetched MarkedPlan
    private _fetchedMarkedPlan: MarkedPlan;

    public isEdited = false;
    public isRevisionMode = false;
    public isRevisionFetched = false;
    public isMobile = false;
    private selectedAnnotationIndex: number | undefined;
    public isAnnotationSelected = false;
    // Drawing vars
    private x0: number;
    private y0: number;

    private lastWidth: number;
    private lastHeight: number;
    private sizeIntervalHandle: NodeJS.Timeout | null = null;

    private lastPinchPan?: LastPinchPan;

    @ViewChild('canvasWrapper', { static: true }) canvasWrapper: ElementRef<HTMLDivElement>;

    /**
     * Base element where pinch/pan events should be registered to.
     */
    @ViewChild('zoomArea', { static: true })
    zoomArea: ElementRef<HTMLDivElement>;

    /**
     * A utility element to which the CSS translation is applied during
     * preview of pinch/pan.
     */
    @ViewChild('wrapperTranslation', { static: true })
    wrapperTranslation: ElementRef<HTMLDivElement>;

    /**
     * The canvas which contains the plan.
     */
    @ViewChild('planCanvas', { static: true }) planCanvas: ElementRef<HTMLCanvasElement>;

    /**
     * This canvas contains the annotations.
     */
    @ViewChild('annotaterCanvas', { static: true }) AnnotaterCanvas: ElementRef<HTMLCanvasElement>;

    private unsubscribe$ = new Subject<void>();

    /**
     * The currently selected tool mode from the sidebar. Note: When chaning
     * the default, also update the value in the html-template in the
     * mat-button-toggle-group.
     */
    currentToolboxCursorMode: ToolboxCursorMode = ToolboxCursorMode.Move;

    /**
     * The strategy to use (for the currently selected tool). It is null
     * for unsupported tools or if the user is only pinching / panning the
     * plan.
     */
    private toolStrategy: TwoPointToolStrategy | null = null;

    //#endregion

    // shortcut to delete the selected element
    @HostListener('document:keydown.backspace', ['$event'])
    @HostListener('document:keydown.delete', ['$event'])
    onKeydownHandler(event: KeyboardEvent): void {
        if (this.isDeleteAllowed()) {
            event.preventDefault();
            this.deleteSelectedAnnotation();
        }
    }

    @HostListener('window:resize')
    onResize(): void {
        this.updateCanvasSize();

        // queue plan update: this is done to prevent premature loading
        // of the plan in case of the component is already being destroyed
        // (this can happen if the plan-preview is destroyed right after
        //  resizing and the related plan was already unloaded)
        setTimeout(() => {
            // verify that this component has not been destroyed yet
            if (!this.unsubscribe$.isStopped) {
                // reset zoom, otherwise bugs are introduced: see comment on
                // zoom reset on plan change
                this._planRatioCurrentValue = undefined;
                // TODO: this method is slow...
                this.initialDrawPlan(this._planAsset.documentPath);
            }
        });
    }

    public ngOnDestroy(): void {
        // DO NOT REMOVE !!!!
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        // clear all canvas references, because the PlanPreviewComponent is being kept in memory
        // by some component

        this._plan = null;
        this._marker = null;
        this._canvasPlan = null;
        this._ctxPlan = null;
        this._canvasAnnotater = null;
        this._ctxAnnotater = null;

        this.canvasWrapper = null;
        this.planCanvas = null;
        this.AnnotaterCanvas = null;

        this.cleanUpIntervalHandle();
    }

    private cleanUpIntervalHandle(): void {
        if (this.sizeIntervalHandle) {
            clearInterval(this.sizeIntervalHandle);
            this.sizeIntervalHandle = null;
        }
    }

    //#region Global ngInit

    refreshMarkedPlan(): void {
        this._fetchedMarkedPlan = cloneDeep({
            ...this.issue.markedPlan,
            ...this.markedPlanChanges,
        });

        // TODO: due to issue change this runs before ngAfterViewInit and other initialization logic
        if (this._ctxAnnotater && this._marker) {
            this.refreshRevisionsAnnotations();
        }
    }

    async ngOnInit() {
        if (this.readOnly) {
            this.currentToolboxCursorMode = ToolboxCursorMode.Move;
        }

        this.cordovaPlatform = (await Device.getInfo()).platform;
        this.isMobile = !!(this._platform.ANDROID || this._platform.IOS) || this.cordovaPlatform === 'ios';
    }


    /**
     * Register an event handler on `_canvasAnnotater`, and remove it on "unsubscribe"
     *
     * @param type "string" allowed because "mousewheel" isn't in HTMLElementEventMap
     * @param handler
     */
    setupEventHandler<K extends keyof HTMLElementEventMap>(
        type: K | string,
        handler: (event: HTMLElementEventMap[K]) => any,
    ): void {
        // These bind functions override the default "this" value for the handlers, which is a Canvas...
        const boundHandler = handler.bind(this) as (event: HTMLElementEventMap[K]) => any;
        this._canvasAnnotater.addEventListener(type, boundHandler, false);
        this.unsubscribe$.subscribe({
            complete: () => {
                // removeEventListener needs the same parameters to match the event listener for removal
                this._canvasAnnotater.removeEventListener(type, boundHandler, false);
            }
        });
    }


    async ngAfterViewInit(): Promise<void> {
        this._canvasPlan = this.planCanvas.nativeElement;
        this._ctxPlan = this._canvasPlan.getContext('2d');

        this._canvasAnnotater = this.AnnotaterCanvas.nativeElement;
        this._ctxAnnotater = this._canvasAnnotater.getContext('2d');

        this._marker = await this.loadImage(PlanPreviewComponent._markerPath);
        this._markerHighlighted = await this.loadImage(PlanPreviewComponent._markerPathHighlighted);

        this.updateCanvasSize();

        this.initialDrawPlan(this._planAsset.documentPath);

        this.connectPinchZoomPanHandlers();

        // attach eventlisteners depending on the platform
        if (this.isMobile) {
            // touch events
            this.setupEventHandler('touchstart', this.touchStartHandler);
            this.setupEventHandler('touchend', this.touchEndHandler);
            this.setupEventHandler('touchmove', this.touchMoveHandler);
        } else {
            // mouse events
            // "mousewheel" appears to be not quiet standard
            this.setupEventHandler('mousewheel', this.mouseWheelHandler);
            this.setupEventHandler('mousedown', this.mouseDownHandler);
            this.setupEventHandler('mouseup', this.mouseUpHandler);
            this.setupEventHandler('mousemove', this.mouseMoveHandler);
            this.setupEventHandler('mouseout', this.mouseUpHandler);
            this.setupEventHandler('dblclick', this.mouseDoubleClickHandler);
        }

        // TODO - Why is this in a timer?
        // TODO - Make a number, not NodeJS type
        this.sizeIntervalHandle = setInterval(() => {
            const size = this.getCanvasWrapperSize();

            const differentSize =
                size.width !== this.lastWidth ||
                size.height !== this.lastHeight;

            // prevent recognizing size changes if the element is not
            // visible, i.e. display: hidden.
            const hasSize = size.width > 0 && size.height > 0;

            if (differentSize && hasSize) {
                this.onResize();
                this.cdr.detectChanges();
            }
        }, 500);
    }

    private connectPinchZoomPanHandlers(): void {
        this.pinchPanService.init(this.zoomArea, this.isMobile);

        // handle preview via CSS transform
        this.pinchPanService.previewPinchPan$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (pinchPanState) => {
                    // prevent panning and pinching for other tools
                    if (!this.pinchPanAllowed()) {
                        // if some preview was applied previously for some reason
                        this.resetPinchPanPreview();
                        return;
                    }

                    const transform = this.pinchPanTransformService.createTransformFor(
                        pinchPanState,
                    );
                    const styles = this.pinchPanTransformService.createCSSTransformationsFor(
                        transform
                    );

                    // Note: This overwrites all other styles defined on the object.
                    this.setElementStyle(this.wrapperTranslation, styles.translation);

                    // Note: Potential performance optimization: Apply this scale to a wrapper element?
                    this.setElementStyle(this.planCanvas, styles.scale);
                    this.setElementStyle(this.AnnotaterCanvas, styles.scale);
                },
            });

        // apply real zoom via existing zoom / translation logic
        this.pinchPanService.pinchPan$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (pinchPanState) => {
                    if (!this.pinchPanAllowed()) {
                        // if some preview was applied previously for some reason
                        this.resetPinchPanPreview();
                        return;
                    }

                    const update = this.pinchPanTransformService.createTransformFor(
                        pinchPanState,
                    );

                    // updating the existing transformation is required instead of
                    // just applying the new one to take changes to existing translation
                    // due to new scale into account
                    const newTransform = Transform.updateExistingTransformation(
                        {
                            // this._currentRatio is a property having either
                            // the initial or current scale value if it's present
                            scale: this._currentRatio,
                            translationX: this._shiftX,
                            translationY: this._shiftY,
                        },
                        update,
                        PlanPreviewComponent._scallingMinConst,
                        PlanPreviewComponent._scallingMaxConst,
                    );

                    console.log('end gesture', newTransform);

                    this._planRatioCurrentValue = newTransform.scale;
                    this._shiftX = newTransform.translationX;
                    this._shiftY = newTransform.translationY;

                    this.resetPinchPanPreview();

                    // It's possible to pinch/pan before the image is loaded
                    if (this._plan) {
                        this.drawPlan(
                            this._plan,
                            this._currentRatio,
                            this._shiftX,
                            this._shiftY
                        );
                    }

                    this.redrawAnnotations(
                        this._currentRatio,
                        this._shiftX,
                        this._shiftY
                    );

                    this.lastPinchPan = {
                        endPosition: {
                            x: pinchPanState.center.x + pinchPanState.pan.x,
                            y: pinchPanState.center.y + pinchPanState.pan.y,
                        },
                        timestamp: Date.now(),
                        zoomArea: this.zoomArea.nativeElement.getBoundingClientRect(),
                    };
                },
            });

        this.pinchPanService.cancelPreview$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.resetPinchPanPreview();
                },
            });

        this.pinchPanService.pinchPanInProgress$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (pinchPanInProgress) => {
                    // do not allow click on annotation while pinch/pan is in
                    // progress or just ended
                    this.preventAnnotationClick = pinchPanInProgress;
                },
            });
    }

    /**
     * Breaks type safety, as setting "style" on HTML elements isn't standard
     * @param ref The element to set the style on.
     * @param style The style value.
     */
    setElementStyle(ref: ElementRef<HTMLElement>, style: string): void {
        (ref.nativeElement as any).style = style;
    }

    /**
     * Reset the pinch / pan preview by removing all applied CSS
     * transformations on the related DOM elements.
     */
    private resetPinchPanPreview(): void {
        this.setElementStyle(this.wrapperTranslation, '');
        this.setElementStyle(this.planCanvas, '');
        this.setElementStyle(this.AnnotaterCanvas, '');
    }

    /** True iff Toolbox is not Move */
    private pinchPanAllowed(): boolean {
        return this.currentToolboxCursorMode === ToolboxCursorMode.Move;
    }

    /** True iff Toolbox is not Move */
    private drawingMode(): boolean {
        return this.currentToolboxCursorMode !== ToolboxCursorMode.Move;
    }

    private updateCanvasSize(): void {
        const size = this.getCanvasWrapperSize();

        this._canvasAnnotater.width = size.width;
        this._canvasAnnotater.height = size.height;
        this._canvasPlan.width = size.width;
        this._canvasPlan.height = size.height;

        this.setLastSize(size);
    }

    private setLastSize(size: DOMRect): void {
        this.lastWidth = size.width;
        this.lastHeight = size.height;
    }

    private getCanvasWrapperSize(): DOMRect {
        const canvasWrapperDiv = this.canvasWrapper.nativeElement;

        // just using clientWidth / clientHeight returns
        // rounded up values of the fractional width / height
        // causing the canvas to be 1px bigger which in turn
        // causes scrollbars to appear...
        return canvasWrapperDiv.getBoundingClientRect();
    }

    //#endregion

    //#region Init



    /**
     * Calculates the initial image scale (image size vs display size, and trigger a draw).
     *
     * Content was repeated between `resetScaling` and `initialDrawPlan`
     *
     * @returns The "initialShift" (was used differently in resetScaling and initialDrawPlan)
     */
    applyAutoScaling(): Vec2D {
        // Note that `_plan` could be null, if pinch/pan events are being emitted before the image is loaded
        const hRatio = this._plan ? this._canvasPlan.width / this._plan.width : 1;
        const vRatio = this._plan ? this._canvasPlan.height / this._plan.height : 1;
        // If the screen is larger than the image in both dimensions, cap scale to 100%
        this._planRatioInitialValue = Math.min(hRatio, vRatio, 1);

        const initialShiftX = this._plan ? (this._canvasPlan.width - this._plan.width * this._planRatioInitialValue) / 2 : 0;
        const initialShiftY = this._plan ? (this._canvasPlan.height - this._plan.height * this._planRatioInitialValue) / 2 : 0;

        this.drawPlan(
            this._plan,
            this._planRatioInitialValue,
            initialShiftX,
            initialShiftY
        );
        return { x: initialShiftX, y: initialShiftY };
    }

    // Called from UI button
    resetScaling(): void {
        const initialShift = this.applyAutoScaling();

        this.redrawAnnotations(
            this._planRatioInitialValue,
            initialShift.x,
            initialShift.y,
        );
        this._planRatioCurrentValue = this._planRatioInitialValue;
    }

    private async initialDrawPlan(path: string): Promise<void> {
        // TODO - This seems to be able to load the file repeatedly
        this._plan = await (this._planAsset.isPDF ? this.loadPDF(path) : this.loadImage(path));

        const initialShift = this.applyAutoScaling();

        this._constPlanRatioInitialValue = this._planRatioInitialValue;
        this._constinitialshiftX = initialShift.x;
        this._constinitialshiftY = initialShift.y;

        this.refreshRevisionsAnnotations();
    }

    refreshRevisionsAnnotations(): void {
        // clear all annotations, subsequent calls to it are guarded
        // by annotations.length > 0
        this._ctxAnnotater.clearRect(
            0,
            0,
            this._canvasPlan.width,
            this._canvasPlan.height
        );
        this._savedannotations = [];

        if (this._fetchedMarkedPlan != null) {
            this._fetchedAnnotations = cloneDeep(
                this._fetchedMarkedPlan.markerAnnotations
            );
            this._fetchedRevisions = cloneDeep(
                this._fetchedMarkedPlan.revisions
            );

            // Annotations
            if (this._fetchedAnnotations?.length > 0) {
                // adding the current marked plan in the revision Selectbox

                const currentRevisionItem: MarkedPlan = {
                    markerAnnotations: this._fetchedAnnotations,
                };

                this.fetchedMatSelectRevision = [{
                    revision: {
                        createTime: this._fetchedMarkedPlan.updateDateTime,
                        revisionItem: currentRevisionItem,
                    },
                    tag: 'Current',
                }];

                this._savedannotations = cloneDeep(this._fetchedAnnotations);
                this.redrawAnnotations(
                    this._currentRatio,
                    this._shiftX,
                    this._shiftY
                );
            }
            // Revisions
            if (this._fetchedRevisions?.length > 0) {
                for (let i = this._fetchedRevisions.length - 1; i >= 0; i--) {
                    const tempMergedRevision: ISelectRevision = {
                        revision: this._fetchedRevisions[i],
                        tag: `Revision ${i + 1}`,
                    };

                    this.fetchedMatSelectRevision.push(tempMergedRevision);
                }
                // console.log(this.fetchedMatSelectRevision);

                this.selectedRevision = this.fetchedMatSelectRevision[0];
                // Show revision select box
                this.isRevisionFetched = true;
            }
        }
        this._isLoading = false;

        // TODO: AfterViewInit which calls this function for the first time is ran after checking for changes
        //       manually force change detection (use another init event?)
        this.cdr.detectChanges();
    }

    //#endregion

    //#region LOADERS

    private loadImage(path: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = path;
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
        });
    }

    private loadPDF(path: string): Promise<any> {
        return new Promise((resolve, reject) => {
            // TODO: PDFJS types
            (PDFJS as any).disableWorker = true; // TODO - this probably doesn't work in any recent version
            PDFJS.getDocument(path).promise.then(
                (pdf: PDFDocumentProxy) => {
                    pdf.getPage(1).then(
                        (page) => {
                            const scale = 1;
                            const viewport: PDFPageViewport = page.getViewport({
                                scale
                            });

                            const canvas: HTMLCanvasElement = document.createElement('canvas');
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;

                            const context: CanvasRenderingContext2D = canvas.getContext('2d');
                            const task: PDFRenderTask = page.render({
                                canvasContext: context,
                                viewport: viewport,
                            });

                            task.promise.then(
                                () => {
                                    pdf.destroy();

                                    const image = new Image();
                                    image.width = canvas.width;
                                    image.height = canvas.height;
                                    image.src = canvas.toDataURL();
                                    // context = null;
                                    // canvas = null;
                                    image.onload = () => resolve(image);
                                    image.onerror = (error) => reject(error);
                                },
                                (error) => console.error(error)
                            );
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
                },
                (error) => {
                    console.error(error);
                    reject(error);
                }
            );
        });
    }

    //#endregion

    //#region DRAWERS

    /**
     * Draw a plan onto a canvas with support for scaling and different positions. Also adds
     * a layer for UI elements like the current zoom level.
     *
     * @param img Plan Image
     * @param scalling Scaling factor for the plan
     * @param shiftX Position of the plan (x)
     * @param shiftY Position of the plan (y)
     * @param showUi Show the UI overlay (defaults to true)
     * @param ctx Use a different context than the default _ctxPlan
     */
    private drawPlan(
        img: HTMLImageElement,
        scalling: number,
        shiftX: number,
        shiftY: number,
        showUi: boolean = true,
        ctx: CanvasRenderingContext2D = this._ctxPlan
    ): void {
        this._shiftX = shiftX;
        this._shiftY = shiftY;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            this._shiftX,
            this._shiftY,
            img.width * scalling,
            img.height * scalling
        );
        if (showUi) {
            ctx.font = PlanPreviewComponent._scallingFont;
            ctx.fillStyle = PlanPreviewComponent._scallingFontColor;
            ctx.textAlign = PlanPreviewComponent._scallingTextAlign;
            ctx.fillText(
                `${Math.round(scalling * 100)}%`,
                ctx.canvas.width - 15,
                30
            );
        }
    }

    /**
     * Draw all annotation onto a canvas with support for scaling and different positions.
     *
     * @param scalling Scaling factor for the annotations map
     * @param shiftX Position of the annotations map (x)
     * @param shiftY Position of the annotations map (y)
     * @param highlight Use the same color for all elements when disabled (defaults to true)
     * @param ctx Use a different context than the default _ctxAnnotater
     */
    private redrawAnnotations(
        scalling: number,
        shiftX: number,
        shiftY: number,
        highlight: boolean = true,
        ctx: CanvasRenderingContext2D = this._ctxAnnotater
    ): void {
        // TODO - Why is this creating side effects?
        this._shiftX = shiftX;
        this._shiftY = shiftY;

        const transform: Transform = {
            scale: scalling,
            translationX: shiftX,
            translationY: shiftY,
        };


        // clear all existing markers to prepare for redrawing
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if (this._savedannotations?.length) {
            const index = this.selectedAnnotationIndex;
            const selected: undefined | MarkerAnnotation = index !== undefined && this._savedannotations[index];
            for (const _annotation of this._savedannotations) {
                // TODO - Why are side effects applied here? This modifies the _annotation reference...
                if (_annotation.color === '#000000') {
                    _annotation.color = PlanPreviewComponent._strokeColor;
                }

                const isSelected = selected === _annotation;
                const highlightedColor: string | undefined = isSelected ? PlanPreviewComponent._selectedColor : _annotation.color;
                const strokeColor: string =
                    highlight ? (highlightedColor || PlanPreviewComponent._strokeColor) // `color` could be undefined...
                        : PlanPreviewComponent._strokeColor;

                const drawArgs: DrawShapeArgs = {
                    ctx,
                    p1: Vec2D.transform(_annotation, transform),
                    p2: Vec2D.transform({ x: _annotation.x1, y: _annotation.y1 }, transform),
                    lineWidth: PlanPreviewComponent._lineWidth * this._currentRatio,
                    stroke: strokeColor,
                };

                switch (_annotation.type) {
                    case 'Marker':
                        // apply scaling on each marker before drawing
                        // (_marker.x * scalling) + this._shiftX  : transfor the marker x and y to the canvas coordinates after applying the scalling and the shift
                        // - ((img.width/2)* scalling) %  - ((img.height)* scalling) : move the marker svg so the pointer fits the user click
                        // scalling reduce

                        const isHighlighted: boolean =
                            highlight &&
                            this.highlightMarkers &&
                            _annotation.color === ISSUE_SELECTED_COLOR;

                        const img: HTMLImageElement = isHighlighted ? this._markerHighlighted : this._marker;

                        const wScaled = img.width * scalling;
                        const hScaled = img.height * scalling;
                        ctx.drawImage(
                            img,
                            0, 0,
                            img.width, img.height,
                            drawArgs.p1.x - wScaled / 2, // Shift to center on p1x
                            drawArgs.p1.y - hScaled,     // Shift by full height
                            wScaled, hScaled
                        );
                        break;
                    case 'Line':
                        DrawShape.arrow(drawArgs, true);
                        break;
                    case 'Arrow':
                        DrawShape.arrow(drawArgs);
                        break;
                    case 'Rectangle':
                        DrawShape.rectangle(drawArgs);
                        break;
                    case 'Circle':
                        DrawShape.oval(drawArgs);
                        break;
                    default:
                        unreachable_safe(_annotation.type);
                }
            }
        }
    }
    //#endregion

    //#region MOUSE EVENT HANDLERS

    private applyPointerDownForEvent(event: MouseEvent | TouchEvent): void {
        if (!this.drawingMode()) {
            return;
        }

        if (this.readOnly || this.isRevisionMode) {
            return;
        }

        const offset: Vec2D = this.getPlatformOffset(event);
        // Store the first contact point, as a reference for calculating the delta of future contract points
        this._dragStart = offset;

        if (this.toolStrategy) {
            this._dragging = true;
            const p = this.getXY(event);
            this.x0 = p.x;
            this.y0 = p.y;
            return;
        }

        // The two tool strategy does not support item dragging, so not running
        // the following code is not an issue.

        const isOverAnnotation: boolean = this.shapeHitTest(offset) !== undefined;
        const isMoveAnnotationMode =
            this.currentToolboxCursorMode === ToolboxCursorMode.MoveAnnotation;

        if (isMoveAnnotationMode && isOverAnnotation) {
            // TODO: try to get the event of there
            this.handleMouseDown(event);
        }
    }

    private applyPointerMovementForEvent(event: MouseEvent | TouchEvent): void {
        // ensure that the cursor only changes when we have a single pointer
        if (!isMultitouchEvent(event)) {
            const rect = this._canvasPlan.getBoundingClientRect();
            const coordinatesViewport = this.getPlatformClientCoordinates(
                event,
                rect
            );
            this.updateCursor(coordinatesViewport);
        }

        if (!this.drawingMode()) {
            return;
        }

        // TODO: figure out if this is the same as the platformoffset
        const to = this.getXY(event);

        if (this.toolStrategy) {
            if (this._dragging) {
                // We are dragging the object or the second part of a tool.
                this.useTwoToolStrategyForPreview(to);
            }
            return;
        }
        const coordinatesPlatform: Vec2D = this.getPlatformOffset(event);

        const isMoveAnnotationMode =
            this.currentToolboxCursorMode === ToolboxCursorMode.MoveAnnotation;
        if (isMoveAnnotationMode) {
            this.onMouseMoveorMarker(coordinatesPlatform);
        }
    }

    private useTwoToolStrategyForPreview(coordinatesTo: Vec2D): void {
        this._ctxAnnotater.clearRect(
            0,
            0,
            this._canvasPlan.width,
            this._canvasPlan.height
        );

        this.toolStrategy.preview({
            ctx: this._ctxAnnotater,
            p1: {
                // These are set during touchstart / mousedown.
                x: this.x0,
                y: this.y0,
            },
            p2: coordinatesTo,
            lineWidth: PlanPreviewComponent._lineWidth * this._currentRatio,
            stroke: PlanPreviewComponent._strokeColor,
        });
    }

    private applyPointerUpForEvent(event: MouseEvent | TouchEvent): void {
        // ignore single clicks / touches on single markers during gestures
        const annotationClickAllowed =
            !this.preventAnnotationClick &&
            this.eventAllowedCheckService.eventAllowed(
                event,
                this.lastPinchPan
            );
        if (annotationClickAllowed) {
            this.emitAngularEventForAnnotationClick(event);
        }

        if (!this.drawingMode()) {
            return;
        }

        // TODO: refactor this part as well?
        if (this.readOnly || this.isRevisionMode) {
            return;
        }

        if (this.toolStrategy) {
            const rect = this._canvasPlan.getBoundingClientRect();
            const to = this.getPlatformClientCoordinates(event, rect);

            this.useTwoToolStrategyForNewAnnotation(to);
            return;
        }

        const isMouseUp = event.type === 'mouseup';
        const isTouchEnd = event.type === 'touchend';

        const inMarkerMode = this.currentToolboxCursorMode === ToolboxCursorMode.Marker;

        // Adding marker is only allowed for the specified event
        // types and we are not dragging another object currently.
        // TODO: the latter could be removed if moving should only be supported in move mode
        const addMarker = inMarkerMode && (isMouseUp || isTouchEnd) && !this._objectDragging;
        if (addMarker) {
            // TODO: try to move out the event?
            this.drawMarker(event);
        }

        // Note: Keep these below the condition above,
        //       to ensure that it operates on the state before this event
        this._dragging = false;
        this._objectDragging = false;
    }

    private useTwoToolStrategyForNewAnnotation(to: Vec2D): void {
        if (this.toolStrategy) {
            if (this._dragging) {
                this._dragging = false;

                const annotation = this.toolStrategy.createAnnotation({
                    fromTo: {
                        from: {
                            x: this.x0,
                            y: this.y0,
                        },
                        to,
                    },
                    withTransform: {
                        scale: this._currentRatio,
                        translationX: this._shiftX,
                        translationY: this._shiftY,
                    },
                    strokeColor: PlanPreviewComponent._strokeColor,
                });

                this._savedannotations.push(annotation);
                this.redrawAnnotations(
                    this._currentRatio,
                    this._shiftX,
                    this._shiftY
                );
                this.markPlanAsChanged();
            }
        }
    }

    private mouseDownHandler(event: MouseEvent): void {
        this.applyPointerDownForEvent(event);
    }

    private mouseMoveHandler(event: MouseEvent): void {
        this.applyPointerMovementForEvent(event);
    }

    private mouseUpHandler(event: MouseEvent): void {
        this.applyPointerUpForEvent(event);
    }

    private touchStartHandler(event: TouchEvent): void {
        this.events.push(event);

        this.applyPointerDownForEvent(event);
    }

    private touchMoveHandler(event: TouchEvent): void {

        this.applyPointerMovementForEvent(event);
    }

    private touchEndHandler(event: TouchEvent): void {

        this.applyPointerUpForEvent(event);
    }

    private mouseWheelHandler(
        event: any, // , //& {wheelDelta: number}, // TODO - Nonstandard property usedty used
        scalling: number = PlanPreviewComponent._scalling
    ): void {
        // TODO: use the zoom logic (preview + final draw) here as well?

        let multiplicator: number;
        let shiftX: number;
        let shiftY: number;

        // Zooming
        if (event.wheelDelta > 0) {
            // Maximum Zooming Reached
            if (scalling * this._currentRatio > this._scallingMax) {
                return;
            }

            multiplicator = scalling * this._currentRatio;
            shiftX =
                this._shiftX - (event.offsetX - this._shiftX) * (scalling - 1);
            shiftY =
                this._shiftY - (event.offsetY - this._shiftY) * (scalling - 1);
        } else {
            if (this._currentRatio / scalling < this._scallingMin) {
                return;
            }
            multiplicator = this._currentRatio / PlanPreviewComponent._scalling;
            shiftX =
                this._shiftX +
                (event.offsetX - this._shiftX) * (1 - 1 / scalling);
            shiftY =
                this._shiftY +
                (event.offsetY - this._shiftY) * (1 - 1 / scalling);
        }

        this._planRatioCurrentValue = multiplicator;
        this.drawPlan(this._plan, multiplicator, shiftX, shiftY);
        this.redrawAnnotations(multiplicator, shiftX, shiftY);
    }

    // TODO: disable when double tap is added
    private mouseDoubleClickHandler(event: MouseEvent): void {
        if (!this.drawingMode()) {
            return;
        }

        this.mouseWheelHandler(
            {
                offsetX: event.offsetX,
                offsetY: event.offsetY,
                wheelDelta: 120,
            },
            PlanPreviewComponent._scallingDblclick
        );
    }

    //#region Helpers

    // TODO - Fix assignemtn
    private updateCursor(coordinatesViewport: Vec2D): void {
        document.body.style.cursor = 'default';
        if (!this._dragging && !this._objectDragging) {
            // change cursor onObjectHover
            const markerHits = this.shapeHitTest(coordinatesViewport);
            let newCursor = 'default';
            if (markerHits !== undefined) {
                const isMoveAnnotationMode = this.currentToolboxCursorMode === ToolboxCursorMode.MoveAnnotation;
                if (!this.readOnly && isMoveAnnotationMode) {
                    newCursor = 'move';
                } else if (this.readOnly) {
                    newCursor = 'pointer';
                }
            }
            document.body.style.cursor = newCursor;
        }
    }

    private onMouseMoveorMarker(coordinatesPlatform: Vec2D): void {
        if (this._objectDragging) {
            this.dragObject(coordinatesPlatform);
        }
    }

    private dragObject(coordPlatform: Vec2D): void {
        this.markPlanAsChanged();

        // calc how much the mouse has moved since we were last here; apply ratio
        const dx = (coordPlatform.x - this._dragStart.x) / this._currentRatio;
        const dy = (coordPlatform.y - this._dragStart.y) / this._currentRatio;

        // set the lastXY for next time we're here
        this._dragStart = coordPlatform;

        // handle drags/pans
        if (this._draggedShapeIndex !== undefined) {
            // we're dragging images

            const object: MarkerAnnotation = this._savedannotations[this._draggedShapeIndex];

            switch (object.type) {
                // Single point
                case 'Marker':
                    object.x += dx;
                    object.y += dy;
                    break;

                // Dual points
                case 'Arrow': // Fallthrough intended
                case 'Rectangle':
                case 'Circle':
                case 'Line': // Line included for completeness here, but may not be supported elsewhere
                    object.x += dx;
                    object.y += dy;
                    object.x1 += dx;
                    object.y1 += dy;
                    break;
                default:
                    unreachable_safe(object.type);
            }
        }

        // this.drawPlan(this._plan, this._currentRatio,  this._shiftX, this._shiftY);
        this.redrawAnnotations(this._currentRatio, this._shiftX, this._shiftY);
    }

    /**
     * (Check to) mark the current plan as changed.
     * Set `isEdited` to true/false after checking
     */
    markPlanAsChanged(): void {
        // TODO - Triple Equals?
        this.isEdited =
            (this.fetchedMatSelectRevision == null) ||
            (this.selectedRevision === this.fetchedMatSelectRevision[0]) ||
            (this._fetchedRevisions == null && this._fetchedAnnotations != null);
    }


    /** Add a marker */
    drawMarker(event: MouseEvent | TouchEvent): void {
        this.markPlanAsChanged();
        const rect = this._canvasPlan.getBoundingClientRect();

        const target = this.getPlatformClientCoordinates(event, rect);

        const shiftedX = (target.x - this._shiftX) / this._currentRatio;
        const shiftedY = (target.y - this._shiftY) / this._currentRatio;
        this._savedannotations = [
            ...this._savedannotations,
            {
                type: MarkerAnnotation.TypeEnum.Marker,
                id: generateAnnotationID(),
                x: shiftedX,
                y: shiftedY,
                width: 73, // TODO - Magic number, which doesn't agree with the "marker width" in MarkerInfo
                height: 102, // TODO - Magic number, which doe
                x1: null,
                y1: null,
                color: PlanPreviewComponent._strokeColor,
            },
        ];
        this.redrawAnnotations(this._currentRatio, this._shiftX, this._shiftY);
    }

    // handleMouseDown, handleMouseUp, handleMouseMove to handle shape drag inside the canvas
    private handleMouseDown(event: MouseEvent | TouchEvent): void {
        const rect = this._canvasPlan.getBoundingClientRect();
        const target = this.getPlatformClientCoordinates(event, rect);

        this._draggedShapeIndex = this.shapeHitTest(target);

        if (this._draggedShapeIndex !== undefined) {
            // TODO - Should this sort circuit return be higher up? Like before the expensive hit test? The else statement won't be triggered
            // Do nothing if Revision Mode is true
            if (this.readOnly || this.isRevisionMode) {
                return;
            }
            this._objectDragging = true;
            this.isAnnotationSelected = true;
            this._dragging = false;

            this.selectedAnnotationIndex = this._draggedShapeIndex;

            this.redrawAnnotations(
                this._currentRatio,
                this._shiftX,
                this._shiftY
            );
        } else {
            this._dragging = true;
            this._objectDragging = true; // TODO - This is set to true in both branches
            this.isAnnotationSelected = false;
        }
    }

    private emitAngularEventForAnnotationClick(
        event: MouseEvent | TouchEvent
    ): void {
        if (event.type !== 'mouseup' && event.type !== 'touchend') {
            // mouseup handler also runs for mouseout to finish drawing
            // annotation if user moves cursor out of canvas => prevent
            // opening issue details for unrelated event
            return;
        }

        const rect = this._canvasPlan.getBoundingClientRect();
        const target = this.getPlatformClientCoordinates(event, rect);

        // Perform a hit test here additionally, because the initial
        // mouse down / touch start event handlers may not have run
        // completly due to a multi touch gesture.
        const firstHit = this.shapeHitTest(target);
        // 0 -> false, thus explicitly check for null/undefined
        if (firstHit !== undefined && firstHit !== null) {
            const annotation = this._savedannotations[firstHit];
            this.annotationClicked.emit(annotation.id);
        }
    }

    /**
     * returns indexes for shapes that match position {x, y}
     */
    private shapeHitTest(p: Vec2D): number | undefined {
        const scaledLineWidth = PlanPreviewComponent._lineWidth * this._currentRatio;
        // Give a minimal fuzzy factor, but when zoomed in, we an use a thick line width instead
        const tolerance = Math.max(this.isMobile ? 20 : 5, scaledLineWidth / 2);
        const transform: Transform = {
            scale: this._currentRatio,
            translationX: this._shiftX,
            translationY: this._shiftY,
        };

        let defaultHit: number | undefined;

        if (this._savedannotations?.length) {
            for (let i = 0; i < this._savedannotations.length; i++) {
                const result: HitTestResult = HitTest.check({
                    p,
                    annotation: this._savedannotations[i],
                    transform,
                    tolerance,
                });
                // If we have a solid hit (i.e. were we are drawing the shape, like the edge)
                if (result.solidHit) {
                    return i;
                }
                // If we are inside, but not near a drawn part
                if (result.hit && defaultHit === undefined) {
                    defaultHit = i;
                }

            }
        }

        return defaultHit;
    }

    //#endregion
    //#endregion

    //#region Toolbox buttons handlers

    // set the currenStatus var depending on the selected value of the toggle group
    onStatusChange(value: string): void {
        this.toolStrategy = null;

        switch (value) {
            case 'move':
                this.currentToolboxCursorMode = ToolboxCursorMode.Move;
                break;
            case 'move-annotation':
                this.currentToolboxCursorMode = ToolboxCursorMode.MoveAnnotation;
                break;
            case 'marker':
                this.currentToolboxCursorMode = ToolboxCursorMode.Marker;
                break;
            case 'rect':
                this.currentToolboxCursorMode = ToolboxCursorMode.Rectangle;
                this.toolStrategy = new RectangleToolStrategy();
                break;
            case 'arrow':
                this.currentToolboxCursorMode = ToolboxCursorMode.Arrow;
                this.toolStrategy = new ArrowToolStrategy();
                break;
            case 'oval':
                this.currentToolboxCursorMode = ToolboxCursorMode.Oval;
                this.toolStrategy = new OvalToolStrategy();
                break;
            default:
        }
    }

    onRevisionChange(event: MatSelectChange): void {
        const tempSelectedRevision = event.value as ISelectRevision;
        this.selectedRevision = tempSelectedRevision;

        // toggle revision mode depending on the selectedRevision
        if (this.selectedRevision === this.fetchedMatSelectRevision[0]) {
            this.isRevisionMode = false;
            this.currentToolboxCursorMode = ToolboxCursorMode.Marker;
        } else {
            this.isRevisionMode = true;
        }

        if (!this.isEdited) {
            this._savedannotations = tempSelectedRevision.revision.revisionItem[
                'markerAnnotations'
            ] as MarkerAnnotation[];

            this.redrawAnnotations(
                this._currentRatio,
                this._shiftX,
                this._shiftY
            );
        }
        // if edited ask to save changes before as a new Revision
        else {
            const closeDialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translate.instant(
                        'DIALOG.PLAN_PREVIEW.DISCARD'
                    ),
                    question: this.translate.instant(
                        'DIALOG.PLAN_PREVIEW.DISCARD_MESSGAE'
                    ),
                    confirmLabel: this.translate.instant(
                        'DIALOG.PLAN_PREVIEW.DISCARD_BUTTON'
                    ),
                },
            });
            closeDialogRef.afterClosed().subscribe((answer) => {
                if (answer) {
                    this._savedannotations = tempSelectedRevision.revision
                        .revisionItem[
                        'markerAnnotations'
                    ] as MarkerAnnotation[];

                    this.isEdited = false;
                    this.redrawAnnotations(
                        this._currentRatio,
                        this._shiftX,
                        this._shiftY
                    );
                }
            });
        }
    }

    zoomIn(): void {
        this.mouseWheelHandler(
            {
                offsetX: this._canvasPlan.width / 2,
                offsetY: this._canvasPlan.height / 2,
                wheelDelta: 120,
            },
            PlanPreviewComponent._scallingDblclick
        );
    }

    zoomOut(): void {
        if (this._currentRatio / PlanPreviewComponent._scallingDblclick < this._scallingMin) {
            return;
        }
        const multiplicator = this._currentRatio / PlanPreviewComponent._scallingDblclick;

        const shiftX = this._shiftX + (this._canvasPlan.width / 2 - this._shiftX) * (1 - 1 / PlanPreviewComponent._scallingDblclick);
        const shiftY = this._shiftY + (this._canvasPlan.height / 2 - this._shiftY) * (1 - 1 / PlanPreviewComponent._scallingDblclick);

        this._planRatioCurrentValue = multiplicator;
        this.drawPlan(this._plan, multiplicator, shiftX, shiftY);
        this.redrawAnnotations(multiplicator, shiftX, shiftY);
    }

    deleteSelectedAnnotation(): void {
        if (this.isDeleteAllowed()) {
            if (this._savedannotations.length > 0) {
                this.markPlanAsChanged();
                this._savedannotations.splice(this.selectedAnnotationIndex, 1);
                this.redrawAnnotations(
                    this._currentRatio,
                    this._shiftX,
                    this._shiftY
                );
                this.isAnnotationSelected = false;
                this.selectedAnnotationIndex = null;
            }
        }
    }


    //#endregion

    //#region Helpers


    /**
     * Return the coordinates of the first touch or click from the given
     * event in plan view coordinates. See `extractSingleClientTarget`
     * in `event-target.ts` for details on how its implemented.
     *
     * @param event The event to extract the coordinates from.
     * @param rect The bounding rectangle of the plan area.
     *
     * @throws See `extractSingleClientTarget`.
     *
     * @returns The extracted x/y coordinates as plan view coordinates.
     */
    private getPlatformClientCoordinates(
        event: MouseEvent | TouchEvent,
        rect: DOMRect
    ): Vec2D {
        const target = extractSingleClientTarget(event);

        return {
            x: target.clientX - rect.left,
            y: target.clientY - rect.top,
        };
    }

    // getX and getY
    getXY(event: MouseEvent | TouchEvent): Vec2D {
        const rect = this.AnnotaterCanvas.nativeElement.getBoundingClientRect();
        if (isTouchEvent(event)) {
            return {
                x: event.targetTouches[0].pageX - rect.left,
                y: event.targetTouches[0].pageY - rect.top,
            };
        } else {
            return {
                x: event.pageX - rect.left,
                y: event.pageY - rect.top,
            };
        }
    }


    /**
     * Used for object dragging and during annotations which need
     * two steps like arrows (x coordinate).
     *
     * Note: This function does not support the 'touchend' event.
     */
    private getPlatformOffset(event: MouseEvent | TouchEvent): Vec2D {
        // TODO - Type safety for event.target not having `getBoundingClientRect()`
        const rect = (event.target as any).getBoundingClientRect();
        console.log(rect);
        if (isTouchEvent(event) /* this.isMobile */) {
            return {
                x: event.targetTouches[0].pageX - rect.left,
                y: event.targetTouches[0].pageY - rect.top,
            };
        } else {
            return {
                x: event.offsetX,
                y: event.offsetY,
            };
        }
    }

    private isDeleteAllowed(): boolean {
        return (
            !this.readOnly &&
            !this.isRevisionMode &&
            this.selectedAnnotationIndex != null
        );
    }

    //#endregion

    //#region Export functions

    // export functions

    exportToImage(): void {
        if (this._constPlanRatioInitialValue === this._currentRatio) {
            this.overlayCanvasesAndSave(
                this._canvasPlan,
                this._canvasAnnotater
            );
        } else {
            // TODO: need translations
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: 'Zoom zurücksetzen?',
                    question: 'Zoom vor dem Export zurücksetzen?',
                },
            });

            dialogRef.afterClosed().subscribe((answer) => {
                if (answer) {
                    this.drawPlan(
                        this._plan,
                        this._constPlanRatioInitialValue,
                        this._constinitialshiftX,
                        this._constinitialshiftY
                    );
                    this.redrawAnnotations(
                        this._constPlanRatioInitialValue,
                        this._constinitialshiftX,
                        this._constinitialshiftY
                    );

                    this.overlayCanvasesAndSave(
                        this._canvasPlan,
                        this._canvasAnnotater
                    );
                } else {
                    this.overlayCanvasesAndSave(
                        this._canvasPlan,
                        this._canvasAnnotater
                    );
                }
            });
        }
    }

    private overlayCanvasesAndSave(
        cnv1: HTMLCanvasElement,
        cnv2: HTMLCanvasElement
    ): void {
        const newCanvas = document.createElement('canvas');
        const ctx = newCanvas.getContext('2d');

        newCanvas.width = cnv1.width;
        newCanvas.height = cnv1.height;

        [cnv1, cnv2].forEach((canvas): void => {
            ctx.beginPath();
            ctx.drawImage(canvas, 0, 0, cnv1.width, cnv1.height);
        });

        const imgData = newCanvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF({
            orientation: 'landscape',
        });

        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save('download.pdf');
    }

    async saveThumb(): Promise<Blob> {
        const dimensions: Rectangle = this.calcAnnotationDimensions({
            top: 0,
            left: 0,
            bottom: this._plan.height,
            right: this._plan.width
        });

        const scale = this._plan.width > this._plan.height ?
            Math.min(this._plan.width, PlanPreviewComponent._thumbnailMaxSize.width) / this._plan.width :
            Math.min(this._plan.height, PlanPreviewComponent._thumbnailMaxSize.height) / this._plan.height;

        const scaledRect = this.shrinkToScale(dimensions, scale);

        const planCanvas = document.createElement('canvas');
        planCanvas.width = Rectangle.getWidth(scaledRect);
        planCanvas.height = Rectangle.getHeight(scaledRect);
        const planCtx = planCanvas.getContext('2d');

        const annotationsCanvas = document.createElement('canvas');
        annotationsCanvas.width = planCanvas.width;
        annotationsCanvas.height = planCanvas.height;
        const annotationsCtx = annotationsCanvas.getContext('2d');

        // since the position of the annotation is based on the plans top-left corner the plan
        // might need to be moved by some value to the right and/or bottom to fit everything on
        // the canvas
        const shiftX = dimensions.left < 0 ? Math.abs(dimensions.left) : 0;
        const shiftY = dimensions.top < 0 ? Math.abs(dimensions.top) : 0;

        planCtx.drawImage(this._plan, shiftX, shiftY, this._plan.width * scale, this._plan.height * scale);

        this.redrawAnnotations(scale, shiftX, shiftY, false, annotationsCtx);

        return this.overlayCanvasesForThumb(
            planCanvas,
            annotationsCanvas
        );
    }

    /**
     * Calculate a rectangle that fits inside maxWidth and maxHeight and keeps the same aspect ratio
     * as the original one. Beware that this function assumes that the top left corner is always
     * negative or zero.
     */
    private shrinkToScale(rectangle: Rectangle, scale: number): Rectangle {
        return {
            top: rectangle.top * scale,
            left: rectangle.left * scale,
            bottom: rectangle.bottom * scale,
            right: rectangle.right * scale
        };
    }

    /**
     * Find the minimum dimensions required to fit all annotations and the rectangle given by def.
     * Positions are relative to the top left corner of def.
     *
     * @param rect The minimum rectangle that is returned even if no annotations are present.
     */
    private calcAnnotationDimensions(rect: Rectangle): Rectangle | undefined {
        const halfLineWidth = PlanPreviewComponent._lineWidth / 2;

        function getBoundry(it: MarkerAnnotation): Rectangle {
            return {
                left: Math.min(it.x, it.x1) - halfLineWidth,
                right: Math.max(it.x, it.x1) + halfLineWidth,
                top: Math.min(it.y, it.y1) + halfLineWidth,
                bottom: Math.max(it.y, it.y1) - halfLineWidth,
            };
        }

        for (const it of this._savedannotations) {
            const isMarker: boolean = it.type === 'Marker';
            const newRect = isMarker ? MarkerInfo.getMarkerBoundry(it) : getBoundry(it);
            rect = Rectangle.growToInclude(rect, newRect);
        }

        return rect;
    }

    /**
     * Stacks one or more canvas(es) on top of each other and applies the scaling factor.
     *
     * @param scaling Scaling factor, must be between 0 (0%) and 1 (100%)
     * @param canvases Canvases that should be drawn
     * @returns A Blob containing all given canvases.
     */
    overlayCanvasesForThumb(
        ...canvases: HTMLCanvasElement[]
    ): Promise<Blob> {
        return new Promise((resolve) => {
            if (canvases.length === 0) {
                return resolve(new Blob());
            }

            const newCanvas = document.createElement('canvas');
            const ctx = newCanvas.getContext('2d');

            newCanvas.width = canvases[0].width;
            newCanvas.height = canvases[0].height;

            canvases.forEach((n: HTMLCanvasElement): void => {
                ctx.drawImage(n, 0, 0, newCanvas.width, newCanvas.height);
            });

            newCanvas.toBlob(resolve, 'image/png');
        });
    }

    async closeDialogWithSaving(): Promise<void> {
        this._isLoading = true;
        const file = await this.saveThumb();

        const isIdValid: boolean =
            this.issue?.id &&
            !AcceptUtils.isLocalGuid(this.issue.id);
        const dbDocumentId = isIdValid ? this.issue.id : 'attachmentcache';

        const result = await this.addAssetService.uploadAssetDbProxy({
            file,
            entityType: 'issue',
            entityId: dbDocumentId,
        });

        if (result) {
            let markedPlanToPost: MarkedPlan = {
                markerAnnotations: this._savedannotations,
                thumbnail: result.id,
            };
            if (this._fetchedMarkedPlan) {
                markedPlanToPost = {
                    ...markedPlanToPost,
                    updateDateTime: this._fetchedMarkedPlan.updateDateTime,
                };
            }
            this.save.emit(markedPlanToPost);
        } else {
            this.exit.emit();
        }
    }

    closeDialogWithoutSaving(): void {
        let closeDialogRef = null;
        if (!this.isEdited) {
            this.exit.emit();
        } else {
            closeDialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translate.instant('DIALOG.PLAN_PREVIEW.SAVE'),
                    question: '',
                },
            });

            closeDialogRef.afterClosed().subscribe(async (answer) => {
                if (!answer) {
                    this.exit.emit();
                    return;
                }
                this.closeDialogWithSaving();
            });
        }
    }
}
//#endregion
//#region Models
// TODO add to models
// TODO add to shared enums
/**
 * The different tools which are available.
 */
export enum ToolboxCursorMode {
    /** Move plan, e.g. via panning and pinching.  */
    Move,
    /** Move a specific annotation.  */
    MoveAnnotation,
    /** Add new markers.  */
    Marker,
    /** Add new arrows.  */
    Arrow,
    /** Add new rectangles.  */
    Rectangle,
    /** Add new circles.  */
    Oval,
}

// TODO  - Why does this have a "Mat" prefix?
export interface ISelectRevision {
    revision: Revision;
    tag: string;
}

//#endregion
