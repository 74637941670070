import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SettingsEffects } from './settings.effects';
import * as fromSettings from './settings.reducer';

@NgModule({
    declarations: [],
    imports: [
        EffectsModule.forFeature([SettingsEffects]),
        StoreModule.forFeature('settings', fromSettings.reducer)
    ],
    exports: [],
    providers: []
})
export class SettingsStoreModule {}
