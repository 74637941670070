<div class="spinner" *ngIf="loadingProjects; else showProjectGrids">
    <div class="spinner-overlay">
    </div>
    <div class="spinner-container">
        <mat-spinner color="accent" class="project-grid-spinner" [diameter]="80"></mat-spinner>
    </div>
</div>
<ng-template #showProjectGrids>
    <div class="dashboard-container">
        <mat-card class="project-info-container" *ngFor="let detail of projectSpecificDetail; trackBy: trackByProjectId"
            matRipple data-test="project-grid-tile"
            [ngStyle]="{ 'border-top': isSeverityHigh(detail) ? '5px solid red' : '5px solid #2C3C9B'}"
            [attr.data-test-severity]="isSeverityHigh(detail) ? 'project-severity-high' : 'project-severity-low'">
            <acc-project-grid-item [projectDetail]="detail" (updated)="updated.next($event)"
                (archived)="archived.next($event)" [class.archived]="detail.markedAsDelete"
                [isDevice]="isDevice$ | async" [projectsToSync]="projectsToSync$ | async">
            </acc-project-grid-item>
        </mat-card>
    </div>
</ng-template>