/// <reference types="cordova-plugin-file" />
/// <reference types="cordova-plugin-file-transfer" />

import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Mutex } from 'async-mutex';
import { RxCollection, RxDocument, uncacheRxQuery } from 'rxdb';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AcceptUtils } from 'app/core/utils/accept-utils';
import { SyncState } from 'app/store/sync/sync.reducer';
import { environment } from 'environments/environment';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { BulkInsertResult } from './bulk-insert-result';
import { Entity } from './entity';
import { EntityBlobMap } from './entity-blob-map';
import { EntityDbResponse } from './entity.db.response';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import {
    DownloadAttachmentsRequest,
    DownloadAttachmentsSuccess,
    FailedToDownloadAttachments,
} from 'app/store/sync/sync.actions';
import {
    AttachmentDownloaderService,
    DownloadResult,
    StoreRequest,
} from 'app/shared/services/attachment-storage/attachment-downloader.service';

/**
 * <T> EntityType
 * <D> DocumentType
 */
export abstract class EntityDbService<T extends Entity, D extends Entity> {
    // note: not all access is currently guarded via this mutex, only bulkInsert,
    //       the methods it calls and attachment id update
    private static mutex = new Mutex();

    constructor(
        public dbService: DatabaseService,
        public http: HttpClient,
        public store: Store<SyncState>,
        protected fileStorageService: FileStorageService,
        protected attachmentDownloaderService: AttachmentDownloaderService,
        private collectionName: Collections
    ) {}

    async getAll(): Promise<EntityDbResponse<D>> {
        const data = await this.collection.dump(true);
        return {
            meta: { status: true, count: data.docs.length },
            data: data.docs,
        };
    }

    get collection(): RxCollection<D> {
        return this.dbService.db[this.collectionName.toString()];
    }

    async getById(id: string): Promise<RxDocument<D>> {
        if (!id) {
            // if the id is undefined the query matches a
            // document and returns something completly unrelated
            throw Error('entityId is undefined');
        }

        const getByIdQuery = this.collection.findOne({
            selector: {
                id: id,
            },
        });

        // RxDB returns a stale document otherwise (the document was updated using
        // the underlying PouchDB API) => clear relevant caches
        uncacheRxQuery(this.collection._queryCache, getByIdQuery);
        this.collection._docCache.delete(id);

        const doc = await getByIdQuery.exec();

        return doc;
    }

    async getByProjectId(projectId: string): Promise<EntityDbResponse<D>> {
        const projectIdQuery = this.collection
            .find()
            .where('projectId')
            .equals(projectId);
        uncacheRxQuery(this.collection._queryCache, projectIdQuery);
        const docs = await projectIdQuery.exec();

        const data = docs.map((b) => b.toJSON());

        return {
            meta: { status: true, count: data.length },
            data,
        };
    }

    async getByOwnerId(ownerId: string): Promise<EntityDbResponse<D>> {
        const ownerIdQuery = this.collection
            .find()
            .where('ownerId')
            .equals(ownerId);
        uncacheRxQuery(this.collection._queryCache, ownerIdQuery);
        const docs = await ownerIdQuery.exec();

        const data = docs.map((b) => b.toJSON());

        return {
            meta: { status: true, count: data.length },
            data,
        };
    }

    async getStoredDocs(projectId?: string): Promise<RxDocument<D>[]> {
        return projectId
            ? await this.collection
                  .find()
                  .where('projectId')
                  .equals(projectId)
                  .exec()
            : await this.collection.find().exec();
    }

    async getByIds(ids: string[]): Promise<(T & PouchDB.Core.AllDocsMeta)[]> {
        if (ids.length === 0) {
            return [];
        }

        const options = {
            include_docs: true,
            keys: ids,
        };

        const allDocs = await this.collection.pouch.allDocs(options);

        const result = allDocs.rows
            .filter((r) => r.doc)
            .map((r) => {
                return r.doc;
            });
        return result;
    }

    async getOrPutDocumentById(
        id: string
    ): Promise<T & PouchDB.Core.AllDocsMeta> {
        try {
            const dbDocument = await this.collection.pouch.get(id);
            return dbDocument;
        } catch (error) {
            if (error.status === 404) {
                await this.collection.pouch.put({
                    _id: id,
                    id,
                });
                const createdDocument = await this.collection.pouch.get(id);
                return createdDocument;
            }
        }
    }

    async downloadRequiredAttachments(entities: D[]): Promise<StoreRequest[]> {
        this.store.dispatch(
            new DownloadAttachmentsRequest({
                pending: entities.length,
            })
        );
        const entityBlobMap: EntityBlobMap = new Map();

        // attachments that we need to download to store in db
        const allAttachmentIdsToDownload: Set<string> = new Set();

        // FIXME: as before, new attachment versions are not handled
        const existingIds = await this.fileStorageService.getStoredAttachmentIds();

        for (const entity of entities) {
            const entityAttachmentIds = this.getAttachmentIdsFromApiItem(
                entity
            );

            // attachment ids to download (not stored in db yet)
            const idsToDownload = entityAttachmentIds
                .filter((id) => !AcceptUtils.isDefaultGuid(id))
                .filter((id) => !existingIds.has(id));

            if (idsToDownload.length > 0) {
                idsToDownload.forEach((id) => {
                    allAttachmentIdsToDownload.add(id);
                });

                // add entry to attachmentIdDictionary
                // connection entity.id => attachmentIds
                const emptyBlobs = idsToDownload.map((id) => ({
                    id,
                    blob: null,
                }));
                entityBlobMap.set(entity.id, emptyBlobs);
            }
        }

        const blobResults: DownloadResult[] = await this.attachmentDownloaderService.downloadAttachments(
            allAttachmentIdsToDownload
        );

        const storeRequests: StoreRequest[] = [];
        const failedDownloads: { id: string; error: any }[] = [];
        for (const blobResult of blobResults) {
            if (blobResult.error) {
                // skip blob which could not be downloaded
                console.error(
                    `failed to download attachment ${blobResult.id}:`,
                    blobResult.error
                );
                failedDownloads.push({
                    id: blobResult.id,
                    error: blobResult.error,
                });
            } else {
                storeRequests.push(blobResult.storeRequest);
            }
        }

        if (failedDownloads.length > 0) {
            this.store.dispatch(
                new FailedToDownloadAttachments({
                    failedDownloads,
                })
            );
        } else {
            this.store.dispatch(new DownloadAttachmentsSuccess());
        }

        return storeRequests;
    }

    /**
     * compares entities to write with stored db docs
     * first, downloads all attachments that are not stored
     * in db yet. Removes attachmentIds from db docs
     * that are not existend on entity anymore.
     */
    async bulkInsert(entities: T[]): Promise<BulkInsertResult<D>> {
        const release = await EntityDbService.mutex.acquire();
        // TODO: Think about error handling...

        try {
            const emptyResult = {
                success: [],
                error: [],
            };
            // only entries that are not markedAsDelete
            // transform to db schema

            // TODO we need to change that behaviour
            // TODO if an issue got marked as delete, we can remove it from the database
            // TODO consider doing deleting deletable issues in a separate handler
            const preparedEntities = entities
                .filter((e) => !e.markedAsDelete)
                .map((entity) => this.apiItemToSchema(entity));

            const entitiesToDelete = entities.filter((e) => e.markedAsDelete);

            if (
                preparedEntities.length === 0 &&
                entitiesToDelete.length === 0
            ) {
                return emptyResult;
            }

            const entityIdsToUpdate = preparedEntities.map((e) => e.id);
            const entityIdsToDelete = entitiesToDelete.map((e) => e.id);
            // stored entity docs
            const docsToUpdate = await this.getByIds(entityIdsToUpdate);
            const docsToDelete = await this.getByIds(entityIdsToDelete);
            const docsToUpdateMap: Map<
                string,
                T & PouchDB.Core.AllDocsMeta
            > = new Map();
            for (const doc of docsToUpdate) {
                docsToUpdateMap.set(doc.id, doc);
            }
            const docsToDeleteMap: Map<
                string,
                T & PouchDB.Core.AllDocsMeta
            > = new Map();
            for (const doc of docsToDelete) {
                docsToDeleteMap.set(doc.id, doc);
            }

            // update exising docs and add new docs
            // filter db updates where entity shares same updateDateTime as db doc
            const dbUpdates = preparedEntities
                .filter((entity) => {
                    const doc = docsToUpdateMap.get(entity.id);
                    return this.shouldUpdateEntity(doc, entity);
                })
                .map((entity) => {
                    const update = {
                        _id: entity.id,
                        ...entity,
                    };

                    const storedDoc = docsToUpdateMap.get(entity.id);

                    return {
                        ...storedDoc,
                        ...update,
                    };
                });

            const dbDeletes = entitiesToDelete
                .filter((entity) => docsToDeleteMap.get(entity.id))
                .map((entity) => {
                    const doc = docsToDeleteMap.get(entity.id);
                    return {
                        ...doc,
                        markedAsDelete: true,
                        _deleted: true,
                    };
                });

            const bulkOperations = [...dbUpdates, ...dbDeletes];

            // write changes to DB

            let results: {
                ok: boolean;
                id: string;
                rev: string;
            }[] = [];

            results = await this.collection.pouch.bulkDocs(bulkOperations);

            const storeRequests = await this.downloadRequiredAttachments(
                preparedEntities
            );

            // debug information
            const attachmentsDownloaded = storeRequests.length;
            if (attachmentsDownloaded > 0) {
                const entityCount = docsToUpdate.length;

                console.log(
                    'downloaded',
                    attachmentsDownloaded,
                    'attachments for ',
                    entityCount,
                    this.collectionName
                );
            }

            const anyBlobStores = storeRequests.find(
                (request) => request.type === 'blob-to-store'
            );

            if (anyBlobStores) {
                for (const storeRequest of storeRequests) {
                    await this.fileStorageService.storeAttachment(storeRequest);
                }
            } else {
                await this.fileStorageService.bulkStoreMeta(storeRequests);
            }

            if (!environment.production) {
                // sync with local running couchdb
                // this.syncCollection();
            }

            const error = results.filter((r) => !r.ok);
            const success = results.filter((r) => r.ok);
            if (success.length > 0) {
                console.log(
                    '',
                    success.length,
                    this.collectionName,
                    'written to db'
                );
            }

            for (const err of error) {
                console.error(err);
            }

            return {
                error,
                success,
            };
        } finally {
            release();
        }
    }

    private shouldUpdateEntity(
        doc: T & PouchDB.Core.AllDocsMeta,
        entity: D
    ): boolean {
        const entityNotInDb = !doc;
        const differentUpdateDateTime =
            doc?.updateDateTime !== entity.updateDateTime;

        const hasUntrackedChanges = this.hasUntrackedChanges(doc, entity);

        const shouldUpdateDbEntity =
            entityNotInDb ||
            !entity.updateDateTime ||
            differentUpdateDateTime ||
            hasUntrackedChanges;

        return shouldUpdateDbEntity;
    }

    private syncCollection(): void {
        this.collection.sync({
            remote: 'http://admin:TODO@127.0.0.1:5984/' + this.collectionName,
            direction: {
                pull: false,
                push: true,
            },
            options: {
                retry: false,
            },
        });
    }

    async upsert(entity: T): Promise<void> {
        const results = await this.bulkInsert([entity]);

        if (results.error.length === 1) {
            throw new Error(`failed to upsert entity '${entity.id}'`);
        }
    }

    async insert(entity: T): Promise<RxDocument<D>> {
        const release = await EntityDbService.mutex.acquire();
        try {
            return await this.collection.insert(this.apiItemToSchema(entity));
        } finally {
            release();
        }
    }

    abstract apiItemToSchema(entity: T): D;

    abstract getAttachmentIdsFromApiItem(entity: T | D): string[];

    /**
     * Check if the entity has changes (e.g. nested marked plans in issues) which
     * are not tracked by the updateDateTime of the entity.
     *
     * When not overriden in a subclass, this method just returns false. Thus do not
     * rely only on the result of this method to check for changes.
     *
     * @param _doc The currently stored document.
     * @param _entity A potentially updated entity.
     */
    hasUntrackedChanges(
        _doc: T & PouchDB.Core.AllDocsMeta,
        _entity: D
    ): boolean {
        return false;
    }
}
