import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogData } from './confirm-dialog.data';

@Component({
    selector: 'acc-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        const confirmLabelInjected = this.data.confirmLabel;

        if (!confirmLabelInjected) {
            this.data.confirmLabel = this.translate.instant('APP.CONFIRM');
        }
    }
}
