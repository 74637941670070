import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { DemoService } from './demo.service';
import { ErrorUtils } from 'app/core/utils/error-util';

import { DemoDialogComponent } from 'app/shared/components/dialogs/demo-dialog/demo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BASE_PATH_OVERRIDE_DEMO } from 'environments/env-common';
import { unreachable } from 'app/core/utils/ts-utils';

@Injectable()
export class DemoInterceptor implements HttpInterceptor {

    // Allowed URLS
    private static readonly whitelist: (string | RegExp)[] = [
        '/api/v1/account/login',
        // A guid has 36 characters, whitelist items for form letter printing.
        /^\/api\/v1\/serialletter\/createpdf\/.{36}\/.{36}$/,
        /^\/api\/v1\/standardserialletter\/createpdf\/.{36}$/,
    ];

    private isDemoMode = false;
    private isEditingDemo = false;

    constructor(
        private demoService: DemoService,
        private errorUtil: ErrorUtils,
        private dialog: MatDialog,
    ) {
        this.demoService.isDemoMode$.subscribe( {next: (val: boolean) => {
            this.isDemoMode = val;
        }});
        this.isEditingDemo = this.demoService.isEditingDemo;
    }

    /**
     * Create a predicate which can test a single whitelist item against the
     * given pathname. Either strings or regular expressions are currently
     * supported.
     *
     * @param pathname The URL pathname which will be used by the returned
     * predicate.
     */
    private static createWhitelistsURLPathnamePredicate(pathname: string): (whitelistItem: string | RegExp) => boolean {
        return whitelistItem => {
            if (typeof whitelistItem === 'string') {
                return whitelistItem === pathname;
            } else if (whitelistItem instanceof RegExp) {
                return whitelistItem.test(pathname);
            } else {
                unreachable(whitelistItem);
            }
        };
    }

    private shouldBlockRequestForDemo(req: HttpRequest<unknown>): boolean {
        if ( req.method === 'GET') {
            return false;
        }

        // Parse the URL (to extract the path)
        const url = new URL(req.url);
        const whitelistsURLPathname = DemoInterceptor.createWhitelistsURLPathnamePredicate(url.pathname);
        return ! DemoInterceptor.whitelist.some(whitelistsURLPathname);
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ( this.isDemoMode && !this.isEditingDemo && this.shouldBlockRequestForDemo(request) ) {
            // Show the demo functionality blocked dialog 
            this.dialog.open(DemoDialogComponent);
            // Supress warnings notifications for short time
            this.errorUtil.suppressForTime();
            const msg = `Blocked ${request.method} ${request.url}`;
            console.error(msg);
            return EMPTY;
        }
        return next.handle(request);
    }
}
