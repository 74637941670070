export class SetIssueIdOfPlanCommandsError extends Error {
    private static appendCause(message: string, cause: Error): string {
        message += `caused by: ${cause.message}`;
        return message;
    }

    static withMessage(message: string): SetIssueIdOfPlanCommandsError {
        return SetIssueIdOfPlanCommandsError.withCauses(message, []);
    }

    static withCause(
        message: string,
        cause: Error
    ): SetIssueIdOfPlanCommandsError {
        return SetIssueIdOfPlanCommandsError.withCauses(message, [cause]);
    }

    static withCauses(
        message: string,
        causes: Error[]
    ): SetIssueIdOfPlanCommandsError {
        let fullMessage = message;

        for (const [i, cause] of causes.entries()) {
            const firstCause = i === 0;
            if (firstCause) {
                fullMessage += '\n';
            }

            fullMessage = SetIssueIdOfPlanCommandsError.appendCause(
                fullMessage,
                cause
            );

            const notLastCause = i !== causes.length - 1;
            if (notLastCause) {
                fullMessage += '\nand ';
            }
        }

        return new SetIssueIdOfPlanCommandsError(fullMessage, causes);
    }

    protected constructor(message: string, public causes: Error[]) {
        super(message);
    }
}
