<mat-toolbar fxHide.xs class="p-0 mat-elevation-z1 accept-toolbar">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div
            class="indigo-700"
            fxFlex="0 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
                data-test="toggle-sidebar"
            >
                <img class="w-40" src="assets/images/logos/icon-60.png" />
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
                fxHide.gt-md
            ></div>
        </div>

        <div fxFlex fxLayout="row">
            <div
                class="search"
                fxFlex="1 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-icon class="mx-8 secondary-text">search</mat-icon>
                <mat-form-field
                    floatLabel="never"
                    fxFlex
                    class="app-input-no-underline"
                >
                    <input
                        [formControl]="searchControl"
                        matInput
                        [placeholder]="'APP.SEARCH' | translate"
                        data-test="search-top"
                    />
                </mat-form-field>
            </div>

            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <button
                    class="secondary-text"
                    mat-icon-button
                    [matMenuTriggerFor]="appMenu.matMenu"
                >
                    <mat-icon
                        [matBadgeHidden]="(totalNotifications$ | async) === 0"
                        [matBadge]="totalNotifications$ | async"
                        matBadgeColor="warn"
                        >notifications</mat-icon
                    >
                </button>

                <acc-notification #appMenu></acc-notification>

                <a
                    *ngIf="(isAdmin$ | async) && !(isDemoMode$ | async)"
                    routerLink="/admin"
                    routerLinkActive="active"
                >
                    <button
                        mat-icon-button
                        class="secondary-text"
                        data-test="global-settings-button"
                    >
                        <mat-icon class="global-settings">settings</mat-icon>
                    </button>
                </a>

                <button
                    mat-button
                    [matMenuTriggerFor]="userMenu"
                    class="user-button"
                    data-test="user-menu-button"
                >
                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        *ngLet="currentUser$ | async as currentUser"
                    >
                        <span class="username mr-4" fxHide fxShow.gt-sm>{{
                            currentUser.username
                        }}</span>
                        <mat-icon class="s-16" fxHide.xs
                            >keyboard_arrow_down</mat-icon
                        >

                        <img
                            class="avatar ml-0 ml-sm-12"
                            src="/assets/images/custom/avatar.svg"
                            *ngIf="!currentUser.contact?.photo; else withPhoto"
                        />

                        <ng-template #withPhoto>
                            <ng-container
                                *ngIf="
                                    assetUrl +
                                        currentUser.contact.photo +
                                        thumbnailSuffix
                                        | secure
                                        | async as backgroundImage
                                "
                            >
                                <img
                                    class="avatar ml-0 ml-sm-12"
                                    [src]="backgroundImage | safe: 'url'"
                                />
                            </ng-container>
                        </ng-template>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item (click)="openUserSettingsDialog()">
                        <mat-icon>perm_identity</mat-icon>
                        <span translate>APP.PROFILE_SETTINGS</span>
                    </button>
                    <button
                        mat-menu-item
                        (click)="logout()"
                        data-test="logout-button"
                    >
                        <mat-icon>exit_to_app</mat-icon>
                        <span translate>APP.LOGOUT</span>
                    </button>
                </mat-menu>
            </div>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        </div>
    </div>
</mat-toolbar>
