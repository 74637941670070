import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgLetModule } from '@ngrx-utils/store';
import { ResponsiveToolbarModule } from 'app/layout/components/responsive-toolbar/responsive-toolbar.module';
import { SharedModule } from 'app/shared/shared.module';
import { NotificationsModule } from '../../notifications/notifications.module';
import { ProjectSyncStatusComponent } from '../project-sync-status/project-sync-status.component';
import { ProjectFormComponent } from './project-form/project-form.component';
import { ProjectGridItemComponent } from './project-grid-item/project-grid-item.component';
import { ProjectGridComponent } from './project-grid/project-grid.component';
import { ProjectListToolbarComponent } from './project-list-toolbar/project-list-toolbar.component';
import { ProjectListComponent } from './project-list.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatCardModule } from '@angular/material/card';
import { ProjectTableComponent } from './project-table/project-table.component';
import { OfflineProjectGridComponent } from './offline-project-grid/offline-project-grid.component';
import { OfflineProjectGridItemComponent } from './offline-project-grid/offline-project-grid-item/offline-project-grid-item.component';

@NgModule({
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatIconModule,
        MatGridListModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        MatRippleModule,
        NgLetModule,
        NotificationsModule,
        ResponsiveToolbarModule,
        RouterModule,
        SharedModule,
        MatCardModule,
        NgxChartsModule,
    ],
    declarations: [
        ProjectGridComponent,
        ProjectGridItemComponent,
        ProjectListComponent,
        ProjectTableComponent,
        ProjectFormComponent,
        ProjectSyncStatusComponent,
        ProjectListToolbarComponent,
        OfflineProjectGridComponent,
        OfflineProjectGridItemComponent,
    ],
    exports: [ProjectListComponent],
    providers: [],
})
export class ProjectListModule {}
