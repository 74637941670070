import { Dictionary } from '@ngrx/entity';
import { Contact, Issue, Location as FlinkLocation } from 'app/core/rest-api';
import { LocationTreeTextPipe } from 'app/shared/pipes/location-tree-text.pipe';
import * as moment from 'moment';
import { FilterIssuesOptions } from './models/issue.filter-options';
import { IssueFilters } from './models/issue.filters';
import { IssueSorts } from './models/issue.sorts.enum';
import { IssueStatistics } from './models/issue.statistics';
import {
    countCustomAttributeFilters,
    filterByCustomAttributes,
} from './shared/custom-attribute-filter.utils';

export class IssuesUtils {
    static calculateTotalFilters(filters: IssueFilters): number {
        let total = 0;
        const doesNotCount = ['archived', 'revision'];
        for (const key in filters) {
            if (
                filters.hasOwnProperty(key) &&
                filters[key] &&
                !doesNotCount.includes(key)
            ) {
                if (key === 'customAttributes') {
                    total += countCustomAttributeFilters(filters[key]);
                } else {
                    total++;
                }
            }
        }
        return total;
    }

    static generateIssueStatistics(issues: Issue[]): IssueStatistics {
        return {
            total: issues.length,

            open: issues.filter((issue) => issue.state === 'Open').length,

            accepted: issues.filter((issue) => issue.state === 'Accepted')
                .length,

            extendedDeadline: issues.filter(
                (issue) => issue.state === 'ExtendedDeadline'
            ).length,

            done: issues.filter((issue) => issue.state === 'Done').length,
        };
    }

    static generateLocationText(
        position: string,
        locations: FlinkLocation[]
    ): string {
        let locationTree = [];
        let result = '';
        let currentItem: any = locations.find((l) => l.id === position);
        if (!currentItem) {
            return '';
        }
        while (currentItem.parentID) {
            locationTree = [currentItem, ...locationTree];
            const parent = locations.find((l) => l.id === currentItem.parentID);
            if (parent) {
                currentItem = parent;
            } else {
                currentItem = {
                    title: 'P-ID? ' + currentItem.parentID,
                };
            }
        }
        locationTree = [currentItem, ...locationTree];

        for (let i = 0; i < locationTree.length; i++) {
            const current = locationTree[i];
            if (current.title) {
                result += current.title;
            }
            const isLast = i === locationTree.length - 1;
            if (!isLast) {
                result += ' | ';
            }
        }

        return result;
    }

    static filterIssues(o: FilterIssuesOptions): Issue[] {
        if (o.issues) {
            return [
                ...o.issues
                    .filter((issue) =>
                        IssuesUtils.filterByBimId(issue, o.filters.bimId)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByArchived(issue, o.filters.archived)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByTitle(issue, o.filters.title)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByCraftId(issue, o.filters.craft)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByInternalResponsibleId(
                            issue,
                            o.filters.responsibleInternalId
                        )
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByExternalResponsibleId(
                            issue,
                            o.filters.responsibleExternalId
                        )
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByState(issue, o.filters.state)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByDueDateFrom(
                            issue,
                            o.filters.dueDateFrom
                        )
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByDueDateTill(
                            issue,
                            o.filters.dueDateTill
                        )
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByCreatedAt(
                            issue,
                            o.filters.createdAt
                        )
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByLocation(issue, o.filters.position)
                    )
                    .filter((issue) =>
                        IssuesUtils.filterByCompanyId(
                            issue,
                            o.filters.companyId,
                            o.contacts
                        )
                    )
                    .filter((issue) =>
                        filterByCustomAttributes(
                            issue,
                            o.filters.customAttributes
                        )
                    )
                    .sort((a, b) =>
                        IssuesUtils.sortIssueBy(
                            a,
                            b,
                            o.sortBy,
                            o.orderAsc,
                            o.contacts,
                            o.locations
                        )
                    )
                    .sort((a, b) =>
                        IssuesUtils.sortIssueBy(a, b, 'markedAsDelete', true)
                    ),
            ];
        } else {
            return [];
        }
    }

    private static filterByCompanyId(
        issue: Issue,
        companyId: string,
        contactsEntities: Dictionary<Contact>
    ): boolean {
        if (!companyId || companyId === '') {
            return true;
        }
        const { responsibleExternalId, responsibleInternalId } = issue;
        if (!responsibleExternalId && !responsibleInternalId) {
            return false;
        }
        const fromCompany = Object.values(contactsEntities)
            .filter(
                (contact) =>
                    contact.companyId === companyId || contact.id === companyId
            ) // contacts from company
            .filter(
                (contact) =>
                    contact.id === issue.responsibleExternalId ||
                    contact.id === issue.responsibleInternalId
            );

        return fromCompany.length > 0;
    }

    private static filterByBimId(issue: Issue, bimId: string): boolean {
        return !bimId ? true : issue.bimId === bimId;
    }

    private static filterByArchived(issue: Issue, archived: boolean): boolean {
        return archived ? true : !issue.markedAsDelete;
    }

    private static filterByTitle(issue: Issue, title: string): boolean {
        return !title
            ? true
            : issue.title.toLowerCase().includes(title.toLowerCase());
    }

    private static filterByCraftId(issue: Issue, craftId: number): boolean {
        return !craftId ? true : craftId === issue.craft;
    }

    private static filterByInternalResponsibleId(
        issue: Issue,
        responsibleId: string
    ): boolean {
        if (!responsibleId) {
            return true;
        }

        return issue.responsibleInternalId === responsibleId;
    }

    private static filterByExternalResponsibleId(
        issue: Issue,
        responsibleId: string
    ): boolean {
        if (!responsibleId) {
            return true;
        }
        return issue.responsibleExternalId === responsibleId;
    }

    private static filterByLocation(issue: Issue, position: string): boolean {
        if (!position) {
            return true;
        }
        return issue.position === position;
    }

    private static filterByState(
        issue: Issue,
        state: Issue.StateEnum
    ): boolean {
        if (!state) {
            return true;
        }
        return issue.state === state;
    }

    private static filterByDueDateFrom(
        issue: Issue,
        dueDateFrom: Date
    ): boolean {
        if (!dueDateFrom) {
            return true;
        }

        return moment(issue.dueDate).isAfter(dueDateFrom);
    }

    private static filterByDueDateTill(
        issue: Issue,
        dueDateTill: Date
    ): boolean {
        if (!dueDateTill) {
            return true;
        }
        return moment(issue.dueDate).isBefore(dueDateTill);
    }

    private static filterByCreatedAt(issue: Issue, createdAt: Date): boolean {
        if (!createdAt) {
            return true;
        }
        const createdAtFrom = new Date(createdAt);
        const createdAtTill = new Date(
            createdAtFrom.getFullYear(),
            createdAtFrom.getMonth(),
            createdAtFrom.getDate(),
            23,
            59,
            59
        );
        return (
            moment(issue.creationDate).isAfter(moment(createdAtFrom)) &&
            moment(issue.creationDate).isBefore(moment(createdAtTill))
        );
    }

    private static sortIssueBy(
        a: Issue,
        b: Issue,
        sortBy: IssueSorts | 'markedAsDelete',
        orderAsc: boolean,
        contacts?: Dictionary<Contact>,
        locations?: FlinkLocation[]
    ): number {
        const order = orderAsc ? 1 : -1;
        let valueA = a[sortBy];
        let valueB = b[sortBy];
        const importanceOrder = {
            first: [
                Issue.StateEnum.Rejected,
                Issue.StateEnum.Draft,
                Issue.StateEnum.Failed,
            ],
            second: [
                Issue.StateEnum.FinalDeadline,
                Issue.StateEnum.MailedFinalDeadline,
                Issue.StateEnum.MailedExtendedDeadline,
                Issue.StateEnum.ExtendedDeadline,
                Issue.StateEnum.DoneWithoutInspection,
                Issue.StateEnum.Open,
                Issue.StateEnum.Accepted,
            ],
            third: [Issue.StateEnum.Done],
        };

        const stateOrder = [
            Issue.StateEnum.Failed,
            Issue.StateEnum.Rejected,
            Issue.StateEnum.FinalDeadline,
            Issue.StateEnum.MailedFinalDeadline,
            Issue.StateEnum.MailedExtendedDeadline,
            Issue.StateEnum.ExtendedDeadline,
            Issue.StateEnum.DoneWithoutInspection,
            Issue.StateEnum.Open,
            Issue.StateEnum.Accepted,
            Issue.StateEnum.Done,
            Issue.StateEnum.Draft,
        ];

        switch (sortBy) {
            case IssueSorts.STATE:
                valueA = stateOrder.indexOf(a.state);
                valueB = stateOrder.indexOf(b.state);
                break;
            case IssueSorts.INTERNAL_RESPONSIBLE:
                valueA = (contacts[a.responsibleExternalId] || ({} as Contact))
                    .name;
                valueB = (contacts[b.responsibleInternalId] || ({} as Contact))
                    .name;
                break;
            case IssueSorts.DUE_DATE:
                if (
                    a.state === Issue.StateEnum.Done &&
                    b.state !== Issue.StateEnum.Done
                ) {
                    return 1;
                } else if (
                    a.state !== Issue.StateEnum.Done &&
                    b.state === Issue.StateEnum.Done
                ) {
                    return -1;
                }
                break;
            case IssueSorts.LOCATION:
                const locationTreeTextPipe = new LocationTreeTextPipe();
                valueA = locationTreeTextPipe.transform(a.position, locations);
                valueB = locationTreeTextPipe.transform(b.position, locations);
                break;
            case IssueSorts.IMPORTANCE:
                const aIndex1 = importanceOrder.first.indexOf(a.state);
                const aIndex2 = importanceOrder.second.indexOf(a.state);
                const aIndex3 = importanceOrder.third.indexOf(a.state);

                const bIndex1 = importanceOrder.first.indexOf(b.state);
                const bIndex2 = importanceOrder.second.indexOf(b.state);
                const bIndex3 = importanceOrder.third.indexOf(b.state);
                // value that represents the importance
                // 3 = first group = very importance
                // 2 = second group = mid importance
                // 1 = third group = low importance
                let importanceA = 0;
                let importanceB = 0;

                if (aIndex1 !== -1) {
                    importanceA = 1;
                } else if (aIndex2 !== -1) {
                    importanceA = 2;
                } else if (aIndex3 !== -1) {
                    importanceA = 3;
                }

                if (bIndex1 !== -1) {
                    importanceB = 1;
                } else if (bIndex2 !== -1) {
                    importanceB = 2;
                } else if (bIndex3 !== -1) {
                    importanceB = 3;
                }

                valueA = importanceA;
                valueB = importanceB;

                if (importanceA === importanceB) {
                    // if same importance, sort by due date
                    valueA = a.dueDate;
                    valueB = b.dueDate;
                }
                break;
            default:
        }

        if (valueA > valueB) {
            return 1 * order;
        }

        if (valueA < valueB) {
            return -1 * order;
        }
        return 0;
    }
}
