/**
 * eTeacher API
 * Last Build: 12/14/2021 10:28:38 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TemplateAttribute } from './templateAttribute';
import { Contact } from './contact';


export interface FormLetterTemplate { 
    id?: string;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    language?: string;
    title?: string;
    description?: string;
    /**
     * Link to Predefined Template, which contains the template file contents
     */
    templateSourceId?: string;
    projectId?: string;
    footerHtmlFragment?: string;
    headerHtmlFragment?: string;
    purpose?: FormLetterTemplate.PurposeEnum;
    /**
     * Temporarily used as an alternate to the file ID
     */
    type?: FormLetterTemplate.TypeEnum;
    customAttributes?: { [key: string]: TemplateAttribute; };
    readonly templateType?: string;
}
export namespace FormLetterTemplate {
    export type PurposeEnum = 'Issues' | 'DailyLog' | 'InspectionEntryLock';
    export const PurposeEnum = {
        Issues: 'Issues' as PurposeEnum,
        DailyLog: 'DailyLog' as PurposeEnum,
        InspectionEntryLock: 'InspectionEntryLock' as PurposeEnum
    };
    export type TypeEnum = 'TicketDetails' | 'BehinderungsanzeigeMitTicketanhang' | 'BehinderungsanzeigeOhneTicketanhang' | 'ProtokollOhneUnterschrift' | 'ProtokollMitUnterschrift' | 'HinweisAufMaengelbeseitigung' | 'MaengelmeldungNachVobMitTicketanhang' | 'MaengelmeldungNachVobOhneTicketanhang' | 'FreitextMitTicketanhang' | 'FreitextOhneTicketanhang' | 'BegehungsprotokollMitTicketanhang';
    export const TypeEnum = {
        TicketDetails: 'TicketDetails' as TypeEnum,
        BehinderungsanzeigeMitTicketanhang: 'BehinderungsanzeigeMitTicketanhang' as TypeEnum,
        BehinderungsanzeigeOhneTicketanhang: 'BehinderungsanzeigeOhneTicketanhang' as TypeEnum,
        ProtokollOhneUnterschrift: 'ProtokollOhneUnterschrift' as TypeEnum,
        ProtokollMitUnterschrift: 'ProtokollMitUnterschrift' as TypeEnum,
        HinweisAufMaengelbeseitigung: 'HinweisAufMaengelbeseitigung' as TypeEnum,
        MaengelmeldungNachVobMitTicketanhang: 'MaengelmeldungNachVobMitTicketanhang' as TypeEnum,
        MaengelmeldungNachVobOhneTicketanhang: 'MaengelmeldungNachVobOhneTicketanhang' as TypeEnum,
        FreitextMitTicketanhang: 'FreitextMitTicketanhang' as TypeEnum,
        FreitextOhneTicketanhang: 'FreitextOhneTicketanhang' as TypeEnum,
        BegehungsprotokollMitTicketanhang: 'BegehungsprotokollMitTicketanhang' as TypeEnum
    };
}


