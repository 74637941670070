import { SyncCommand } from '../../main/sync/models/sync-command';
import { EntityAttachmentMap } from '../issues/models/entity-attachment-map';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import { DiscardParameters } from '../sync/discard-parameters.model';
import { EMPTY, from, Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { AcceptUtils } from 'app/core/utils/accept-utils';

export type EntityUpdater<T> = (
    entityId: string,
    commandChanges: Partial<T>,
    attachmentMap: EntityAttachmentMap
) => Partial<T>;

export interface AttachmentIdUpdate<
    EntityType,
    EntityDocType,
    CommandType extends SyncCommand<EntityType> = SyncCommand<EntityType>
> {
    commands: CommandType[];
    attachmentMap: EntityAttachmentMap;
    entityUpdater: EntityUpdater<EntityType>;
    commandDbService: CommandDbService<EntityType>;
    fileStorageService: FileStorageService;
    clearAction: Action;
}

export class CommandStoreUtils {
    /**
     * Update the attachment ids in commands from local to server ones.
     *
     * @type T The entity type the commands are for.
     * @type CommandType An explicit command type which is needed for PlanCommand
     * @param commands The commands to update.
     * @param attachmentMap The attachment map containing the mapping from local to server ids.
     * @param entityUpdater This function is used to update the command changes for the concrete entity type.
     */
    static updateCommandsAttachmentIds<
        T,
        CommandType extends SyncCommand<T> = SyncCommand<T>
    >(
        commands: CommandType[],
        attachmentMap: EntityAttachmentMap,
        entityUpdater: EntityUpdater<T>
    ): CommandType[] {
        return commands.map((command) => {
            return {
                ...command,
                changes: entityUpdater(
                    command.entityId,
                    {
                        // note: the above function modifies the changes in place
                        //       and also returns them, thus copy here to
                        //       prevent changing the arguments of this function
                        ...command.changes,
                    },
                    attachmentMap
                ),
            };
        });
    }

    static updateCommandDatabaseId<
        T,
        CommandType extends SyncCommand<T> = SyncCommand<T>
    >(
        commands: CommandType[],
        commandToUpdate: CommandType,
        databaseId: string
    ): CommandType[] {
        return commands.map((command) => {
            if (isEqual(command, commandToUpdate)) {
                const newCommand: CommandType = {
                    ...command,
                    databaseId,
                };

                return newCommand;
            } else {
                return command;
            }
        });
    }

    static removeCommandsWithIds<
        T,
        CommandType extends SyncCommand<T> = SyncCommand<T>
    >(
        commands: CommandType[],
        discardParameters: DiscardParameters[]
    ): CommandType[] {
        const ids = discardParameters.map(
            (discardParameter) => discardParameter.entityId
        );
        return commands.filter((command) => !ids.includes(command.entityId));
    }

    static removePlanCommandsWithIssueIds(
        commands: PlanCommand[],
        discardParameters: DiscardParameters[]
    ): PlanCommand[] {
        const ids = discardParameters.map(
            (discardParameter) => discardParameter.entityId
        );
        return commands.filter((command) => !ids.includes(command.issueId));
    }

    static updateAttachmentIdsInDb<
        EntityType,
        EntityDocType,
        CommandType extends SyncCommand<EntityType> = SyncCommand<EntityType>
    >(
        attachmentIdUpdate: AttachmentIdUpdate<
            EntityType,
            EntityDocType,
            CommandType
        >
    ): Observable<Action> {
        const {
            commands,
            attachmentMap,
            entityUpdater,
            commandDbService,
            fileStorageService,
            clearAction,
        } = attachmentIdUpdate;

        if (attachmentMap.size === 0) {
            // no attachment mappings -> nothing to update
            return EMPTY;
        }

        const updates: Promise<void>[] = [];
        const updatedCommands = CommandStoreUtils.updateCommandsAttachmentIds(
            commands,
            attachmentMap,
            entityUpdater
        );

        let commandMissingDatabaseId = false;

        for (const [index, command] of commands.entries()) {
            if (command.databaseId) {
                updates.push(
                    commandDbService.updateByPrimaryId(
                        command.databaseId,
                        updatedCommands[index],
                        {
                            ignoreMissing: true,
                        }
                    )
                );
            } else {
                commandMissingDatabaseId = true;
            }
        }

        const mapOfAll = AcceptUtils.combineMaps(...attachmentMap.values());

        updates.push(fileStorageService.updateAttachmentIds(mapOfAll));

        const clearAttachmentMap = !commandMissingDatabaseId;

        return from(Promise.all(updates)).pipe(
            mergeMap(() => (clearAttachmentMap ? of(clearAction) : EMPTY)),
            catchError((error) => {
                console.error(
                    'an error occured during attachment update:',
                    error
                );
                return EMPTY;
            })
        );
    }

    static markCommandsInFlightForEntities<
        CommandType extends SyncCommand<unknown> = SyncCommand<unknown>
    >(existingCommands: CommandType[], entityIds: string[]): CommandType[] {
        return existingCommands.map((command) => {
            const lockAllowed = !command.locked && !command.waitForCreation;
            if (!lockAllowed) {
                return command;
            }

            const needsLock = entityIds.includes(command.entityId);
            if (needsLock) {
                return {
                    ...command,
                    inFlight: true,
                };
            } else {
                return command;
            }
        });
    }

    static clearInFlightFlagOfCommands<
        CommandType extends SyncCommand<unknown> = SyncCommand<unknown>
    >(existingCommands: CommandType[]): CommandType[] {
        return existingCommands.map((command) => {
            if (command.inFlight) {
                return {
                    ...command,
                    inFlight: false,
                };
            } else {
                return command;
            }
        });
    }
}
