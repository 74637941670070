export * from './account.service';
import { AccountService } from './account.service';
export * from './app.service';
import { AppService } from './app.service';
export * from './checklistTemplate.service';
import { ChecklistTemplateService } from './checklistTemplate.service';
export * from './contacts.service';
import { ContactsService } from './contacts.service';
export * from './customAttribute.service';
import { CustomAttributeService } from './customAttribute.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './customerCrafts.service';
import { CustomerCraftsService } from './customerCrafts.service';
export * from './dailyLogs.service';
import { DailyLogsService } from './dailyLogs.service';
export * from './documentArchive.service';
import { DocumentArchiveService } from './documentArchive.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './formLetter.service';
import { FormLetterService } from './formLetter.service';
export * from './import.service';
import { ImportService } from './import.service';
export * from './issues.service';
import { IssuesService } from './issues.service';
export * from './manage.service';
import { ManageService } from './manage.service';
export * from './markedPlans.service';
import { MarkedPlansService } from './markedPlans.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './predefinedFormLetterTemplate.service';
import { PredefinedFormLetterTemplateService } from './predefinedFormLetterTemplate.service';
export * from './projectSettings.service';
import { ProjectSettingsService } from './projectSettings.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './serialLetter.service';
import { SerialLetterService } from './serialLetter.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './standardSerialLetter.service';
import { StandardSerialLetterService } from './standardSerialLetter.service';
export * from './template.service';
import { TemplateService } from './template.service';
export * from './test.service';
import { TestService } from './test.service';
export * from './viki.service';
import { VikiService } from './viki.service';
export const APIS = [AccountService, AppService, ChecklistTemplateService, ContactsService, CustomAttributeService, CustomerService, CustomerCraftsService, DailyLogsService, DocumentArchiveService, FilesService, FormLetterService, ImportService, IssuesService, ManageService, MarkedPlansService, NotificationsService, PredefinedFormLetterTemplateService, ProjectSettingsService, ProjectsService, SerialLetterService, SettingsService, StandardSerialLetterService, TemplateService, TestService, VikiService];
