<div class="project-stats" [routerLink]="['/projects', projectDetail.project.id, 'issues']">
    <img [@fade]="animationType" (@fade.done)="fadeCompleted()" [src]="imageUrl | safe:'url'" class="project-image" />
    <div class="project-detail">
        <div class="project-start-end-date">
            <div class="project-start-date"> <span translate>PROJECT.BEGINNING</span>
                : <div *ngIf="projectDetail.startDate; else displayDash">&nbsp;
                    {{projectDetail.startDate}} | </div>
            </div>
            <div class="project-end-date"><span translate>PROJECT.END</span>: <div
                    *ngIf="projectDetail.dueDate; else displayDash">&nbsp;
                    {{projectDetail.dueDate}}
                </div>
            </div>
        </div>
        <div class="project-title-container">
            <div class="project-title" data-test="project-name" *ngIf="projectDetail.projectName">
                {{projectDetail.projectName}}</div>
            <button mat-button [matMenuTriggerFor]="projectMenuOptions" class="options-container"
                data-test="project-more-option-button" (click)="$event.stopPropagation()"><img
                    src='assets/icons/options.svg' class="options-icon" /></button>
            <mat-menu #projectMenuOptions="matMenu">
                <button mat-menu-item (click)="archived.next(projectDetail.project)"
                    *ngIf="(isAdmin$ | async) && !projectDetail.markedAsDelete"
                    data-test="project-archive-project-option-button">
                    <mat-icon>archive</mat-icon>
                    <span translate>PROJECT.ARCHIVE_PROJECT</span>
                </button>
                <button mat-menu-item (click)="updated.next(projectDetail.project)" [disabled]="!(isOnline$ | async)"
                    data-test="project-edit-option-button">
                    <mat-icon>edit</mat-icon>
                    <span translate>PROJECT.EDIT_PROJECT</span>
                </button>
                <button mat-menu-item (click)="toggleSync()" *ngIf="isDevice">
                    <mat-icon>{{
                        (markedForSync$ | async) ? 'close' : 'check'
                        }}</mat-icon>
                    <span>
                        {{
                        (markedForSync$ | async)
                        ? ('APP.UNMARK_FOR_SYNC' | translate)
                        : ('APP.MARK_FOR_SYNC' | translate)
                        }}
                    </span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>
<div class="project-issues-detail">
    <div class="spinner-overlay" *ngIf="projectDetail.loading">
        <div class="spinner-container">
            <mat-spinner color="accent" class="project-grid-spinner" [diameter]="50"></mat-spinner>
        </div>
    </div>
    <div class="project-issue-container">
        <div class="project-issues-statuses">
            <div class="status-colour-text">
                <div class="status-colour status-colour-done"></div>
                <div class="status-text"><span translate>PROJECT.STATUS_DONE</span></div>
            </div>
            <div *ngIf="projectDetail.doneIssuesCount; else displayDash">{{projectDetail.doneIssuesCount}}&nbsp;<span
                    translate>PROJECT.TICKETS</span>
            </div>
        </div>
        <div class="project-issues-statuses">
            <div class="status-colour-text">
                <div class="status-colour status-colour-open"></div>
                <div class="status-text"><span translate>PROJECT.STATUS_OPEN</span></div>
            </div>
            <div *ngIf="projectDetail.openIssuesCount; else displayDash">{{projectDetail.openIssuesCount}}&nbsp;<span
                    translate>PROJECT.TICKETS</span>
            </div>
        </div>
        <div class="project-issues-statuses">
            <div class="status-colour-text">
                <div class="status-colour status-colour-accepted"></div>
                <div class="status-text"><span translate>PROJECT.STATUS_ACCEPTED</span></div>
            </div>
            <div *ngIf="projectDetail.acceptedIssuesCount; else displayDash">
                {{projectDetail.acceptedIssuesCount}}&nbsp;<span translate>PROJECT.TICKETS</span></div>
        </div>
        <div class="project-issues-statuses"
            [ngStyle]="{ 'color': projectDetail.extendedDeadlineIssuesCount > 0 ? 'red' : ''}">
            <div class="status-colour-text">
                <div class="status-colour status-colour-deadline"></div>
                <div class="status-text"><span translate>PROJECT.STATUS_EXTENDED_DEADLINE</span></div>
            </div>
            <div *ngIf="projectDetail.extendedDeadlineIssuesCount; else displayDash">
                {{projectDetail.extendedDeadlineIssuesCount}}&nbsp;<span translate>PROJECT.TICKETS</span>
            </div>
        </div>
    </div>
    <div class="project-graph-container" *ngIf="projectDetail.totalIssuesCount > 0">
        <div class="issue-count-container">
            <div class="total-text"><span translate>PROJECT.TOTAL</span></div>
            <div class="issue-count" *ngIf="projectDetail.totalIssuesCount; else displayDash">
                {{projectDetail.totalIssuesCount}}</div>
        </div>
        <div class="project-graph">
            <ngx-charts-pie-chart [scheme]="colorScheme" [results]="projectDetail.chartData" [gradient]="gradient"
                [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut" [view]="view">
            </ngx-charts-pie-chart>
        </div>
    </div>
</div>
<ng-template #displayDash>
    -
</ng-template>