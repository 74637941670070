import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    Contact,
    ContactsService,
    Location as FlinkLocation,
    ProjectPartner,
    ProjectRole,
    ProjectSettingsService,
    SettingsService,
} from 'app/core/rest-api';
import { CraftToPlanLocationDbService } from 'app/db/craft-to-plan-locations/craft-to-plan-locations.db.service';
import { ProjectContactDbService } from 'app/db/project-contacts/project-contact.db.service';
import { ProjectCraftIdsDbService } from 'app/db/project-craft-ids/project-craft-ids.db.service';
import { ProjectCraftIdsDocType } from 'app/db/project-craft-ids/project-craft-ids.document';
import { ProjectPartnerDbService } from 'app/db/project-partners/project-partners.db.service';
import { ProjectRolesDbService } from 'app/db/project-roles/project-roles.db.service';
import { SyncService } from 'app/main/sync/sync.service';
import { defer, from, of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { getIsDevice, getIsOnline } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import {
    CreateProjectPartnerError,
    CreateProjectPartnerRequested,
    CreateProjectPartnerSuccess,
    CreateProjectRoleRequested,
    DeleteProjectPartnerError,
    DeleteProjectPartnerRequested,
    DeleteProjectPartnerSuccess,
    DeleteProjectRoleRequested,
    LoadLanguagesError,
    LoadLanguagesRequested,
    LoadLanguagesSuccess,
    LoadProjectContactsError,
    LoadProjectContactsRequest,
    LoadProjectContactsSuccess,
    LoadProjectCraftsError,
    LoadProjectCraftsRequest,
    LoadProjectCraftsSuccess,
    LoadProjectCraftToPlanError,
    LoadProjectCraftToPlanRequested,
    LoadProjectCraftToPlanSuccess,
    LoadProjectPartnersError,
    LoadProjectPartnersRequested,
    LoadProjectPartnersSuccess,
    LoadProjectRolesError,
    LoadProjectRolesRequest,
    LoadProjectRolesSuccess,
    LoadTimezonesError,
    LoadTimezonesRequested,
    LoadTimezonesSuccess,
    SettingsActionTypes,
    UpdateProjectPartnerError,
    UpdateProjectPartnerRequested,
    UpdateProjectPartnerSuccess,
    UpdateProjectRoleRequested,
    UpsertProjectContactsFinish,
    UpsertProjectContactsStart,
    UpsertProjectCraftsFinish,
    UpsertProjectCraftsStart,
    UpsertProjectCraftToPlanFinish,
    UpsertProjectCraftToPlanStart,
    UpsertProjectPartnersFinish,
    UpsertProjectPartnersStart,
    UpsertProjectRolesFinish,
    UpsertProjectRolesStart,
    CreateProjectRolesError,
    UpdateProjectRolesError,
    DeleteProjectRoleError,
} from './settings.actions';
import { SettingsState } from './settings.reducer';
import { ErrorUtils } from 'app/core/utils/error-util';
import { getIsLoggedIn } from '../auth/auth.selectors';

@Injectable()
export class SettingsEffects {
    /**
     * ProjectCraftToPlan (crafToPlanLocations)
     */
    @Effect()
    loadProjectCraftToPlanRequest$ = this.actions$.pipe(
        ofType<LoadProjectCraftToPlanRequested>(
            SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_REQUESTED
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        switchMap(([payload, currentProjectId]) => {
            const { projectId } = payload;
            const server$ = this.projectSettings.projectsByProjectIdSettingsLocationsandfallbacklocationsGet(
                projectId
            );
            /**
             * defer() => cold observable
             */
            const db$ = defer(() =>
                from(this.craftToPlanLocationDb.getByProjectId(projectId))
            );
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadProjectCraftToPlanSuccess({
                                craftToPlanLocations: response.data as FlinkLocation[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new LoadProjectCraftToPlanError({
                                error,
                            })
                        )
                    )
                );
        })
    );

    @Effect()
    loadProjectCraftToPlanSuccess$ = this.actions$.pipe(
        ofType<LoadProjectCraftToPlanSuccess>(
            SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        switchMap(({ craftToPlanLocations }) =>
            of(new UpsertProjectCraftToPlanStart({ craftToPlanLocations }))
        )
    );

    @Effect()
    loadProjectCraftToPlanError$ = this.actions$.pipe(
        ofType<LoadProjectCraftToPlanError>(
            SettingsActionTypes.LOAD_PROJECT_CRAFT_TO_PLAN_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.LOAD_PROJECT_CRAFT_TO_PLAN'
            );
            return of();
        })
    );

    @Effect()
    upsertProjectCraftToPlanStart$ = this.actions$.pipe(
        ofType<UpsertProjectCraftToPlanStart>(
            SettingsActionTypes.UPSERT_PROJECT_CRAFT_TO_PLAN_START
        ),
        map((action) => action.payload),
        mergeMap(({ craftToPlanLocations }) => {
            return from(
                this.craftToPlanLocationDb.bulkInsert(craftToPlanLocations)
            ).pipe(
                map(
                    (results) => new UpsertProjectCraftToPlanFinish({ results })
                )
            );
        })
    );
    /**
     * Project Roles
     */
    @Effect()
    loadProjectRoles$ = this.actions$.pipe(
        ofType<LoadProjectRolesRequest>(
            SettingsActionTypes.LOAD_PROJECT_ROLES_REQUESTED
        ),
        map((action) => action.payload.projectId),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        switchMap(([projectId, currentProjectId]) => {
            const server$ = this.contactsService.projectsByProjectIdContactsProjectrolesGet(
                projectId
            );
            /**
             * defer() => cold observable
             */
            const db$ = defer(() =>
                from(this.projectRolesDb.getByProjectId(projectId))
            );

            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadProjectRolesSuccess({
                                projectRoles: response.data as ProjectRole[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new LoadProjectRolesError({
                                error,
                            })
                        )
                    )
                );
        })
    );

    @Effect()
    loadProjectRolesError$ = this.actions$.pipe(
        ofType<LoadProjectRolesError>(
            SettingsActionTypes.LOAD_PROJECT_ROLES_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_ROLES.LOAD'
            );
            return of();
        })
    );

    @Effect()
    loadProjectRolesSuccess$ = this.actions$.pipe(
        ofType<LoadProjectRolesSuccess>(
            SettingsActionTypes.LOAD_PROJECT_ROLES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        switchMap(({ projectRoles, fromDb }) =>
            of(new UpsertProjectRolesStart({ projectRoles }))
        )
    );

    @Effect()
    upsertProjectRolesStart$ = this.actions$.pipe(
        ofType<UpsertProjectRolesStart>(
            SettingsActionTypes.UPSERT_PROJECT_ROLES_START
        ),
        map((action) => action.payload.projectRoles),
        mergeMap((projectRoles) => {
            return from(this.projectRolesDb.bulkInsert(projectRoles)).pipe(
                map((results) => new UpsertProjectRolesFinish({ results }))
            );
        })
    );

    @Effect()
    createProjectRole$ = this.actions$.pipe(
        ofType<CreateProjectRoleRequested>(
            SettingsActionTypes.CREATE_PROJECT_ROLE_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap((payload) =>
            this.contactsService
                .projectsByProjectIdContactsProjectrolesPost(
                    payload.projectId,
                    payload.projectRole
                )
                .pipe(
                    map(
                        () =>
                            new LoadProjectRolesRequest({
                                projectId: payload.projectId,
                            })
                    ),
                    catchError((error) =>
                        of(new CreateProjectRolesError({ error }))
                    )
                )
        )
    );

    @Effect()
    createProjectRolesError$ = this.actions$.pipe(
        ofType<CreateProjectRolesError>(
            SettingsActionTypes.CREATE_PROJECT_ROLE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_ROLES.CREATE'
            );
            return of();
        })
    );

    @Effect()
    updateProjectRole$ = this.actions$.pipe(
        ofType<UpdateProjectRoleRequested>(
            SettingsActionTypes.UPDATE_PROJECT_ROLE_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap((payload) =>
            this.contactsService
                .projectsByProjectIdContactsProjectrolesByProjectPartnerIdPatch(
                    payload.projectRole.id,
                    payload.projectId,
                    payload.projectRole
                )
                .pipe(
                    map(
                        () =>
                            new LoadProjectRolesRequest({
                                projectId: payload.projectId,
                            })
                    ),
                    catchError((error) =>
                        of(new UpdateProjectRolesError({ error }))
                    )
                )
        )
    );

    @Effect()
    updateProjectRolesError$ = this.actions$.pipe(
        ofType<UpdateProjectRolesError>(
            SettingsActionTypes.UPDATE_PROJECT_ROLE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_ROLES.UPDATE'
            );
            return of();
        })
    );

    @Effect()
    deleteProjectRole$ = this.actions$.pipe(
        ofType<DeleteProjectRoleRequested>(
            SettingsActionTypes.DELETE_PROJECT_ROLE_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap((payload) =>
            this.contactsService
                .projectsByProjectIdContactsProjectrolesByProjectPartnerIdDelete(
                    payload.id,
                    payload.projectId
                )
                .pipe(
                    map(
                        () =>
                            new LoadProjectRolesRequest({
                                projectId: payload.projectId,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new DeleteProjectRoleError({
                                error,
                            })
                        )
                    )
                )
        )
    );

    @Effect()
    deleteProjectRolesError$ = this.actions$.pipe(
        ofType<DeleteProjectRoleError>(
            SettingsActionTypes.DELETE_PROJECT_ROLE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_ROLES.DELETE'
            );
            return of();
        })
    );

    /**
     * Project Crafts
     */
    @Effect()
    loadProjectCrafts$ = this.actions$.pipe(
        ofType<LoadProjectCraftsRequest>(
            SettingsActionTypes.LOAD_PROJECT_CRAFTS_REQUESTED
        ),
        map((action) => action.payload.projectId),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([projectId, currentProjectId]) => {
            const server$ = this.projectSettings.projectsByProjectIdSettingsCraftsGet(
                projectId
            );

            const db$ = defer(() =>
                from(this.projectCraftIdsDb.getByProjectId(projectId))
            );
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map((response) => {
                        let craftIds = [];
                        const { fromDb, data } = response;
                        if (fromDb) {
                            if (data) {
                                const craftIdDocuments = (data as unknown) as ProjectCraftIdsDocType[];
                                if (craftIdDocuments.length > 0) {
                                    const [first] = craftIdDocuments;
                                    craftIds = first.craftIds;
                                }
                            }
                        } else {
                            const apiCraftIds: number[] = (response.data as unknown) as number[];
                            craftIds = apiCraftIds;
                        }

                        return new LoadProjectCraftsSuccess({
                            craftIds,
                            fromDb: fromDb,
                            projectId,
                            currentProjectId,
                            requestProjectId: projectId,
                        });
                    }),
                    catchError((error) =>
                        of(new LoadProjectCraftsError({ error }))
                    )
                );
        })
    );

    @Effect()
    loadProjectCraftsSuccess$ = this.actions$.pipe(
        ofType<LoadProjectCraftsSuccess>(
            SettingsActionTypes.LOAD_PROJECT_CRAFTS_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        switchMap(({ craftIds, projectId }) =>
            of(new UpsertProjectCraftsStart({ craftIds, projectId }))
        )
    );

    @Effect()
    upsertProjectCraftsStart$ = this.actions$.pipe(
        ofType<UpsertProjectCraftsStart>(
            SettingsActionTypes.UPSERT_PROJECT_CRAFTS_START
        ),
        map((action) => action.payload),
        concatMap((payload) => {
            const { projectId, craftIds } = payload;
            const id = projectId;
            const toInsert = [{ id, projectId, craftIds }];
            return from(this.projectCraftIdsDb.bulkInsert(toInsert)).pipe(
                map((results) => new UpsertProjectCraftsFinish({ results }))
            );
        })
    );

    /**
     * Project Contacts
     */
    @Effect()
    loadProjectContacts$ = this.actions$.pipe(
        ofType<LoadProjectContactsRequest>(
            SettingsActionTypes.LOAD_PROJECT_CONTACTS_REQUEST
        ),
        map((action) => action.payload.projectId),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([projectId, currentProjectId]) => {
            const server$ = this.contactsService.projectsByProjectIdContactsGet(
                projectId
            );
            /**
             * defer() => cold observable
             */
            const db$ = defer(() =>
                from(this.projectContactDb.getByProjectId(projectId))
            );
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadProjectContactsSuccess({
                                contacts: (response.data as Contact[]).map((contact) => ({
                                    ...contact,
                                    fullnameWithCompany: contact.companyName
                                        ? `${contact.fullname}, ${contact.companyName}`
                                        : contact.fullname,
                                })),
                                projectId,
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) =>
                        of(new LoadProjectContactsError({ error }))
                    )
                );
        })
    );

    @Effect()
    loadProjectContactsSuccess$ = this.actions$.pipe(
        ofType<LoadProjectContactsSuccess>(
            SettingsActionTypes.LOAD_PROJECT_CONTACTS_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        mergeMap(({ contacts, projectId, fromDb }) => {
            contacts = contacts.map((contact) => ({ ...contact, projectId }));
            return of(new UpsertProjectContactsStart({ contacts }));
        })
    );

    @Effect()
    upsertProjectContactsStart$ = this.actions$.pipe(
        ofType<UpsertProjectContactsStart>(
            SettingsActionTypes.UPSERT_PROJECT_CONTACTS_START
        ),
        map((action) => action.payload),
        mergeMap(({ contacts }) => {
            return from(this.projectContactDb.bulkInsert(contacts)).pipe(
                map((results) => new UpsertProjectContactsFinish({ results }))
            );
        })
    );
    /**
     * Project Partners
     */
    @Effect()
    loadProjectPartners$ = this.actions$.pipe(
        ofType<LoadProjectPartnersRequested>(
            SettingsActionTypes.LOAD_PROJECT_PARTNERS_REQUESTED
        ),
        map((action) => action.payload.projectId),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([projectId, currentProjectId]) => {
            const server$ = this.contactsService.projectsByProjectIdContactsCraftsGet(
                projectId
            );
            /**
             * defer() => cold observable
             */
            const db$ = defer(() =>
                from(this.projectPartnerDb.getByProjectId(projectId))
            );
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadProjectPartnersSuccess({
                                projectPartners: response.data as ProjectPartner[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) =>
                        of(new LoadProjectPartnersError({ error }))
                    )
                );
        })
    );

    @Effect()
    loadProjectPartnersSuccess$ = this.actions$.pipe(
        ofType<LoadProjectPartnersSuccess>(
            SettingsActionTypes.LOAD_PROJECT_PARTNERS_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        mergeMap(({ projectPartners }) =>
            of(new UpsertProjectPartnersStart({ projectPartners }))
        )
    );

    @Effect()
    upsertProjectPartnersStart$ = this.actions$.pipe(
        ofType<UpsertProjectPartnersStart>(
            SettingsActionTypes.UPSERT_PROJECT_PARTNERS_START
        ),
        map((action) => action.payload),
        mergeMap(({ projectPartners }) => {
            return from(this.projectPartnerDb.bulkInsert(projectPartners)).pipe(
                map((results) => new UpsertProjectPartnersFinish({ results }))
            );
        })
    );

    @Effect()
    createProjectPartner$ = this.actions$.pipe(
        ofType<CreateProjectPartnerRequested>(
            SettingsActionTypes.CREATE_PROJECT_PARTNER_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap(({ projectId, projectPartner }) =>
            this.contactsService
                .projectsByProjectIdContactsByContactIdCraftsPost(
                    projectId,
                    projectPartner.contactId,
                    projectPartner
                )
                .pipe(
                    map(
                        (response) =>
                            new CreateProjectPartnerSuccess({
                                projectPartner: response.data,
                            })
                    ),
                    catchError((error) =>
                        of(new CreateProjectPartnerError({ error }))
                    )
                )
        )
    );

    @Effect()
    updateProjectPartner$ = this.actions$.pipe(
        ofType<UpdateProjectPartnerRequested>(
            SettingsActionTypes.UPDATE_PROJECT_PARTNER_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap(({ projectPartner }) =>
            this.contactsService
                .projectsByProjectIdContactsCraftsByProjectPartnerIdPatch(
                    projectPartner.id,
                    projectPartner.projectId,
                    projectPartner
                )
                .pipe(
                    map(
                        (response) =>
                            new UpdateProjectPartnerSuccess({
                                projectPartner: response.data,
                            })
                    ),
                    catchError((error) =>
                        of(new UpdateProjectPartnerError({ error }))
                    )
                )
        )
    );

    @Effect()
    deleteProjectPartner$ = this.actions$.pipe(
        ofType<DeleteProjectPartnerRequested>(
            SettingsActionTypes.DELETE_PROJECT_PARTNER_REQUESTED
        ),
        map((action) => action.payload),
        mergeMap(({ projectPartner }) =>
            this.contactsService
                .projectsByProjectIdContactsCraftsByProjectPartnerIdDelete(
                    projectPartner.id,
                    projectPartner.projectId
                )
                .pipe(
                    map(
                        (response) =>
                            new DeleteProjectPartnerSuccess({
                                projectPartner,
                            })
                    ),
                    catchError((error) =>
                        of(new DeleteProjectPartnerError({ error }))
                    )
                )
        )
    );

    /**
     * Timezones
     */

    @Effect()
    loadTimezones$ = this.actions$.pipe(
        ofType<LoadTimezonesRequested>(
            SettingsActionTypes.LOAD_TIMEZONES_REQUESTED
        ),
        withLatestFrom(
            this.store.pipe(select(getIsLoggedIn)),
            this.store.pipe(select(getIsOnline))
        ),
        map(([action, isLoggedIn, isOnline]) => ({
            isLoggedIn,
            isOnline
        })),
        filter(status => status.isLoggedIn && status.isOnline),
        mergeMap(() =>
            this.settingsService.settingsTimezonesGet().pipe(
                map(
                    (response) =>
                        new LoadTimezonesSuccess({ timezones: response.data })
                ),
                catchError((error) => of(new LoadTimezonesError({ error })))
            )
        )
    );

    @Effect()
    loadLanguages$ = this.actions$.pipe(
        ofType<LoadLanguagesRequested>(
            SettingsActionTypes.LOAD_LANGUAGES_REQUESTED
        ),
        mergeMap((action) =>
            this.settingsService.settingsLanguagesGet().pipe(
                map(
                    (response) =>
                        new LoadLanguagesSuccess({ languages: response.data })
                ),
                catchError((error) => of(new LoadLanguagesError({ error })))
            )
        )
    );

    /**
     * Error Effects
     */

    @Effect({ dispatch: false })
    errorLoadTimezones$ = this.actions$.pipe(
        ofType<LoadTimezonesError>(SettingsActionTypes.LOAD_TIMEZONES_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.LOAD_TIMEZONES'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorLoadLanguages$ = this.actions$.pipe(
        ofType<LoadLanguagesError>(SettingsActionTypes.LOAD_LANGUAGES_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.LOAD_LANGUAGES'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorLoadProjectContacts$ = this.actions$.pipe(
        ofType<LoadProjectContactsError>(
            SettingsActionTypes.LOAD_PROJECT_CONTACTS_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.LOAD_PROJECT_CONTACT'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorLoadProjectPartners$ = this.actions$.pipe(
        ofType<LoadProjectPartnersError>(
            SettingsActionTypes.LOAD_PROJECT_PARTNERS_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_PARTNERS.LOAD'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorUpdateProjectPartner$ = this.actions$.pipe(
        ofType<UpdateProjectPartnerError>(
            SettingsActionTypes.UPDATE_PROJECT_PARTNER_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_PARTNERS.UPDATE'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorCreateProjectPartner$ = this.actions$.pipe(
        ofType<CreateProjectPartnerError>(
            SettingsActionTypes.CREATE_PROJECT_PARTNER_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_PARTNERS.CREATE'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorDeleteProjectPartner$ = this.actions$.pipe(
        ofType<DeleteProjectPartnerError>(
            SettingsActionTypes.DELETE_PROJECT_PARTNER_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.PROJECT_PARTNERS.DELETE'
            );
            return of();
        })
    );

    @Effect({ dispatch: false })
    errorLoadProjectCrafts$ = this.actions$.pipe(
        ofType<LoadProjectCraftsError>(
            SettingsActionTypes.LOAD_PROJECT_CRAFTS_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'SETTINGS.LOAD_PROJECT_CRAFTS'
            );
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private contactsService: ContactsService,
        private syncService: SyncService,
        private store: Store<SettingsState>,
        private settingsService: SettingsService,
        private projectRolesDb: ProjectRolesDbService,
        private craftToPlanLocationDb: CraftToPlanLocationDbService,
        private projectCraftIdsDb: ProjectCraftIdsDbService,
        private projectPartnerDb: ProjectPartnerDbService,
        private projectContactDb: ProjectContactDbService,
        private projectSettings: ProjectSettingsService,
        private errorUtils: ErrorUtils
    ) { }
}
