<mat-toolbar id="responsive-toolbar" color="accent">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <button
            *ngIf="!showSearch"
            mat-icon-button
            (click)="toggleSidebarOpen('navbar')"
            data-test="responsive-toolbar-sidebar-toggle"
        >
            <mat-icon>menu</mat-icon>
        </button>

        <button *ngIf="showSearch" (click)="disableSearch()" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
        </button>

        <div fxLayout="row" *ngIf="!showSearch">
            <ng-content></ng-content>

            <button
                mat-icon-button
                [matMenuTriggerFor]="menu.matMenu"
                (click)="temporaryFixIOS()"
            >
                <mat-icon
                    [matBadgeHidden]="notificationBadgeCount === 0"
                    [matBadge]="notificationBadgeCount"
                    matBadgeColor="warn"
                    >notifications</mat-icon
                >
            </button>
            <acc-notification #menu></acc-notification>

            <button mat-icon-button (click)="activateSearch()">
                <mat-icon
                    [matBadgeHidden]="!searchControl.value"
                    [matBadge]="'!'"
                    matBadgeColor="warn"
                    >search</mat-icon
                >
            </button>
        </div>

        <div class="fuse-white ml-8" *ngIf="showSearch" fxFlex fxLayout="row">
            <div
                class="search"
                fxFlex="1 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-icon class=" mx-8 secondary-text">search</mat-icon>
                <mat-form-field
                    floatLabel="never"
                    fxFlex
                    class="app-input-no-underline"
                >
                    <input [formControl]="searchControl" matInput />
                </mat-form-field>
                <div class="h-40">
                    <button
                        *ngIf="searchControl.value"
                        (click)="clearSearch()"
                        mat-icon-button
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
