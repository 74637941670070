import { RxJsonSchema } from 'rxdb';

export const craftToPlanLocationsSchema: RxJsonSchema = {
    title: 'craftToPlanLocation schema',
    description: 'describes a craftToPlanLocation',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true
        },
        title: {
            type: ['string', 'null']
        },
        projectId: {
            type: 'string',
        },
        parentID: {
            type: ['string', 'null']
        },
        creationDate: {
            type: 'string'
        },
        markedAsDelete: {
            type: ['boolean', 'null']
        },
        updateDateTime: {
            type: 'string'
        },
        craftToPlan: {
            type: 'object',
            additionalProperties: true
        }
    },
    indexes: [
        'projectId'
    ],
    additionalProperties: false,
    attachments: {
        encrypted: false
    }
};
