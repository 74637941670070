import { SyncActions, SyncActionTypes } from './../sync/sync.actions';
import { DailyLogEntryBase } from './../../core/rest-api/model/dailyLogEntryBase';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { DiaryCommand } from 'app/main/diary/models/diary-command';
import { Conflict } from 'app/main/models/conflict';
import { DiaryUtils } from '../../main/diary/diary.utils';
import { DiaryFilters } from '../../main/diary/models/diary.filters';
import { IssuesActions, IssuesActionTypes } from '../issues/issues.actions';
import { DiaryActions, DiaryActionTypes } from './diary.actions';
import { ConflictUtils } from 'app/db/conflict/conflict-utils';
import { CommandStoreUtils } from '../commands/commands-store-utils';
import { DiaryStoreUtilsService } from './diary-store-utils.service';
import { ActionsSubject } from '@ngrx/store';
import { EntityAttachmentMap } from '../issues/models/entity-attachment-map';

export const adapter: EntityAdapter<DailyLogEntryBase> = createEntityAdapter<
    DailyLogEntryBase
>({
    sortComparer: sortByLogEntryDateTime,
});

function sortByLogEntryDateTime(
    a: DailyLogEntryBase,
    b: DailyLogEntryBase
): number {
    if (a.logEntryDateTime > b.logEntryDateTime) {
        return -1;
    } else if (a.logEntryDateTime < b.logEntryDateTime) {
        return 1;
    } else {
        return 0;
    }
}

export interface DiaryState extends EntityState<DailyLogEntryBase> {
    filters: DiaryFilters;
    loading: boolean;
    loaded: boolean;
    selected: DailyLogEntryBase[];
    selectedCompanyId: string;
    inspectionCraft: string;
    withArchived: boolean;
    diaryCommands: DiaryCommand[];
    conflicts: Conflict<DailyLogEntryBase>[];
    issueIdsForNewInspection: string[];

    initialized: {
        commands: boolean;
        conflicts: boolean;
    };
    loadingConflicts: boolean;

    attachmentMap: EntityAttachmentMap;
}

export const initialState: DiaryState = adapter.getInitialState({
    filters: new DiaryFilters(),
    loading: false,
    loaded: false,
    selectedCompanyId: undefined,
    selected: [],
    inspectionCraft: '',
    withArchived: false,
    diaryCommands: [],
    conflicts: [],
    issueIdsForNewInspection: [],
    initialized: {
        commands: false,
        conflicts: false,
    },
    loadingConflicts: false,
    attachmentMap: new Map(),
});

export function reducer(
    state = initialState,
    action: DiaryActions | IssuesActions | SyncActions
): DiaryState {
    switch (action.type) {
        case DiaryActionTypes.CLEAR_ISSUE_IDS_FOR_NEW_INSPECTION: {
            return {
                ...state,
                issueIdsForNewInspection: [],
            };
        }

        case DiaryActionTypes.ADD_TO_ISSUE_IDS_FOR_NEW_INSPECTION: {
            const { id } = action.payload;
            return {
                ...state,
                issueIdsForNewInspection: [
                    ...state.issueIdsForNewInspection,
                    id,
                ],
            };
        }
        /**
         * we check every locked diary command
         * check, if the locked diary command has a issueIDs (local)
         * which is now generated by the backend.
         * if yes, we will replace the local id with the (real) api id
         * und unlock the command, so that it will sync with the backend in the future
         */
        case IssuesActionTypes.CREATE_ISSUES_SUCCESS: {
            const updatedCommands = [...state.diaryCommands].map((command) => {
                const isInspection =
                    command.changes.type ===
                    DailyLogEntryBase.TypeEnum.InspectionEntry;
                const inspectionIssueIds: string[] =
                    command.changes['issuesIDs'] || [];
                const hasInspectionIssues = inspectionIssueIds.length > 0;

                if (!command.locked || !isInspection || !hasInspectionIssues) {
                    return command;
                }
                // replace the local with api id
                const updatedInspectionIssueIds = inspectionIssueIds.map(
                    (possiblyLocalId) => {
                        const created = action.payload.issues.find(
                            (entry) => entry.entityId === possiblyLocalId
                        );
                        if (created) {
                            return created.issue.id;
                        }
                        // console.error(
                        //     'did not found a created issue for the inspection local id (issuesIDs)'
                        // );
                        return possiblyLocalId;
                    }
                );
                return {
                    ...command,
                    locked: false,
                    changes: {
                        ...command.changes,
                        issuesIDs: updatedInspectionIssueIds,
                    },
                };
            });

            return {
                ...state,
                diaryCommands: updatedCommands,
            };
        }

        case DiaryActionTypes.CREATE_DIARY_COMMAND: {
            let { command } = action.payload;
            const { applyConflict } = action.payload;
            let currentCommands = [...state.diaryCommands];
            if (applyConflict) {
                // filter commands with this entityId
                currentCommands = currentCommands.filter(
                    (c) => c.entityId !== command.entityId
                );
            } else {
                if (DiaryStoreUtilsService.onlyEmptyChanges(command)) {
                    return state;
                }

                // lock commands which have the same entity id
                // as a conflict
                command = ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    [command]
                )[0];
            }

            if (state.issueIdsForNewInspection.length > 0) {
                let locked = false;

                for (const id of state.issueIdsForNewInspection) {
                    // if this is the case, the diary command need to wait
                    // for the creation of issues in the backend
                    if (AcceptUtils.isLocalGuid(id)) {
                        locked = true;
                    }
                }
                command = {
                    ...command,
                    locked,
                    changes: {
                        ...command.changes,
                        issuesIDs: state.issueIdsForNewInspection,
                    },
                };
            }

            return {
                ...state,
                diaryCommands: [...currentCommands, command],
                issueIdsForNewInspection: [],
            };
        }

        case DiaryActionTypes.CREATE_DIARY_CONFLICTS: {
            return {
                ...state,
                conflicts: ConflictUtils.newestUniqueConflicts(
                    ...state.conflicts,
                    ...action.payload.conflicts
                ),
                // lock command on conflict
                diaryCommands: ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    state.diaryCommands
                ),
            };
        }

        case DiaryActionTypes.LOAD_DIARY_CONFLICTS: {
            return {
                ...state,
                loadingConflicts: true,
            };
        }

        case DiaryActionTypes.SET_DIARY_CONFLICTS: {
            return {
                ...state,
                conflicts: ConflictUtils.newestUniqueConflicts(
                    ...action.payload.conflicts
                ),
                // lock command on conflict
                diaryCommands: ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    state.diaryCommands
                ),
                initialized: {
                    ...state.initialized,
                    conflicts: true,
                },
                loadingConflicts: false,
            };
        }

        case DiaryActionTypes.SET_INSPECTION_CRAFT: {
            const { craftId } = action.payload;
            return {
                ...state,
                inspectionCraft: craftId,
            };
        }

        case DiaryActionTypes.SET_SELECTED_COMPANY_ID: {
            return {
                ...state,
                selectedCompanyId: action.payload.companyId,
            };
        }

        case DiaryActionTypes.ADD_DIARY_ATTACHMENT_MAPPINGS: {
            const combinedMaps = AcceptUtils.combineMaps(
                state.attachmentMap,
                action.payload.attachmentMap
            );
            return {
                ...state,
                attachmentMap: combinedMaps,
            };
        }

        case DiaryActionTypes.UPDATE_DIARY_ENTRIES_ATTACHMENT_IDS: {
            return {
                ...state,
                diaryCommands: CommandStoreUtils.updateCommandsAttachmentIds(
                    state.diaryCommands,
                    state.attachmentMap,
                    DiaryStoreUtilsService.replaceDiaryAttachmentIdsInCommandChanges
                ),
            };
        }

        case DiaryActionTypes.CLEAR_DIARY_ATTACHMENT_MAP: {
            return {
                ...state,
                attachmentMap: new Map(),
            };
        }

        case DiaryActionTypes.LOAD_DIARY_COMMANDS_SUCCESS: {
            return {
                ...state,
                diaryCommands: ConflictUtils.lockConflictingCommands(
                    state.conflicts,
                    action.payload.commands
                ),
                initialized: {
                    ...state.initialized,
                    commands: true,
                },
            };
        }

        case SyncActionTypes.StoreCommandSuccess: {
            const { command, databaseId } = action.payload;
            if (command.entityType === 'diary') {
                return {
                    ...state,
                    diaryCommands: CommandStoreUtils.updateCommandDatabaseId(
                        state.diaryCommands,
                        command,
                        databaseId
                    ),
                };
            } else {
                return {
                    ...state,
                };
            }
        }

        case DiaryActionTypes.LOAD_DIARY_ENTRIES_REQUEST: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }

        case DiaryActionTypes.LOAD_DIARY_ENTRIES_SUCCESS: {
            const entries = action.payload.diaries.map((entry) => {
                return {
                    ...entry,
                    creationDate: new Date(entry.creationDate),
                    logEntryDateTime: new Date(entry.logEntryDateTime),
                    updateDateTime: new Date(entry.updateDateTime),
                    deleteDateTime: new Date(entry.deleteDateTime),
                };
            });
            return adapter.setAll(entries, {
                ...state,
                loaded: true,
                loading: false,
            });
        }

        case DiaryActionTypes.LOAD_DIARY_ENTRIES_ERROR: {
            return { ...state, loading: false };
        }

        case DiaryActionTypes.CREATE_DIARY_ENTRIES_SUCCESS: {
            const { diaries, currentProjectId } = action.payload;
            const createdDiaries = diaries.map((i) => i.diary);
            const createSuccessIds = diaries.map((i) => i.entityId);
            const createdDiariesToAdd = createdDiaries
                .filter((diary) => diary.projectId === currentProjectId)
                .map((entry) => ({
                    ...entry,
                    creationDate: new Date(entry.creationDate),
                    logEntryDateTime: new Date(entry.logEntryDateTime),
                    updateDateTime: new Date(entry.updateDateTime),
                    deleteDateTime: new Date(entry.deleteDateTime),
                }));
            // If in this time frame the user created a new update command for that issue
            // we are creating a command with "waitForCreation: true".
            // This is the place when the creation was successfull, so we will update all
            // update commands with that entityId
            const updatedWaitForCreation = state.diaryCommands
                .filter((c) => {
                    return (
                        (c.locked || c.waitForCreation) && c.action === 'update'
                    );
                })
                .map((command) => {
                    const diaryCreate = diaries.find(
                        (create) => create.entityId === command.entityId
                    );
                    if (diaryCreate) {
                        const { diary } = diaryCreate;
                        return {
                            ...command,
                            waitForCreation: false,
                            entityId: diary.id,
                            changes: {
                                ...command.changes,
                                updateDateTime: diary.updateDateTime,
                            },
                        };
                    }

                    // there are 'wait for creation' / locked update commands which are not unlocked by the
                    // current successful creates
                    return command;
                });

            const commandsWithoutCreatedEntities = state.diaryCommands.filter(
                (c) => !createSuccessIds.includes(c.entityId)
            );

            return adapter.addMany(createdDiariesToAdd, {
                ...state,
                diaryCommands: [
                    ...commandsWithoutCreatedEntities,
                    ...updatedWaitForCreation,
                ],
            });
        }

        case DiaryActionTypes.UPDATE_DIARY_ENTRIES_ERROR:
            const errorIds = action.payload.errors.map((e) => e.entityId);
            return {
                ...state,
                diaryCommands: [...state.diaryCommands].map((c) =>
                    errorIds.includes(c.entityId) ? { ...c, locked: true } : c
                ),
            };

        case DiaryActionTypes.CREATE_DIARY_ENTRIES_ERROR: {
            const createErrorIds = action.payload.errors.map((e) => e.entityId);

            return {
                ...state,
                diaryCommands: [...state.diaryCommands].map((c) =>
                    createErrorIds.includes(c.entityId)
                        ? { ...c, locked: true }
                        : c
                ),
            };
        }

        case DiaryActionTypes.UPDATE_DIARY_ENTRIES_SUCCESS: {
            const { updates, currentProjectId } = action.payload;
            const updateIds = updates.map((u) => u.id);
            const updatesToApply = updates
                .filter((u) => u.changes.projectId === currentProjectId)
                .map((update) => ({
                    ...update,
                    changes: {
                        ...update.changes,
                        creationDate: new Date(update.changes.creationDate),
                        logEntryDateTime: new Date(
                            update.changes.logEntryDateTime
                        ),
                        updateDateTime: new Date(update.changes.updateDateTime),
                        deleteDateTime: new Date(update.changes.deleteDateTime),
                    },
                }));

            // keep commands which were not transferred yet
            const commandsWithoutUpdatedDiaryIds = state.diaryCommands.filter(
                (c) => !updateIds.includes(c.entityId)
            );

            // update commands that were created, when there was an
            // ongoing PATCH to the backend. These were on hold
            // by setting "waitForCreation" to true.
            // now we are removing the on hold, and setting
            // the updatedatetime of the update
            const updatedWaitForCreation = state.diaryCommands
                .filter((c) => c.waitForCreation && c.action === 'update')
                .map((command) => {
                    const update = updates.find(
                        (u) => u.changes.id === command.entityId
                    );
                    if (update) {
                        return {
                            ...command,
                            waitForCreation: false,
                            entityId: update.changes.id,
                            changes: {
                                ...command.changes,
                                updateDateTime: update.changes.updateDateTime,
                            },
                        };
                    }
                });

            return adapter.updateMany(updatesToApply, {
                ...state,
                diaryCommands: [
                    // existing commands are deleted by not including them in any
                    // of these two arrays
                    ...commandsWithoutUpdatedDiaryIds,
                    ...updatedWaitForCreation,
                ],
                conflicts: state.conflicts.filter(
                    (conflictItem) =>
                        !updateIds.includes(conflictItem.itemWantUpdate.id)
                ),
            });
        }

        case DiaryActionTypes.TOGGLE_SELECT_DIARY_ENTRY: {
            const selected = [...state.selected];

            if (selected.indexOf(action.payload.diaryEntry) > -1) {
                selected.splice(selected.indexOf(action.payload.diaryEntry), 1);
            } else {
                selected.push(action.payload.diaryEntry);
            }

            return {
                ...state,
                selected: selected,
            };
        }

        case DiaryActionTypes.TOGGLE_SELECT_MONTH: {
            let filteredSelected = DiaryUtils.filterDiaryEntries({
                diaryEntries: [...state.selected],
                filters: state.filters,
            });
            const ids = state.ids as string[];

            const allEntriesForMonth = ids
                .filter((id) =>
                    areDatesInTheSameMonth(
                        state.entities[id].logEntryDateTime,
                        action.payload.date
                    )
                )
                .map((id) => state.entities[id]);

            const allEntriesForMonthFiltered = DiaryUtils.filterDiaryEntries({
                diaryEntries: allEntriesForMonth,
                filters: state.filters,
            });

            const selectedForMonth = filteredSelected.filter((entry) =>
                areDatesInTheSameMonth(
                    entry.logEntryDateTime,
                    action.payload.date
                )
            );

            const selectedWithoutThisMonth = filteredSelected.filter(
                (entry) =>
                    !areDatesInTheSameMonth(
                        entry.logEntryDateTime,
                        action.payload.date
                    )
            );

            if (allEntriesForMonthFiltered.length === selectedForMonth.length) {
                filteredSelected = selectedWithoutThisMonth;
            } else {
                filteredSelected = allEntriesForMonthFiltered.concat(
                    selectedWithoutThisMonth
                );
            }

            return {
                ...state,
                selected: filteredSelected,
            };
        }

        case DiaryActionTypes.TOGGLE_SELECT_DATE: {
            let filteredSelected = DiaryUtils.filterDiaryEntries({
                diaryEntries: [...state.selected],
                filters: state.filters,
            });
            const ids = state.ids as string[];

            const allEntriesForDate = ids
                .filter((id) =>
                    areDatesAtTheSameDate(
                        state.entities[id].logEntryDateTime,
                        action.payload.date
                    )
                )
                .map((id) => state.entities[id]);

            const allEntriesForDateFiltered = DiaryUtils.filterDiaryEntries({
                diaryEntries: allEntriesForDate,
                filters: state.filters,
            });

            const selectedForDate = filteredSelected.filter((entry) =>
                areDatesAtTheSameDate(
                    entry.logEntryDateTime,
                    action.payload.date
                )
            );

            const selectedWithoutThisDate = filteredSelected.filter(
                (entry) =>
                    !areDatesAtTheSameDate(
                        entry.logEntryDateTime,
                        action.payload.date
                    )
            );

            if (allEntriesForDateFiltered.length === selectedForDate.length) {
                filteredSelected = selectedWithoutThisDate;
            } else {
                filteredSelected = allEntriesForDateFiltered.concat(
                    selectedWithoutThisDate
                );
            }

            return {
                ...state,
                selected: filteredSelected,
            };
        }

        case DiaryActionTypes.TOGGLE_SELECT_ALL_DIARY_ENTRIES: {
            const ids = state.ids as string[];
            const allEntries = ids.map((id) => state.entities[id]);
            const allEntriesFiltered = DiaryUtils.filterDiaryEntries({
                diaryEntries: allEntries,
                filters: state.filters,
            });

            if (allEntriesFiltered.length === state.selected.length) {
                return {
                    ...state,
                    selected: [],
                };
            } else {
                return {
                    ...state,
                    selected: allEntriesFiltered,
                };
            }
        }

        case DiaryActionTypes.DESELECT_ALL_DIARY_ENTRIES: {
            return {
                ...state,
                selected: [],
            };
        }

        case DiaryActionTypes.TOGGLE_ARCHIVED_DIARY_ENTRIES: {
            return {
                ...state,
                withArchived: !state.withArchived,
            };
        }

        case DiaryActionTypes.SET_DIARY_FILTERS:
            return {
                ...state,
                filters: action.payload.filters,
                selected: [],
            };

        case DiaryActionTypes.CLEAN_UP_TEMP_ENTRIES: {
            const ids = state.ids as string[];
            const tmpIds = ids.filter((id) => AcceptUtils.isLocalGuid(id));
            return adapter.removeMany(tmpIds, state);
        }

        case SyncActionTypes.DiscardCommandsWithErrors: {
            const discardParameters = action.payload.discardParameters;

            return {
                ...state,
                diaryCommands: CommandStoreUtils.removeCommandsWithIds(
                    state.diaryCommands,
                    discardParameters
                ),
            };
        }

        case SyncActionTypes.MarkCommandsInFlightForEntities: {
            return {
                ...state,
                diaryCommands: CommandStoreUtils.markCommandsInFlightForEntities(
                    state.diaryCommands,
                    action.payload.entityIds
                ),
            };
        }

        case SyncActionTypes.ClearInFlightFlagOfCommands: {
            return {
                ...state,
                diaryCommands: CommandStoreUtils.clearInFlightFlagOfCommands(
                    state.diaryCommands
                ),
            };
        }

        default:
            return state;
    }
}

function areDatesInTheSameMonth(date1: Date, date2: Date): boolean {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
    );
}

function areDatesAtTheSameDate(date1: Date, date2: Date): boolean {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
