import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { createRestrictedFunctionalityCTAURL } from 'app/shared/services/demo/cta-links';

@Component({
    selector: 'acc-demo-dialog',
    templateUrl: 'demo-dialog.component.html',
    styleUrls: ['demo-dialog.component.scss'],
})
export class DemoDialogComponent implements OnInit {
    restrictedFunctionalityCTAURL = createRestrictedFunctionalityCTAURL('popup');

    constructor(
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
    }
}
