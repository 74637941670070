export const locale = {
    lang: 'de',
    data: {
        NAV: {
            ORDER: {
                TITLE: 'Überzeugt? Jetzt bestellen!',
            },
            PROJECTS: {
                TITLE: 'Projekte',
            },
            ISSUES: {
                TITLE: 'Tickets',
            },
            DIARY: {
                TITLE: 'Begehung',
            },
            ARCHIVE: {
                TITLE: 'Archiv',
            },
            BUILDINGS: {
                TITLE: 'Gebäude',
            },
            USERS: {
                TITLE: 'Teilnehmer',
            },
            SETTINGS: {
                TITLE: 'Einstellungen',
            },
            SYNC: {
                TITLE: 'Synchronisation',
            },
            BACKLINK: {
                TITLE: 'untermStrich',
            },
            SHARED_BOILERPLATES: 'Globale Textbausteine',
            GLOBAL_SETTINGS: {
                ADDRESS_BOOK: {
                    TITLE: 'Adressbuch',
                },
                COMPANY_LOGO: {
                    TITLE: 'Unternehmenslogo',
                },
                TEXT_COMPONENTS: {
                    TITLE: 'Textbausteine',
                },
                ISSUE_CATEGORIES: {
                    TITLE: 'Ticketkategorien',
                },
            },
            ABOUT: {
                TITLE: 'Kontoeinstellungen',
            },
            HELP: {
                TITLE: 'Hilfe benötigt?',
            },
        },
    },
};
