import { Pipe, PipeTransform } from '@angular/core';
import { Location as FlinkLocation } from 'app/core/rest-api';

@Pipe({ name: 'locationtreetext' })
export class LocationTreeTextPipe implements PipeTransform {
    transform(position: string, locations: FlinkLocation[]): string {
        let locationTree = [];
        let currentItem: any = locations.find(l => l.id === position);
        if (!currentItem) {
            return '';
        }
        while (currentItem.parentID) {
            locationTree = [currentItem, ...locationTree];
            const parent = locations.find(l => l.id === currentItem.parentID);
            if (parent) {
                currentItem = parent;
            } else {
                currentItem = {
                    title: 'P-ID? ' + currentItem.parentID
                };
            }
        }
        locationTree = [currentItem, ...locationTree];

        let result = '';

        for (let i = 0; i < locationTree.length; i++) {
            const current = locationTree[i];
            if (current.title) {
                result += current.title;
            }
            const isLast = i === locationTree.length - 1;
            if (!isLast) {
                result += ' | ';
            }
        }

        return result;
    }
}
