import { RxJsonSchema } from 'rxdb';
import {
    customAttributeTemplateIdsSchema,
    customAttributeTemplatesSchema,
} from '../custom-attributes/custom-attribute.model';
import {
    checklistTemplatesIdsSchema,
    checklistSchema,
} from '../checklists/checklists.models';

export const projectSchema: RxJsonSchema = {
    title: 'project schema',
    description: 'describes a project',
    version: 2,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true,
        },
        title: {
            type: ['string', 'null'],
        },
        creationDate: {
            type: ['string', 'null'],
        },
        markedAsDelete: {
            type: ['boolean', 'null'],
        },
        updateDateTime: {
            type: ['string', 'null'],
        },
        dueDate: {
            type: ['string', 'null'],
        },
        startDate: {
            type: ['string', 'null'],
        },
        address: {
            type: ['string', 'null'],
        },
        postCode: {
            type: ['string', 'null'],
        },
        city: {
            type: ['string', 'null'],
        },
        country: {
            type: ['string', 'null'],
        },
        craftExternalResponsableId: {
            type: 'string',
        },
        craftInternalResponsableId: {
            type: 'string',
        },
        serialLetters: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        photo: {
            type: ['string', 'null'],
        },
        customAttributeTemplateIds: customAttributeTemplateIdsSchema,
        customAttributeTemplates: customAttributeTemplatesSchema,
        checklistTemplatesIds: checklistTemplatesIdsSchema,
        checklistTemplates: checklistSchema,
    },
    // do not allow properties that are not in the schema
    additionalProperties: false,
    attachments: {
        encrypted: false,
    },
};
