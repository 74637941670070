import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { select, Store } from '@ngrx/store';
import { GalleryItem, ImageItem } from '@ngx-gallery/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import {
    catchError,
    first,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
    take,
    tap,
} from 'rxjs/operators';

import { AcceptUtils } from 'app/core/utils/accept-utils';
import { EntityType } from 'app/main/sync/models/entity-type';
import { CoreState } from 'app/store/core/core.reducer';
import { getIsDevice, getIsOffline } from 'app/store/core/core.selectors';
import { environment } from 'environments/environment';
import {
    AttachmentAvailable,
    AttachmentResult,
    AttachmentResultType,
    AttachmentError,
} from './attachment-result.model';
import {
    FileNotFoundError,
    FileStorageService,
} from 'app/shared/services/attachment-storage/file-storage.service';
import { getIssueMarkedPlan } from 'app/store/issues/issues.selectors';
import { getMarkedPlansInfo } from 'app/store/projects/projects.selectors';
import { MarkedPlanInfo } from 'app/core/rest-api/model/markedPlanInfo';
import { getPlanIdByLocationIdCraftId } from 'app/store/settings/settings.selectors';
import { FilesService } from 'app/core/rest-api';

/**
 * Used as input in {@link AssetTileService.loadImageOrDefault}.
 * __All parameters are required.__
 */
export interface ImageOrDefaultLoadOptions {
    /**
     * The attachment id of the image to load.
     */
    id: string;
    /**
     * The related entity type. Only used for improving error messages.
     */
    entityType: EntityType;
    /**
     * The entity id this attachment id belongs to. Only used for
     * improving error messages.
     */
    entityId: string;
    /**
     * The fallback image url to use.
     */
    defaultImageUrl: string;
}

@Injectable({
    providedIn: 'root',
})
export class AssetTileService {
    issueMarkedPlanPlan: any;
    markedPlansEntities: MarkedPlanInfo[];
    planIdByLocationIdCraftId: any;

    constructor(
        private http: HttpClient,
        private store: Store<CoreState>,
        private fileStorageService: FileStorageService,
        private filesService: FilesService
    ) { }

    private static logAttachmentError(
        attachmentResult: AttachmentResult,
        logFunction: (
            message?: any,
            ...optionalParams: any[]
        ) => void = console.error
    ): void {
        if (attachmentResult.type !== AttachmentResultType.AttachmentError) {
            logFunction('result', attachmentResult, 'is not an error');
            return;
        }

        const errorDetails = (attachmentResult as AttachmentError).errorDetails;
        logFunction(
            'error type',
            errorDetails.errorType,
            'entity type',
            errorDetails.entityType,
            'entity id',
            errorDetails.entityId,
            'attachment id',
            errorDetails.assetId,
            'additionalInformation',
            errorDetails.additionalInformation
        );
    }

    private static warnDifferentBlobTypes(
        attachment: {
            blobUrl: string;
            blobType: string;
        },
        thumbnail: {
            blobUrl: string;
            blobType: string;
        }
    ): void {
        if (attachment.blobType !== thumbnail.blobType) {
            console.warn(
                'attachment has content type "',
                attachment.blobType,
                '" but thumbnail has "',
                thumbnail.blobType,
                '" during fallback'
            );
        }
    }

    private static extractUrlAndType(
        attachmentResult: AttachmentResult,
        logError: boolean = true
    ): {
        blobUrl: string;
        blobType: string;
    } {
        switch (attachmentResult.type) {
            case AttachmentResultType.AttachmentAvailable:
                const attachmentAvailable = attachmentResult as AttachmentAvailable;
                return {
                    blobUrl: attachmentAvailable.blobUrl,
                    blobType: attachmentAvailable.blobType,
                };
            case AttachmentResultType.AttachmentError:
                if (logError) {
                    AssetTileService.logAttachmentError(attachmentResult);
                }

                return {
                    blobUrl: '',
                    blobType: '',
                };
        }
    }

    async generateGalleryItems(
        entityId: string,
        assetIds: string[],
        entityType: EntityType,
        onlyThumb = false,
        onlySrc = false,
        locationId?: string,
        craftId?: number,
    ): Promise<GalleryItem[]> {
        let planIdByLocationIdCraftId = null;
        await this.store.pipe(
            select(getIssueMarkedPlan),
            tap(markedPlan => this.issueMarkedPlanPlan = markedPlan),
            first()
        ).subscribe()

        await this.store.pipe(
            select(getMarkedPlansInfo),
            tap(markedPlansEntities => this.markedPlansEntities = markedPlansEntities),
            first()
        ).subscribe()

        this.store.pipe(
            select(getPlanIdByLocationIdCraftId, {
                locationId: locationId,
                craftId: craftId
            }),
            tap(planId => {
                planIdByLocationIdCraftId = planId
            }),
            first()
        ).subscribe();

        try {
            if (!entityId) {
                entityId = 'attachmentcache';
            }
            if (!assetIds || assetIds.length === 0) {
                return [];
            }
            const isDevice = await this.store
                .pipe(
                    select(getIsDevice),
                    first() // needed!
                )
                .toPromise();
            const items: GalleryItem[] = [];
            if (assetIds && assetIds.length) {
                // push requests for each image and thumbnail in this array
                const requests: Observable<AttachmentResult>[] = [];
                let additionalPath = '';
                let additionalFetchedFile = null;
                let fileContentType = null;
                for (const id of assetIds) {
                    const foundInMarkedPlans = this.markedPlansEntities.find(mpe => mpe.planId == id)

                    if (foundInMarkedPlans) {
                        if (foundInMarkedPlans.fileType == 'application/pdf') {
                            additionalPath = AcceptUtils.fullscaleSuffix();
                        }
                    } else if (planIdByLocationIdCraftId) {
                        console.log('Get files info for '+planIdByLocationIdCraftId);
                        additionalFetchedFile = await this.filesService.filesByIdGet(planIdByLocationIdCraftId).toPromise()
                        fileContentType = additionalFetchedFile?.data?.contentType;

                        if (fileContentType == 'application/pdf') {
                            additionalPath = AcceptUtils.fullscaleSuffix();
                        }
                    }

                    const thumbId = id + AcceptUtils.thumbnailSuffix();
                    const assetPath = environment.endpoints.asset;

                    const src = assetPath + id + additionalPath;
                    const thumb = assetPath + thumbId;

                    if (!onlyThumb) {
                        requests.push(
                            this.getBlobUrl({
                                src,
                                isDevice,
                                entityType,
                                entityId,
                                id,
                            })
                        );
                    }
                    if (!onlySrc) {
                        requests.push(
                            this.getBlobUrl({
                                src: thumb,
                                isDevice,
                                entityType,
                                entityId,
                                id: thumbId,
                            })
                        );
                    }
                }

                const results: AttachmentResult[] = await forkJoin(
                    requests
                ).toPromise();

                if (onlyThumb) {
                    for (let i = 0, len = results.length; i < len; i++) {
                        const available =
                            results[i].type ===
                            AttachmentResultType.AttachmentAvailable;

                        const thumbnail = AssetTileService.extractUrlAndType(
                            results[i]
                        );

                        items.push(
                            new ImageItem({
                                thumb: thumbnail.blobUrl,
                                type: thumbnail.blobType,
                                id: assetIds[i],
                                available,
                            })
                        );
                    }
                } else if (onlySrc) {
                    for (let i = 0, len = results.length; i < len; i++) {
                        const available =
                            results[i].type ===
                            AttachmentResultType.AttachmentAvailable;

                        const attachment = AssetTileService.extractUrlAndType(
                            results[i]
                        );

                        items.push(
                            new ImageItem({
                                src: attachment.blobUrl,
                                type: attachment.blobType,
                                id: assetIds[i],
                                available,
                            })
                        );
                    }
                } else {
                    for (let i = 0, len = results.length; i < len - 1; i += 2) {
                        const attachmentAvailable =
                            results[i].type ===
                            AttachmentResultType.AttachmentAvailable;

                        const thumbnailAvailable =
                            results[i + 1].type ===
                            AttachmentResultType.AttachmentAvailable;

                        const available =
                            attachmentAvailable || thumbnailAvailable;

                        let attachment = AssetTileService.extractUrlAndType(
                            results[i],
                            false
                        );
                        let thumbnail = AssetTileService.extractUrlAndType(
                            results[i + 1],
                            false
                        );

                        // reuse the other if one is missing
                        if (!available) {
                            console.error(
                                'neither attachment nor its thumbnail available'
                            );
                            AssetTileService.logAttachmentError(results[i]);
                            AssetTileService.logAttachmentError(results[i + 1]);
                        } else if (!attachmentAvailable) {
                            AssetTileService.warnDifferentBlobTypes(
                                attachment,
                                thumbnail
                            );
                            attachment = thumbnail;
                        } else if (!thumbnailAvailable) {
                            AssetTileService.warnDifferentBlobTypes(
                                attachment,
                                thumbnail
                            );
                            thumbnail = attachment;
                        }

                        items.push(
                            new ImageItem({
                                src: attachment.blobUrl,
                                type: attachment.blobType,
                                thumb: thumbnail.blobUrl,
                                id: assetIds[i / 2],
                                available,
                            })
                        );
                    }
                }
            }
            return items;
        } catch (ex) {
            console.error(
                'failed to generate gallery items for entity',
                entityId,
                'with asset ids',
                assetIds,
                ', cause:',
                ex
            );
            throw ex;
        }
    }

    /**
     * Load a blob from either the offline db or the backend. For example
     * to display images or thumbnails.
     */
    getBlobUrl(o: {
        src: string;
        isDevice: boolean;
        entityType: EntityType;
        entityId: string;
        id: string;
    }): Observable<AttachmentResult> {
        const fromOnline$ = from(Preferences.get({ key: 'token' })).pipe(
            mergeMap((token) =>
                this.http
                    .get(o.src, {
                        responseType: 'blob' as 'json',
                        headers: {
                            Authorization: 'Bearer ' + token.value,
                        },
                    })
                    .pipe(
                        map((blob: Blob) => {
                            const blobType = blob.type;
                            const blobUrl = URL.createObjectURL(blob);

                            return AttachmentAvailable.create({
                                blobUrl,
                                blobType,
                            });
                        }),
                        catchError((error) => {
                            console.error('fetch blob failed', o.src);
                            return of(AttachmentError.networkIssue(error));
                        })
                    )
            )
        );

        if (o.isDevice) {
            return from(this.getAttachmentFromDb(o.id)).pipe(
                withLatestFrom(this.store.pipe(select(getIsOffline))),
                switchMap(([dbAttachment, isOffline]) => {
                    const dbHasAttachment =
                        dbAttachment.type ===
                        AttachmentResultType.AttachmentAvailable;
                    // TODO: enable this when thumbnails are fully available offline
                    // if (!dbHasAttachment && !isOffline) {
                    //     console.warn(
                    //         'falling back to backend for attachment',
                    //         o.id
                    //     );
                    // }

                    if (dbHasAttachment || isOffline) {
                        return of(dbAttachment);
                    }

                    return fromOnline$;
                }),
                map((result) => {
                    if (result.type === AttachmentResultType.AttachmentError) {
                        // add additional error information
                        return {
                            ...result,
                            errorDetails: {
                                errorType: (result as any).errorDetails
                                    .errorType,
                                entityType: o.entityType,
                                entityId: o.entityId,
                                assetId: o.id,
                            },
                        };
                    } else {
                        return result;
                    }
                })
            );
        }

        return fromOnline$;
    }

    private async getAttachmentFromDb(
        assetId: string
    ): Promise<AttachmentResult> {
        try {
            const data = await this.fileStorageService.loadAttachment(assetId);

            if (!data) {
                console.error('attachment data missing', assetId);
                return AttachmentError.dataMissing();
            }

            const type = data.type;
            const blobUrl = URL.createObjectURL(data);

            return AttachmentAvailable.create({
                blobUrl,
                blobType: type,
            });
        } catch (ex) {
            if (ex instanceof FileNotFoundError) {
                return AttachmentError.attachmentMissing();
            }

            console.error('failed to get attachment from DB', ex);
            return AttachmentError.otherError(ex);
        }
    }

    /**
     * Frees the memory used by an asset. If the objectURL is null
     * this method is a no-op.
     */
    releaseAsset(objectURL: string | null): void {
        if (objectURL) {
            URL.revokeObjectURL(objectURL);
        }
    }

    onlyAvailableImages(galleryItems: GalleryItem[]): GalleryItem[] {
        return galleryItems.filter((item) => item.data.available);
    }

    onlyAvailableIndex(galleryItems: GalleryItem[], index: number): number {
        let newIndex = 0;
        for (let i = 0; i < index; i++) {
            if (galleryItems[i].data.available) {
                newIndex++;
            }
        }
        return newIndex;
    }

    /**
     * Load the image with the given id. It aggresively falls back to returning the defaultImage
     * while logging any errors.
     *
     * Especially in these cases:
     * - image is not available
     * - image failed to load
     * - blob belonging to the id has a content type which does not start with 'image'
     * - all other errors in the underlying observables
     *
     * __Please note, that the returned URL must be released again using `releaseAsset`
     * of this service, _if it's not the default url_. Otherwise memory may be leaked.__
     *
     * The returned observable only emits once and completes after that.
     *
     * See documentation of {@link LoadOptions} for details about the input parameters.
     */
    loadImageOrDefault(
        loadOptions: ImageOrDefaultLoadOptions
    ): Observable<string> {
        return this.store.pipe(
            select(getIsDevice),
            // don't react to all changes to the isDevice state, we only need
            // the current one
            take(1),
            switchMap((isDevice: boolean) => {
                return this.getBlobUrl({
                    id: loadOptions.id,
                    // backend URL of this attachment
                    src: environment.endpoints.asset + loadOptions.id,
                    isDevice,
                    entityId: loadOptions.entityId,
                    entityType: loadOptions.entityType,
                });
            }),
            // the getBlobUrl logic should be completed after the image was loaded,
            // but limit it here again to be on the save side
            take(1),
            map((attachmentResult) => {
                if (
                    attachmentResult.type ===
                    AttachmentResultType.AttachmentAvailable
                ) {
                    const attachmentAvailable = attachmentResult as AttachmentAvailable;

                    const notAnImage = !attachmentAvailable.blobType.startsWith(
                        'image'
                    );
                    if (notAnImage) {
                        this.releaseAsset(attachmentAvailable.blobUrl);

                        console.warn(
                            'non-image blobType for',
                            loadOptions.id,
                            ':',
                            attachmentAvailable.blobType
                        );

                        return loadOptions.defaultImageUrl;
                    }

                    return attachmentAvailable.blobUrl;
                } else if (
                    attachmentResult.type ===
                    AttachmentResultType.AttachmentError
                ) {
                    const attachmentError = attachmentResult as AttachmentError;

                    console.error(
                        'failed to load image',
                        attachmentError.errorDetails
                    );

                    return loadOptions.defaultImageUrl;
                }
            }),
            catchError((error) => {
                console.error(
                    'unexpected error when loading project image',
                    error
                );
                return of(loadOptions.defaultImageUrl);
            })
        );
    }
}
