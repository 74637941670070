<div fxFlex class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
    <div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon
            *ngFor="let icon of _icons"
            [ngClass]="{ 's-120': !small, 's-40': small }"
            class="secondary-text"
            [@animate]="{
                value: '*',
                params: { delay: '50ms', scale: '0.2' }
            }"
        >
            {{ icon }}
        </mat-icon>
    </div>
    <p
        class="secondary-text h2"
        [ngClass]="{ h2: !small, h4: small, center: center }"
        [@animate]="{
            value: '*',
            params: { delay: '50ms', scale: '0.2' }
        }"
    >
        {{ translateCode | translate }}
    </p>
</div>
