import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterByText } from './filter-by-text.model';

@Component({
    selector: 'acc-filter-by-text',
    templateUrl: './filter-by-text.component.html',
    styleUrls: ['./filter-by-text.component.scss'],
})
export class FilterByTextComponent implements OnInit {
    @Input() filterItem: FilterByText;

    constructor() {}

    ngOnInit(): void {}
}
