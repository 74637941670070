import { Inject, Injectable, Optional } from '@angular/core';

import { ArgumentMissingError } from 'app/core/utils/exceptions';

import {
    defaultEventAllowedCheckOptions,
    EVENT_ALLOWED_OPTIONS,
    EventAllowedCheckOptions,
} from './event-allowed-check-options.model';
import { ClientTarget, extractSingleClientTarget, isMultitouchEvent } from './event-target';
import { Vec2D } from './transform.model';

/**
 * The last zoom / pan event.
 */
export interface LastPinchPan {
    /**
     * The end position of the gesture.
     */
    endPosition: Vec2D;

    /**
     * The timestamp in millis obtained from Date.now()
     * of the time of the event.
     */
    timestamp: number;

    /**
     * A rectangle indiciating the bounds and position
     * of the zoom area.
     */
    zoomArea: DOMRect;
}

/**
 * A services providing utilities to check if specific events should run
 * after a zoom / pan event. You can provide optional configuration by
 * using the EVENT_ALLOWED_OPTIONS injection token.
 */
@Injectable()
export class EventAllowedCheckService {
    private options: EventAllowedCheckOptions;

    constructor(
        @Inject(EVENT_ALLOWED_OPTIONS)
        @Optional()
        options?: EventAllowedCheckOptions
    ) {
        this.options = {
            ...defaultEventAllowedCheckOptions,
            ...options,
        };
    }

    /**
     * Check if the given event is allowed be processed, i.e. it's either
     * after some time after the last gesture or in a specific distance.
     * In addition, only single-touch events are allowed.
     *
     * @param event The event to check.
     * @param lastPinchPan
     */
    eventAllowed(
        event: TouchEvent | MouseEvent,
        lastPinchPan?: LastPinchPan
    ): boolean {
        if (!event) {
            throw new ArgumentMissingError('event');
        }

        // Multi touch is handled by Hammer, so only
        // - single point touch events or
        // - zero point (touch end) events
        // are valid here.
        if (isMultitouchEvent(event)) {
            return false;
        }

        const noPinchPanYet = !lastPinchPan;

        if (noPinchPanYet) {
            // no zoom event yet happened => other events are not an issue
            return true;
        }

        if (this.enoughTimePassed(lastPinchPan.timestamp)) {
            return true;
        }

        const target = extractSingleClientTarget(event);

        return this.farEnoughAway(target, lastPinchPan);
    }

    private enoughTimePassed(timestamp: number): boolean {
        const now = Date.now();
        const timeSinceLastZoom = now - timestamp;

        return timeSinceLastZoom >= this.options.timeThreshold;
    }

    private farEnoughAway(
        target: ClientTarget,
        lastZoomPan: LastPinchPan
    ): boolean {
        const distanceToLastZoomEvent = (xTo: number, yTo: number): number => {
            // at this point there was a zoom event already, thus
            // the point this function compares to is already present
            return Math.sqrt(
                (xTo - lastZoomPan.endPosition.x) ** 2 +
                    (yTo - lastZoomPan.endPosition.y) ** 2
            );
        };

        // determine offset in the target area
        const x = target.clientX - lastZoomPan.zoomArea.x;
        const y = target.clientY - lastZoomPan.zoomArea.y;
        const distance = distanceToLastZoomEvent(x, y);

        const DISTANCE_THRESHOLD = 5;

        return distance >= DISTANCE_THRESHOLD;
    }
}
