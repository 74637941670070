import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getSearch } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import * as fromProjectTemplates from './project-templates.reducer';

export const selectProjectTemplatesState = createFeatureSelector<
    fromProjectTemplates.ProjectTemplatesState
>('project-templates');

export const selectAllProjectTemplates = createSelector(
    selectProjectTemplatesState,
    fromProjectTemplates.selectAll
);

export const selectAllUserCreatedProjectTemplates = createSelector(
    selectAllProjectTemplates,
    (templates) => templates.filter((template) => !template['isReadOnly'])
);

export const selectAllActiveProjectTemplates = createSelector(
    selectAllProjectTemplates,
    (projectTemplatesState) =>
        projectTemplatesState.filter((template) => !template.markedAsDelete)
);

export const selectAllActiveUserCreatedProjectTemplates = createSelector(
    selectAllUserCreatedProjectTemplates,
    (projectTemplatesState) =>
        projectTemplatesState.filter((template) => !template.markedAsDelete)
);

export const selectFilteredUserCreatedProjectTemplates = createSelector(
    selectProjectTemplatesState,
    selectAllUserCreatedProjectTemplates,
    getSearch,
    (projectTemplatesState, projects, searchQuery) =>
        projects
            .filter((template) => {
                if (projectTemplatesState.withArchived) {
                    return true;
                } else {
                    return !template.markedAsDelete;
                }
            })
            .filter((template) => {
                if (searchQuery) {
                    return template.title
                        .toLowerCase()
                        .match(`.*${searchQuery.toLowerCase()}.*`);
                } else {
                    return true;
                }
            })
);

export const selectActiveFilteredUserCreatedProjectTemplates = createSelector(
    selectAllActiveUserCreatedProjectTemplates,
    getSearch,
    (projectTemplatesState, searchQuery) =>
        projectTemplatesState.filter((template) => {
            if (searchQuery) {
                return template.title
                    .toLowerCase()
                    .match(`.*${searchQuery.toLowerCase()}.*`);
            } else {
                return true;
            }
        })
);

export const selectAllArchivedProjectTemplates = createSelector(
    selectAllProjectTemplates,
    (projectTemplatesState) =>
        projectTemplatesState.filter((template) => template.markedAsDelete)
);

export const selectProjectTemplateById = (templateId: string) =>
    createSelector(
        selectProjectTemplatesState,
        (projectTemplatesState) => projectTemplatesState.entities[templateId]
    );

export const getProjectTemplatesByIds = (templateIds: string[]) =>
    createSelector(selectAllProjectTemplates, (projects) =>
        projects.filter((template) => templateIds.includes(template.id))
    );

export const selectAllProjectTemplateEntities = createSelector(
    selectProjectTemplatesState,
    fromProjectTemplates.selectEntities
);

export const getLoading = createSelector(
    selectProjectTemplatesState,
    (state) => state.loading
);

export const getLoaded = createSelector(
    selectProjectTemplatesState,
    (state) => state.loaded
);

export const getSelectedProjectTemplates = createSelector(
    selectProjectTemplatesState,
    (state) => state.selected
);

export const getProjectTemplateIds = createSelector(
    selectProjectTemplatesState,
    fromProjectTemplates.selectIds
);

export const getTotalProjectTemplateCount = createSelector(
    selectProjectTemplatesState,
    selectAllUserCreatedProjectTemplates,
    selectAllActiveUserCreatedProjectTemplates,
    (state, allTemplates, allActiveTemplates) => {
        if (state.withArchived) {
            return allTemplates.length;
        } else {
            return allActiveTemplates.length;
        }
    }
);



/**
 * Get the current `projectId` (although expecting a template) field from the Route, and load that 
 *  from Project Templates. Mostly copied from project.selectors.ts
 */
export const getCurrentProjectTemplate = createSelector(
    selectProjectTemplatesState, // This is where we differentiate from the Project selector
    getCurrentProjectId,
    (state, id) => {
        if (state && state?.entities) {
            return state.entities[id];
        } else {
            return { timeZoneId: '' };
        }
    }
);
