import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { ExternalContactsEffects } from './external-contacts.effects';
import * as fromExternalContacts from './external-contacts.reducer';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        MatSnackBarModule,
        StoreModule.forFeature(
            'external-contacts',
            fromExternalContacts.reducer
        ),
        EffectsModule.forFeature([ExternalContactsEffects]),
    ],
})
export class ExternalContactsStoreModule {}
