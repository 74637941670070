import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import http from 'pouchdb-adapter-http';
import idb from 'pouchdb-adapter-idb';
import { addRxPlugin, createRxDatabase, removeRxDatabase } from 'rxdb';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBWatchForChangesPlugin } from 'rxdb/plugins/watch-for-changes';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { Database, DatabaseCollections, DeletionState } from './database';
import { DB_COLLECTIONS } from './database.collections';
import { fixCollections } from './fix-collections.util';

addRxPlugin(http);
addRxPlugin(RxDBEncryptionPlugin);
addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBAttachmentsPlugin);
addRxPlugin(RxDBJsonDumpPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBWatchForChangesPlugin);
addRxPlugin(RxDBMigrationPlugin);

let DB_INSTANCE: Database;

const collections = DB_COLLECTIONS;

export interface DBAdapter {
    name: string;
    adapter: any;
    iosDatabaseLocation?: string;
}

// Add additional database adapters here. Make sure to use the structure defined
// as DBAdapter above for the entries. The name must match the official
// RxDB adapter names. See <https://rxdb.info/adapters.html> for more details.
export const DBAdapters = {
    idb: {
        name: 'idb',
        adapter: idb,
    },
};

async function createCollections(db: Database): Promise<void> {
    for (const collection of collections) {
        await db.collection(collection);
    }

    // When writing DB entries during the sync process, PouchDB's
    // bulkDocs is used directly for performance reasons.
    // The documents written there also contain an '_id' attribute
    // which is not part of the schema and also produces errors
    // when used directly with RxDB's insertion methods.
    //
    // One possible fix for this would be to force a full re-sync
    // at some point in the future.
    //
    // This '_id' introduces schema errors during migrations. To fix
    // this the migrations remove the '_id' field during the migration.
    // It is still kept by RxDB/PouchDB, though. What goes missing, is
    // the 'id' field, the actual primary key in the RxDB schema.
    // But it is missing, if PouchDB's allDocs are used to get
    // the elements (which is used at some points for performance reasons).
    // When RxDB's find is used, the id is still there.
    //
    // This is an ulgy fix for this: It adds the 'id' attribute back
    // for the entries which miss it, but only on the collections
    // which were just affected by the migration during the collection
    // creation above. It uses PouchDB's methods directly to bypass
    // the schema validation on purpose.
    await fixCollections(db);
}

async function createAttachmentCaches(db: Database): Promise<void> {
    const attachmentIssuesCache = await db.issues
        .findOne({ selector: { id: 'attachmentcache' } })
        .exec();
    if (!attachmentIssuesCache) {
        await db.issues.upsert({
            id: 'attachmentcache',
        });
    }
    const attachmentDiariesCache = await db.diaries
        .findOne({ selector: { id: 'attachmentcache' } })
        .exec();

    if (!attachmentDiariesCache) {
        await db.diaries.upsert({
            id: 'attachmentcache',
        });
    }
}

export async function create(
    remove = false,
    adapter: DBAdapter = DBAdapters.idb
): Promise<Database> {
    addRxPlugin(adapter.adapter);

    if (remove) {
        await removeRxDatabase('flink2go', adapter.name);

        // the above operation does not seem to be enough to completely
        // remove the database causing a createRxDatabase to fail
        // if this function is called with 'remove = true' more than once.
        // note: DB_INSTANCE is not set, when this function is called for
        //       the first time
        if (DB_INSTANCE) {
            await DB_INSTANCE.remove();
        }
    }

    const db = await createRxDatabase<DatabaseCollections>({
        name: 'flink2go',
        adapter: adapter.name,
        pouchSettings: {
            revs_limit: 1,
        },
        password: environment.dbPassword,
    });

    await createCollections(db);
    await createAttachmentCaches(db);

    return db;
}

export async function initDatabase(
    remove = false,
    adapter: DBAdapter = DBAdapters.idb
): Promise<any> {
    if (DB_INSTANCE && !remove) {
        console.error(
            'DB already exists (this message is currently normal and should occur only once)'
        );
        return;
    }

    DB_INSTANCE = await create(remove, adapter);
}

@Injectable({
    providedIn: 'root',
})
export class DatabaseService {
    private deleting = false;

    constructor() {}

    get db(): Database {
        return DB_INSTANCE;
    }

    get deletionInProgress(): boolean {
        return this.deleting;
    }

    async deleteDb(): Promise<DeletionState> {
        if (!DB_INSTANCE) {
            return DeletionState.NoDatabaseExists;
        }

        if (this.deleting) {
            return DeletionState.InProgress;
        }

        this.deleting = true;

        // clear collection documents
        const deletions: Promise<any>[] = [];
        for (const { name } of collections) {
            const collection = DB_INSTANCE[name];
            if (collection) {
                deletions.push(collection.remove());
            }
        }
        await Promise.all(deletions);

        await createCollections(DB_INSTANCE);
        await createAttachmentCaches(DB_INSTANCE);

        this.deleting = false;
        return DeletionState.Completed;
    }
}
