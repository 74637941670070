import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotifications from './notifications.reducer';
import { NotificationsState } from './notifications.reducer';

export const selectNotificationsState = createFeatureSelector<
    NotificationsState
>('notifications');

export const getAllNotifications = createSelector(
    selectNotificationsState,
    fromNotifications.selectAll
);

export const getUnreadNotifications = createSelector(
    getAllNotifications,
    notifications =>
        notifications.filter(notification => !notification.readDateTime)
);

export const getNotificationsLoaded = createSelector(
    selectNotificationsState,
    state => state.loaded
);

export const getNotificationById = (id: string) =>
    createSelector(selectNotificationsState, state => state.entities[id]);

export const getTotalNotifications = createSelector(
    getUnreadNotifications,
    notifications => notifications.length
);
