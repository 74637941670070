<form [formGroup]="form">
    <div class="sidenav-banner">
        <div fxLayout="row" fxLayoutAlign="end">
            <button mat-icon-button (click)="toggleSidenav.emit()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <h4 *ngIf="updateMode && !isTemplate">
            {{ 'PROJECT.EDIT_PROJECT' | translate }}
        </h4>
        <h4 *ngIf="!updateMode && !isTemplate">
            {{ 'PROJECT.CREATE_PROJECT' | translate }}
        </h4>
        <h4 *ngIf="updateMode && isTemplate">
            {{ 'PROJECT.EDIT_TEMPLATE' | translate }}
        </h4>
        <h4 *ngIf="!updateMode && isTemplate">
            {{ 'PROJECT.CREATE_TEMPLATE' | translate }}
        </h4>

        <div class="project-img">
            <img
                *ngIf="!currentProject?.photo; else withPhoto"
                src="assets/images/custom/project-avatar.svg"
            />

            <ng-template #withPhoto>
                <ng-container
                    *ngIf="
                        assetUrl + currentProject.photo
                            | secure
                            | async as backgroundImage
                    "
                >
                    <img
                        *ngIf="currentProject?.photo"
                        [src]="backgroundImage | safe: 'url'"
                    />
                </ng-container>
            </ng-template>

            <div
                *ngIf="isAdmin$ | async"
                (click)="openImgDialog.emit(currentProject)"
                class="img-overlay"
                fxLayout="row"
                fxLayoutAlign="end end"
                data-test="project-edit-change-image"
            >
                <button mat-mini-fab class="mr-16 mb-16">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="sidenav-body">
        <div class="input-group" *ngIf="!updateMode">
            <mat-form-field *ngIf="!updateMode">
                <mat-select
                    [placeholder]="'APP.SELECT_SNIPPET' | translate"
                    (valueChange)="changeTemplate.emit($event)"
                    data-test="create-project-template-select"
                >
                    <mat-option
                        data-test="create-project-template-select-option"
                        >{{ 'APP.NO_SNIPPET' | translate }}</mat-option
                    >
                    <mat-option
                        *ngFor="let template of templates"
                        [value]="template"
                    >
                        {{ template.title }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-group">
            <mat-form-field>
                <input
                    matInput
                    formControlName="title"
                    [placeholder]="'DATA.NAME' | translate"
                    required="true"
                    cdkFocusInitial
                    data-test="create-project-title"
                />
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    formControlName="startDate"
                    [matDatepicker]="startPicker"
                    [placeholder]="'PROJECT.LIST.FORM.BEGIN' | translate"
                    data-test="create-project-startdate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    formControlName="dueDate"
                    [matDatepicker]="duePicker"
                    [placeholder]="'PROJECT.LIST.FORM.END' | translate"
                    data-test="create-project-duedate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="duePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #duePicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="input-group">
            <mat-form-field>
                <input
                    matInput
                    formControlName="address"
                    [placeholder]="'PROJECT.LIST.FORM.STREET' | translate"
                    data-test="create-project-street"
                />
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    formControlName="postCode"
                    [placeholder]="'PROJECT.LIST.FORM.ZIP' | translate"
                    data-test="create-project-zipcode"
                />
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    formControlName="city"
                    [placeholder]="'DATA.CITY' | translate"
                    data-test="create-project-city"
                />
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    [placeholder]="'APP.COUNTRY' | translate"
                    formControlName="country"
                    data-test="create-project-country"
                />
            </mat-form-field>

            <asc-auto-selector
                #timezoneSelector
                formControlName="timeZoneId"
                [config]="timezoneAutoSelectorConfiguration"
                [items]="timezones"
                [label]="'APP.TIMEZONE' | translate"
                required
                data-test="create-project-timezone-selector"
            ></asc-auto-selector>
        </div>
    </div>

    <!--
        only the edit button can be reached with non-admin, because
        the new project fab is disabled for them
    -->
    <button
        *ngIf="updateMode && (isAdmin$ | async)"
        [disabled]="!form.valid"
        mat-fab
        class="fab-bottom-right"
        (click)="updateProject.emit()"
        data-test="save-project-submit-button"
        [matTooltip]="'APP.SAVE' | translate"
        matTooltipPosition="left"
        [matTooltipDisabled]="tooltipUtilService.matTooltipDisabled"
    >
        <mat-icon>save</mat-icon>
    </button>

    <button
        *ngIf="!updateMode"
        [disabled]="!form.valid"
        mat-fab
        class="fab-bottom-right"
        (click)="createProject.emit()"
        data-test="create-project-submit-button"
        [matTooltip]="'APP.SAVE' | translate"
        matTooltipPosition="left"
        [matTooltipDisabled]="tooltipUtilService.matTooltipDisabled"
    >
        <mat-icon>save</mat-icon>
    </button>
</form>
