import { loginConfig } from './../login.fuse-config';
import { TranslateService } from '@ngx-translate/core';
import { getLanguage } from 'app/store/core/core.selectors';
import { getIsLoggedIn } from 'app/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordViewModel } from './../../../core/rest-api/model/forgotPasswordViewModel';
import { ResetPasswordViewModel } from './../../../core/rest-api/model/resetPasswordViewModel';
import { AccountService } from './../../../core/rest-api/api/account.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'acc-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    isLoggedIn$: Observable<boolean> = this.store.pipe(select(getIsLoggedIn));

    passwordForm = this.formBuilder.group({
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
    });

    emailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    language$ = this.store.pipe(select(getLanguage));

    resetPassword: ResetPasswordViewModel;

    constructor(
        private accountService: AccountService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private snackbar: MatSnackBar,
        private translateService: TranslateService,
        private store: Store,
        private fuseConfigService: FuseConfigService
    ) {
        setTimeout(() => {
            this.fuseConfigService.setConfig(loginConfig);
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams) => {
            this.resetPassword = {
                email: queryParams.mail,
                code: decodeURIComponent(queryParams.code).split(' ').join('+'),
                password: null,
            };
        });

        this.isLoggedIn$.pipe(take(1)).subscribe((res) => {
            if (res) {
                this.router.navigateByUrl('/projects');
            }
        });
    }

    sendEmail() {
        const forgotPassword: ForgotPasswordViewModel = {
            email: this.emailForm.value.email,
        };

        const snackbarText = this.translateService.instant(
            'RESET_PASSWORD.E_MAIL_ENTRY_SUCCESS'
        );

        this.accountService
            .accountForgotpasswordPost(forgotPassword)
            .pipe(take(1))
            .subscribe((res) => {
                this.snackbar
                    .open(snackbarText + forgotPassword.email, '', {
                        duration: 2000,
                        panelClass: ['mat-toolbar', 'mat-success'],
                    })
                    .afterOpened()
                    .pipe(take(1))
                    .subscribe((res) => {
                        this.router.navigateByUrl('/login');
                    });
            });
    }

    confirmPasswordReset() {
        const snackbarTextNoMatch = this.translateService.instant(
            'RESET_PASSWORD.PASSWORDS_DO_NOT_MATCH'
        );
        const snackbarTextSuccess = this.translateService.instant(
            'RESET_PASSWORD.PASSWORD_RESET_SUCCESS'
        );
        this.resetPassword.password = this.passwordForm.value.newPassword;
        if (
            this.passwordForm.value.newPassword !==
            this.passwordForm.value.confirmPassword
        ) {
            this.snackbar.open(snackbarTextNoMatch, '', {
                duration: 2000,
                panelClass: ['mat-toolbar', 'mat-warn'],
            });
            return;
        }

        this.accountService
            .accountResetpasswordPost(this.resetPassword)
            .pipe(take(1))
            .subscribe({
                next: (res) =>
                    this.snackbar
                        .open(snackbarTextSuccess, '', {
                            duration: 2000,
                            panelClass: ['mat-toolbar', 'mat-success'],
                        })
                        .afterOpened()
                        .pipe(take(1))
                        .subscribe((res) => {
                            this.router.navigateByUrl('/login');
                        }),
                error: (res) => {
                    this.snackbar.open(
                        res.error.meta.error.allMessages[0],
                        '',
                        {
                            duration: 2000,
                            panelClass: ['mat-toolbar', 'mat-warn'],
                        }
                    );
                },
            });
    }

    ngOnDestroy() {}
}
