<h2 mat-dialog-title translate>DIALOG.USER_SETTINGS.TITLE</h2>
<div fxLayout="column" class="dialog-container" fxLayoutAlign="space-around">
    <mat-dialog-content class="mat-typography h-100-p">
        <div class="user-img h-120 w-120" (click)="imgDialog()">
            <img
                src="/assets/images/custom/avatar.svg"
                *ngIf="!form.get('photo').value; else withPhoto"
            />

            <ng-template #withPhoto>
                <ng-container
                    *ngIf="
                        assetUrl + form.get('photo').value
                            | secure
                            | async as backgroundImage
                    "
                >
                    <img
                        *ngIf="form.get('photo').value"
                        [src]="backgroundImage | safe: 'url'"
                    />
                </ng-container>
            </ng-template>

            <div class="img-overlay" fxLayout="row" fxLayoutAlign="end end">
                <button mat-mini-fab class="mr-16">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>

        <form fxLayout="column" [formGroup]="form">
            <mat-form-field>
                <input
                    matInput
                    [placeholder]="
                        'DIALOG.USER_SETTINGS.EMAIL_ADDRESS' | translate
                    "
                    formControlName="email"
                />
            </mat-form-field>
            <mat-form-field *ngIf="confirmEmail">
                <input
                    matInput
                    [placeholder]="
                        'DIALOG.USER_SETTINGS.EMAIL_ADDRESS_REPEAT' | translate
                    "
                    formControlName="confirmEmail"
                    required="true"
                />
            </mat-form-field>
            <ng-container *ngIf="!(isDemoMode$ | async)">
                <mat-form-field>
                    <input
                        type="password"
                        autocomplete="new-password"
                        matInput
                        [placeholder]="
                            'DIALOG.USER_SETTINGS.NEW_PASSWORD' | translate
                        "
                        formControlName="password"
                    />
                </mat-form-field>
                <mat-form-field *ngIf="confirmPassword">
                    <input
                        type="password"
                        autocomplete="new-password"
                        formControlName="confirmPassword"
                        matInput
                        [placeholder]="
                            'DIALOG.USER_SETTINGS.NEW_PASSWORD_REPEAT'
                                | translate
                        "
                    />
                </mat-form-field>

                <mat-form-field>
                    <input
                        autocomplete="firstname"
                        formControlName="firstname"
                        matInput
                        [placeholder]="'DATA.FIRST_NAME' | translate"
                    />
                </mat-form-field>

                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="name"
                        matInput
                        [placeholder]="'DATA.NAME' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="addressStreet"
                        matInput
                        [placeholder]="'DATA.STREET' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="addressPostCode"
                        matInput
                        [placeholder]="'DATA.ZIP' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="addressState"
                        matInput
                        [placeholder]="'DATA.STATE' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="addressCity"
                        matInput
                        [placeholder]="'DATA.CITY' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        type="tel"
                        formControlName="phone"
                        matInput
                        [placeholder]="'DATA.PHONE' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="mobilePhone"
                        matInput
                        [placeholder]="'DATA.MOBILE_PHONE' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="fax"
                        matInput
                        [placeholder]="'DATA.FAX' | translate"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        autocomplete="new-password"
                        formControlName="website"
                        matInput
                        [placeholder]="'DATA.WEBSITE' | translate"
                    />
                </mat-form-field>
            </ng-container>
            <div fxLayout="row" fxLayoutAlign="start center">
                <p class="m-0">{{ 'APP.LANGUAGE' | translate }}:</p>
                <acc-language-switcher
                    #languageSelector
                    [language]="language$ | async"
                    (languageChange)="languageChange($event)"
                ></acc-language-switcher>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>
            {{ 'APP.CANCEL' | translate }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="true"
            cdkFocusInitial
            [disabled]="!hasEntryChanges || !isFormValid"
            (click)="updateContact()"
        >
            {{ 'APP.SAVE' | translate }}
        </button>
    </mat-dialog-actions>
</div>
