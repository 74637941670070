// TODO - Implement type checking later
// import {locale as DE_locale} from './de';

// interface DeIsMissing444 {
//     data: {
//         CRAFTS: {
//             444: string
//         }
//     }
// }

export const locale /*: (typeof DE_locale) & DeIsMissing444 */ = {
    lang: 'en',
    data: {
        APP: {
            UPLOAD_CHANGES: 'Upload changes',
            SYNC_PROJECTS: 'Download Projects',
            MARK_FOR_SYNC: 'Select for syncronization',
            UNMARK_FOR_SYNC: 'Deselect for synchronization',
            PULL_TO_REFRESH: 'Pull down to Refresh',
            RELEASE_TO_REFRESH: 'Release to Refresh',
            REFRESHING: 'Refreshing',
            DELETE_OFFLINE_DATA: 'Offline-Daten löschen',
            SELECT_PROJECT: 'Select Project',
            EXTERNAL_CONTACTS: 'Transfer sync addresses',
            TEMPLATES: 'Templates',
            CSV_UPLOAD_SUCCESS: 'CSV uploaded',
            SHOW_TICKETS: 'Show Tickets',
            IMPORTANCE: 'Importance',
            HISTORY_NO_OFFLINE: 'The history is only available online',
            PLAN_MISSING: 'No plan found',
            NOTE: 'Note',
            INPUT_COMMENT: 'Input comment',
            COMMENT: 'Comment',
            CHOOSE_CRAFT_LOCATION: 'Select Craft and Location',
            CONFLICT: 'Conflict',
            CONFLICTS: 'Conflicts',
            SYNC: 'Enable Sync',
            NO_SYNC: 'Disable Sync',
            APPLY: 'Apply',
            DAYS: 'Days',
            FORCE_OFFLINE: 'Offline-Mode',
            SHOW: 'Show',
            ALL: 'All',
            RECOMMENDATIONS: 'Recommendations',
            RECOMMENDED: 'Recommended',
            SELECT_SNIPPET: 'Select Template',
            NO_SNIPPET: 'No Template',
            LOCATION_SELECTOR_LABEL: 'Location',
            BOILERPLATES: 'Boilerplates',
            ISSUES: 'Tickets',
            TITLE: 'Title',
            COPY: 'copy',
            ARCHIVE_VERB: 'Delete',
            IMPORT_UNTERMSTRICH: 'Add untermStrich contact',
            DELETE_TICKET_VERB: 'will be deleted',
            FORM_LETTER: 'Select Form Letter',
            FORM_LETTER_CONTENT: 'Content of Form Letter',
            PRINT: 'Print',
            PRINT_DISABLED_NO_SELECTION:
                'No entries are selected for printing.',
            PRINT_DISABLED_DIARY_BAD_SELECTION:
                'Only entries of type inspection can be printed. Please adjust your selection, so that it only contains inspection entries.',
            EXPORT: 'Export',
            NEXT: 'Next',
            DIARY_PRINT_BUTTON: {
                ENABLED: 'Print',
                DISABLED_NO_SELECTION: 'No entries are selected for printing.',
                DISABLED_BAD_SELECTION:
                    'Only entries of type "inspection" can be printed. Please adjust your selection, so that it only contains inspection entries.',
            },
            GERMAN: 'German',
            BRAZILIAN_PORTUGUESE: 'Brazilian Portuguese',
            SELECT_ISSUE: 'Select Ticket',
            SELECT_ISSUE_OR_FILTER_BY_PLAN:
                'Select ticket or select the plan overview in the filter menu',
            NO_ISSUES: 'No Tickets present',
            NO_PROJECTS: 'No Projects present',
            ENGLISH: 'English',
            HIGH: 'High',
            MEDIUM: 'Medium',
            LOW: 'Low',
            ADD: 'Add',
            SAVE: 'Save',
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            PREVIEW: 'Preview',
            DOWNLOAD: 'Download',
            SEARCH: 'Search',
            LOGOUT: 'Log out',
            LOGOUT_DIALOG: {
                TITLE: 'Do you really want to log out?',
                DEVICE_WARNING:
                    'The data on this device is not fully synchronized with the server. If you continue, this data is lost.',
            },
            EDIT: 'Edit',
            SETTINGS: 'Settings',
            CRAFT: 'Craft',
            CRAFTS: 'Crafts',
            ISSUE: 'Ticket',
            ROLE: 'Role',
            ROLES: 'Roles',
            COMPANY: 'Company',
            CONFIRM: 'Confirm',
            BACK: 'Back',
            ATTENDEES: 'Participants',
            COMPANIES: 'Companies',
            CATEGORY: 'Category',
            EDITING: 'Editing',
            FILTER: 'Filter',
            RESET: 'Reset',
            OPEN: 'Open',
            BROWSE: 'browse',
            ARCHIVE: 'Deleted',
            UPLOAD: 'Upload',
            PROFILE_SETTINGS: 'Edit Profile',
            COUNTRY: 'Country',
            LANGUAGE: 'Language',
            DATE: 'Date',
            TIME: 'Time',
            AS: 'As',
            AT: 'at',
            TEXT: 'Text',
            MANAGEMENT: 'Management',

            WEATHERENTRY: 'Weather Conditions',
            INSPECTIONENTRY: 'Inspection',
            APPROVALENTRY: 'Approval',
            NOTEENTRY: 'Notes',
            PROGRESSREPORTENTRY: 'Construction Status',
            ATTENDANCEENTRY: 'Attendees',

            PROJECT: 'Project',

            NO_ENTRIES: 'No entries present',
            SYNCED_ENTRIES: 'Synched',
            UNSYNCED_ENTRIES: 'To Sync',
            NO_REVISIONS: 'No Revisions present',

            BROWSE_IMG_TITLE: 'Upload a Picture',
            BROWSE_IMG: 'Drag and drop a picture or browse...',
            CREATE_ACCOUNT: 'Create Account',
            NOT_ACTIVE_ANYMORE: 'Not active anymore',
            PERSON: 'Person',
            PERSONS: 'Persons',
            TYPE: 'Type',
            TIMEZONE: 'Timezone',
            ADD_NEW_ITEM: 'Add new Item',
            SHOW_MORE: 'Show more',
            SHOW_LESS: 'Show less',
            SYNCING: 'Synchronizing Data',
            INPUT: 'Input',
            TABLE_NO_RECORDS_TO_SHOW: 'No records found',
            FORM_LETTER_DIALOG: {
                PREDEFINED_TEMPLATES: 'Predefined Templates',
                CUSTOM_TEMPLATES: 'Custom Templates',
                SUCCESS_CREATE_LETTER_TEMPLATE: 'Template has been saved',
                RTF_TEMPLATES: 'RTF Templates',
                ADD_ISSUE_DETAILS_TO_FORM_LETTER:
                    'Would you like to add the ticket details to the form letter?',
                WITH_ISSUE_DETAILS: 'With ticket details',
                WITHOUT_ISSUE_DETAILS: 'Without ticket details',
                ADD_SIGNATURE_TO_FORM_LETTER:
                    'Would you like to add a signature field to the form letter?',
                WITH_SIGNATURE: 'With signature',
                WITHOUT_SIGNATURE: 'Without signature',
                FORM_LETTER_READY_FOR_DOWNLOAD:
                    'The form letter is now ready for export.',
                SAVE_INPUT_AS_TEMPLATE:
                    'Would you like to save your entries as a new template?',
                CREATE_PDF: 'Create PDF',
            },
        },
        CRAFTS: {
            0: 'Safety equipment, construction site equipment',
            1: 'Scaffolding work',
            2: 'Earthworks',
            3: 'Landscaping works',
            4: 'Landscaping work - plants',
            5: 'Well construction and exploratory drilling',
            6: 'Special civil engineering works',
            7: 'Underground mining works',
            8: 'Water drainage works',
            9: 'Drainage channel works',
            10: 'Drainage and percolation works',
            11: 'Separator and small sewage treatment plants',
            12: 'Masonry work',
            13: 'Concrete work',
            14: 'Natural and concrete stone work',
            16: 'Carpentry',
            17: 'Steel construction',
            18: 'Waterproofing work',
            20: 'Roofing work',
            21: 'Roof waterproofing work',
            22: 'Plumbing work',
            23: 'Putz-Stuck-Wärmed.',
            24: 'Tiling work',
            25: 'Screed work',
            26: 'Windows, exterior doors',
            27: 'Carpentry work',
            28: 'Parquetry work',
            29: 'Fitting work',
            30: 'Roller shutter work',
            31: 'Metal construction work',
            32: 'Glazing work',
            33: 'Building cleaning work',
            34: 'Painting work',
            35: 'Corrosion protection',
            36: 'Floor covering works',
            37: 'Wallpapering',
            38: 'Facade',
            39: 'Dry construction',
            40: 'Heating-sanitary',
            41: 'Heat supply systems - pipes, fittings, heating surfaces',
            42: 'Gas and water installations - pipes, fittings',
            43: 'Pressure pipelines for gas, water and sewage',
            44: 'Sewage systems - pipes, drains, fittings',
            45: 'Gas, water and drainage installations - equipment, elements, prefabricated bathrooms',
            46: 'Gas, water and drainage installations - operating equipment',
            47: 'Fire protection',
            49: 'Fire extinguishers, fire fighting equipment',
            50: 'Lightning protection',
            51: 'Underground cable laying works',
            52: 'Medium voltage systems',
            53: 'Electric',
            54: 'Low-voltage installations - distribution systems and built-in devices',
            55: 'Spare power supply systems',
            57: 'Building systems engineering',
            58: 'Lights and lamps',
            59: 'Safety lighting systems',
            60: 'Electro-acoustic systems, intercom systems, paging systems',
            61: 'Communication networks',
            62: 'Communication equipment',
            63: 'Alarm systems',
            64: 'Access control, time recording systems',
            69: 'Lifts',
            70: 'Building automation',
            75: 'Ventilation and air conditioning - climate',
            78: 'Refrigeration systems for air conditioning systems',
            80: 'Roads, paths, squares',
            81: 'Concrete maintenance work',
            82: 'Combating wood protection',
            83: 'Renovation work on components containing pollutants',
            84: 'Demolition and dismantling',
            85: 'Pipe jacking works',
            87: 'Waste management, recovery and disposal',
            90: 'Construction logistics',
            91: 'Hourly wage work',
            96: 'Construction work on level crossings',
            97: 'Construction work on tracks and switches',
            98: 'Weather protection measures',

            491: 'Construction site equipment, inspection flaps',
            430: 'Ventilation and air conditioning',
            490: 'Detailed design',
            300: 'Shell of building',

            400: 'Electric',
            410: 'Gas and water installations - pipes, fittings',
            420: 'Heat supply systems',
            444: 'Low-voltage systems - cables / wires, installation systems, installation equipment',
            450: 'Plumbing, heating, air conditioning',
            900: 'Exterior view',
            910: 'Outdoor facilities',

            6000: 'Workshop',
            6010: 'Warehouse',
            6020: 'Office',
            6030: 'Building',
            6031: 'Kitchenette', // Brian's translation of "Teekueche"
            6032: 'Sanitary facilities',
            6040: 'Kitchen / Canteen',
            6050: 'Power plant',
            6060: 'Stations / Substations',
            6070: 'Water production',
            6080: 'Outdoor facilities',
            6100: 'Fire protection',
            6110: 'Hazardous substances',
            6120: 'First Aid',
            6140: 'Inspection', // Possible translation of 'Prüfungen',
            6150: 'Documentation',

            8000: 'Wind turbines',
            8001: 'Rotor blades',
            8002: 'Tower',
            8003: 'Gondola',

            8010: 'Wind Power Plant: General',
            8020: 'Wind Power Plant: Labelling',
            8030: 'Wind Power Plant: Documentation',
            8040: 'Wind Power Plant: Transformer station grid connection',
            8050: 'Wind Power Plant: Exterior entrance',
            8060: 'Wind Power Plant: Foundation construction',
            8070: 'Wind Power Plant: Tower construction',
            8080: 'Wind Power Plant: Electric Systems',
            8090: 'Wind Power Plant: Nacelle',
            8100: 'Wind Power Plant: Connecting Rod',
            8110: 'Wind Power Plant: Rotor Hub',
            8120: 'Wind Power Plant: Rotor Blade A',
            8121: 'Wind Power Plant: Rotor Blade B',
            8122: 'Wind Power Plant: Rotor Blade C',

            9020: 'Specialist planner',

            9996: 'Checklist',
            9997: 'Principal',
            9998: 'Not Assigned',
            9999: 'General',
        },
        SYNC: {
            TYPES: {
                ISSUE: 'Tickets',
                ATTACHMENT: 'Attachments',
                DIARY: 'Construction Log Entries',
                PROJECT: 'Projects',
            },
            ATTACHMENT_NOT_AVAILABLE_OFFLINE:
                'This attachment is currently not available.',
            ENTITY_ERRORS: 'Errors in changes',
            ERROR_DISCARD_CREATE: 'Discard changes',
            ERROR_DISCARD_UPDATE: 'Discard changes',
            ENTITY_ERROR_DETAILS: 'Error details',
            NO_ENTITY_ERROR_DETAILS: 'No error details available.',
            GO_TO_ERRORS: 'Show errors',
        },
        COUNTRIES: {
            GERMANY: 'Germany',
            UNITED_KINGDOM: 'United Kingdom',
        },
        FILEPOND: {
            LABEL: 'Add photos',
        },
        CAPACITOR: {
            CAMERA_LABELS: {
                HEADER: 'Add Photo',
                FROM_GALLERY: 'From Gallery',
                FROM_CAMERA: 'Take Picture',
            },
        },
        PAGINATOR: {
            FIRST_PAGE: 'First Page',
            ITEMS_PER_PAGE: 'Items per page',
            LAST_PAGE: 'Last page',
            NEXT_PAGE: 'Next Page',
            PREVIOUS_PAGE: 'Previous Page',
            OF: 'of',
        },
        DATA: {
            STREET: 'Street',
            ZIP: 'ZIP',
            CITY: 'City',
            STATE: 'State',
            PHONE: 'Phone',
            MOBILE_PHONE: 'Mobile Phone',
            CONTACT_PERSON: 'Contact Person',
            RESPONSIBLE: 'Responsible Party',
            CREATED_AT: 'Created At',
            NAME: 'Name',
            FIRST_NAME: 'First Name',
            ATTENDEES: 'Attendees',
            FAX: 'Fax',
            WEBSITE: 'Website',
            SUBSCRIBED_SERVICES: 'Subscribed Services',
            COMPLETION: 'Ending',
        },
        PROJECT: {
            CREATE_PROJECT: 'Create Project',
            EDIT_PROJECT: 'Edit Project',
            CREATE_TEMPLATE: 'Create Template',
            EDIT_TEMPLATE: 'Edit Template',
            PRINT_PROJECTS: 'Print a Project Report',
            COPY_PROJECTS: 'Copy Projects',
            BEGINNING: 'Beginning',
            END: 'End',
            ARCHIVE_PROJECT: 'Delete Project',
            ARCHIVE_PROJECT_QUESTION: 'Archive Project?',
            ARCHIVE_PROJECT_CONFIRM:
                'Are you sure you want to delete this project?',
            ARCHIVE_PROJECTS: 'Delete Projects',
            ARCHIVE_PROJECTS_QUESTION: 'Delete Projects?',
            ARCHIVE_PROJECTS_CONFIRM:
                'Are you sure you want to delete {{ length }} projects?',
            STATUS_DONE: 'Done',
            STATUS_OPEN: 'Open',
            STATUS_ACCEPTED: 'Accepted',
            STATUS_EXTENDED_DEADLINE: 'Extended Deadline',
            TICKETS: 'Tickets',
            TOTAL: 'Total',
            LIST: {
                WELCOME: 'Welcome',
                WELCOME_MOBILE: 'Welcome',
                YOUR_DUE_DATES: 'You have {{ length }} due dates',
                FORM: {
                    UPLOAD_PROJECT_IMAGE: 'Project Image Upload',
                    BROWSE_PROJECT_IMAGE:
                        'Drag & Drop Project Image or <u>Browse</u>',
                    PROJECT: 'Project',
                    BEGIN: 'Project Begin',
                    END: 'Project End',
                    STREET: 'Street + Street Number',
                    ZIP: 'ZIP Code',
                    COUNTRY: 'Country',
                    ADD_PROJECT_IMAGE: 'Add or browse project image',
                    BUILDING_OWNER: 'Building Owner',
                },
                TABLE: {
                    NAME: 'Project Name',
                    BEGIN: 'Begin',
                    END: 'End',
                    CREATED_AT: 'Created at',
                },
            },
            DETAIL: {
                CREATED: 'created',
                DEACTIVATE: 'Deactivate Project',
                ARCHIVE: 'Archive Project',
            },
        },
        ISSUES: {
            SELECT_PRIMARY_PHOTO: 'select primary photo',
            NO_PLAN: 'No plan available',
            CONFLICT_TITLE: 'Caution, ticket conflict detected!',
            CONFLICT_TEXT: 'Please choose, which ticket you want to persist.',
            CREATE_ISSUE: 'Create Ticket',
            PRINT_DIALOG_TITLE: 'Select Report',
            PRINT_DIALOG_TITLE_FORM: 'Fill in Report',
            SELECT_LETTER: 'Select a Report',
            CUSTOM_TEXT: 'Custom Text',
            INTERNAL_PERSON: 'Internal',
            EXTERNAL_PERSON: 'External',
            STATES: {
                Draft: 'Draft',
                Open: 'Open',
                Accepted: 'Accepted',
                Rejected: 'Rejected',
                DoneWithoutInspection: 'Done Without Inspection',
                ExtendedDeadline: 'Extended Deadline',
                FinalDeadline: 'Final Deadline',
                MailedExtendedDeadline: 'Mailed Extended Deadline',
                MailedFinalDeadline: 'Mailed Final Deadline',
                Done: 'Done',
                Failed: 'Failed',
            },
            LIST: {
                DUE_FROM: 'Due From',
                DUE_TO: 'Due To',
                STATUS: 'Status',
                SHOW_ON_PLAN: 'Show on plan',
                TOGGLE_LIST: 'Liste',
                TOGGLE_PLAN: 'Plan',
            },
            DETAIL: {
                CREATE_ISSUE: 'Title',
                DETAIL: 'Detail',
                PHOTOS: 'Photos',
                DOCUMENTS: 'Documents',
                HISTORY: 'History',
                FILES: 'Files',
                FORM: {
                    BOILERPLATES_UNAVAILABLE:
                        'Your selected craft has no boilerplates available. Add them in Project Settings.',
                    RAISE_DUE_DAYS: 'Raise due date',
                    ISSUE_DESC: {
                        BLOCK: 'Ticket Description (Text Boilerplates)',
                        FREE: 'Ticket Description (Free Text)',
                    },
                    DUE_DATE: 'Due date',
                    LOCATION: 'Location',
                    ADD_LOCATION: 'Add location',
                    EXTERNAL_RESPONSIBLE: 'External Responsible Party',
                    SEVERITY: 'Severity',
                    INTERNAL_RESPONSIBLE: 'Internal Responsible Party',
                    CREATED_BY: 'Created by',
                    ADD_PHOTO: 'Add Photos',
                    FLOOR_PLAN: 'Floor Plan',
                    DISABLE_BOILERPLATES: 'Activate Free Text Mode',
                    ENABLE_BOILERPLATES: 'Activate Boilerplates',
                },
                PREVIEW: 'Preview',
                DOWNLOAD: 'Download',
                DELETE_IMAGE: 'Delete Image',
            },
            PLAN_OVERVIEW: {
                PLAN_SELECTOR_PLACEHOLDER: 'Select plan',
                NO_PLAN_AVAILABLE:
                    'No plan can currently be shown. Please verify your filter settings and building configuration.',
            },
            TOOLBAR: {
                SELECT_ALL: 'Select all',
                SORT: 'Sort',
                FILTER: 'Filter',
                ARCHIVE: 'Deleted',
            },
            DIALOGS: {
                DISCARD_CHANGES: {
                    TITLE: 'Modifications not Saved',
                    QUESTION:
                        'The ticket has modifications not saved, do you want to discard the changes?',
                    CONFIRM_LABEL: 'Discard',
                },
                COMMENT_FOR_REJECTED: {
                    TITLE: 'Reject ticket',
                    REJECT: 'Reject',
                },
            },
            ERROR_DISCARD_CREATE: 'Discard issue',
            ERROR_DISCARD_UPDATE: 'Discard changes',
            PLAN_OF: 'Plan of',
            CUSTOM_ATTRIBUTES: {
                FROM: 'from',
                TO: 'to',
            },
            CHECKLIST: {
                TAB_TITLE: 'Checklist',
                ADD_CHECKLIST: 'Add checklist',
                ADD_CHECKLIST_DIALOG: {
                    TITLE: 'Add checklist',
                    SELECTOR_PLACEHOLDER: 'Checklist',
                    NO_CHECKLISTS_CONFIGURED:
                        'There are currently no checklists configured in this project. You can add new checklists in the settings of the current project.',
                    ADD_BUTTON: 'Add',
                    CANCEL_BUTTON: 'Cancel',
                },
                REMOVE_CHECKLIST: 'Remove checklist',
                NO_CHECKLIST_ADDED:
                    'There is no checklist configured. Use the "PLUS button" to add one.',
                NO_CHECKLIST_ADDED_AND_NO_RIGHTS:
                    'There is no checklist configured.',
            },
        },
        NOTIFICATIONS: {
            LAST_ACTIVITIES: 'Last Activities',
            NO_ACTIVITIES: 'No recent activities',
            ISSUE_CREATED: '{{user}} created a new ticket: {{ticket}}',
            ISSUE_UPDATED: '{{user}} updated a ticket: {{ticket}}',
            INSPECTION_ENTRY_FINISHED: '{{user}} has locked an inspection',
            MESSAGE_CREATED: '{{user}} created a new {{title}}',
            MESSAGE_UPDATE: '{{user}} updated {{title}}',
            MARK_ALL_AS_READ: 'Mark all as read',
        },

        ACTIVITIES: {
            FORM_LETTER: {
                TITLE: 'Report "{{formLetterTitle}}"',
                DESCRIPTION_RUNNING: 'The download will be ready shortly...',
                DESCRIPTION_SUCCESS: 'The download is available',
                DESCRIPTION_FAILED: 'The download creation failed',
            },
        },

        ATTENDEES: {
            ASSIGN_USERS: 'Assign Participants to Project',
            ASSIGN_TO_PROJECT: 'Assign to Project',
        },

        BUILDINGS: {
            TITLE: 'Buildings',
            ADD_PLANS: 'Add floor plans or',
            BROWSE_PLANS: 'Drag and drop a plan or browse...',
            DELETE_PLAN_TITLE: 'Delete Plan?',
            DELETE_PLAN_QUESTION:
                'Are you sure you want to delete the „{{ craftName }}“ plan?',
            ADD_LEVEL: 'Add a level',
            ADD_PLAN_DIALOG: 'Add a plan for {{ locationTitle }}',
            DELETE_LOCATION_TITLE: 'Delete Location?',
            DELETE_LOCATION_QUESTION:
                'Are you sure you want to delete the location?',
            ADD_LOCATION: 'Add a Location',
            ERRORS: {
                DELETE_LOCATION_ERROR_IS_USED:
                    'Location or sublocation is used.',
            },
        },

        USER: {
            LIST: {
                TABLE: {
                    TITLE: 'Title',
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    CONTACT_TITLE: 'Title',
                    CONTACT_TITLE_MALE: 'Mr',
                    CONTACT_TITLE_FEMALE: 'Ms',
                    CONTACT_TITLE_UNSPECIFIED: 'unspecified',
                    COMPANY: 'Company',
                    EMAIL: 'E-Mail',
                },
                ASSIGN_USER: 'Assign Participant',
                CREATE_USER: 'Create Participant',
                FORM: {
                    USER_DATA: 'Participant Data',
                    COMPANY_DATA: 'Company Data',
                    SAVE: 'SAVE',
                    CELL_PHONE: 'Cell Phone',
                    PHONE: 'Phone',
                    USERNAME: 'Username',
                    PASSWORD: 'Password',
                    PASSWORD_REPEAT: 'Password Repeat',
                    ADD_AVATAR: 'Add or browse for an avatar image',
                    CONTACT_TITLE: 'Title',
                    CONTACT_TITLES: {
                        0: 'Mr',
                        1: 'Ms',
                    },
                },
                DELETE: {
                    ATTENDEE: {
                        TITLE: 'Delete Participant?',
                        QUESTION:
                            'Are you sure you want to delete the participant?',
                    },
                    ATTENDEES: {
                        TITLE: 'Delete Participants?',
                        QUESTION:
                            'Are you sure you want to delete {{ attendeeCount }} participants?',
                    },
                    COMPANY: {
                        TITLE: 'Delete Company?',
                        QUESTION:
                            'Are you sure you want to delete the company?',
                    },
                    COMPANIES: {
                        TITLE: 'Delete Companies?',
                        QUESTION:
                            'Are you sure you want to delete {{ companyCount }} companies?',
                    },
                },
            },
        },

        SETTINGS: {
            PROJECT_SETTINGS: {
                TITLE: 'Project Settings',
                DEADLINE: 'Deadline',
                DEADLINE_DAYS: 'Deadline Days',
                DAY_SETTING_TITLE:
                    'Project with working days (VOB) or calendar days (BGB)',
                DAY_SETTING_PLACEHOLDER: 'Choose project days',
                SYNC_SETTING_TITLE:
                    'When should tickets be synchronized and send?',
                ON_INPUT: 'On input',
                END_OF_DAY: 'At the end of the day, not later than 8 PM',
            },
            FORM_LETTERS: {
                FORM_LETTERS: 'Form Letters',

                DELETE_FORM_LETTER_TITLE: 'Delete template?',
                DELETE_FORM_LETTER_QUESTION:
                    'Are you sure you want to delete the template "{{formLetterTitle}}"?',

                PREDEFINED_TEMPLATES: 'Predefined Templates',
                CUSTOM_TEMPLATES: 'Custom Templates',
                SHOW_IN_EXPORT_DIALOG: 'Show in export dialog',
                EDIT_TABLE_HEADER: 'Edit',

                PREVIEW_TOOLTIP: 'Preview',
                CREATE_CUSTOM_TEMPLATE_TOOLTIP: 'Create custom template',

                COPY_AND_EDIT_TEMPLATE_TOOLTIP: 'Copy and edit template',
                EDIT_TEMPLATE_TOOLTIP: 'Edit template',
                ARCHIVE_TEMPLATE_TOOLTIP: 'Archive template',

                PREVIOUS: 'Previous',
                NEXT: 'Next',
                SAVE: 'Save',

                NEW_CUSTOM_FROM_PREDEFINED_HELPER_TEXT:
                    'Are you often using re-occurring texts? Here, you can add these texts as standard values and create a custom template. If required, the values in this custom template can also be adjusted during PDF export.',

                SETTINGS_DIALOG_TITLE_PREFIXES: {
                    NEW_CUSTOM_TEMPLATE: 'New custom template:',
                    EDIT_CUSTOM_TEMPLATE: 'Edit custom template:',
                },

                DIALOG_FINAL_PAGE: {
                    TEMPLATE_TITLE_LABEL:
                        'Please enter the title for this custom template:',
                    TEMPLATE_TITLE_PLACEHOLDER: 'Title of custom template',
                },
            },
            ROLES: {
                FOUR_EYES: 'Four Eyes Principle',
                APPLY_FOUR_EYES: 'Apply Four Eyes Principle',
                INTERNAL_RESPONSIBLE: 'Internal Responsible Party',
                EXTERNAL_RESPONSIBLE: 'External Responsible Party',
                CHOOSE_ROLE: 'Choose Role',
                CHOOSE_COMPANY: 'Choose Company',
                ROLES_AND_RIGHTS: 'Roles & Rights',
                ARCHIVE_PROJECT_PARTNER_TITLE: 'Archive Project Partner?',
                ARCHIVE_PROJECT_PARTNER_QUESTION:
                    'Are you sure you want to archive the project partner?',
                ARCHIVE_PROJECT_PARTNERS_TITLE: 'Archive Project Partners?',
                ARCHIVE_PROJECT_PARTNERS_QUESTION:
                    'Are you sure you want to archive {{ count }} project partners?',
                ROLE_NORMAL: 'Normal',
                ROLE_CRAFTRESPONSIBLE: 'Craft Responsible',
                ROLE_PROJECTMANAGER: 'Project Manager',
                ROLE_ADMIN: 'Administrator',
                SELECT_CRAFT: 'Craft',
                SELECT_EXT_RESPONSIBLE: 'External Responsible Party',
                SELECT_INT_RESPONSIBLE: 'Internal Responsible Party',
                ROLE_SUPERADMIN: 'Super Admin',
            },
            TICKETS: {
                TICKET_SETTINGS: 'Additional data - Tickets',
                TITLE: 'Attribute',
                TYPE: 'Type',
                SELECT_TYPE: 'Select type',
                TYPE_DESCRIPTION: 'Add description',
                TICKET_SETTINGS_DIALOG: 'Edit Additional data - Tickets',
                DIALOG_ATTRIBUTENAME: 'Attribute name',
                DELETE_ATTRIBUTE_TITLE: 'Delete Custom Attribute?',
                DELETE_ATTRIBUTE_QUESTION:
                    'Are you sure you want to delete the custom attribute?',
            },
            CHECKLIST: {
                CHECKLIST_SETTINGS: 'Checklists',
                TITLE: 'Checklist',
                SETTINGS_DIALOG: 'Edit Checklist',
                DIALOG_CHECKLIST_NAME: 'Checklist Name',
                DIALOG_ITEM: 'Add Description',
                DIALOG_TYPE: 'Select Type',
                ADD_TYPE: 'Add additional type',
                DELETE_CHECKLIST_TITLE: 'Delete checklist?',
                DELETE_CHECKLIST_QUESTION:
                    'Are you sure you want to delete the checklist?',
            },
            TYPES: {
                Text: 'Text',
                Number: 'Number',
                Contact: 'Contact',
                Date: 'Date',
                Url: 'Url',
                Boolean: 'Yes/No',
                Label: 'Subheading',
            },
        },

        ADMIN_LOGO: {
            COMPANY_LOGO: 'Company Logo',
            NO_LOGO: 'No logo has been provided.',
            UPLOAD_LOGO: 'Upload Logo ',
            CHANGE_LOGO: 'Change Logo',
            NOTE_EVALUATE: 'Your logo will be used in report generation.',
            NOTE_SIZE_FIRST: {
                START: 'Upload a company logo with a sized of ',
                PIXELS: '{{x}}x{{y}} pixels.',
                END: '',
            },
            NOTE_SIZE_CHANGE: {
                START: 'Please note: Your logo needs a size of ',
                PIXELS: '{{x}}x{{y}} pixels.',
            },

            DELETE_LOGO: {
                TITLE: 'Delete Company Logo',
                QUESTION: 'Are you sure you want to delete the logo?',
            },

            RESIZE_TITLE: 'Resize image',
            SCALED: 'Scaled',
            SCALE: 'Scale',
            SQUISHED: 'Squished',
            SQUISH: 'Squish',
        },
        GLOBAL_SETTINGS: {
            ADDRESS_BOOK: {
                TITLE: 'Address Book',
                ADD_ATTENDEE: 'Add contact',
                ADD_ONE_ATTENDEE: 'Add a single contact',
                ADD_UNTERMSTRICH_CONTACT: 'Add untermStrich contact',
                ADD_COMPANY: 'Add company',
                ELEMENTS_SELECTED: 'elements selected',
                PRINT_ATTENDEES: 'print participants',
                ARCHIVE_ATTENDEES: 'archive participants',
                ARCHIVE_ATTENDEE: 'archive participant',
                EDIT_ATTENDEE: 'edit participant',
                DELETE_ATTENDEES: 'Delete participants?',
                PRINT_COMPANIES: 'print companies',
                ARCHIVE_COMPANIES: 'archive companies',
                ARCHIVE_COMPANY: 'archive company',
                EDIT_COMPANY: 'edit company',

                CONTACT_IMPORT: {
                    OPEN_DIALOG_BUTTON: 'Import contacts',
                    NOT_AVAILABLE:
                        'Importing contacts is currently not possible. Please create a company to be able to import contacts.',

                    COMPANY_CSV_STEP: 'Select company and file',
                    COMPANY_HINT:
                        'Please select the company which should be used for the imported contacts:',
                    CSV_HINT:
                        'Please select the CSV file exported from Microsoft Outlook on Windows, which contains the contacts:',
                    CSV_FILE_POND_LABEL:
                        'Please click here to select a CSV file.',
                    CSV_FILE_POND_LABEL_LOADING: 'Loading...',
                    CSV_DOWNLOAD_EXAMPLE:
                        'Click here to download a template for the CSV file.',
                    TITLE_HINT:
                        'The title of the contact is automatically determined based on the first name. Please check this setting after the import.',
                    CANCEL: 'Cancel',
                    IMPORT: 'Import',

                    IMPORT_RESULT_STEP: 'Import results',
                    IMPORT_FAILED:
                        'An error occurred during import. Please check the format of your CSV file.',
                    CONTACTS_IMPORTED: {
                        '=0': 'No contacts were imported.',
                        '=1': 'One contact was imported.',
                        other: '# contacts were imported.',
                    },
                    PROBLEMS_OCCURRED: {
                        '=1': 'One problem occurred during import.',
                        other: '# problems occurred during import.',
                    },
                    PLEASE_CHECK_LINES:
                        'Please check the following notes regarding the lines of your Outlook CSV-file.',
                    CLOSE: 'Close',

                    ERRORS: {
                        SKIPPED:
                            'A contact already exists with this email address.',
                        CSV_FORMAT_INVALID: 'This line has an invalid format.',
                        FIRSTNAME: 'First name',
                        NAME: 'Last name',
                        EMAIL: 'Email',
                        NO_VALUE: 'Field "{{ field }}" has no value.',
                        INVALID_FORMAT:
                            'Field "{{ field }}" has an invalid format.',
                    },
                },
            },
            EXTERNAL_CONTACTS: {
                TABLE_TITLE: 'untermStrich Contacts',
                SEARCH_BY: 'Search by',
                SEARCH_TERM: 'Search',
                SEARCH_TYPES: {
                    EMAIL: 'Email',
                    NAME: 'Name',
                    FIRSTNAME: 'First Name',
                },
                NO_TABLE_RECORDS: 'Please use search filters to find external contacts',
            },
        },
        ERRORS: {
            REQUIRED: {
                EMAIL: 'Email is required',
                PASSWORD: 'Password is required',
            },
            VALIDATE: {
                EMAIL: 'Email is not valid',
            },
        },

        BANNER: {
            PROJECT_BEGIN: 'Project Begin',
            PROJECT_END: 'Project End',
            OPEN: 'Open',
            ACCEPTED: 'Accepted',
            EXTENDED_DEADLINE: 'Extended Deadline',
            DONE: 'Done',
        },

        DIALOG: {
            USER_SETTINGS: {
                TITLE: 'User Settings',
                EMAIL_ADDRESS: 'E-Mail Address',
                EMAIL_ADDRESS_REPEAT: 'Repeat your new E-Mail Address',
                NEW_PASSWORD: 'New Password',
                NEW_PASSWORD_REPEAT: 'Repeat your new Password',
                TWO_FACTOR_AUTH: 'Enable 2-Factor Authentication',
            },
            PLAN_PREVIEW: {
                SAVE: 'Save changes?',
                DISCARD: 'Discard changes?',
                DISCARD_MESSGAE:
                    'Changes has been made on the current version. Do you want to discard them?',
                DISCARD_BUTTON: 'Discard changes',
                SAVEWITHOUTSAVING: 'Close without saving?',
            },
        },

        DIARY: {
            SELECT_ENTRY: 'Select an entry',
            ARCHIVE_ENTRY: 'Archive entry',
            ADD_ENTRY: 'Add entry',
            NOW: 'now',
            ONE_MINUTE_AGO: '1 minute ago',
            MINUTES_AGO: '{{ minutes }} minutes ago',
            ONE_HOUR_AGO: '1 hour ago',
            HOURS_AGO: '{{ hours }} hours ago',
            ARCHIVE_ENTRY_TITLE: 'Archive entry?',
            ARCHIVE_ENTRIES_TITLE: 'Archive entries?',
            ARCHIVE_ENTRY_QUESTION:
                'Are you sure you want to archive this entry?',
            ARCHIVE_ENTRIES_QUESTION:
                'Are you sure you want to archive {{ count }} entries?',
            DATE_FROM: 'Date from',
            DATE_TILL: 'Date till',
            NUMBER_OF_ENTRIES: 'Entries',
            ENTRIES_NOT_FOUND: 'No entries found',
            CONTACT: 'Contact',

            APPROVAL: {
                SERVICE: 'Service',
                APPROVED_BY: 'Approved by',
                APPROVAL_DATE: 'Approval date',
                COMMENTS: 'Comments',
            },

            ATTENDANCE: {
                ATTENDENT: 'Attendent',
                ABSENT: 'Absent',
                NUMBER_OF_ATTENDEES: 'Number of attendees',
                WORKING_TIME_FROM: 'Time from',
                TILL: 'till',
                PERSONS: 'Persons',
            },

            COMMENTS: {
                TITLE: 'Notes',
                COMMENT: 'Note',
            },

            PROGRESS: {
                TITLE: 'Construction Status',
                PROGRESS_TO: 'Finished:',
                COMPLETED: '',
                DESCRIPTION: 'Description',
            },

            WEATHER: {
                TITLE: 'Weather Conditions',
                MEASURING_TIME: 'Measuring time',
                RAINFALL: 'Rain',
                WIND: 'Wind',
                TEMPERATURE: 'Temperature',

                CALM: 'Calm',
                LIGHTBREEZE: 'Light breeze',
                MODERATEGALE: 'Moderate gale',
                HURRICANE: 'Hurricane',

                CLEAR: 'Clear sky',
                PARTLYCLOUDY: 'Partly cloudy',
                CLOUDY: 'Cloudy',
                LIGHTRAIN: 'Light rain',
                RAIN: 'Rain',
                HEAVYRAIN: 'Heavy rain',
                THUNDERSTORMS: 'Thunderstorms',
                SLEET: 'Sleet',
                SNOW: 'Snow',
                HEAVYSNOW: 'Heavy snow',
                FOG: 'Fog',
                BLIZZARD: 'Blizzard',
            },

            INSPECTION: {
                OPEN: 'open',
                LOCKED: 'locked',
                INSPECTION_DATA: 'Inspection Data',
                LOCK_TEXT:
                    'Lock the inspection once you have assigned all tickets.',
                LOCK_INSPECTION: 'Lock Inspection',
                INSPECTION_LOCKED: 'Inspection Locked',
                LOCK_INSPECTION_QUESTION:
                    'Are you sure you want to lock the inspection?',
                UNLOCK_INSPECTION: 'Unlock Inspection',
                UNLOCK_INSPECTION_QUESTION:
                    'Are you sure, you want to unlock this inspection? It might have already been sent to Attendees/Craft responsible',
                RECOMMENDATIONS: 'Recommendations',
            },
            ACTIONS: {
                APPROVALENTRY: '', // 1. Approval
                INSPECTIONENTRY: 'Inspection',
                PROGRESSREPORTENTRY: '', // 3. Construction Status
                NOTEENTRY: 'Notes',
                ATTENDANCEENTRY: 'Participants',
                WEATHERENTRY: '', // 6. Weather Conditions
            },
            CONFLICTS: {
                VIEWJOURNALCONFLICTS: 'Show Journal conflicts',
                ACCEPT: 'Accept',
                OPEN: 'Open',
                NO_ENTRIES: 'No Entries.',
                OFFLINE_MODE: 'Offline Mode',
                DOWNLOAD_PROJECTS: 'Download Projects',
                UPLOAD_CHANGES: 'Upload changes',
                DELETE_OFFLINE_DATA: 'Delete Offline Data',
                CONFLICTS: 'Conflicts',
                SYNCHRONIZED_ENTRIES: ' Completed Entries',
                PENDING_ENTRIES: 'Pending Entries',
                JOURNALCONFLICTS: 'Journal Conflicts',
            },
            ERROR_DISCARD_CREATE: 'Discard journal entry',
            ERROR_DISCARD_UPDATE: 'Discard changes',
        },
        TIME: {
            SECONDS: {
                SINGULAR: {
                    TEXT: 'Second',
                    FUTURE: 'in *** second',
                    PAST: '*** second ago',
                    PRESENT: '*** second',
                },
                PLURAL: {
                    TEXT: 'Seconds',
                    FUTURE: 'in *** seconds',
                    PAST: '*** seconds ago',
                    PRESENT: '*** seconds',
                },
            },
            MINUTES: {
                SINGULAR: {
                    TEXT: 'Minute',
                    FUTURE: 'in *** minute',
                    PAST: '*** minute ago',
                    PRESENT: '*** minute',
                },
                PLURAL: {
                    TEXT: 'Minutes',
                    FUTURE: 'in *** minutes',
                    PAST: '*** minutes ago',
                    PRESENT: '*** minutes',
                },
            },
            HOURS: {
                SINGULAR: {
                    TEXT: 'Hour',
                    FUTURE: 'in *** hour',
                    PAST: '*** hour ago',
                    PRESENT: '*** hour',
                },
                PLURAL: {
                    TEXT: 'Hours',
                    FUTURE: 'in *** hours',
                    PAST: '*** hours ago',
                    PRESENT: '*** hours',
                },
            },
            DAYS: {
                SINGULAR: {
                    TEXT: 'Day',
                    FUTURE: 'in *** day',
                    PAST: '*** day ago',
                    PRESENT: '*** day',
                },
                PLURAL: {
                    TEXT: 'Days',
                    FUTURE: 'in *** days',
                    PAST: '*** days ago',
                    PRESENT: '*** days',
                },
            },
            MONTHS: {
                SINGULAR: {
                    TEXT: 'Month',
                    FUTURE: 'in *** month',
                    PAST: '*** month ago',
                    PRESENT: '*** month',
                },
                PLURAL: {
                    TEXT: 'Months',
                    FUTURE: 'in *** months',
                    PAST: '*** months ago',
                    PRESENT: '*** months',
                },
            },
            YEARS: {
                SINGULAR: {
                    TEXT: 'Year',
                    FUTURE: 'in *** year',
                    PAST: '*** year ago',
                    PRESENT: '*** year',
                },
                PLURAL: {
                    TEXT: 'Years',
                    FUTURE: 'in *** year',
                    PAST: '*** year ago',
                    PRESENT: '*** year',
                },
            },
        },
        GLOBAL_BOILERPLATE: 'Global Boilerplate',
        BOILERPLATE: {
            TITLE: 'Boilerplates',
            NO_CRAFT_SELECTED: 'No craft selected',
            DELETE: {
                TITLE: 'Delete boilerplate "{{ boilerplateTitle }}"?',
                CONFIRMATION:
                    'Are you sure you want to delete the boilerplate?',
                MESSAGES: {
                    SUCCESS: 'Boilerplate Deleted Successfully',
                    ERROR: 'Delete Boilerplate Failed',
                },
            },
            CREATE: {
                MESSAGES: {
                    SUCCESS: 'Boilerplate Created Successfully',
                    ERROR: 'Create Boilerplate Failed',
                },
            },
            UPDATE: {
                MESSAGES: {
                    SUCCESS: 'Boilerplate Updated Successfully',
                    ERROR: 'Update Boilerplate Failed',
                },
            },
            IMPORT_FROM_CSV: {
                BUTTON: 'Import from CSV file',
                MESSAGES: {
                    SUCCESS: 'Boilerplates imported successfully',
                    ERROR: 'Import Boilerplates Failed',
                },
            },
            LOAD_MESSAGES: {
                ERROR: 'Load Boilerplates Failed',
            },
        },
        DEFAULT_ERROR_MESSAGES: {
            AUTH: {
                LOGIN: 'An error occurred on Login!',
                REGISTER: 'An error occurred while creating the account!',
                REGISTER_COMPLETE:
                    'An error occurred while creating the contact account!',
                UPDATE_PASSWORD:
                    'An error occurred while updating the password!',
            },
            SYNC: {
                STORE_COMMAND: 'An error occurred while storing a command!',
                DELETE_COMMAND: 'An error occurred while deleting a command!',
                DOWNLOAD_ATTACHMENTS:
                    'An error occured while downloading one or more files!',
            },
            PROJECTS: {
                LOAD: 'An error occurred while loading the projects!',
                CREATE: 'An error occurred while creating the project!',
                UPDATE: 'An error occurred while updating the project!',
                UPDATE_MANY: 'An error occurred while updating the projects!',
            },
            DIARY: {
                LOAD:
                    'An error occurred while loading the construction log entries!',
                CREATE:
                    'An error occurred while creating the construction log entry!',
                UPDATE:
                    'An error occurred while updating the construction log entry!',
                UPDATE_MANY:
                    'An error occurred while updating the construction log entries!',
            },
            CRAFTS: {
                LOAD: 'An error occurred while loading the crafts!',
            },
            ISSUES: {
                LOAD: 'An error occurred while loading the tickets!',
                CREATE: 'An error occurred while creating the ticket!',
                UPDATE: 'An error occurred while updating the ticket!',
                STORE_ATTACHMENT:
                    'An error occured while processing the photo or file!',
                UPDATE_ISSUE_ID_OF_COMMANDS_IN_DB:
                    'An occured while saving changes for offline functionality. Please go online to save the changes.',
            },
            PLAN_OVERVIEW: {
                LOAD_PLAN_ERROR:
                    'An error occurred while loading the plan overview!',
            },
            LETTERS: {
                LOAD: 'An error occurred while loading the letters!',
                // Note: Currently, it's intentional to use the same message
                // for both keys. This allows them to change independently.
                ERROR_CREATE_LETTER_TEMPLATE: 'Template could not be saved!',
                ERROR_UPDATE_LETTER_TEMPLATE: 'Template could not be saved!',
                ERROR_DELETE_LETTER_TEMPLATE: 'Template could not be deleted!',
            },
            CONTACTS: {
                LOAD: 'An error occurred while loading the contacts!',
                CREATE: 'An error occurred while creating the contact!',
                UPDATE: 'An error occurred while updating the contact!',
                DELETE: 'An error occurred while deleting the contact!',
                IMPORT: 'An error occurred while importing contacts!',
            },
            SETTINGS: {
                LOAD_PROJECT_CRAFT_TO_PLAN:
                    'An error occurred while loading the crafts to plan!',
                PROJECT_ROLES: {
                    LOAD: 'An error occurred while loading the roles!',
                    CREATE: 'An error occurred while creating the role!',
                    UPDATE: 'An error occurred while updating the role!',
                    DELETE: 'An error occurred while deleting the role!',
                },
                LOAD_PROJECT_CRAFTS:
                    'An error occurred while loading the project crafts',
                LOAD_PROJECT_CONTACT:
                    'An error occurred while loading the contacts of the project!',
                PROJECT_PARTNERS: {
                    LOAD: 'An error occurred while loading the partners!',
                    CREATE: 'An error occurred while creating the partner!',
                    UPDATE: 'An error occurred while updating the partner!',
                    DELETE: 'An error occurred while deleting the partner!',
                },
                LOAD_LANGUAGES:
                    'An error occurred while loading the languages!',
                LOAD_TIMEZONES:
                    'An error occurred while loading the timezones!',
            },
            LOCATIONS: {
                LOAD: 'An error occurred while loading the locations!',
                CREATE: 'An error occurred while creating the location!',
                UPDATE: 'An error occurred while updating the location!',
                DELETE: 'An error occurred while deleting the location!',
            },
            BOILERPLATES: {
                LOAD: 'An error occurred while loading the boilerplates!',
                CREATE: 'An error occurred while creating the boilerplate!',
                UPDATE: 'An error occurred while updating the boilerplate!',
                DELETE: 'An error occurred while deleting the boilerplate!',
            },
            NOTIFICATIONS: {
                LOAD: 'An error occurred while loading the notifications!',
                MARK_AS_READ: 'Marking the notification as read failed!',
                MARK_ALL_AS_READ:
                    'Marking all the notifications as read failed!',
            },
        },

        RESET_PASSWORD: {
            RESET_PASSWORD_TEXT:
                'Please enter your E-Mail Address to reset your password',
            SEND_MAIL: 'Send Mail',
            RESET_PASSWORD: 'Reset password',
            RESET_PASSWORD_CONFIRM:
                'Please enter a new password for your account.',
            RESET_PASSWORD_BUTTON: 'Reset password',
            NEW_PASSWORD: 'New Password',
            E_MAIL_ENTRY_SUCCESS: 'E-Mail sent to ',
            PASSWORD_RESET_SUCCESS: 'Password successfully changed',
            PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
        },
        GENERIC_BUTTONS: {
            OK: 'OK',
        },
        GENERIC_LABELS: {
            YES: 'Yes',
            NO: 'No',
        },
        DEMO_MODE: {
            POPUP: {
                HEADER_START: 'Welcome to your',
                HEADER_END: ' demonstration!',
                HINT: 'Please note the following:',
                NO_CONFIDENTIAL_INFO:
                    'Please do not enter confidential information.',
                SAVING_IS_DISABLED: 'Saving changes is not possible.',

                CTA_GET_HELP: 'Get Help',

                CONFIRM: 'I understand',
            },
            SAVE_DIALOG: {
                TITLE: 'Demo',
                TEXT:
                    'Saving changes is not possible in this demo. Please contact us for a personalised demo.',
                CTA: 'Request personal demo',
                CONFIRM: 'I understand',
            },
        },
    },
};
