import { User } from 'app/core/auth/models/user';
import { Project, CustomAttribute, Issue } from 'app/core/rest-api';
import { environment } from 'environments/environment';

export function createCustomAttributesFromProject(
    project?: Project
): CustomAttribute[] {
    return project?.customAttributeTemplates?.map(
        (template): CustomAttribute => {
            return {
                template: {
                    // manual copying to prevent POSTing all the custom attribute
                    // metadata
                    id: template.id,
                    title: template.title,
                    type: template.type,
                },
                value: null,
            };
        }
    );
}

const allowedStates = [
    Issue.StateEnum.Accepted,
    Issue.StateEnum.Rejected,
    Issue.StateEnum.DoneWithoutInspection,
];

export function issueStatesAllowedForUser(
    issue: Issue | null,
    props: {
        maxRole: number;
        user: User;
    }
): Issue.StateEnum[] | typeof Issue.StateEnum {
    if (!issue) {
        return [];
    }

    const atLeastProjectManager =
        props.maxRole >= environment.roleNumbers.PROJECT_MANAGER;
    const isCraftResponsible =
        props.maxRole === environment.roleNumbers.CRAFT_RESPONSIBLE;

    // note: the internally / externally responsible ids are contact ids
    const contactId = props.user.contactId;

    const isInternallyResponsible =
        isCraftResponsible && issue.responsibleInternalId === contactId;

    if (atLeastProjectManager || isInternallyResponsible) {
        return Issue.StateEnum;
    }

    const isExternallyResponsible =
        isCraftResponsible && issue.responsibleExternalId === contactId;
    const issueIsOpen = issue.state === Issue.StateEnum.Open;

    if (isExternallyResponsible && issueIsOpen) {
        return [...allowedStates, issue.state];
    } else if (isExternallyResponsible && allowedStates.includes(issue.state)) {
        return [Issue.StateEnum.Open, issue.state];
    }

    return [issue.state];
}
