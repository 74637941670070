import { Injectable } from '@angular/core';

import { Issue } from 'app/core/rest-api';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { DiaryDbService } from 'app/db/diary/diary.db.service';
import { IssueDbService } from 'app/db/issues/issue.db.service';
import { DiaryCommand } from 'app/main/diary/models/diary-command';
import { IssueCommand } from 'app/main/issues/models/issue-command';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import { IssuesStoreUtilsService } from 'app/store/issues/issues-store-utils.service';

export abstract class CommandAttachmentIdsService {
    public abstract obtainAttachmentIds(): Promise<Set<string>>;
}

@Injectable()
export class DatabaseCommandAttachmentIdsService extends CommandAttachmentIdsService {
    constructor(
        private commands: CommandDbService<any>,
        private diaries: DiaryDbService,
        private issues: IssueDbService
    ) {
        super();
    }

    private async getIssueCommands(): Promise<IssueCommand[]> {
        const issueCommands = await this.commands.getCommandsByEntityType(
            'issue'
        );
        return issueCommands as IssueCommand[];
    }

    private async getPlanCommandsGroupedByIssueId(): Promise<
        Map<string, PlanCommand[]>
    > {
        const planCommands = await this.commands.getCommandsByEntityType(
            'plan'
        );

        const planCommandsGroupedByIssueId = new Map<string, PlanCommand[]>();
        for (const planCommand of planCommands) {
            const issueId = planCommand.issueId;
            if (planCommandsGroupedByIssueId.has(issueId)) {
                planCommandsGroupedByIssueId
                    .get(issueId)
                    .push(planCommand as PlanCommand);
            } else {
                planCommandsGroupedByIssueId.set(issueId, [
                    planCommand as PlanCommand,
                ]);
            }
        }

        return planCommandsGroupedByIssueId;
    }

    private async obtainFromIssueCommands(
        usedAttachmentIds: Set<string>
    ): Promise<void> {
        const issueCommands = await this.getIssueCommands();
        const planCommandsGroupedByIssueId = await this.getPlanCommandsGroupedByIssueId();

        for (const issueCommand of issueCommands) {
            const assocciatedPlanCommands = planCommandsGroupedByIssueId.get(
                issueCommand.entityId
            );
            planCommandsGroupedByIssueId.delete(issueCommand.entityId);

            const combinedCommandChanges = this.combineCommandChanges(
                assocciatedPlanCommands,
                issueCommand
            );

            this.addAttachmentIdsFromIssueCommand(
                combinedCommandChanges,
                usedAttachmentIds
            );
        }

        // pure plan commands for which there is no associated issue command
        for (const [_issueId, planCommands] of planCommandsGroupedByIssueId) {
            const issue = this.combineCommandChanges(
                planCommands,
                new IssueCommand({}, {})
            );

            this.addAttachmentIdsFromIssueCommand(issue, usedAttachmentIds);
        }
    }

    private addAttachmentIdsFromIssueCommand(
        combinedCommandChanges: Partial<Issue>,
        usedAttachmentIds: Set<string>
    ): void {
        const attachmentIds = this.issues.getAttachmentIdsFromApiItem(
            combinedCommandChanges
        );
        attachmentIds.forEach((id) => usedAttachmentIds.add(id));
    }

    private combineCommandChanges(
        assocciatedPlanCommands: PlanCommand[],
        issueCommand: IssueCommand
    ): Partial<Issue> {
        const markedPlan = IssuesStoreUtilsService.mergePlanCommandChanges(
            assocciatedPlanCommands ? assocciatedPlanCommands : [],
            issueCommand.changes.markedPlan
        );

        const combinedCommandChanges: Partial<Issue> = {
            ...issueCommand.changes,
            markedPlan,
        };
        return combinedCommandChanges;
    }

    private async obtainFromDiaryCommands(
        usedAttachmentIds: Set<string>
    ): Promise<void> {
        const diaryCommands = (await this.commands.getCommandsByEntityType(
            'diary'
        )) as DiaryCommand[];

        for (const diaryCommand of diaryCommands) {
            const attachmentIds = this.diaries.getAttachmentIdsFromApiItem(
                diaryCommand.changes
            );
            attachmentIds.forEach((id) => usedAttachmentIds.add(id));
        }
    }

    public async obtainAttachmentIds(): Promise<Set<string>> {
        const usedAttachmentIds = new Set<string>();
        await this.obtainFromIssueCommands(usedAttachmentIds);
        await this.obtainFromDiaryCommands(usedAttachmentIds);
        return usedAttachmentIds;
    }
}
