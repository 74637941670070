/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { BoilerPlate } from '../model/models';
import { Location } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ResponseModelBoilerPlate } from '../model/models';
import { ResponseModelListAccessRights } from '../model/models';
import { ResponseModelListBoilerPlate } from '../model/models';
import { ResponseModelListGuid } from '../model/models';
import { ResponseModelListInt32 } from '../model/models';
import { ResponseModelListLocation } from '../model/models';
import { ResponseModelListRTFTemplate } from '../model/models';
import { ResponseModelLocation } from '../model/models';
import { ResponseModelString } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class ProjectSettingsService {
    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            elem,
                            key
                        ))
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(
                        key,
                        (value as Date).toISOString().substr(0, 10)
                    );
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k
                        ))
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsAuthorizationGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListAccessRights>;
    public projectsByProjectIdSettingsAuthorizationGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListAccessRights>>;
    public projectsByProjectIdSettingsAuthorizationGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListAccessRights>>;
    public projectsByProjectIdSettingsAuthorizationGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsAuthorizationGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListAccessRights>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/authorization`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param id
     * @param deleteRecursively
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsBoilerplateByIdDelete(
        projectId: string,
        id: string,
        deleteRecursively?: boolean,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelBoilerPlate>;
    public projectsByProjectIdSettingsBoilerplateByIdDelete(
        projectId: string,
        id: string,
        deleteRecursively?: boolean,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplateByIdDelete(
        projectId: string,
        id: string,
        deleteRecursively?: boolean,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplateByIdDelete(
        projectId: string,
        id: string,
        deleteRecursively?: boolean,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        // if (projectId === null || projectId === undefined) {
        //     throw new Error(
        //         'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsBoilerplateByIdDelete.'
        //     );
        // }
        if (id === null || id === undefined) {
            throw new Error(
                'Required parameter id was null or undefined when calling projectsByProjectIdSettingsBoilerplateByIdDelete.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (deleteRecursively !== undefined && deleteRecursively !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>deleteRecursively,
                'deleteRecursively'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<ResponseModelBoilerPlate>(
            `${this.configuration.basePath}/boilerplates/${encodeURIComponent(
                String(id)
            )}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsBoilerplateGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListBoilerPlate>;
    public projectsByProjectIdSettingsBoilerplateGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplateGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplateGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsBoilerplateGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListBoilerPlate>(
            `${this.configuration.basePath}/boilerplates/${encodeURIComponent(
                String(projectId)
            )}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param boilerPlate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsBoilerplatePatch(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelBoilerPlate>;
    public projectsByProjectIdSettingsBoilerplatePatch(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplatePatch(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplatePatch(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsBoilerplatePatch.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelBoilerPlate>(
            `${this.configuration.basePath}/boilerplates`,
            boilerPlate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param boilerPlate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsBoilerplatePost(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelBoilerPlate>;
    public projectsByProjectIdSettingsBoilerplatePost(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplatePost(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelBoilerPlate>>;
    public projectsByProjectIdSettingsBoilerplatePost(
        projectId: string,
        boilerPlate?: BoilerPlate,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsBoilerplatePost.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }
        boilerPlate.projectId = projectId;

        return this.httpClient.post<ResponseModelBoilerPlate>(
            `${this.configuration.basePath}/boilerplates`,
            boilerPlate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsCraftsGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListInt32>;
    public projectsByProjectIdSettingsCraftsGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListInt32>>;
    public projectsByProjectIdSettingsCraftsGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListInt32>>;
    public projectsByProjectIdSettingsCraftsGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsCraftsGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListInt32>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/crafts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListLocation>;
    public projectsByProjectIdSettingsLocationGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListLocation>>;
    public projectsByProjectIdSettingsLocationGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListLocation>>;
    public projectsByProjectIdSettingsLocationGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListLocation>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/location`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param locationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationLocationidDelete(
        projectId: string,
        locationId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public projectsByProjectIdSettingsLocationLocationidDelete(
        projectId: string,
        locationId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public projectsByProjectIdSettingsLocationLocationidDelete(
        projectId: string,
        locationId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public projectsByProjectIdSettingsLocationLocationidDelete(
        projectId: string,
        locationId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationLocationidDelete.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (locationId !== undefined && locationId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>locationId,
                'locationId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/location/locationid`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationPatch(
        projectId: string,
        location?: Location,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelLocation>;
    public projectsByProjectIdSettingsLocationPatch(
        projectId: string,
        location?: Location,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationPatch(
        projectId: string,
        location?: Location,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationPatch(
        projectId: string,
        location?: Location,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationPatch.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelLocation>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/location`,
            location,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationPost(
        projectId: string,
        location?: Location,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelLocation>;
    public projectsByProjectIdSettingsLocationPost(
        projectId: string,
        location?: Location,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationPost(
        projectId: string,
        location?: Location,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationPost(
        projectId: string,
        location?: Location,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationPost.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelLocation>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/location`,
            location,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationsandfallbacklocationsGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListLocation>;
    public projectsByProjectIdSettingsLocationsandfallbacklocationsGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListLocation>>;
    public projectsByProjectIdSettingsLocationsandfallbacklocationsGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListLocation>>;
    public projectsByProjectIdSettingsLocationsandfallbacklocationsGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationsandfallbacklocationsGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListLocation>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/locationsandfallbacklocations`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsLocationtestdataPost(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelLocation>;
    public projectsByProjectIdSettingsLocationtestdataPost(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationtestdataPost(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelLocation>>;
    public projectsByProjectIdSettingsLocationtestdataPost(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsLocationtestdataPost.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelLocation>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/locationtestdata`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsPlansGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListGuid>;
    public projectsByProjectIdSettingsPlansGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListGuid>>;
    public projectsByProjectIdSettingsPlansGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListGuid>>;
    public projectsByProjectIdSettingsPlansGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsPlansGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListGuid>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/plans`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsProjecttypeGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelString>;
    public projectsByProjectIdSettingsProjecttypeGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelString>>;
    public projectsByProjectIdSettingsProjecttypeGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelString>>;
    public projectsByProjectIdSettingsProjecttypeGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsProjecttypeGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelString>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/projecttype`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsProjecttypePatch(
        projectId: string,
        type?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelString>;
    public projectsByProjectIdSettingsProjecttypePatch(
        projectId: string,
        type?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelString>>;
    public projectsByProjectIdSettingsProjecttypePatch(
        projectId: string,
        type?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelString>>;
    public projectsByProjectIdSettingsProjecttypePatch(
        projectId: string,
        type?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsProjecttypePatch.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (type !== undefined && type !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>type,
                'type'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelString>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/projecttype`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param letterId
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsSeriallettersByLetterIdDelete(
        letterId: string,
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListRTFTemplate>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdDelete(
        letterId: string,
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdDelete(
        letterId: string,
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdDelete(
        letterId: string,
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (letterId === null || letterId === undefined) {
            throw new Error(
                'Required parameter letterId was null or undefined when calling projectsByProjectIdSettingsSeriallettersByLetterIdDelete.'
            );
        }
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsSeriallettersByLetterIdDelete.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<ResponseModelListRTFTemplate>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/serialletters/${encodeURIComponent(String(letterId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param letterId
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsSeriallettersByLetterIdPost(
        letterId: string,
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListRTFTemplate>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdPost(
        letterId: string,
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdPost(
        letterId: string,
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersByLetterIdPost(
        letterId: string,
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (letterId === null || letterId === undefined) {
            throw new Error(
                'Required parameter letterId was null or undefined when calling projectsByProjectIdSettingsSeriallettersByLetterIdPost.'
            );
        }
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsSeriallettersByLetterIdPost.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelListRTFTemplate>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/serialletters/${encodeURIComponent(String(letterId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param projectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsByProjectIdSettingsSeriallettersGet(
        projectId: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListRTFTemplate>;
    public projectsByProjectIdSettingsSeriallettersGet(
        projectId: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersGet(
        projectId: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListRTFTemplate>>;
    public projectsByProjectIdSettingsSeriallettersGet(
        projectId: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error(
                'Required parameter projectId was null or undefined when calling projectsByProjectIdSettingsSeriallettersGet.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListRTFTemplate>(
            `${this.configuration.basePath}/projects/${encodeURIComponent(
                String(projectId)
            )}/settings/serialletters`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }
}
