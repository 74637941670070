import { Pipe, PipeTransform } from '@angular/core';
import { BoilerPlate } from 'app/core/rest-api';
import { CascadeDictionaryItem } from 'app/shared/components/cascade-column-list/models/cascade-dictionary-item.model';
import { Store, select } from '@ngrx/store';
import { BoilerplatesState } from 'app/store/boilerplates/boilerplates.reducers';
import { Craft } from 'app/store/crafts/craft';
import {
    getBoilerplatesByParentId,
    getBoilerplateById,
} from 'app/store/boilerplates/boilerplates.selectors';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from '@ngrx/entity';
import {
    getGlobalBoilerplateById,
    getGlobalBoilerplatesByParentId,
} from 'app/store/global-bolierplates/global-boilerplates.selectors';

@Pipe({ name: 'transformToCascadeDictionary' })
export class TransformToCascadeDictionaryPipe implements PipeTransform {
    constructor(
        private store: Store<BoilerplatesState>,
        private translationService: TranslateService
    ) {}

    transform(
        selectedBoilerplates: string[],
        craftEntities?: Dictionary<Craft>,
        globalBoilerplate = false
    ): CascadeDictionaryItem[] {
        return selectedBoilerplates.map((boilerplateId) => {
            if (!boilerplateId) return undefined;

            let boilerplate: BoilerPlate = this.getBoilerplate(
                boilerplateId,
                globalBoilerplate
            );

            let children = this.getBoilerplates(
                boilerplateId,
                globalBoilerplate
            );

            return new CascadeDictionaryItem(
                this.getBoilerPlateWithProperTitle(boilerplate, craftEntities),
                children
            );
        });
    }

    getBoilerplate(
        boilerplateId: string,
        globalBoilerplate: boolean
    ): BoilerPlate {
        let result: BoilerPlate;
        let boilerplate$ = this.store.pipe(
            select(
                globalBoilerplate
                    ? getGlobalBoilerplateById
                    : getBoilerplateById,
                { id: boilerplateId }
            ),
            take(1)
        );

        boilerplate$.subscribe((boilerplate) => {
            if (!boilerplate) return;
            result = boilerplate;
        });

        return result;
    }
    getBoilerPlateWithProperTitle(
        boilerplate: BoilerPlate,
        craftEntities?: Dictionary<Craft>
    ): BoilerPlate {
        if (boilerplate && !boilerplate.parentID) {
            let title = this.translationService.instant(
                'CRAFTS.' + boilerplate.title
            );

            if (craftEntities) {
                const craftNumber = parseInt(boilerplate.title, 10);
                title = craftEntities[craftNumber]?.title ?? boilerplate.title;
            }

            return { ...boilerplate, title };
        }

        return boilerplate;
    }

    getBoilerplates(
        parentId: string,
        globalBoilerplate: boolean
    ): BoilerPlate[] {
        let result: BoilerPlate[];
        let list$ = this.store.pipe(
            select(
                globalBoilerplate
                    ? getGlobalBoilerplatesByParentId
                    : getBoilerplatesByParentId,
                { id: parentId }
            ),
            take(1)
        );

        list$.subscribe((list) => {
            if (!list) return;
            result = list;
        });

        return result;
    }
}
