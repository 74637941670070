import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'app/store/auth/auth.reducer';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private store: Store<AuthState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(select(getIsAdminGlobal));
    }
}
