

let count = 0;
const timestamp: string = Math.floor((new Date()).getTime() / 1000).toString(36);

export function generateAnnotationID(): string {
    return (
        '_' +
        Math.random()
            .toString(36)
            .substr(2, 9) +
        timestamp +
        (count++).toString(36)
    );
}
