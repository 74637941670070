<div>
  <mat-option
    (click)="itemSelected.emit(item)"
    *ngFor="let item of filteredItems"
    [value]="item"
  >
    <div
      class="autosel-flex autosel-flex-row autosel-justify-between autosel-items-center"
    >
      <span> {{ item[titleProperty] }} </span>
    </div>
  </mat-option>

  <ng-container *ngIf="noMatchComponent && !filteredItems.length">
    <mat-option value="{}" disabled class="no-match" (click)="$event.stopPropagation();">
      <ng-template [ngTemplateOutlet]="noMatchComponent.innerTemplate"></ng-template>
    </mat-option>
  </ng-container>

</div>
