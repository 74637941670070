import { environment } from 'environments/environment';

export class AcceptUtils {
    constructor() {}

    static generateGuid(): string {
        const random = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return (
            random() +
            random() +
            '-' +
            random() +
            '-' +
            random() +
            '-' +
            random() +
            '-' +
            random() +
            random() +
            random()
        );
    }

    static isDefaultGuid(id: string): boolean {
        return id === environment.defaultGuid;
    }

    /** Check if `id` includes "NEW_" */
    static isLocalGuid(id: string): boolean {
        return id && id.includes('NEW_');
    }

    // TODO - Why isn't this a standard library?
    static decodeJWT(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(this.b64DecodeUnicode(base64));
    }

    static b64DecodeUnicode(str): string {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split('')
                .map((c) => {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );
    }

    /**
     * Takes a sorted list and a grouping function that's executed on each list entry to group them by
     * @param sortedList A list of objects that's sorted by the the field to group by
     * @param groupByFn This function has to return the field of the list objects by which they should be grouped
     */
    static groupSortedList<T>(
        sortedList: T[],
        groupByFn: (entity: T) => any
    ): T[][] {
        let prev = sortedList[0];
        const groupedList: T[][] = [];
        let tmpList = [];
        sortedList.forEach((entity) => {
            const current = groupByFn(entity);
            if (current === groupByFn(prev)) {
                tmpList.push(entity);
            } else {
                prev = entity;
                groupedList.push(tmpList);
                tmpList = [entity];
            }
        });
        groupedList.push(tmpList);
        return groupedList;
    }

    static combineMaps<K, V>(...maps: Map<K, V>[]): Map<K, V> {
        const combined = new Map<K, V>();

        for (const map of maps) {
            for (const [key, value] of map.entries()) {
                combined.set(key, value);
            }
        }

        return combined;
    }

    static thumbnailSuffix(): string {
        return '/thumbnail';
    }

    static fullscaleSuffix(): string {
        return '/fullscale';
    }
}

/**
 * Invert the given predicate.
 */
export function not<A>(f: (argument: A) => boolean): (argument: A) => boolean {
    return (argument) => !f(argument);
}

/**
 * Returns true if the array is either missing or has no elements.
 *
 * The array elements are typed unknown, because they are not accessed
 * and do not matter for this function.
 */
export function missingOrEmpty(array?: unknown[]): boolean {
    return !array || array.length === 0;
}

/**
 * Returns true if the value is either null or undefined.
 */
export function nullOrUndefined<T>(value?: T | null): boolean {
    return value === undefined || value === null;
}

/**
 * Returns true if the string is either missing or is empty.
 */
export function missingOrEmptyString(value?: string | null): boolean {
    if (nullOrUndefined(value)) {
        return true;
    }

    return value.length === 0;
}
