import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeUnit } from '../models/time-unit.model';
import * as moment from 'moment';
import { TimeTense } from '../models/time-tense.model';

@Pipe({ name: 'timeDifferenceText' })
export class TimeDifferenceTextPipe implements PipeTransform {
    constructor(private translationService: TranslateService) {}

    transform(
        pastTime: Date,
        futureTime: Date,
        timeTense: TimeTense,
        unitBase?: TimeUnit
    ): string {
        const past = moment(pastTime);
        const future = moment(futureTime);

        let differenceResult: number = Math.round(future.diff(past) / 1000);
        differenceResult = differenceResult >= 0 ? differenceResult : 0;

        let result;

        // Check if result is by Seconds
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.SECONDS,
            differenceResult,
            timeTense,
            60,
            result
        );

        // Difference in Minutes
        differenceResult = Math.round(differenceResult / 60);
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.MINUTES,
            differenceResult,
            timeTense,
            60,
            result
        );

        // Difference in Hours
        differenceResult = Math.round(differenceResult / 60);
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.HOURS,
            differenceResult,
            timeTense,
            24,
            result
        );

        // Difference in Days
        differenceResult = Math.round(differenceResult / 24);
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.DAYS,
            differenceResult,
            timeTense,
            30,
            result
        );

        // Difference in Months
        differenceResult = Math.round(differenceResult / 30);
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.MONTHS,
            differenceResult,
            timeTense,
            12,
            result
        );

        // Difference in Years
        differenceResult = Math.round(differenceResult / 12);
        result = this.getTranslationByUnit(
            unitBase,
            TimeUnit.YEARS,
            differenceResult,
            timeTense,
            999,
            result
        );

        return result;
    }

    getTranslationByUnit(
        unitBase: TimeUnit,
        unit: TimeUnit,
        differenceResult: number,
        timeTense: TimeTense,
        limitForUnit: number,
        result: string
    ): string {
        if (
            (unitBase && unitBase === unit) ||
            (differenceResult < limitForUnit && !result)
        )
            result = this.getTimeTranslation(differenceResult, unit, timeTense);

        return result;
    }

    getTimeTranslation(
        timeDifference: number,
        unitBase: TimeUnit,
        timeTense: TimeTense
    ): string {
        const quantityText = timeDifference > 1 ? 'PLURAL' : 'SINGULAR';
        const baseText: string = this.translationService.instant(
            'TIME.' + unitBase + '.' + quantityText + '.' + timeTense
        );

        return baseText.replace('***', timeDifference + '');
    }
}
