import { Injectable } from '@angular/core';
import { Dictionary, Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import {
    ApprovalEntry,
    DailyLogEntryBase,
    NoteEntry,
    ProgressReportEntry,
} from 'app/core/rest-api';
import { AcceptUtils, not } from 'app/core/utils/accept-utils';
import { DiaryCommand } from 'app/main/diary/models/diary-command';
import { AttachmentUploadFromDbService } from 'app/shared/services/attachment-upload/attachment-upload-from-db.service';
import { EntityAttachmentMap } from '../issues/models/entity-attachment-map';
import {
    SetDiaryCreatesAttachmentsUploading,
    SetDiaryUpdatesAttachmentsUploading,
} from '../sync/sync.actions';
import { DiaryState } from './diary.reducer';
import { DiaryEntry } from './models/diary-entry';
import { DiaryToCreate } from './models/diary-to-create';
import { DiaryToUpdate } from './models/diary-to-update';
import { MergeDiaryEntitiesWithCommands } from './models/merge-diaries-entities-with-commands';
import { MergeDiariesWithCommands } from './models/merge-diaries-with-commands';

@Injectable({ providedIn: 'root' })
export class DiaryStoreUtilsService {
    constructor(
        private store: Store<DiaryState>,
        private attachmentUploadFromDbService: AttachmentUploadFromDbService
    ) {}

    static mergeDiariesWithCommands({
        entities,
        diaryCommands,
        projectId,
    }: MergeDiariesWithCommands): (DiaryEntry | DailyLogEntryBase)[] {
        const entityMapFromArray = {};
        for (const entity of entities) {
            entityMapFromArray[entity.id] = entity;
        }

        const changedEntities = this.mergeDiaryEntitiesWithCommands({
            entityMap: entityMapFromArray,
            diaryCommands,
            projectId,
        });
        return Object.values(changedEntities);
    }

    static mergeDiaryEntitiesWithCommands({
        entityMap,
        diaryCommands,
        projectId,
    }: MergeDiaryEntitiesWithCommands): Dictionary<
        DiaryEntry | DailyLogEntryBase
    > {
        // we need that, because we can have offline stored commands
        // for multiple projects
        const projectCommands = diaryCommands.filter(
            (c) => c.projectId === projectId
        );
        const diaryIds = new Set(projectCommands.map((c) => c.entityId));

        for (const id of diaryIds) {
            // last issue from api
            const diaryEntity = entityMap[id] || {};
            // stored commands for that issue
            const diaryCommandsForEntry = diaryCommands.filter(
                (command) => command.entityId === id
            );
            // merge changes of all commands
            let merged: any = {};
            for (const command of diaryCommandsForEntry) {
                merged = {
                    ...merged,
                    ...command.changes,
                    id,
                };
            }
            // apply command changes to issue
            entityMap = {
                ...entityMap,
                [id]: { ...diaryEntity, ...merged },
            };
        }

        return entityMap;
    }

    static replaceDiaryAttachmentIdsInCommandChanges(
        entityId: string,
        commandChanges: Partial<
            NoteEntry | ApprovalEntry | ProgressReportEntry
        >,
        diaryIdToAttachmentIds: EntityAttachmentMap
    ): Partial<DiaryEntry> {
        let { files, photos } = commandChanges;
        const attachmentMap = diaryIdToAttachmentIds.get(entityId);
        if (!attachmentMap) {
            return commandChanges;
        }
        for (const [localId, apiId] of attachmentMap) {
            if (photos) {
                photos = photos.map((current) =>
                    current === localId ? apiId : current
                );
                commandChanges.photos = photos;
            }
            if (files) {
                files = files.map((current) =>
                    current === localId ? apiId : current
                );
                commandChanges.files = files;
            }
        }
        return commandChanges;
    }

    static onlyEmptyChanges(command: DiaryCommand): boolean {
        const changes = Object.keys(command.changes);

        const changesTypeAndUpdateDateTime =
            changes.includes('type') && changes.includes('updateDateTime');
        const onlyTypeAndUpdateDateTime =
            changes.length === 2 && changesTypeAndUpdateDateTime;
        const withLogEntryDateTime =
            changes.length === 3 &&
            changesTypeAndUpdateDateTime &&
            changes.includes('logEntryDateTime');

        const emptyChanges = onlyTypeAndUpdateDateTime || withLogEntryDateTime;

        return emptyChanges;
    }

    async prepareDiaryCommands(
        diaryCommands: DiaryCommand[]
    ): Promise<{
        diariesToCreate: DiaryToCreate[];
        diariesToUpdate: DiaryToUpdate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        // do not try to include commands which are already part of a request
        const notInFlight = diaryCommands.filter((c) => !c.inFlight);

        // need to handle markedPlan updates separately
        const diaryCreates = notInFlight.filter((c) => c.action === 'create');
        let diaryUpdates = notInFlight.filter((c) => c.action === 'update');
        // map for localId => apiId of attachments

        const {
            diariesToCreate,
            attachmentMap: createAttachmentMap,
        } = await this.getDiaryCreatesWithApiAttachmentIds(
            diaryCreates,
            diaryUpdates
        );

        const diariesToCreateIds = diariesToCreate.map((post) => post.entityId);
        // remove entityIds that have a create command
        // we merged the available updates commands for this create already
        // in getCreateCommandsWithApiAttachmentIds
        diaryUpdates = diaryUpdates.filter(
            (command) =>
                !diariesToCreateIds.includes(command.entityId) &&
                !command.waitForCreation
        );

        // bugfix: remove update commands which only update the updateDateTime,
        //         these are caused by autosave in inspections when one opens
        //         issue details from there
        diaryUpdates = diaryUpdates.filter(
            not(DiaryStoreUtilsService.onlyEmptyChanges)
        );

        const {
            diariesToUpdate,
            attachmentMap: updateAttachmentMap,
        } = await this.getDiaryUpdatesWithApiAttachmentIds(diaryUpdates);

        return {
            diariesToCreate,
            diariesToUpdate,
            attachmentMap: AcceptUtils.combineMaps(
                createAttachmentMap,
                updateAttachmentMap
            ),
        };
    }

    /**
     * uploads local attachments and
     * replaces the local attachment ids (e.g. NEW_XYZ)
     * with the api attachment mongo id
     * @param creates
     * @param updates
     */
    async getDiaryCreatesWithApiAttachmentIds(
        creates: DiaryCommand[],
        updates: DiaryCommand[]
    ): Promise<{
        diariesToCreate: DiaryToCreate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        const toCreate = [];
        const diaryIdToAttachmentIds: EntityAttachmentMap = new Map();
        // merge create command with available update commands
        for (const create of creates) {
            let mergedChanges = {
                ...create.changes,
            };
            const { entityId } = create;
            // 'update' commands, that followed on a 'create' command
            const updatesForCreate = updates.filter(
                (c) => c.entityId === entityId
            );
            // apply updates that followed after create command
            for (const update of updatesForCreate) {
                if (!update.locked) {
                    mergedChanges = {
                        ...mergedChanges,
                        ...update.changes,
                    };
                }
            }
            // get local created ids (= non api ids)
            const localAttachmentIds = this.getLocalDiaryAttachmentIds(
                mergedChanges
            );

            delete mergedChanges.id;
            const diary = mergedChanges as DailyLogEntryBase;
            if (!create.locked) {
                toCreate.push({
                    diary,
                    entityId,
                });
            }

            if (localAttachmentIds.length > 0) {
                const localIdToApiIdMap = new Map();
                for (const localId of localAttachmentIds) {
                    localIdToApiIdMap.set(localId, '');
                }
                diaryIdToAttachmentIds.set(create.entityId, localIdToApiIdMap);
            }
        }

        if (diaryIdToAttachmentIds.size === 0) {
            return {
                diariesToCreate: toCreate,
                attachmentMap: diaryIdToAttachmentIds,
            };
        }

        this.store.dispatch(
            new SetDiaryCreatesAttachmentsUploading({ uploading: true })
        );

        const updatedDiaryIdToAttachmentIds = await this.attachmentUploadFromDbService.uploadAttachmentsAndReplaceLocalIds(
            diaryIdToAttachmentIds
        );

        this.store.dispatch(
            new SetDiaryCreatesAttachmentsUploading({ uploading: false })
        );

        const toCreateWithApiAttachmentIds = toCreate.map((create) => {
            const diary = DiaryStoreUtilsService.replaceDiaryAttachmentIdsInCommandChanges(
                create.entityId,
                create.diary,
                updatedDiaryIdToAttachmentIds
            );
            return {
                ...create,
                diary,
            };
        });

        return {
            diariesToCreate: toCreateWithApiAttachmentIds,
            attachmentMap: updatedDiaryIdToAttachmentIds,
        };
    }

    async getDiaryUpdatesWithApiAttachmentIds(
        updates: DiaryCommand[]
    ): Promise<{
        diariesToUpdate: DiaryToUpdate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        const toUpdate: DiaryToUpdate[] = [];
        const entityIds = Array.from(new Set(updates.map((c) => c.entityId)));
        const diaryIdToAttachmentIds: EntityAttachmentMap = new Map();

        for (const entityId of entityIds) {
            // commands related to issue with id
            const updateCommands = updates.filter(
                (c) => c.entityId === entityId
            );

            // use the first command for this entity id
            // to set the id the DiaryToUpdate
            // TODO: why is entityId not used directly?
            const projectId = updateCommands[0].projectId;
            let mergedUpdates: Update<DiaryEntry> = {
                id: updateCommands[0].entityId,
                changes: {},
            };

            // merge change of commands
            let locked = false;
            for (const update of updateCommands) {
                mergedUpdates = {
                    ...mergedUpdates,
                    changes: {
                        ...mergedUpdates.changes,
                        ...update.changes,
                    },
                };
                locked = update.locked;
            }
            if (!locked) {
                toUpdate.push({ projectId, update: mergedUpdates });
            }
            const localAttachmentIds = this.getLocalDiaryAttachmentIds(
                mergedUpdates.changes
            );

            if (localAttachmentIds.length > 0) {
                const localIdToApiIdMap = new Map();
                for (const localId of localAttachmentIds) {
                    localIdToApiIdMap.set(localId, '');
                }
                diaryIdToAttachmentIds.set(entityId, localIdToApiIdMap);
            }
        }

        if (diaryIdToAttachmentIds.size === 0) {
            return {
                diariesToUpdate: toUpdate,
                attachmentMap: diaryIdToAttachmentIds,
            };
        }

        this.store.dispatch(
            new SetDiaryUpdatesAttachmentsUploading({ uploading: true })
        );
        const updatedDiaryIdToAttachmentIds = await this.attachmentUploadFromDbService.uploadAttachmentsAndReplaceLocalIds(
            diaryIdToAttachmentIds
        );

        this.store.dispatch(
            new SetDiaryUpdatesAttachmentsUploading({ uploading: false })
        );

        const toUpdateWithApiAttachmentIds = toUpdate.map((updateEntry) => {
            const changesWithReplacedIds = DiaryStoreUtilsService.replaceDiaryAttachmentIdsInCommandChanges(
                updateEntry.update.id.toString(),
                updateEntry.update.changes,
                updatedDiaryIdToAttachmentIds
            );
            return {
                ...updateEntry,
                update: {
                    ...updateEntry.update,
                    changes: changesWithReplacedIds,
                },
            };
        });
        return {
            diariesToUpdate: toUpdateWithApiAttachmentIds,
            attachmentMap: updatedDiaryIdToAttachmentIds,
        };
    }

    getLocalDiaryAttachmentIds(changes: Partial<DiaryEntry>): string[] {
        let localIds = [];
        const photos = changes['photos'];
        const files = changes['files'];

        if (photos?.length > 0) {
            localIds = [...localIds, ...photos];
        }

        if (files?.length > 0) {
            localIds = [...localIds, ...files];
        }
        localIds = localIds.filter((id) => AcceptUtils.isLocalGuid(id));
        return localIds;
    }
}
