
<div>
    <mat-table  #issueTable="matSort"
                matSort
                [dataSource]="issueLettersData"
                class=" mb-24"
    >

        <!-- Issue Column -->
        <ng-container matColumnDef="title">
            <mat-cell fxFlex="90"
                      *matCellDef="let element"> {{element.title}} </mat-cell>
        </ng-container>


        <!-- Edit Column -->
        <ng-container matColumnDef="edit">

            <mat-cell fxFlex="10 0 auto" fxLayoutAlign="end center"
                      *matCellDef="let element">
                <button
                    mat-icon-button
                    *ngIf="templateType==='FormLetterTemplate'"
                    (click)="deleteFormLetter(element); "
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    mat-icon-button
                    *ngIf="templateType!='RTFTemplate'"
                    (click)="onOpenPreview(element)"
                >
                    <mat-icon>visibility</mat-icon>
                </button>

            </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns;"
                 [ngClass]="{'mat-list-single-selected-option': selectedRowIndex == row.id}"
                 (click)="highlight(row); selectLetter(row)">
        </mat-row>

    </mat-table>

</div>
