import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary, Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Issue, MarkedPlan } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { IssueCommand } from 'app/main/issues/models/issue-command';
import { PlanCommand } from 'app/main/issues/models/plan-command';
import { AttachmentUploadFromDbService } from 'app/shared/services/attachment-upload/attachment-upload-from-db.service';
import {
    SetIssueCreatesAttachmentsUploading,
    SetIssueUpdatesAttachmentsUploading,
    SetPlanUpdatesAttachmentsUploading,
} from '../sync/sync.actions';
import { SyncState } from '../sync/sync.reducer';
import { EntityAttachmentMap } from './models/entity-attachment-map';
import { IssueToCreate } from './models/issue-to-create';
import { IssueToUpdate } from './models/issue-to-update';
import { MergeIssueEntitiesWithCommands } from './models/merge-issue-entities-with-commands';
import { MergeIssuesWithCommands } from './models/merge-issues-with-commands';
import { PlanToUpdate } from './models/plan-to-update';

@Injectable({
    providedIn: 'root',
})
export class IssuesStoreUtilsService {
    constructor(
        private store: Store<SyncState>,
        private attachmentUploadFromDbService: AttachmentUploadFromDbService
    ) {}

    /**
     * just converts the array entries to a map
     * and let other functon do the work
     * @param entities
     * @param commands
     * @param planCommands
     */
    static mergeIssuesWithCommands({
        entities,
        issueCommands,
        planCommands,
        projectId,
    }: MergeIssuesWithCommands): Issue[] {
        const entityMapFromArray = {};
        for (const entity of entities) {
            entityMapFromArray[entity.id] = entity;
        }

        const changedEntities = this.mergeIssuesEntitiesWithCommands({
            entityMap: entityMapFromArray,
            issueCommands,
            planCommands,
            projectId,
        });
        return Object.values(changedEntities);
    }
    /**
     * merges last api issue state
     * with stored issue commands (transactional changes)
     * and stored plan commands (transactional markedPlan changes)
     * to implement CQRS
     * @param entityMap
     * @param issueCommands
     * @param planCommands
     */
    static mergeIssuesEntitiesWithCommands({
        entityMap,
        issueCommands,
        planCommands,
        projectId,
    }: MergeIssueEntitiesWithCommands): Dictionary<Issue> {
        // we need that, because we can have offline stored commands
        // for multiple projects

        const projectCommands = issueCommands.filter(
            (c) => c.projectId === projectId
        );
        const issueIds = new Set(projectCommands.map((c) => c.entityId));

        for (const id of issueIds) {
            // last issue from api
            const issueEntity = entityMap[id] || {};
            // stored commands for that issue
            const issueCommandsForIssue = issueCommands.filter(
                (command) => command.entityId === id
            );
            // merge changes of all commands
            let merged = {};
            for (const command of issueCommandsForIssue) {
                merged = {
                    ...merged,
                    ...command.changes,
                    id,
                };
            }
            // apply command changes to issue
            entityMap = {
                ...entityMap,
                [id]: { ...issueEntity, ...merged },
            };
        }
        // set of unique issueIds of planCommands
        const planIssueIds = new Set(planCommands.map((c) => c.issueId));
        for (const id of planIssueIds) {
            if (!id) {
                continue;
            }
            // plan commands related to issue
            const planCommandsForIssue = planCommands.filter(
                (command) => command.issueId === id
            );

            const markedPlanFromIssue = entityMap[id]?.markedPlan;
            const markedPlan = IssuesStoreUtilsService.mergePlanCommandChanges(
                planCommandsForIssue,
                markedPlanFromIssue
            );

            // apply plan changes from commands to issue
            entityMap = {
                ...entityMap,
                [id]: { ...entityMap[id], markedPlan },
            };
        }
        return entityMap;
    }

    public static mergePlanCommandChanges(
        planCommandsForIssue: PlanCommand[],
        markedPlanFromIssue?: MarkedPlan
    ): MarkedPlan {
        let markedPlan = {};
        for (const command of planCommandsForIssue) {
            markedPlan = {
                ...markedPlan,
                ...command.changes,
            };
        }

        // include existing changes from the issue
        markedPlan = {
            ...markedPlanFromIssue,
            ...markedPlan,
        };
        return markedPlan;
    }

    static replaceIssueAttachmentIdsInCommandChanges(
        entityId: string,
        commandChanges: Partial<Issue>,
        issueIdToAttachmentIds: EntityAttachmentMap
    ): Partial<Issue> {
        let { photo, files, photos } = commandChanges;
        const attachmentMap = issueIdToAttachmentIds.get(entityId);
        if (!attachmentMap) {
            return commandChanges;
        }
        for (const [localId, apiId] of attachmentMap) {
            if (photo && photo === localId) {
                photo = apiId;
                commandChanges.photo = photo;
            }
            if (photos) {
                photos = photos.map((current) =>
                    current === localId ? apiId : current
                );
                commandChanges.photos = photos;
            }
            if (files) {
                files = files.map((current) =>
                    current === localId ? apiId : current
                );
                commandChanges.files = files;
            }
        }
        return commandChanges;
    }

    async prepareIssueCommands(
        issueCommands: IssueCommand[],
        planCommands: PlanCommand[]
    ): Promise<{
        issuesToCreate: IssueToCreate[];
        issuesToUpdate: IssueToUpdate[];
        plansToUpdate: PlanToUpdate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        // planCommandsForNewIssues = planCommands
        // will be handled by planCommands
        issueCommands = issueCommands.filter(
            (c) => !c.changes.hasOwnProperty('markedPlan')
        );

        issueCommands = issueCommands.filter((c) => !c.inFlight);

        planCommands = planCommands.filter((c) => !c.inFlight);

        // need to handle markedPlan updates separately
        const issueCreates = issueCommands.filter((c) => c.action === 'create');
        let issueUpdates = issueCommands.filter((c) => c.action === 'update');
        // map for localId => apiId of attachments

        const {
            issuesToCreate,
            attachmentMap: createAttachmentMap,
        } = await this.getIssueCreatesWithApiAttachmentIds(
            issueCreates,
            issueUpdates
        );

        const {
            plansToUpdate,
            attachmentMap: planUpdateAttachmentMap,
        } = await this.getPlanUpdatesWithApiAttachmentIds(planCommands);

        const issuesToCreateIds = issuesToCreate.map((post) => post.entityId);
        // remove entityIds that have a create command
        // we merged the available updates commands for this create already
        // in getCreateCommandsWithApiAttachmentIds
        issueUpdates = issueUpdates.filter(
            (command) =>
                !issuesToCreateIds.includes(command.entityId) &&
                !command.waitForCreation
        );

        const {
            issuesToUpdate,
            attachmentMap: updateAttachmentMap,
        } = await this.getIssueUpdatesWithApiAttachmentIds(issueUpdates);

        return {
            issuesToCreate,
            issuesToUpdate,
            plansToUpdate,
            attachmentMap: AcceptUtils.combineMaps(
                createAttachmentMap,
                updateAttachmentMap,
                planUpdateAttachmentMap
            ),
        };
    }

    /**
     * uploads local attachments and
     * replaces the local attachment ids (e.g. NEW_XYZ)
     * with the api attachment mongo id
     * @param creates
     * @param updates
     */
    async getIssueCreatesWithApiAttachmentIds(
        creates: IssueCommand[],
        updates: IssueCommand[]
    ): Promise<{
        issuesToCreate: IssueToCreate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        const toCreate = [];
        // { issueId: { localId: attachmentId, ... }, ... }
        const issueIdToAttachmentIds: EntityAttachmentMap = new Map();
        // merge create command with available update commands
        for (const create of creates) {
            let mergedChanges = {
                ...create.changes,
            };
            const { entityId } = create;
            // 'update' commands, that followed on a 'create' command
            const updatesForCreate = updates.filter(
                (c) => c.entityId === entityId
            );
            // apply updates that followed after create command
            for (const update of updatesForCreate) {
                mergedChanges = {
                    ...mergedChanges,
                    ...update.changes,
                };
            }
            // get local created ids (= non api ids)
            const localAttachmentIds = this.getLocalIssueAttachmentIds(
                mergedChanges
            );

            delete mergedChanges.id;
            const issue = mergedChanges;
            if (!create.locked) {
                toCreate.push({
                    issue,
                    entityId,
                });
            }

            if (localAttachmentIds.length > 0) {
                const localIdToApiIdMap = new Map();
                for (const localId of localAttachmentIds) {
                    localIdToApiIdMap.set(localId, '');
                }
                issueIdToAttachmentIds.set(create.entityId, localIdToApiIdMap);
            }
        }

        if (issueIdToAttachmentIds.size === 0) {
            return {
                issuesToCreate: toCreate,
                attachmentMap: issueIdToAttachmentIds,
            };
        }

        this.store.dispatch(
            new SetIssueCreatesAttachmentsUploading({ uploading: true })
        );

        const updatedIssueIdToAttachmentIds = await this.attachmentUploadFromDbService.uploadAttachmentsAndReplaceLocalIds(
            issueIdToAttachmentIds
        );

        this.store.dispatch(
            new SetIssueCreatesAttachmentsUploading({ uploading: false })
        );

        const toCreateWithApiAttachmentIds = toCreate.map((create) => {
            const issue = IssuesStoreUtilsService.replaceIssueAttachmentIdsInCommandChanges(
                create.entityId,
                create.issue,
                updatedIssueIdToAttachmentIds
            );
            return {
                ...create,
                issue,
            };
        });

        return {
            issuesToCreate: toCreateWithApiAttachmentIds,
            attachmentMap: updatedIssueIdToAttachmentIds,
        };
    }

    async getIssueUpdatesWithApiAttachmentIds(
        updates: IssueCommand[]
    ): Promise<{
        issuesToUpdate: IssueToUpdate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        const toUpdate: IssueToUpdate[] = [];
        const entityIds = Array.from(new Set(updates.map((c) => c.entityId)));
        const issueIdToAttachmentIds: EntityAttachmentMap = new Map();

        for (const entityId of entityIds) {
            // commands related to issue with id
            const updateCommands = updates.filter(
                (c) => c.entityId === entityId
            );
            const projectId = updateCommands[0].projectId;
            let mergedUpdates: Update<Issue> = {
                id: updateCommands[0].entityId,
                changes: {},
            };

            // merge change of commands
            let locked = false;
            for (const update of updateCommands) {
                mergedUpdates = {
                    ...mergedUpdates,
                    changes: {
                        ...mergedUpdates.changes,
                        ...update.changes,
                    },
                };
                locked = update.locked;
            }
            if (!locked) {
                toUpdate.push({ projectId, update: mergedUpdates });
            }
            const localAttachmentIds = this.getLocalIssueAttachmentIds(
                mergedUpdates.changes
            );

            if (localAttachmentIds.length > 0) {
                const localIdToApiIdMap = new Map();
                for (const localId of localAttachmentIds) {
                    localIdToApiIdMap.set(localId, '');
                }
                issueIdToAttachmentIds.set(entityId, localIdToApiIdMap);
            }
        }

        if (issueIdToAttachmentIds.size === 0) {
            return {
                issuesToUpdate: toUpdate,
                attachmentMap: issueIdToAttachmentIds,
            };
        }

        this.store.dispatch(
            new SetIssueUpdatesAttachmentsUploading({ uploading: true })
        );
        const updatedIssueIdToAttachmentIds = await await this.attachmentUploadFromDbService.uploadAttachmentsAndReplaceLocalIds(
            issueIdToAttachmentIds
        );

        this.store.dispatch(
            new SetIssueUpdatesAttachmentsUploading({ uploading: false })
        );

        const toUpdateWithApiAttachmentIds = toUpdate.map((updateEntry) => {
            const changesWithReplacedIds = IssuesStoreUtilsService.replaceIssueAttachmentIdsInCommandChanges(
                updateEntry.update.id.toString(),
                updateEntry.update.changes,
                updatedIssueIdToAttachmentIds
            );
            return {
                ...updateEntry,
                update: {
                    ...updateEntry.update,
                    changes: changesWithReplacedIds,
                },
            };
        });
        return {
            issuesToUpdate: toUpdateWithApiAttachmentIds,
            attachmentMap: updatedIssueIdToAttachmentIds,
        };
    }

    async getPlanUpdatesWithApiAttachmentIds(
        updates: PlanCommand[]
    ): Promise<{
        plansToUpdate: PlanToUpdate[];
        attachmentMap: EntityAttachmentMap;
    }> {
        const toUpdate: PlanToUpdate[] = [];
        const issueIds = Array.from(
            new Set(updates.map((c) => c.issueId).filter((id) => id))
        );

        const issueIdToAttachmentIds: EntityAttachmentMap = new Map();

        for (const issueId of issueIds) {
            // commands related to issue with id
            const planCommandsForIssue = updates.filter(
                (c) => c.issueId === issueId
            );

            let markedPlan: MarkedPlan = {};
            let projectId;
            let locked = false;
            for (const command of planCommandsForIssue) {
                locked = locked || command.locked;
                projectId = command.projectId;
                markedPlan = { ...markedPlan, ...command.changes };
            }

            if (!locked) {
                toUpdate.push({
                    projectId,
                    markedPlan,
                    issueId,
                    planEntityIds: planCommandsForIssue.map((c) => c.entityId),
                });
            }

            const localAttachmentIds = this.getLocalPlanAttachmentIds(
                markedPlan
            );

            if (localAttachmentIds.length > 0) {
                const localIdToApiIdMap = new Map();
                for (const localId of localAttachmentIds) {
                    localIdToApiIdMap.set(localId, '');
                }
                issueIdToAttachmentIds.set(issueId, localIdToApiIdMap);
            }
        }

        if (issueIdToAttachmentIds.size === 0) {
            return {
                plansToUpdate: toUpdate,
                attachmentMap: issueIdToAttachmentIds,
            };
        }

        this.store.dispatch(
            new SetPlanUpdatesAttachmentsUploading({ uploading: true })
        );
        const udpatedPlanAttachmentIds = await this.attachmentUploadFromDbService.uploadAttachmentsAndReplaceLocalIds(
            issueIdToAttachmentIds
        );

        this.store.dispatch(
            new SetPlanUpdatesAttachmentsUploading({ uploading: false })
        );

        const toUpdateWithApiAttachmentIds = toUpdate.map((updateEntry) => {
            const { thumbnail } = updateEntry.markedPlan;
            // attachment ids of this plan command
            const attachmentMap = udpatedPlanAttachmentIds.get(
                updateEntry.issueId
            );
            if (!attachmentMap) {
                return updateEntry;
            }

            let markedPlan = { ...updateEntry.markedPlan };

            for (const [localId, apiId] of attachmentMap) {
                if (thumbnail && thumbnail === localId) {
                    markedPlan = { ...markedPlan, thumbnail: apiId };
                }
            }

            return {
                ...updateEntry,
                markedPlan,
            };
        });

        return {
            plansToUpdate: toUpdateWithApiAttachmentIds,
            attachmentMap: udpatedPlanAttachmentIds,
        };
    }

    getLocalPlanAttachmentIds(markedPlan: MarkedPlan): string[] {
        let ids = [];
        const { thumbnail } = markedPlan;
        if (thumbnail) {
            ids = [...ids, thumbnail];
        }
        ids = ids.filter((id) => AcceptUtils.isLocalGuid(id));
        return ids;
    }

    getLocalIssueAttachmentIds(changes: Partial<Issue>): string[] {
        const { photos, photo, files } = changes;
        let localIds = [];
        if (photos) {
            localIds = [...localIds, ...photos];
        }
        if (files) {
            localIds = [...localIds, ...files];
        }
        if (photo) {
            if (!localIds.includes(photo)) {
                localIds = [...localIds, photo];
            }
        }
        localIds = localIds.filter((id) => AcceptUtils.isLocalGuid(id));
        return localIds;
    }
}
