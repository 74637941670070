<h1 mat-dialog-title fxLayout="row" fxLayoutAlign="center baseline">
    <span>{{ 'DEMO_MODE.POPUP.HEADER_START' | translate }}</span>
    <img
        src="assets/images/flink2go-demo-dialog-logo.svg"
        alt="uStrich2Go"
        class="logo"
    />
    <span>{{ 'DEMO_MODE.POPUP.HEADER_END' | translate }}</span>
</h1>

<mat-dialog-content fxLayout="column" fxLayoutAlign="start start">
    <h3 class="hint-header">{{ 'DEMO_MODE.POPUP.HINT' | translate }}</h3>

    <div
        fxLayout="column"
        fxLayoutAlign="start start"
        class="pt-8 pb-8"
        role="list"
    >
        <div role="listitem" class="hint-list-item">
            <mat-icon>chevron_right</mat-icon>
            {{ 'DEMO_MODE.POPUP.NO_CONFIDENTIAL_INFO' | translate }}
        </div>
        <mat-divider class="w-100-p"></mat-divider>
        <div role="listitem" class="hint-list-item">
            <mat-icon>chevron_right</mat-icon>
            {{ 'DEMO_MODE.POPUP.SAVING_IS_DISABLED' | translate }}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <a mat-raised-button [href]="getHelpCTAUrl" target="_blank">
        {{ 'DEMO_MODE.POPUP.CTA_GET_HELP' | translate }}
    </a>
    <button mat-raised-button (click)="onConfirm()" color="accent">
        {{ 'DEMO_MODE.POPUP.CONFIRM' | translate }}
    </button>
</mat-dialog-actions>
