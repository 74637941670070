import { Issue, CustomAttribute } from 'app/core/rest-api';
import { FilterCustomAttribute } from '../shared/issue-custom-attributes/filter-custom-attribute.model';

export class IssueFilters {
    craft: number;
    companyId: string;
    createdAt: Date;
    dueDateFrom: Date;
    dueDateTill: Date;
    responsibleInternalId: string;
    responsibleExternalId: string;
    state: Issue.StateEnum;
    title: string;
    archived: boolean;
    bimId: string;
    position: string;

    customAttributes: FilterCustomAttribute[];

    constructor() {
        this.companyId = '';
        this.craft = undefined;
        this.createdAt = undefined;
        this.dueDateFrom = undefined;
        this.dueDateTill = undefined;
        this.responsibleInternalId = '';
        this.state = undefined;
        this.title = '';
        this.archived = false;
        this.bimId = undefined;
        this.position = '';
        this.responsibleExternalId = '';

        this.customAttributes = [];
    }
}

export function processCustomAttributeFilters(
    queryParams: IssueFilters | undefined
): string | undefined {
    if (!queryParams) {
        return undefined;
    }

    return JSON.stringify(queryParams.customAttributes);
}

export function processCustomAttributesQuery(
    customAttributesFromQueryAsString: string,
    customAttributesOfCurrentProject: CustomAttribute[]
): FilterCustomAttribute[] {
    const customAttributesFromQuery = JSON.parse(
        customAttributesFromQueryAsString
    );

    return fillCustomAttributes(
        customAttributesFromQuery,
        customAttributesOfCurrentProject
    );
}

export function fillCustomAttributes(
    customAttributesFromQuery: FilterCustomAttribute[],
    customAttributesOfCurrentProject: CustomAttribute[]
): FilterCustomAttribute[] {
    const filledAttributes: FilterCustomAttribute[] = [];

    for (const fromProject of customAttributesOfCurrentProject) {
        const fromQuery = customAttributesFromQuery.find(
            (queryAttribute) =>
                queryAttribute.template.id === fromProject.template.id
        );

        filledAttributes.push({
            ...fromQuery,
            template: fromProject.template,
        });
    }

    return filledAttributes;
}
