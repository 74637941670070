import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ProjectWithStatistics } from '../project-statistics/project-with-statistics.model';
import { getIsDevice } from 'app/store/core/core.selectors';
import { SyncState } from 'app/store/sync/sync.reducer';
import { getProjectsToSync } from 'app/store/sync/sync.selectors';
import { select, Store } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { TranslateService } from '@ngx-translate/core';
import orderBy from 'lodash/orderBy';

export interface ProjectDetailData {
    projectName: string;
    openIssuesCount: number;
    acceptedIssuesCount: number;
    extendedDeadlineIssuesCount: number;
    doneIssuesCount: number;
    totalIssuesCount: number;
    startDate: string;
    dueDate: string;
    markedAsDelete: boolean;
    chartData: object[];
    photoId?: string;
    project?: Project;
    loading: boolean;
}
@Component({
    selector: 'acc-project-grid',
    templateUrl: 'project-grid.component.html',
    styleUrls: ['project-grid.component.scss'],
    providers: [
        // the simplest solution to make the date pipe available here for now
        DatePipe,
    ],
})
export class ProjectGridComponent {
    projectSpecificDetail: Array<ProjectDetailData> = [];
    loadingProjects: boolean = true;

    constructor(
        private datePipe: DatePipe,
        private store: Store<SyncState>,
        private translateService: TranslateService
    ) {}

    @Input()
    set projectsWithStatistics(projects: ProjectWithStatistics[] | null) {
        if (!projects) {
            // projects are not yet loaded
            return;
        }

        // prefer non-archived projects when ordering the products
        const activeProjects = projects.filter(
            (projectWithStatistics) =>
                !projectWithStatistics.project.markedAsDelete
        );
        const archivedProjects = projects.filter(
            (projectWithStatistics) =>
                projectWithStatistics.project.markedAsDelete
        );

        this.orderedProjectsWithStatistics = [];
        this.orderedProjectsWithStatistics.push(
            ...activeProjects,
            ...archivedProjects
        );

        this.dashboardProjectDetails();

        // some basic project information is present, so we
        // can hide the global loading spinner
        this.loadingProjects = false;
    }

    @Input()
    public language?: string;

    @Output()
    public archived = new EventEmitter<Project>();

    @Output()
    public updated = new EventEmitter<Project>();

    // contains the ordered projects and their statistics
    public orderedProjectsWithStatistics: ProjectWithStatistics[];

    projectsToSync$ = this.store.pipe(select(getProjectsToSync));
    isDevice$ = this.store.pipe(select(getIsDevice));

    /**
     * Extracts required dashboard data to an array for the project grid item
     */
    dashboardProjectDetails(): ProjectDetailData[] {
        // ensure a clean state when this method is called multiple times
        this.projectSpecificDetail = [];

        for (const projectWithStatistics of this
            .orderedProjectsWithStatistics) {
            const project = projectWithStatistics.project;
            const statistics = projectWithStatistics.statistics;

            this.projectSpecificDetail.push({
                projectName: project.title,
                openIssuesCount: statistics?.openIssues,
                acceptedIssuesCount: statistics?.acceptedIssues,
                extendedDeadlineIssuesCount: statistics?.extendedDeadlineIssues,
                doneIssuesCount: statistics?.doneIssues,
                totalIssuesCount: statistics?.totalIssues,
                startDate: this.extractDate(project.startDate),
                dueDate: this.extractDate(project.dueDate),
                markedAsDelete: project.markedAsDelete,
                chartData: [
                    {
                        name: this.translateService.instant(
                            'PROJECT.STATUS_DONE'
                        ),
                        value: statistics?.doneIssues,
                    },
                    {
                        name: this.translateService.instant(
                            'PROJECT.STATUS_OPEN'
                        ),
                        value: statistics?.openIssues,
                    },
                    {
                        name: this.translateService.instant(
                            'PROJECT.STATUS_ACCEPTED'
                        ),
                        value: statistics?.acceptedIssues,
                    },
                    {
                        name: this.translateService.instant(
                            'PROJECT.STATUS_EXTENDED_DEADLINE'
                        ),
                        value: statistics?.extendedDeadlineIssues,
                    },
                ],
                photoId: project.photo,
                project: project,
                loading: projectWithStatistics.loading,
            });
        }

        this.projectSpecificDetail = this.orderAccordingToDeadlineIssues(
            this.projectSpecificDetail
        );

        return this.projectSpecificDetail;
    }

    orderAccordingToDeadlineIssues(
        project: ProjectDetailData[]
    ): ProjectDetailData[] {
        return orderBy(project, 'extendedDeadlineIssuesCount', 'desc');
    }

    extractDate(date: Date | null): string | null {
        if (date) {
            return this.datePipe.transform(
                new Date(date),
                'shortDate',
                undefined,
                this.language
            );
        }

        return null;
    }

    trackByProjectId(_index: number, detail: ProjectDetailData): string {
        // note: This assumes, that the project is always present and always
        //       has an id. Because projects are always fetched from backend
        //       and can't currently be created in offline mode, this should
        //       never happen. Nonetheless, an error is printed if this
        //       happens.
        const projectId = detail.project?.id;

        if (!projectId) {
            console.error('project has no id, trackBy behaviour may be broken');
        }

        return projectId;
    }

    isSeverityHigh(detail: ProjectDetailData): boolean {
        return detail.extendedDeadlineIssuesCount > 0;
    }
}
