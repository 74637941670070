import { PasswordResetComponent } from './main/login/password-reset/password-reset.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/auth.guard';
import { AdminGuard } from './core/auth/admin.guard';
import { ProjectManagerGuard } from './core/auth/project-manager.guard';
import { AdminSetNavigationGuard } from './main/admin/admin-set-navigation.guard';
import { MainSetNavigationGuard } from './main/main-set-navigation.guard';
import { ProjectListComponent } from './main/projects/project-list/project-list.component';
import { ProjectResourcesGuard } from './main/projects/ProjectResourcesGuard';
import { AdminPanelGuard } from './main/admin-panel.guard';

const appRoutes: Routes = [
    {
        path: 'login',
        canActivate: [MainSetNavigationGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
            import('./main/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, AdminGuard, AdminSetNavigationGuard],
        loadChildren: () =>
            import('./main/admin/admin.module').then((m) => m.AdminModule),
    },
    {
        // private admin panel not accessible for customers
        path: 'adminpanel',
        // prevent whole lazy module from loading
        canLoad: [AdminPanelGuard],
        loadChildren: () =>
            import('./main/admin-panel/admin-panel.module').then(
                (m) => m.AdminPanelModule
            ),
    },
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'sync',
                canActivate: [MainSetNavigationGuard],
                loadChildren: () =>
                    import('./main/sync/sync.module').then((m) => m.SyncModule),
            },
            {
                canActivate: [MainSetNavigationGuard],
                path: 'projects',
                component: ProjectListComponent,
            },
            {
                path: 'projects/:projectId',
                canActivate: [ProjectResourcesGuard, MainSetNavigationGuard],
                runGuardsAndResolvers: 'paramsChange',
                children: [
                    {
                        path: '',
                        component: ProjectListComponent,
                    },
                    {
                        path: 'issues',
                        loadChildren: () =>
                            import('./main/issues/issues.module').then(
                                (m) => m.IssuesModule
                            ),
                    },
                    {
                        path: 'diary',
                        loadChildren: () =>
                            import('./main/diary/diary.module').then(
                                (m) => m.DiaryModule
                            ),
                    },
                    {
                        path: 'archive',
                        loadChildren: () =>
                            import('./main/archive/archive.module').then(
                                (m) => m.ArchiveModule
                            ),
                    },
                    {
                        path: 'buildings',
                        canActivate: [ProjectManagerGuard],
                        loadChildren: () =>
                            import('./main/buildings/buildings.module').then(
                                (m) => m.BuildingsModule
                            ),
                    },
                    {
                        path: 'users',
                        loadChildren: () =>
                            import('./main/attendees/attendees.module').then(
                                (m) => m.AttendeesModule
                            ),
                    },
                    {
                        path: 'settings',
                        canActivate: [ProjectManagerGuard],
                        loadChildren: () =>
                            import('./main/settings/settings.module').then(
                                (m) => m.SettingsModule
                            ),
                    },
                    {
                        path: 'sync',
                        canActivate: [MainSetNavigationGuard],
                        loadChildren: () =>
                            import('./main/sync/sync.module').then(
                                (m) => m.SyncModule
                            ),
                    },
                ],
            },
            {
                path: 'about',
                loadChildren: () =>
                    import('./main/about/about.module').then(
                        (m) => m.AboutModule
                    ),
            },
            {
                path: 'global-boilerplates',
                canActivate: [ProjectManagerGuard],
                loadChildren: () =>
                    import(
                        './main/global-boilerplates/global-boilerplates.module'
                    ).then((m) => m.GlobalBoilerplatesModule),
            },
            {
                path: '**',
                redirectTo: '/projects',
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
