import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoilerPlate } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import * as fromCrafts from '../crafts/crafts.selectors';
import * as fromGlobalBoilerplates from './global-boilerplates.reducer';
import { GlobalBoilerplatesState } from './global-boilerplates.reducer';
import { sortByName } from '../crafts/crafts.reducer';

export const selectGlobalBoilerplatesState = createFeatureSelector<
    GlobalBoilerplatesState
>('global-boilerplates');

export const getAllBoilerplates = createSelector(
    selectGlobalBoilerplatesState,
    fromGlobalBoilerplates.selectAll
);

export const getGlobalBoilerplatesEntities = createSelector(
    selectGlobalBoilerplatesState,
    fromGlobalBoilerplates.selectEntities
);
export const getGlobalBoilerplates = createSelector(
    selectGlobalBoilerplatesState,
    (state) => state.entities
);

export const getBoilerplatesLoaded = createSelector(
    selectGlobalBoilerplatesState,
    (state) => state.loaded
);

export const getLocationById = createSelector(
    getGlobalBoilerplatesEntities,
    (entities: Dictionary<BoilerPlate>, props: { id: string }) =>
        entities[props.id]
);

export const getBoilerplatesIds = createSelector(
    selectGlobalBoilerplatesState,
    fromGlobalBoilerplates.selectIds
);

export const getGlobalBoilerplateByCraftId = createSelector(
    getAllBoilerplates,
    (boilerplates: BoilerPlate[], props) => {
        if ((!props.id && props.id !== 0) || !boilerplates) {
            return undefined;
        }

        return boilerplates.find(
            (boilerPlate) => boilerPlate.title === props.id.toString()
        );
    }
);

// actually gets by the translation id, not the craft boilerplate id
export const getGlobalBoilerplatesByCraftId = (craftId) =>
    createSelector(getAllBoilerplates, (bps: BoilerPlate[]): BoilerPlate[] => {
        // Craft ID for Boilerplates
        // If no Craft ID given, return []
        if (!craftId && craftId !== 0) {
            return [];
        }
        const parentBp = bps.find((b) => b.title === craftId.toString());
        if (!parentBp) {
            return [];
        }

        return bps
            .filter((b) => !AcceptUtils.isDefaultGuid(b.parentID) && b.parentID) // remove other "root" nodes
            .map((b) => {
                // need to delete parentIDs as this will be the new root level
                return {
                    ...b,
                    parentID: b.parentID === parentBp.id ? '' : b.parentID,
                };
            });
    });

export const getGlobalBoilerplatesByParentId = createSelector(
    getAllBoilerplates,
    (boilerplates, props) => {
        if (!props.id && props.id !== 0) {
            return [];
        }

        return boilerplates.filter((boilerplate) => {
            if (!boilerplate.parentID) {
                return false;
            }
            return boilerplate.parentID === props.id;
        });
    }
);

export const getLoadingStatus = createSelector(
    selectGlobalBoilerplatesState,
    (state) => {
        return state.loading;
    }
);

export const getCraftIdsForGlobalBoilerplates = createSelector(
    getAllBoilerplates,
    fromCrafts.getAllCrafts,
    (boilerplates, crafts) =>
        crafts
            .map((craft) => craft.id)
            .filter((id) => boilerplates.find((item) => item.title === id + ''))
);
export const getGlobalBoilerplateCrafts = createSelector(
    fromCrafts.getAllCrafts,
    getCraftIdsForGlobalBoilerplates,
    (crafts, craftIds) => {
        craftIds
            .map((projectCraftId) =>
                crafts.find((craft) => craft.id === projectCraftId)
            )
            .sort(sortByName);
    }
);

export const getGlobalBoilerplateById = createSelector(
    getAllBoilerplates,
    (boilerplates, props) => {
        if (!props || (!props.id && props.id !== 0)) {
            return undefined;
        }

        return boilerplates.find((item) => item.id === props.id);
    }
);
export const getSelectedGlobalBoilerplates = createSelector(
    selectGlobalBoilerplatesState,
    (state) => {
        return state.selectedBoilerplatesId;
    }
);
