import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationsEffects } from './locations.effects';
import * as fromLocations from './locations.reducers';

@NgModule({
    imports: [
        MatSnackBarModule,
        StoreModule.forFeature('locations', fromLocations.reducer),
        EffectsModule.forFeature([LocationsEffects])
    ]
})
export class LocationsStoreModule {}
