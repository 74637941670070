import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Contact, ExternalContact } from 'app/core/rest-api';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    CreateContactFromExternalContactError,
    CreateContactFromExternalContactRequest,
    CreateContactFromExternalContactSuccess,
    ExternalContactsActionTypes,
    LoadExternalContactsError,
    LoadExternalContactsRequested,
    LoadExternalContactsSuccess,
    ReloadExternalContactsRequested,
    UpsertExternalContactsStart,
} from './external-contacts.actions';
import { ExternalContactsState } from './external-contacts.reducer';
import { ErrorUtils } from 'app/core/utils/error-util';
import { ExternalContactService } from 'app/core/rest-api/api/external-contact.service';

@Injectable()
export class ExternalContactsEffects {
    @Effect()
    loadExternalContacts$ = this.actions$.pipe(
        ofType<LoadExternalContactsRequested>(
            ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_REQUESTED
        ),
        switchMap((action) => {
            const { searchTerm, searchType, craftId } = action.payload;
            return this.externalContactService
                .getExternalContacts(searchTerm, searchType, craftId)
                .pipe(
                    map((response) => {
                        const contacts = (response.data as ExternalContact[]).map(
                            (c: ExternalContact) => {
                                return {
                                    ...c,
                                    fullnameWithCompany: c.companyName
                                        ? `${c.fullname}, ${c.companyName}`
                                        : c.fullname,
                                };
                            }
                        );

                        return new LoadExternalContactsSuccess({
                            contacts: contacts,
                        });
                    }),
                    catchError((error) =>
                        of(new LoadExternalContactsError({ error }))
                    )
                );
        })
    );

    @Effect()
    loadExternalContactsSuccess$ = this.actions$.pipe(
        ofType<LoadExternalContactsSuccess>(
            ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_SUCCESS
        ),
        map((action) => action.payload.contacts),
        switchMap((contacts) =>
            of(new UpsertExternalContactsStart({ contacts }))
        )
    );

    @Effect()
    errorLoadContacts$ = this.actions$.pipe(
        ofType<LoadExternalContactsError>(
            ExternalContactsActionTypes.LOAD_EXTERNAL_CONTACTS_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'CONTACTS.LOAD');
            return of();
        })
    );

    @Effect()
    createContactFromExternalContact$ = this.actions$.pipe(
        ofType<CreateContactFromExternalContactRequest>(
            ExternalContactsActionTypes.CREATE_CONTACT_FROM_EXTERNAL_CONTACT_REQUEST
        ),
        switchMap(({ payload }) => {
            const model = payload.contact as ExternalContact;
            return this.externalContactService
                .createContactFromExternalContact(model)
                .pipe(
                    map(
                        (response) =>
                            new CreateContactFromExternalContactSuccess({
                                contact: response.data as ExternalContact,
                            })
                    ),
                    catchError((error) =>
                        of(new CreateContactFromExternalContactError({ error }))
                    )
                );
        })
    );

    @Effect()
    reloadExternalContactsRequested$ = this.actions$.pipe(
        ofType<ReloadExternalContactsRequested>
            (ExternalContactsActionTypes.RELOAD_EXTERNAL_CONTACTS_REQUESTED),
        switchMap((action) => {
            return of(new LoadExternalContactsRequested(action.payload));
        })
    )

    constructor(
        private actions$: Actions,
        private externalContactService: ExternalContactService,
        private store: Store<ExternalContactsState>,
        private errorUtils: ErrorUtils
    ) {}
}
