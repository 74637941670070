import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { ProjectDocType } from './project.document';
import {
    customAttributeTemplatesApiToSchema,
    getAttachmentIdsFromProject,
    hasUntrackedChanges,
    checklistTemplatesApiToSchema,
} from '../utils/project-utils';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectDbService extends EntityDbService<Project, ProjectDocType> {
    apiItemToSchema(project: Project): ProjectDocType {
        const {
            address,
            city,
            country,
            creationDate,
            dueDate,
            id,
            markedAsDelete,
            postCode,
            serialLetters,
            startDate,
            title,
            updateDateTime,
            photo,
            customAttributeTemplateIds,
            customAttributeTemplates,
            checklistTemplatesIds,
            checklistTemplates,
        } = project;
        const craftExternalResponsableId = '';
        const craftInternalResponsableId = '';
        return {
            address,
            city,
            country,
            craftExternalResponsableId,
            craftInternalResponsableId,
            creationDate,
            dueDate,
            id,
            markedAsDelete,
            postCode,
            serialLetters,
            startDate,
            title,
            updateDateTime,
            photo,
            customAttributeTemplateIds,
            customAttributeTemplates: customAttributeTemplatesApiToSchema(
                customAttributeTemplates
            ),
            checklistTemplatesIds,
            checklistTemplates: checklistTemplatesApiToSchema(
                checklistTemplates
            ),
        };
    }
    getAttachmentIdsFromApiItem(project: Project): string[] {
        return getAttachmentIdsFromProject(project);
    }
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Projects
        );
    }

    hasUntrackedChanges(
        doc: Project & PouchDB.Core.AllDocsMeta,
        entity: ProjectDocType
    ): boolean {
        return hasUntrackedChanges(doc, entity);
    }
}
