import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { FileInfo } from './file-info';
import { AssetDownloadService } from './../../services/asset-download.service';

@Component({
    selector: 'acc-asset-tile',
    templateUrl: './asset-tile.component.html',
    styleUrls: ['./asset-tile.component.scss'],
})
export class AssetTileComponent implements OnInit, OnChanges {
    @Input()
    thumb: string;

    @Input()
    hideMenuButton = false;

    @Input()
    src: string;

    @Input()
    label: string;

    @Input()
    isPlan = false;

    @Input()
    isFile = false;

    @Input()
    fileInfos: FileInfo;

    @Input()
    available = true;

    @Output()
    assetDelete: EventEmitter<void> = new EventEmitter();

    constructor(public assetDownloadService: AssetDownloadService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {}

    delete(): void {
        this.assetDelete.emit();
    }

    downloadAllowed(): boolean {
        return !this.isPlan && this.assetDownloadService.downloadAllowed;
    }

    download(): void {
        const downloadNotAllowed = !this.downloadAllowed();
        if (!this.src || downloadNotAllowed) {
            return;
        }

        window.open(this.src, '_blank');
    }
}
