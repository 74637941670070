import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Project } from 'app/core/rest-api';
import { getSearch } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import * as fromProjects from './projects.reducer';
import { getCurrentProjectTemplate } from '../project-templates/project-templates.selectors';

export const selectProjectsState = createFeatureSelector<
    fromProjects.ProjectsState
>('projects');

export const selectAllProjects = createSelector(
    selectProjectsState,
    fromProjects.selectAll
);

export const selectAllActiveProjects = createSelector(
    selectAllProjects,
    (projectsState) =>
        projectsState.filter((project) => !project.markedAsDelete)
);

export const selectFilteredProjects = createSelector(
    selectProjectsState,
    selectAllProjects,
    getSearch,
    (projectsState, projects, searchQuery) =>
        projects
            .filter((project) => {
                if (projectsState.withArchived) {
                    return true;
                } else {
                    return !project.markedAsDelete;
                }
            })
            .filter((project) => {
                if (searchQuery) {
                    return project.title
                        .toLowerCase()
                        .match(`.*${searchQuery.toLowerCase()}.*`);
                } else {
                    return true;
                }
            })
);

export const selectActiveFilteredProjects = createSelector(
    selectAllActiveProjects,
    getSearch,
    (projectsState, searchQuery) =>
        projectsState.filter((project) => {
            if (searchQuery) {
                return project.title
                    .toLowerCase()
                    .match(`.*${searchQuery.toLowerCase()}.*`);
            } else {
                return true;
            }
        })
);

export const selectAllArchivedProjects = createSelector(
    selectAllProjects,
    (projectsState) => projectsState.filter((project) => project.markedAsDelete)
);

export const selectProjectById = (projectId: string) =>
    createSelector(
        selectProjectsState,
        (projectsState) => projectsState.entities[projectId]
    );

export const getProjectsByIds = (projectIds: string[]) =>
    createSelector(selectAllProjects, (projects) =>
        projects.filter((project) => projectIds.includes(project.id))
    );

export const selectAllProjectEntities = createSelector(
    selectProjectsState,
    fromProjects.selectEntities
);

export const getLoading = createSelector(
    selectProjectsState,
    (state) => state.loading
);

export const getLoaded = createSelector(
    selectProjectsState,
    (state) => state.loaded
);

export const getCurrentProject = createSelector(
    selectProjectsState,
    getCurrentProjectId,
    (state, id) => {
        if (state && state.entities) {
            return state.entities[id];
        } else {
            return { timeZoneId: '' };
        }
    }
);

export const getSelectedProjects = createSelector(
    selectProjectsState,
    (state) => state.selected
);

export const getProjectIds = createSelector(
    selectProjectsState,
    fromProjects.selectIds
);

export const getTotalProjectCount = createSelector(
    selectProjectsState,
    (state) => {
        if (state.withArchived) {
            return state.ids.length;
        } else {
            return (state.ids as string[]).filter(
                (id) => !state.entities[id].markedAsDelete
            ).length;
        }
    }
);

export const getProjectById = createSelector(
    selectAllProjects,
    (projects: Project[], props) => projects.find((p) => p.id === props.id)
);

export const getCurrentProjectOrTemplate = createSelector(
    getCurrentProject,
    getCurrentProjectTemplate,
    (project, projectTemplate) => {
        if (project) {
            return project;
        }
        return projectTemplate;
    }
);

export const getMarkedPlansInfo = createSelector(
    selectProjectsState,
    (state) => state.markedPlansInfo
)
