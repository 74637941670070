import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { untilDestroy } from '@ngrx-utils/store';
import { select, Store } from '@ngrx/store';
import { getMaxRoleCurrentProject } from 'app/store/auth/auth.selectors';
import { ProjectsState } from 'app/store/projects/projects.reducer';
import { getCurrentProjectId } from 'app/store/router/router.selectors';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit, OnDestroy {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    projectId: string;
    currentUsersMaxProjectRole = 0;

    constructor(
        private cdr: ChangeDetectorRef,
        private fuseNavService: FuseNavigationService,
        private store: Store<ProjectsState>
    ) {
        this.store
            .pipe(
                select(getCurrentProjectId),
                untilDestroy(this),
                tap((projectId: string) => {
                    this.projectId = projectId;
                })
            )
            .subscribe();

        this.store
            .pipe(
                untilDestroy(this),
                select(getMaxRoleCurrentProject),
                tap(maxRole => (this.currentUsersMaxProjectRole = maxRole))
            )
            .subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation =
            this.navigation || this.fuseNavService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this.fuseNavService.onNavigationChanged
            .pipe(untilDestroy(this))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this.fuseNavService.getCurrentNavigation();

                // Mark for check
                this.cdr.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this.fuseNavService.onNavigationItemAdded,
            this.fuseNavService.onNavigationItemUpdated,
            this.fuseNavService.onNavigationItemRemoved
        )
            .pipe(untilDestroy(this))
            .subscribe(() => {
                // Mark for check
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        // do not remove
    }
}
