import * as fromLetters from './letters.reducers.ts';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LettersState } from './letters.reducers.ts';
import { RTFTemplate } from 'app/core/rest-api';

export const selectLettersState = createFeatureSelector<LettersState>('letters');

export const getAllLetters = createSelector(selectLettersState, fromLetters.selectAll);

export const getLettersEntities = createSelector(selectLettersState, fromLetters.selectEntities);

export const getLettersLoading = createSelector(
    selectLettersState,
    (state) => state.loading
);

export const getLetterById = createSelector(
    getLettersEntities,
    (entities, props) => entities[props.id]
);

export const getIssueLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => letter.purpose === RTFTemplate.PurposeEnum.Issues)
);

export const getIssueRTFLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.Issues && letter.templateType === 'RTFTemplate'))
);

export const getIssuePredefinedFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.Issues && letter.templateType === 'PredefinedFormLetterTemplate'))
);

export const getIssueFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.Issues && letter.templateType === 'FormLetterTemplate'))
);

export const getDiaryLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => letter.purpose === RTFTemplate.PurposeEnum.DailyLog)
);

// TODO: refactor this to be common

export const getDiaryRTFLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.DailyLog && letter.templateType === 'RTFTemplate'))
);

export const getDiaryPredefinedFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.DailyLog && letter.templateType === 'PredefinedFormLetterTemplate'))
);

export const getDiaryFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.purpose === RTFTemplate.PurposeEnum.DailyLog && letter.templateType === 'FormLetterTemplate'))
);

export const getLettersIds = createSelector(selectLettersState, fromLetters.selectIds);

export const getSelectedLetter = createSelector(selectLettersState, state => state.selectedLetter);

export const getTemplateSourceLetter = (templateSourceId: string) =>
  createSelector(
      getAllLetters,
     (letters) =>  letters.find(letter => letter.id === templateSourceId)
  );

export const getAllPredefinedFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.templateType === 'PredefinedFormLetterTemplate'))
);

export const getAllCustomFormLetters = createSelector(
    getAllLetters,
    letters => letters.filter(letter => (letter.templateType === 'FormLetterTemplate'))
);
