import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'acc-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    dataSource = new MatTableDataSource();
    selection = new SelectionModel();

    @Input()
    items: any[];

    @Input()
    pageSizeOptions = [[5, 10, 20]];

    @Input()
    title: string;

    constructor() {
        // SET VALUE
    }

    ngOnInit(): void {}
}
