import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ChecklistsActionTypes, CreateChecklistError, CreateChecklistRequested, CreateChecklistSuccess, DeleteChecklistError, DeleteChecklistRequested, DeleteChecklistSuccess, LoadChecklistsError, LoadChecklistsRequested, LoadChecklistsSuccess, UpdateChecklistError, UpdateChecklistRequested, UpdateChecklistSuccess } from "./checklists.actions";
import { ChecklistsState } from "./checklists.reducer";
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ChecklistTemplateService } from 'app/core/rest-api';
import { of } from "rxjs";
import { select, Store } from "@ngrx/store";
import { getCurrentProjectId } from "../router/router.selectors";

@Injectable()
export class ChecklistsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ChecklistsState>,
        private checklistTemplateService: ChecklistTemplateService,
    ) {}

    @Effect()
    loadChecklists$ = this.actions$.pipe(
        ofType<LoadChecklistsRequested>(ChecklistsActionTypes.LOAD_CHECKLISTS_REQUESTED),
        map((action) => action.payload),
        mergeMap(({ projectId }) =>
            this.checklistTemplateService
                .projectsByProjectIdSettingsChecklisttemplateGet(projectId)
                .pipe(
                    map((response) => response.data),
                    map((checklists) => new LoadChecklistsSuccess({ checklists })),
                    catchError((error) => of(new LoadChecklistsError({ error })))
                )
        )
    );

    @Effect()
    updateChecklist$ = this.actions$.pipe(
        ofType<UpdateChecklistRequested>(ChecklistsActionTypes.UPDATE_CHECKLIST_REQUESTED),
        map((action) => action.payload),
        mergeMap(({ checklist }) =>
            this.checklistTemplateService
                .projectsByProjectIdSettingsChecklisttemplateByIdPatch(checklist.id, checklist.projectId, checklist)
                .pipe(
                    map((response) => response.data),
                    map((checklist) => new UpdateChecklistSuccess({ checklist })),
                    catchError((error) => of(new UpdateChecklistError({ error })))
                )
        )
    );

    @Effect()
    createChecklist$ = this.actions$.pipe(
        ofType<CreateChecklistRequested>(ChecklistsActionTypes.CREATE_CHECKLIST_REQUESTED),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([{ checklist }, projectId]) =>
            this.checklistTemplateService
                .projectsByProjectIdSettingsChecklisttemplatePost(projectId, checklist)
                .pipe(
                    map((response) => response.data),
                    map((checklist) => new CreateChecklistSuccess({ checklist })),
                    catchError((error) => of(new CreateChecklistError({ error })))
                )
        )
    );

    @Effect()
    deleteChecklist$ = this.actions$.pipe(
        ofType<DeleteChecklistRequested>(ChecklistsActionTypes.DELETE_CHECKLIST_REQUESTED),
        map((action) => action.payload),
        mergeMap(({ checklist }) =>
            this.checklistTemplateService
                .projectsByProjectIdSettingsChecklisttemplateByIdDelete(checklist.id, checklist.projectId)
                .pipe(
                    map((response) => response.data),
                    map((checklist) => new DeleteChecklistSuccess({ checklist })),
                    catchError((error) => of(new DeleteChecklistError({ error })))
                )
        )
    );
}
