import { Action } from '@ngrx/store';
import {
    NotificationsActions,
    NotificationsActionTypes
} from './notifications.actions';
import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    Update
} from '@ngrx/entity';
import { Notification } from 'app/core/rest-api';

const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
    sortComparer: sortByDatetimeDescending
});

export function sortByDatetimeDescending(
    a: Notification,
    b: Notification
): number {
    if (a.creationDate > b.creationDate) {
        return -1;
    } else if (a.creationDate < b.creationDate) {
        return 1;
    } else {
        return 0;
    }
}

export interface NotificationsState extends EntityState<Notification> {
    loaded: boolean;
}

export const initialState: NotificationsState = adapter.getInitialState({
    loaded: false
});

export function reducer(
    state = initialState,
    action: NotificationsActions
): NotificationsState {
    switch (action.type) {
        case NotificationsActionTypes.LOAD_NOTIFICATIONS_SUCCESS:
            return adapter.addMany(action.payload.notifications, {
                ...state,
                loaded: true
            });

        case NotificationsActionTypes.ADD_NOTIFICATION:
            return adapter.addOne(action.payload.notification, { ...state });

        case NotificationsActionTypes.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
            const notifications = action.payload.notifications;
            const updates: Update<Notification>[] = [];

            notifications.forEach(notification => {
                updates.push({ id: notification.id, changes: notification });
            });

            return adapter.updateMany(updates, { ...state });

        case NotificationsActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS:
            return adapter.removeOne(action.payload.deletedNotification.id, { ...state });

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
