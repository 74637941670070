import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

import { CoreState } from 'app/store/core/core.reducer';
import { getIsDevice } from 'app/store/core/core.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetDownloadService {
    downloadAllowed = false;

    constructor(private store: Store<CoreState>) {
        this.store
            .pipe(
                select(getIsDevice),
                take(1),
                tap((isDevice) => {
                    this.downloadAllowed = !isDevice;
                })
            )
            .subscribe();
    }
}
