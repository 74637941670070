import { CommandDocTypeV0, CommandDocTypeV1 } from './command.document';

function addTimestampCreated(oldDoc: CommandDocTypeV0): CommandDocTypeV1 {
    const newDoc: CommandDocTypeV1 = {
        ...oldDoc,
        timestampCreated: new Date().getTime(),
    };

    return newDoc;
}

export const commandMigrations = {
    1: addTimestampCreated,
};
