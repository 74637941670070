import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';
import {
    createContactSalesCTAUrlFrom,
    createGetHelpCTAUrlFrom,
} from 'app/shared/services/demo/cta-links';
import { Router } from '@angular/router';

export const navigation: FuseNavigation[] = [
    {
        id: 'order',
        title: 'Order',
        translate: 'NAV.ORDER.TITLE',
        type: 'item',
        classes: 'menu-item-order',
        externalUrl: true,
        openInNewTab: true,
        url: createContactSalesCTAUrlFrom('navigation-menu'),
        hidden: true,
    },
    {
        id: 'projects',
        title: 'Projects',
        translate: 'NAV.PROJECTS.TITLE',
        type: 'item',
        icon: 'home',
        url: '/projects',
        exactMatch: true,
    },
    {
        id: 'issues',
        hideWithoutProject: true,
        title: 'Issues',
        translate: 'NAV.ISSUES.TITLE',
        type: 'item',
        icon: 'bar_chart',
        url: '/issues',
    },
    {
        id: 'diary',
        title: 'Construction Log',
        hideWithoutProject: true,
        translate: 'NAV.DIARY.TITLE',
        type: 'item',
        icon: 'question_answer',
        url: '/diary',
    },
    {
        id: 'buildings',
        title: 'Buildings',
        hideWithoutProject: true,
        translate: 'NAV.BUILDINGS.TITLE',
        type: 'item',
        icon: 'location_city',
        url: '/buildings',
        hideXs: true,
        minimumRole: environment.roleNumbers.PROJECT_MANAGER,
    },
    {
        id: 'users',
        title: 'Users',
        hideWithoutProject: true,
        translate: 'NAV.USERS.TITLE',
        type: 'item',
        icon: 'person_outline',
        url: '/users',
        hideXs: true,
    },
    {
        id: 'settings',
        title: 'Settings',
        hideWithoutProject: true,
        translate: 'NAV.SETTINGS.TITLE',
        type: 'item',
        icon: 'settings',
        minimumRole: environment.roleNumbers.PROJECT_MANAGER,
        url: '/settings',
        hideMobile: true,
    },
    {
        id: 'sync',
        title: 'Sync',
        hideWithoutProject: true,
        translate: 'NAV.SYNC.TITLE',
        type: 'item',
        icon: 'refresh',
        url: '/sync',
    },
    {
        id: 'sync-all-projects',
        title: 'Sync',
        hideWithoutProject: false,
        hideWithProject: true,
        translate: 'NAV.SYNC.TITLE',
        type: 'item',
        icon: 'refresh',
        url: '/sync',
        exactMatch: true,
    },
    {
        id: 'about',
        title: 'About',
        translate: 'NAV.ABOUT.TITLE',
        type: 'item',
        icon: 'settings',
        url: '/about',
        hideGtXs: true,
        hideXs: true,
    },
    {
        id: 'help',
        title: 'Help',
        translate: 'NAV.HELP.TITLE',
        type: 'item',
        icon: 'help',
        externalUrl: true,
        openInNewTab: true,
        url: createGetHelpCTAUrlFrom('navigation-menu'),
        hidden: true,
    },
    {
        id: 'boilerplates',
        title: 'Global Boilerplates',
        translate: 'NAV.SHARED_BOILERPLATES',
        type: 'item',
        icon: 'settings',
        minimumRole: environment.roleNumbers.PROJECT_MANAGER,
        function: (router?: Router) =>
            router?.navigateByUrl('/global-boilerplates', { replaceUrl: true }),
    },
    {
        id: 'backlink',
        title: 'untermStrich',
        hideWithoutProject: true,
        hideWithProject: false,
        translate: 'NAV.BACKLINK.TITLE',
        type: 'item',
        url: null,
        imagePath: 'assets/images/custom/u-strich-logo.png',
        externalUrl: true,
        openInNewTab: true,
    },
    // {
    //     id: 'archive',
    //     title: 'Archive',
    //     translate: 'NAV.ARCHIVE.TITLE',
    //     type: 'item',
    //     icon: 'receipt',
    //     url: '/archive'
    // },
];
