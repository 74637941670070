import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    FormLetterService,
    PredefinedFormLetterTemplateService,
    ProjectSettingsService,
    RTFTemplate,
    SerialLetterService,
} from 'app/core/rest-api';
import { forkJoin, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { getIsOnline } from '../core/core.selectors';
import {
    CreateLetter,
    CreateLetterError,
    CreateLetterSuccess,
    CreateRtfLetter,
    DeleteLetter,
    DeleteLetterError,
    DeleteLetterSuccess,
    LettersActionTypes,
    LoadLettersError,
    LoadLettersRequested,
    LoadLettersSuccess,
    UpdateLetter,
    UpdateLetterError,
    UpdateLetterSuccess,
} from './letters.actions';
import { LettersState } from './letters.reducers.ts';
import { ErrorUtils } from 'app/core/utils/error-util';
import { getCurrentProjectId } from '../router/router.selectors';
import {
    isFormLetterTemplate,
    isPredefinedFormLetterTemplate,
} from 'app/shared/components/print-dialog/common-template';

@Injectable()
export class LettersEffects {
    constructor(
        private actions$: Actions,
        private store: Store<LettersState>,
        private projectSettingsService: ProjectSettingsService,
        private serialLetterService: SerialLetterService,
        private errorUtils: ErrorUtils,
        private formLetterService: FormLetterService,
        private predefinedFormLetterTemplateService: PredefinedFormLetterTemplateService
    ) {}

    @Effect()
    loadLetters$ = this.actions$.pipe(
        ofType<LoadLettersRequested>(LettersActionTypes.LOAD_LETTERS_REQUESTED),
        withLatestFrom(this.store.pipe(select(getIsOnline))),
        filter(([action, isOnline]) => isOnline),
        map(([action, isOnline]) => action.payload),
        mergeMap(({ projectId }) =>
            forkJoin({
                // todo if really needed add Issue/this.dialogData.purpose in get of predefined / or create selector
                predefinedTemplates: this.predefinedFormLetterTemplateService
                    .predefinedformlettertemplatePredefinedFormLetterTemplatesGet()
                    .pipe(map((response) => response.data)),
                htmlTemplates: this.formLetterService
                    .formletterFormLetterTemplatesByProjectIdGet(projectId)
                    .pipe(map((response) => response.data)),
                rtfTemplates: this.projectSettingsService
                    .projectsByProjectIdSettingsSeriallettersGet(projectId)
                    .pipe(map((response) => response.data)),
            }).pipe(
                map(
                    ({ predefinedTemplates, htmlTemplates, rtfTemplates }) =>
                        new LoadLettersSuccess({
                            letters: [
                                ...predefinedTemplates,
                                ...htmlTemplates,
                                ...rtfTemplates,
                            ],
                        })
                ),
                catchError((error) => of(new LoadLettersError({ error })))
            )
        )
    );

    @Effect()
    loadLettersError$ = this.actions$.pipe(
        ofType<LoadLettersError>(LettersActionTypes.LOAD_LETTERS_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'LETTERS.LOAD');
            return of();
        })
    );

    @Effect()
    createLetter$: Observable<
        CreateLetterSuccess | CreateLetterError
    > = this.actions$.pipe(
        ofType<CreateLetter>(LettersActionTypes.CREATE_LETTER_REQUESTED),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([{ letter }, projectId]) => {
            if (isFormLetterTemplate(letter)) {
                return this.formLetterService
                    .formletterFormLetterTemplatesPost({
                        ...letter,
                        projectId,
                        // prevent patching the full objects, this causes weird
                        // validation issues in some cases
                        owner: undefined,
                        creator: undefined,
                        updater: undefined,
                    })
                    .pipe(
                        map(
                            (response): CreateLetterSuccess =>
                                new CreateLetterSuccess({
                                    letter: response?.data,
                                })
                        ),

                        catchError((error) =>
                            of(new CreateLetterError({ error }))
                        )
                    );
            }
            return of(
                new CreateLetterError({ error: 'not a FormLetterTemplate' })
            );
        })
    );

    @Effect({ dispatch: false })
    createLetterError$ = this.actions$.pipe(
        ofType<CreateLetterError>(LettersActionTypes.CREATE_LETTER_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LETTERS.ERROR_CREATE_LETTER_TEMPLATE'
            );
            return of();
        })
    );

    @Effect()
    createRtfLetter$: Observable<
        CreateLetterSuccess | CreateLetterError
    > = this.actions$.pipe(
        ofType<CreateRtfLetter>(LettersActionTypes.CREATE_RTF_LETTER_REQUESTED),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([{ file, letter }, projectId]) => {
            return this.serialLetterService
                .serialletterPost(
                    file,
                    letter.purpose,
                    JSON.stringify((letter as RTFTemplate).customKeyValuePairs),
                    letter.title
                )
                .pipe(
                    map((response) => response.data),
                    switchMap((createdLetter) =>
                        this.projectSettingsService
                            .projectsByProjectIdSettingsSeriallettersByLetterIdPost(
                                createdLetter.id,
                                projectId
                            )
                            .pipe(
                                map(() => {
                                    return new CreateLetterSuccess({
                                        letter: createdLetter,
                                    });
                                })
                            )
                    ),
                    catchError((error) => of(new CreateLetterError({ error })))
                );
        })
    );

    @Effect()
    deleteLetter$: Observable<
        DeleteLetterSuccess | DeleteLetterError
    > = this.actions$.pipe(
        ofType<DeleteLetter>(LettersActionTypes.DELETE_LETTER_REQUESTED),
        map((action) => action.payload),
        switchMap(({ letter }) => {
            if (isFormLetterTemplate(letter)) {
                return this.formLetterService
                    .formletterFormLetterTemplatesByTemplateIdDelete(letter.id)
                    .pipe(
                        map((response) => new DeleteLetterSuccess({ letter })),
                        catchError((error) =>
                            of(new DeleteLetterError({ error }))
                        )
                    );
            }

            if (isPredefinedFormLetterTemplate(letter)) {
                return of(new DeleteLetterError({
                    error: new Error('trying to delete predefined form letter'),
                }));
            }

            return this.serialLetterService
                .serialletterByLetteridDelete(letter.id)
                .pipe(
                    map((response) => new DeleteLetterSuccess({ letter })),
                    catchError((error) => of(new DeleteLetterError({ error })))
                );
        })
    );

    @Effect({ dispatch: false })
    deleteLetterError$ = this.actions$.pipe(
        ofType<DeleteLetterError>(LettersActionTypes.DELETE_LETTER_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LETTERS.ERROR_DELETE_LETTER_TEMPLATE'
            );
            return of();
        })
    );

    @Effect()
    updateLetter$: Observable<
        UpdateLetterSuccess | UpdateLetterError
    > = this.actions$.pipe(
        ofType<UpdateLetter>(LettersActionTypes.UPDATE_LETTER_REQUESTED),
        map((action) => action.payload),
        switchMap(({ letter }) => {
            if (isFormLetterTemplate(letter)) {
                return this.formLetterService
                    .formletterFormLetterTemplatesByTemplateIdPut(
                        letter.id,
                        {
                            ...letter,
                            // prevent patching the full objects, this causes weird
                            // validation issues in some cases
                            owner: undefined,
                            creator: undefined,
                            updater: undefined,
                        },
                    )
                    .pipe(
                        map((response) => {
                            return new UpdateLetterSuccess({
                                letter: response.data,
                            });
                        }),
                        catchError((error) =>
                            of(new UpdateLetterError({ error }))
                        )
                    );
            }

            if (isPredefinedFormLetterTemplate(letter)) {
                return of(new UpdateLetterError({
                    error: new Error('trying to update predefined form letter'),
                }));
            }

            return this.serialLetterService.serialletterPatch(letter).pipe(
                map((response) => {
                    return new UpdateLetterSuccess({ letter: response.data });
                }),
                catchError((error) => of(new UpdateLetterError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    updateLetterError$ = this.actions$.pipe(
        ofType<UpdateLetterError>(LettersActionTypes.UPDATE_LETTER_ERROR),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'LETTERS.ERROR_UPDATE_LETTER_TEMPLATE'
            );
            return of();
        })
    );
}
