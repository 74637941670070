import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackbarData } from './snackbar-data';

@Component({
    selector: 'acc-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnInit, SnackbarData {
    message = '';
    color = '';
    icon = '';
    spinner = false;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
        if (!data) {
            throw new Error('data for SnackbarComponent missing');
        }

        for (const [props, value] of Object.entries(data)) {
            this[props] = value;
        }
    }

    ngOnInit(): void {}
}
