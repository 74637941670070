/**
 * eTeacher API
 * Last Build: 02/08/2022 10:21:42 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ExternalContact {
    id?: string;
    ownerId?: string;
    owner?: ExternalContact;
    updaterId?: string;
    updater?: ExternalContact;
    creatorId?: string;
    creator?: ExternalContact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    firstname?: string;
    name: string;
    addressStreet?: string;
    addressPostCode?: string;
    addressState?: string;
    addressCity?: string;
    phone?: string;
    mobilePhone?: string;
    fax?: string;
    email?: string;
    website?: string;
    userId?: string;
    photo?: string;
    /**
     * If the company is set, it is a \"person\" that belongs to the specified company.  Otherwise (null) it is a company                Should not be the default GUID
     */
    externalContactId: string;
    externalCompanyId?: string;
    companyId?: string;
    company?: ExternalContact;
    companyName?: string;
    language: string;
    subscribedServices?: ExternalContact.SubscribedServicesEnum;
    roles?: Array<ExternalContact.RolesEnum>;
    crafts?: Array<number>;
    gender?: number;
    fullname?: string;
    fullnameWithCompany?: string;
}
export namespace ExternalContact {
    export type SubscribedServicesEnum = 'Email' | 'SignalR' | 'Fcm' | 'Web';
    export const SubscribedServicesEnum = {
        Email: 'Email' as SubscribedServicesEnum,
        SignalR: 'SignalR' as SubscribedServicesEnum,
        Fcm: 'Fcm' as SubscribedServicesEnum,
        Web: 'Web' as SubscribedServicesEnum,
    };
    export type RolesEnum =
        | 'Normal'
        | 'CraftResponsible'
        | 'ProjectManager'
        | 'Admin'
        | 'SuperAdmin';
    export const RolesEnum = {
        Normal: 'Normal' as RolesEnum,
        CraftResponsible: 'CraftResponsible' as RolesEnum,
        ProjectManager: 'ProjectManager' as RolesEnum,
        Admin: 'Admin' as RolesEnum,
        SuperAdmin: 'SuperAdmin' as RolesEnum,
    };
}
