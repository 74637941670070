import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { ProjectTemplatesEffects } from './project-templates.effects';
import * as fromProjectTemplates from './project-templates.reducer';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(
            'project-templates',
            fromProjectTemplates.reducer
        ),
        EffectsModule.forFeature([ProjectTemplatesEffects]),
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class ProjectTemplatesStoreModule {}
