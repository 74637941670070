import { Preferences } from '@capacitor/preferences';
import { Injectable } from '@angular/core';

import { Conflict } from 'app/main/models/conflict';
import { Issue } from 'app/core/rest-api';
import { DailyLogEntryBase } from 'app/core/rest-api/model/dailyLogEntryBase';

import {
    ConflictDocType,
    IssueConflictDocType,
    ConflictTypeDocType,
    DailyLogEntryBaseConflictDocType,
} from './conflict.document';

import { IssueDbService } from '../issues/issue.db.service';
import { DiaryDbService } from '../diary/diary.db.service';

const CONFLICTS_KEY = 'conflicts';

export abstract class ConflictStorageService<T> {
    abstract keySuffix: string;

    constructor() {}

    private get key(): string {
        return `${CONFLICTS_KEY}_${this.keySuffix}`;
    }

    protected async _setAll(docs: ConflictDocType[]): Promise<void> {
        await Preferences.set({
            key: this.key,
            value: JSON.stringify(docs),
        });
    }

    protected async _getAll(): Promise<ConflictDocType[]> {
        const { value } = await Preferences.get({ key: this.key });
        if (value) {
            return JSON.parse(value);
        } else {
            // no conflicts saved
            return [];
        }
    }

    abstract getAll(): Promise<Conflict<T>[]>;
    abstract setAll(conflicts: Conflict<T>[]): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class IssueConflictStorageService extends ConflictStorageService<Issue> {
    keySuffix = 'issues';

    constructor(protected issueDbService: IssueDbService) {
        super();
    }

    async getAll(): Promise<Conflict<Issue>[]> {
        const allConflicts = await this._getAll();
        const issueConflicts = allConflicts
            .filter((conflict) => conflict.type === ConflictTypeDocType.Issue)
            .map((conflictDoc) => conflictDoc as IssueConflictDocType);

        return issueConflicts.map((conflictDoc) => {
            return {
                itemOnServer: conflictDoc.itemOnServer as Issue,
                itemWantUpdate: conflictDoc.itemWantUpdate as Issue,
                requiredUpdateDateTime: (conflictDoc.requiredUpdateDateTime as unknown) as Date,
            };
        });
    }

    async setAll(conflicts: Conflict<Issue>[]): Promise<void> {
        const docs = conflicts.map((conflict) => {
            // the requiredUpdateDateTime is actually a string but has the Date type
            // in the codebase
            return new IssueConflictDocType(
                conflict.itemOnServer.id,
                this.issueDbService.apiItemToSchema(conflict.itemOnServer),
                this.issueDbService.apiItemToSchema(conflict.itemWantUpdate),
                (conflict.requiredUpdateDateTime as unknown) as string
            );
        });

        await this._setAll(docs);
    }
}

@Injectable({ providedIn: 'root' })
export class DiaryConflictStorageService extends ConflictStorageService<
    DailyLogEntryBase
> {
    keySuffix = 'daily_log_entries';

    constructor(protected diaryDbService: DiaryDbService) {
        super();
    }

    async getAll(): Promise<Conflict<DailyLogEntryBase>[]> {
        const allConflicts = await this._getAll();
        const diaryConflicts = allConflicts
            .filter(
                (conflict) =>
                    conflict.type === ConflictTypeDocType.DailyLogEntry
            )
            .map(
                (conflictDoc) => conflictDoc as DailyLogEntryBaseConflictDocType
            );

        return diaryConflicts.map((conflictDoc) => {
            return {
                itemOnServer: conflictDoc.itemOnServer as DailyLogEntryBase,
                itemWantUpdate: conflictDoc.itemWantUpdate as DailyLogEntryBase,
                requiredUpdateDateTime: (conflictDoc.requiredUpdateDateTime as unknown) as Date,
            };
        });
    }

    async setAll(conflicts: Conflict<DailyLogEntryBase>[]): Promise<void> {
        const docs = conflicts.map((conflict) => {
            // the requiredUpdateDateTime is actually a string but has the Date type
            // in the codebase
            return new DailyLogEntryBaseConflictDocType(
                conflict.itemOnServer.id,
                conflict.itemOnServer,
                conflict.itemWantUpdate,
                (conflict.requiredUpdateDateTime as unknown) as string
            );
        });

        await this._setAll(docs);
    }
}
