<mat-form-field class="w-100-p">
    <mat-label>{{
        filterItem.labelTranslationIdentifier | translate
    }}</mat-label>
    <input
        matInput
        [(ngModel)]="filterItem.value"
        [maxlength]="filterItem.maxLength"
    />
</mat-form-field>
