import { Contact, Project } from 'app/core/rest-api';

export class ProjectModel implements Project {
    id?: string;
    timeZoneId: string;
    owner?: Contact;
    title?: string;
    photo?: string;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    dueDate?: Date;
    startDate?: Date;
    address?: string;
    postCode?: string;
    city?: string;
    country?: string;

    constructor() {
        // this.id = AcceptUtils.generateGuid();
        this.creationDate = new Date();
    }
}
