import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Preferences } from '@capacitor/preferences';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { LoadCraftsRequested } from 'app/store/crafts/crafts.actions';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SyncDiaryCommands } from '../diary/diary.actions';
import { SyncIssueCommands } from '../issues/issues.actions';
import { CoreActionTypes, SetLanguage, SetNetworkStatus } from './core.actions';

@Injectable()
export class CoreEffects {
    @Effect()
    networkStatus$ = this.actions$.pipe(
        ofType<SetNetworkStatus>(CoreActionTypes.SetNetworkStatus),
        map((action) => action.payload.status.connected),
        filter((connected) => connected),
        switchMap(() => [new SyncIssueCommands(), new SyncDiaryCommands()])
    );

    @Effect()
    setLanguage$ = this.actions$.pipe(
        ofType<SetLanguage>(CoreActionTypes.SetLanguage),
        map((action) => action.payload.language),
        switchMap((language) => {
            this.translate.use(language);
            this.dateAdapter.setLocale(language);
            return from(Preferences.set({ key: 'language', value: language })).pipe(
                // NEEDED TO TRIGGER TITLE TRANSLATION OF CRAFTS
                map(() => new LoadCraftsRequested())
            );
        })
    );

    constructor(
        private actions$: Actions,
        private translate: TranslateService,
        private dateAdapter: DateAdapter<any>
    ) {}
}
