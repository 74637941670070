import * as signalR from '@aspnet/signalr';
import { Store } from '@ngrx/store';
import { Notification } from 'app/core/rest-api';
import { environment } from 'environments/environment';
import { AddNotification } from '../../store/notifications/notifications.actions';

export class SignalR {
    constructor(private bearerToken: string, private store: Store<any>) {}

    listen(): signalR.HubConnection {
        const options = {
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: (): string => this.bearerToken,
            skipNegotiation: true
        };

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.basePath}/notificationHub`, options)
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        connection.on(
            'notification',
            (notification: Notification, message): void => {
                this.store.dispatch(
                    new AddNotification({ notification: notification })
                );
            }
        );

        connection.onclose = function(message): void {
            console.log('connection closed: ' + message);
        };

        connection
            .start()
            .catch(err =>
                console.log('connection exception: ' + (err || {}).toString())
            );

        return connection;
    }
}
