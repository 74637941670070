import { Vec2D } from './transform.model';


export interface DrawShapeArgs {
    /** Target canvas.  `strokeStyle`, `fillStyle, and `lineWidth` will likely be modified */
    ctx: CanvasRenderingContext2D;
    /** First reference point, in canvas's pixels */
    p1: Vec2D;
    /** Second reference point, in canvas's pixels */
    p2: Vec2D;
    /** Width, in the canas's pixels */
    lineWidth: CanvasPathDrawingStyles['lineWidth'];
    /** Color, as per canvas stokeStyle and fillStyle */
    stroke: CanvasFillStrokeStyles['strokeStyle'];
}

// type StrokeType = string;
export class DrawShape {
    /*
     * Appears to be unused, and incomplete (as p1 is not used), but the Pathing route might be useful in the future
     *
    static pathMarker(ctx: CanvasRenderingContext2D, p1: null, lineWidth: number, stroke: StrokeType): void {
        ctx.lineWidth = lineWidth;
        ctx.fillStyle = stroke;
        // tslint:disable-next-line: max-line-length
        const p = new Path2D(
            'M36.5,1.1C17.1,1.1,1.2,16.8,1.2,36.4c0,26.5,35.3,65.6,35.3,65.6s35.3-39.1,35.3-65.6C72,17,56.1,1.1,' + 
            '36.5,1.1C36.7,1.1,36.5,1.1,36.5,1.1z M36.5,49c-7,0-12.6-5.6-12.6-12.6s5.6-12.6,12.6-12.6 c7,0,12.6,' +
            '5.6,12.6,12.6S43.5,49,36.5,49z'
        );
        ctx.fill(p);
    }
    */

    static arrow(
        args: DrawShapeArgs,
        asLine: boolean = false,
    ): void {
        // starting path of the arrow from the start square to the end square and drawing the stroke
        args.ctx.beginPath();
        args.ctx.moveTo(args.p1.x, args.p1.y);
        args.ctx.lineTo(args.p2.x, args.p2.y);
        args.ctx.strokeStyle = args.stroke;
        args.ctx.lineWidth = args.lineWidth;
        args.ctx.stroke();

        if ( !asLine ) {
            // variables to be used when creating the arrow
            const headlen = 5 * args.lineWidth;
            const angle = Math.atan2(args.p2.y - args.p1.y, args.p2.x - args.p1.x);
            const deltaTheta = Math.PI / 7;

            // starting a new path from the head of the arrow to one of the sides of the point
            args.ctx.beginPath();
            args.ctx.moveTo(args.p2.x, args.p2.y);
            args.ctx.lineTo(
                args.p2.x - headlen * Math.cos(angle - deltaTheta),
                args.p2.y - headlen * Math.sin(angle - deltaTheta)
            );

            // path from the side point of the arrow, to the other side point
            args.ctx.lineTo(
                args.p2.x - headlen * Math.cos(angle + deltaTheta),
                args.p2.y - headlen * Math.sin(angle + deltaTheta)
            );

            // path from the side point back to the tip of the arrow, and then again to the opposite side point
            args.ctx.lineTo(args.p2.x, args.p2.y);
            args.ctx.lineTo( // Line needed to complete the "tip" of the arrow during rendering 
                args.p2.x - headlen * Math.cos(angle - deltaTheta),
                args.p2.y - headlen * Math.sin(angle - deltaTheta)
            );

            // draws the paths created above
            args.ctx.strokeStyle = args.stroke;
            args.ctx.lineWidth = args.lineWidth;
            args.ctx.stroke();
            args.ctx.fillStyle = args.stroke;
            args.ctx.fill();
        }
    }

    static rectangle(
        args: DrawShapeArgs,
    ): void {
        args.ctx.beginPath();
        const width = args.p2.x - args.p1.x;
        const height = args.p2.y - args.p1.y;
        args.ctx.rect(args.p1.x, args.p1.y, width, height);
        args.ctx.strokeStyle = args.stroke;
        args.ctx.lineWidth = args.lineWidth;
        args.ctx.stroke();
    }

    /** Ellipse */
    static oval(
        args: DrawShapeArgs,
    ): void {
        args.ctx.beginPath();
        const center: Vec2D = Vec2D.midpoint(args.p1, args.p2);
        const xRad = Math.abs(args.p1.x - args.p2.x) / 2;
        const yRad = Math.abs(args.p1.y - args.p2.y) / 2;
        args.ctx.ellipse(center.x, center.y, xRad, yRad, 0, 0, 2 * Math.PI );
        args.ctx.closePath();

        args.ctx.strokeStyle = args.stroke;
        args.ctx.lineWidth = args.lineWidth;
        args.ctx.stroke();
    }
}
