import { Action } from '@ngrx/store';
import { FormLetterTemplate, RTFTemplate } from 'app/core/rest-api';
import { CommonTemplate } from 'app/shared/components/print-dialog/common-template';


export enum LettersActionTypes {
    LOAD_LETTERS_REQUESTED = '[Letters] Load Letters Requested',
    LOAD_LETTERS_SUCCESS = '[API] Load Letters Success',
    LOAD_LETTERS_ERROR = '[API] Load Letters Error',
    SELECT_LETTER = '[Letters] Select Letter',
    DESELECT_LETTER = '[Letters] Deselect Letter',
    CREATE_LETTER_REQUESTED = '[Letters] Create Letter',
    CREATE_LETTER_SUCCESS = '[Letters] Create Letter Success',
    CREATE_LETTER_ERROR = '[Letters] Create Letter Error',
    DELETE_LETTER_REQUESTED = '[Letters] Delete Letter Requested',
    DELETE_LETTER_ERROR = '[Letters] Delete Letter Error',
    DELETE_LETTER_SUCCESS = '[Letters] Delete Letter Success',
    UPDATE_LETTER_REQUESTED = '[Letters] Update Letter Requested',
    UPDATE_LETTER_SUCCESS = '[Letters] Update Letter Success',
    UPDATE_LETTER_ERROR = '[Letters] Update Letter Error',
    CREATE_RTF_LETTER_REQUESTED = '[Letters] Create RTF Letter',
}

export class LoadLettersRequested implements Action {
    readonly type = LettersActionTypes.LOAD_LETTERS_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadLettersSuccess implements Action {
    readonly type = LettersActionTypes.LOAD_LETTERS_SUCCESS;
    constructor(public payload: { letters: CommonTemplate[] }) {}
}

export class LoadLettersError implements Action {
    readonly type = LettersActionTypes.LOAD_LETTERS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class SelectLetter implements Action {
    readonly type = LettersActionTypes.SELECT_LETTER;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class DeselectLetter implements Action {
    readonly type = LettersActionTypes.DESELECT_LETTER;
}

export class CreateRtfLetter implements Action {
    readonly type = LettersActionTypes.CREATE_RTF_LETTER_REQUESTED;
    constructor(public payload: { letter: RTFTemplate, file: Blob }) {}
}

export class CreateLetter implements Action {
    readonly type = LettersActionTypes.CREATE_LETTER_REQUESTED;
    constructor(public payload: { letter: FormLetterTemplate }) {}
}

export class CreateLetterSuccess implements Action {
    readonly type = LettersActionTypes.CREATE_LETTER_SUCCESS;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class CreateLetterError implements Action {
    readonly type = LettersActionTypes.CREATE_LETTER_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteLetter implements Action {
    readonly type = LettersActionTypes.DELETE_LETTER_REQUESTED;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class DeleteLetterError implements Action {
    readonly type = LettersActionTypes.DELETE_LETTER_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteLetterSuccess implements Action {
    readonly type = LettersActionTypes.DELETE_LETTER_SUCCESS;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class UpdateLetter implements Action {
    readonly type = LettersActionTypes.UPDATE_LETTER_REQUESTED;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class UpdateLetterSuccess implements Action {
    readonly type = LettersActionTypes.UPDATE_LETTER_SUCCESS;
    constructor(public payload: { letter: CommonTemplate }) {}
}

export class UpdateLetterError implements Action {
    readonly type = LettersActionTypes.UPDATE_LETTER_ERROR;
    constructor(public payload: { error: any }) {}
}

export type LettersActions =
    | LoadLettersRequested
    | LoadLettersSuccess
    | LoadLettersError
    | SelectLetter
    | DeselectLetter
    | CreateLetterSuccess
    | DeleteLetterSuccess
    | UpdateLetterSuccess;
