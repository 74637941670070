import { Injectable } from '@angular/core';

import { BoilerplateDbService } from 'app/db/boilerplates/boilerplate.db.service';
import { ContactDbService } from 'app/db/contacts/contact.db.service';
import { CraftToPlanLocationDbService } from 'app/db/craft-to-plan-locations/craft-to-plan-locations.db.service';
import { DiaryDbService } from 'app/db/diary/diary.db.service';
import { EntityDbService } from 'app/db/entity/entity.db.service';
import { IssueDbService } from 'app/db/issues/issue.db.service';
import { LocationDbService } from 'app/db/locations/location.db.service';
import { ProjectContactDbService } from 'app/db/project-contacts/project-contact.db.service';
import { ProjectCraftIdsDbService } from 'app/db/project-craft-ids/project-craft-ids.db.service';
import { ProjectPartnerDbService } from 'app/db/project-partners/project-partners.db.service';
import { ProjectRolesDbService } from 'app/db/project-roles/project-roles.db.service';
import { ProjectTemplatesDbService } from 'app/db/projects-templates/project.db.service';
import { ProjectDbService } from 'app/db/projects/project.db.service';

export abstract class EntityAttachmentIdsService {
    public abstract obtainAttachmentIds(): Promise<Set<string>>;
}

@Injectable()
export class DatabaseEntityAttachmentIdsService extends EntityAttachmentIdsService {
    constructor(
        private boilerplates: BoilerplateDbService,
        private contacts: ContactDbService,
        private craftToPlanLocations: CraftToPlanLocationDbService,
        private diaries: DiaryDbService,
        private issues: IssueDbService,
        private projectContacts: ProjectContactDbService,
        private projectCraftIds: ProjectCraftIdsDbService,
        private projectPartners: ProjectPartnerDbService,
        private projectRoles: ProjectRolesDbService,
        private projects: ProjectDbService,
        private locations: LocationDbService,
        private projectTemplates: ProjectTemplatesDbService
    ) {
        super();
    }

    private static async obtainAttachmentIds(
        service: EntityDbService<any, any>,
        usedAttachmentIds: Set<string>
    ): Promise<void> {
        const entities = (await service.getAll()).data;

        for (const entity of entities) {
            const entityAttachments = service.getAttachmentIdsFromApiItem(
                entity
            );

            entityAttachments.forEach((id) => usedAttachmentIds.add(id));
        }
    }

    public async obtainAttachmentIds(): Promise<Set<string>> {
        const usedAttachmentIds = new Set<string>();

        const { obtainAttachmentIds } = DatabaseEntityAttachmentIdsService;

        await Promise.all([
            obtainAttachmentIds(this.boilerplates, usedAttachmentIds),
            obtainAttachmentIds(this.contacts, usedAttachmentIds),
            obtainAttachmentIds(this.craftToPlanLocations, usedAttachmentIds),
            obtainAttachmentIds(this.diaries, usedAttachmentIds),
            obtainAttachmentIds(this.issues, usedAttachmentIds),
            obtainAttachmentIds(this.projectContacts, usedAttachmentIds),
            obtainAttachmentIds(this.projectCraftIds, usedAttachmentIds),
            obtainAttachmentIds(this.projectPartners, usedAttachmentIds),
            obtainAttachmentIds(this.projectRoles, usedAttachmentIds),
            obtainAttachmentIds(this.projects, usedAttachmentIds),
            obtainAttachmentIds(this.locations, usedAttachmentIds),
            obtainAttachmentIds(this.projectTemplates, usedAttachmentIds),
        ]);

        return usedAttachmentIds;
    }
}
