<button
    mat-button
    disableRipple="true"
    class="toggle-button"
    (click)="onToggle()"
    [disabled]="disabled"
    [attr.aria-label]="
        (booleanValue ? 'GENERIC_LABELS.YES' : 'GENERIC_LABELS.NO') | translate
    "
    [ngClass]="{
        'toggle-button-disabled': disabled
    }"
    [ngStyle]="toggleButtonStyle"
    data-test="labeled-slide-toggle"
>
    <div class="circle-base">
        <div
            class="toggle-background"
            [@toggleColor]="booleanValue ? 'on' : 'off'"
        >
            <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="w-100-p h-100-p state-label-container"
            >
                <span
                    class="state-label"
                    [@fadeText]="booleanValue ? 'in' : 'out'"
                    data-test="labeled-slide-toggle-label-on"
                    [ngStyle]="stateLabelYesStyle"
                    >{{ 'GENERIC_LABELS.YES' | translate }}</span
                >
                <span
                    class="state-label"
                    [@fadeText]="booleanValue ? 'out' : 'in'"
                    data-test="labeled-slide-toggle-label-off"
                    [ngStyle]="stateLabelNoStyle"
                    >{{ 'GENERIC_LABELS.NO' | translate }}</span
                >
            </div>
        </div>

        <div
            class="circle"
            [@toggleCircle]="{
                value: booleanValue ? 'right' : 'left',
                params: {
                    circleAnimationOnPosition: circleAnimationOnPosition,
                    circleAnimationOffPosition: circleAnimationOffPosition
                }
            }"
            [ngStyle]="circleStyle"
            data-test="labeled-slide-toggle-circle"
        ></div>
    </div>
</button>
