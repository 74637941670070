import {
    ApprovalEntry,
    AttendanceEntry,
    DailyLogEntryBase,
    InspectionEntry,
    WeatherEntry
} from 'app/core/rest-api';
import * as moment from 'moment';
import { FilterDiaryEntriesOptions } from './models/diary.filter-options';
import { DiaryFilters } from './models/diary.filters';

export class DiaryUtils {
    static nonFilterParams = [
        'diaryEntriesType',
        'diaryEntriesIsoDate',
        'inspectionEntry',
        'issueId',
        'diaryEntryId'
    ];

    static calculateTotalFilters(filters: DiaryFilters): number {
        let total = 0;
        for (const key in filters) {
            if (
                filters.hasOwnProperty(key) &&
                filters[key] &&
                !this.nonFilterParams.includes(key)
            ) {
                total++;
            }
        }
        return total;
    }

    static filterDiaryEntries(
        options: FilterDiaryEntriesOptions
    ): DailyLogEntryBase[] {
        if (!options.diaryEntries) {
            return [];
        }

        return [...options.diaryEntries]
            .filter(entry =>
                DiaryUtils.filterByType(entry, options.filters.type)
            )
            .filter(entry =>
                DiaryUtils.filterByLogEntryDateFrom(
                    entry,
                    options.filters.logEntryDateFrom
                )
            )
            .filter(entry =>
                DiaryUtils.filterByLogEntryDateTill(
                    entry,
                    options.filters.logEntryDateTill
                )
            )
            .filter(entry =>
                DiaryUtils.filterByText(entry, options.filters.text)
            )
            .filter(entry =>
                DiaryUtils.filterByContact(entry, options.filters.contactId)
            );
    }

    private static filterByType(
        entry: DailyLogEntryBase,
        type: DailyLogEntryBase.TypeEnum
    ): boolean {
        if (!type) {
            return true;
        }
        return entry.type === type;
    }

    static getLastDiaryEntryForDate(
        entries: DailyLogEntryBase[],
        props: {
            date: Date;
            entryType?: DailyLogEntryBase.TypeEnum;
            notArchived?: boolean;
        }
    ): DailyLogEntryBase {
        let filteredEntries = entries;

        if (props.entryType) {
            filteredEntries = filteredEntries.filter(
                entry => entry.type === props.entryType
            );
        }

        if (props.notArchived) {
            filteredEntries = filteredEntries.filter(
                entry => !entry.markedAsDelete
            );
        }

        filteredEntries = filteredEntries.filter(entry => {
            return (
                entry.logEntryDateTime.getFullYear() ===
                    props.date.getFullYear() &&
                entry.logEntryDateTime.getMonth() === props.date.getMonth() &&
                entry.logEntryDateTime.getDate() === props.date.getDate()
            );
        });
        const sortedEntries = filteredEntries.sort((e1, e2) => {
            if (e1.logEntryDateTime > e2.logEntryDateTime) {
                return 1;
            } else if (e1.logEntryDateTime < e2.logEntryDateTime) {
                return -1;
            } else {
                return 0;
            }
        });
        return sortedEntries[0];
    }

    private static filterByLogEntryDateFrom(
        entry: DailyLogEntryBase,
        logEntryDateFrom: Date
    ): boolean {
        if (!logEntryDateFrom) {
            return true;
        }

        return moment(entry.logEntryDateTime)
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .isAfter(logEntryDateFrom);
    }

    private static filterByLogEntryDateTill(
        entry: DailyLogEntryBase,
        logEntryDateTill: Date
    ): boolean {
        if (!logEntryDateTill) {
            return true;
        }

        return moment(entry.logEntryDateTime).isBefore(logEntryDateTill);
    }

    static filterByText(entry: DailyLogEntryBase, query: string): boolean {
        if (!query) {
            return true;
        }

        for (const property in entry) {
            if (entry.hasOwnProperty(property)) {
                if (
                    entry[property] &&
                    typeof entry[property] === 'string' &&
                    entry[property].toLowerCase().includes(query.toLowerCase())
                ) {
                    return true;
                }
            }
        }
    }

    static filterByContact(
        entry: DailyLogEntryBase,
        contactId: string
    ): boolean {
        if (!contactId) {
            return true;
        }

        switch (entry.type) {
            case DailyLogEntryBase.TypeEnum.ApprovalEntry:
                const approvalEntry = entry as ApprovalEntry;
                return (
                    approvalEntry.companyId === contactId ||
                    approvalEntry.ownerId === contactId
                );

            case DailyLogEntryBase.TypeEnum.AttendanceEntry:
                const attendanceEntry = entry as AttendanceEntry;
                return (
                    attendanceEntry.companyId === contactId ||
                    (attendanceEntry.participantsIds &&
                        attendanceEntry.participantsIds.includes(contactId))
                );

            case DailyLogEntryBase.TypeEnum.InspectionEntry:
                const inspectionEntry = entry as InspectionEntry;
                return (
                    inspectionEntry.ownerId === contactId ||
                    (inspectionEntry.participantsIds &&
                        inspectionEntry.participantsIds.includes(contactId))
                );
        }
    }

    static getWeatherIcon(weatherEntry: WeatherEntry): string {
        switch (weatherEntry.weather) {
            case WeatherEntry.WeatherEnum.Clear:
                return 'sun.svg';
            case WeatherEntry.WeatherEnum.PartlyCloudy:
                return 'cloudy.svg';
            case WeatherEntry.WeatherEnum.Cloudy:
                return 'cloud.svg';
            case WeatherEntry.WeatherEnum.LightRain:
            case WeatherEntry.WeatherEnum.Rain:
            case WeatherEntry.WeatherEnum.HeavyRain:
                return 'raining.svg';
            case WeatherEntry.WeatherEnum.Thunderstorms:
                return 'storm1.svg';
            case WeatherEntry.WeatherEnum.Sleet:
                return 'graupel.svg';
            case WeatherEntry.WeatherEnum.Snow:
            case WeatherEntry.WeatherEnum.HeavySnow:
            case WeatherEntry.WeatherEnum.Blizzard:
                return 'snowflake.svg';
            case WeatherEntry.WeatherEnum.Fog:
                return 'fog.svg';
            default:
                return 'sun.svg';
        }
    }
}
