<h2 mat-dialog-title translate>DEMO_MODE.SAVE_DIALOG.TITLE</h2>

<mat-dialog-content>
    <p translate>DEMO_MODE.SAVE_DIALOG.TEXT</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <a mat-raised-button [href]="restrictedFunctionalityCTAURL" target="_blank" color="accent">
    {{ 'DEMO_MODE.SAVE_DIALOG.CTA' | translate }}
    </a>
    <button
        mat-button
        mat-dialog-close
    >
    {{ 'DEMO_MODE.SAVE_DIALOG.CONFIRM' | translate }}
    </button>
</mat-dialog-actions>
