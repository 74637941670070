import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { AssetTileService } from 'app/shared/components/asset-tile/asset-tile.service';
import { ProjectDetailData } from '../project-grid/project-grid.component';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Project } from 'app/core/rest-api';
import { getIsProjectToSync } from 'app/store/sync/sync.selectors';
import { Observable } from 'rxjs';
import { SetProjectsToSync } from 'app/store/sync/sync.actions';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { getIsOnline } from 'app/store/core/core.selectors';

@Component({
    selector: 'acc-project-grid-item',
    templateUrl: 'project-grid-item.component.html',
    styleUrls: ['project-grid-item.component.scss'],
    animations: [
        trigger('fade', [
            state(
                'initial',
                style({
                    opacity: 1,
                })
            ),
            state(
                'in',
                style({
                    opacity: 1,
                })
            ),
            state(
                'out',
                style({
                    opacity: 0,
                })
            ),
            // If the project image loads quickly, the initial default image is only
            // shown a short amount of time. It looks better with the initial fade
            // out being slowed down thus showing this default image longer.
            // Otherwise the images almost flicker.
            transition('initial => out', animate('0.5s')),
            transition('in <=> out', animate('0.2s')),
        ]),
    ],
})
export class ProjectGridItemComponent implements OnInit, OnChanges, OnDestroy {
    private static readonly placeholderSrc =
        'assets/images/custom/project-avatar.svg';
    strOpenTickets: string = '';

    @Input('projectDetail')
    set _projectDetail(val: ProjectDetailData) {
        this.projectDetail = val;
    }
    projectDetail: ProjectDetailData;

    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));
    isOnline$ = this.store.pipe(select(getIsOnline));

    @Output()
    archived = new EventEmitter<Project>();

    @Output()
    updated = new EventEmitter<Project>();

    @Input()
    projectsToSync: string[];

    @Input()
    isDevice = false;

    markedForSync$: Observable<boolean>;

    /**
     * Indicates if the component was destroyed, this is used
     * to determine if the currently loading image has to be
     * released directly after completion.
     */
    private componentDestroyed = false;
    private unsubscribe$ = new Subject<void>();

    /**
     * The next project image for animation purposes.
     */
    newImageUrl: string = ProjectGridItemComponent.placeholderSrc;

    /**
     * Indiciates if a fade in or fade out must happen.
     */
    animationType: 'initial' | 'in' | 'out' = 'initial';

    /**
     * The current image url of the project this grid item represents.
     */
    imageUrl: string = ProjectGridItemComponent.placeholderSrc;

    view: any[] = [150, 150];

    // options
    gradient: boolean = false;
    showLegend: boolean = false;
    showLabels: boolean = false;
    isDoughnut: boolean = true;
    colorScheme = {
        domain: ['#4ba82c', '#2a7ec5', '#65b9c5', '#e03141'],
    };

    constructor(
        private assetTileService: AssetTileService,
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<SyncState>
    ) {}

    ngOnInit(): void {
        this.markedForSync$ = this.store.pipe(
            select(getIsProjectToSync, { id: this.projectDetail.project.id })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        const projectDetailChanges = changes._projectDetail;

        const photoIdChanged =
            projectDetailChanges &&
            projectDetailChanges.currentValue?.photoId !==
                projectDetailChanges.previousValue?.photoId;
        if (photoIdChanged) {
            this.updateImageUrl();
        }
    }

    private updateImageUrl(): void {
        // clean up the previously used image to prevent leaking memory
        // and set it to the default image in case there is not any image
        // set for the project
        this.releaseAndResetProjectImage();

        const photoIdAndProjectIdAvailable =
            this.projectDetail?.photoId && this.projectDetail?.project.id;
        if (photoIdAndProjectIdAvailable) {
            this.assetTileService
                .loadImageOrDefault({
                    id: this.projectDetail.photoId,
                    entityType: 'project',
                    entityId: this.projectDetail.project.id,
                    defaultImageUrl: ProjectGridItemComponent.placeholderSrc,
                })
                .pipe(
                    takeUntil(this.unsubscribe$),
                    tap((imageUrl) => {
                        this.newImageUrl = imageUrl;
                        this.animationType = 'out';

                        // for some reason Angular does not detect this change, force to
                        // detect the new imageUrl
                        this.changeDetectorRef.detectChanges();

                        // in case loading the image tooks longer and the component was already
                        // destroyed make sure that the object url is still cleaned up
                        if (this.componentDestroyed) {
                            this.releaseAndResetProjectImage();
                        }
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        this.releaseAndResetProjectImage();
        this.componentDestroyed = true;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * Clean up the loaded project image. It resets the image to the default image,
     * so calling this method multiple times is not an issue.
     *
     * If the default image url is set, calling this method will have no effect.
     */
    private releaseAndResetProjectImage(): void {
        const imageUrlExistsAndNotDefault =
            this.newImageUrl &&
            this.newImageUrl !== ProjectGridItemComponent.placeholderSrc;
        if (imageUrlExistsAndNotDefault) {
            this.assetTileService.releaseAsset(this.newImageUrl);

            // this also prevents releasing the same imageUrl multiple times
            this.newImageUrl = ProjectGridItemComponent.placeholderSrc;
            this.animationType = 'out';
        }
    }

    toggleSync(): void {
        let projectsToSync: string[] = [];
        if (!this.projectsToSync.includes(this.projectDetail.project.id)) {
            projectsToSync = [
                ...this.projectsToSync,
                this.projectDetail.project.id,
            ];
        } else {
            projectsToSync = this.projectsToSync.filter(
                (p) => p !== this.projectDetail.project.id
            );
        }
        this.store.dispatch(new SetProjectsToSync({ projectsToSync }));
    }

    fadeCompleted(): void {
        if (this.animationType === 'out') {
            // the old image was faded out, fade in the new one

            this.animationType = 'in';
            this.imageUrl = this.newImageUrl;
        }
    }
}
