import { Component, OnInit, Input } from '@angular/core';
import { FilterByDateRange } from './filter-by-date-range.model';

@Component({
    selector: 'acc-filter-by-date-range',
    templateUrl: './filter-by-date-range.component.html',
    styleUrls: ['./filter-by-date-range.component.scss'],
})
export class FilterByDateRangeComponent implements OnInit {
    @Input() filterItem: FilterByDateRange;

    public dateFromMaxValue: Date;
    public dateToMinValue: Date;
    constructor() {}

    ngOnInit(): void {
        this.dateFromMaxValue = this.filterItem.maxValue;
        this.dateToMinValue = this.filterItem.minValue;
    }

    updateValue(): void {
        const dateFrom: Date = new Date(this.filterItem.stringValueFrom);
        const dateTo: Date = new Date(this.filterItem.stringValueTo);

        this.updateValueLimits(dateFrom, dateTo);
    }

    updateValueLimits(dateFrom: Date, dateTo: Date): void {
        this.updateDateFromMaxValue(dateTo);
        this.updateDateToMinValue(dateFrom);
    }

    updateDateFromMaxValue(dateTo: Date): void {
        if (!this.filterItem.maxValue) {
            this.dateFromMaxValue = dateTo;
            return;
        }

        this.dateFromMaxValue =
            dateTo < this.filterItem.maxValue
                ? dateTo
                : this.filterItem.maxValue;
    }

    updateDateToMinValue(dateFrom: Date): void {
        if (!this.filterItem.minValue) {
            this.dateToMinValue = dateFrom;
            return;
        }

        this.dateToMinValue =
            dateFrom > this.filterItem.minValue
                ? dateFrom
                : this.filterItem.minValue;
    }
}
