export const locale = {
    lang: 'br',
    data: {
        NAV: {
            PROJECTS: {
                TITLE: 'Projetos',
            },
            ISSUES: {
                TITLE: 'Tickets',
            },
            DIARY: {
                TITLE: 'Diário de Construção',
            },
            ARCHIVE: {
                TITLE: 'Arquivo',
            },
            BUILDINGS: {
                TITLE: 'Edifícios',
            },
            USERS: {
                TITLE: 'Participantes',
            },
            SETTINGS: {
                TITLE: 'Configurações',
            },
            SYNC: {
                TITLE: 'Sincronização',
            },
            GLOBAL_SETTINGS: {
                ADDRESS_BOOK: {
                    TITLE: 'Lista de endereços',
                },
                TEXT_COMPONENTS: {
                    TITLE: 'Componentes de texto',
                },
                ISSUE_CATEGORIES: {
                    TITLE: 'Categorias de Ticket',
                },
            },
            ABOUT: {
                TITLE: 'Sobre',
            },
        },
    },
};
