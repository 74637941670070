import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { ContactsEffects } from './contacts.effects';
import * as fromContacts from './contacts.reducer';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        MatSnackBarModule,
        StoreModule.forFeature('contacts', fromContacts.reducer),
        EffectsModule.forFeature([ContactsEffects])
    ]
})
export class ContactsStoreModule {}
