import { ChecklistTemplate } from 'app/core/rest-api';

export interface WithChecklists {
    checklists?: ChecklistTemplate[];
}

export interface WithChecklistTemplates {
    checklistTemplatesIds?: string[];
    checklistTemplates?: ChecklistTemplate[];
}

export const checklistTemplatesIdsSchema = {
    type: ['array', 'null'],
    items: {
        type: 'string',
    },
};

export const checklistSchema = {
    type: ['array', 'null'],
    items: {
        type: 'object',
        // allow adding new values to the checklist model later without having to
        // write a migration again
        additionalProperties: true,
    },
};
