import { MongoFile } from 'app/core/rest-api';
import { StoreRequest } from './attachment-downloader.service';
import { FileMetadataStorage } from './file-metadata-storage';

export class FileStorageError extends Error {
    constructor(public message: string, public cause?: any) {
        super(message);
    }
}

export class FileNotFoundError extends FileStorageError {
    constructor(public message: string, public cause?: any) {
        super(message, cause);
    }
}

/**
 * 
 * This service handles (low-level) storing files in the local (device) database. 
 * See also:
 *  * `FileService` for (low-level) communication with the API.
 *  * `AddAssetService` for high level operations,
 *  * `AttachmentUploadFromDbService`  ??? Some kind of high level operations ???
 *     >>  The AttachmentUploadFromDbService manage uploading attachments which are currently only available in Db (while the AddAssetService
 *     >>  only does upload if the user is actually online). It also renames files from the local offline-only id to the backend-provided id after upload.
 * 
 * 
 * Note: Keep in mind, that all methods that are implemented
 *       also have to call the method in the base class, which
 *       manages the metadata (excluding loadAttachment).
 *
 * The following methods are required to be implemented in the
 * subclass to ensure that the file storage is working correctly:
 * - init
 * - storeAttachment
 * - loadAttachment
 * - removeAttachment
 * - clear
 * - updateAttachmentIds
 */
export abstract class FileStorageService {
    constructor(protected fileMetadataStorage: FileMetadataStorage) {}

    private async upsertMetadata(
        attachmentId: string,
        metadata: MongoFile
    ): Promise<void> {
        await this.fileMetadataStorage.upsertMetadata({
            ...metadata,
            id: attachmentId,
        });
    }

    /**
     * Initialize the file storage. Please note, that this method can be
     * called multiple times. If the implementation contains awaits, this
     * can possibly happen concurrently. Thus it has to be idempotent and
     * ensure that the body does not run concurrently, if that could be a
     * problem.
     */
    async init(): Promise<void> {}

    /**
     * Determine if the given attachment is stored in this storage.
     */
    attachmentExists(attachmentId: string): Promise<boolean> {
        return this.fileMetadataStorage.attachmentExists(attachmentId);
    }

    /**
     * Stores either both the attachment and its metadata or only the
     * metadata. This is determined by the kind of StoreRequest, that
     * has been passed to this method.
     *
     * Already existing attachments with the same id must be overwritten.
     *
     * @param storeRequest A request to store either a blob + metadata or metadata
     */
    storeAttachment(storeRequest: StoreRequest): Promise<void> {
        return this.upsertMetadata(
            storeRequest.attachmentId,
            storeRequest.metadata
        );
    }

    /**
     * Store metadata for multiple attachments at once. Please note,
     * that only metadata store requests are allowed here.
     *
     * @returns A promise resolved after the metadata has been stored. __If any of the
     *          given store requests also includes a blob, the promise will be rejected.__
     */
    bulkStoreMeta(storeRequests: StoreRequest[]): Promise<void> {
        return this.fileMetadataStorage.bulkStoreMeta(
            storeRequests.map((storeRequest) => {
                if (storeRequest.type !== 'metadata-to-store') {
                    throw new Error(
                        `invalid store request, only metadata storing supported: ${storeRequest.type}`
                    );
                }

                return {
                    ...storeRequest.metadata,
                    id: storeRequest.attachmentId,
                };
            })
        );
    }

    /**
     * Remove the given attachment from the storage. Please not that the returned promise
     * should be rejected if the attachment does not exist.
     */
    removeAttachment(attachmentId: string): Promise<void> {
        return this.fileMetadataStorage.removeMetadataFor(attachmentId);
    }

    /**
     * Return a set of unique attachment ids of all stored attachments. This can be
     * used to determine, which attachments have not yet been downloaded but are
     * required by entities stored in the offline database.
     */
    getStoredAttachmentIds(): Promise<Set<string>> {
        return this.fileMetadataStorage.getStoredAttachmentIds();
    }

    /**
     * Update the ids of multiple attachments, so that they are available
     * by the new ids.
     *
     * The main purpose of this method is to move from local-only attachment
     * ids to real ids obtained from the backend after uploading attachments
     * added in offline mode.
     *
     * Please note: This  method could be called concurrently for the same
     * 'from' and / or 'to' ids and has to be able to handle that.
     *
     * @param idMap A mapping of attachment ids from the local to the backend variant.
     */
    async updateAttachmentIds(idMap: Map<string, string>): Promise<void> {
        for (const [from, to] of idMap) {
            await this.fileMetadataStorage.moveMetadata(from, to);
        }
    }

    /**
     * Remove all attachments. Please note, that this method can be
     * called multiple times. If the implementation contains awaits,
     * this can possibly happen concurrently. Thus it has to check that
     * there is something to remove and ensure that the body does not
     * run concurrently, if that could be a problem.
     *
     * The main purpose of this method is to wipe the user's data after
     * they logout.
     */
    clear(): Promise<void> {
        return this.fileMetadataStorage.clear();
    }

    /**
     * Load the attachment with the given id. The returned promise should
     * be rejected if the attachment is not available.
     */
    abstract loadAttachment(attachmentId: string): Promise<Blob>;
}
