interface AutoSelectConfigProperties {
  title: string;
  id?: string;
  parentId?: string;
  parentIds?: string;
  avatar?: string;
}
export class AutoSelectorConfig {
  properties: AutoSelectConfigProperties = {
    title: 'title',
    id: 'id',
    parentId: 'parentID',
    parentIds: 'parentID',
    avatar: 'avatar'
  };
  label = '';
  mode: 'single' | 'multi' = 'single';
  tree = false;
  duplicates = false;
  avatar = false;
  avatarRounded = true;
  
  constructor(o: {
    properties: AutoSelectConfigProperties;
    label?: string;
    mode?: 'single' | 'multi';
    tree?: boolean;
    duplicates?: boolean;
    avatar?: boolean;
    avatarRounded?: boolean;
  }) {
    for (const key in o) {
      if (o.hasOwnProperty(key) && key !== 'properties') {
        this[key] = o[key];
      }
    }
    if (o.hasOwnProperty('properties')) {
      for (const key in o.properties) {
        if (o.properties.hasOwnProperty(key)) {
          this.properties[key] = o.properties[key];
        }
      }
    }
  }
}
