import { Contact } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';

interface JwtRole {
    CustomerId: string;
    Role: number;
    ProjectId: string;
}

interface RolesByProjectId {
    [projectId: string]: number[];
}

export class User {
    id: string;
    rolesByProjectId?: RolesByProjectId;
    username: string;
    firstName: string;
    lastName: string;
    company?: string;
    email: string;
    cellPhone?: string;
    phone?: string;
    craft?: string;
    contactId?: string;
    contact?: Contact;
    constructor(token: string) {
        const decodedToken = AcceptUtils.decodeJWT(token);

        this.id = decodedToken.nameid;
        this.email = decodedToken.email;
        this.username = `${decodedToken.unique_name} ${decodedToken.family_name}`;
        this.firstName = decodedToken.unique_name;
        this.lastName = decodedToken.family_name;
        this.contactId = decodedToken['F2G.ConId'];
        const decodedRoles: JwtRole[] = decodedToken['F2G.Role']?.map(entry =>
            JSON.parse(entry)
        );
        this.rolesByProjectId = {};

        if (decodedRoles)
            for (const role of decodedRoles) {
                const project = this.rolesByProjectId[role.ProjectId];
                if (project) {
                    if (!project.includes(role.Role)) {
                        project.push(role.Role);
                    }
                } else {
                    this.rolesByProjectId = {
                        ...this.rolesByProjectId,
                        [role.ProjectId]: [role.Role]
                    };
                }
            }

        // this.roles = decodedRole.map();
    }
}
