/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Contact } from './contact';


export interface CustomAttributeTemplate { 
    id?: string;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    projectId?: string;
    title?: string;
    type?: CustomAttributeTemplate.TypeEnum;
    value?: string;
}
export namespace CustomAttributeTemplate {
    export type TypeEnum = 'Text' | 'Number' | 'Contact' | 'Date' | 'Url' | 'Boolean' | 'Label';
    export const TypeEnum = {
        Text: 'Text' as TypeEnum,
        Number: 'Number' as TypeEnum,
        Contact: 'Contact' as TypeEnum,
        Date: 'Date' as TypeEnum,
        Url: 'Url' as TypeEnum,
        Boolean: 'Boolean' as TypeEnum,
        Label: 'Label' as TypeEnum
    };
}


