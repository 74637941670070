import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'acc-offline-dialog',
    templateUrl: './offline-dialog.component.html',
    styleUrls: ['./offline-dialog.component.scss']
})
export class OfflineDialogComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
