
/**
 * TSCompiler will error if this is reachable (at compile time).
 * For extra safety, this will throw an error at runtime, if it ever does execute, (which shouldn't happen if the compiler is correct). 
 */
export function unreachable(arg: never): never {
    throw new Error('Unreachable: ' + arg);
}


/**
 * TSCompiler will error if this is reachable (at compile time).
 * If this ever does execute, (which shouldn't happen if the compiler is correct), it will simply output a warning on the console
 */
export function unreachable_safe(arg: never): void {
    console.warn( (new Error('Unreachable: ' + arg)).stack );
}

