import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Preferences } from '@capacitor/preferences';
import { select, Store } from '@ngrx/store';
import { OfflineDialogComponent } from 'app/shared/components/dialogs/offline-dialog/offline-dialog.component';
import { LoadContactsRequested } from 'app/store/contacts/contacts.actions';
import {
    getIsDevice,
    getIsOffline,
    getIsOnline,
} from 'app/store/core/core.selectors';
import { LoadCraftsRequested } from 'app/store/crafts/crafts.actions';
import { LoadProjectTemplatesRequest } from 'app/store/project-templates/project-templates.actions';
import { LoadProjectsRequest } from 'app/store/projects/projects.actions';
import { LoadTimezonesRequested } from 'app/store/settings/settings.actions';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    withLatestFrom,
    take,
    tap,
} from 'rxjs/operators';
import {
    ClearInFlightFlagOfCommands,
    SetForceOffline,
    SetProjectsToSync,
} from '../../store/sync/sync.actions';
import { SyncState } from '../../store/sync/sync.reducer';
import { DbOrServer } from './models/db-or-server';

@Injectable({
    providedIn: 'root',
})
export class SyncService {
    offlineDialog: MatDialogRef<OfflineDialogComponent>;

    private inFlightRemovalSubscription?: Subscription = null;

    constructor(private dialog: MatDialog, private store: Store<SyncState>) {}

    async init(): Promise<void> {
        this.store.dispatch(new LoadProjectsRequest());
        this.store.dispatch(new LoadProjectTemplatesRequest());
        this.store.dispatch(new LoadCraftsRequested());
        this.store.dispatch(new LoadContactsRequested());
        this.store.dispatch(new LoadTimezonesRequested());

        await this.initProjectsToSync();

        this.registerInFlightRemovalOnGoingOffline();
    }

    async initProjectsToSync(): Promise<string[]> {
        const projectsToSyncString = await Preferences.get({
            key: 'projectsToSync',
        });
        let projectsToSync = [];
        if (projectsToSyncString.value) {
            projectsToSync = JSON.parse(projectsToSyncString.value);
            this.store.dispatch(new SetProjectsToSync({ projectsToSync }));
        }
        return projectsToSync;
    }

    registerInFlightRemovalOnGoingOffline(): void {
        // note: this handler just removes a flag but does not cancel the actual
        //       requests

        // the init-method of this service could be called multiple times (i.e. on start and on login)
        // => remove the existing subscription
        const alreadySubscribed = !!this.inFlightRemovalSubscription;
        if (alreadySubscribed) {
            this.inFlightRemovalSubscription.unsubscribe();
            this.inFlightRemovalSubscription = null;
        }

        this.inFlightRemovalSubscription = this.store
            .pipe(
                select(getIsOffline),
                tap((isOffline) => {
                    if (isOffline) {
                        this.store.dispatch(new ClearInFlightFlagOfCommands());
                    }
                })
            )
            .subscribe();
    }

    fetchFromServerOrDb<A extends DbOrServer, D extends DbOrServer>({
        server$,
        db$,
    }: {
        server$: Observable<A>;
        db$: Observable<D>;
    }): Observable<{ data: A | D; fromDb: boolean }> {
        return this.store.pipe(
            select(getIsOnline),
            take(1),
            withLatestFrom(this.store.pipe(select(getIsDevice))),
            switchMap(([isOnline, isDevice]) => {
                const _useApi = new BehaviorSubject(isOnline);
                let offlineDialogTimeout: NodeJS.Timeout;
                let fromDb = false;

                if (isDevice && !isOnline) {
                    fromDb = true;
                }

                if (isDevice && isOnline) {
                    // TODO: needs to be tested, not really working
                    // offlineDialogTimeout = setTimeout(() => {
                    //     this.openOfflineDialogSingleton()
                    //         .afterClosed()
                    //         .pipe(
                    //             tap(wantsOfflineMode => {
                    //                 if (wantsOfflineMode) {
                    //                     this.store.dispatch(
                    //                         new SetForceOffline({
                    //                             forceOffline: true
                    //                         })
                    //                     );
                    //                     fromDb = true;
                    //                     return db$.pipe(
                    //                         map(response => ({
                    //                             data: response.data,
                    //                             fromDb
                    //                         }))
                    //                     );
                    //                 }
                    //             })
                    //         );
                    // }, environment.offlineDialogTimeout);
                }

                return _useApi.pipe(
                    switchMap((useApi) => (useApi ? server$ : db$)),
                    catchError((error) => {
                        console.error('error trying to fetch data:', error);
                        clearTimeout(offlineDialogTimeout);
                        fromDb = true;
                        return db$;
                    }),
                    map((response) => {
                        clearTimeout(offlineDialogTimeout);
                        return {
                            data: response.data,
                            fromDb,
                        };
                    })
                );
            })
        );
    }

    openOfflineDialogSingleton(): MatDialogRef<OfflineDialogComponent> {
        if (this.offlineDialog) {
            return this.offlineDialog;
        } else {
            this.offlineDialog = this.dialog.open(OfflineDialogComponent);
            return this.offlineDialog;
        }
    }

    async disableForceOffline(): Promise<void> {
        this.store.dispatch(
            new SetForceOffline({
                forceOffline: false,
            })
        );
    }
}
