import { DatabaseService } from './db/database.service';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { untilDestroy } from '@ngrx-utils/store';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { NavigationService } from './navigation/navigation.service';
import { LoginByToken } from './store/auth/auth.actions';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    // SVG Icon table  (Name -> File)
    private readonly iconDict: {[K: string]: string} = {
        file_audio: 'audio.svg',
        f2g_check: 'check.svg',
        f2g_close: 'close.svg',
        f2g_warning: 'warning.svg',
        f2g_textbaustein_aktiviert: 'textbaustein-aktiviert.svg',
        f2g_textbaustein_deaktiviert: 'textbaustein-deaktiviert.svg',
    };

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _platform: Platform,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store<RouterReducerState>,
        private navigationService: NavigationService
    ) {
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        this._fuseConfigService.config
            .pipe(untilDestroy(this))
            .subscribe((config: any) => {
                this.fuseConfig = config;

                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        this.navigationService.init();
        const token = await Preferences.get({ key: 'token ' });
        if (token.value) {
            this.store.dispatch(new LoginByToken({ token: token.value }));
        }

        // Add custom SVG icons to the icon registry
        for ( const [key, val] of Object.entries(this.iconDict) ) {
            const filename = `../assets/icons/svg/${val}`;
            const url = this.sanitizer.bypassSecurityTrustResourceUrl(filename);
            this.iconRegistry.addSvgIcon( key, url );
        }

        const info = await Device.getInfo();
        if (info.platform === 'ios' || info.platform === 'android') {
            setTimeout(async () => {
                await SplashScreen.hide();
            }, 1000);
        }
    }

    ngOnDestroy(): void {
        // do not remove
    }
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
