import { MarkerAnnotation } from 'app/core/rest-api/model/markerAnnotation';

import { DrawShape, DrawShapeArgs } from '../draw-shape';
import { Transform, Vec2D } from '../transform.model';
import { generateAnnotationID } from '../utils';

/**
 * A simple utility interface to represent a relation
 * between to points.
 */
export interface FromTo {
    from: Vec2D;
    to: Vec2D;
}


/**
 * A parameter object for the `createAnnotation` method of the
 * TwoPointToolStrategy interface.
 */
export interface CreateAnnotation {
    /**
     * The two points to create the annotation from.
     */
    fromTo: FromTo;
    /**
     * The current transform of the plan to be able
     * to determine the coordinates in the original plan,
     * independent from scale and translation.
     */
    withTransform: Transform;
    /**
     * The color to use for the annotation.
     */
    strokeColor: string;
}

/**
 * An abstract strategy for tools which use two points to specify
 * the new object. An example is an arrow where the user provides
 * a 'from' and a 'to' point.
 */
export interface TwoPointToolStrategy {
    /**
     * Preview the current state of the annotation. This can be
     * used to show the intermediate result to the user before
     * they confirm it.
     *
     * @param preview The Preview parameter object. See its documentation for details.
     */
    preview(preview: DrawShapeArgs): void;

    /**
     * Create the actual annotation to be stored with the plan.
     *
     * @param createAnnotation The CreateAnnotation parameter object. See its documentation for details.
     */
    createAnnotation(createAnnotation: CreateAnnotation): MarkerAnnotation;
}

/**
 * A small utility function to create annotations.
 *
 * @param type The annotation type.
 * @param createAnnotation The CreateAnnotation parameter object.
 */
function createAnnotationWithType(
    type: MarkerAnnotation.TypeEnum,
    createAnnotation: CreateAnnotation
): MarkerAnnotation {
    const { withTransform, fromTo, strokeColor } = createAnnotation;

    const scale = withTransform.scale;
    const shiftX = withTransform.translationX;
    const shiftY = withTransform.translationY;

    return {
        type,
        id: generateAnnotationID(),
        x: (fromTo.from.x - shiftX) / scale,
        y: (fromTo.from.y - shiftY) / scale,
        width: null,
        height: null,
        x1: (fromTo.to.x - shiftX) / scale,
        y1: (fromTo.to.y - shiftY) / scale,
        color: strokeColor,
    };
}

/**
 * A concrete TwoPointToolStrategy to preview and create arrows.
 */
export class ArrowToolStrategy implements TwoPointToolStrategy {
    preview(preview: DrawShapeArgs): void {
        DrawShape.arrow( preview );
    }

    createAnnotation(createAnnotation: CreateAnnotation): MarkerAnnotation {
        return createAnnotationWithType(
            MarkerAnnotation.TypeEnum.Arrow,
            createAnnotation
        );
    }
}

/**
 * A concrete TwoPointToolStrategy to preview and create rectangles.
 */
export class RectangleToolStrategy implements TwoPointToolStrategy {
    preview(preview: DrawShapeArgs): void {
        DrawShape.rectangle( preview );
    }

    createAnnotation(createAnnotation: CreateAnnotation): MarkerAnnotation {
        return createAnnotationWithType(
            MarkerAnnotation.TypeEnum.Rectangle,
            createAnnotation
        );
    }
}

/**
 * A concrete TwoPointToolStrategy to preview and create ovals.
 */
export class OvalToolStrategy implements TwoPointToolStrategy {
    preview(preview: DrawShapeArgs): void {
        DrawShape.oval( preview );
    }

    createAnnotation(createAnnotation: CreateAnnotation): MarkerAnnotation {
        return createAnnotationWithType(
            MarkerAnnotation.TypeEnum.Circle,
            createAnnotation
        );
    }
}
