<div
    class="banner indigo-700 px-32 pb-16"
    fxLayout="row"
    fxLayoutAlign="space-between"
>
    <div *ngLet="currentProject$ | async as currentProject">
        <div fxLayout="row" fxLayoutAlign="start center">
            <button
                class="mt-8 no-uppercase"
                mat-button
                [matMenuTriggerFor]="projectMenu"
            >
                <div fxLayout="row" fxLayoutAlign="start center">
                    <h1 *ngIf="currentProject">
                        {{ currentProject.title }}
                    </h1>
                    <h1 *ngIf="!currentProject" translate>
                        APP.SELECT_PROJECT
                    </h1>

                    <mat-icon class="s-32">expand_more</mat-icon>
                </div>
            </button>
        </div>
        <div
            [style.visibility]="!currentProject ? 'hidden' : 'visible'"
            *ngLet="language$ | async as language"
        >
            <span class="ml-16">
                {{ 'BANNER.PROJECT_BEGIN' | translate }}:
                {{
                    currentProject?.startDate
                        | date: 'shortDate':undefined:language
                }}
            </span>
            <span class="mx-12">|</span>
            <span
                >{{ 'BANNER.PROJECT_END' | translate }}:
                {{
                    currentProject?.dueDate
                        | date: 'shortDate':undefined:language
                }}</span
            >
        </div>
    </div>

    <ng-content></ng-content>

    <mat-menu #projectMenu="matMenu">
        <button
            mat-menu-item
            [ngClass]="{ 'menu-item-active': project.id === currentProjectId }"
            (click)="switchToProject(project)"
            *ngFor="let project of projects$ | async"
        >
            {{ project?.title }}
        </button>
    </mat-menu>
</div>
