import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'string2array'
})
export class String2ArrayPipe implements PipeTransform {
    transform(value: string, args?: any): string[] {
        return value ? value.split(',') : [];
    }
}
