import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoSelectorNoMatchComponent } from '../../auto-selector-no-match/auto-selector-no-match.component';

@Component({
  selector: 'asc-auto-selector-dialog-results',
  templateUrl: './auto-selector-dialog-results.component.html',
  styleUrls: ['./auto-selector-dialog-results.component.scss']
})
export class AutoSelectorDialogResultsComponent {
  @Input()
  filteredItems: any[];

  @Input()
  noMatchComponent?: AutoSelectorNoMatchComponent;

  @Input()
  titleProperty: string;

  @Output()
  itemSelected: EventEmitter<string> = new EventEmitter();

  constructor() {}
}
