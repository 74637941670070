import {
    FormLetterTemplate,
    PredefinedFormLetterTemplate,
    RTFTemplate,
} from 'app/core/rest-api';

/**
 * A common type for all currently available templates.
 */
export type NewFormLetterTemplate =
    | FormLetterTemplate
    | PredefinedFormLetterTemplate;
export type CommonTemplate = NewFormLetterTemplate | RTFTemplate;

/**
 * Possible values for the templateType attribute of templates.
 *
 * TODO: is there a way to get the backend to generate this correctly?, will look
 *       into that when working on visibility
 */
export enum NewFormLetterTemplateType {
    PredefinedFormLetterTemplate = 'PredefinedFormLetterTemplate',
    FormLetterTemplate = 'FormLetterTemplate',
}

/**
 * This is used to determine if a `CommonTemplate` is a old (RTFTemplate) or one
 * of the new Types (PredefinedFormLetterTemplate or FormLetterTemplate,
 * i.e. HTML based). Once support for RTFTEmplate is removed, the CommonTemplate
 * can be simplified to NewFormLetterTemplate and this function can be removed.
 */
export function isFormLetterTemplateOrPredefined(
    template: CommonTemplate
): template is NewFormLetterTemplate {
    return (
        isFormLetterTemplate(template) ||
        isPredefinedFormLetterTemplate(template)
    );
}

export function isPredefinedFormLetterTemplate(
    template: CommonTemplate
): template is PredefinedFormLetterTemplate {
    return (
        template.templateType ===
        NewFormLetterTemplateType.PredefinedFormLetterTemplate
    );
}

/**
 * This is used to determine if a `CommonTemplate` is a custom HTML based FormLetterTemplate. Returns false if it is
 * a predefined template (or an old RTF template).
 */
export function isFormLetterTemplate(
    template: FormLetterTemplate | PredefinedFormLetterTemplate | RTFTemplate
): template is FormLetterTemplate {
    return (
        template.templateType === NewFormLetterTemplateType.FormLetterTemplate
    );
}
