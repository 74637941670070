import { EntityType } from './../../main/sync/models/entity-type';
import { Issue } from 'app/core/rest-api';

export class IssueEntityError {
    type = 'error';
    entityType: EntityType = 'issue';

    // needed for existing logic in the effects
    hasError = true;
    isError = true;

    constructor(
        public entityId: string,
        public action: 'create' | 'update',
        public error?: any
    ) {}
}

export class IssueEntitySuccess {
    type = 'success';

    constructor(public entityId: string, public issue: Issue) {}
}

export class DiaryEntityError {
    type = 'error';
    entityType: EntityType = 'diary';

    // needed for existing logic in the effects
    hasError = true;
    isError = true;

    constructor(
        public entityId: string,
        public action: 'create' | 'update',
        public error?: any
    ) {}
}

export class PlanEntityError {
    type = 'error';
    entityType: EntityType = 'plan';

    // needed for existing logic in the effects
    hasError = true;
    isError = true;

    constructor(
        public entityId: string,
        public action: 'create' | 'update',
        public error?: any
    ) {}
}

export type IssueEntityResponse = EntityError | IssueEntitySuccess;

export type EntityError = IssueEntityError | DiaryEntityError | PlanEntityError;
