import { Action } from '@ngrx/store';
import { SyncCommand } from '../../main/sync/models/sync-command';
import { EntityError } from './entity-response.model';
import { DiscardParameters } from './discard-parameters.model';

export enum SyncActionTypes {
    TriggerFullSync = '[Sync Dashboard] Full Sync',

    StoreCommandRequest = '[APP] Store Command Request',
    StoreCommandSuccess = '[DB] Store Command Success',
    StoreCommandError = '[DB] Store Command Error',

    DeleteCommandRequest = '[APP] Delete Command Request',
    DeleteCommandSuccess = '[DB] Delete Command Success',
    DeleteCommandError = '[DB] Delete Command Error',

    SetForceOffline = '[User Settings | Offline Dialog] Set Force Offline',
    SetProjectsToSync = '[CoreService Init (StartUp)] Set Projects To Sync',

    SetIssueCreatesAttachmentsUploading = '[Sync Issues] Set Uploading of Issue Create Offline Attachments',
    SetIssueUpdatesAttachmentsUploading = '[Sync Issues] Set Uploading of Issue Updates Offline Attachments',
    SetDiaryCreatesAttachmentsUploading = '[Sync Diarys] Set Uploading of Diary Create Offline Attachments',
    SetDiaryUpdatesAttachmentsUploading = '[Sync Diarys] Set Uploading of Diary Updates Offline Attachments',
    SetPlanUpdatesAttachmentsUploading = '[Sync Plans] Set Uploading of Plan Updates Offline Attachments',

    SetCommandSyncAfterOnlineInProgress = '[User Settings | Offline Dialog | SetForceOffline Effect] Set Command Sync After Online In Progress',
    SyncAfterOffline = '[Sync Issue Commands | Upsert Issue Finish] Sync After Offline',

    DownloadAttachmentsRequest = '[Entity DB Service] Download Attachments Request',
    DownloadAttachmentsSuccess = '[Entity DB Service] Download Attachments Success',
    FailedToDownloadAttachments = '[Entity DB Service] Failed To Download Attachments',

    NavigateToSync = '[Issue Effects | Diary Effects] Navigate To Sync',
    AddEntityErrors = '[Issue Effects | Diary Effects] Add Entity Errors',
    DiscardCommandsWithErrors = '[Sync View] Discard Commands With Errors',

    MarkCommandsInFlightForEntities = '[Issue Effects | Diary Effects] Mark Commands In Flight For Entities',
    ClearInFlightFlagOfCommands = '[SyncService] Clear In Flight Flag Of Commands',
}

export class TriggerFullSync implements Action {
    readonly type = SyncActionTypes.TriggerFullSync;
    constructor(public payload: { withGlobalResources: boolean }) {}
}

export class SetPlanUpdatesAttachmentsUploading implements Action {
    readonly type = SyncActionTypes.SetPlanUpdatesAttachmentsUploading;
    constructor(public payload: { uploading: boolean }) {}
}

export class SetIssueCreatesAttachmentsUploading implements Action {
    readonly type = SyncActionTypes.SetIssueCreatesAttachmentsUploading;
    constructor(public payload: { uploading: boolean }) {}
}
export class SetIssueUpdatesAttachmentsUploading implements Action {
    readonly type = SyncActionTypes.SetIssueUpdatesAttachmentsUploading;
    constructor(public payload: { uploading: boolean }) {}
}
export class SetDiaryCreatesAttachmentsUploading implements Action {
    readonly type = SyncActionTypes.SetDiaryCreatesAttachmentsUploading;
    constructor(public payload: { uploading: boolean }) {}
}
export class SetDiaryUpdatesAttachmentsUploading implements Action {
    readonly type = SyncActionTypes.SetDiaryUpdatesAttachmentsUploading;
    constructor(public payload: { uploading: boolean }) {}
}

export class DeleteCommandRequest implements Action {
    readonly type = SyncActionTypes.DeleteCommandRequest;
    constructor(public payload: { query: any }) {}
}

export class DeleteCommandSuccess implements Action {
    readonly type = SyncActionTypes.DeleteCommandSuccess;
    constructor(public payload: { query: any }) {}
}

export class DeleteCommandError implements Action {
    readonly type = SyncActionTypes.DeleteCommandError;
    constructor(public payload: { error?: any }) {}
}

export class StoreCommandRequest implements Action {
    readonly type = SyncActionTypes.StoreCommandRequest;
    constructor(public payload: { command: SyncCommand<any> }) {}
}

export class StoreCommandSuccess implements Action {
    readonly type = SyncActionTypes.StoreCommandSuccess;
    constructor(
        public payload: {
            command: SyncCommand<any>;
            databaseId: string;
        }
    ) {}
}

export class StoreCommandError implements Action {
    readonly type = SyncActionTypes.StoreCommandError;
    constructor(public payload: { error?: any }) {}
}

export class SetForceOffline implements Action {
    readonly type = SyncActionTypes.SetForceOffline;
    constructor(public payload: { forceOffline: boolean }) {}
}

export class SetProjectsToSync implements Action {
    readonly type = SyncActionTypes.SetProjectsToSync;
    constructor(public payload: { projectsToSync: string[] }) {}
}

export class SetCommandSyncAfterOnlineInProgress implements Action {
    readonly type = SyncActionTypes.SetCommandSyncAfterOnlineInProgress;
    constructor(public payload: { inProgress: boolean }) {}
}

export class SyncAfterOffline implements Action {
    readonly type = SyncActionTypes.SyncAfterOffline;
}

export class DownloadAttachmentsRequest implements Action {
    readonly type = SyncActionTypes.DownloadAttachmentsRequest;
    constructor(public payload: { pending: number }) {}
}

export class DownloadAttachmentsSuccess implements Action {
    readonly type = SyncActionTypes.DownloadAttachmentsSuccess;
    constructor() {}
}

export class FailedToDownloadAttachments implements Action {
    readonly type = SyncActionTypes.FailedToDownloadAttachments;
    constructor(
        public payload: {
            failedDownloads: { id: string; error: any }[];
        }
    ) {}
}

export class AddEntityErrors implements Action {
    readonly type = SyncActionTypes.AddEntityErrors;

    constructor(
        public payload: {
            errors: EntityError[];
        }
    ) {}
}

export class DiscardCommandsWithErrors implements Action {
    readonly type = SyncActionTypes.DiscardCommandsWithErrors;

    constructor(
        public payload: {
            discardParameters: DiscardParameters[];
        }
    ) {}
}

export class NavigateToSync implements Action {
    readonly type = SyncActionTypes.NavigateToSync;

    constructor() {}
}

export class MarkCommandsInFlightForEntities implements Action {
    readonly type = SyncActionTypes.MarkCommandsInFlightForEntities;
    constructor(public payload: { entityIds: string[] }) {}
}

export class ClearInFlightFlagOfCommands implements Action {
    readonly type = SyncActionTypes.ClearInFlightFlagOfCommands;
    constructor() {}
}

export type SyncActions =
    | StoreCommandSuccess
    | StoreCommandError
    | DeleteCommandRequest
    | DeleteCommandSuccess
    | DeleteCommandError
    | SetForceOffline
    | SetProjectsToSync
    | SetIssueCreatesAttachmentsUploading
    | SetIssueUpdatesAttachmentsUploading
    | SetDiaryCreatesAttachmentsUploading
    | SetDiaryUpdatesAttachmentsUploading
    | SetPlanUpdatesAttachmentsUploading
    | TriggerFullSync
    | SetCommandSyncAfterOnlineInProgress
    | SyncAfterOffline
    | DownloadAttachmentsRequest
    | DownloadAttachmentsSuccess
    | FailedToDownloadAttachments
    | AddEntityErrors
    | DiscardCommandsWithErrors
    | NavigateToSync
    | MarkCommandsInFlightForEntities
    | ClearInFlightFlagOfCommands;
