import { MongoFile } from 'app/core/rest-api/model/mongoFile';

export class BlobToStore {
    type: 'blob-to-store';
    attachmentId: string;
    blob: Blob;
    metadata: MongoFile;
}

export class MetadataToStore {
    type: 'metadata-to-store';
    attachmentId: string;
    metadata: MongoFile;
}

export type StoreRequest = BlobToStore | MetadataToStore;

export interface DownloadResult {
    id: string;
    storeRequest?: StoreRequest;
    error?: any;
}
export abstract class AttachmentDownloaderService {
    constructor() {}

    abstract downloadAttachmentMetadata(
        ids: Set<string>
    ): Promise<DownloadResult[]>;
    abstract downloadAttachments(ids: Set<string>): Promise<DownloadResult[]>;
}
