import { RxJsonSchema } from 'rxdb';

export const locationSchema: RxJsonSchema = {
    title: 'location schema',
    description: 'describes a location',
    version: 1,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true,
        },
        title: {
            type: ['string', 'null'],
        },
        ownerId: {
            type: ['string', 'null'],
        },
        updaterId: {
            type: ['string', 'null'],
        },
        creatorId: {
            type: ['string', 'null'],
        },
        creationDate: {
            type: 'string',
        },
        markedAsDelete: {
            type: ['boolean', 'null'],
        },
        deleteDateTime: {
            type: ['string', 'null'],
        },
        updateDateTime: {
            type: 'string',
        },
        projectId: {
            type: 'string',
        },
        order: {
            type: ['number', 'null'],
        },
        parentID: {
            type: ['string', 'null'],
        },
        craftToPlan: {
            type: 'object',
            additionalProperties: true,
        },
    },
    indexes: ['projectId'],
    additionalProperties: false,
};
