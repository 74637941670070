import { Injectable } from '@angular/core';
import { AutoSelectorFilterParams } from './auto-selector.filter-params';
@Injectable({
  providedIn: 'root'
})
export class AutoSelectorService {
  constructor() {}

  buildTree(items: any[], id: any): any[] {
    let newTree = [];
    // this.selectedItems = [];
    let currentItem = items.find(l => l.id === id);
    if (!currentItem) {
      if (id) {
        newTree.push({
          title: `ID ${id} not found`
        });
      }
      return newTree;
    }
    // while not at root
    while (currentItem.parentID) {
      newTree = [currentItem, ...newTree];
      const parent = items.find(l => l.id === currentItem.parentID);
      if (parent) {
        currentItem = parent;
      } else {
        currentItem = {
          title: 'P-ID? ' + currentItem.parentID
        };
      }
    }
    newTree = [currentItem, ...newTree];
    return newTree;
  }

  filterByParent(o: AutoSelectorFilterParams): boolean {
    if (!o.tree || o.mode !== 'multi') {
      return true;
    }

    if (o.selectedItems.length === 0) {
      const { parentID } = o.item;
      const isAspNetDefaultGuid =
        parentID === '00000000-0000-0000-0000-000000000000';
      return !parentID || isAspNetDefaultGuid;
    }

    return o.item.parentID === o.selectedItems[o.selectedItems.length - 1].id;
  }

  /**
   * Location Specific
   */
  filterByLocationTree(o: AutoSelectorFilterParams): boolean {
    if (o.mode !== 'single' || !o.tree) {
      return true;
    }
    if (o.selectedItems.length === 0) {
      return !o.item.parentID;
    }
    return o.item.parentID === o.selectedItems[o.selectedItems.length - 1].id;
  }

  filterDuplicates(o: AutoSelectorFilterParams): boolean {
    if (o.mode === 'multi' && o.tree === false && o.duplicates === false) {
      const item = o.selectedItems.find(
        i => i[o.idProperty] === o.item[o.idProperty]
      );
      if (item) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
