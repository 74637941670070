<div fxLayout="column" fxLayoutAlign="start start" class="w-100-p h-100-p">
    <div
        *ngIf="showHeader"
        class="container planDialogHeader w-100-p px-8"
        fxLayout="row"
        fxLayout.xs="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
        fxLayoutGap.xs="0"
    >
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxFlex="15%"
            fxFlex.xs="55"
        >
            <button mat-button (click)="closeDialogWithoutSaving()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div fxFlex style="text-align: center">
            <mat-form-field
                [style.visibility]="isRevisionFetched ? 'visible' : 'hidden'"
            >
                <mat-select
                    [(value)]="selectedRevision"
                    (selectionChange)="onRevisionChange($event)"
                >
                    <mat-option
                        *ngFor="let revision of fetchedMatSelectRevision"
                        [value]="revision"
                    >
                        {{
                            revision.revision.createTime
                                | date: 'medium':undefined:translate.currentLang
                        }}
                        {{ revision.tag }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div
            *ngIf="assetDownloadService.downloadAllowed"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxFlex="15%"
            fxFlex.xs="55"
            fxFlexOrder="3"
            style="text-align: right"
        >
            <button mat-button (click)="exportToImage()">
                <mat-icon>arrow_downward</mat-icon>Export
            </button>
        </div>
    </div>

    <div #canvasWrapper class="plan-preview-content w-100-p h-100-p">
        <div
            id="menu"
            [ngClass]="readOnly || isRevisionMode ? 'menu-mini' : 'danger'"
            fxLayout="column"
            fxLayoutAlign="center start"
        >
            <mat-button-toggle-group
                *ngIf="!(readOnly || isRevisionMode)"
                (change)="onStatusChange(group.value)"
                vertical
                #group="matButtonToggleGroup"
                value="move"
            >
                <mat-button-toggle value="move">
                    <mat-icon>pan_tool</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="move-annotation">
                    <mat-icon>open_with</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="marker">
                    <mat-icon>location_on</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="arrow">
                    <mat-icon>call_made</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="rect">
                    <mat-icon>crop_square</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="oval">
                    <mat-icon>panorama_fish_eye</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div
                class="sub-menu"
                fxLayout="column"
                fxLayoutAlign="start start"
                [ngClass]="{
                    'no-left-border': hideLeftBorderOfReadOnlyTools
                }"
            >
                <button class="scale-btn" mat-icon-button (click)="zoomIn()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button
                    class="scale-btn sub-menu-border"
                    mat-icon-button
                    (click)="zoomOut()"
                >
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button
                    class="scale-btn sub-menu-border"
                    mat-icon-button
                    (click)="resetScaling()"
                >
                    <mat-icon>youtube_searched_for</mat-icon>
                </button>
                <button
                    data-test="deleteMarkerButton"
                    style="background: #ff1314 !important"
                    *ngIf="isAnnotationSelected"
                    mat-button
                    (click)="deleteSelectedAnnotation()"
                >
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
        </div>

        <div class="wrapper-zoom-area" #zoomArea>
            <div class="wrapper wrapper-translation" #wrapperTranslation>
                <canvas class="pdfViewer" #planCanvas> </canvas>

                <canvas
                    class="annotations-viewer"
                    data-test="annotaterCanvas"
                    #annotaterCanvas
                ></canvas>

                <!-- The loading shade should not be translated, move to wrapper-zoom-area? -->
                <div
                    class="loading-shade"
                    *ngIf="_isLoading"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                >
                    <mat-spinner
                        class="spinner"
                        color="accent"
                        mode="indeterminate"
                    ></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>
<button
    *ngIf="isEdited && !_isLoading"
    (click)="closeDialogWithSaving()"
    style="margin: 10px; background-color: #8bc34a"
    class="fab-bottom-right"
    mat-fab
    [matTooltip]="'APP.SAVE' | translate"
    matTooltipPosition="left"
>
    <mat-icon>save</mat-icon>
</button>
