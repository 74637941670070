import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { CustomAttributeService, MarkedPlansService, Project, ProjectsService } from 'app/core/rest-api';
import { ErrorUtils } from 'app/core/utils/error-util';
import { ProjectDbService } from 'app/db/projects/project.db.service';
import { SyncService } from 'app/main/sync/sync.service';
import { defer, forkJoin, from, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { getIsLoggedIn } from '../auth/auth.selectors';
import { getIsDevice, getIsOnline } from '../core/core.selectors';
import { LoadProjectTemplatesRequest } from '../project-templates/project-templates.actions';
import {
    CreateProjectCustomAttributesError,
    CreateProjectCustomAttributesRequest,
    CreateProjectError,
    CreateProjectRequest,
    CreateProjectSuccess,
    DeleteProjectCustomAttributesError,
    DeleteProjectCustomAttributesRequest,
    LoadMarkedPlansInfoRequest,
    LoadMarkedPlansInfoSuccess,
    LoadProjectsError,
    LoadProjectsRequest,
    LoadProjectsSuccess,
    ProjectCustomAttributeActionPayload,
    ProjectsActionTypes,
    UpdateProjectCustomAttributesError,
    UpdateProjectCustomAttributesRequest,
    UpdateProjectError,
    UpdateProjectRequest,
    UpdateProjectsError,
    UpdateProjectsRequest,
    UpdateProjectsSuccess,
    UpdateProjectSuccess,
    UpsertProjectsFinish,
    UpsertProjectsStart,
} from './projects.actions';
import { ProjectsState } from './projects.reducer';
import { unreachable } from '../../core/utils/ts-utils';

@Injectable()
export class ProjectsEffects {
    unnecessaryProperties: string[] = [
        'id',
        'creationDate',
        'ownerId',
        'ownerId',
        'owner',
        'updaterId',
        'updater',
        'creatorId',
        'creator',
        'markedAsDelete',
        'deleteDateTime',
        'updateDateTime',
        'isTemplate',
    ];

    @Effect()
    loadProjects$ = this.actions$.pipe(
        ofType<LoadProjectsRequest>(ProjectsActionTypes.LOAD_PROJECTS_REQUEST),
        withLatestFrom(this.store.pipe(select(getIsOnline))),
        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([[action, isOnline], isLoggedIn]) => isLoggedIn),
        switchMap(([[action, isOnline], isLoggedIn]) => {
            let withArchived: boolean;
            if (isOnline) {
                withArchived = action.payload.withArchived;
            }

            const server$ = this.projectsService.projectsGet(withArchived);
            const db$ = defer(() => from(this.projectDb.getAll()));
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadProjectsSuccess({
                                projects: response.data as Project[],
                                fromDb: response.fromDb,
                            })
                    ),
                    catchError(() => of(new LoadProjectsError()))
                );
        })
    );

    @Effect()
    loadProjectsSuccess$ = this.actions$.pipe(
        ofType<LoadProjectsSuccess>(ProjectsActionTypes.LOAD_PROJECTS_SUCCESS),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.projects),
        switchMap((projects) =>
            of(
                new UpsertProjectsStart({
                    projects,
                })
            )
        )
    );

    @Effect()
    loadMarkedPlansInfoRequest$ = this.actions$.pipe(
        ofType<LoadMarkedPlansInfoRequest>(ProjectsActionTypes.LOAD_MARKED_PLANS_INFO_REQUEST),
        map((action) => action.payload.projectId),
        switchMap((projectId) => this.markedPlansService.getProjectMarkedPlansInfo(projectId).pipe(map((response) => {
            return new LoadMarkedPlansInfoSuccess({
                markedPlansInfo: response.data,
            })
        }))
        )
    );

    @Effect()
    upsertProjectsStart$ = this.actions$.pipe(
        ofType<UpsertProjectsStart>(ProjectsActionTypes.UPSERT_PROJECTS_START),
        map((action) => action.payload.projects),
        switchMap((projects) =>
            from(this.projectDb.bulkInsert(projects)).pipe(
                map((results) => new UpsertProjectsFinish({ results }))
            )
        )
    );

    @Effect({ dispatch: false })
    errorLoadProjects$ = this.actions$.pipe(
        ofType<LoadProjectsError>(ProjectsActionTypes.LOAD_PROJECTS_ERROR),
        switchMap((error: LoadProjectsError) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'PROJECTS.LOAD');
            return of();
        })
    );

    @Effect()
    createProject$ = this.actions$.pipe(
        ofType<CreateProjectRequest>(
            ProjectsActionTypes.CREATE_PROJECT_REQUEST
        ),
        // TODO: Set language dynamic?
        mergeMap((action) => {
            const project = this.getProjectWithoutUnnecessaryTemplateProperties(
                action.payload.project
            );

            return this.projectsService
                .projectsCreatePost(
                    action.payload.templateProjectId,
                    'de',
                    false,
                    project
                )
                .pipe(
                    map(
                        (response) =>
                            new CreateProjectSuccess({ project: response.data })
                    ),
                    catchError((error) => of(new CreateProjectError()))
                );
        })
    );

    @Effect()
    createProjectCustomAttributes = this.actions$.pipe(
        ofType<CreateProjectCustomAttributesRequest>(
            ProjectsActionTypes.CREATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST
        ),
        mergeMap((action) =>
            this.customAttributeService
                .projectsByProjectIdCustomattributePost(
                    action.payload.projectId,
                    action.payload.template
                )
                .pipe(
                    map((response) => this.createProjectLoadAction(action.payload)),
                    catchError((error) => of(new CreateProjectCustomAttributesError()))
                )
        )
    );

    @Effect({ dispatch: false })
    errorCreateProject$ = this.actions$.pipe(
        ofType<CreateProjectError>(ProjectsActionTypes.CREATE_PROJECT_ERROR),
        switchMap((error: CreateProjectError) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'PROJECTS.CREATE'
            );
            return of();
        })
    );

    @Effect()
    updateProject$ = this.actions$.pipe(
        ofType<UpdateProjectRequest>(
            ProjectsActionTypes.UPDATE_PROJECT_REQUEST
        ),
        mergeMap((action) =>
            this.projectsService
                .projectsByProjectIdPatch(
                    action.payload.project.id,
                    action.payload.project
                )
                .pipe(
                    map(
                        (response) =>
                            new UpdateProjectSuccess({ project: response.data })
                    ),
                    catchError((error) => of(new UpdateProjectError()))
                )
        )
    );

    @Effect()
    updateProjectCustomAttributes = this.actions$.pipe(
        ofType<UpdateProjectCustomAttributesRequest>(
            ProjectsActionTypes.UPDATE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST
        ),
        mergeMap((action) =>
            this.customAttributeService
                .projectsByProjectIdCustomattributeByIdPatch(
                    action.payload.template.id,
                    action.payload.projectId,
                    action.payload.template
                )
                .pipe(
                    map((response) => this.createProjectLoadAction(action.payload)),
                    catchError((error) => of(new UpdateProjectCustomAttributesError()))
                )
        )
    );

    @Effect()
    deleteProjectCustomAttributes = this.actions$.pipe(
        ofType<DeleteProjectCustomAttributesRequest>(
            ProjectsActionTypes.DELETE_PROJECT_CUSTOM_ATTRIBUTES_REQUEST
        ),
        mergeMap((action) =>
            this.customAttributeService
                .projectsByProjectIdCustomattributeByIdDelete(
                    action.payload.template.id,
                    action.payload.projectId
                )
                .pipe(
                    map((response) => this.createProjectLoadAction(action.payload)),
                    catchError((error) => of(new DeleteProjectCustomAttributesError()))
                )
        )
    );

    @Effect({ dispatch: false })
    errorUpdateProject$ = this.actions$.pipe(
        ofType<UpdateProjectError>(ProjectsActionTypes.UPDATE_PROJECT_ERROR),
        switchMap((error: UpdateProjectError) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'PROJECTS.UPDATE'
            );

            return of();
        })
    );

    @Effect()
    updateProjects$ = this.actions$.pipe(
        ofType<UpdateProjectsRequest>(
            ProjectsActionTypes.UPDATE_PROJECTS_REQUEST
        ),
        map((action) => {
            const requests = [];
            for (const project of action.payload.projects) {
                requests.push(
                    this.projectsService
                        .projectsByProjectIdPatch(project.id, project)
                        .pipe(
                            take(1),
                            catchError((error) => of(new UpdateProjectsError()))
                        )
                );
            }
            return requests;
        }),
        mergeMap((requests) => {
            return forkJoin(requests).pipe(
                map((results) => {
                    const updatedProjects = results.map(
                        (result: any) => result.data
                    );
                    return new UpdateProjectsSuccess({
                        projects: updatedProjects,
                    });
                })
            );
        })
    );

    @Effect({ dispatch: false })
    errorUpdateProjects$ = this.actions$.pipe(
        ofType<UpdateProjectsError>(ProjectsActionTypes.UPDATE_PROJECTS_ERROR),
        switchMap((error: UpdateProjectsError) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'PROJECTS.UPDATE_MANY'
            );

            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private projectsService: ProjectsService,
        private markedPlansService: MarkedPlansService,
        private customAttributeService: CustomAttributeService,
        private store: Store<ProjectsState>,
        private projectDb: ProjectDbService,
        private syncService: SyncService,
        private errorUtils: ErrorUtils
    ) { }

    private getProjectWithoutUnnecessaryTemplateProperties(
        project: Project
    ): Project {
        const resultProject = { ...project };

        for (const property of this.unnecessaryProperties) {
            resultProject[property] = undefined;
        }

        return resultProject;
    }

    /** Create the apprirate next Action (LoadProjectsRequest or LoadProjectTemplatesRequest) */
    private createProjectLoadAction(action: ProjectCustomAttributeActionPayload): Action {
        switch (action.projectType) {
            case 'PROJECT':
                return new LoadProjectsRequest();
            case 'PROJECT_TEMPLATE':
                return new LoadProjectTemplatesRequest();
            default:
                unreachable(action.projectType);
        }
    }

}

