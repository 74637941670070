import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgLetModule } from '@ngrx-utils/store';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { TranslateModule } from '@ngx-translate/core';
import { FulfillingSquareSpinnerModule } from 'angular-epic-spinners';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { AddAssetComponent } from './components/add-asset/add-asset.component';
import { AssetTileComponent } from './components/asset-tile/asset-tile.component';
import { BannerComponent } from './components/banner/banner.component';
import { ConflictSnackbarComponent } from './components/conflict-snackbar/conflict-snackbar.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { FileDialogComponent } from './components/dialogs/file-dialog/file-dialog.component';
import { OfflineDialogComponent } from './components/dialogs/offline-dialog/offline-dialog.component';
import { PlanPreviewDialogModule } from './components/dialogs/plan-preview-dialog/plan-preview-dialog.module';
import { SearchDialogComponent } from './components/dialogs/search-dialog/search-dialog.component';
import { UserSettingsDialogComponent } from './components/dialogs/user-settings-dialog/user-settings-dialog.component';
import { DemoDialogComponent } from './components/dialogs/demo-dialog/demo-dialog.component';
import { EmptyBannerComponent } from './components/empty-banner/empty-banner.component';
import { EmptyInfoComponent } from './components/empty-info/empty-info.component';
import { EntityTableComponent } from './components/entity-table/entity-table.component';
import { LanguageSwitcherModule } from './components/language-switcher/language-switcher.module';
import { NumericInputFieldModule } from './components/numeric-input-field/numeric-input-field.module';
import { PullToRefreshComponent } from './components/pull-to-refresh/pull-to-refresh.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SubscribedServiceSelectorComponent } from './components/subscribed-service-selector/subscribed-service-selector.component';
import { TabLabelComponent } from './components/tab-label/tab-label.component';
import { TableComponent } from './components/table/table.component';
import { LabeledSlideToggleComponent } from './components/labeled-slide-toggle/labeled-slide-toggle.component';
import { GenericFilterMenuModule } from './components/generic-filter-menu/generic-filter-menu.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { LogoutDialogComponent } from './services/logout/logout-dialog.component';
import { PrintDialogModule } from './components/print-dialog/print-dialog.module';
import { DemoModule } from './services/demo/demo.module';
import { AutoSelectorModule } from 'app/shared/components/auto-selector/auto-selector.module';

const materialModules = [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
];

registerPlugin(
    // stores files as in-memory data
    FilePondPluginFileEncode,
    // // validates files based on input type
    FilePondPluginFileValidateType,
    // corrects mobile image orientation
    FilePondPluginImageExifOrientation,
    // previews the image
    FilePondPluginImagePreview,
    /** crops the image to a certain aspect ratio
     *  needed for exif / transformation plugin to rotate the image
     */
    FilePondPluginImageCrop,
    // resizes the image to fit a certain size
    FilePondPluginImageResize,
    // applies crop and resize information on the client
    FilePondPluginImageTransform
);

@NgModule({
    imports: [
        ...materialModules,
        AutoSelectorModule,
        FilePondModule,
        FlexLayoutModule,
        FuseSharedModule,
        GalleryModule,
        LanguageSwitcherModule,
        NgLetModule,
        PlanPreviewDialogModule,
        FilePondModule,
        GalleryModule,
        AutoSelectorModule,
        NumericInputFieldModule,
        FulfillingSquareSpinnerModule,
        TranslateModule.forChild(),
        SharedPipesModule,
        PrintDialogModule,
        DemoModule,
    ],
    declarations: [
        EmptyInfoComponent,
        ConfirmDialogComponent,
        FileDialogComponent,
        SearchDialogComponent,
        EmptyBannerComponent,
        UserSettingsDialogComponent,
        DemoDialogComponent,
        SnackbarComponent,
        AddAssetComponent,
        BannerComponent,
        AssetTileComponent,
        TabLabelComponent,
        OfflineDialogComponent,
        TableComponent,
        SubscribedServiceSelectorComponent,
        EntityTableComponent,
        PullToRefreshComponent,
        ConflictSnackbarComponent,
        LabeledSlideToggleComponent,
        LogoutDialogComponent,
    ],
    exports: [
        FuseSharedModule,
        TranslateModule,
        NgLetModule,
        FilePondModule,
        GalleryModule,
        LightboxModule,
        EmptyInfoComponent,
        AddAssetComponent,
        BannerComponent,
        EmptyBannerComponent,
        AssetTileComponent,
        ConfirmDialogComponent,
        TabLabelComponent,
        AutoSelectorModule,
        TableComponent,
        SubscribedServiceSelectorComponent,
        EntityTableComponent,
        NumericInputFieldModule,
        SnackbarComponent,
        ConflictSnackbarComponent,
        PullToRefreshComponent,
        LabeledSlideToggleComponent,
        GenericFilterMenuModule,
        PlanPreviewDialogModule,
        SharedPipesModule,
        FileDialogComponent,
    ],
})
export class SharedModule {}
