import { DailyLogEntryBase } from 'app/core/rest-api';
import { SyncCommand } from 'app/main/sync/models/sync-command';
import { DiaryEntry } from 'app/store/diary/models/diary-entry';

export class DiaryCommand extends SyncCommand<DiaryEntry> {
    constructor(
        diary: DiaryEntry | DailyLogEntryBase,
        diaryChanges: Partial<DiaryEntry | DailyLogEntryBase>
    ) {
        const { id, projectId, updateDateTime, type } = diary;
        super();
        this.action = 'create';
        this.projectId = projectId;
        this.entityType = 'diary';
        this.waitForCreation = false;
        this.locked = false;
        this.entityId = 'NEW_' + new Date().getTime().toString();
        let base: any = { ...diary };
        if (id) {
            this.action = 'update';
            this.entityId = id;
            base = { updateDateTime, type };
        }
        this.changes = {
            ...base,
            ...diaryChanges,
        };
    }
}
