/**
 * A very simple vector interface with x and y coordinates.
 */
export interface Vec2D {
    x: number;
    y: number;
}

export class Vec2D implements Vec2D {
    /** The midpoint, or average of the points */
    static midpoint(a: Vec2D, b: Vec2D): Vec2D {
        return {
            x: (a.x + b.x) / 2,
            y: (a.y + b.y) / 2,
        };
    }

    /** Mix the points to get 4 corners */
    static boundingPoints(a: Vec2D, b: Vec2D): [Vec2D, Vec2D, Vec2D, Vec2D] {
        // Handle the X/Y split
        type TMinMax = Math['min']|Math['max'];
        function minMax(fX: TMinMax, fY: TMinMax ): Vec2D {
            return {
                x: fX(a.x, b.x),
                y: fY(a.y, b.y),
            };
        }

        // Iterate on the points
        return [
            minMax( Math.min, Math.min),
            minMax( Math.min, Math.max),
            minMax( Math.max, Math.max),
            minMax( Math.max, Math.min),
        ];
    } 

    static distance(a: Vec2D, b: Vec2D): number {
        const dX = a.x - b.x;
        const dY = a.y - b.y;
        return Math.sqrt(dX * dX + dY * dY);
    }

    static scalarMult(a: Vec2D, val: number): Vec2D {
        return {
            x: a.x * val,
            y: a.y * val,
        };
    }

    /**
     * Scales, then shifts/translates
     * @param p  Input point. Parital Vec2D, because the generated types are partial. Defaults to value 0
     * @param t  Transform to apply
     * @returns the new point
     */
    static transform(p: Partial<Vec2D>, t: Transform): Vec2D {
        return {
            x: (p.x || 0) * t.scale + t.translationX,
            y: (p.y || 0) * t.scale + t.translationY,
        };
    }

    /** Static constructor */
    static of(x: number, y: number): Vec2D {
        return {x, y};
    }
}


export interface Rectangle {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

export abstract class Rectangle implements Rectangle {
    static fromPoints(p1: Vec2D, p2: Vec2D): Rectangle {
        return {
            top:    Math.min(p1.y, p2.y),
            bottom: Math.max(p1.y, p2.y),
            left:   Math.min(p1.x, p2.x),
            right:  Math.max(p1.x, p2.x),
        };
    }

    /** Expand (or shrink for negative values) the rectangle boundries */
    static grow(r: Rectangle, delta: number): Rectangle {
        return {
            top:    r.top    - delta,
            bottom: r.bottom + delta,
            left:   r.left   - delta,
            right:  r.right  + delta,
        };
    }

    /** Returns the minimum rectange to fully contain both passed rectangles  */
    static growToInclude(a: Rectangle, b: Rectangle): Rectangle {
        return {
            top:    Math.min(a.top,    b.top),
            bottom: Math.max(a.bottom, b.bottom),
            left:   Math.min(a.left,   b.left),
            right:  Math.max(a.right,  b.right),
        };
    }

    static containsPoint(r: Rectangle, p: Vec2D): boolean {
        return p.x >= r.left &&
            p.x <= r.right  &&
            p.y >= r.top &&
            p.y <= r.bottom;
    }

    static getWidth(rect: Rectangle): number {
        return rect.right - rect.left;
    }
    static getHeight(rect: Rectangle): number {
        return rect.bottom - rect.top;
    }

    static midpoint(rect: Rectangle): Vec2D {
        return Vec2D.midpoint(
            {x: rect.left, y: rect.top},
            {x: rect.right, y: rect.bottom}
        );
    }
}


/**
 * A utility interface represenenting the plan's transformation
 * (mainly in new code).
 */
export interface Transform {
    scale: number;
    translationX: number;
    translationY: number;
}

export abstract class Transform implements Transform {
    /**
     * Update the existing transformation with a new one.
     *
     * @param existing The existing transformation.
     * @param update The update.
     */
    static updateExistingTransformation(
        existing: Transform,
        update: Transform,
        scaleMin: number,
        scaleMax: number,
    ): Transform {
        let updateScale = update.scale; // Temp variable, will be changed if capped

        // apply new scaling to previous scaling
        let newScale = existing.scale * update.scale;

        // if ( newScale > scaleMax ) {
        //     updateScale =  existing.scale / scaleMax;
        //     newScale = scaleMax;
        // } else if ( newScale < scaleMin ) {
        //     updateScale =  existing.scale / scaleMin;
        //     newScale = scaleMin;
        // }


        // apply new translation to existing translation taking the
        // scale change into account
        const newTranslationX =
            updateScale * existing.translationX + update.translationX;
        const newTranslationY =
            updateScale * existing.translationY + update.translationY;

        return {
            scale: newScale,
            translationX: newTranslationX,
            translationY: newTranslationY,
        };
    }
}
