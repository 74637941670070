<mat-form-field fxFlex>
    <mat-label>
        {{ filterItem.labelTranslationIdentifier | translate }}</mat-label
    >
    <input
        (focus)="datePicker.open()"
        (click)="datePicker.open()"
        matInput
        [matDatepicker]="datePicker"
        [min]="filterItem.minValue"
        [max]="filterItem.maxValue"
        [(ngModel)]="filterItem.stringValue"
    />
    <mat-datepicker-toggle matPrefix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker [touchUi]="true" #datePicker></mat-datepicker>
</mat-form-field>
