import { MarkerAnnotation } from 'app/core/rest-api/model/markerAnnotation';
import { unreachable_safe } from 'app/core/utils/ts-utils';
import { Rectangle, Transform, Vec2D } from './transform.model';


export interface HitTestArgs {
    p: Vec2D;
    transform: Transform;
    annotation: MarkerAnnotation;
    tolerance: number;
}

export interface HitTestResult {
    /** If we are within the boundry */
    hit: boolean;
    /** If this is very near the boundry (defined by `HitTestArgs.tollerance`) */
    solidHit: boolean;
}

const solidHitThreshold = 3;

/**
 * TODO - Shape handling needs be more consistent, but the Marker is the only non-generic type we have at the moment
 */
export abstract class MarkerInfo {
    static readonly _markerHeight = 66;
    static readonly _markerWidth = 46;
    static readonly halfMarkerWidth = MarkerInfo._markerWidth / 2;

    static getMarkerBoundry(shape: MarkerAnnotation): Rectangle {
        return {
            left:  shape.x - this.halfMarkerWidth,
            right: shape.x + this.halfMarkerWidth,
            top:    shape.y - this._markerHeight,
            bottom: shape.y,
        };
    }
}

export class HitTest {
    static check(
        args: HitTestArgs,
    ): HitTestResult {
        switch (args.annotation.type) {
            case 'Marker':
                return HitTest.marker(args);
            case 'Line': // Fallthrough to Arrow
            case 'Arrow':
                return HitTest.arrow(args);
            case 'Rectangle':
                return HitTest.rectangle(args);
            case 'Circle':
                return HitTest.circle(args);
            default:
                unreachable_safe(args.annotation.type);
        }
    }

    static marker(
        args: HitTestArgs,
    ): HitTestResult {
        const t: Transform = args.transform;
        const imgX = args.annotation.x * t.scale + t.translationX - (args.annotation.width / 2) * t.scale;
        const imgY = args.annotation.y * t.scale + t.translationY -  args.annotation.height     * t.scale;

        const hit = (
            args.p.x > imgX &&
            args.p.x < imgX + args.annotation.width * t.scale &&
            args.p.y > imgY &&
            args.p.y < imgY + args.annotation.height * t.scale
        );
        return {
            hit,
            solidHit: hit,
        };
    }

    static arrow(
        args: HitTestArgs,
    ): HitTestResult {
        const p1 = Vec2D.transform(args.annotation, args.transform);
        const p2 = Vec2D.transform({x: args.annotation.x1, y: args.annotation.y1}, args.transform);
        const rect = Rectangle.fromPoints(p1, p2);

        if ( !Rectangle.containsPoint(rect, args.p) ) {
            return {hit: false, solidHit: false};
        }

        const linepoint: Vec2D = this.linepointNearestMouse(p1, p2, args.p);
        const distance = Vec2D.distance(linepoint, args.p);
        const hit = distance < (args.tolerance);
        return {
            hit,
            solidHit: hit,
        };
    }

    static rectangle(
        args: HitTestArgs,
    ): HitTestResult {
        const p1 = Vec2D.transform(args.annotation, args.transform);
        const p2 = Vec2D.transform({x: args.annotation.x1, y: args.annotation.y1}, args.transform);
        const rect = Rectangle.fromPoints(p1, p2);
        const thresholdRect = Rectangle.grow(rect, args.tolerance);
        const innerRect = Rectangle.grow(rect, -1 * solidHitThreshold * args.tolerance );

        const hit: boolean = Rectangle.containsPoint(thresholdRect, args.p);
        return {
            hit,
            solidHit: hit && !Rectangle.containsPoint(innerRect, args.p),
        };
    }

    static circle(
        args: HitTestArgs,
    ): HitTestResult {
        const p1 = Vec2D.transform(args.annotation, args.transform);
        const p2 = Vec2D.transform({x: args.annotation.x1, y: args.annotation.y1}, args.transform);
        const rect = Rectangle.fromPoints(p1, p2);

        // Can we skip the expensive calculations below?
        const thresholdRect = Rectangle.grow(rect, args.tolerance);
        if ( !Rectangle.containsPoint(thresholdRect, args.p) ) {
            return {hit: false, solidHit: false};
        }

        // Radius components for the ellipse
        const xRadius = Rectangle.getWidth(rect) / 2 + .001; // Cheat to avoid a divide by 0 error
        const yRadius = Rectangle.getHeight(rect) / 2 + .001; // Cheat to avoid a divide by 0 error

        const center = Rectangle.midpoint(rect);

        // Calculate distance to ellipse edge, in the direction of the cusor at {x, y}
        // See: https://warpycode.wordpress.com/2011/01/21/calculating-the-distance-to-the-edge-of-an-ellipse/
        // Basically, it's a squashed circle, so it's a (more complex) variation of Pythagoras' Therom.
        const theta = Math.atan2(center.x - args.p.x, center.y - args.p.y); // The positive/negative directions aren't checked, but it doesn't matter for a symetrical ellipse
        const xPart = Math.sin(theta) / xRadius;
        const yPart = Math.cos(theta) / yRadius;
        const distCircumferance = Math.sqrt( 1 / (xPart * xPart + yPart * yPart));

        // Distance, cursor to center of elipse
        const dist = Vec2D.distance(args.p, center);

        const hit: boolean = dist <= (distCircumferance + args.tolerance);
        return {
            hit,
            solidHit: hit && dist >= (distCircumferance - solidHitThreshold * args.tolerance),
        };
    }

    /**
     *
     * @param p1 Start of the line segment
     * @param p2 End of the line segement
     * @param ref The reference point
     * @returns
     */
    private static linepointNearestMouse(p1: Vec2D, p2: Vec2D, ref: Vec2D ): Vec2D {
        const lerp = (a: number, b: number, x2: number): number => {
            return a + x2 * (b - a);
        };
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        if ( p1.x === p2.x && p1.y === p2.y ) {
            // Handle divide by 0 (p1 is the same as p2)
            return {...p1};
        }
        const t = ((ref.x - p1.x) * dx + (ref.y - p1.y) * dy) / (dx * dx + dy * dy);
        const lineX = lerp(p1.x, p2.x, t);
        const lineY = lerp(p1.y, p2.y, t);
        return { x: lineX, y: lineY };
    }

}
