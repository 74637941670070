import { Action } from '@ngrx/store';
import { ConnectionStatus } from '@capacitor/network';

export enum CoreActionTypes {
    SetSearch = '[Toolbar] Set Search',
    SetLanguage = '[User Settings / Env / Storage] Set Language',
    SetNetworkStatus = '[DeviceService => Capacitor ] Set Network Status',
    SetIsDevice = '[DeviceService] Set is Device'
}

export class SetSearch implements Action {
    readonly type = CoreActionTypes.SetSearch;
    constructor(public payload: { search: string }) {}
}

export class SetLanguage implements Action {
    readonly type = CoreActionTypes.SetLanguage;
    constructor(public payload: { language: string }) {}
}

export class SetNetworkStatus implements Action {
    readonly type = CoreActionTypes.SetNetworkStatus;
    constructor(public payload: { status: ConnectionStatus }) {}
}

export class SetIsDevice implements Action {
    readonly type = CoreActionTypes.SetIsDevice;
    constructor(public payload: { isDevice: boolean }) {}
}

export type CoreActions =
    | SetSearch
    | SetLanguage
    | SetNetworkStatus
    | SetIsDevice;
