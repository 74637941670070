import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LoadBoilerplatesRequested } from 'app/store/boilerplates/boilerplates.actions';
import {
    DeselectAllDiaryEntries,
    LoadDiaryCommandsRequest,
    LoadDiaryEntriesRequest,
    LoadDiaryConflicts,
} from 'app/store/diary/diary.actions';
import {
    LoadIssueCommandsRequest,
    LoadIssuesRequest,
    SelectIssues,
    LoadIssueConflicts,
} from 'app/store/issues/issues.actions';
import { IssuesState } from 'app/store/issues/issues.reducer';
import { LoadLettersRequested } from 'app/store/letters/letters.actions';
import { LoadLocationsRequested } from 'app/store/locations/locations.actions';
import {
    LoadProjectContactsRequest,
    LoadProjectCraftsRequest,
    LoadProjectCraftToPlanRequested,
    LoadProjectPartnersRequested,
    LoadProjectRolesRequest,
} from 'app/store/settings/settings.actions';
import {
    getIssueLoadingConflicts,
    getIssuesConflictsInitialized,
} from 'app/store/issues/issues.selectors';
import { take, filter, tap, withLatestFrom } from 'rxjs/operators';
import {
    getDiaryConflictsInitialized,
    getDiaryLoadingConflicts,
} from 'app/store/diary/diary.selectors';
import { LoadChecklistsRequested } from 'app/store/checklists/checklists.actions';
import { LoadGlobalBoilerplatesRequested } from 'app/store/global-bolierplates/global-boilerplates.actions';
import { LoadMarkedPlansInfoRequest } from 'app/store/projects/projects.actions';

@Injectable({
    providedIn: 'root',
})
export class ProjectResourcesGuard implements CanActivate {
    constructor(private store: Store<IssuesState>) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const { projectId } = route.params;

        if (!projectId) {
            return;
        }

        const archived = false;

        this.store.dispatch(new LoadMarkedPlansInfoRequest({ projectId }));

        this.store.dispatch(new LoadIssuesRequest({ projectId, archived }));
        this.store.dispatch(
            new LoadDiaryEntriesRequest({ projectId, archived })
        );
        this.store.dispatch(new DeselectAllDiaryEntries());

        this.store.dispatch(new LoadLocationsRequested({ projectId }));
        this.store.dispatch(new LoadBoilerplatesRequested({ projectId }));
        // this.store.dispatch(new LoadLocalBoilerplatesRequested({ projectId }));
        this.store.dispatch(new LoadGlobalBoilerplatesRequested());

        this.store.dispatch(new LoadLettersRequested({ projectId }));
        this.store.dispatch(new LoadChecklistsRequested({ projectId }));
        this.store.dispatch(new SelectIssues({ ids: [] }));
        this.store.dispatch(
            new LoadIssueCommandsRequest({ projectId, entityType: 'issue' })
        );
        this.store.dispatch(
            new LoadIssueCommandsRequest({ projectId, entityType: 'plan' })
        );
        this.store.dispatch(
            new LoadDiaryCommandsRequest({ projectId, entityType: 'diary' })
        );
        this.store.dispatch(new LoadProjectPartnersRequested({ projectId }));
        this.store.dispatch(new LoadProjectCraftsRequest({ projectId }));
        this.store.dispatch(new LoadProjectRolesRequest({ projectId }));
        this.store.dispatch(new LoadProjectCraftToPlanRequested({ projectId }));
        this.store.dispatch(new LoadProjectContactsRequest({ projectId }));

        // note: This only happens on the first start of the application
        //       where Logout action wipes store and conflict load state
        //       otherwise this are dispatched on initialization. Other
        //       action which influence the initialization state are already
        //       dispatched above.
        const shouldDispatch = ([initialized, loading]) =>
            !initialized && !loading;
        this.store
            .pipe(
                select(getIssuesConflictsInitialized),
                withLatestFrom(
                    this.store.pipe(select(getIssueLoadingConflicts))
                ),
                take(1),
                filter(shouldDispatch),
                tap(() => {
                    this.store.dispatch(new LoadIssueConflicts());
                })
            )
            .subscribe();
        this.store
            .pipe(
                select(getDiaryConflictsInitialized),
                withLatestFrom(
                    this.store.pipe(select(getDiaryLoadingConflicts))
                ),
                take(1),
                filter(shouldDispatch),
                tap(() => {
                    this.store.dispatch(new LoadDiaryConflicts());
                })
            )
            .subscribe();

        return true;
    }
}
