import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { BoilerPlate } from 'app/core/rest-api';
import { BoilerplateDocType } from 'app/db/boilerplates/boilerplate.document';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';

export enum GlobalBoilerplatesActionTypes {
    LOAD_GLOBAL_BOILERPLATES_REQUESTED = '[GlobalBoilerplates] Global Boilerplates Requested',
    LOAD_GLOBAL_BOILERPLATES_SUCCESS = '[API] Load BoilerGlobales Success',
    LOAD_GLOBAL_BOILERPLATES_ERROR = '[API] Load BoilerplGlobal Error',

    // BOILERPLATES
    UPSERT_GLOBAL_BOILERPLATES_START = '[APP] Upsert GlobalBoilerplates Request',
    UPSERT_GLOBAL_BOILERPLATES_FINISH = '[DB] Upsert GlobalBoilerplates Success',

    IMPORT_GLOBAL_BOILERPLATE_CSV_REQUEST = '[Settings] Import Boilerplate CSV Request',
    IMPORT_GLOBAL_BOILERPLATE_CSV_SUCCESS = '[Settings] Import Boilerplate CSV Success',
    IMPORT_GLOBAL_BOILERPLATE_CSV_ERROR = '[Settings] Import Boilerplate CSV Error',

    CREATE_GLOBAL_BOILERPLATE_REQUEST = '[GlobalBoilerplates] Create Boilerplate Requested',
    CREATE_GLOBAL_BOILERPLATE_SUCCESS = '[GlobalBoilerplates] Create Boilerplate Success',
    CREATE_GLOBAL_BOILERPLATE_ERROR = '[GlobalBoilerplates] Create Boilerplate Error',

    UPDATE_GLOBAL_BOILERPLATE_REQUEST = '[GlobalBoilerplates] Update Boilerplate Requested',
    UPDATE_GLOBAL_BOILERPLATE_SUCCESS = '[GlobalBoilerplates] Update Boilerplate Success',
    UPDATE_GLOBAL_BOILERPLATE_ERROR = '[GlobalBoilerplates] Update Boilerplate Error',

    DELETE_GLOBAL_BOILERPLATE_REQUEST = '[GlobalBoilerplates] Delete Boilerplate Requested',
    DELETE_GLOBAL_BOILERPLATE_SUCCESS = '[GlobalBoilerplates] Delete Boilerplate Success',
    DELETE_GLOBAL_BOILERPLATE_ERROR = '[GlobalBoilerplates] Delete Boilerplate Error',

    ADD_SELECTED_GLOBAL_CRAFT = '[GlobalBoilerplates] Add Selected Global Craft',
    REMOVE_SELECTED_GLOBAL_CRAFT = '[GlobalBoilerplates] Remove Selected Global Craft',

    ADD_SELECTED_GLOBAL_BOILERPLATE = '[GlobalBoilerplates] Add Selected Global Boilerplate',
    REMOVE_SELECTED_GLOBAL_BOILERPLATE = '[GlobalBoilerplates] Remove Selected Global Boilerplate',
}

export class UpsertGlobalBoilerplatesStart implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.UPSERT_GLOBAL_BOILERPLATES_START;
    constructor(public payload: { boilerplates: BoilerPlate[] }) {}
}

export class UpsertGlobalBoilerplatesFinish implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.UPSERT_GLOBAL_BOILERPLATES_FINISH;
    constructor(
        public payload: { results: BulkInsertResult<BoilerplateDocType> }
    ) {}
}

export class LoadGlobalBoilerplatesRequested implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_REQUESTED;
    constructor() {}
}

export class LoadGlobalBoilerplatesSuccess implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_SUCCESS;
    constructor(
        public payload: {
            fromDb: boolean;
            boilerplates: BoilerPlate[];
        }
    ) {}
}

export class LoadGlobalBoilerplatesError implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.LOAD_GLOBAL_BOILERPLATES_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateGlobalBoilerplateRequested implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_REQUEST;
    constructor(
        public payload: {
            parentId: string;
            title: string;
        }
    ) {}
}

export class CreateGlobalBoilerplateSuccess implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_SUCCESS;
    constructor(public payload: { boilerplate: BoilerPlate }) {}
}

export class CreateGlobalBoilerplateError implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.CREATE_GLOBAL_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class UpdateGlobalBoilerplateRequested implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_REQUEST;
    constructor(
        public payload: {
            update: Update<BoilerPlate>;
        }
    ) {}
}

export class UpdateGlobalBoilerplateSuccess implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_SUCCESS;
    constructor(public payload: { update: Update<BoilerPlate> }) {}
}

export class UpdateGlobalBoilerplateError implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.UPDATE_GLOBAL_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}

export class DeleteGlobalBoilerplateRequested implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_REQUEST;
    constructor(
        public payload: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export class DeleteGlobalBoilerplateSuccess implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_SUCCESS;
    constructor(
        public payload?: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export class DeleteGlobalBoilerplateError implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.DELETE_GLOBAL_BOILERPLATE_ERROR;
    constructor(public payload?: { error?: any }) {}
}
export class AddSelectedCraft implements Action {
    readonly type = GlobalBoilerplatesActionTypes.ADD_SELECTED_GLOBAL_CRAFT;
    constructor(
        public payload: {
            boilerplate: Partial<BoilerPlate>;
        }
    ) {}
}

export class RemoveSelectedCraft implements Action {
    readonly type = GlobalBoilerplatesActionTypes.REMOVE_SELECTED_GLOBAL_CRAFT;
    constructor() {}
}

export class AddSelectedGlobalBoilerplate implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.ADD_SELECTED_GLOBAL_BOILERPLATE;
    constructor(
        public payload: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export class RemoveSelectedGlobalBoilerplate implements Action {
    readonly type =
        GlobalBoilerplatesActionTypes.REMOVE_SELECTED_GLOBAL_BOILERPLATE;
    constructor(
        public payload: {
            boilerplate: BoilerPlate;
        }
    ) {}
}

export type GlobalBoilerplatesActions =
    | LoadGlobalBoilerplatesRequested
    | LoadGlobalBoilerplatesSuccess
    | LoadGlobalBoilerplatesError
    | CreateGlobalBoilerplateRequested
    | CreateGlobalBoilerplateSuccess
    | CreateGlobalBoilerplateError
    | UpdateGlobalBoilerplateRequested
    | UpdateGlobalBoilerplateSuccess
    | UpdateGlobalBoilerplateError
    | UpsertGlobalBoilerplatesStart
    | UpsertGlobalBoilerplatesFinish
    | DeleteGlobalBoilerplateRequested
    | DeleteGlobalBoilerplateSuccess
    | DeleteGlobalBoilerplateError
    | AddSelectedCraft
    | RemoveSelectedCraft
    | AddSelectedGlobalBoilerplate
    | RemoveSelectedGlobalBoilerplate;
