/// <reference types="cordova-plugin-file" />

import { Inject, Injectable } from '@angular/core';
import { Mutex } from 'async-mutex';

import { ATTACHMENT_DIRECTORY } from './attachment-configuration.tokens';
import { StoreRequest } from './attachment-downloader.service';
import {
    SafeName,
    createDirectory,
    getFile,
    moveInDirectory,
    notFoundError,
    readFile,
    removeFile,
    removeRecursively,
    requestFileSystem,
    writeFile,
} from './cordova-plugin-file.utils';
import { FileMetadataStorage } from './file-metadata-storage';
import { FileNotFoundError, FileStorageService } from './file-storage.service';

@Injectable()
export class CordovaFileStorageService extends FileStorageService {
    private initialized = false;

    private fs: FileSystem;
    private attachmentDirectoryEntry: FileSystemEntry | null = null;

    private mutex: Mutex;

    private static attachmentNotFoundMessage(id: string): string {
        return `attachment not found: ${id}`;
    }

    private static notYetInitializedError(): Error {
        return new Error(
            'CordovaFileStorageService has not yet been initialized.'
        );
    }

    constructor(
        fileMetadataStorage: FileMetadataStorage,
        @Inject(ATTACHMENT_DIRECTORY) private attachmentDirectory: SafeName
    ) {
        super(fileMetadataStorage);

        this.mutex = new Mutex();
    }

    async initDirectories(): Promise<void> {
        const cordova = (window as any).cordova as Cordova;
        const dataDir = cordova.file.dataDirectory;

        this.attachmentDirectoryEntry = await createDirectory(
            this.fs,
            dataDir,
            this.attachmentDirectory
        );
    }

    async init(): Promise<void> {
        const release = await this.mutex.acquire();
        try {
            // ensure that subsequent calls to this function are not a problem
            if (this.initialized) {
                return;
            }

            await super.init();

            // Wait until Cordova is ready, note that this event is
            // special: if an event has already fired, the registered
            // callback is executed immediately.
            // (see https://cordova.apache.org/docs/en/10.x/cordova/events/events.html#deviceready)
            //
            // if this event is not firing, Cordova is not available
            // and something is seriously wrong
            await new Promise<void>((resolve, _reject) => {
                const listener = () => {
                    document.removeEventListener('deviceready', listener);
                    resolve();
                };
                document.addEventListener('deviceready', listener);
            });

            this.fs = await requestFileSystem(LocalFileSystem.PERSISTENT);

            await this.initDirectories();

            this.initialized = true;
        } finally {
            release();
        }
    }

    private assertFullyInitialized(): void {
        if (!this.initialized || !this.attachmentDirectoryEntry) {
            throw CordovaFileStorageService.notYetInitializedError();
        }
    }

    async loadAttachment(attachmentId: string): Promise<Blob> {
        this.assertFullyInitialized();

        const metadata = await this.fileMetadataStorage.getMetadata(
            attachmentId
        );

        if (!metadata) {
            throw new FileNotFoundError(
                CordovaFileStorageService.attachmentNotFoundMessage(
                    attachmentId
                )
            );
        }

        const escapedSlash = SafeName.create(attachmentId);

        try {
            return readFile(
                this.attachmentDirectoryEntry,
                escapedSlash,
                metadata.contentType
            );
        } catch (ex) {
            if (notFoundError(ex)) {
                // this can occur if attachment disappears between checking
                // for metadata and the actual loading of the attachment
                throw new FileNotFoundError(
                    CordovaFileStorageService.attachmentNotFoundMessage(
                        attachmentId
                    ),
                    ex
                );
            }

            throw ex;
        }
    }

    async storeAttachment(storeRequest: StoreRequest): Promise<void> {
        this.assertFullyInitialized();

        await super.storeAttachment(storeRequest);

        switch (storeRequest.type) {
            case 'blob-to-store': {
                const { attachmentId, blob } = storeRequest;

                // due to id/thumbnail
                const escapedSlash = SafeName.create(attachmentId);

                await writeFile(
                    this.attachmentDirectoryEntry,
                    escapedSlash,
                    blob
                );
                break;
            }
        }
    }

    async removeAttachment(attachmentId: string): Promise<void> {
        this.assertFullyInitialized();

        await super.removeAttachment(attachmentId);

        const file = await getFile(
            this.attachmentDirectoryEntry,
            SafeName.create(attachmentId),
            {
                create: false,
            }
        );

        return removeFile(file);
    }

    async clear(): Promise<void> {
        // prevent multiple clear-requests from running concurrently
        const release = await this.mutex.acquire();
        try {
            await super.clear();

            // clear() can be called before init() if application starts
            // without a logged in user (e.g. first start). In this case
            // the directory and the attachmentDirectoryEntry attribute
            // were not initialized yet, thus there is nothing to delete.
            const directoryInitialized = !!this.attachmentDirectoryEntry;
            if (this.initialized && directoryInitialized) {
                // if the directory has been initialized, there is something
                // to delete
                await removeRecursively(this.attachmentDirectoryEntry);

                // even though it's currently not possible to have a removed attachment
                // directory without having it initialized again, the attribute is reset
                // to be on the safe side
                this.attachmentDirectoryEntry = null;

                await this.initDirectories();
            }
        } finally {
            release();
        }
    }

    async updateAttachmentIds(idMap: Map<string, string>): Promise<void> {
        this.assertFullyInitialized();

        // TODO: why is this not awaited?
        super.updateAttachmentIds(idMap);

        for (const [from, to] of idMap) {
            const fromEscaped = SafeName.create(from);
            const toEscaped = SafeName.create(to);

            await moveInDirectory({
                directory: this.attachmentDirectoryEntry,
                from: fromEscaped,
                to: toEscaped,
                // to prevent overwriting already downloaded attachments
                skipExisting: true,
                // to prevent errors if it tries to move the same
                // file more than once at the same time
                skipMissing: true,
            });
        }
    }
}
