<div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="h-100-p"
    *ngIf="false"
>
    <mat-spinner color="accent"></mat-spinner>
</div>

<mat-sidenav-container class="inner-scroll">
    <mat-sidenav #sidenav mode="over" position="end">
        <acc-project-form
            *ngIf="isOnline$"
            #projectForm
            [form]="form"
            [timezones]="timezones$ | async"
            [updateMode]="updateMode"
            [templates]="projectTemplates$ | async"
            [currentProject]="currentProject"
            [hasProjectChanges]="hasProjectChanges"
            [isTemplate]="showTemplates"
            (openImgDialog)="imgDialog($event)"
            (changeTemplate)="changeTemplate($event)"
            (updateProject)="updateProject()"
            (createProject)="createProject()"
            (toggleSidenav)="sidenav.toggle()"
        ></acc-project-form>
    </mat-sidenav>

    <mat-sidenav-content
        fxLayout="column"
        *ngLet="selectedProjectsIds$ | async as selectedProjectsIds"
    >
        <acc-responsive-toolbar fxHide.gt-xs class="w-100-p">
        </acc-responsive-toolbar>

        <div
            fxLayout="column"
            class="h-100-p fuse-white-bg"
            ngClass.xs="mt-64 mobile-container"
            data-test="projects-list-grid-container"
        >
            <div fxHide.xs class="welcome">
                <h1>
                    {{ 'PROJECT.LIST.WELCOME' | translate }},
                    {{ (user$ | async)?.username }}!
                </h1>
            </div>

            <div
                fxHide.gt-xs
                class="p-4 welcome-mobile text-center"
                fxLayout="row"
                fxLayoutAlign="center"
            >
                <img class="w-32" src="assets/images/custom/avatar.svg" />
                <div
                    fxLayout="column"
                    fxLayoutAlign="center start"
                    class="ml-8"
                >
                    <p>
                        {{ 'PROJECT.LIST.WELCOME_MOBILE' | translate }},
                        {{ (user$ | async)?.firstName }}!
                    </p>
                    <!-- TODO: Use styled translation here <p>Sie haben heute <u><strong>5 Fälligkeiten</strong></u></p> -->
                    <!-- <p>{{ 'PROJECT.LIST.YOUR_DUE_DATES' | translate: '{length: 5}' }}</p> -->
                </div>
            </div>

            <div class="wrapper" fxFlex fxLayout="column" [ngClass.xs]="'m-0'">
                <acc-project-list-toolbar
                    [gridView]="gridView"
                    [selectedProjectsIds]="selectedProjectsIds"
                    [showArchived]="showArchived"
                    (setListView)="setListView()"
                    (setGridView)="setGridView()"
                    (toggleArchived)="toggleArchived($event)"
                    (toggleRefresh)="toggleRefresh()"
                    (openArchiveSelectedProjectsDialog)="
                        archiveSelectedProjectsDialog()
                    "
                    (toggleTemplates)="toggleTemplates($event)"
                ></acc-project-list-toolbar>

                <!-- <ng-container
                    *ngIf="isOnline$ | async; else offlineProjectGrid"
                >
                    <acc-project-grid
                        *ngIf="gridView"
                        (updated)="updateDialog($event)"
                        (archived)="archiveProjectDialog($event)"
                        [language]="language$ | async"
                        [projectsWithStatistics]="
                            projectsWithStatistics$ | async
                        "
                    ></acc-project-grid>
                </ng-container>

                <ng-template #offlineProjectGrid> -->
                <acc-offline-project-grid
                    *ngIf="gridView"
                    (updated)="updateDialog($event)"
                    (archived)="archiveProjectDialog($event)"
                    [cols]="cols"
                    [projects]="projects$ | async"
                >
                </acc-offline-project-grid>
                <!-- </ng-template> -->

                <div
                    *ngIf="!gridView"
                    class="table-container mat-elevation-z8 mt-16 mb-48"
                >
                    <acc-project-table
                        (updated)="updateDialog($event)"
                        (archived)="archiveProjectDialog($event)"
                        (toggleSelect)="toggleSelect($event)"
                        (toggleSelectAll)="toggleSelectAll()"
                        [displayedColumns]="displayedColumns"
                        [dataSource]="dataSource"
                        [language]="language$ | async"
                        [selectedProjectsIds]="selectedProjectsIds"
                        [totalProjectsCount]="totalProjectsCount$ | async"
                    ></acc-project-table>
                </div>
            </div>

            <button
                *ngIf="isAdmin$ | async"
                (click)="createDialog()"
                [disabled]="!(isOnline$ | async)"
                class="action-btn"
                mat-fab
                [matTooltip]="'PROJECT.CREATE_PROJECT' | translate"
                matTooltipPosition="left"
                [matTooltipDisabled]="tooltipUtilService.matTooltipDisabled"
                data-test="create-project-button"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
