/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ArchiveReference } from './archiveReference';
import { MongoFileRev } from './mongoFileRev';
import { Contact } from './contact';


export interface MongoFile { 
    id?: string;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    customTitle?: string;
    fileDescription?: string;
    fileName?: string;
    fileSize?: number;
    contentType?: string;
    oldVersions?: Array<MongoFileRev>;
    documentType?: MongoFile.DocumentTypeEnum;
    oldReferences?: { [key: string]: Array<string>; };
    language?: string;
    references?: Array<ArchiveReference>;
    projectIdReferences?: { [key: string]: number; };
}
export namespace MongoFile {
    export type DocumentTypeEnum = 'LockProtocol' | 'Template';
    export const DocumentTypeEnum = {
        LockProtocol: 'LockProtocol' as DocumentTypeEnum,
        Template: 'Template' as DocumentTypeEnum
    };
}


