<div fxLayout="row" class="add-asset-tile" fxFlexFill>
    <file-pond
        #pond
        *ngIf="displayPond"
        (click)="useCapacitorCamera($event)"
        (input)="onInput($event)"
        (drop)="onDrop()"
        fxFlex
        [options]="pondOptions"
        data-test="add-asset-pond"
    >
    </file-pond>
</div>
