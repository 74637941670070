<div class="autosel-flex autosel-flex-fill autosel-flex-col">
  <div
    class="search-head mat-elevation-z12 pt-12 px-12 autosel-flex autosel-flex-row autosel-justify-start autosel-items-center"
  >
    <button (click)="closeWithValue()" mat-icon-button>
      <mat-icon>check</mat-icon>
    </button>
    <asc-auto-selector
      class="px-12 autosel-flex-auto"
      #autoSelector
      [dialogMode]="true"
      [config]="data.config"
      [ngModel]="data.value"
      (ngModelChange)="setValue($event)"
      (finish)="closeWithValue()"
      [items]="data?.items"
      [fallbackItems]="data.fallbackItems"
      (filteredItemsChange)="setFilteredItems($event)"
    >
    </asc-auto-selector>
  </div>

  <ng-container
    *ngIf="
      data?.items &&
      data?.items.length > 0 &&
      data?.items[0].label &&
      data?.items[0]?.items
    "
  >
    <mat-tab-group
      mat-align-tabs="center"
      style="overflow: auto !important"
      class="autosel-flex-auto"
    >
      <mat-tab
        *ngFor="let group of data?.items | keyvalue"
        [label]="group.value.label"
      >
        <asc-auto-selector-dialog-results
          (itemSelected)="autoSelector.setItem($event)"
          [filteredItems]="filteredItems[group.key]?.items"
          [titleProperty]="data.config.properties.title"
          [noMatchComponent]="data.noMatchComponent"
        ></asc-auto-selector-dialog-results>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <div
    *ngIf="
      (data?.items && data?.items.length > 0 && !data?.items[0].label) ||
      !data?.items[0]?.items
    "
    class="results pt-12 autosel-flex-auto"
  >
    <asc-auto-selector-dialog-results
      (itemSelected)="autoSelector.setItem($event)"
      [filteredItems]="filteredItems"
      [titleProperty]="data.config.properties.title"
      [noMatchComponent]="data.noMatchComponent"
    ></asc-auto-selector-dialog-results>
  </div>
</div>
