import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanLoad,
    Route,
    UrlSegment,
    Router,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom, map, take } from 'rxjs/operators';

import { AuthState } from 'app/store/auth/auth.reducer';
import {
    getMaxRoleGlobal,
    getCurrentUser,
} from 'app/store/auth/auth.selectors';
import { environment } from 'environments/environment';

type ActivateLoadResult =
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree;

@Injectable({
    providedIn: 'root',
})
export class AdminPanelGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private store: Store<AuthState>) {}

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): ActivateLoadResult {
        return this.redirectToHomeIfNotAllowed();
    }

    canLoad(_route: Route, _segments: UrlSegment[]): ActivateLoadResult {
        return this.redirectToHomeIfNotAllowed();
    }

    private redirectToHomeIfNotAllowed(): ActivateLoadResult {
        return this.store.pipe(
            select(getMaxRoleGlobal),
            take(1),
            withLatestFrom(this.store.pipe(select(getCurrentUser))),
            map(([maxRoleGlobal, currentUser]) => {
                const isFlink2GoAdmin =
                    maxRoleGlobal >= environment.roleNumbers.FLINK2GO_ADMIN;
                const whitelistedUser =
                    currentUser.email === environment.whitelistedAdminPanelUser;

                if (isFlink2GoAdmin || whitelistedUser) {
                    return true;
                } else {
                    return this.router.createUrlTree(['/']);
                }
            })
        );
    }
}
