import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImageItem } from '@ngx-gallery/core';
import { Project } from 'app/core/rest-api';
import { AssetTileService } from 'app/shared/components/asset-tile/asset-tile.service';
import { getIsAdminGlobal } from 'app/store/auth/auth.selectors';
import { getIsOnline } from 'app/store/core/core.selectors';
import { selectProjectById } from 'app/store/projects/projects.selectors';
import { SetProjectsToSync } from 'app/store/sync/sync.actions';
import { SyncState } from 'app/store/sync/sync.reducer';
import { getIsProjectToSync } from 'app/store/sync/sync.selectors';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'acc-offline-project-grid-item',
    templateUrl: './offline-project-grid-item.component.html',
    styleUrls: ['./offline-project-grid-item.component.scss'],
})
export class OfflineProjectGridItemComponent implements OnInit, OnChanges {
    readonly placeholderSrc = 'assets/images/custom/project-avatar.svg';

    galleryItems = [];
    loadingGallery = true;

    isOnline$ = this.store.pipe(select(getIsOnline));
    isAdmin$ = this.store.pipe(select(getIsAdminGlobal));
    isBacklinkSetInProject$ = (projectId) =>
        this.store
            .pipe(select(selectProjectById(projectId)))
            .pipe(map((project) => !!project.backlink));

    @Input()
    project: Project;

    @Input()
    projectsToSync: string[];

    @Input()
    isDevice = false;

    @Output()
    archived = new EventEmitter<Project>();

    @Output()
    updated = new EventEmitter<Project>();

    synced$: Observable<boolean>;
    markedForSync$: Observable<boolean>;

    constructor(
        private store: Store<SyncState>,
        private assetTileService: AssetTileService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.markedForSync$ = this.store.pipe(
            select(getIsProjectToSync, { id: this.project.id })
        );
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.project && this.project.photo && this.project.id) {
            this.galleryItems = await this.assetTileService.generateGalleryItems(
                this.project.id,
                [this.project.photo],
                'project',
                true
            );
        } else {
            this.galleryItems = [
                new ImageItem({
                    thumb: this.placeholderSrc,
                    type: 'image/svg+xml',
                    id: 'placeholder',
                }),
            ];
        }
        this.loadingGallery = false;

        this.cdr.detectChanges();
    }

    toggleSync(): void {
        let projectsToSync: string[] = [];
        if (!this.projectsToSync.includes(this.project.id)) {
            projectsToSync = [...this.projectsToSync, this.project.id];
        } else {
            projectsToSync = this.projectsToSync.filter(
                (p) => p !== this.project.id
            );
        }
        this.store.dispatch(new SetProjectsToSync({ projectsToSync }));
    }
}
