/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { ITemplateModel } from './iTemplateModel';
import { TemplateModelParameter } from './templateModelParameter';
import { Contact } from './contact';


export interface TemplateDocument { 
    id?: string;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    projectId?: string;
    project?: Project;
    language?: string;
    templateType?: ITemplateModel;
    modelType?: TemplateModelParameter;
    additionalParameters?: Array<TemplateModelParameter>;
    defaultTemplate?: TemplateDocument.DefaultTemplateEnum;
    flinkType?: TemplateDocument.FlinkTypeEnum;
    reason?: TemplateDocument.ReasonEnum;
    creatorId?: string;
    creator?: Contact;
}
export namespace TemplateDocument {
    export type DefaultTemplateEnum = 'AccountConfirmation' | 'PasswordReset' | 'Notification';
    export const DefaultTemplateEnum = {
        AccountConfirmation: 'AccountConfirmation' as DefaultTemplateEnum,
        PasswordReset: 'PasswordReset' as DefaultTemplateEnum,
        Notification: 'Notification' as DefaultTemplateEnum
    };
    export type FlinkTypeEnum = 'Issue' | 'Project' | 'ApplicationUser' | 'Contact' | 'Message' | 'Broadcast' | 'Undefined' | 'InspectionEntry' | 'DailyLogEntry';
    export const FlinkTypeEnum = {
        Issue: 'Issue' as FlinkTypeEnum,
        Project: 'Project' as FlinkTypeEnum,
        ApplicationUser: 'ApplicationUser' as FlinkTypeEnum,
        Contact: 'Contact' as FlinkTypeEnum,
        Message: 'Message' as FlinkTypeEnum,
        Broadcast: 'Broadcast' as FlinkTypeEnum,
        Undefined: 'Undefined' as FlinkTypeEnum,
        InspectionEntry: 'InspectionEntry' as FlinkTypeEnum,
        DailyLogEntry: 'DailyLogEntry' as FlinkTypeEnum
    };
    export type ReasonEnum = 'Created' | 'Deleted' | 'Message' | 'Updated' | 'Post' | 'Patch' | 'Finished';
    export const ReasonEnum = {
        Created: 'Created' as ReasonEnum,
        Deleted: 'Deleted' as ReasonEnum,
        Message: 'Message' as ReasonEnum,
        Updated: 'Updated' as ReasonEnum,
        Post: 'Post' as ReasonEnum,
        Patch: 'Patch' as ReasonEnum,
        Finished: 'Finished' as ReasonEnum
    };
}


