import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { ProjectCraftIdsDocType } from './project-craft-ids.document';

@Injectable({
    providedIn: 'root',
})
export class ProjectCraftIdsDbService extends EntityDbService<
    ProjectCraftIdsDocType,
    ProjectCraftIdsDocType
> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.ProjectCraftIds
        );
    }

    getAttachmentIdsFromApiItem(
        projectCraftIds: ProjectCraftIdsDocType
    ): string[] {
        return [];
    }

    apiItemToSchema(
        projectCraftIds: ProjectCraftIdsDocType
    ): ProjectCraftIdsDocType {
        const { id, projectId, craftIds } = projectCraftIds;
        return {
            id,
            projectId,
            craftIds,
        };
    }
}
