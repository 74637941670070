import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import {locale as deLocale, LocaleKey} from 'app/i18n/de';

type Locale = typeof deLocale;

/*
const demoData: IActivity[] = [{
        status: 'FAILED',
        translationTemplateKey: 'FORM_LETTER',
        translationParams: {formLetterTitle: 'Test'},
        actions: [{
            title: 'GENERIC_BUTTONS.OK',
            type: 'CLOSE',
        }],
        show: true,
    }, {
        status: 'SUCCESS',
        translationTemplateKey: 'FORM_LETTER',
        translationParams: {formLetterTitle: 'Test'},
        actions: [{
            title: 'APP.DOWNLOAD',
            type: 'HREF',
            url: 'http://localhost',
            filename: 'test.pdf',
            icon: 'file_download',
        }, {
            title: 'GENERIC_BUTTONS.OK',
            type: 'CLOSE',
        }],
        show: true,
    }, {
        status: 'RUNNING',
        translationTemplateKey: 'FORM_LETTER',
        translationParams: {formLetterTitle: 'Test'},
        // desc: 'Pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et.',
        actions: [],
        show: true,
    }];
*/

export type TranslationTemplateKey = keyof Locale['data']['ACTIVITIES'];
/** Parameters to use with the translation message */
type TranslationParams = {
    formLetterTitle?: string,
    errorMessage?: string,
};

export interface IActivityAction  {
    /** Translation key for the dialog title. Translated in conjunction with `TranslationParams` */
    title: LocaleKey;
    /**
     * * HREF - Open a link (utilizes the `url` and `filename` params)
     * * CLOSE - Closes (i.e. removes/deletes) the activitiy 
     * 
     * See `activateAction()` in activity.component.ts 
     */
    type: 'HREF'|'CLOSE';
    /** For "HREF", this is the target link */
    url?: string;
    /** For "HREF", this is the `download` parameter attribute */
    filename?: string;
    icon?: string;
}

export interface IActivity {
    status: 'FAILED' | 'RUNNING' | 'SUCCESS';
    actions: IActivityAction[];
    show: boolean;

    /** Which translation to use, (under data.ACTIVITIES) */
    translationTemplateKey: TranslationTemplateKey;
    /** Parameters for the translate pipe */
    translationParams: TranslationParams;
}

/**
 * The (initial) goal is to represent to the user the status of ongoing activites/actions in progreess, such as generating PDFs
 * 
 * (At least initially), these only exist in the UI, without backend support, and are lost on a page refresh.
 * 
 * An action is created with `create(), and then passed to subsequent calls. `setStatus`, `addAction`, `extendData`, and
 *  `deleteActivity` can be used to manipulate the activity, while triggering the apprirate observables for changes.
 *  Modifying the action directly would miss sending updates.
 * 
 * Bulk updates would be more efficient, but left for a future version.
 * 
 */
@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
 
    private activitiesList: IActivity[] = [];

    private _activitesList$: Subject<IActivity[]> = new ReplaySubject(1);
    public get activitiesList$(): Observable<IActivity[]> {
        return this._activitesList$;
    }

    constructor() { 
        // Set initial value for observable
        this._activitesList$.next(this.activitiesList);
    }

    /**
     * Creates an activity with the given title. 
     * No actions, default status is 'RUNNING'
     * 
     * @param translationTemplateKey Title
     */
    create( 
        translationTemplateKey: TranslationTemplateKey,
        translationParams: TranslationParams = {},
    ): IActivity {
        const activity: IActivity = {
            actions: [],
            status: 'RUNNING',
            show: false,
            translationTemplateKey,
            translationParams,
        };
        this.activitiesList.push(activity);
        this._activitesList$.next(this.activitiesList);
        // Set to be shown in the very near future
        setTimeout( () => activity.show = true, 10);
        return activity;
    }

    // 
    setStatus(activity: IActivity, newStatus: IActivity['status']): void {
        // Ensure we are tracking this object, or don't do anything
        if ( ! _.find(this.activitiesList, activity)) {
            return;
        }
        activity.status = newStatus;
        this._activitesList$.next(this.activitiesList);
        return;
    }

    /** Add/define an action button */
    addAction(activity: IActivity, action: IActivityAction ): void {
        // Ensure we are tracking this object, or don't do anything
        if ( ! _.find(this.activitiesList, activity)) {
            return;
        }
        activity.actions.push(action);
        this._activitesList$.next(this.activitiesList);
        return;
    }

    /**
     * Add new data to the Translation data
     */
    extendData(activity: IActivity, deltaParams: Partial<TranslationParams>): void {
        activity.translationParams = {
            ...activity.translationParams,
            ...deltaParams,
        };
        this._activitesList$.next(this.activitiesList);
    }

    /** 
     * Immediatlly deletes the activity from the activity list. (It would be ideal to remove it through
     *  the `ActivitesComponent`, so that closing the activity can be animated) 
     */
    deleteActivity(activity: IActivity): void {
        this.activitiesList = this.activitiesList.filter(it => it !== activity);
        this._activitesList$.next(this.activitiesList);
    }
}
