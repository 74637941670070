import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location as FlinkLocation } from 'app/core/rest-api';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { LocationDocType } from './location.document';

@Injectable({
    providedIn: 'root',
})
export class LocationDbService extends EntityDbService<
    FlinkLocation,
    LocationDocType
> {
    getAttachmentIdsFromApiItem(entity: FlinkLocation): string[] {
        return [];
    }
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Locations
        );
    }

    apiItemToSchema(location: FlinkLocation): LocationDocType {
        const {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
            craftToPlan,
            ownerId,
            updaterId,
            creatorId,
            deleteDateTime,
            order,
        } = location;
        return {
            creationDate,
            id,
            markedAsDelete,
            title,
            updateDateTime,
            parentID,
            projectId,
            craftToPlan,
            ownerId,
            updaterId,
            creatorId,
            // its actually a string, wrong generated model
            deleteDateTime: (deleteDateTime as any) as string,
            order,
        };
    }

    private isMissingAnyOf(
        keys: (keyof FlinkLocation)[],
        doc: FlinkLocation
    ): boolean {
        for (const key of keys) {
            const missingAttribute =
                doc[key] === undefined || doc[key] === null;
            if (missingAttribute) {
                return true;
            }
        }

        return false;
    }

    hasUntrackedChanges(
        doc: FlinkLocation & PouchDB.Core.AllDocsMeta,
        _entity: LocationDocType
    ): boolean {
        const noDoc = !doc;
        if (noDoc) {
            return true;
        }

        // the migration of locations from v0 to v1 does not set a
        // value for several attributes. Only `craftToPlan` and `order` are currently
        // checked here, because the other attributes like deleteDateTime
        // could be null.
        const docHasMissingAttributes = this.isMissingAnyOf(
            ['craftToPlan', 'order'],
            doc
        );
        if (!docHasMissingAttributes) {
            // if they are already present then no special case here is needed
            return false;
        }

        // store the new location in the DB
        return true;
    }
}
