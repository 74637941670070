import { RxDatabase } from 'rxdb';
import { BoilerplateCollection } from './boilerplates/boilerplate.collection';
import { CommandCollection } from './commands/command.collection';
import { ContactCollection } from './contacts/contact.collection';
import { CraftToPlanLocationCollection } from './craft-to-plan-locations/craft-to-plan-locations.collection';
import { DiaryCollection } from './diary/diary.collection';
import { IssueCollection } from './issues/issue.collection';
import { LocationCollection } from './locations/location.collection';
import { ProjectCraftIdCollection } from './project-craft-ids/project-craft-ids.collection';
import { ProjectPartnerCollection } from './project-partners/project-partners.collection';
import { ProjectRoleCollection } from './project-roles/project-roles.collection';
import { ProjectCollection } from './projects/project.collection';
import { FileMetadataCollection } from './file-metadata/file-metadata.collection';
import { GlobalBoilerplateCollection } from './global-boilerplates/global-boilerplate.collection';

export interface DatabaseCollections {
    issues: IssueCollection;
    diaries: DiaryCollection;
    projects: ProjectCollection;
    contacts: ContactCollection;
    boilerplates: BoilerplateCollection;
    globalBoilerplates: GlobalBoilerplateCollection;
    locations: LocationCollection;
    commands: CommandCollection;
    project_partners: ProjectPartnerCollection;
    project_craft_ids: ProjectCraftIdCollection;
    craft_to_plan_locations: CraftToPlanLocationCollection;
    project_contacts: ContactCollection;
    project_roles: ProjectRoleCollection;
    project_templates: ProjectCollection;
    file_metadata: FileMetadataCollection;
}

export enum Collections {
    Diaries = 'diaries',
    Issues = 'issues',
    Projects = 'projects',
    Contacts = 'contacts',
    Boilerplates = 'boilerplates',
    GlobalBoilerplates = 'global_boilerplates',
    Locations = 'locations',
    Commands = 'commands',
    ProjectPartners = 'project_partners',
    ProjectCraftIds = 'project_craft_ids',
    CraftToPlanLocations = 'craft_to_plan_locations',
    ProjectContacts = 'project_contacts',
    ProjectRoles = 'project_roles',
    ProjectTemplates = 'project_templates',
    FileMetadata = 'file_metadata',
}

export type Database = RxDatabase<DatabaseCollections>;

export enum DeletionState {
    NoDatabaseExists,
    InProgress,
    Completed,
}
