import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

import { Device } from '@capacitor/device';
import { CameraResultType, Camera } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { FilePondErrorDescription, FilePondFile } from 'filepond';

import { ErrorUtils } from 'app/core/utils/error-util';
import { MongoFile } from 'app/core/rest-api';
import { EntityType } from 'app/main/sync/models/entity-type';
import { environment } from 'environments/environment';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { AddAssetService } from './add-asset.service';
import { UploadComplete } from './models/upload-complete.model';
import { ReplaySubject, Subject } from 'rxjs';
import { DemoService } from 'app/shared/services/demo/demo.service';
import { DemoDialogComponent } from '../dialogs/demo-dialog/demo-dialog.component';
import { MatDialog } from '@angular/material/dialog';

const ADD_ASSET_ID_PREFIX = 'ADD_ASSET_';
const ADD_ASSET_ID_METADATA_KEY = 'addAssetId';

const CAPACITOR_CAMERA_LABELS_PREFIX = 'CAPACITOR.CAMERA_LABELS.';

@Component({
    selector: 'acc-add-asset',
    templateUrl: './add-asset.component.html',
    styleUrls: ['./add-asset.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddAssetComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    acceptedFileTypes = '';

    @Input()
    entityId?: string;

    @Input()
    entityType?: EntityType;

    @ViewChild('pond', { static: false })
    pond: any;

    @Output()
    fileProcessingStarted: EventEmitter<void> = new EventEmitter();

    @Output()
    fileUploaded: EventEmitter<{
        id: string;
        fileInfos: MongoFile;
    }> = new EventEmitter();

    @Output()
    fileProcessingEnded: EventEmitter<void> = new EventEmitter();

    displayPond = true;

    pondOptions: any;
    isDevice: boolean;

    // keeping explicitly track of current files in progress to prevent
    // notifying more than once for a file.
    inProgress = new Map<string, Subject<void> | null>();
    private entityIdOnAddFileStart?: string;

    constructor(
        private translate: TranslateService,
        private addAssetService: AddAssetService,
        private errorUtils: ErrorUtils,
        private changeDetectorRef: ChangeDetectorRef,
        private demoService: DemoService,
        private dialog: MatDialog,
        private ngZone: NgZone
    ) { }

    addToInProgress(addAssetId: string): void {
        if (!this.inProgress.has(addAssetId)) {
            this.inProgress.set(addAssetId, null);
            this.fileProcessingStarted.emit();
        }
    }

    appendCancel(addAssetId: string, cancel$: Subject<void>): void {
        if (!this.inProgress.has(addAssetId)) {
            console.warn(
                'trying to append cancel$ subject to an asset which is not in progress anymore'
            );
        }

        this.inProgress.set(addAssetId, cancel$);
    }

    removeFromInProgress(addAssetId: string): boolean {
        return this.inProgress.delete(addAssetId);
    }

    finishInProgress(addAssetId: string): void {
        const isInProgress = this.inProgress.has(addAssetId);

        if (isInProgress) {
            const cancel$ = this.inProgress.get(addAssetId);


            // cancel$ can be null if not set, but entry is present
            if (cancel$) {
                cancel$.next();
                cancel$.complete();
            }
            this.fileProcessingEnded.emit();

            this.inProgress.delete(addAssetId);
        }
    }

    resetPond(): void {
        // This effectively forces Angular to rebuild the file-pond component
        // I did not find another way to reliably abort / remove files, which are
        // currently being processed.
        //
        // Just calling removeFiles on the pond fixes it partially,
        // but by being very fast or using multiple files, the user can still
        // move pictures to another issue during processing.
        this.displayPond = false;
        this.changeDetectorRef.detectChanges();
        this.displayPond = true;
    }

    abortRemainingInProgress(): void {
        this.resetPond();
        const remainingIds = this.inProgress.entries();
        for (const [addAssetId, cancel$] of remainingIds) {
            this.finishInProgress(addAssetId);
        }
    }

    async ngOnInit(): Promise<void> {

        this.pondOptions = {
            name: 'files',
            labelIdle: this.translate.instant('FILEPOND.LABEL'),
            required: true,
            allowMultiple: true,
            acceptedFileTypes: this.acceptedFileTypes,
            imageResizeUpscale: false,
            imageResizeTargetWidth: environment.imageResizeTargetWidth,
            beforeAddFile: (file: FilePondFile): boolean => {
                // remove marker
                this.finishInProgress(this.entityIdOnAddFileStart);

                // the user changed the underlying entity before
                // filepond called this function (it looks like there
                // is some slow preprocessing done)
                const abortFileAdd =
                    this.entityId !== this.entityIdOnAddFileStart;
                if (abortFileAdd) {
                    return false;
                }

                const assetId =
                    ADD_ASSET_ID_PREFIX + AcceptUtils.generateGuid();
                file.setMetadata(ADD_ASSET_ID_METADATA_KEY, assetId);
                this.addToInProgress(assetId);
                return true;
            },
            onerror: (
                error: FilePondErrorDescription,
                file?: FilePondFile,
                _status?: any
            ) => {
                // an error occured
                if (file) {
                    const addAssetId = file.getMetadata(
                        ADD_ASSET_ID_METADATA_KEY
                    );
                    this.finishInProgress(addAssetId);

                    console.error(
                        'error in filepond:',
                        error,
                        'with file',
                        file
                    );
                } else {
                    console.error('non-file error in filepond:', error);

                    // remove marker
                    this.finishInProgress(this.entityIdOnAddFileStart);
                }
            },
            onprocessfileabort: (file: FilePondFile) => {
                // user aborted
                const addAssetId = file.getMetadata(ADD_ASSET_ID_METADATA_KEY);
                this.finishInProgress(addAssetId);
            },
            server: {
                process: this.onProcessFile.bind(this),
                fetch: null,
                revert: null,
            },
        };
        const platform = (await Device.getInfo()).platform;
        this.isDevice = platform === 'android' || platform === 'ios';
        this.pondOptions = {
            ...this.pondOptions,
            allowBrowse: !this.isDevice,
            allowDrop: !this.isDevice,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['entityId']) {
        }

        if (!this.entityId) {
            this.entityId = 'attachmentcache';
        }

        const entityIdChanges = changes['entityId'];
        const underlyingEntityIdChanged =
            entityIdChanges &&
            entityIdChanges.previousValue !== entityIdChanges.currentValue;
        const pondInitialized = !!this.pond;

        const abortRemaining =
            underlyingEntityIdChanged &&
            !entityIdChanges.firstChange &&
            pondInitialized;

        if (abortRemaining) {
            this.abortRemainingInProgress();
        }
    }

    onInput(event: InputEvent): void {
        if (!this.isDevice) {
            this.onStartAddingFile();
        }
    }

    private onStartAddingFile(): void {
        this.entityIdOnAddFileStart = this.entityId;

        // The id of the current entity is used as a marker, that
        // a file is in the process of being added. The marker
        // is used to notify the issue-detail component, that
        // there is some file processing being done. It is removed
        // when FilePond says that it started adding a file.
        //
        // There is significant delay between here and beforeAddFile
        // enough for the user to e.g. select another issue.
        this.addToInProgress(this.entityIdOnAddFileStart);
    }

    onDrop(): void {
        this.onStartAddingFile();
    }

    async useCapacitorCamera(event: Event): Promise<void> {
        // TODO: this click handler is called multiple times, it
        //       looks like filepond registers the given click event
        //       handler on multiple overlaying objects...

        if (!this.isDevice) {
            return;
        }

        // fix web view UI elements appearing in iOS on second visit
        event.preventDefault();

        // currently using any because its a hotfix for Capacitor 1.5.2 in the native plugins
        // imported from Capacitor 2.x and there are no type definitions yet
        const localization: any = {
            promptLabelHeader: this.translate.instant(
                CAPACITOR_CAMERA_LABELS_PREFIX + 'HEADER'
            ),
            promptLabelPhoto: this.translate.instant(
                CAPACITOR_CAMERA_LABELS_PREFIX + 'FROM_GALLERY'
            ),
            promptLabelPicture: this.translate.instant(
                CAPACITOR_CAMERA_LABELS_PREFIX + 'FROM_CAMERA'
            ),
            promptLabelCancel: this.translate.instant('APP.CANCEL'),
        };

        const photo = await Camera.getPhoto({
            quality: 70,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            // let the native plugin perform the resize
            width: environment.imageResizeTargetWidth,
            // note: this should be the default according to docs,
            //       but capturing photo breaks on API 29 or with added
            //       cordova-file-plugin (adds new Android permission)
            //       without this
            saveToGallery: false,
            ...localization,
        });

        this.pond.addFile(photo.webPath);
        this.onStartAddingFile();
    }

    private async onProcessFile(
        fieldName: any,
        file: any,
        metadata: any,
        load: any,
        error: any,
        progress: any,
        abort: any
    ): Promise<any> {
        const addAssetId = metadata[ADD_ASSET_ID_METADATA_KEY];

        if (this.demoService.shouldUseReadOnlyDemoBehaviour()) {
            // Signal the file pond, that the file upload has been cancelled &
            // reset it.
            abort();
            this.resetPond();

            // Ensure that the change handling logic picks up correctly,
            // that no upload is in progress anymore.
            this.finishInProgress(addAssetId);

            // It looks like the File Pond Component calls this callback outside
            // of the Angular Zone. This causes issues with the dialog until
            // the next change detection cycle including opening a broken
            // dialog first, closing it and then opening the good one.
            //
            // This forces will run the callback inside the zone, so that the
            // dialog works correctly.
            this.ngZone.run(() => {
                this.dialog.open(DemoDialogComponent);
            });

            return;
        }

        // by using a replay subject cancels before the subscription
        // on this this observable are also recognized
        // behavior subject could also be used, but it requires an
        // initial value which would need to be handled separately
        const cancel$ = new ReplaySubject<void>();
        this.appendCancel(addAssetId, cancel$);

        try {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const result = await this.addAssetService.uploadAssetDbProxy({
                file,
                entityType: this.entityType,
                entityId: this.entityId,
                nonBlocking: true,
                cancelBackendUpload$: cancel$,
            });

            if (result) {
                const { id, fileName, fileSize, contentType } = result;

                this.onUploadComplete({
                    id,
                    fileInfos: {
                        id,
                        fileName,
                        fileSize,
                        contentType,
                    },
                    addAssetId,
                });
                load(id);
            }
        } catch (ex) {
            this.errorUtils.showSingleMessageOrDefault(
                null,
                'ISSUES.STORE_ATTACHMENT'
            );
            console.error(ex);
            error();
        }

        return {
            abort: () => {
                abort();
            },
        };
    }

    private entityHasNotChangedDuringUpload(): boolean {
        return this.entityIdOnAddFileStart === this.entityId;
    }

    private assetStillInProgress(addAssetID: string): boolean {
        return this.inProgress.has(addAssetID);
    }

    private onUploadComplete(uploadComplete: UploadComplete): void {
        const { id, fileInfos, addAssetId } = uploadComplete;

        const shouldNotify =
            this.entityHasNotChangedDuringUpload() &&
            this.assetStillInProgress(addAssetId);

        // prevent taking the asset to other entity
        if (shouldNotify) {
            this.fileUploaded.emit({ id, fileInfos });
        }

        this.pond.removeFiles();
        this.finishInProgress(addAssetId);
    }

    ngOnDestroy(): void {
        // notify about remaining assets which are still in progress
        this.abortRemainingInProgress();
    }
}
