import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getForceOffline } from 'app/store/sync/sync.selectors';
import { environment } from 'environments/environment';
import { CoreState } from './core.reducer';

export const selectCoreState = createFeatureSelector<CoreState>('core');

export const getSearch = createSelector(selectCoreState, state => state.search);

export const getLanguage = createSelector(
    selectCoreState,
    state => state.language
);

export const getLangcode = createSelector(
    selectCoreState,
    state =>
        environment.languages.find(lang => lang.code === state.language)
            .langCode
);

export const getNetworkStatus = createSelector(
    selectCoreState,
    state => state.networkStatus
);

export const getIsConnected = createSelector(
    getNetworkStatus,
    network => network.connected
);

export const getIsDevice = createSelector(
    selectCoreState,
    // state => false
    state => state.isDevice
);

export const getIsOnline = createSelector(
    getIsConnected,
    getForceOffline,
    (connected, forceOffline) => connected && !forceOffline
);

export const getIsOffline = createSelector(getIsOnline, online => !online);

export const getIsDeviceAndOffline = createSelector(
    getIsDevice,
    getIsOffline,
    (isDevice, isOffline) => isDevice && isOffline
);

export const getConnectionType = createSelector(
    getNetworkStatus,
    status => status.connectionType
);
