import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeDuplicated' })
export class RemoveDuplicatedPipe implements PipeTransform {
    transform(value: any[]): any[] {
        let returnList = [];

        for (let item of value) {
            if (!returnList.find(element => item === element))
                returnList.push(item);
        }

        return returnList;
    }
}
