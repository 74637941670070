import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Action, select, Store } from '@ngrx/store';
import { DailyLogEntryBase, DailyLogsService } from 'app/core/rest-api';
import { AcceptUtils, not } from 'app/core/utils/accept-utils';
import { ErrorUtils } from 'app/core/utils/error-util';
import { CommandDbService } from 'app/db/commands/command.db.service';
import { DiaryDbService } from 'app/db/diary/diary.db.service';
import { DiaryCommand } from 'app/main/diary/models/diary-command';
import { SyncService } from 'app/main/sync/sync.service';
import { defer, forkJoin, from, of } from 'rxjs';
import {
    catchError,
    concatMap,
    debounceTime,
    filter,
    map,
    mergeMap,
    tap,
    withLatestFrom,
    switchMap,
} from 'rxjs/operators';
import { HasConflict } from '../../main/models/has-conflict';
import { HasError } from '../../main/models/has-error';
import { getIsDevice, getIsOnline } from '../core/core.selectors';
import {
    CreateIssueCommand,
    CreateIssuesSuccess,
    IssuesActionTypes,
} from '../issues/issues.actions';
import {
    getCurrentDiaryEntryId,
    getCurrentProjectId,
} from '../router/router.selectors';
import {
    AddEntityErrors,
    DeleteCommandRequest,
    MarkCommandsInFlightForEntities,
    NavigateToSync,
    StoreCommandRequest,
    SyncAfterOffline,
} from '../sync/sync.actions';
import { DiaryStoreUtilsService } from './diary-store-utils.service';
import {
    AddToIssueIdsForNewInspection,
    CreateDiaryCommand,
    CreateDiaryConflicts,
    CreateDiaryEntriesError,
    CreateDiaryEntriesRequest,
    CreateDiaryEntriesSuccess,
    DiaryActionTypes,
    LoadDiaryCommandsError,
    LoadDiaryCommandsRequest,
    LoadDiaryCommandsSuccess,
    LoadDiaryEntriesError,
    LoadDiaryEntriesRequest,
    LoadDiaryEntriesSuccess,
    SyncDiaryCommands,
    UpdateDiaryEntriesError,
    UpdateDiaryEntriesRequest,
    UpdateDiaryEntriesSuccess,
    UpsertDiariesFinish,
    UpsertDiariesStart,
    StoreDiaryConflicts,
    LoadDiaryConflicts,
    SetDiaryConflicts,
    UpdateDiaryEntriesAttachmentIds,
    AddDiaryAttachmentMappings,
    ClearDiaryAttachmentMap,
} from './diary.actions';
import { DiaryState } from './diary.reducer';
import {
    getDiaryCommands,
    getSelectedInspectionEntry,
    getDiaryConflicts,
    getDiaryFullyInitialized,
    getDiaryAttachmentMap,
} from './diary.selectors';
import { DiaryEntry } from './models/diary-entry';
import { DiaryConflictStorageService } from 'app/db/conflict/conflict-storage.service';
import { CommandStoreUtils } from '../commands/commands-store-utils';
import { ConflictUtils } from 'app/db/conflict/conflict-utils';
import { DiaryEntityError } from '../sync/entity-response.model';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { DiaryToCreate } from './models/diary-to-create';
import { DiaryToUpdate } from './models/diary-to-update';

@Injectable()
export class DiaryEffects {
    /**
     * needed for inspection issue connection
     * we will only let this effect run, if we are
     * currently in the diary section
     */
    @Effect()
    createdIssueCommandInDiarySection$ = this.actions$.pipe(
        ofType<CreateIssueCommand>(IssuesActionTypes.CREATE_ISSUE_COMMAND),
        map((action) => action.payload.command),
        filter((command) => this.router.url.includes('diary')),
        withLatestFrom(
            this.store.pipe(select(getCurrentDiaryEntryId)),
            this.store.pipe(select(getSelectedInspectionEntry))
        ),
        concatMap(([command, currentDiaryEntryId, selectedInspectionEntry]) => {
            if (!selectedInspectionEntry) {
                return [];
            }

            if (currentDiaryEntryId === 'new') {
                return of(
                    new AddToIssueIdsForNewInspection({ id: command.entityId })
                );
            } else {
                const issuesIDs = selectedInspectionEntry.issuesIDs || [];
                // if this is a new created issue, lock the diary command until issue creation
                const locked = AcceptUtils.isLocalGuid(command.entityId);
                let diaryCommand = new DiaryCommand(selectedInspectionEntry, {
                    type: DailyLogEntryBase.TypeEnum.InspectionEntry,
                    updateDateTime: selectedInspectionEntry.updateDateTime,
                    issuesIDs: [...issuesIDs, command.entityId],
                });
                diaryCommand = {
                    ...diaryCommand,
                    locked,
                };
                return of(new CreateDiaryCommand({ command: diaryCommand }));
            }
        })
    );

    /**
     * we possibly have diary commands waiting for issue creation (inspection)
     * we trigger the sync again after successfull issue creation
     */
    @Effect()
    issuesCreated$ = this.actions$.pipe(
        ofType<CreateIssuesSuccess>(IssuesActionTypes.CREATE_ISSUES_SUCCESS),
        concatMap(() => of(new SyncDiaryCommands()))
    );

    @Effect()
    upsertDiariesStart$ = this.actions$.pipe(
        ofType<UpsertDiariesStart>(DiaryActionTypes.UPSERT_DIARIES_START),
        map((action) => action.payload.diaries),
        concatMap((diaries) =>
            from(this.diaryDb.bulkInsert(diaries as DiaryEntry[])).pipe(
                map((results) => new UpsertDiariesFinish({ results }))
            )
        )
    );

    @Effect()
    upsertDiariesFinish$ = this.actions$.pipe(
        ofType<UpsertDiariesFinish>(DiaryActionTypes.UPSERT_DIARIES_FINISH),
        map((action) => new SyncAfterOffline())
    );

    @Effect()
    loadDiariesSuccess$ = this.actions$.pipe(
        ofType<LoadDiaryEntriesSuccess>(
            DiaryActionTypes.LOAD_DIARY_ENTRIES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.diaries),
        concatMap((diaries) =>
            of(new UpsertDiariesStart({ diaries: diaries as DiaryEntry[] }))
        )
    );

    @Effect()
    loadCommands$ = this.actions$.pipe(
        ofType<LoadDiaryCommandsRequest>(
            DiaryActionTypes.LOAD_DIARY_COMMANDS_REQUEST
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        mergeMap(([payload, isDevice]) => {
            return !isDevice
                ? of(
                      new LoadDiaryCommandsSuccess({
                          commands: [],
                          entityType: payload.entityType,
                      })
                  )
                : from(
                      this.commandDb.getCommandsByEntityType(payload.entityType)
                  ).pipe(
                      map(
                          (commands) =>
                              new LoadDiaryCommandsSuccess({
                                  commands,
                                  entityType: payload.entityType,
                              })
                      ),
                      catchError((error) =>
                          of(new LoadDiaryCommandsError({ error }))
                      )
                  );
        })
    );

    @Effect()
    createDiaryConflicts$ = this.actions$.pipe(
        ofType<CreateDiaryConflicts>(DiaryActionTypes.CREATE_DIARY_CONFLICTS),
        map(() => new StoreDiaryConflicts())
    );

    @Effect()
    loadDiaryConflicts$ = this.actions$.pipe(
        ofType<LoadDiaryConflicts>(DiaryActionTypes.LOAD_DIARY_CONFLICTS),
        switchMap(() => this.diaryConflictStorageService.getAll()),
        map(
            (conflicts) =>
                new SetDiaryConflicts({
                    conflicts,
                })
        )
    );

    @Effect({ dispatch: false })
    storeDiaryConflicts$ = this.actions$.pipe(
        ofType<StoreDiaryConflicts>(DiaryActionTypes.STORE_DIARY_CONFLICTS),
        withLatestFrom(this.store.pipe(select(getDiaryConflicts))),
        mergeMap(([action, conflicts]) =>
            this.diaryConflictStorageService.setAll(conflicts)
        )
    );

    @Effect()
    createDiaryCommand$ = this.actions$.pipe(
        ofType<CreateDiaryCommand>(DiaryActionTypes.CREATE_DIARY_COMMAND),
        map((action) => action.payload),
        filter((payload) =>
            not(DiaryStoreUtilsService.onlyEmptyChanges)(payload.command)
        ),
        withLatestFrom(
            this.store.pipe(select(getIsDevice)),
            this.store.pipe(select(getDiaryConflicts))
        ),
        tap(([payload, isDevice, conflicts]) => {
            const { command } = payload;
            if (
                command.action === 'create' &&
                AcceptUtils.isLocalGuid(command.entityId)
            ) {
                // after command creation, if it is a create command, redirect to command after creation
                const projectId = command.projectId;
                const base = ['/projects', projectId];
                const diaryEntryId = command.entityId;
                const showInspectionList = null;
                this.router.navigate([...base, 'diary'], {
                    queryParams: { diaryEntryId, showInspectionList },
                    queryParamsHandling: 'merge',
                });
            }
        }),
        mergeMap(([payload, isDevice, conflicts]) => {
            let { command } = payload;
            const actions = [];
            if (isDevice) {
                if (payload.applyConflict) {
                    const query = {
                        entityId: payload.command.entityId,
                        entityType: 'diary',
                    };
                    actions.push(new DeleteCommandRequest({ query }));
                } else {
                    // note: see CREATE_ISSUE_COMMAND reducer
                    command = ConflictUtils.lockConflictingCommands(conflicts, [
                        command,
                    ])[0];
                }
                actions.push(new StoreCommandRequest({ command }));
            }
            actions.push(new SyncDiaryCommands());
            return actions;
        })
    );

    @Effect()
    setDiaryConflicts$ = this.actions$.pipe(
        ofType<SetDiaryConflicts>(DiaryActionTypes.SET_DIARY_CONFLICTS),
        map(() => new SyncDiaryCommands())
    );

    @Effect()
    loadDiaryCommandsSuccess$ = this.actions$.pipe(
        ofType<LoadDiaryCommandsSuccess>(
            DiaryActionTypes.LOAD_DIARY_COMMANDS_SUCCESS
        ),
        map(() => new SyncDiaryCommands())
    );

    @Effect()
    syncDiaryCommands$ = this.actions$.pipe(
        ofType<SyncDiaryCommands>(DiaryActionTypes.SYNC_DIARY_COMMANDS),
        debounceTime(20),
        concatMap((action) => {
            // see https://stackoverflow.com/questions/39635680
            // this serializes executing the inner observable if the actions one emits
            // if the inner has not completed
            //
            // this is done here and not instead of the mergeMap
            // because we always need the newest state, e.g. diary commands,
            // to prevent processing the commands multiple times

            return of(action).pipe(
                withLatestFrom(
                    this.store.pipe(select(getDiaryCommands)),
                    this.store.pipe(select(getIsOnline)),
                    this.store.pipe(select(getDiaryFullyInitialized))
                ),
                filter(
                    ([
                        action,
                        diaryCommands,
                        isOnline,
                        diaryEntriesInitialized,
                    ]) => isOnline && diaryEntriesInitialized
                ),
                mergeMap(
                    ([
                        action,
                        diaryCommands,
                        isOnline,
                        diaryEntriesInitialized,
                    ]) => {
                        if (diaryCommands.length === 0) {
                            // no changes: request sync from server
                            return [new SyncAfterOffline()];
                        }

                        return from(
                            // because of the outer concatMap, only one of this
                            // runs concurrently and e.g. uploads attachments
                            this.diaryStoreUtils.prepareDiaryCommands(
                                diaryCommands
                            )
                        ).pipe(
                            mergeMap((preparedCommands) => {
                                const actions = [];

                                actions.push(
                                    DiaryEffects.createMarkCommandsInFlightAction(
                                        preparedCommands.diariesToCreate,
                                        preparedCommands.diariesToUpdate
                                    )
                                );

                                const {
                                    diariesToCreate,
                                    diariesToUpdate,
                                    attachmentMap,
                                } = preparedCommands;

                                // attachments have been uploaded, thus enqueue local id replacement everywhere for diaries
                                actions.push(
                                    new AddDiaryAttachmentMappings({
                                        attachmentMap,
                                    })
                                );
                                actions.push(
                                    new UpdateDiaryEntriesAttachmentIds()
                                );

                                // if we have create
                                if (diariesToCreate.length > 0) {
                                    actions.push(
                                        new CreateDiaryEntriesRequest({
                                            diaries: diariesToCreate,
                                        })
                                    );
                                }
                                // if we have plan updates and no issue updates

                                const projectIds = [
                                    ...new Set(
                                        diariesToUpdate.map((u) => u.projectId)
                                    ),
                                ];
                                for (const projectId of projectIds) {
                                    const toUpdateForProject = diariesToUpdate.filter(
                                        (u) => u.projectId === projectId
                                    );
                                    const updates = toUpdateForProject.map(
                                        (u) => u.update
                                    );
                                    actions.push(
                                        new UpdateDiaryEntriesRequest({
                                            projectId,
                                            updates,
                                        })
                                    );
                                }

                                return actions;
                            })
                        );
                    }
                )
            );
        })
    );

    @Effect()
    updateDiaryEntriesAttachmentIds$ = this.actions$.pipe(
        ofType<UpdateDiaryEntriesAttachmentIds>(
            DiaryActionTypes.UPDATE_DIARY_ENTRIES_ATTACHMENT_IDS
        ),
        withLatestFrom(
            this.store.pipe(select(getDiaryAttachmentMap)),
            this.store.pipe(select(getDiaryCommands)),
            this.store.pipe(select(getIsDevice))
        ),
        filter(([action, attachmentMap, diaryCommands, isDevice]) => isDevice),
        mergeMap(([action, attachmentMap, diaryCommands, isDevice]) => {
            return CommandStoreUtils.updateAttachmentIdsInDb({
                commands: diaryCommands,
                attachmentMap,
                entityUpdater:
                    DiaryStoreUtilsService.replaceDiaryAttachmentIdsInCommandChanges,
                commandDbService: this.commandDb,
                fileStorageService: this.fileStorageService,
                clearAction: new ClearDiaryAttachmentMap(),
            });
        })
    );

    @Effect()
    loadDiaryEntries$ = this.actions$.pipe(
        ofType<LoadDiaryEntriesRequest>(
            DiaryActionTypes.LOAD_DIARY_ENTRIES_REQUEST
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([payload, currentProjectId]) => {
            const { projectId, archived } = payload;
            const server$ = this.dailyLogsService.projectsByProjectIdDailylogsGet(
                projectId,
                archived
            );

            const db$ = defer(() =>
                from(this.diaryDb.getByProjectId(projectId))
            );

            return this.syncService.fetchFromServerOrDb({ server$, db$ }).pipe(
                map(
                    ({ data, fromDb }) =>
                        new LoadDiaryEntriesSuccess({
                            diaries: data as DailyLogEntryBase[],
                            fromDb,
                            currentProjectId,
                            requestProjectId: projectId,
                        })
                ),
                catchError((error) => of(new LoadDiaryEntriesError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    errorLoadDiaryEntries$ = this.actions$.pipe(
        ofType<LoadDiaryEntriesError>(
            DiaryActionTypes.LOAD_DIARY_ENTRIES_ERROR
        ),
        map((action) => action.payload.error),
        map((error) => {
            this.errorUtils.showSingleMessageOrDefault(error, 'DIARY.LOAD');

            return of();
        })
    );

    @Effect()
    createDiaryEntries$ = this.actions$.pipe(
        ofType<CreateDiaryEntriesRequest>(
            DiaryActionTypes.CREATE_DIARY_ENTRIES_REQUEST
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),

        mergeMap(([{ diaries }, currentProjectId]) => {
            const requests = diaries.map((entry) => {
                const { diary, entityId } = entry;
                return this.dailyLogsService
                    .projectsByProjectIdDailylogsPost(diary.projectId, diary)
                    .pipe(
                        map((response) => ({ diary: response.data, entityId })),
                        catchError((error) =>
                            of({
                                error: {
                                    ...error,
                                    entityId,
                                },
                                hasError: true,
                            })
                        )
                    );
            });

            return forkJoin(requests).pipe(
                mergeMap((responses: any[]) => {
                    const entries = responses.filter((r) => r.diary);
                    const actions = [];
                    if (entries.length > 0) {
                        actions.push(
                            new CreateDiaryEntriesSuccess({
                                diaries: entries,
                                currentProjectId,
                            })
                        );
                    }

                    const errors = responses
                        .filter((r) => r.error)
                        .map(
                            (r) =>
                                new DiaryEntityError(
                                    r.error.entityId,
                                    'create',
                                    r.error
                                )
                        );
                    if (errors.length > 0) {
                        actions.push(new CreateDiaryEntriesError({ errors }));
                    }

                    const onlyErrors =
                        entries.length === 0 && errors.length > 0;
                    if (onlyErrors) {
                        actions.push(new SyncAfterOffline());
                    }

                    return actions;
                })
            );
        })
    );

    @Effect()
    createDiaryEntriesSuccess$ = this.actions$.pipe(
        ofType<CreateDiaryEntriesSuccess>(
            DiaryActionTypes.CREATE_DIARY_ENTRIES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        tap(([payload, isDevice]) => {
            // currenty navigation = command, whose issue now got created
            // is yes, navigate to new created issue
            let currentlyViewingCreatedDiary = false;
            let diaryEntryId;
            let projectId;
            for (const entry of payload.diaries) {
                if (this.router.url.includes(entry.entityId)) {
                    currentlyViewingCreatedDiary = true;
                    diaryEntryId = entry.diary.id;
                    projectId = entry.diary.projectId;
                }
            }
            if (currentlyViewingCreatedDiary) {
                const base = ['/projects', projectId];
                this.router.navigate([...base, 'diary'], {
                    queryParams: {
                        diaryEntryId,
                    },
                    queryParamsHandling: 'merge',
                });
            }
        }),
        mergeMap(([payload, isDevice]) => {
            const actions: any[] = [];
            // for commands waiting for creation, to retrigger sync
            actions.push(new SyncDiaryCommands());
            if (isDevice) {
                // Store new Issue State in DB
                // todo: delete all commands in one go
                for (const diary of payload.diaries) {
                    const query = {
                        entityId: diary.entityId as string,
                        entityType: 'diary',
                    };
                    // todo: need to merge in one db command do delete
                    actions.push(
                        new DeleteCommandRequest({
                            query,
                        })
                    );
                }
                const diaries = payload.diaries.map((i) => i.diary);
                actions.push(
                    new UpsertDiariesStart({
                        diaries,
                    })
                );
            }

            return actions;
        })
    );

    @Effect()
    errorCreateDiaryEntries$ = this.actions$.pipe(
        ofType<CreateDiaryEntriesError>(
            DiaryActionTypes.CREATE_DIARY_ENTRIES_ERROR
        ),
        map((action) => action.payload.errors),
        map((errors) => {
            this.errorUtils.showSingleMessageOrDefault(errors, 'DIARY.CREATE', {
                autohide: false,
                buttonLabelKey: 'SYNC.GO_TO_ERRORS',
                dispatchAction: new NavigateToSync(),
            });

            return new AddEntityErrors({
                errors,
            });
        })
    );

    @Effect()
    errorUpdateDiaryEntries$ = this.actions$.pipe(
        ofType<UpdateDiaryEntriesError>(
            DiaryActionTypes.UPDATE_DIARY_ENTRIES_ERROR
        ),
        map((action) => action.payload.errors),
        map((errors) => {
            this.errorUtils.showSingleMessageOrDefault(errors, 'DIARY.UPDATE', {
                autohide: false,
                buttonLabelKey: 'SYNC.GO_TO_ERRORS',
                dispatchAction: new NavigateToSync(),
            });

            return new AddEntityErrors({
                errors,
            });
        })
    );

    @Effect()
    updateDiaryEntries$ = this.actions$.pipe(
        ofType<UpdateDiaryEntriesRequest>(
            DiaryActionTypes.UPDATE_DIARY_ENTRIES_REQUEST
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),

        mergeMap(([{ updates, projectId }, currentProjectId]) => {
            const requests = updates.map((update) => {
                return this.dailyLogsService
                    .projectsByProjectIdDailylogsByDailyLogEntryIdPatch(
                        projectId,
                        update.id.toString(),
                        update.changes as DailyLogEntryBase
                    )
                    .pipe(
                        map((response) => response.data),
                        map((diary) => ({
                            id: diary.id,
                            changes: diary,
                        })),
                        catchError((error) => {
                            if (error.status === 409) {
                                return of({
                                    conflict: error.error.data[0],
                                    isConflict: true,
                                });
                            }
                            return of({
                                error: {
                                    ...error,
                                    entityId: update.id as string,
                                },
                                isError: true,
                            });
                        })
                    );
            });

            return forkJoin(requests).pipe(
                mergeMap((responses: any) => {
                    const actions = [];
                    // tslint:disable-next-line: no-shadowed-variable
                    const updates: Update<
                        DailyLogEntryBase
                    >[] = responses.filter((r) => !r.isConflict && !r.isError);
                    const haveConflicts: HasConflict<
                        DailyLogEntryBase
                    >[] = responses.filter((r) => r.isConflict);
                    const conflicts = haveConflicts.map((c) => c.conflict);
                    if (updates.length > 0) {
                        actions.push(
                            new UpdateDiaryEntriesSuccess({
                                updates,
                                currentProjectId,
                            })
                        );
                    }
                    if (conflicts.length > 0) {
                        actions.push(new CreateDiaryConflicts({ conflicts }));
                    }

                    const haveErrors: HasError[] = responses.filter(
                        (r) => r.isError
                    );
                    const errors = haveErrors.map(
                        (e) =>
                            new DiaryEntityError(
                                e.error.entityId,
                                'update',
                                e.error
                            )
                    );
                    if (errors.length > 0) {
                        actions.push(new UpdateDiaryEntriesError({ errors }));
                    }

                    const noUpdates = updates.length === 0;
                    const conflictsOrErrors =
                        conflicts.length > 0 || errors.length > 0;
                    if (noUpdates && conflictsOrErrors) {
                        actions.push(new SyncAfterOffline());
                    }

                    actions.push(new SyncDiaryCommands());
                    return actions;
                })
            );
        })
    );

    @Effect()
    updateDiaryEntriesSuccess$ = this.actions$.pipe(
        ofType<UpdateDiaryEntriesSuccess>(
            DiaryActionTypes.UPDATE_DIARY_ENTRIES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        mergeMap(([payload, isDevice]) => {
            const actions: any[] = [];
            if (isDevice) {
                for (const update of payload.updates) {
                    const query = {
                        entityId: update.id as string,
                        entityType: 'diary',
                    };
                    actions.push(
                        new DeleteCommandRequest({
                            query,
                        })
                    );
                }
                const changes = payload.updates.map((u) => u.changes);
                actions.push(
                    new UpsertDiariesStart({
                        diaries: [...changes] as DailyLogEntryBase[],
                    })
                );
            }

            actions.push(new StoreDiaryConflicts());

            return actions;
        })
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private dailyLogsService: DailyLogsService,
        private store: Store<DiaryState>,
        private errorUtils: ErrorUtils,
        private diaryDb: DiaryDbService,
        private fileStorageService: FileStorageService,
        private commandDb: CommandDbService<DiaryCommand>,
        private syncService: SyncService,
        private diaryStoreUtils: DiaryStoreUtilsService,
        private diaryConflictStorageService: DiaryConflictStorageService
    ) {}

    private static createMarkCommandsInFlightAction(
        diariesToCreate: DiaryToCreate[],
        diariesToUpdate: DiaryToUpdate[]
    ): Action {
        return new MarkCommandsInFlightForEntities({
            entityIds: [
                ...diariesToCreate.map(
                    (diaryToCreate) => diaryToCreate.entityId
                ),
                ...diariesToUpdate.map(
                    // this is the entity id, see DiaryStoreUtils
                    (diaryToUpdate) => {
                        // note: id is from NgRx Entity, thus it has
                        //       a number | string type, we only use
                        //       strings
                        const id = diaryToUpdate.update.id;

                        if (typeof id !== 'string') {
                            throw new Error('non-string id for diary entity');
                        }

                        return id;
                    }
                ),
            ],
        });
    }
}
