/**
 * eTeacher API
 * Last Build: 12/14/2021 10:28:38 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TupleTemplateValuePurposeObject } from './tupleTemplateValuePurposeObject';
import { MongoFile } from './mongoFile';
import { Contact } from './contact';


export interface RTFTemplate { 
    id?: string;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    language?: string;
    title?: string;
    file?: MongoFile;
    purpose?: RTFTemplate.PurposeEnum;
    customKeyValuePairs?: { [key: string]: TupleTemplateValuePurposeObject; };
    readonly templateType?: string;
}
export namespace RTFTemplate {
    export type PurposeEnum = 'Issues' | 'DailyLog' | 'InspectionEntryLock';
    export const PurposeEnum = {
        Issues: 'Issues' as PurposeEnum,
        DailyLog: 'DailyLog' as PurposeEnum,
        InspectionEntryLock: 'InspectionEntryLock' as PurposeEnum
    };
}


