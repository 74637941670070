import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typeicon'
})
export class TypeIconPipe implements PipeTransform {
    transform(contentType: string, args: any[]): string {
        switch (contentType) {
            case 'application/pdf':
                return 'picture_as_pdf';
            case 'application/msexcel':
                return 'table_chart';
            case 'application/msword':
                return 'subtitles';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'subtitles';
            case 'application/xml':
                return 'subtitles';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'table_chart';
            case 'application/rtf':
                return 'receipt';
            case 'application/zip':
                return 'redeem';
            case 'audio/mp4':
                return 'music_note';
            case 'audio/ogg':
                return 'music_note';
            case 'audio/wav':
                return 'music_note';
            case 'audio/x-midi':
                return 'music_note';
            case 'audio/x-mpeg':
                return 'music_note';
            case 'video/mpeg':
                return 'movie';
            case 'video/mp4':
                return 'movie';
            case 'video/ogg':
                return 'movie';
            case 'video/quicktime':
                return 'movie';
            case 'video/x-msvideo':
                return 'movie';
            case 'video/x-sgi-movie':
                return 'movie';
            default:
                return 'insert_drive_file';
        }
    }
}
