import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    Project,
    ProjectsService,
    ResponseModelListProject,
} from 'app/core/rest-api';
import { ProjectTemplatesDbService } from 'app/db/projects-templates/project.db.service';
import { ProjectDbService } from 'app/db/projects/project.db.service';
import { SyncService } from 'app/main/sync/sync.service';
import { defer, forkJoin, from, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { getIsDevice, getIsOnline } from '../core/core.selectors';
import { ProjectTemplatesState } from './project-templates.reducer';
import {
    LoadProjectTemplatesRequest,
    ProjectTemplatesActionTypes,
    LoadProjectTemplatesSuccess,
    LoadProjectTemplatesError,
    UpsertProjectTemplatesStart,
    UpsertProjectTemplatesFinish,
    CreateProjectTemplateRequest,
    CreateProjectTemplateSuccess,
    CreateProjectTemplateError,
    UpdateProjectTemplateRequest,
    UpdateProjectTemplateSuccess,
    UpdateProjectTemplateError,
} from './project-templates.actions';
import { getIsLoggedIn } from '../auth/auth.selectors';
import { ProjectsActionTypes } from '../projects/projects.actions';

@Injectable()
export class ProjectTemplatesEffects {
    unnecessaryProperties: string[] = [
        'id',
        'creationDate',
        'ownerId',
        'ownerId',
        'owner',
        'updaterId',
        'updater',
        'creatorId',
        'creator',
        'markedAsDelete',
        'deleteDateTime',
        'updateDateTime',
        'isTemplate',
    ];

    @Effect()
    loadProjectTemplates$ = this.actions$.pipe(
        // This isn't perfect design to import the LOAD_PROJECTS_REQUEST, but is simple
        ofType<LoadProjectTemplatesRequest>(
            ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_REQUEST,
        ),
        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([action, isLoggedIn]) => isLoggedIn),
        switchMap(([action, isLoggedIn]) => {
            const server$ = this.projectsService
                .projectsGet(action.payload.withArchived, true, false)
                .pipe(
                    map((response) => response.data),
                    switchMap((templatesFromUser) => {
                        const userCreatedTemplates: Project[] = templatesFromUser.map(
                            (template) => {
                                return { ...template, isReadOnly: false };
                            }
                        );

                        return this.projectsService
                            .projectsGet(
                                action.payload.withArchived,
                                true,
                                true
                            )
                            .pipe(
                                map((response) => response.data),
                                map((allTemplates) => {
                                    const readOnlyTemplates: Project[] = allTemplates
                                        .filter(
                                            (item) =>
                                                userCreatedTemplates.find(
                                                    (template) =>
                                                        template.id === item.id
                                                ) !== null
                                        )
                                        .map((templateData) => {
                                            return {
                                                ...templateData,
                                                isReadOnly: true,
                                            };
                                        });

                                    const newResponse: ResponseModelListProject = {
                                        data: [
                                            ...userCreatedTemplates,
                                            ...readOnlyTemplates,
                                        ],
                                    };
                                    return newResponse;
                                })
                            );
                    })
                );

            const db$ = defer(() => from(this.projectTemplatesDb.getAll()));
            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map((response) => {
                        return new LoadProjectTemplatesSuccess({
                            projectTemplates: response.data as Project[],
                            fromDb: response.fromDb,
                        });
                    }),
                    catchError(() => of(new LoadProjectTemplatesError()))
                );
        })
    );

    @Effect()
    loadProjectsTemplatesSuccess$ = this.actions$.pipe(
        ofType<LoadProjectTemplatesSuccess>(
            ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload),
        switchMap(({ projectTemplates }) =>
            of(
                new UpsertProjectTemplatesStart({
                    projectTemplates,
                })
            )
        )
    );

    @Effect()
    upsertProjectTemplatesStart$ = this.actions$.pipe(
        ofType<UpsertProjectTemplatesStart>(
            ProjectTemplatesActionTypes.UPSERT_PROJECT_TEMPLATES_START
        ),
        map((action) => action.payload.projectTemplates),
        switchMap((projectTemplates) =>
            from(this.projectTemplatesDb.bulkInsert(projectTemplates)).pipe(
                map((results) => new UpsertProjectTemplatesFinish({ results }))
            )
        )
    );

    @Effect()
    createProjectTemplate$ = this.actions$.pipe(
        ofType<CreateProjectTemplateRequest>(
            ProjectTemplatesActionTypes.CREATE_PROJECT_TEMPLATE_REQUEST
        ),
        mergeMap((action) => {
            const template = this.getProjectWithoutUnnecessaryTemplateProperties(
                action.payload.template
            );

            return this.projectsService
                .projectsCreatePost(
                    action.payload.templateProjectId,
                    'de',
                    true,
                    template
                )
                .pipe(
                    map(
                        (response) =>
                            new CreateProjectTemplateSuccess({
                                template: response.data,
                            })
                    ),
                    catchError((error) => of(new CreateProjectTemplateError()))
                );
        })
    );

    @Effect()
    updateProjectTemplate$ = this.actions$.pipe(
        ofType<UpdateProjectTemplateRequest>(
            ProjectTemplatesActionTypes.UPDATE_PROJECT_TEMPLATE_REQUEST
        ),
        mergeMap((action) =>
            this.projectsService
                .projectsByProjectIdPatch(
                    action.payload.template.id,
                    action.payload.template
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (template) =>
                            new UpdateProjectTemplateSuccess({
                                template,
                            })
                    ),
                    catchError(() => of(new UpdateProjectTemplateError()))
                )
        )
    );

    @Effect({ dispatch: false })
    errorLoadProjectTemplates$ = this.actions$.pipe(
        ofType<LoadProjectTemplatesError>(
            ProjectTemplatesActionTypes.LOAD_PROJECT_TEMPLATES_ERROR
        ),
        switchMap((error: LoadProjectTemplatesError) => {
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private projectsService: ProjectsService,
        private snackBar: MatSnackBar,
        private store: Store<ProjectTemplatesState>,
        private projectDb: ProjectDbService,
        private projectTemplatesDb: ProjectTemplatesDbService,
        private syncService: SyncService
    ) {}

    private getProjectWithoutUnnecessaryTemplateProperties(
        project: Project
    ): Project {
        const resultProject = { ...project };

        for (const property of this.unnecessaryProperties) {
            resultProject[property] = undefined;
        }

        return resultProject;
    }
}
