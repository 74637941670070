import { RxJsonSchema } from 'rxdb';

export const projectCraftIdsSchema: RxJsonSchema = {
    title: 'project craft ids',
    description: 'describes a projectPartner',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true
        },
        projectId: {
            type: 'string',
        },
        craftIds: {
            type: ['array', 'null'],
            items: {
                type: 'integer'
            }
        }
    },
    indexes: [
        'projectId'
    ],
    additionalProperties: false
};
