import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import {
    ExternalContact,
    ExternalContactSearchType,
    ResponseModelContact,
    ResponseModelListExternalContact,
} from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class ExternalContactService {
    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */

    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                    (httpParams = this.addToHttpParamsRecursive(
                        httpParams,
                        elem,
                        key
                    ))
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(
                        key,
                        (value as Date).toISOString().substr(0, 10)
                    );
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                    (httpParams = this.addToHttpParamsRecursive(
                        httpParams,
                        value[k],
                        key != null ? `${key}.${k}` : k
                    ))
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * @param searchTerm
     * @param searchType
     * @param craft
     */
    public getExternalContacts(
        searchTerm: string,
        searchType: ExternalContactSearchType,
        craft?: string,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<ResponseModelListExternalContact>;
    public getExternalContacts(
        searchTerm: string,
        searchType: ExternalContactSearchType,
        craft?: string,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListExternalContact>>;
    public getExternalContacts(
        searchTerm: string,
        searchType: ExternalContactSearchType,
        craft?: string,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListExternalContact>>;
    public getExternalContacts(
        searchTerm: string,
        searchType: ExternalContactSearchType,
        craft?: string,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        let qparams = { value: searchTerm, keyword: searchType };
        if (craft) {
            qparams['craft'] = craft;
        }
        queryParameters = this.addToHttpParams(queryParameters, qparams);

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListExternalContact>(
            `${this.configuration.basePath}/externalcontact/search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                // observe: observe,
                // reportProgress: reportProgress,
            }
        );
    }

    /**
     * Creates a new contact (person or company)
     * @param model
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContactFromExternalContact(
        model?: ExternalContact,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<ResponseModelContact>;
    public createContactFromExternalContact(
        model?: ExternalContact,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelContact>>;
    public createContactFromExternalContact(
        model?: ExternalContact,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelContact>>;
    public createContactFromExternalContact(
        model?: ExternalContact,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
            | 'text/plain'
            | 'application/json'
            | 'text/json'
            | 'text/csv'
            | 'application/xml'
            | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelContact>(
            `${this.configuration.basePath}/externalContact/${model.externalCompanyId ? 'create-contact' : 'create-company'}`,
            model,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }
}
