import { CommonModule, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { Array2StringPipe } from './array2string.pipe';
import { BoilerplateTextPipe } from './boilerplate-text.pipe';
import { FilesizePipe } from './filesize.pipe';
import { GetLocationTreeByPositionPipe } from './get-location-tree-by-position.pipe';
import { IsSelectedItemPipe } from './is-selected-item.pipe';
import { LocationTreeTextPipe } from './location-tree-text.pipe';
import { NegateValuePipe } from './negate-value.pipe';
import { RemoveDuplicatedPipe } from './remove-duplicated.pipe';
import { SafePipe } from './safe.pipe';
import { SecurePipe } from './secure.pipe';
import { ShortenTextPipe } from './shorten-text.pipe';
import { String2ArrayPipe } from './string2array.pipe';
import { TimeDifferenceTextPipe } from './time-difference-text.pipe';
import { TransformIntoArrayPipe } from './transform-into-array.pipe';
import { TypeIconPipe } from './type-icon.pipe';
import { PluralTranslationPipe } from './plural-translation.pipe';
import { TransformToCascadeDictionaryPipe } from './transform-to-cascade-dictionary.pipe';

const pipes = [
    SecurePipe,
    SafePipe,
    FilesizePipe,
    TypeIconPipe,
    LocationTreeTextPipe,
    BoilerplateTextPipe,
    Array2StringPipe,
    String2ArrayPipe,
    TimeDifferenceTextPipe,
    GetLocationTreeByPositionPipe,
    TransformIntoArrayPipe,
    IsSelectedItemPipe,
    RemoveDuplicatedPipe,
    NegateValuePipe,
    ShortenTextPipe,
    PluralTranslationPipe,
    TransformToCascadeDictionaryPipe,
];

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    declarations: [...pipes],
    // translate and i18nPlural are required by the PluralTranslationPipe
    providers: [SecurePipe, SafePipe, TranslatePipe, I18nPluralPipe],
    exports: [...pipes],
})
export class SharedPipesModule {}
