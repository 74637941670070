import { ChecklistTemplate } from 'app/core/rest-api';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { ChecklistsActions, ChecklistsActionTypes } from './checklists.actions';

export const adapter: EntityAdapter<ChecklistTemplate> = createEntityAdapter<ChecklistTemplate>();

export interface ChecklistsState extends EntityState<ChecklistTemplate> {
    loading: boolean;
}

export const initialState: ChecklistsState = adapter.getInitialState({
    loading: false
});

export function reducer(state = initialState, action: ChecklistsActions): ChecklistsState {
    switch (action.type) {
        case ChecklistsActionTypes.LOAD_CHECKLISTS_REQUESTED:
            return { ...state, loading: true };

        case ChecklistsActionTypes.LOAD_CHECKLISTS_SUCCESS:
            return adapter.setAll(action.payload.checklists, {
                ...state,
                loading: false
            });

        case ChecklistsActionTypes.LOAD_CHECKLISTS_ERROR:
            return { loading: false, ...state };

        case ChecklistsActionTypes.UPDATE_CHECKLIST_SUCCESS:
            return adapter.setOne(action.payload.checklist, state);

        case ChecklistsActionTypes.CREATE_CHECKLIST_SUCCESS:
            return adapter.addOne(action.payload.checklist, state);

        case ChecklistsActionTypes.DELETE_CHECKLIST_SUCCESS:
            return adapter.removeOne(action.payload.checklist.id, state);

        default:
            return state;
    }
}

export const { selectAll } = adapter.getSelectors();
