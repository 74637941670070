import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    HostBinding
} from '@angular/core';

@Component({
    selector: 'acc-tab-label',
    templateUrl: './tab-label.component.html',
    styleUrls: ['./tab-label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabLabelComponent implements OnInit {
    @HostBinding('class.acc-tab-label') customClass = true;
    @Input()
    badgeHidden: boolean;

    @Input()
    badge: number;

    @Input()
    label: string;

    @Input()
    icon: string;

    constructor() {}

    ngOnInit(): void {}
}
