<ng-container [formGroup]="form">
    <div *ngIf="( isRTFTemplate(template )?template.customKeyValuePairs: template.customAttributes) as customAttributes" fxLayout="column">
        <ng-container
            *ngFor="
                            let customField of customAttributes | keyvalue:keepOriginalOrder;
                            let i = index
                        "
            [ngSwitch]="customField.value.item1 || customField.value.type"
        >
            <ng-container  *ngIf="isRTFTemplate(template ) || ( customField.value.shownInPrintDialog)" >
                <mat-form-field *ngSwitchCase="'Text'" >

                    <mat-label>{{ customField.value.title || customField.key }}</mat-label>
                    <textarea
                        rows="3"
                        matInput
                        [formControlName]="customField.key"
                        [required]="customField.value.isRequired && honorRequiredSetting"
                    ></textarea>
                </mat-form-field>

                <asc-auto-selector
                    *ngSwitchCase="'Contact'"
                    [formControlName]="customField.key"
                    [label]="customField.value.title"
                    [config]="autoSelectorConfigDict[customField.key]"
                    [items]="contacts$ | async"
                    [required]="customField.value.isRequired && honorRequiredSetting"
                    data-test="issue-print-dialog-contact"
                ></asc-auto-selector>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
