<h1 mat-dialog-title>Offline-Modus aktivieren?</h1>
<div mat-dialog-content>
    <div class="text-center">
        <mat-icon class="s-60 secondary-text">offline_bolt</mat-icon>
    </div>
    <p>Deine Verbindung scheint schlecht zu sein</p>
    <p>Möchstest du offline arbeiten?</p>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Nein danke</button>
    <button color="accent" mat-button [mat-dialog-close]="true" cdkFocusInitial>
        Offline-Modus
    </button>
</div>
