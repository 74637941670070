<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon color="primary">close</mat-icon>
</button>

<div *ngIf=" (currentStep === printStep.LetterSelection); else showForm">
    <h1 mat-dialog-title translate>ISSUES.PRINT_DIALOG_TITLE</h1>

        <acc-print-dialog-letter-selection
            [purpose]="dialogData.purpose"
            [(currentExpandedList)]="currentExpandedFormLetterList"
        ></acc-print-dialog-letter-selection>

        <div  fxLayoutAlign="end end">
            <button
                mat-flat-button
                type="button"
                class="button-next"
                (click)="continueToForm()"
                [disabled]="!selectedLetter"
                color="accent"
            >
                <span translate>APP.NEXT</span>
            </button>
        </div>

</div>

<ng-template #showForm >
<div class="print-dialog-frame" >
    <h1 mat-dialog-title>{{'ISSUES.PRINT_DIALOG_TITLE_FORM' | translate}}: {{ selectedLetter?.title }}</h1>
    <h2 fxHide.gt-sm>{{ selectedLetter?.file?.customTitle }}</h2>

    <form fxLayout="column" class="issue-print-form" [formGroup]="printForm">
        <div *ngIf="currentStep === printStep.EnterBaseAttributes">

         <acc-print-dialog-page-base-attributes
                [form]="printForm"
                [template]="selectedLetter"
                [contacts$]="contacts$"
            >  </acc-print-dialog-page-base-attributes>

        </div>

        <div *ngIf="currentStep === printStep.FinalPage">

            <acc-print-dialog-final-page
                [selectedLetter]="selectedLetter"
                [showSaveTemplateSuccess]="showSaveTemplateSuccess"
            ></acc-print-dialog-final-page>




        </div>

    </form>
</div>
<div   mat-dialog-actions  class="print-dialog-footer"   fxLayout="row" fxLayoutAlign="space-between end"   >

            <button type="button" mat-flat-button class="print-dialog-button" (click)="goBack()">
                <span translate>APP.BACK</span>
            </button>

            <button *ngIf="(currentStep === printStep.EnterBaseAttributes)"
                mat-flat-button
                type="button"
                class="print-dialog-button print-dialog-button-right"
                (click)="continueToFinalPage()"
                [disabled]="!printForm.valid"
                color="accent"
            >
                <span translate>APP.NEXT</span>
            </button>
            <button *ngIf="(currentStep === printStep.FinalPage)"
                mat-flat-button
                type="button"
                class="print-dialog-button print-dialog-button-right"
                (click)="export()"
                [disabled]="!printForm.valid"
                color="accent"
            >
                <span translate>APP.FORM_LETTER_DIALOG.CREATE_PDF</span>
            </button>

    </div>


</ng-template>