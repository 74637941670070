import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BASE_PATH_OVERRIDE_DEMO, endpointApiFor, endpointAssetFor } from '../../../../environments/env-common';
import { Configuration } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { AuthState } from 'app/store/auth/auth.reducer';
import { getCurrentUser } from 'app/store/auth/auth.selectors';

import { DemoPopupComponent } from './demo-popup/demo-popup.component';

/**
 * Represents objects which contain an email attribute and can thus be viewed
 * as a user-like object.
 */
export type UserLike = { email: string };

/**
 * This service is responsible for actions and popups related to the
 * self-service demo.
 *
 * By default, all functions of this service are no-ops. If a demo user is
 * configured in the environment settings and if the currently logged-in user
 * is this specific user, the functions have an effect.
 */
@Injectable({
    providedIn: 'root',
})
export class DemoService {
    /**
     * This observable indicates if demo mode is enabled. Currently, it waits
     * until the user has logged in and then checks if this is a demo user.
     *
     * Subsequent logins on the same instance (i.e. without refreshing the page)
     * will still show demo mode as active.
     */
    readonly isDemoMode$: Observable<boolean> = this.store.pipe(
        select(getCurrentUser),
        // wait until the user is logged in (sometime the user object
        // appears, but without an associated mail => this is not yet logged in)
        filter((user) => !!user && !!user.email),
        take(1),
        map((user) => {
            return this.isDemoUser(user);
        }),
        // keep last value indefinitely, it's only a boolean
        shareReplay({
            bufferSize: 1,
            refCount: false,
        })
    );

    /**
     * Indicates if the application is currently in the "Editing Demo" mode,
     * i.e. the current user is the demo user, but the BASE_PATH_OVERRIDE_DEMO
     * local storage item is set. This keeps editing functionality enabled
     * in the application despite being the demo user.
     *
     * Note: Explicit boolean type is needed, otherwise this attribute has a
     * type of 'false' if readonly is used...
     */
    readonly isEditingDemo: boolean = false;

    private isDemoMode = false;

    private initialBasePath?: string;

    constructor(
        private configuration: Configuration,
        private dialog: MatDialog,
        private store: Store<AuthState>
    ) {
        this.isEditingDemo = !!localStorage.getItem(BASE_PATH_OVERRIDE_DEMO);
        this.isDemoMode$.pipe(take(1)).subscribe({
            next: (isDemoMode) => {
                this.isDemoMode = isDemoMode;
            },
        });
    }

    private static isUserLike(o: any): o is UserLike {
        return o.email !== undefined;
    }

    /**
     * Can be used to check if read-only demo behaviour should be used (e.g.
     * editing should be blocked), for example by event handlers to prevent
     * changes in demo mode.
     */
    public shouldUseReadOnlyDemoBehaviour(): boolean {
        return this.isDemoMode && !this.isEditingDemo;
    }

    public isDemoUser(user?: UserLike): boolean {
        const demoUserEmail = environment.demoUser;

        // Without a configured demo user, the demo mode will be disabled.
        if (!demoUserEmail) {
            return false;
        }

        // Note: Additionally, it could make sense to check the current URL
        //       for the demo.flink2go.com host. This would also have to check
        //       for the demo.flink2go.de domain. The problem is the mobile
        //       application which is hosted at localhost and would need to be
        //       handled separately.

        return user?.email === demoUserEmail;
    }

    public isDemoUserFromToken(token?: string): boolean {
        try {
            if (!token) {
                return false;
            }

            const decodedToken = AcceptUtils.decodeJWT(token);

            if (!DemoService.isUserLike(decodedToken)) {
                // Prefer non-demo user in case something is weird with the token
                // to be on the safe side.
                return false;
            }

            return this.isDemoUser(decodedToken);
        } catch {
            // Token seems to be invalid => prefer normal mode.
            console.warn('failed to decode token for demo mode check, using normal mode');
            return false;
        }
    }

    /**
     * Runs the given function / callback if demo mode is active. Please note,
     * that the given function runs asynchronously and runIfDemoMode can return
     * before that function has been called.
     *
     * This function does not return a promise intentionally, because it is not
     * needed at the call sites. This method is called in normal mode as well
     * and decides based on isDemoMode$ if it should execute the fn callback.
     * So, in non-demo mode it's best to not wait until that happens.
     *
     * Returning a promise would only force the callers to handle the error
     * case in which we should prefer not to display this popup.
     *
     * **Please Note:** Errors in the isDemoMode$ observable and the callback
     * are trapped in this function and do not reach the caller. In the case
     * that these errors need to be handled, this function needs to be extended.
     */
    runIfInDemoMode(fn: () => void): void {
        this.isDemoMode$
            .pipe(
                take(1),
                // Using tap instead of next in subscription to also catch errors
                // in the callback with the same handler. When doing it in `next`,
                // the behaviour seems to depend on the Observable, because it
                // may also be executed synchronously (see
                // https://rxjs.dev/api/index/class/Observable#subscribe-).
                tap((demoMode) => {
                    if (demoMode) {
                        fn();
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    console.error(
                        'an error occurred when trying to determine' +
                            ' if demo mode is active or executing the callback:',
                        error
                    );
                },
            });
    }

    /**
     * Show the initial popup after first login with hints related to the demo
     * mode.
     */
    showDemoModeStartPopup(): void {
        this.runIfInDemoMode(() => {
            this.dialog.open<DemoPopupComponent, void, void>(
                DemoPopupComponent,
                {
                    disableClose: true,
                    autoFocus: false,
                }
            );
        });
    }

    switchToDemoBackend(): void {
        // Backup the initial basePath if not yet done.
        if (!this.initialBasePath) {
            this.initialBasePath = environment.basePath;
        }

        this.changeBasePath(environment.demoModeBasePath);
    }

    resetBackendConfiguration(): void {
        if (!this.initialBasePath) {
            // The backend configuration was not changed by switching to the
            // demo backend, so nothing to do here.
            return;
        }

        this.changeBasePath(this.initialBasePath);
    }

    /**
     * Override the currently configured base path used by services
     * and code using the basePath variable directly with the given
     * basePath.
     */
    private changeBasePath(basePath: string): void {
        environment.basePath = basePath;

        // Some configuration strings depend on the base path and need
        // to be regenerated when changing the base path.
        environment.endpoints.api = endpointApiFor(basePath);
        environment.endpoints.asset = endpointAssetFor(basePath);


        // Currently, the configuration instance is created by a factory
        // function passed to the ApiModule imported in AppModule where
        // it used in a provider. Thus, it's only created once and
        // modifying the instance injected into this service modifies
        // the global instance. It would be different, if ApiModule
        // would be lazily loaded.
        //
        // One does have to take care, though, that no Configuration is
        // configured in nested providers which will be used for
        // dependency resolution in this service.
        //
        // See https://angular.io/guide/hierarchical-dependency-injection#moduleinjector
        // for details.
        this.configuration.basePath = environment.endpoints.api;
    }
}
