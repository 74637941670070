import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { keepOriginalOrder } from 'app/core/utils/keyvalue-utils';

import { NewFormLetterTemplate } from '../../common-template';

/**
 * This component contains the view for additional form letter print settings,
 * like IncludeSignature and IncludeProtocol to show proper radio buttons
 * for them, usually on the second page in the print dialogs.
 */
@Component({
    selector: 'acc-additional-form-letter-settings',
    templateUrl: './additional-form-letter-settings.component.html',
    styleUrls: ['./additional-form-letter-settings.component.scss'],
})
export class AdditionalFormLetterSettingsComponent implements OnInit {
    keepOriginalOrder = keepOriginalOrder;

    /**
     * The template which the additional attributes should be shown for.
     */
    @Input()
    template: NewFormLetterTemplate;

    /**
     * The form group to use to store the values of the additional attributes
     * at the top level with their attribute key.
     */
    @Input()
    form: UntypedFormGroup;

    constructor() {}

    ngOnInit(): void {}
}
