import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoSelectorComponent } from '../auto-selector.component';
import { AutoSelectorDialogData } from './auto-selector-dialog.data';

export type AutoSelectorDialogResult = string|string[];

@Component({
  selector: 'asc-auto-selector-dialog',
  templateUrl: './auto-selector-dialog.component.html',
  styleUrls: ['./auto-selector-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoSelectorDialogComponent implements OnInit {
  filteredItems = [];
  value: AutoSelectorDialogResult;

  @ViewChild('autoSelector', {static: true}) 
  autoSelector: AutoSelectorComponent

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AutoSelectorDialogData,
    public dialogRef: MatDialogRef<AutoSelectorDialogComponent, AutoSelectorDialogResult>
  ) {}

  ngOnInit(): void {
    this.value = this.data.value;
  }

  setFilteredItems(items: any[]): void {
    this.filteredItems = items;
  }

  setValue(value: string | string[]): void {
    this.value = value;
  }

  // Used to extract the input value, primarly when switching to free text mode mid-edit
  getInput(): string {
    return this.autoSelector.filterControl.value;
  }

  closeWithValue(): void {
    this.dialogRef.close(this.value);
  }
}
