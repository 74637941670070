import { RxJsonSchema } from 'rxdb';

export const diarySchema: RxJsonSchema = {
    title: 'diary schema',
    description: 'describes a diary entry',
    version: 0,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true,
        },
        ownerId: {
            type: ['string', 'null'],
        },
        updaterId: {
            type: ['string', 'null'],
        },
        creatorId: {
            type: ['string', 'null'],
        },
        creationDate: {
            type: ['string', 'null'],
        },
        markedAsDelete: {
            type: ['boolean', 'null'],
        },
        deleteDateTime: {
            type: ['string', 'null'],
        },
        updateDateTime: {
            type: ['string', 'null'],
        },

        projectId: {
            type: 'string',
        },
        type: {
            type: ['string', 'null'],
        },
        logEntryDateTime: {
            type: ['string', 'null'],
        },
        participantsIds: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        participantIds: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        issueIds: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        issuesIDs: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        locked: {
            type: ['boolean', 'null'],
        },
        lockDate: {
            type: ['string', 'null'],
        },
        lockProtocol: {
            type: ['string', 'null'],
        },
        title: {
            type: ['string', 'null'],
        },
        craft: {
            type: ['string', 'null'],
        },
        crafts: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        startTime: {
            type: ['string', 'null'],
        },
        endTime: {
            type: ['string', 'null'],
        },
        comment: {
            type: ['string', 'null'],
        },
        comments: {
            type: ['string', 'null'],
        },
        files: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        photos: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        weather: {
            type: ['string', 'null'],
        },
        raindfall: {
            type: ['number', 'null'],
        },
        windType: {
            type: ['string', 'null'],
        },
        wind: {
            type: ['number', 'null'],
        },
        temperature: {
            type: ['number', 'null'],
        },
        companyId: {
            type: ['string', 'null'],
        },
        service: {
            type: ['string', 'null'],
        },
        description: {
            type: ['string', 'null'],
        },
        approvalDateTime: {
            type: ['string', 'null'],
        },
        numberOfAttendies: {
            type: ['number', 'null'],
        },
        percentage: {
            type: ['number', 'null'],
        },
    },
    indexes: [
        'projectId'
    ],
    additionalProperties: false,
    attachments: {
        encrypted: false,
    },
};
