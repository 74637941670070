import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SyncEffects } from './sync.effects';
import * as fromSync from './sync.reducer';

@NgModule({
    declarations: [],
    imports: [
        MatSnackBarModule,
        StoreModule.forFeature('sync', fromSync.reducer),
        EffectsModule.forFeature([SyncEffects])
    ],
    exports: [],
    providers: []
})
export class SyncStoreModule {}
