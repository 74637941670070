import { RxJsonSchema } from 'rxdb';

export const commandSchema: RxJsonSchema = {
    title: 'command schema',
    description: 'describes a command',
    version: 1,
    type: 'object',
    properties: {
        action: {
            type: 'string',
        },
        entityType: {
            type: 'string',
        },
        entityId: {
            type: 'string',
        },
        projectId: {
            type: 'string',
        },
        issueId: {
            type: 'string',
        },
        changes: {
            type: 'object',
        },
        timestampCreated: {
            type: 'number',
        },
    },
    indexes: ['entityId', 'projectId', 'issueId', 'timestampCreated'],
    additionalProperties: false,
};
