
import { NgModule } from '@angular/core';

import { LanguageSwitcherComponent } from './language-switcher.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule.forChild(),
        MatMenuModule,
        MatButtonModule
    ],
    declarations: [LanguageSwitcherComponent],
    exports: [LanguageSwitcherComponent],
    providers: [],
})
export class LanguageSwitcherModule { }
