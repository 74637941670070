import { Pipe, PipeTransform } from '@angular/core';
import { I18nPluralPipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

/**
 * A very simple pipe to translate pluralized messages. It uses
 * first the ngx-translate pipe on the key to obtain the related
 * translation object. Then, it uses Angular's I18nPluralPipe to
 * obtain the correct message for the value.
 *
 * It expects the translation key to the left of the pipe and the
 * value to pluralize on as a parameter.
 */
@Pipe({
    name: 'pluralTranslation',
    pure: false,
})
export class PluralTranslationPipe implements PipeTransform {
    constructor(
        private translatePipe: TranslatePipe,
        private i18nPluralPipe: I18nPluralPipe
    ) {}

    transform(translationKey: string, pluralizedValue: number): string {
        const messageMapping = this.translatePipe.transform(translationKey);
        return this.i18nPluralPipe.transform(pluralizedValue, messageMapping);
    }
}
