<table mat-table matSort [dataSource]="dataSource" class="w-100-p">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (change)="toggleSelectAll.emit()"
                [checked]="
                    this.selectedProjectsIds.length === this.totalProjectsCount
                "
                [indeterminate]="
                    this.selectedProjectsIds.length > 0 &&
                    this.selectedProjectsIds.length < this.totalProjectsCount
                "
            >
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="toggleSelect.emit(row.id)"
                [checked]="selectedProjectsIds.includes(row.id)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Project Name Column -->
    <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'PROJECT.LIST.TABLE.NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
            <a [routerLink]="['/projects', project.id, 'issues']">{{
                project.title
            }}</a>
        </td>
    </ng-container>

    <!-- Project Begin Column -->
    <ng-container matColumnDef="startDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'PROJECT.LIST.TABLE.BEGIN' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
            {{ project.startDate | date: undefined:language }}
        </td>
    </ng-container>

    <!-- Project End Column -->
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'PROJECT.LIST.TABLE.END' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
            {{ project.dueDate | date: undefined:language }}
        </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="creationDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'PROJECT.LIST.TABLE.CREATED_AT' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
            {{ project.creationDate | date: undefined:language }}
        </td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let project" class="text-right">
            <button
                mat-icon-button
                [disabled]="!(isOnline$ | async)"
                (click)="updated.next(project)"
                class="secondary-text"
                [matTooltip]="'PROJECT.EDIT_PROJECT' | translate"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                mat-icon-button
                (click)="archived.next(project)"
                class="secondary-text"
                [matTooltip]="'PROJECT.ARCHIVE_PROJECT' | translate"
                *ngIf="(isAdmin$ | async) && !project.markedAsDelete"
                data-test="project-table-row-archive-button"
            >
                <mat-icon>archive</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
