/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { BoilerPlate } from '../model/models';
import { CompanyData } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ResponseModelBoilerPlate } from '../model/models';
import { ResponseModelCompanyData } from '../model/models';
import { ResponseModelListBoilerPlate } from '../model/models';
import { ResponseModelListCultureInfo } from '../model/models';
import { ResponseModelListGuid } from '../model/models';
import { ResponseModelListLocation } from '../model/models';
import { ResponseModelListRTFTemplate } from '../model/models';
import { ResponseModelListTimeZoneShortInfo } from '../model/models';
import { ResponseModelTranslation } from '../model/models';
import { Translation } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value: any,
        key?: string
    ): HttpParams {
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            elem,
                            key
                        ))
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(
                        key,
                        (value as Date).toISOString().substr(0, 10)
                    );
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k
                        ))
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsBoilerplateGet(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListBoilerPlate>;
    public settingsBoilerplateGet(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListBoilerPlate>>;
    public settingsBoilerplateGet(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListBoilerPlate>>;
    public settingsBoilerplateGet(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListBoilerPlate>(
            `${this.configuration.basePath}/settings/boilerplate`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param boilerPlate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsBoilerplatePatch(
        boilerPlate?: BoilerPlate,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelBoilerPlate>;
    public settingsBoilerplatePatch(
        boilerPlate?: BoilerPlate,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelBoilerPlate>>;
    public settingsBoilerplatePatch(
        boilerPlate?: BoilerPlate,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelBoilerPlate>>;
    public settingsBoilerplatePatch(
        boilerPlate?: BoilerPlate,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelBoilerPlate>(
            `${this.configuration.basePath}/settings/boilerplate`,
            boilerPlate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param boilerPlate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsBoilerplatePost(
        boilerPlate?: BoilerPlate,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelBoilerPlate>;
    public settingsBoilerplatePost(
        boilerPlate?: BoilerPlate,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelBoilerPlate>>;
    public settingsBoilerplatePost(
        boilerPlate?: BoilerPlate,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelBoilerPlate>>;
    public settingsBoilerplatePost(
        boilerPlate?: BoilerPlate,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelBoilerPlate>(
            `${this.configuration.basePath}/settings/boilerplate`,
            boilerPlate,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsCompanydataGet(
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelCompanyData>;
    public settingsCompanydataGet(
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelCompanyData>>;
    public settingsCompanydataGet(
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelCompanyData>>;
    public settingsCompanydataGet(
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelCompanyData>(
            `${this.configuration.basePath}/settings/companydata`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param companyData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsCompanydataPatch(
        companyData?: CompanyData,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelCompanyData>;
    public settingsCompanydataPatch(
        companyData?: CompanyData,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelCompanyData>>;
    public settingsCompanydataPatch(
        companyData?: CompanyData,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelCompanyData>>;
    public settingsCompanydataPatch(
        companyData?: CompanyData,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelCompanyData>(
            `${this.configuration.basePath}/settings/companydata`,
            companyData,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param employeeguid
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEmployeeByEmployeeguidDelete(
        employeeguid: string,
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public settingsEmployeeByEmployeeguidDelete(
        employeeguid: string,
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public settingsEmployeeByEmployeeguidDelete(
        employeeguid: string,
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public settingsEmployeeByEmployeeguidDelete(
        employeeguid: string,
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (employeeguid === null || employeeguid === undefined) {
            throw new Error(
                'Required parameter employeeguid was null or undefined when calling settingsEmployeeByEmployeeguidDelete.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(
            `${
                this.configuration.basePath
            }/settings/employee/${encodeURIComponent(String(employeeguid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEmployeeGet(
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListGuid>;
    public settingsEmployeeGet(
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListGuid>>;
    public settingsEmployeeGet(
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListGuid>>;
    public settingsEmployeeGet(
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListGuid>(
            `${this.configuration.basePath}/settings/employee`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param employeeguid
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEmployeesByEmployeeguidPost(
        employeeguid: string,
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public settingsEmployeesByEmployeeguidPost(
        employeeguid: string,
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public settingsEmployeesByEmployeeguidPost(
        employeeguid: string,
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public settingsEmployeesByEmployeeguidPost(
        employeeguid: string,
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (employeeguid === null || employeeguid === undefined) {
            throw new Error(
                'Required parameter employeeguid was null or undefined when calling settingsEmployeesByEmployeeguidPost.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(
            `${
                this.configuration.basePath
            }/settings/employees/${encodeURIComponent(String(employeeguid))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsLanguagesGet(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListCultureInfo>;
    public settingsLanguagesGet(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListCultureInfo>>;
    public settingsLanguagesGet(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListCultureInfo>>;
    public settingsLanguagesGet(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListCultureInfo>(
            `${this.configuration.basePath}/settings/languages`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsSeriallettersGet(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListRTFTemplate>;
    public settingsSeriallettersGet(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListRTFTemplate>>;
    public settingsSeriallettersGet(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListRTFTemplate>>;
    public settingsSeriallettersGet(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListRTFTemplate>(
            `${this.configuration.basePath}/settings/serialletters`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param subcontractorguid
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsSubcontractorsBySubcontractorguidDelete(
        subcontractorguid: string,
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public settingsSubcontractorsBySubcontractorguidDelete(
        subcontractorguid: string,
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public settingsSubcontractorsBySubcontractorguidDelete(
        subcontractorguid: string,
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public settingsSubcontractorsBySubcontractorguidDelete(
        subcontractorguid: string,
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (subcontractorguid === null || subcontractorguid === undefined) {
            throw new Error(
                'Required parameter subcontractorguid was null or undefined when calling settingsSubcontractorsBySubcontractorguidDelete.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(
            `${
                this.configuration.basePath
            }/settings/subcontractors/${encodeURIComponent(
                String(subcontractorguid)
            )}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param subcontractorsguid
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsSubcontractorsBySubcontractorsguidPost(
        subcontractorsguid: string,
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public settingsSubcontractorsBySubcontractorsguidPost(
        subcontractorsguid: string,
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public settingsSubcontractorsBySubcontractorsguidPost(
        subcontractorsguid: string,
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public settingsSubcontractorsBySubcontractorsguidPost(
        subcontractorsguid: string,
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        if (subcontractorsguid === null || subcontractorsguid === undefined) {
            throw new Error(
                'Required parameter subcontractorsguid was null or undefined when calling settingsSubcontractorsBySubcontractorsguidPost.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(
            `${
                this.configuration.basePath
            }/settings/subcontractors/${encodeURIComponent(
                String(subcontractorsguid)
            )}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsSubcontractorsGet(
        companyId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListGuid>;
    public settingsSubcontractorsGet(
        companyId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListGuid>>;
    public settingsSubcontractorsGet(
        companyId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListGuid>>;
    public settingsSubcontractorsGet(
        companyId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (companyId !== undefined && companyId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>companyId,
                'companyId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListGuid>(
            `${this.configuration.basePath}/settings/subcontractors`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsTimezonesGet(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListTimeZoneShortInfo>;
    public settingsTimezonesGet(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListTimeZoneShortInfo>>;
    public settingsTimezonesGet(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListTimeZoneShortInfo>>;
    public settingsTimezonesGet(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListTimeZoneShortInfo>(
            `${this.configuration.basePath}/settings/timezones`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsTranslationDelete(
        language?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any>;
    public settingsTranslationDelete(
        language?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<any>>;
    public settingsTranslationDelete(
        language?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<any>>;
    public settingsTranslationDelete(
        language?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (language !== undefined && language !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>language,
                'language'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(
            `${this.configuration.basePath}/settings/translation`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param ownerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsTranslationGet(
        ownerId?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelListLocation>;
    public settingsTranslationGet(
        ownerId?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelListLocation>>;
    public settingsTranslationGet(
        ownerId?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelListLocation>>;
    public settingsTranslationGet(
        ownerId?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (ownerId !== undefined && ownerId !== null) {
            queryParameters = this.addToHttpParams(
                queryParameters,
                <any>ownerId,
                'ownerId'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModelListLocation>(
            `${this.configuration.basePath}/settings/translation`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param translation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsTranslationPatch(
        translation?: Translation,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelTranslation>;
    public settingsTranslationPatch(
        translation?: Translation,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelTranslation>>;
    public settingsTranslationPatch(
        translation?: Translation,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelTranslation>>;
    public settingsTranslationPatch(
        translation?: Translation,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResponseModelTranslation>(
            `${this.configuration.basePath}/settings/translation`,
            translation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param translation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsTranslationPost(
        translation?: Translation,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<ResponseModelTranslation>;
    public settingsTranslationPost(
        translation?: Translation,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpResponse<ResponseModelTranslation>>;
    public settingsTranslationPost(
        translation?: Translation,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<HttpEvent<ResponseModelTranslation>>;
    public settingsTranslationPost(
        translation?: Translation,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?:
                | 'text/plain'
                | 'application/json'
                | 'text/json'
                | 'text/csv'
                | 'application/xml'
                | 'text/xml';
        }
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (
            this.configuration.apiKeys &&
            this.configuration.apiKeys['Authorization']
        ) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        let httpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'text/csv',
                'application/xml',
                'text/xml',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
                httpHeaderAccepts
            );
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
            'application/xml',
            'text/xml',
            'application/_*+xml',
        ];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (
            httpHeaderAcceptSelected &&
            httpHeaderAcceptSelected.startsWith('text')
        ) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModelTranslation>(
            `${this.configuration.basePath}/settings/translation`,
            translation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }
}
