import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseConfigService } from '@fuse/services/config.service';
import { untilDestroy } from '@ngrx-utils/store';
import { select, Store } from '@ngrx/store';
import { User } from 'app/core/auth/models/user';
import { Contact, Project } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import { AssetTileService } from 'app/shared/components/asset-tile/asset-tile.service';
import { LogoutService } from 'app/shared/services/logout/logout.service';
import { UserSettingsDialogComponent } from 'app/shared/components/dialogs/user-settings-dialog/user-settings-dialog.component';
import {
    getCurrentUser,
    getCurrentUsersCompany,
} from 'app/store/auth/auth.selectors';
import { ProjectsState } from 'app/store/projects/projects.reducer';
import { getCurrentProject } from 'app/store/projects/projects.selectors';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    assetPath = environment.endpoints.asset;
    thumbnailSuffix = AcceptUtils.thumbnailSuffix();

    fuseConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<void>;

    currentProject$: Observable<Project>;
    language$: Observable<string>;
    currentProject: Project;
    projectThumb: string;
    currentCompany$: Observable<Contact>;
    currentUser$: Observable<User>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private assetTileService: AssetTileService,
        private store: Store<ProjectsState>,
        private logoutService: LogoutService,
        private dialog: MatDialog,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), untilDestroy(this))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement(
                        'navbar .nav-link.active',
                        -120
                    );
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroy(this)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(untilDestroy(this))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                untilDestroy(this)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        this.currentProject$ = this.store.pipe(select(getCurrentProject));

        this.currentCompany$ = this.store.pipe(select(getCurrentUsersCompany));

        this.currentProject$
            .pipe(untilDestroy(this))
            .subscribe(async (project) => {
                this.projectThumb = '';
                this.currentProject = project;
                if (project && project.id && project.photo) {
                    const urls = await this.assetTileService.generateGalleryItems(
                        project.id,
                        [project.photo],
                        'project',
                        true
                    );
                    this.projectThumb = urls[0].data.thumb;
                } else {
                    this.projectThumb = 'assets/images/custom/gebaeude.jpg';
                }
            });

        this.currentUser$ = this.store.pipe(select(getCurrentUser));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    logout(): void {
        this.logoutService.logoutIfUserConfirms();
    }

    openUserSettingsDialog(): void {
        this.dialog.open(UserSettingsDialogComponent, {
            height: '600px',
            width: '100vw'
        });
    }
}
