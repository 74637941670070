<div fxFlex>
    <div
        fxLayout="row"
        class="accent-50 p-12"
        fxLayoutAlign="space-between center"
    >
        <button mat-button (click)="apply()">
            <mat-icon>done</mat-icon>
            {{ 'APP.FILTER' | translate }}
        </button>
        <button (click)="resetFilters()" type="button" mat-button>
            {{ 'APP.RESET' | translate }}
        </button>
    </div>
    <div class="py-8 px-16">
        <div
            *ngFor="let item of filterList"
            [ngSwitch]="item.filterType"
        >
            <acc-filter-by-text
                *ngSwitchCase="'FilterByText'"
                [filterItem]="item"
            ></acc-filter-by-text>
            <acc-filter-by-number
                *ngSwitchCase="'FilterByNumber'"
                [filterItem]="item"
            ></acc-filter-by-number>
            <acc-filter-by-date
                *ngSwitchCase="'FilterByDate'"
                [filterItem]="item"
            ></acc-filter-by-date>
            <acc-filter-by-date-range
                *ngSwitchCase="'FilterByDateRange'"
                [filterItem]="item"
            ></acc-filter-by-date-range>
            <acc-filter-by-auto-selector
                *ngSwitchCase="'FilterByAutoSelector'"
                [filterItem]="item"
            ></acc-filter-by-auto-selector>
        </div>
    </div>
</div>
