import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

export interface DialogData {
    title: string;
    browse: string;
}

@Component({
    selector: 'acc-file-dialog',
    templateUrl: 'file-dialog.component.html',
    styleUrls: ['file-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FileDialogComponent {

    pondOptions = {
        labelIdle: this.data.browse,
        required: true,
        acceptedFileTypes: 'image/jpeg, image/png',
        imageResizeUpscale: false,
        allowImageResize: true,
        imageResizeTargetWidth: 1920,
        imageResizeTargetHeight: 1080,
        allowImageTransform: true
    };

    imgSelected = false;

    uploading = false;

    private formData = new FormData();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private http: HttpClient,
        public matDialogRef: MatDialogRef<FileDialogComponent>
    ) { }

    pondHandleAddFile(event: any): void {
        this.imgSelected = true;
        this.formData.append('files', event.file.source);
    }

    upload(): void {
        const headers = new HttpHeaders({
            'Cache-Control': 'private, no-store, max-age: 0'
        });                   
        const options = { headers: headers };
        this.uploading = true;
        this.http.post(`${environment.endpoints.api}/files/direct`, this.formData, options).subscribe(response => {
            const imgId = response[0].id;
            this.matDialogRef.close(imgId);
            this.uploading = false;
        });
    }
}
