import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { Store } from '@ngrx/store';
import { Logout } from 'app/store/auth/auth.actions';
import { AuthState } from 'app/store/auth/auth.reducer';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private store: Store<AuthState>) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return from(Preferences.get({ key: 'token' })).pipe(
            map(token => token.value),
            switchMap(token => {
                const clone = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                });
                return next.handle(clone).pipe(
                    catchError(err => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                if (this.router.url !== '/login') {
                                    this.store.dispatch(new Logout());
                                }
                            }
                            return throwError(err);
                        }
                    })
                );
            })
        );
    }
}
