import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    Contact,
    DailyLogEntryBase,
    InspectionEntry,
    ProjectRole,
} from 'app/core/rest-api';
import { IssuesUtils } from 'app/main/issues/issues-utils.service';
import moment from 'moment';
import { DiaryUtils } from '../../main/diary/diary.utils';
import { getCurrentUser } from '../auth/auth.selectors';
import {
    getAllContacts,
    getContactsEntities,
} from '../contacts/contacts.selectors';
import { getSearch } from '../core/core.selectors';
import {
    getAllIssues,
    getIssueEntities,
    getIssueFilters,
    getIssuesOrderAsc,
    getSortIssuesBy,
} from '../issues/issues.selectors';
import { getAllLocations } from '../locations/locations.selectors';
import {
    getCurrentDiaryEntryId,
    getCurrentProjectId,
    getDiaryTypeToCreate,
} from '../router/router.selectors';
import {
    getAllProjectRoles,
    getProjectPersons,
} from '../settings/settings.selectors';
import { DiaryStoreUtilsService } from './diary-store-utils.service';
import * as fromDiary from './diary.reducer';
import { DiaryState } from './diary.reducer';

export const selectDiaryState = createFeatureSelector<DiaryState>('diary');

export const getAllDiaryEntriesWithoutCommands = createSelector(
    selectDiaryState,
    fromDiary.selectAll
);

export const getDiaryEntryEntitiesWithoutCommands = createSelector(
    selectDiaryState,
    fromDiary.selectEntities
);

export const getDiaryCommands = createSelector(
    selectDiaryState,
    (state) => state.diaryCommands
);

export const getAllDiaryEntries = createSelector(
    getAllDiaryEntriesWithoutCommands,
    getDiaryCommands,
    getCurrentProjectId,
    (entities, diaryCommands, projectId) =>
        DiaryStoreUtilsService.mergeDiariesWithCommands({
            entities,
            diaryCommands,
            projectId,
        })
);

export const getDiaryEntryEntities = createSelector(
    getDiaryEntryEntitiesWithoutCommands,
    getDiaryCommands,
    getCurrentProjectId,
    (entityMap, diaryCommands, projectId) =>
        DiaryStoreUtilsService.mergeDiaryEntitiesWithCommands({
            entityMap,
            diaryCommands,
            projectId,
        })
);

export const getDiaryEntries = createSelector(
    getAllDiaryEntries,
    selectDiaryState,
    getSearch,
    (entries, state, query) => {
        let filteredByQuery: DailyLogEntryBase[];
        if (query) {
            filteredByQuery = entries.filter((entry) =>
                DiaryUtils.filterByText(entry, query)
            );
        }

        if (state.withArchived) {
            return filteredByQuery || entries;
        } else {
            if (filteredByQuery) {
                return filteredByQuery.filter((entry) => !entry.markedAsDelete);
            } else {
                return entries.filter((entry) => !entry.markedAsDelete);
            }
        }
    }
);

export const getCurrentDiaryEntries = createSelector(
    getDiaryEntries,
    getCurrentDiaryEntryId,
    getDiaryTypeToCreate,
    getCurrentProjectId,
    (entries, currentId, typeToCreate, projectId) => {
        if (!currentId) {
            return [];
        }
        // create temporary new entry (neither api item nor command)
        // ui form will init with newEntry values
        // after save => command will be created, after sync, command will deleted
        // as we have the created api item (= command is "done")
        if (currentId === 'new') {
            const newEntry = {
                type: typeToCreate,
                logEntryDateTime: new Date(),
                projectId,
            };
            return [newEntry];
        }
        const currentDiaryEntry = entries.find((e) => e.id === currentId);
        if (!currentDiaryEntry) {
            return [];
        }

        return entries.filter((entry) => {
            return (
                new Date(entry.logEntryDateTime).getFullYear() ===
                    new Date(
                        currentDiaryEntry.logEntryDateTime
                    ).getFullYear() &&
                new Date(entry.logEntryDateTime).getMonth() ===
                    new Date(currentDiaryEntry.logEntryDateTime).getMonth() &&
                new Date(entry.logEntryDateTime).getDate() ===
                    new Date(currentDiaryEntry.logEntryDateTime).getDate() &&
                entry.type === currentDiaryEntry.type
            );
        });
    }
);

export const getSelectedDiaryEntries = createSelector(
    selectDiaryState,
    (state) => state.selected
);

export const getSelectedCompanyId = createSelector(
    selectDiaryState,
    (state) => state.selectedCompanyId
);

export const getProjectPersonsByCompanyId = createSelector(
    getProjectPersons,
    getSelectedCompanyId,
    (contacts, companyId) =>
        [...contacts].filter((c) => c.companyId === companyId)
);

export const getTotalDiaryEntriesCount = createSelector(
    selectDiaryState,
    fromDiary.selectTotal
);

export const getDiaryEntriesLoading = createSelector(
    selectDiaryState,
    (state) => state.loading
);

export const getSelectedInspectionEntry = createSelector(
    getDiaryEntryEntities,
    getCurrentDiaryEntryId,
    getCurrentDiaryEntries,
    getCurrentProjectId,
    getDiaryTypeToCreate,
    getCurrentUser,
    (
        entries,
        currentId,
        diaryEntries,
        projectId,
        typeToCreate,
        currentUser
    ) => {
        if (currentId === 'new') {
            if (typeToCreate !== DailyLogEntryBase.TypeEnum.InspectionEntry) {
                return null;
            }
            let logEntryDateTime = new Date();
            const now = new Date();
            const startTime = `${now.getHours()}:${now.getMinutes()}`;
            if (diaryEntries && diaryEntries.length > 0) {
                const [first] = diaryEntries;
                const m = moment();
                logEntryDateTime = moment(first.logEntryDateTime)
                    .hours(m.hours())
                    .minutes(m.minutes())
                    .seconds(m.seconds())
                    .milliseconds(m.milliseconds())
                    .toDate();
            }
            const creatorId = currentUser?.contactId;
            const newInspection: InspectionEntry = {
                type: DailyLogEntryBase.TypeEnum.InspectionEntry,
                title: 'Titel',
                logEntryDateTime,
                projectId,
                startTime,
                craft: null,
                endTime: null,
                participantsIds: [],
                comment: '',
                creatorId,
            };
            return newInspection;
        }
        const inspectionEntry = entries[currentId] as InspectionEntry;
        return inspectionEntry &&
            inspectionEntry.type === DailyLogEntryBase.TypeEnum.InspectionEntry
            ? inspectionEntry
            : null;
    }
);

export const getDiaryWithArchived = createSelector(
    selectDiaryState,
    (state) => state.withArchived
);

export const getDiaryFilters = createSelector(
    selectDiaryState,
    (state) => state.filters
);

export const getTotalFilteredDiaryEntriesCount = createSelector(
    getAllDiaryEntries,
    getDiaryFilters,
    (entries, filters) =>
        DiaryUtils.filterDiaryEntries({
            diaryEntries: entries,
            filters: filters,
        }).length
);

// export const getDiaryEntriesByIds = (props: { ids: string[] }) => createSelector(
//     getAllDiaryEntries,
//     (entries: DailyLogEntryBase[]) =>
//         entries.filter((entry) => props.ids && props.ids.includes(entry.id))
// );

export const getInspectionCraft = createSelector(
    selectDiaryState,
    (state) => state.inspectionCraft
);

export const getLastDiaryEntryForDate = createSelector(
    getAllDiaryEntries,
    (
        entries: DailyLogEntryBase[],
        props: {
            date: Date;
            entryType?: DailyLogEntryBase.TypeEnum;
            notArchived?: boolean;
        }
    ) => DiaryUtils.getLastDiaryEntryForDate(entries, props)
);

export const getIssueIdsForNewInspection = createSelector(
    selectDiaryState,
    (state) => state.issueIdsForNewInspection
);

export const getInspectionIssues = createSelector(
    getDiaryEntryEntities,
    getCurrentDiaryEntryId,
    getIssueEntities,
    getIssueIdsForNewInspection,
    (diaryEntries, currentDiaryEntryId, issues, issueIdsForNewInspection) => {
        const diaryEntry = diaryEntries[currentDiaryEntryId] as InspectionEntry;
        let issuesIDs = diaryEntry?.issuesIDs || [];
        /**
         * if the inspection is not created/saved yet, this array holds
         * the issues that are "marked to be added" to the new entry
         * we need to filter them from the recommendations as well
         */
        if (currentDiaryEntryId === 'new') {
            issuesIDs = [...issuesIDs, ...issueIdsForNewInspection];
        }

        if (!issuesIDs || issuesIDs.length === 0) {
            return [];
        }

        return [...issuesIDs]
            .map((issueId) => issues[issueId])
            .filter((issue) => issue);
    }
);

export const getInspectionIssueRecommendations = createSelector(
    getAllIssues,
    getInspectionCraft,
    getInspectionIssues,
    getCurrentDiaryEntryId,
    (issues, craftId, inspectionIssues, currentDiaryEntryId) =>
        issues.filter((issue) => {
            if (!craftId) {
                return true;
            }
            const isCraft = issue.craft === parseInt(craftId, 10);
            // already part of the "tickets" tab
            const alreadyAdded = inspectionIssues.find(
                (inspectionIssue) => inspectionIssue.id === issue.id
            );

            return isCraft && !alreadyAdded;
        })
);

export const getInspectionAttendeeRecommendation = createSelector(
    getAllProjectRoles,
    getAllContacts,
    getInspectionCraft,
    (roles: ProjectRole[], contacts: Contact[], craftIdString) => {
        const craftId = parseInt(craftIdString, 10);
        const defaultCraftId = 9999;
        if (!craftId) {
            return contacts;
        }

        const externalResponsibleIds = roles
            .filter((role) => role.externalResponsible)
            .filter((role) => {
                // for default craft, return all
                if (craftId === defaultCraftId) {
                    return true;
                } else {
                    return role.craft === craftId;
                }
            })
            .map((role) => role.externalResponsible);
        const internalResponsibleIds = roles
            .filter((role) => role.internalResponsible)
            .filter((role) => {
                // for default craft, return all
                if (craftId === defaultCraftId) {
                    return true;
                } else {
                    return role.craft === craftId;
                }
            })
            .map((role) => role.internalResponsible);

        const contactIds = [
            ...new Set([...internalResponsibleIds, ...externalResponsibleIds]),
        ];

        return contactIds
            .map((id) => contacts.find((contact) => contact.id === id))
            .filter((contact) => contact);
    }
);

export const getFilteredInspectionIssueRecommendations = createSelector(
    getSortIssuesBy,
    getInspectionIssueRecommendations,
    getIssueFilters,
    getIssuesOrderAsc,
    getAllLocations,
    getContactsEntities,
    (sortBy, issues, filters, orderAsc, locations, contacts) =>
        IssuesUtils.filterIssues({
            sortBy,
            issues,
            filters,
            orderAsc,
            locations,
            contacts,
        })
);

export const getDiaryConflicts = createSelector(
    selectDiaryState,
    (state) => state.conflicts
);

export const getDiaryConflictsWithCommands = createSelector(
    getDiaryConflicts,
    getDiaryCommands,
    getCurrentProjectId,
    (conflicts, commands, projectId) => {
        const itemWantUpdates = DiaryStoreUtilsService.mergeDiariesWithCommands(
            {
                entities: conflicts.map((conflict) => conflict.itemWantUpdate),
                diaryCommands: commands,
                projectId,
            }
        );

        return conflicts.map((command, index) => {
            return {
                ...command,
                itemWantUpdate: itemWantUpdates[index],
            };
        });
    }
);

export const getDiaryInitialized = createSelector(
    selectDiaryState,
    (state) => state.initialized
);

export const getDiaryConflictsInitialized = createSelector(
    getDiaryInitialized,
    (initialized) => initialized.conflicts
);

export const getDiaryFullyInitialized = createSelector(
    getDiaryInitialized,
    (initialized) => Object.values(initialized).every((condition) => condition)
);

export const getDiaryLoadingConflicts = createSelector(
    selectDiaryState,
    (state) => state.loadingConflicts
);

export const getDiaryAttachmentMap = createSelector(
    selectDiaryState,
    (state) => state.attachmentMap
);
