import { MongoFile } from 'app/core/rest-api/model/mongoFile';

export abstract class FileMetadataStorage {
    /**
     * Initialize the metadata storage related to attachments. Please note, that
     * this function can be called multiple times. If the implementation
     * contains awaits, this can possibly happen concurrently. Thus it has
     * to be idempotent and ensure that the body does not run concurrently,
     * if that could be a problem.
     */
    abstract init(): Promise<void>;

    /**
     * Check if there is metadata stored about the given attachment.
     */
    abstract attachmentExists(attachmentId: string): Promise<boolean>;

    /**
     * Obtain the metadata for the given attachment. If it does not exist,
     * this method should return null.
     */
    abstract getMetadata(attachmentId: string): Promise<MongoFile | null>;

    /**
     * Get file metadata for multiple attachment ids at once. If one or more
     * attachmentIds are not available this method can return null entries in
     * the returned array.
     *
     * The default implementation uses this.getMetadata(attachmentId: string)
     * to fetch the metadata one by one and waits until all of these operations
     * complete. _This is not required by other implementations._
     *
     * A concrete implementation can for example fetch all rows at once
     * from the database to improve performance.
     */
    async getMetadataForIds(
        attachmentIds: string[]
    ): Promise<(MongoFile | null)[]> {
        if (!attachmentIds) {
            return undefined;
        }

        return await Promise.all(
            attachmentIds.map((attachmentId) => {
                return this.getMetadata(attachmentId);
            })
        );
    }

    /**
     * Store the attachments metadata. It should be able to overwrite
     * existing metadata for the same file, if it does exist.
     */
    abstract upsertMetadata(file: MongoFile): Promise<void>;

    /**
     * Remove the metadata stored for the given attachment. If the attachment
     * does not exist, the promise should be rejected.
     */
    abstract removeMetadataFor(attachmentId: string): Promise<void>;

    /**
     * Store multiple file metadata objects at once. This method should
     * be faster than upserting the metadata objects one by one.
     */
    abstract bulkStoreMeta(files: MongoFile[]): Promise<void>;

    /**
     * Get the ids of all stored attachments. This can be used to for
     * example dermine, which attachments are not yet available in
     * offline mode.
     */
    abstract getStoredAttachmentIds(): Promise<Set<string>>;

    /**
     * Move the metadata object from one id to the other. This method
     * could be called concurrently for the same metadata object
     * with the same from and to ids and has to be able to handle that.
     *
     * For example it should verify, that the source still exists and
     * handle conflicts in storage when the target already exists.
     *
     * @param from The source id.
     * @param to The target id.
     */
    abstract moveMetadata(from: string, to: string): Promise<void>;

    /**
     * Remove all metadata related to attachments. Please note, that
     * this function can be called multiple times. If the implementation
     * contains awaits, this can possibly happen concurrently. Thus it has
     * to check that there is something to remove and ensure that the body
     * does not run concurrently, if that could be a problem.
     */
    abstract clear(): Promise<void>;
}
