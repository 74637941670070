import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ApprovalEntry,
    AttendanceEntry,
    DailyLogEntryBase,
    InspectionEntry,
    NoteEntry,
    ProgressReportEntry,
    WeatherEntry,
} from 'app/core/rest-api';
import { AttachmentDownloaderService } from 'app/shared/services/attachment-storage/attachment-downloader.service';
import { FileStorageService } from 'app/shared/services/attachment-storage/file-storage.service';
import { DiaryEntry } from 'app/store/diary/models/diary-entry';
import { SyncState } from 'app/store/sync/sync.reducer';
import { Collections } from '../database';
import { DatabaseService } from '../database.service';
import { EntityDbService } from '../entity/entity.db.service';
import { DiaryDocType } from './diary.document';

@Injectable({
    providedIn: 'root',
})
export class DiaryDbService extends EntityDbService<DiaryEntry, DiaryDocType> {
    constructor(
        dbService: DatabaseService,
        http: HttpClient,
        store: Store<SyncState>,
        fileStorageService: FileStorageService,
        attachmentDownloaderService: AttachmentDownloaderService
    ) {
        super(
            dbService,
            http,
            store,
            fileStorageService,
            attachmentDownloaderService,
            Collections.Diaries
        );
    }

    getAttachmentIdsFromApiItem(
        diary: Partial<
            | InspectionEntry
            | NoteEntry
            | WeatherEntry
            | AttendanceEntry
            | ApprovalEntry
            | ProgressReportEntry
        >
    ): string[] {
        switch (diary.type) {
            case DailyLogEntryBase.TypeEnum.ApprovalEntry:
            case DailyLogEntryBase.TypeEnum.NoteEntry:
            case DailyLogEntryBase.TypeEnum.ProgressReportEntry: {
                const entry = diary as
                    | ApprovalEntry
                    | NoteEntry
                    | ProgressReportEntry;
                let ids = [];
                const { files, photos } = entry;
                if (files) {
                    ids = [...ids, ...files];
                }
                if (photos) {
                    ids = [...ids, ...photos];
                }
                return ids;
            }
            case DailyLogEntryBase.TypeEnum.InspectionEntry:
            case DailyLogEntryBase.TypeEnum.AttendanceEntry:
            case DailyLogEntryBase.TypeEnum.WeatherEntry: {
                const ids = [];
                return ids;
            }
            default: {
                console.warn('unknown daily log entry type', diary.type);
                const ids = [];
                return ids;
            }
        }
    }

    apiItemToSchema(
        diary:
            | InspectionEntry
            | NoteEntry
            | WeatherEntry
            | AttendanceEntry
            | ApprovalEntry
            | ProgressReportEntry
    ): DiaryDocType {
        const base: DailyLogEntryBase = {
            id: diary.id,
            ownerId: diary.ownerId,
            updaterId: diary.updaterId,
            creatorId: diary.creatorId,
            creationDate: diary.creationDate,
            markedAsDelete: diary.markedAsDelete,
            deleteDateTime: diary.deleteDateTime,
            updateDateTime: diary.updateDateTime,
            projectId: diary.projectId,
            type: diary.type,
            logEntryDateTime: diary.logEntryDateTime,
        };

        let dbItem: DiaryDocType;

        switch (diary.type) {
            case DailyLogEntryBase.TypeEnum.ApprovalEntry: {
                const approvalEntry = diary as ApprovalEntry;
                const {
                    approvalDateTime,
                    companyId,
                    title,
                    comments,
                    crafts,
                    files,
                    photos,
                    service,
                } = approvalEntry;
                dbItem = {
                    ...base,
                    approvalDateTime,
                    companyId,
                    title,
                    comments,
                    crafts,
                    files,
                    photos,
                    service,
                } as ApprovalEntry;
                break;
            }
            case DailyLogEntryBase.TypeEnum.AttendanceEntry: {
                const attendanceEntry = diary as AttendanceEntry;
                const {
                    companyId,
                    comment,
                    endTime,
                    numberOfAttendies,
                    participantsIds,
                    startTime,
                } = attendanceEntry;
                dbItem = {
                    ...base,
                    companyId,
                    comment,
                    endTime,
                    numberOfAttendies,
                    participantsIds,
                    startTime,
                } as AttendanceEntry;
                break;
            }
            case DailyLogEntryBase.TypeEnum.InspectionEntry: {
                const inspectionEntry = diary as InspectionEntry;
                const {
                    title,
                    comment,
                    craft,
                    crafts,
                    endTime,
                    issueIds,
                    issuesIDs,
                    lockDate,
                    lockProtocol,
                    locked,
                    participantIds,
                    participantsIds,
                    startTime,
                } = inspectionEntry;
                dbItem = {
                    ...base,
                    title,
                    comment,
                    craft,
                    crafts,
                    endTime,
                    issueIds,
                    issuesIDs,
                    lockDate,
                    lockProtocol,
                    locked,
                    participantIds,
                    participantsIds,
                    startTime,
                } as InspectionEntry;
                break;
            }
            case DailyLogEntryBase.TypeEnum.NoteEntry: {
                const noteEntry = diary as NoteEntry;
                const { title, comments, files, photos } = noteEntry;
                dbItem = {
                    ...base,
                    title,
                    comments,
                    files,
                    photos,
                } as NoteEntry;
                break;
            }
            case DailyLogEntryBase.TypeEnum.ProgressReportEntry: {
                const reportEntry = diary as ProgressReportEntry;
                const {
                    craft,
                    percentage,
                    description,
                    files,
                    photos,
                } = reportEntry;
                dbItem = {
                    ...base,
                    craft,
                    percentage,
                    description,
                    files,
                    photos,
                };

                break;
            }
            case DailyLogEntryBase.TypeEnum.WeatherEntry: {
                const weatherEntry = diary as WeatherEntry;
                const {
                    temperature,
                    weather,
                    windType,
                    comment,
                    rainfall,
                    wind,
                } = weatherEntry;
                dbItem = {
                    ...base,
                    temperature,
                    weather,
                    windType,
                    comment,
                    rainfall,
                    wind,
                } as WeatherEntry;
                break;
            }
            default: {
                break;
            }
        }

        return dbItem;
    }
}
