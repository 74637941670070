import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { take, tap, concatMap, switchMap } from 'rxjs/operators';

import { Logout } from 'app/store/auth/auth.actions';
import * as fromAuth from 'app/store/auth/auth.reducer';

import {
    LogoutDialogComponent,
    LogoutDialogComponentData,
} from './logout-dialog.component';
import { getIsDevice } from 'app/store/core/core.selectors';
import { Observable } from 'rxjs';
import { getAllCommandsLength } from 'app/store/sync/sync-after-offline.selectors';

/**
 * This service is used to start the logout process (initiated
 * by the user via explicit logout buttons). It allows showing
 * a logout dialog, where the user has to confirm if they really
 * want to contiue logging out.
 *
 * This way the user does not accidentally delete his data including
 * potentially unsynchronized changes.
 */
@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(
        private matDialog: MatDialog,
        private store: Store<fromAuth.AuthState>
    ) {}

    /**
     * Open the login dialog. If the user confirms it, the actual
     * logout will be performed.
     *
     * On devices an additional warning about potentially unsynchronized
     * changes is shown.
     */
    logoutIfUserConfirms(): void {
        this.openDialog()
            .subscribe((logoutConfirmed) => {
                if (logoutConfirmed) {
                    this.store.dispatch(new Logout());
                }
            });
    }

    /**
     * Open the dialog while optionally showing the device warning.
     */
    private openDialog(): Observable<boolean> {
        return this.store.pipe(
            select(getAllCommandsLength),
            take(1),
            switchMap((commandsLength) => {
                return this.matDialog
                    .open<LogoutDialogComponent, LogoutDialogComponentData, boolean>(
                        LogoutDialogComponent,
                        {
                            data: {
                                showUnsynchronizedWarning: commandsLength > 0,
                            },
                        }
                    )
                    .afterClosed()
                    .pipe(take(1));
            })
        );
    }
}
