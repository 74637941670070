import { DailyLogEntryBase } from 'app/core/rest-api';

export class DiaryFilters {
    logEntryDateFrom: Date;
    logEntryDateTill: Date;
    type: DailyLogEntryBase.TypeEnum;
    text: string;
    contactId: string;

    constructor() {
        this.logEntryDateFrom = undefined;
        this.logEntryDateTill = undefined;
        this.type = undefined;
        this.text = undefined;
        this.contactId = undefined;
    }
}
