import { SyncMessage } from '../../main/sync/models/sync-message';
import {
    BoilerplatesActions,
    BoilerplatesActionTypes,
} from '../boilerplates/boilerplates.actions';
import {
    ContactsActions,
    ContactsActionTypes,
} from '../contacts/contacts.actions';
import { IssuesActions, IssuesActionTypes } from '../issues/issues.actions';
import {
    LocationsActions,
    LocationsActionTypes,
} from '../locations/locations.actions';
import {
    ProjectTemplatesActions,
    ProjectTemplatesActionTypes,
} from '../project-templates/project-templates.actions';
import {
    ProjectsActions,
    ProjectsActionTypes,
} from '../projects/projects.actions';
import {
    SettingsActions,
    SettingsActionTypes,
} from '../settings/settings.actions';
import { SyncActions, SyncActionTypes } from './sync.actions';
import { DiaryActionTypes, DiaryActions } from '../diary/diary.actions';
import { DiaryStoreUtilsService } from '../diary/diary-store-utils.service';
import { EntityError, IssueEntityError } from './entity-response.model';

export interface SyncState {
    syncMessages: SyncMessage[];
    forceOffline: boolean;
    projectsToSync: string[];
    inSyncToDb: {
        attachments: number;
        boilerplates: number;
        contacts: number;
        issues: number;
        locations: number;
        projects: number;
        projectCraftIds: number;
        projectContacts: number;
        projectPartners: number;
        projectRoles: number;
        craftToPlanLocations: number;
        projectTemplates: number;
    };
    inSyncToServer: {
        issueCreates: number;
        issueUpdates: number;
        diaryCreates: number;
        diaryUpdates: number;
        issueCreatesAttachmentsUploading: boolean;
        issueUpdatesAttachmentsUploading: boolean;
        diaryCreatesAttachmentsUploading: boolean;
        diaryUpdatesAttachmentsUploading: boolean;
        planUpdatesAttachmentsUploading: boolean;
    };
    commandSyncAfterOnlineInProgress: boolean;
    entityErrors: EntityError[];
}

export const initialState: SyncState = {
    syncMessages: [],
    forceOffline: false,
    projectsToSync: [],
    inSyncToDb: {
        attachments: 0,
        boilerplates: 0,
        contacts: 0,
        issues: 0,
        locations: 0,
        projects: 0,
        projectCraftIds: 0,
        projectPartners: 0,
        projectRoles: 0,
        projectContacts: 0,
        craftToPlanLocations: 0,
        projectTemplates: 0,
    },
    inSyncToServer: {
        issueCreates: 0,
        issueUpdates: 0,
        diaryCreates: 0,
        diaryUpdates: 0,
        issueCreatesAttachmentsUploading: false,
        issueUpdatesAttachmentsUploading: false,
        diaryCreatesAttachmentsUploading: false,
        diaryUpdatesAttachmentsUploading: false,
        planUpdatesAttachmentsUploading: false,
    },
    commandSyncAfterOnlineInProgress: false,
    entityErrors: [],
};

export function reducer(
    state = initialState,
    action:
        | SyncActions
        | IssuesActions
        | DiaryActions
        | BoilerplatesActions
        | ProjectsActions
        | ProjectTemplatesActions
        | ContactsActions
        | SettingsActions
        | LocationsActions
): SyncState {
    switch (action.type) {
        case SyncActionTypes.SetForceOffline: {
            const { forceOffline } = action.payload;
            return {
                ...state,
                forceOffline,
            };
        }

        case SyncActionTypes.SetProjectsToSync: {
            const { projectsToSync } = action.payload;
            return {
                ...state,
                projectsToSync,
            };
        }

        case SyncActionTypes.SetIssueCreatesAttachmentsUploading:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueCreatesAttachmentsUploading: action.payload.uploading,
                },
            };

        case SyncActionTypes.SetIssueUpdatesAttachmentsUploading:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueUpdatesAttachmentsUploading: action.payload.uploading,
                },
            };

        case SyncActionTypes.SetDiaryCreatesAttachmentsUploading:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryCreatesAttachmentsUploading: action.payload.uploading,
                },
            };

        case SyncActionTypes.SetDiaryUpdatesAttachmentsUploading:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryUpdatesAttachmentsUploading: action.payload.uploading,
                },
            };

        case SyncActionTypes.SetPlanUpdatesAttachmentsUploading:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    planUpdatesAttachmentsUploading: action.payload.uploading,
                },
            };

        case SyncActionTypes.SetCommandSyncAfterOnlineInProgress:
            return {
                ...state,
                commandSyncAfterOnlineInProgress: action.payload.inProgress,
            };

        case SyncActionTypes.TriggerFullSync:
            // a full sync has been requested: partial sync
            // after it has finished is not needed, thus
            // reset 'sync after online' state
            return {
                ...state,
                commandSyncAfterOnlineInProgress: false,
            };

        case SyncActionTypes.DiscardCommandsWithErrors: {
            // remove metadata (errors, sync messages) about
            // the entities whose commands are discarded

            const failedIds = action.payload.discardParameters.map(
                (discardParameter) => discardParameter.entityId
            );
            return {
                ...state,
                syncMessages: state.syncMessages.filter(
                    (message) => !failedIds.includes(message.entityId)
                ),
                entityErrors: state.entityErrors.filter(
                    (error) => !failedIds.includes(error.entityId)
                ),
            };
        }

        case SyncActionTypes.AddEntityErrors:
            return {
                ...state,
                entityErrors: [...state.entityErrors, ...action.payload.errors],
            };

        case IssuesActionTypes.CREATE_ISSUES_REQUEST:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueCreates: state.inSyncToServer.issueCreates + 1,
                },
            };

        case IssuesActionTypes.UPDATE_ISSUES_REQUEST:
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueUpdates: state.inSyncToServer.issueUpdates + 1,
                },
            };

        case IssuesActionTypes.LOAD_ISSUE_COMMANDS_SUCCESS: {
            const { commands } = action.payload;
            const messages: SyncMessage[] = commands.map((command) => {
                const { entityId, projectId, entityType } = command;
                const target = 'server';
                return new SyncMessage({
                    entityId,
                    projectId,
                    entityType,
                    target,
                });
            });
            return {
                ...state,
                syncMessages: [...state.syncMessages, ...messages],
            };
        }

        case IssuesActionTypes.CREATE_ISSUE_COMMAND: {
            const { entityId, projectId, entityType } = action.payload.command;
            const target = 'server';
            return {
                ...state,
                syncMessages: [
                    ...state.syncMessages,
                    new SyncMessage({
                        entityId,
                        projectId,
                        entityType,
                        target,
                    }),
                ],
            };
        }

        case IssuesActionTypes.CREATE_ISSUES_SUCCESS: {
            const { issues } = action.payload;
            const createIds = issues.map((i) => i.entityId);

            return {
                ...state,
                syncMessages: state.syncMessages.map((message) => {
                    if (createIds.includes(message.entityId)) {
                        // replace local generated command id with serverId of generated issue
                        const serverId = issues.find(
                            (created) => created.entityId === message.entityId
                        )?.issue?.id;

                        return new SyncMessage({
                            ...message,
                            entityId: serverId,
                            done: true,
                        });
                    }
                    return message;
                }),
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueCreates: state.inSyncToServer.issueCreates - 1,
                },
            };
        }

        case IssuesActionTypes.UPDATE_ISSUES_SUCCESS: {
            const updateIds = action.payload.updates.map((update) => update.id);

            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    issueUpdates: state.inSyncToServer.issueUpdates - 1,
                },
                syncMessages: state.syncMessages.map((message) =>
                    updateIds.includes(message.entityId)
                        ? new SyncMessage({ ...message, done: true })
                        : message
                ),
            };
        }

        case DiaryActionTypes.CREATE_DIARY_COMMAND: {
            if (
                DiaryStoreUtilsService.onlyEmptyChanges(action.payload.command)
            ) {
                return state;
            }

            const { entityId, projectId, entityType } = action.payload.command;
            const target = 'server';
            return {
                ...state,
                syncMessages: [
                    ...state.syncMessages,
                    new SyncMessage({
                        entityId,
                        projectId,
                        entityType,
                        target,
                    }),
                ],
            };
        }

        case DiaryActionTypes.CREATE_DIARY_ENTRIES_REQUEST: {
            const { diaries } = action.payload;
            const createIds = diaries.map((i) => i.entityId);

            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryCreates: state.inSyncToServer.diaryCreates + 1,
                },
            };
        }

        case DiaryActionTypes.UPDATE_DIARY_ENTRIES_REQUEST: {
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryUpdates: state.inSyncToServer.diaryUpdates + 1,
                },
            };
        }

        case DiaryActionTypes.CREATE_DIARY_ENTRIES_SUCCESS: {
            const { diaries } = action.payload;
            const createIds = diaries.map((i) => i.entityId);
            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryCreates: state.inSyncToServer.diaryCreates - 1,
                },
                syncMessages: state.syncMessages.map((message) => {
                    if (createIds.includes(message.entityId)) {
                        // replace local generated command id with serverId of generated issue
                        const serverId = diaries.find(
                            (created) => created.entityId === message.entityId
                        )?.diary?.id;

                        return new SyncMessage({
                            ...message,
                            entityId: serverId,
                            done: true,
                        });
                    }
                    return message;
                }),
            };
        }

        case DiaryActionTypes.UPDATE_DIARY_ENTRIES_SUCCESS: {
            const updateIds = action.payload.updates.map((update) => update.id);

            return {
                ...state,
                inSyncToServer: {
                    ...state.inSyncToServer,
                    diaryUpdates: state.inSyncToServer.diaryUpdates - 1,
                },
                syncMessages: state.syncMessages.map((message) =>
                    updateIds.includes(message.entityId)
                        ? new SyncMessage({ ...message, done: true })
                        : message
                ),
            };
        }

        // remove sync messages that had a conflict
        // conflicts will be handled separately
        case IssuesActionTypes.CREATE_ISSUE_CONFLICTS: {
            const { conflicts } = action.payload;
            const conflictIds = conflicts.map((c) => c.itemOnServer.id);
            return {
                ...state,
                syncMessages: state.syncMessages.filter((message) => {
                    const isConflict = conflictIds.includes(message.entityId);
                    return !isConflict;
                }),
            };
        }

        case SyncActionTypes.DownloadAttachmentsRequest: {
            const { attachments } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    attachments: attachments + 1,
                },
            };
        }

        case SyncActionTypes.DownloadAttachmentsSuccess: {
            const { attachments } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    attachments: attachments - 1,
                },
            };
        }

        case IssuesActionTypes.UPSERT_ISSUES_START: {
            const { issues } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    issues: issues + 1,
                },
            };
        }

        case IssuesActionTypes.UPSERT_ISSUES_FINISH: {
            const { issues } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    issues: issues - 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CRAFT_TO_PLAN_START: {
            const { craftToPlanLocations } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    craftToPlanLocations: craftToPlanLocations + 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CRAFT_TO_PLAN_FINISH: {
            const { craftToPlanLocations } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    craftToPlanLocations: craftToPlanLocations - 1,
                },
            };
        }

        case ProjectTemplatesActionTypes.UPSERT_PROJECT_TEMPLATES_START: {
            const { projectTemplates } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectTemplates: projectTemplates + 1,
                },
            };
        }

        case ProjectTemplatesActionTypes.UPSERT_PROJECT_TEMPLATES_FINISH: {
            const { projectTemplates } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectTemplates: projectTemplates - 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CONTACTS_START: {
            const { projectContacts } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectContacts: projectContacts + 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CONTACTS_FINISH: {
            const { projectContacts } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectContacts: projectContacts - 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_PARTNERS_START: {
            const { projectPartners } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectPartners: projectPartners + 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_PARTNERS_FINISH: {
            const { projectPartners } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectPartners: projectPartners - 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_ROLES_START: {
            const { projectRoles } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectRoles: projectRoles + 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_ROLES_FINISH: {
            const { projectRoles } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectRoles: projectRoles - 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CRAFTS_START: {
            const { projectCraftIds } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectCraftIds: projectCraftIds + 1,
                },
            };
        }

        case SettingsActionTypes.UPSERT_PROJECT_CRAFTS_FINISH: {
            const { projectCraftIds } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projectCraftIds: projectCraftIds - 1,
                },
            };
        }

        case BoilerplatesActionTypes.UPSERT_BOILERPLATES_START: {
            const { boilerplates } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    boilerplates: boilerplates + 1,
                },
            };
        }

        case BoilerplatesActionTypes.UPSERT_BOILERPLATES_FINISH: {
            const { boilerplates } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    boilerplates: boilerplates - 1,
                },
            };
        }

        case ProjectsActionTypes.UPSERT_PROJECTS_START: {
            const { projects } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projects: projects + 1,
                },
            };
        }

        case ProjectsActionTypes.UPSERT_PROJECT_FINISH: {
            const { projects } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    projects: projects - 1,
                },
            };
        }

        case ContactsActionTypes.UPSERT_CONTACTS_START: {
            const { contacts } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    contacts: contacts + 1,
                },
            };
        }

        case ContactsActionTypes.UPSERT_CONTACTS_FINISH: {
            const { contacts } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    contacts: contacts - 1,
                },
            };
        }

        case LocationsActionTypes.UPSERT_LOCATIONS_START: {
            const { locations } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    locations: locations + 1,
                },
            };
        }

        case LocationsActionTypes.UPSERT_LOCATIONS_FINISH: {
            const { locations } = state.inSyncToDb;
            return {
                ...state,
                inSyncToDb: {
                    ...state.inSyncToDb,
                    locations: locations - 1,
                },
            };
        }

        default:
            return state;
    }
}
