import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import {
    trigger,
    state,
    style,
    transition,
    animate,
    query,
    animateChild,
    group,
} from '@angular/animations';
import { StyleDefinition } from '@angular/flex-layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// values in px, see note in scss file
const TOGGLE_BUTTON_DEFAULT_WIDTH = 64;
const TOGGLE_BUTTON_HANDSET_WIDTH = 76;
const TOGGLE_CIRCLE_DEFAULT_SPACING = 2;

const COLOR_ANIMATION_DURATION = '0.1s';
const CIRCLE_ANIMATION_DURATION = '0.1s';
const TEXT_FADE_ANIMATION_DURATION = '0.1s';

@Component({
    selector: 'acc-labeled-slide-toggle',
    templateUrl: './labeled-slide-toggle.component.html',
    styleUrls: ['./labeled-slide-toggle.component.scss'],
    animations: [
        trigger('toggleColor', [
            state(
                'on',
                style({
                    backgroundColor: '#80bb41',
                })
            ),
            state(
                'off',
                style({
                    backgroundColor: '#cdcdcd',
                })
            ),
            transition('on <=> off', [
                group([
                    query('@fadeText', animateChild()),
                    animate(COLOR_ANIMATION_DURATION),
                ]),
            ]),
        ]),
        trigger('toggleCircle', [
            state(
                'left',
                style({
                    left: '{{ circleAnimationOffPosition }}px',
                }),
                {
                    params: {
                        circleAnimationOffPosition: 0,
                    },
                }
            ),
            state(
                'right',
                style({
                    left: '{{ circleAnimationOnPosition }}px',
                }),
                {
                    params: {
                        circleAnimationOnPosition: 0,
                    },
                }
            ),
            transition('left <=> right', [animate(CIRCLE_ANIMATION_DURATION)]),
        ]),
        trigger('fadeText', [
            state(
                'in',
                style({
                    opacity: 1,
                })
            ),
            state(
                'out',
                style({
                    opacity: 0,
                })
            ),
            transition('in <=> out', [animate(TEXT_FADE_ANIMATION_DURATION)]),
        ]),
    ],
})
export class LabeledSlideToggleComponent implements OnInit, OnDestroy {
    booleanValue?: boolean;
    @Input()
    set value(value: boolean | string) {
        this.booleanValue = this.processBooleanValue(value);
    }
    get value(): boolean | string {
        return this.booleanValue;
    }

    @Input()
    disabled = false;

    @Output()
    toggle = new EventEmitter<boolean>();

    _circleSpacing = TOGGLE_CIRCLE_DEFAULT_SPACING;
    set circleSpacing(value: number) {
        this._circleSpacing = value;
        this.updateSizesAndStyles();
    }
    get circleSpacing(): number {
        return this._circleSpacing;
    }

    _buttonWidth = TOGGLE_BUTTON_DEFAULT_WIDTH;
    set buttonWidth(value: number) {
        this._buttonWidth = value;
        this.updateSizesAndStyles();
    }
    get buttonWidth(): number {
        return this._buttonWidth;
    }
    buttonHeight?: number;
    circleSize?: number;

    toggleButtonStyle?: StyleDefinition;
    stateLabelYesStyle?: StyleDefinition;
    stateLabelNoStyle?: StyleDefinition;
    circleStyle?: StyleDefinition;

    circleAnimationOnPosition?: number;
    circleAnimationOffPosition?: number;

    private unsubscribe$ = new Subject<void>();

    constructor(private breakpointObserver: BreakpointObserver) {}

    ngOnInit(): void {
        this.updateSizesAndStyles();

        this.breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small])
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((result) => {
                    if (result.matches) {
                        this.buttonWidth = TOGGLE_BUTTON_HANDSET_WIDTH;
                    } else {
                        this.buttonWidth = TOGGLE_BUTTON_DEFAULT_WIDTH;
                    }
                })
            )
            .subscribe();
    }

    private updateSizesAndStyles(): void {
        this.buttonHeight = this.buttonWidth / 2;

        // note: spacing is added above and below
        this.circleSize = this.buttonHeight - 2 * this.circleSpacing;

        this.toggleButtonStyle = {
            // override default minimal width from buttons
            'min-width': px(this.buttonWidth),
            // size the 'button'
            width: px(this.buttonWidth),
            height: px(this.buttonWidth / 2),
        };

        this.stateLabelYesStyle = {
            left: px(this.circleSpacing),
            top: px(this.circleSpacing),
            bottom: px(this.circleSpacing),
            right: px(
                this.buttonWidth - 2 * this.circleSpacing - this.circleSize
            ),
        };

        this.stateLabelNoStyle = {
            left: px(2 * this.circleSpacing + this.circleSize),
            top: px(this.circleSpacing),
            bottom: px(this.circleSpacing),
            right: px(this.circleSpacing),
            // otherwise it does not look centered
            'padding-right': px(1),
        };

        this.circleStyle = {
            top: px(this.circleSpacing),
            width: px(this.circleSize),
            height: px(this.circleSize),
        };

        this.circleAnimationOnPosition =
            this.buttonWidth - this.circleSize - this.circleSpacing;
        this.circleAnimationOffPosition = this.circleSpacing;
    }

    onToggle(): void {
        if (!this.disabled) {
            this.toggle.emit(!this.booleanValue);
        }
    }

    private processBooleanValue(value: boolean | string): boolean {
        // undefined/null are default values, at least for e.g.
        // checklists, explicitly handle them as false
        if (value === undefined || value === null) {
            return false;
        }

        // all other values are false other than exact 'true' or
        // the actual boolean value
        return value === 'true' || value === true;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

function px(value: number): string {
    return `${value}px`;
}
