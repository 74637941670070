import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

@Pipe({ name: 'secure' })
export class SecurePipe implements PipeTransform {
    constructor(private http: HttpClient) {}

    transform(url: string): Observable<SafeUrl> {
        return from(Preferences.get({ key: 'token' })).pipe(
            map(token => token.value),
            switchMap(token =>
                this.http
                    .get(url, {
                        responseType: 'blob',
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    .pipe(map(val => URL.createObjectURL(val)))
            )
        );
    }
}
