import {
    NgModule,
    ModuleWithProviders,
    SkipSelf,
    Optional,
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountService } from './api/account.service';
import { AppService } from './api/app.service';
import { ChecklistTemplateService } from './api/checklistTemplate.service';
import { ContactsService } from './api/contacts.service';
import { CustomAttributeService } from './api/customAttribute.service';
import { CustomerService } from './api/customer.service';
import { CustomerCraftsService } from './api/customerCrafts.service';
import { DailyLogsService } from './api/dailyLogs.service';
import { DocumentArchiveService } from './api/documentArchive.service';
import { FilesService } from './api/files.service';
import { FormLetterService } from './api/formLetter.service';
import { ImportService } from './api/import.service';
import { IssuesService } from './api/issues.service';
import { ManageService } from './api/manage.service';
import { MarkedPlansService } from './api/markedPlans.service';
import { NotificationsService } from './api/notifications.service';
import { PredefinedFormLetterTemplateService } from './api/predefinedFormLetterTemplate.service';
import { ProjectSettingsService } from './api/projectSettings.service';
import { ProjectsService } from './api/projects.service';
import { SerialLetterService } from './api/serialLetter.service';
import { SettingsService } from './api/settings.service';
import { StandardSerialLetterService } from './api/standardSerialLetter.service';
import { TemplateService } from './api/template.service';
import { TestService } from './api/test.service';
import { VikiService } from './api/viki.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [],
})
export class ApiModule {
    public static forRoot(
        configurationFactory: () => Configuration
    ): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                { provide: Configuration, useFactory: configurationFactory },
            ],
        };
    }

    constructor(
        @Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient
    ) {
        if (parentModule) {
            throw new Error(
                'ApiModule is already loaded. Import in your base AppModule only.'
            );
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575'
            );
        }
    }
}
