import { RxJsonSchema } from 'rxdb';
import { customAttributesSchema } from '../custom-attributes/custom-attribute.model';
import { checklistSchema } from '../checklists/checklists.models';

export const issueSchema: RxJsonSchema = {
    title: 'issue schema',
    description: 'describes an issue',
    version: 3,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
            final: true,
        },
        title: {
            type: ['string', 'null'],
        },
        position: {
            type: ['string', 'null'],
        },
        creationDate: {
            type: ['string', 'null'],
        },
        markedAsDelete: {
            type: ['boolean', 'null'],
        },
        updateDateTime: {
            type: ['string', 'null'],
        },
        dueDate: {
            type: ['string', 'null'],
        },
        state: {
            type: ['string', 'null'],
        },
        photo: {
            type: ['string', 'null'],
        },
        markedPlan: {
            type: ['object', 'null'],
        },
        files: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        photos: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        creatorId: {
            type: ['string', 'null'],
        },
        craft: {
            type: ['integer', 'null'],
        },
        difficulty: {
            type: ['number', 'null'],
        },
        responsibleInternalId: {
            type: ['string', 'null'],
        },
        responsibleExternalId: {
            type: ['string', 'null'],
        },
        projectId: {
            type: 'string',
        },
        secondDueDate: {
            type: ['string', 'null'],
        },
        boilerPlattes: {
            type: ['array', 'null'],
            items: {
                type: 'string',
            },
        },
        description: {
            type: ['string', 'null'],
        },
        customAttributes: customAttributesSchema,
        checklists: checklistSchema,
        isUsingFreetextAsBoilerplate: {
            type: ['boolean', 'null'],
        },
        freetext: {
            type: ['string', 'null'],
        },
    },
    indexes: ['projectId'],
    additionalProperties: false,
    attachments: {
        encrypted: false,
    },
};
