import { CraftsActions, CraftsActionTypes } from './crafts.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Craft } from './craft';

export const adapter: EntityAdapter<Craft> = createEntityAdapter<Craft>({
    sortComparer: sortByName
});

export function sortByName(a: Craft, b: Craft): number {
    if (a.id === 9999) {
        return -1;
    } else if (b.id === 9999) {
        return 1;
    }
    return a.title.localeCompare(b.title);
}

export interface CraftsState extends EntityState<Craft> {
    loaded: boolean;
    loading: boolean;
}

export const initialState: CraftsState = adapter.getInitialState({
    loaded: false,
    loading: false
});

export function reducer(
    state = initialState,
    action: CraftsActions
): CraftsState {
    switch (action.type) {
        case CraftsActionTypes.LOAD_CRAFTS_REQUESTED:
            return { ...state, loading: true };

        case CraftsActionTypes.LOAD_CRAFTS_SUCCESS:
            return adapter.addMany(action.payload.crafts, {
                ...state,
                loaded: true
            });

        case CraftsActionTypes.LOAD_CRAFTS_ERROR:
            return { ...state, loading: false };

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
