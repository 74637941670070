import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    Update,
} from '@ngrx/entity';
import { Location as FlinkLocation, Location } from 'app/core/rest-api';
import { LocationsActions, LocationsActionTypes } from './locations.actions';

export const adapter: EntityAdapter<FlinkLocation> = createEntityAdapter<
    FlinkLocation
>({
    sortComparer: (a: FlinkLocation, b: FlinkLocation) => {
        if (
            a.order !== undefined &&
            b.order !== undefined &&
            a.order !== b.order
        ) {
            return a.order - b.order;
        }
        if (a.title && b.title) {
            return a.title.localeCompare(b.title);
        }
    },
});

export interface LocationsState extends EntityState<FlinkLocation> {
    loading: boolean;
}

export const initialState: LocationsState = adapter.getInitialState({
    loading: false,
});

export function reducer(
    state = initialState,
    action: LocationsActions
): LocationsState {
    switch (action.type) {
        case LocationsActionTypes.LOAD_LOCATIONS_REQUESTED:
            return { ...state, loading: true };

        case LocationsActionTypes.LOAD_LOCATIONS_SUCCESS: {
            const {
                locations,
                currentProjectId,
                requestProjectId,
            } = action.payload;
            const forCurrentProject = requestProjectId === currentProjectId;
            if (forCurrentProject) {
                return adapter.setAll(locations, {
                    ...state,
                    loading: false,
                });
            } else {
                return { ...state, loading: false };
            }
        }

        case LocationsActionTypes.LOAD_LOCATIONS_ERROR:
            return { ...state, loading: false };

        case LocationsActionTypes.CREATE_LOCATION_SUCCESS:
            if (action.payload.tmpLocationId) {
                return adapter.updateOne(
                    {
                        id: action.payload.tmpLocationId,
                        changes: {
                            ...action.payload.location,
                            id: action.payload.location.id,
                        },
                    },
                    state
                );
            }
            return adapter.addOne(action.payload.location, { ...state });

        case LocationsActionTypes.UPDATE_LOCATION_SUCCESS:
            const updatedLocation = action.payload.location;
            const locationUpdate = {
                id: updatedLocation.id,
                changes: updatedLocation,
            } as Update<FlinkLocation>;
            return adapter.updateOne(locationUpdate, { ...state });

        case LocationsActionTypes.DELETE_LOCATION_SUCCESS:
            return adapter.removeOne(action.payload.location.id, state);

        case LocationsActionTypes.CLEAN_UP_TEMP_ENTRIES:
            const ids = state.ids as string[];
            const tmpIds = ids.filter((id) => id.startsWith('new-location-'));
            return adapter.removeMany(tmpIds, state);

        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = adapter.getSelectors();
