import { KeyFunctionMap, RxJsonSchema } from 'rxdb';
import { boilerplateSchema } from './boilerplates/boilerplate.schema';
import { commandSchema } from './commands/command.schema';
import { contactSchema } from './contacts/contact.schema';
import { craftToPlanLocationsSchema } from './craft-to-plan-locations/craft-to-plan-locations.schema';
import { Collections } from './database';
import { diarySchema } from './diary/diary.schema';
import { issueSchema } from './issues/issue.schema';
import { locationSchema } from './locations/location.schema';
import { projectCraftIdsSchema } from './project-craft-ids/project-craft-ids.schema';
import { projectPartnerSchema } from './project-partners/project-partners.schema';
import { projectRoleSchema } from './project-roles/project-roles.schema';
import { projectSchema } from './projects/project.schema';
import { fileMetadataSchema } from './file-metadata/file-metadata.schema';

import { commandMigrations } from './commands/command.migrations';
import { projectMigrations } from './projects/project.migrations';
import { issueMigrations } from './issues/issue.migrations';
import { locationMigrations } from './locations/location.migrations';
import { RxCollectionCreator } from 'rxdb/dist/types/types';

export interface CollectionDescription extends RxCollectionCreator {
    name: Collections;
}

export const DB_COLLECTIONS: CollectionDescription[] = [
    {
        name: Collections.Projects,
        schema: projectSchema,
        migrationStrategies: projectMigrations,
    },
    {
        name: Collections.Issues,
        schema: issueSchema,
        migrationStrategies: issueMigrations,
    },
    {
        name: Collections.Contacts,
        schema: contactSchema,
    },
    {
        name: Collections.Boilerplates,
        schema: boilerplateSchema,
    },
    {
        name: Collections.Locations,
        schema: locationSchema,
        migrationStrategies: locationMigrations,
    },
    {
        name: Collections.Commands,
        schema: commandSchema,
        migrationStrategies: commandMigrations,
    },
    {
        name: Collections.ProjectPartners,
        schema: projectPartnerSchema,
    },
    {
        name: Collections.ProjectCraftIds,
        schema: projectCraftIdsSchema,
    },
    {
        name: Collections.CraftToPlanLocations,
        schema: craftToPlanLocationsSchema,
    },
    {
        name: Collections.ProjectContacts,
        schema: contactSchema,
    },
    {
        name: Collections.ProjectRoles,
        schema: projectRoleSchema,
    },
    {
        name: Collections.ProjectTemplates,
        schema: projectSchema,
        migrationStrategies: projectMigrations,
    },
    {
        name: Collections.Diaries,
        schema: diarySchema,
    },
    {
        name: Collections.FileMetadata,
        schema: fileMetadataSchema,
    },
];
