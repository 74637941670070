import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BoilerPlate, ProjectSettingsService } from 'app/core/rest-api';
import { BoilerplateDbService } from 'app/db/boilerplates/boilerplate.db.service';
import { defer, from, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { SyncService } from '../../main/sync/sync.service';
import { getIsDevice } from '../core/core.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import {
    BoilerplatesActionTypes,
    CreateBoilerplateError,
    CreateBoilerplateRequested,
    CreateBoilerplateSuccess,
    DeleteBoilerplateError,
    DeleteBoilerplateRequested,
    DeleteBoilerplateSuccess,
    LoadBoilerplatesError,
    LoadBoilerplatesRequested,
    LoadBoilerplatesSuccess,
    UpdateBoilerplateError,
    UpdateBoilerplateRequested,
    UpdateBoilerplateSuccess,
    UpsertBoilerplatesFinish,
    UpsertBoilerplatesStart,
} from './boilerplates.actions';
import { BoilerplatesState } from './boilerplates.reducers';
import { ErrorUtils } from 'app/core/utils/error-util';

@Injectable()
export class BoilerplatesEffects {
    @Effect()
    $upsertBoilerplatesStart = this.actions$.pipe(
        ofType<UpsertBoilerplatesStart>(
            BoilerplatesActionTypes.UPSERT_BOILERPLATES_START
        ),
        map((action) => action.payload.boilerplates),
        mergeMap((boilerplates) =>
            from(this.boilerplateDb.bulkInsert(boilerplates)).pipe(
                map((results) => new UpsertBoilerplatesFinish({ results }))
            )
        )
    );

    @Effect()
    loadBoilerplates$ = this.actions$.pipe(
        ofType<LoadBoilerplatesRequested>(
            BoilerplatesActionTypes.LOAD_BOILERPLATES_REQUESTED
        ),
        map((action) => action.payload.projectId),
        withLatestFrom(this.store.pipe(select(getCurrentProjectId))),
        mergeMap(([projectId, currentProjectId]) => {
            const server$ = this.projectSettingsService.projectsByProjectIdSettingsBoilerplateGet(
                projectId
            );
            const db$ = defer(() =>
                from(this.boilerplateDb.getByProjectId(projectId))
            );

            return this.syncService
                .fetchFromServerOrDb({
                    server$,
                    db$,
                })
                .pipe(
                    map(
                        (response) =>
                            new LoadBoilerplatesSuccess({
                                boilerplates: response.data as BoilerPlate[],
                                fromDb: response.fromDb,
                                currentProjectId,
                                requestProjectId: projectId,
                            })
                    ),
                    catchError((error) =>
                        of(new LoadBoilerplatesError({ error }))
                    )
                );
        })
    );

    @Effect()
    loadBoilerplatesSuccess$ = this.actions$.pipe(
        ofType<LoadBoilerplatesSuccess>(
            BoilerplatesActionTypes.LOAD_BOILERPLATES_SUCCESS
        ),
        map((action) => action.payload),
        withLatestFrom(this.store.pipe(select(getIsDevice))),
        filter(([payload, isDevice]) => !payload.fromDb && isDevice),
        map(([payload, isDevice]) => payload.boilerplates),
        switchMap((boilerplates) =>
            of(new UpsertBoilerplatesStart({ boilerplates }))
        )
    );

    @Effect()
    errorLoadBoilerplates$ = this.actions$.pipe(
        ofType<LoadBoilerplatesError>(
            BoilerplatesActionTypes.LOAD_BOILERPLATES_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.LOAD'
            );
            return of();
        })
    );

    @Effect()
    createBoilerplate$ = this.actions$.pipe(
        ofType<CreateBoilerplateRequested>(
            BoilerplatesActionTypes.CREATE_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.projectSettingsService
                .projectsByProjectIdSettingsBoilerplatePost(payload.projectId, {
                    title: payload.title,
                    parentID: payload.parentId,
                })
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new CreateBoilerplateSuccess({
                                boilerplate,
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new CreateBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    errorCreateBoilerplate$ = this.actions$.pipe(
        ofType<CreateBoilerplateError>(
            BoilerplatesActionTypes.CREATE_BOILERPLATE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.CREATE'
            );
            return of();
        })
    );

    @Effect()
    updateBoilerplate$ = this.actions$.pipe(
        ofType<UpdateBoilerplateRequested>(
            BoilerplatesActionTypes.UPDATE_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.projectSettingsService
                .projectsByProjectIdSettingsBoilerplatePatch(
                    payload.projectId,
                    {
                        id: payload.update.id + '',
                        ...payload.update.changes,
                    }
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new UpdateBoilerplateSuccess({
                                update: {
                                    id: boilerplate.id,
                                    changes: boilerplate,
                                },
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new UpdateBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    errorUpdateBoilerplate$ = this.actions$.pipe(
        ofType<UpdateBoilerplateError>(
            BoilerplatesActionTypes.UPDATE_BOILERPLATE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.UPDATE'
            );
            return of();
        })
    );

    @Effect()
    deleteBoilerplate$ = this.actions$.pipe(
        ofType<DeleteBoilerplateRequested>(
            BoilerplatesActionTypes.DELETE_BOILERPLATE_REQUEST
        ),
        map((action) => action.payload),
        mergeMap((payload) => {
            return this.projectSettingsService
                .projectsByProjectIdSettingsBoilerplateByIdDelete(
                    payload.projectId,
                    payload.boilerplate.id,
                    true
                )
                .pipe(
                    map((response) => response.data),
                    map(
                        (boilerplate) =>
                            new DeleteBoilerplateSuccess({
                                projectId: payload.projectId,
                                boilerplate: payload.boilerplate,
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new DeleteBoilerplateError({
                                error,
                            })
                        );
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    errorDeleteBoilerplate$ = this.actions$.pipe(
        ofType<DeleteBoilerplateError>(
            BoilerplatesActionTypes.DELETE_BOILERPLATE_ERROR
        ),
        map((action) => action.payload.error),
        switchMap((error) => {
            this.errorUtils.showSingleMessageOrDefault(
                error,
                'BOILERPLATES.DELETE'
            );
            return of();
        })
    );

    constructor(
        private actions$: Actions,
        private projectSettingsService: ProjectSettingsService,
        private store: Store<BoilerplatesState>,
        private boilerplateDb: BoilerplateDbService,
        private syncService: SyncService,
        private errorUtils: ErrorUtils
    ) {}
}
