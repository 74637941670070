import { KeyValue } from '@angular/common';

/**
 * A small utility to keep the original order when using the keyvalue pipe.
 *
 * See https://stackoverflow.com/a/52794221 which proposed to use a compare function returning 0.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
 * regarding returning 0 from compare functions to keep original order.
 */
export function keepOriginalOrder(_a: KeyValue<unknown, unknown>, _b: KeyValue<unknown, unknown>): number {
    return 0;
}
