import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Contact, ProjectRole } from 'app/core/rest-api';
import { createResponsiblesIdEqualityResultSelector } from 'app/core/utils/selector-utils';
import { Timezone } from 'app/shared/models/timezone.model';
import {
    getAllContacts,
    getContactsEntities,
} from '../contacts/contacts.selectors';
import { sortByName } from '../crafts/crafts.reducer';
import { getAllCrafts } from '../crafts/crafts.selectors';
import * as fromSettings from './settings.reducer';
import { SettingsState } from './settings.reducer';
import { getCurrentUser } from '../auth/auth.selectors';
import { getGlobalBoilerplateCrafts } from '../global-bolierplates/global-boilerplates.selectors';

export const selectSettingsState = createFeatureSelector<SettingsState>(
    'settings'
);

export const getAllLanguages = createSelector(
    selectSettingsState,
    (state) => state.languages
);

export const getTimezones = createSelector(selectSettingsState, (state) => {
    return state.timezones.map(
        (timezone) =>
            new Timezone(
                timezone.id,
                timezone.displayName,
                timezone.standardName
            )
    );
});

export const getCraftToPlanLocationsState = createSelector(
    selectSettingsState,
    (state) => state.craftToPlanLocations
);

export const getCraftToPlanLocationsEntities = createSelector(
    getCraftToPlanLocationsState,
    fromSettings.craftToPlanEntity.selectEntities
);

export const getPlanIdByLocationIdCraftId = createSelector(
    getCraftToPlanLocationsState,
    getCraftToPlanLocationsEntities,
    (state, entities, props: { locationId: any, craftId: any }) => {
        const location = entities[props.locationId];
        if (location) {
            const planIds: string[] = location.craftToPlan[props.craftId];

            if (planIds) {
                // Please note, that this assumes, that the last element in this
                // array is the latest version of the plan and that the
                // behaviour of src/app/main/buildings/building-detail/building-detail.component.ts
                // regarding adding the most recent plan at the end of the array
                // remains unchanged.
                //
                // To solve this properly, this line and the related code in
                // building-detail should be extracted into their own service
                // or similar -- but there is currently no time to do that.
                return planIds[planIds.length - 1];
            }
        }
        return '';
    }
);

export const getAllPlanIdsByLocationIdCraftId = createSelector(
    getCraftToPlanLocationsState,
    getCraftToPlanLocationsEntities,
    (state, entities, props) => {
        const location = entities[props.locationId];
        if (location) {
            const planIds: string[] = location.craftToPlan[props.craftId];
            return planIds ?? [];
        }

        return [];
    }
);

export const getProjectPartnersState = createSelector(
    selectSettingsState,
    (state) => state.projectPartners
);

export const getProjectRolesState = createSelector(
    selectSettingsState,
    (state) => state.projectRoles
);

export const getProjectContactsState = createSelector(
    selectSettingsState,
    (state) => state.projectContacts
);

export const getAllProjectPartners = createSelector(
    getProjectPartnersState,
    fromSettings.selectAllProjectPartners
);

export const getAllProjectRoles = createSelector(
    getProjectRolesState,
    fromSettings.projectRolesEntity.selectAll
);

export const getProjectRoleFilters = createSelector(
    selectSettingsState,
    (state) => state.projectRolesFilters
);

export const getFilteredProjectRoles = createSelector(
    getProjectRoleFilters,
    getAllProjectRoles,
    getContactsEntities,
    (filter, roles, contacts) =>
        filter ? filter.applyFilters(roles, contacts) : roles
);

export const getAllActiveProjectPartners = createSelector(
    getAllProjectPartners,
    (partners) => partners.filter((partner) => !partner.markedAsDelete)
);

export const getProjectCraftIds = createSelector(
    selectSettingsState,
    (state) => state.projectCraftIds
);

export const getProjectCrafts = createSelector(
    getAllCrafts,
    getProjectCraftIds,
    (crafts, projectCraftIds) =>
        projectCraftIds
            .map((projectCraftId) =>
                crafts.find((craft) => craft.id === projectCraftId)
            )
            .sort(sortByName)
);

export const getProjectAndGlobalCrafts = createSelector(
    getProjectCrafts,
    getGlobalBoilerplateCrafts,
    (projectCrafts, globalCrafts) => [...projectCrafts, globalCrafts]
);

export const getExternalResponsiblesByCraft = createResponsiblesIdEqualityResultSelector(
    getAllProjectRoles,
    getAllContacts,
    (
        projectRoles: ProjectRole[],
        contacts: Contact[],
        props: { id: number }
    ) => {
        const responsibles = projectRoles
            .filter((projectRole) => {
                const craftId = props.id;
                const defaultCraftId = 9999;
                const { externalResponsible, craft } = projectRole;
                // for default craft, return all
                if (craftId === defaultCraftId) {
                    return externalResponsible;
                } else {
                    return craft === craftId && externalResponsible;
                }
            })
            .map((projectRole) =>
                contacts.find(
                    (contact) => contact.id === projectRole.externalResponsible
                )
            )
            .filter(
                (contact, index, list) =>
                    contact && list.indexOf(contact) === index
            ); // remove duplicates

        return responsibles;
    }
);

export const getInternalResponsiblesByCraft = createResponsiblesIdEqualityResultSelector(
    getAllProjectRoles,
    getAllContacts,
    (roles: ProjectRole[], contacts: Contact[], props: { id: number }) => {
        const responsibles = roles
            .filter((role) => {
                if (props.id === 9999) {
                    return role.internalResponsible;
                }
                return role.craft === props.id && role.internalResponsible;
            })
            .map((role) =>
                contacts.find(
                    (contact) => contact.id === role.internalResponsible
                )
            )
            .filter(
                (contact, index, list) =>
                    contact && list.indexOf(contact) === index
            ); // remove duplicates

        return responsibles;
    }
);

export const getProjectContacts = createSelector(
    getProjectContactsState,
    fromSettings.projectContactsEntity.selectAll
);

export const getProjectCompanies = createSelector(
    getProjectContacts,
    (contacts) => contacts.filter((c) => c.companyId === null)
);

export const getProjectPersons = createSelector(
    getProjectContacts,
    (contacts) => contacts.filter((c) => c.companyId !== null)
);

/**
 * Get the project users (without companies) including the current user, even if
 * the current user is a company.
 *
 * Quick fix for demo mode (F2G-2332): Adding the current user, so that
 * e.g. attendees in approval show up correctly and not a GUID. Please
 * note, that other users would still see the GUID, if the current user
 * is not both configured in the project and not a company. It could
 * make sense to use a fallback of all users & companies for the auto
 * selectors where this issue occurs.
 */
export const getProjectPersonsWithCurrentUser = createSelector(
    getProjectPersons,
    getCurrentUser,
    (projectPersons, currentUser) =>
        projectPersons && currentUser?.contact
            ? [...projectPersons, currentUser.contact]
            : projectPersons
);
