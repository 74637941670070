import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from 'environments/environment';
import { getContactsEntities } from '../contacts/contacts.selectors';
import { getCurrentProjectId } from '../router/router.selectors';
import * as fromAuthState from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuthState.AuthState>(
    'auth'
);

export const getIsLoggedIn = createSelector(
    selectAuthState,
    state => state.isLoggedIn
);

export const getCurrentUser = createSelector(
    selectAuthState,
    getContactsEntities,
    (authState, contactsEntities) => {
        const currentUser = authState?.currentUser;
        const contact = contactsEntities[currentUser?.contactId];
        return {
            ...currentUser,
            contact
        };
    }
);

export const getCurrentUsersCompany = createSelector(
    getCurrentUser,
    getContactsEntities,
    (user, contactsEntities) => contactsEntities[user?.contact?.companyId]
);

export const getCurrentUserRolesDictionary = createSelector(
    getCurrentUser,
    user => user?.rolesByProjectId
);

export const getMaxRoleGlobal = createSelector(
    getCurrentUserRolesDictionary,
    dictionary => {
        const projectId = environment.defaultGuid;
        if (!dictionary || !dictionary[projectId]) {
            return 0;
        }
        const roles = dictionary[projectId];
        if (roles.length === 0) {
            return 0;
        }
        return Math.max(...roles);
    }
);

export const getMaxRoleCurrentProject = createSelector(
    getCurrentUserRolesDictionary,
    getCurrentProjectId,
    getMaxRoleGlobal,
    (dictionary, projectId, maxRoleGlobal) => {
        if (!projectId) {
            return maxRoleGlobal;
        }
        if (!dictionary || !dictionary[projectId]) {
            return maxRoleGlobal;
        }

        const roles = dictionary[projectId];
        if (roles.length === 0) {
            return maxRoleGlobal;
        }
        return Math.max(...roles, maxRoleGlobal);
    }
);

export const getIsAdminGlobal = createSelector(
    getMaxRoleGlobal,
    maxRole => maxRole >= environment.roleNumbers.ADMIN
);

export const getIsSuperAdminCurrentProject = createSelector(
    getMaxRoleCurrentProject,
    maxRole => maxRole >= environment.roleNumbers.SUPER_ADMIN
);

export const getIsProjectManagerOrAboveForCurrentProject = createSelector(
    getMaxRoleCurrentProject,
    (maxRole) => maxRole >= environment.roleNumbers.PROJECT_MANAGER
);
