import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    forwardRef
} from '@angular/core';
import { NumericInputFieldConfiguration } from './models/numeric-input-field-configuration.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'acc-numeric-input-field',
    templateUrl: './numeric-input-field.component.html',
    styleUrls: ['./numeric-input-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericInputFieldComponent),
            multi: true
        }
    ]
})
export class NumericInputFieldComponent implements OnInit {
    @Input() configuration: NumericInputFieldConfiguration;
    @Input() placeholder: string;
    @Input() max: number;
    @Input() min: number;
    @Input() step: number;
    @Input() autocomplete: boolean;
    @Input() required = false;

    valueBefore: number;
    value: number;

    setValueBefore(): void {
        if (
            !this.value &&
            (this.value !== undefined ||
                this.value !== null ||
                this.value !== 0)
        ) {
            this.valueBefore = null;
            return;
        }

        this.valueBefore = this.value;
    }

    needsToValidate(key: string): boolean {
        const valueDidntChange = this.valueBefore === this.value;
        const isBackspaceKey = key === 'Backspace';
        const isDeleteKey = key === 'Delete';
        const isNegativeKey = key === '-';

        const hadValuePreviously = this.valueBefore || this.valueBefore === 0;

        const valueBeforeHasNegativeSign =
            hadValuePreviously && this.valueBefore.toString().includes('-');

        const isNegativeNumberWhenNotAllowed =
            isNegativeKey &&
            ((this.configuration && !this.configuration.allowNegativeValues) ||
                this.valueBefore === 0);

        if (
            (isNegativeKey && valueBeforeHasNegativeSign) ||
            isNegativeNumberWhenNotAllowed
        )
            return true;

        if (
            isDeleteKey ||
            isBackspaceKey ||
            (hadValuePreviously && valueDidntChange) ||
            (!hadValuePreviously && isNegativeKey)
        )
            return false;

        return true;
    }

    setValidatedValue(keyEvent: KeyboardEvent): void {
        if (!this.needsToValidate(keyEvent.key)) return;

        if (this.value === null) {
            this.value =
                this.valueBefore === null ? undefined : this.valueBefore;
        }

        if (!this.configuration) {
            this.setValueBefore();
            return;
        }

        if (this.value < 0 && !this.configuration.allowNegativeValues) {
            this.value *= -1;
        }

        if (this.value % 0 !== 0 && !this.configuration.allowDecimalValue) {
            this.value = Math.trunc(this.value);
        }

        this.setValueBefore();
    }
    constructor() {}

    ngOnInit(): void {
        if (!this.configuration) return;
        if (!this.configuration.allowNegativeValues && !this.min) {
            this.min = 0;
        }

        if (this.configuration?.allowDecimalValue && !this.step) {
            this.step = 0.01;
        }
    }

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};
    updateChanges(): void {
        this.onChange(this.value);
    }

    // OVERRIDES from ngModel
    writeValue(value: number): void {
        this.value = value;
        this.updateChanges();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
