import { ChecklistTemplate } from 'app/core/rest-api';
import { Action } from '@ngrx/store';

export enum ChecklistsActionTypes {
    LOAD_CHECKLISTS_REQUESTED = '[Checklists] Load Checklists Requested',
    LOAD_CHECKLISTS_SUCCESS = '[Checklists] Load Checklists Success',
    LOAD_CHECKLISTS_ERROR = '[Checklists] Load Checklists Error',
    UPDATE_CHECKLIST_REQUESTED = '[Checklist] Update Checklist Requested',
    UPDATE_CHECKLIST_SUCCESS = '[Checklist] Update Checklist Success',
    UPDATE_CHECKLIST_ERROR = '[Checklist] Update Checklist Error',
    CREATE_CHECKLIST_REQUESTED = '[Checklist] Create Checklist Requested',
    CREATE_CHECKLIST_SUCCESS = '[Checklist] Create Checklist Success',
    CREATE_CHECKLIST_ERROR = '[Checklist] Create Checklist Error',
    DELETE_CHECKLIST_REQUESTED = '[Checklist] Delete Checklist Requested',
    DELETE_CHECKLIST_SUCCESS = '[Checklist] Delete Checklist Success',
    DELETE_CHECKLIST_ERROR = '[Checklist] Delete Checklist Error',
}

export class LoadChecklistsRequested implements Action {
    readonly type = ChecklistsActionTypes.LOAD_CHECKLISTS_REQUESTED;
    constructor(public payload: { projectId: string }) {}
}

export class LoadChecklistsSuccess implements Action {
    readonly type = ChecklistsActionTypes.LOAD_CHECKLISTS_SUCCESS;
    constructor(public payload: { checklists: ChecklistTemplate[] }) {}
}

export class LoadChecklistsError implements Action {
    readonly type = ChecklistsActionTypes.LOAD_CHECKLISTS_ERROR;
    constructor(public payload: { error: any }) {}
}

export class UpdateChecklistRequested implements Action {
    readonly type = ChecklistsActionTypes.UPDATE_CHECKLIST_REQUESTED;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class UpdateChecklistSuccess implements Action {
    readonly type = ChecklistsActionTypes.UPDATE_CHECKLIST_SUCCESS;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class UpdateChecklistError implements Action {
    readonly type = ChecklistsActionTypes.UPDATE_CHECKLIST_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateChecklistRequested implements Action {
    readonly type = ChecklistsActionTypes.CREATE_CHECKLIST_REQUESTED;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class CreateChecklistSuccess implements Action {
    readonly type = ChecklistsActionTypes.CREATE_CHECKLIST_SUCCESS;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class CreateChecklistError implements Action {
    readonly type = ChecklistsActionTypes.CREATE_CHECKLIST_ERROR;
    constructor(public payload: { error: any }) {}
}

export class DeleteChecklistRequested implements Action {
    readonly type = ChecklistsActionTypes.DELETE_CHECKLIST_REQUESTED;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class DeleteChecklistSuccess implements Action {
    readonly type = ChecklistsActionTypes.DELETE_CHECKLIST_SUCCESS;
    constructor(public payload: { checklist: ChecklistTemplate }) {}
}

export class DeleteChecklistError implements Action {
    readonly type = ChecklistsActionTypes.DELETE_CHECKLIST_ERROR;
    constructor(public payload: { error: any }) {}
}

export type ChecklistsActions =
    | LoadChecklistsRequested
    | LoadChecklistsSuccess
    | LoadChecklistsError
    | UpdateChecklistRequested
    | UpdateChecklistSuccess
    | UpdateChecklistError
    | CreateChecklistRequested
    | CreateChecklistSuccess
    | CreateChecklistError
    | DeleteChecklistRequested
    | DeleteChecklistSuccess
    | DeleteChecklistError;
