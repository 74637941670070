<mat-icon
    fxHide
    fxShow.lt-xl
    [matBadge]="badge"
    [matBadgeHidden]="badgeHidden"
    [matTooltip]="label"
>
    {{ icon }}
</mat-icon>
<mat-icon fxHide.lt-xl>{{ icon }}</mat-icon>
<p
    fxHide.lt-xl
    class="ml-4 text-uppercase"
    translate
    [matBadge]="badge"
    [matBadgeHidden]="badgeHidden"
>
    {{ label }}
</p>
