import moment from 'moment';

import {
    CustomAttributeTemplate,
    Issue,
    CustomAttribute,
} from 'app/core/rest-api';

import {
    FilterCustomAttribute,
    fromAvailable,
    toAvailable,
    valueAvailable,
} from './issue-custom-attributes/filter-custom-attribute.model';

export function countCustomAttributeFilters(
    customAttributeFilters: FilterCustomAttribute[]
): number {
    let count = 0;

    for (const attribute of customAttributeFilters) {
        switch (attribute.template.type) {
            case CustomAttributeTemplate.TypeEnum.Number:
            case CustomAttributeTemplate.TypeEnum.Date:
                if (fromAvailable(attribute)) {
                    count++;
                }

                if (toAvailable(attribute)) {
                    count++;
                }

                break;
            default:
                if (valueAvailable(attribute)) {
                    count++;
                }

                break;
        }
    }

    return count;
}

function customAttributeFilterNotSet(
    filterValue: FilterCustomAttribute
): boolean {
    switch (filterValue.template.type) {
        case CustomAttributeTemplate.TypeEnum.Number:
        case CustomAttributeTemplate.TypeEnum.Date:
            const fromMissing = !fromAvailable(filterValue);
            const toMissing = !toAvailable(filterValue);
            return fromMissing && toMissing;
        default:
            return !valueAvailable(filterValue);
    }
}

function filterByDate(
    issueValue: CustomAttribute,
    filterValue: FilterCustomAttribute
): boolean {
    try {
        // note: the moment function and it's fallback new Date
        //       do not throw, so this function is missing a check for
        //       valid dates -> comparison still works, but returns false
        //       which is not consistent with the behaviour for numbers

        const issueDate = moment(issueValue.value);

        if (filterValue.from) {
            const dateFrom = moment(filterValue.from);

            if (!issueDate.isAfter(dateFrom)) {
                return false;
            }
        }

        if (filterValue.to) {
            const dateTo = moment(filterValue.to);

            if (!issueDate.isBefore(dateTo)) {
                return false;
            }
        }
    } catch (ex) {
        console.error(
            'error in filterByCustomAttributes (date):',
            issueValue.template.id,
            ex
        );

        // ignore the faulty attribute
    }

    return true;
}

function filterByNumber(
    issueValue: CustomAttribute,
    filterValue: FilterCustomAttribute
): boolean {
    try {
        const issueNumber = parseFloat(issueValue.value);

        if (filterValue.from) {
            const from = parseFloat(filterValue.from);
            if (issueNumber < from) {
                return false;
            }
        }

        if (filterValue.to) {
            const to = parseFloat(filterValue.to);
            if (issueNumber > to) {
                return false;
            }
        }
    } catch (ex) {
        console.error(
            'error in filterByCustomAttributes (number):',
            issueValue.template.id,
            ex
        );

        // like for dates: ignore the faulty attribute
    }

    return true;
}

export function filterByCustomAttributes(
    issue: Issue,
    customAttributes?: FilterCustomAttribute[]
): boolean {
    if (!customAttributes) {
        return true;
    }

    for (const filterValue of customAttributes) {
        if (customAttributeFilterNotSet(filterValue)) {
            continue;
        }

        const attributeId = filterValue.template.id;
        const issueValue = issue.customAttributes?.find(
            (attribute) => attribute.template.id === attributeId
        );

        const noIssueValue = !issueValue?.value;
        if (noIssueValue) {
            // old issue without the custom attribute the user wants to filter with
            // or new issue where this value is not set
            return false;
        }

        switch (filterValue.template.type) {
            case CustomAttributeTemplate.TypeEnum.Text:
            case CustomAttributeTemplate.TypeEnum.Url:
                const includesText = issueValue.value
                    .toLowerCase()
                    .includes(filterValue.value.toLowerCase());

                if (!includesText) {
                    return false;
                }

                // even though this statement is unreachable, TSLint
                // creates a warning about case fallthrough...
                break;

            case CustomAttributeTemplate.TypeEnum.Boolean:
            case CustomAttributeTemplate.TypeEnum.Contact:
                if (issueValue.value !== filterValue.value) {
                    return false;
                }
                break;

            case CustomAttributeTemplate.TypeEnum.Date:
                if (!filterByDate(issueValue, filterValue)) {
                    return false;
                }

                break;

            case CustomAttributeTemplate.TypeEnum.Number:
                if (!filterByNumber(issueValue, filterValue)) {
                    return false;
                }

                break;
            default:
                console.warn(
                    'issue filter: unknown custom attribute type:',
                    filterValue.template.type
                );

                // ignore faulty attribute
                continue;
        }
    }

    return true;
}
