import { Project, ChecklistTemplate } from 'app/core/rest-api';
import { AcceptUtils, missingOrEmpty } from 'app/core/utils/accept-utils';
import { CustomAttributeTemplate as CustomAttributeTemplateApi } from 'app/core/rest-api/model/customAttributeTemplate';

import { CustomAttributeTemplate as CustomAttributeTemplateDb } from '../custom-attributes/custom-attribute.model';
import { ProjectDocType } from '../projects/project.document';
import { KeysWithMatchingType } from './type-utils';

export function getAttachmentIdsFromProject(project: Project): string[] {
    let ids = [];
    const { photo } = project;
    if (photo && !AcceptUtils.isDefaultGuid(photo)) {
        ids = [...ids, photo, photo + AcceptUtils.thumbnailSuffix()];
    }
    return ids;
}

export function customAttributeTemplatesApiToSchema(
    apiItems: CustomAttributeTemplateApi[]
): CustomAttributeTemplateDb[] {
    return apiItems.map(
        (apiItem): CustomAttributeTemplateDb => {
            const {
                id,
                ownerId,
                updaterId,
                creatorId,
                creationDate,
                markedAsDelete,
                deleteDateTime,
                updateDateTime,
                projectId,
                title,
                type,
            } = apiItem;

            // note: even though the dates are returned as strings
            //       from the backend, Swagger generates Dates, thus
            //       just change the type to string here

            return {
                id,
                ownerId,
                updaterId,
                creatorId,
                creationDate: (creationDate as any) as string,
                markedAsDelete,
                deleteDateTime: (deleteDateTime as any) as string,
                updateDateTime: (updateDateTime as any) as string,
                projectId,
                title,
                type,
            };
        }
    );
}

export function checklistTemplatesApiToSchema(
    apiItems: ChecklistTemplate[]
): ChecklistTemplate[] {
    return apiItems.map(
        (apiItem): ChecklistTemplate => {
            return {
                ...apiItem,
                // we already store the related ids and the
                // global / project contacts, thus no need
                // to store additional data here
                owner: null,
                updater: null,
                creator: null,
            };
        }
    );
}

function idsAndTemplatesEqual(
    ids?: string[],
    templates?: { id?: string }[]
): boolean {
    const idsAvailable = !!ids;
    const templatesAvailable = !!templates;
    const differentAvailability =
        (idsAvailable && !templatesAvailable) ||
        (!idsAvailable && templatesAvailable);
    if (differentAvailability) {
        return false;
    }

    const bothMissing = !ids && !templates;
    if (bothMissing) {
        return true;
    }

    const differentLength = ids.length !== templates.length;
    if (differentLength) {
        return false;
    }

    for (let i = 0; i < ids.length; i++) {
        const differentIds = ids[i] !== templates[i].id;
        if (differentIds) {
            return false;
        }
    }

    return true;
}

function badDataInDb(doc: Project & PouchDB.Core.AllDocsMeta): boolean {
    const badCustomAttributes = !idsAndTemplatesEqual(
        doc.customAttributeTemplateIds,
        doc.customAttributeTemplates
    );

    const badChecklists = !idsAndTemplatesEqual(
        doc.checklistTemplatesIds,
        doc.checklistTemplates
    );

    return badCustomAttributes || badChecklists;
}
type NeededAttributeType = { id?: string; updateDateTime?: Date }[];
type KeyType =
    | KeysWithMatchingType<Project, NeededAttributeType>
    | KeysWithMatchingType<ProjectDocType, NeededAttributeType>;

/**
 * Check if the templates (based on the array of template objects) differs
 * in length and id / updateDateTime. Note: it is assumed that checks to test
 * for null / undefined were already done.
 */
function templateArraysDiffer(
    doc: Project & PouchDB.Core.AllDocsMeta,
    entity: ProjectDocType,
    key: KeyType
): boolean {
    const bothMissing = missingOrEmpty(doc[key]) && missingOrEmpty(entity[key]);
    if (bothMissing) {
        return false;
    }

    const differentTemplatesLength = doc[key].length !== entity[key].length;
    if (differentTemplatesLength) {
        return true;
    }

    for (let i = 0; i < doc[key].length; i++) {
        const docTemplate = doc[key][i];
        const entityTemplate = entity[key][i];

        const differentIds = docTemplate.id !== entityTemplate.id;
        if (differentIds) {
            return true;
        }

        const differentUpdateDateTime =
            ((docTemplate.updateDateTime as any) as string) !==
            entityTemplate.updateDateTime;
        if (differentUpdateDateTime) {
            return true;
        }
    }

    return false;
}

function addedOrRemoved(
    doc: Project & PouchDB.Core.AllDocsMeta,
    entity: ProjectDocType,
    key: KeyType
): boolean {
    const missingOnDoc = missingOrEmpty(doc[key]);
    const missingOnEntity = missingOrEmpty(entity[key]);

    const bothMissing = missingOnDoc && missingOnEntity;
    if (bothMissing) {
        return false;
    }

    return missingOnDoc || missingOnEntity;
}

export function hasUntrackedChanges(
    doc: Project & PouchDB.Core.AllDocsMeta,
    entity: ProjectDocType
): boolean {
    const noDoc = !doc;
    if (noDoc) {
        return true;
    }

    // custom attributes and checklists are not tracked with the project's updateDateTime
    // thus check them manually here

    // overwrite with the api entity, if there is something wrong
    // in the offline DB
    if (badDataInDb(doc)) {
        return true;
    }

    if (addedOrRemoved(doc, entity, 'customAttributeTemplates')) {
        return true;
    }

    if (addedOrRemoved(doc, entity, 'checklistTemplates')) {
        return true;
    }

    const templatesDiffer =
        templateArraysDiffer(doc, entity, 'customAttributeTemplates') ||
        templateArraysDiffer(doc, entity, 'checklistTemplates');
    return templatesDiffer;
}
