import { FileStorageService } from './file-storage.service';
import { CordovaFileStorageService } from './cordova-file-storage.service';
import { FileMetadataStorage } from './file-metadata-storage';
import { RxDBFileMetadataStorage } from './rxdb-file-metadata-storage';
import {
    DatabaseEntityAttachmentIdsService,
    EntityAttachmentIdsService,
} from './entity-attachment-ids.service';
import {
    DatabaseCommandAttachmentIdsService,
    CommandAttachmentIdsService,
} from './command-attachment-ids.service';
import { AttachmentDownloaderService } from './attachment-downloader.service';
import { CordovaAttachmentDownloaderService } from './cordova-attachment-downloader.service';
import { SafeName } from './cordova-plugin-file.utils';
import {
    ATTACHMENT_DIRECTORY,
    ATTACHMENT_THUMBNAIL_CONTENT_TYPE,
} from './attachment-configuration.tokens';

export const ATTACHMENT_PROVIDERS = [
    // TODO: use other class on web for device simulation!
    {
        provide: FileStorageService,
        useClass: CordovaFileStorageService,
    },
    {
        provide: FileMetadataStorage,
        useClass: RxDBFileMetadataStorage,
    },
    {
        provide: EntityAttachmentIdsService,
        useClass: DatabaseEntityAttachmentIdsService,
    },
    {
        provide: CommandAttachmentIdsService,
        useClass: DatabaseCommandAttachmentIdsService,
    },
    {
        provide: AttachmentDownloaderService,
        useClass: CordovaAttachmentDownloaderService,
    },
    {
        provide: ATTACHMENT_DIRECTORY,
        useValue: SafeName.create('attachments'),
    },
    {
        provide: ATTACHMENT_THUMBNAIL_CONTENT_TYPE,
        useValue: 'image/jpeg',
    },
];
