import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Issue, MarkedPlan, Revision } from 'app/core/rest-api';
import { BulkInsertResult } from 'app/db/entity/bulk-insert-result';
import { IssueDocType } from 'app/db/issues/issue.document';
import { Conflict } from 'app/main/models/conflict';
import { EntityType } from 'app/main/sync/models/entity-type';
import { IssueCommand } from '../../main/issues/models/issue-command';
import { IssueFilters } from '../../main/issues/models/issue.filters';
import { IssuePost } from '../../main/issues/models/issue.post';
import { IssueSorts } from '../../main/issues/models/issue.sorts.enum';
import { PlanCommand } from '../../main/issues/models/plan-command';
import { SyncCommand } from '../../main/sync/models/sync-command';
import { PlanToUpdate } from './models/plan-to-update';
import { EntityAttachmentMap } from './models/entity-attachment-map';
import { EntityError } from '../sync/entity-response.model';

export enum IssuesActionTypes {
    // commands
    CREATE_ISSUE_COMMAND = '[APP] Create Issue Command',
    CREATE_PLAN_COMMAND = '[APP] Create Plan Command',
    REMOVE_PLAN_COMMANDS = '[APP] Remove Plan Commands',

    // ISSUES
    UPSERT_ISSUES_START = '[APP] Upsert Issues Request',
    UPSERT_ISSUES_FINISH = '[DB] Upsert Issues Success',

    SYNC_ISSUE_COMMANDS = '[APP] Sync Issue Commands',
    LOAD_ISSUE_COMMANDS_REQUEST = '[APP] Load Issue Commands Request',
    LOAD_ISSUE_COMMANDS_SUCCESS = '[DB] Load Issue Commands Success',
    LOAD_ISSUE_COMMANDS_ERROR = '[DB] Load Issue Commands Error',

    // plan command update
    SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_REQUEST = '[CreateIssueCommand] Set Issue Id Of Plan Commands In DB Request',
    SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_SUCCESS = '[CreateIssueCommand] Set Issue Id Of Plan Commands In DB Success',
    SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_ERROR = '[CreateIssueCommand] Set Issue Id Of Plan Commands In DB Error',

    // issue attachments
    ADD_ISSUE_ATTACHMENT_MAPPINGS = '[SyncIssueCommands Effect] Add Issue Attachment Mappings',
    UPDATE_ISSUE_ATTACHMENT_IDS = '[SyncIssueCommands Effect] Update Issue Attachment Ids',
    CLEAR_ISSUE_ATTACHMENT_MAP = '[UpdateIssueAttachmentIds] Clear Issue Attachment Map',

    // api create
    CREATE_ISSUES_ERROR = '[API] Create Issues Error',
    CREATE_ISSUES_REQUEST = '[Issue Detail] Create Issues Request',
    CREATE_ISSUES_SUCCESS = '[API] Create Issues Success',
    // api get all
    LOAD_ISSUES_ERROR = '[API] Load Issues Error',
    LOAD_ISSUES_REQUEST = '[Issue Container] Load Issues Request',
    LOAD_ISSUES_SUCCESS = '[API] Load Issues Success',
    // sorting / filtering
    SET_ISSUE_FILTERS = '[Issue Filter Sidenav] Set Issue Filter',
    SET_ISSUE_LIST_SORT = '[Issue List Toolbar] Sort Changed',
    // selection (checkbox)
    SELECT_ISSUES = '[Issue List Toolbar] Toggle Select Multiple Issues',
    TOGGLE_SELECT_ISSUE = '[Issue List] Toggle Select Issue',
    // api update
    UPDATE_ISSUES_ERROR = '[API] Update Issues Error',
    UPDATE_ISSUES_REQUEST = '[APP | COMMAND] Update Issues Request',
    UPDATE_ISSUES_SUCCESS = '[API] Update Issues Success',
    // conflicts
    CREATE_ISSUE_CONFLICTS = '[API] Create Issue Conflicts',
    LOAD_ISSUE_CONFLICTS = '[CoreService Init (StartUp)] Load Issue Conflicts',
    SET_ISSUE_CONFLICTS = '[Issue Effects] Set Issue Conflicts',
    STORE_ISSUE_CONFLICTS = '[Issue Effects] Store Conflicts',
    // api get revisions
    LOAD_REVISIONS_REQUEST = '[ISSUE_DETAIL] Load Revision Request',
    LOAD_REVISIONS_SUCCESS = '[API] Load Revisions Success',
    LOAD_REVISIONS_ERROR = '[API] Load Revisions Error',
    // plan revisions
    LOAD_PLAN_REVISIONS_REQUEST = '[Plan Dialog] Load Plan Revisions Request',
    LOAD_PLAN_REVISIONS_SUCCESS = '[Plan Dialog] Load Plan Revisions Success',
    LOAD_PLAN_REVISIONS_ERROR = '[Plan Dialog] Load Plan Revisions Error',
    //
    SET_TEMPORARY_PLAN = '[Issue Commands] Set Temporary Plan',
    RESET_TEMPORARY_PLAN = '[APP] Remove Temporary Plan',
}

export class UpsertIssuesStart implements Action {
    readonly type = IssuesActionTypes.UPSERT_ISSUES_START;
    constructor(public payload: { issues: Issue[] }) {}
}

export class UpsertIssuesFinish implements Action {
    readonly type = IssuesActionTypes.UPSERT_ISSUES_FINISH;
    constructor(
        public payload: {
            results: BulkInsertResult<IssueDocType>;
        }
    ) {}
}

export class CreateIssueCommand implements Action {
    readonly type = IssuesActionTypes.CREATE_ISSUE_COMMAND;
    constructor(
        public payload: {
            command: IssueCommand;
            // used to apply conflicts
            applyConflict?: boolean;
        }
    ) {}
}

export class CreatePlanCommand implements Action {
    readonly type = IssuesActionTypes.CREATE_PLAN_COMMAND;
    constructor(public payload: { command: PlanCommand }) {}
}

export class RemovePlanCommands implements Action {
    readonly type = IssuesActionTypes.REMOVE_PLAN_COMMANDS;
    constructor() {}
}

export class CreateIssueConflicts implements Action {
    readonly type = IssuesActionTypes.CREATE_ISSUE_CONFLICTS;
    constructor(public payload: { conflicts: Conflict<Issue>[] }) {}
}

export class LoadIssueConflicts implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUE_CONFLICTS;
}

export class SetIssueConflicts implements Action {
    readonly type = IssuesActionTypes.SET_ISSUE_CONFLICTS;
    constructor(public payload: { conflicts: Conflict<Issue>[] }) {}
}

export class StoreIssueConflicts implements Action {
    readonly type = IssuesActionTypes.STORE_ISSUE_CONFLICTS;
}

export class LoadIssuesRequest implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUES_REQUEST;
    constructor(public payload: { projectId: string; archived: boolean }) {}
}

export class LoadIssuesSuccess implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUES_SUCCESS;
    constructor(
        public payload: {
            issues: Issue[];
            fromDb: boolean;
            requestProjectId: string;
            currentProjectId: string;
        }
    ) {}
}

export class LoadIssuesError implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUES_ERROR;
    constructor(public payload: { error: any }) {}
}

export class CreateIssuesRequest implements Action {
    readonly type = IssuesActionTypes.CREATE_ISSUES_REQUEST;
    constructor(
        public payload: {
            issues: { issue: IssuePost; entityId: string }[];
            plansToUpdate: PlanToUpdate[];
        }
    ) {}
}

export class CreateIssuesSuccess implements Action {
    readonly type = IssuesActionTypes.CREATE_ISSUES_SUCCESS;
    constructor(
        public payload: {
            issues: {
                issue: Issue;
                entityId: string;
            }[];
            currentProjectId: string;
        }
    ) {}
}

export class CreateIssuesError implements Action {
    readonly type = IssuesActionTypes.CREATE_ISSUES_ERROR;
    constructor(public payload: { errors: EntityError[] }) {}
}

export class UpdateIssuesRequest implements Action {
    readonly type = IssuesActionTypes.UPDATE_ISSUES_REQUEST;
    constructor(
        public payload: {
            projectId: string;
            updates: Update<Issue>[];
            plansToUpdate: PlanToUpdate[];
        }
    ) {}
}

export class UpdateIssuesSuccess implements Action {
    readonly type = IssuesActionTypes.UPDATE_ISSUES_SUCCESS;
    constructor(
        public payload: {
            updates: Update<Issue>[];
            currentProjectId: string;
        }
    ) {}
}

export class UpdateIssuesError implements Action {
    readonly type = IssuesActionTypes.UPDATE_ISSUES_ERROR;
    constructor(public payload: { errors: EntityError[] }) {}
}

export class LoadRevisionsRequest implements Action {
    readonly type = IssuesActionTypes.LOAD_REVISIONS_REQUEST;
    constructor(public payload: { issueId: string; projectId: string }) {}
}

export class LoadRevisionsSuccess implements Action {
    readonly type = IssuesActionTypes.LOAD_REVISIONS_SUCCESS;
    constructor(public payload: { issueId: string; revisions: Revision[] }) {}
}

export class LoadRevisionsError implements Action {
    readonly type = IssuesActionTypes.LOAD_REVISIONS_ERROR;
    constructor(public payload: { issueId: string; error?: any }) {}
}

export class ToggleSelectIssue implements Action {
    readonly type = IssuesActionTypes.TOGGLE_SELECT_ISSUE;
    constructor(public payload: { issueId: string }) {}
}

export class SelectIssues implements Action {
    readonly type = IssuesActionTypes.SELECT_ISSUES;
    constructor(public payload: { ids: string[] }) {}
}

export class SetIssueListSort implements Action {
    readonly type = IssuesActionTypes.SET_ISSUE_LIST_SORT;
    constructor(public payload: { sortBy: IssueSorts; orderAsc: boolean }) {}
}

export class SetIssueFilters implements Action {
    readonly type = IssuesActionTypes.SET_ISSUE_FILTERS;
    constructor(public payload: { filters: IssueFilters }) {}
}

export class SyncIssueCommands implements Action {
    readonly type = IssuesActionTypes.SYNC_ISSUE_COMMANDS;
}

export class LoadIssueCommandsRequest implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUE_COMMANDS_REQUEST;
    constructor(
        public payload: { projectId: string; entityType: EntityType }
    ) {}
}

export class LoadIssueCommandsSuccess implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUE_COMMANDS_SUCCESS;
    constructor(
        public payload: { commands: SyncCommand<any>[]; entityType: EntityType }
    ) {}
}

export class LoadIssueCommandsError implements Action {
    readonly type = IssuesActionTypes.LOAD_ISSUE_COMMANDS_ERROR;
    constructor(public payload: { error?: any }) {}
}

export class LoadPlanRevisionsRequest implements Action {
    readonly type = IssuesActionTypes.LOAD_PLAN_REVISIONS_REQUEST;
    constructor(public payload: { issueId: string; projectId: string }) {}
}

export class LoadPlanRevisionsSuccess implements Action {
    readonly type = IssuesActionTypes.LOAD_PLAN_REVISIONS_SUCCESS;
    constructor(public payload: { issueId: string; markedPlan: MarkedPlan }) {}
}

export class LoadPlanRevisionsError implements Action {
    readonly type = IssuesActionTypes.LOAD_PLAN_REVISIONS_ERROR;
    constructor(public payload?: { error: any }) {}
}

export class SetIssueIdOfPlanCommandsInDbRequest implements Action {
    readonly type =
        IssuesActionTypes.SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_REQUEST;
    constructor(
        public payload: {
            issueId: string;
            planCommands: PlanCommand[];
        }
    ) {}
}

export class SetIssueIdOfPlanCommandsInDbSuccess implements Action {
    readonly type =
        IssuesActionTypes.SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_SUCCESS;
    constructor() {}
}

export class SetIssueIdOfPlanCommandsInDbError implements Action {
    readonly type = IssuesActionTypes.SET_ISSUE_ID_OF_PLAN_COMMANDS_IN_DB_ERROR;
    constructor(
        public payload: {
            error: any;
        }
    ) {}
}

export class AddIssueAttachmentMappings implements Action {
    readonly type = IssuesActionTypes.ADD_ISSUE_ATTACHMENT_MAPPINGS;
    constructor(
        public payload: {
            attachmentMap: EntityAttachmentMap;
        }
    ) {}
}

export class UpdateIssueAttachmentIds implements Action {
    readonly type = IssuesActionTypes.UPDATE_ISSUE_ATTACHMENT_IDS;
    constructor() {}
}

export class ClearIssueAttachmentMap implements Action {
    readonly type = IssuesActionTypes.CLEAR_ISSUE_ATTACHMENT_MAP;
    constructor() {}
}

export type IssuesActions =
    | LoadIssuesRequest
    | LoadIssuesSuccess
    | LoadIssuesError
    | CreateIssuesRequest
    | CreateIssuesSuccess
    | CreateIssuesError
    | UpdateIssuesRequest
    | UpdateIssuesSuccess
    | UpdateIssuesError
    | ToggleSelectIssue
    | SelectIssues
    | SetIssueListSort
    | SetIssueFilters
    | LoadRevisionsRequest
    | LoadRevisionsSuccess
    | LoadRevisionsError
    | CreateIssueCommand
    | SyncIssueCommands
    | LoadIssueCommandsRequest
    | LoadIssueCommandsError
    | LoadIssueCommandsSuccess
    | SetIssueIdOfPlanCommandsInDbRequest
    | SetIssueIdOfPlanCommandsInDbSuccess
    | SetIssueIdOfPlanCommandsInDbError
    | AddIssueAttachmentMappings
    | UpdateIssueAttachmentIds
    | ClearIssueAttachmentMap
    | CreateIssueConflicts
    | LoadIssueConflicts
    | SetIssueConflicts
    | StoreIssueConflicts
    | LoadPlanRevisionsRequest
    | LoadPlanRevisionsSuccess
    | LoadPlanRevisionsError
    | CreatePlanCommand
    | UpsertIssuesStart
    | UpsertIssuesFinish
    | RemovePlanCommands;
