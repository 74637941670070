import { createSelector } from '@ngrx/store';

import { Conflict } from '../../main/models/conflict';
import { getDiaryConflicts } from './../diary/diary.selectors';
import { getIssuesConflicts } from './../issues/issues.selectors';

export const getAllConflicts = createSelector(
    getIssuesConflicts,
    getDiaryConflicts,
    (issuesConflicts, diaryConflicts) => {
        const allConflicts: Conflict<unknown>[] = [];

        if (issuesConflicts) {
            allConflicts.push(...issuesConflicts);
        }

        if (diaryConflicts) {
            allConflicts.push(...diaryConflicts);
        }

        return allConflicts;
    }
);

export const getAllConflictsCount = createSelector(
    getIssuesConflicts,
    getDiaryConflicts,
    (issuesConflicts, diaryConflicts) => {
        let conflictsCount = 0;

        if (issuesConflicts) {
            conflictsCount += issuesConflicts.length;
        }

        if (diaryConflicts) {
            conflictsCount += diaryConflicts.length;
        }

        return conflictsCount;
    }
);
