import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getCurrentLocationId } from '../router/router.selectors';
import * as fromLocations from './locations.reducers';
import { LocationsState } from './locations.reducers';

export const selectLocationsState = createFeatureSelector<LocationsState>(
    'locations'
);

export const getAllLocations = createSelector(
    selectLocationsState,
    fromLocations.selectAll
);

export const getLocationsEntities = createSelector(
    selectLocationsState,
    fromLocations.selectEntities
);

export const getLocationById = createSelector(
    getLocationsEntities,
    (entities, props: { id: string }) => entities[props.id]
);

export const getLocationsIds = createSelector(
    selectLocationsState,
    fromLocations.selectIds
);

export const getCurrentLocation = createSelector(
    getLocationsEntities,
    getCurrentLocationId,
    (locationEntities, id) => locationEntities[id]
);

export const getCurrentLocations = createSelector(
    getAllLocations,
    getCurrentLocation,
    (allLocations, currentLocation) =>
        allLocations.filter(
            (location) =>
                location.parentID ===
                (currentLocation ? currentLocation.id || null : null)
        )
);

// TODO: Is it the right way to mark archived locations as deleted and then select the non-marked ones?
export const getActiveCurrentLocations = createSelector(
    getCurrentLocations,
    (locations) => locations.filter((location) => !location.markedAsDelete)
);
