import { EntityType } from 'app/main/sync/models/entity-type';

export enum AttachmentResultType {
    AttachmentAvailable,
    AttachmentError,
}

export enum AttachmentErrorType {
    DocumentMissing = 'no doc',
    AttachmentMissing = 'no attachment',
    DataMissing = 'no data',
    NetworkIssue = 'fetch from backend',
    OtherError = 'other error',
}

export interface ErrorDetails {
    errorType: AttachmentErrorType;
    entityType?: EntityType;
    entityId?: string;
    assetId?: string;
    additionalInformation?: any;
}

export class AttachmentAvailable {
    type = AttachmentResultType.AttachmentAvailable;

    /**
     * @param blobUrl The URL the blob is available at.
     * @param blobType The content type of the blob.
     */
    private constructor(public blobUrl: string, public blobType: string) {}
    public static create(args: {
        blobUrl: string;
        blobType: string;
    }): AttachmentResult {
        return new AttachmentAvailable(args.blobUrl, args.blobType);
    }
}

export class AttachmentError {
    type = AttachmentResultType.AttachmentError;

    private constructor(public errorDetails: ErrorDetails) {}
    public static create(errorDetails: ErrorDetails): AttachmentResult {
        return new AttachmentError(errorDetails);
    }

    private static createWithErrorType(
        errorType: AttachmentErrorType
    ): AttachmentError {
        return new AttachmentError({
            errorType,
        });
    }

    private static createWithErrorTypeAdditionalInformation(
        errorType: AttachmentErrorType,
        additionalInformation: any
    ): AttachmentError {
        return new AttachmentError({
            errorType,
            additionalInformation,
        });
    }

    public static documentMissing(): AttachmentResult {
        return AttachmentError.createWithErrorType(
            AttachmentErrorType.DocumentMissing
        );
    }

    public static attachmentMissing(): AttachmentResult {
        return AttachmentError.createWithErrorType(
            AttachmentErrorType.AttachmentMissing
        );
    }

    public static dataMissing(): AttachmentResult {
        return AttachmentError.createWithErrorType(
            AttachmentErrorType.DataMissing
        );
    }

    public static networkIssue(additionalInformation: any): AttachmentResult {
        return AttachmentError.createWithErrorTypeAdditionalInformation(
            AttachmentErrorType.NetworkIssue,
            additionalInformation
        );
    }

    public static otherError(additionalInformation: any): AttachmentResult {
        return AttachmentError.createWithErrorTypeAdditionalInformation(
            AttachmentErrorType.OtherError,
            additionalInformation
        );
    }
}

export type AttachmentResult = AttachmentAvailable | AttachmentError;
