const roleNumbers = {
    NORMAL: 1,
    CRAFT_RESPONSIBLE: 2,
    PROJECT_MANAGER: 4,
    ADMIN: 8,
    SUPER_ADMIN: 16,
    FLINK2GO_ADMIN: 32,
};

const roleKeyToApiRole = {
    NORMAL: 'Normal',
    CRAFT_RESPONSIBLE: 'CraftResponsible',
    PROJECT_MANAGER: 'ProjectManager',
    ADMIN: 'Admin',
    SUPER_ADMIN: 'SuperAdmin',
};

export interface IEnvCommon {
    /**
     * The main basePath for API calls. Please take care to update the backend
     * switching logic in `demo.service.ts` in case you are adding other
     * configuration strings which depend on this base path.
     */
    basePath: string;
    endpoints: {
        api: string;
        asset: string;
    };
    dbPassword: string;
    offlineDialogTimeout: number;
    imageResizeTargetWidth: number;
    defaultGuid: '00000000-0000-0000-0000-000000000000';
    defaults: {
        language: string;
        locale: string;
    };
    roleNumbers: typeof roleNumbers;
    roleKeyToApiRole: typeof roleKeyToApiRole;
    languages: {
        code: string;
        langCode: string;
        translateCode: string;
        flag: string;
    }[];
    whitelistedAdminPanelUser: 'admin@untermstrich';
    /**
     * Logging in as this user turns on the F2G self-service demo mode. This
     * is explicitly enabled only for specific environments.
     */
    demoUser?: 'demo@flink2go.com';
    /**
     * The base path to use in case of logging in as the demo user.
     */
    demoModeBasePath: string;
    /**
     * The "Get Help" CTA URL.
     */
    getHelpCTAURL: string;
    /**
     * The "Contact Sales" CTA URL.
     */
    contactSalesCTAURL: string;
    /**
     * The CTA URL which is used in the "Not-in-Demo" dialog which appears
     * if the user tries to make changes in the demo mode, see
     * `src/app/shared/components/dialogs/demo-dialog/demo-dialog.component.ts`.
     */
    restrictedFunctionalityCTAURL: string;
}

/**
 * Create base path for Api calls with version 1.
 */
export function endpointApiFor(basePath: string): string {
    return basePath + '/api/v1';
}

/**
 * Create base path for asset (files/direct) api calls with version 1.
 */
export function endpointAssetFor(basePath: string): string {
    return endpointApiFor(basePath) + '/files/direct/';
}

export const BASE_PATH_OVERRIDE_DEMO = 'basePathOverrideDemo';
/**
 * Gets the common configuration.
 * While not really a part of the configurable environment, the OpenAPI code generated
 *  has expectations here, so some parts need to be included.
 *
 * @param defaultBasePath  - The default base path to use, if not using `basePathOverrride`
 */
export function getEnvCommon(defaultBasePath: string): IEnvCommon {
    const basePath: string =
        localStorage.getItem('basePathOverride') || defaultBasePath;

    const demoModeBasePath: string =
        localStorage.getItem(BASE_PATH_OVERRIDE_DEMO) ||
        'http://dev1.f2g.ustrich-web.com';

    const envConstants: IEnvCommon = {
        basePath: basePath,
        endpoints: {
            api: endpointApiFor(basePath),
            asset: endpointAssetFor(basePath),
        },
        dbPassword: 'GUfXMikH9dcLYaim',
        offlineDialogTimeout: 10000,
        imageResizeTargetWidth: 1024,
        defaultGuid: '00000000-0000-0000-0000-000000000000',
        defaults: {
            language: 'de',
            locale: 'de-de',
        },
        roleNumbers,
        roleKeyToApiRole,
        languages: [
            {
                code: 'de',
                langCode: 'de-de',
                translateCode: 'APP.GERMAN',
                flag: 'assets/icons/flags/de-de.png',
            },
            {
                code: 'en',
                langCode: 'en-us',
                translateCode: 'APP.ENGLISH',
                flag: 'assets/icons/flags/en-us.png',
            },
        ],
        /**
         * Allow an additional user access to the Admin Panel
         * _in addition_ to the users with at least the role
         * of FLINK2GO_ADMIN.
         */
        whitelistedAdminPanelUser: 'admin@untermstrich',
        demoUser: 'demo@flink2go.com',
        demoModeBasePath,

        getHelpCTAURL:
            'https://flink2go.com/kontakt-hilfe/?utm_source=flink2go-app&utm_medium=inappbutton&utm_campaign=DemoApp&utm_content=helpContactForm',
        contactSalesCTAURL:
            'https://flink2go.com/termin-buchen/?utm_source=flink2go-app&utm_medium=inappbutton&utm_campaign=DemoApp&utm_content=bookAppointment',
        restrictedFunctionalityCTAURL:
            'https://flink2go.com/personalisierte-demo-buchen/?utm_source=flink2go-app&utm_medium=inappbutton&utm_campaign=DemoApp&utm_content=bookPersonalDemo',
    };
    return envConstants;
}
