import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { CommandAttachmentIdsService } from './command-attachment-ids.service';
import { EntityAttachmentIdsService } from './entity-attachment-ids.service';
import { FileStorageService } from './file-storage.service';

@Injectable({ providedIn: 'root' })
export class AttachmentGarbageCollectorService {
    constructor(
        private commandAttachmentIdsService: CommandAttachmentIdsService,
        private entityAttachmentIdsService: EntityAttachmentIdsService,
        private fileStorePrividerService: FileStorageService
    ) {}

    public async garbageCollectAttachments(): Promise<void> {
        const usedAttachmentIds = await this.obtainUsedAttachmentIds();
        const storedAttachmentIds = await this.fileStorePrividerService.getStoredAttachmentIds();

        for (const usedAttachmentId of usedAttachmentIds) {
            if (!storedAttachmentIds.has(usedAttachmentId)) {
                console.warn('used', usedAttachmentId, 'is not stored');
                continue;
            }

            storedAttachmentIds.delete(usedAttachmentId);
        }

        const removes: Promise<void>[] = [];

        for (const storedAttachmentId of storedAttachmentIds) {
            removes.push(
                this.fileStorePrividerService.removeAttachment(
                    storedAttachmentId
                )
            );
        }

        await Promise.all(removes);
    }

    private async obtainUsedAttachmentIds(): Promise<Set<string>> {
        const entityAttachmentIds = await this.entityAttachmentIdsService.obtainAttachmentIds();
        const commandAttachmentIds = await this.commandAttachmentIdsService.obtainAttachmentIds();

        const usedAttachmentIds = new Set<string>();

        entityAttachmentIds.forEach((id) => usedAttachmentIds.add(id));
        commandAttachmentIds.forEach((id) => usedAttachmentIds.add(id));

        // this one is used for specifying unset attributes where
        // null is not valid
        usedAttachmentIds.delete(environment.defaultGuid);

        return usedAttachmentIds;
    }
}
