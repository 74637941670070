import { FuseNavigation } from '@fuse/types';

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'projects',
        title: 'Projects',
        translate: 'NAV.PROJECTS.TITLE',
        type: 'item',
        icon: 'arrow_back',
        url: '/projects'
    },
    {
        id: 'address-book',
        title: 'Address Book',
        translate: 'NAV.GLOBAL_SETTINGS.ADDRESS_BOOK.TITLE',
        type: 'item',
        icon: 'mail_outline',
        url: '/admin/addressbook'
    },
    {
        id: 'header-image-settings',
        title: 'Unternehmenslogo',
        translate: 'NAV.GLOBAL_SETTINGS.COMPANY_LOGO.TITLE',
        type: 'item',
        icon: 'image',
        url: '/admin/header-image-settings',
    }
    // currently disabled
    // {
    //     id: 'issue-categories',
    //     title: 'Issue Categories',
    //     translate: 'NAV.GLOBAL_SETTINGS.ISSUE_CATEGORIES.TITLE',
    //     type: 'item',
    //     icon: 'question_answer',
    //     url: '/admin/ticket-categories'
    // }
];
