import { Injectable } from '@angular/core';

import { Transform } from './transform.model';
import { PinchPanState } from './pinch-pan-state.model';

/**
 * Provides utilities to compute transformations based on
 * the current pinch and pan changes. Use ZoomService
 * to obtain these changes.
 *
 * It also provides utilities to update an existing transformation
 * with a new one.
 */
@Injectable()
export class PinchPanTransformService {
    constructor() {}

    /**
     * Create a transformation composed of scale and translation to
     * apply the the given pinch and pan change. This ensures, that
     * the translation also includes a component to properly center
     * the viewport around the pinch-to-zoom center.
     *
     * @param pinch The pinch/pan change.
     */
    createTransformFor(pinch: PinchPanState): Transform {
        // the center point between the two fingers in viewport (plan view)
        // coordinates as a vector towards the origin
        const xVpCenter = -pinch.center.x;
        const yVpCenter = -pinch.center.y;

        // vector from center point between to fingers
        // to the top left corner of the viewport with
        // applied scaling
        const xNewOffset = pinch.scale * xVpCenter;
        const yNewOffset = pinch.scale * yVpCenter;

        // calculate the translation needed to move the image in the viewport
        // due to scale to keep our center point between the two fingers
        // at the same place
        const xNewVpOffset = xNewOffset - xVpCenter;
        const yNewVpOffset = yNewOffset - yVpCenter;

        const transform = {
            scale: pinch.scale,
            // note that the pan translation is added in a direction reverse to
            // to the translation caused by the scale
            translationX: xNewVpOffset + pinch.pan.x,
            translationY: yNewVpOffset + pinch.pan.y,
        };

        return transform;
    }

    /**
     * Create CSS transform directives from a transformation.
     *
     * @see createTransformFor for details.
     *
     * @param transform The transform from `createTransformFor`.
     */
    createCSSTransformationsFor(
        transform: Transform
    ): {
        scale: string;
        translation: string;
    } {
        const scale = `transform: scale3d(${transform.scale}, ${transform.scale}, 1);`;

        // move scale center so that it's still aligned with the pinch center
        const translation = `transform: translate3d(${transform.translationX}px, ${transform.translationY}px, 0px);`;

        return {
            scale,
            translation,
        };
    }

}
