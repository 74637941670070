/**
 * eTeacher API
 * Last Build: 10/11/2021 12:08:17 Swagger documentation for the AcceptB2B API. <br><a href=\"../html/signalr2.html\">SignalR test page</a><br><a href=\"../hangfire\">Hangfire Dashboard</a><br><a href=\"../hc-ui\">HealthChecks Dashboard (not dev)</a><br><a href=\"../hc\">HealthChecks (not dev)</a><br><a href=\"../profiler/results-index\">MiniProfiler (dev)</a><br><a href=\"../rin/\">Rin (dev)</a><br><a href=\"../html/registercustomer.html\">Register Customer</a><br><a href=\"../html/testemail.html\">Test eMail</a>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { Contact } from './contact';


export interface WeatherEntryAllOf { 
    weather: WeatherEntryAllOf.WeatherEnum;
    rainfall?: number;
    windType: WeatherEntryAllOf.WindTypeEnum;
    wind?: number;
    temperature: number;
    comment?: string;
    id?: string;
    ownerId?: string;
    owner?: Contact;
    updaterId?: string;
    updater?: Contact;
    creatorId?: string;
    creator?: Contact;
    creationDate?: Date;
    markedAsDelete?: boolean;
    deleteDateTime?: Date;
    updateDateTime?: Date;
    projectId?: string;
    project?: Project;
    type?: WeatherEntryAllOf.TypeEnum;
    logEntryDateTime: Date;
}
export namespace WeatherEntryAllOf {
    export type WeatherEnum = 'Clear' | 'PartlyCloudy' | 'Cloudy' | 'LightRain' | 'Rain' | 'HeavyRain' | 'Thunderstorms' | 'Sleet' | 'Snow' | 'HeavySnow' | 'Fog' | 'Blizzard';
    export const WeatherEnum = {
        Clear: 'Clear' as WeatherEnum,
        PartlyCloudy: 'PartlyCloudy' as WeatherEnum,
        Cloudy: 'Cloudy' as WeatherEnum,
        LightRain: 'LightRain' as WeatherEnum,
        Rain: 'Rain' as WeatherEnum,
        HeavyRain: 'HeavyRain' as WeatherEnum,
        Thunderstorms: 'Thunderstorms' as WeatherEnum,
        Sleet: 'Sleet' as WeatherEnum,
        Snow: 'Snow' as WeatherEnum,
        HeavySnow: 'HeavySnow' as WeatherEnum,
        Fog: 'Fog' as WeatherEnum,
        Blizzard: 'Blizzard' as WeatherEnum
    };
    export type WindTypeEnum = 'Calm' | 'LightBreeze' | 'ModerateGale' | 'Hurricane';
    export const WindTypeEnum = {
        Calm: 'Calm' as WindTypeEnum,
        LightBreeze: 'LightBreeze' as WindTypeEnum,
        ModerateGale: 'ModerateGale' as WindTypeEnum,
        Hurricane: 'Hurricane' as WindTypeEnum
    };
    export type TypeEnum = 'ProgressReportEntry' | 'AttendanceEntry' | 'InspectionEntry' | 'NoteEntry' | 'WeatherEntry' | 'ApprovalEntry';
    export const TypeEnum = {
        ProgressReportEntry: 'ProgressReportEntry' as TypeEnum,
        AttendanceEntry: 'AttendanceEntry' as TypeEnum,
        InspectionEntry: 'InspectionEntry' as TypeEnum,
        NoteEntry: 'NoteEntry' as TypeEnum,
        WeatherEntry: 'WeatherEntry' as TypeEnum,
        ApprovalEntry: 'ApprovalEntry' as TypeEnum
    };
}


