import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    MatSnackBarRef,
    MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Conflict } from 'app/main/models/conflict';

import { getCurrentProjectId } from 'app/store/router/router.selectors';
import { getAllConflictsCount } from 'app/store/conflict/conflict.selectors';

@Component({
    selector: 'acc-conflict-snackbar',
    templateUrl: './conflict-snackbar.component.html',
    styleUrls: ['./conflict-snackbar.component.scss'],
})
export class ConflictSnackbarComponent implements OnInit, OnDestroy {
    projectId: string;
    unsubscribe$ = new Subject<void>();

    conflictCount$ = this.store.pipe(
        select(getAllConflictsCount),
        tap((allConflictsCount) => {
            if (allConflictsCount === 0) {
                // hide snackbar if there are no conflicts anymore
                this.snackBarRef.dismiss();
            }
        })
    );

    constructor(
        private snackBarRef: MatSnackBarRef<ConflictSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: Conflict<unknown>[],
        private router: Router,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(getCurrentProjectId),
                takeUntil(this.unsubscribe$),
                tap((projectId) => (this.projectId = projectId))
            )
            .subscribe();
    }

    showConflicts(): void {
        if (!this.projectId) {
            this.router.navigate(['/sync']);
        } else {
            this.router.navigate(['/projects', this.projectId, 'sync']);
        }
        this.snackBarRef.dismiss();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
