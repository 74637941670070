import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoilerPlate } from 'app/core/rest-api';
import { AcceptUtils } from 'app/core/utils/accept-utils';
import * as fromSettings from '../settings/settings.selectors';
import * as fromBoilerplates from './boilerplates.reducers';
import { BoilerplatesState } from './boilerplates.reducers';
import { getGlobalBoilerplatesByCraftId } from '../global-bolierplates/global-boilerplates.selectors';

export const selectBoilerplatesState = createFeatureSelector<BoilerplatesState>(
    'boilerplates'
);

export const getAllBoilerplates = createSelector(
    selectBoilerplatesState,
    fromBoilerplates.selectAll
);

export const getBoilerplatesEntities = createSelector(
    selectBoilerplatesState,
    fromBoilerplates.selectEntities
);

export const getBoilerplatesLoaded = createSelector(
    selectBoilerplatesState,
    (state) => state.loaded
);

export const getLocationById = createSelector(
    getBoilerplatesEntities,
    (entities: Dictionary<BoilerPlate>, props: { id: string }) =>
        entities[props.id]
);

export const getBoilerplatesIds = createSelector(
    selectBoilerplatesState,
    fromBoilerplates.selectIds
);

export const getProjectBoilerplatesByProjectId = createSelector(
    getBoilerplatesEntities,
    (entities: Dictionary<BoilerPlate>, props: { projectId: string }) => {
        Object.keys(entities).map((entityId) => entities[entityId].projectId);
    }
);

export const getBoilerplateByCraftId = createSelector(
    getAllBoilerplates,
    (boilerplates: BoilerPlate[], props) => {
        if ((!props.id && props.id !== 0) || !boilerplates) {
            return undefined;
        }

        return boilerplates.find(
            (boilerPlate) => boilerPlate.title === props.id.toString()
        );
    }
);

// actually gets by the translation id, not the craft boilerplate id
export const getBoilerplatesByCraftId = (craftId) =>
    createSelector(getAllBoilerplates, (bps: BoilerPlate[]): BoilerPlate[] => {
        // Craft ID for Boilerplates
        // If no Craft ID given, return []
        if (!craftId && craftId !== 0) {
            return [];
        }
        const parentBp = bps.find((b) => b.title === craftId.toString());
        if (!parentBp) {
            return [];
        }

        return bps
            .filter((b) => !AcceptUtils.isDefaultGuid(b.parentID) && b.parentID) // remove other "root" nodes
            .map((b) => {
                // need to delete parentIDs as this will be the new root level
                return {
                    ...b,
                    parentID: b.parentID === parentBp.id ? '' : b.parentID,
                };
            });
    });

export const getGlobalAndLocalBoilerplatesByCraftId = (craftId) =>
    createSelector(
        getBoilerplatesByCraftId(craftId),
        getGlobalBoilerplatesByCraftId(craftId),
        (localBps: BoilerPlate[], globalBps: BoilerPlate[]) => {
            return [...localBps];
        }
    );

export const getBoilerplatesByParentId = createSelector(
    getAllBoilerplates,
    (boilerplates, props) => {
        if (!props.id && props.id !== 0) {
            return [];
        }

        return boilerplates.filter((boilerplate) => {
            if (!boilerplate.parentID) {
                return false;
            }
            return boilerplate.parentID === props.id;
        });
    }
);

export const getProjectSelectedBoilerplates = createSelector(
    selectBoilerplatesState,
    (state) => {
        return state.selectedBoilerplatesId;
    }
);

export const getLoadingStatus = createSelector(
    selectBoilerplatesState,
    (state) => {
        return state.loading;
    }
);

export const getCraftIdsForBoilerplates = createSelector(
    getAllBoilerplates,
    fromSettings.getProjectCraftIds,
    (boilerplates, craftIds) =>
        craftIds.filter((id) =>
            boilerplates.find(
                (item) => item.title === id + '' && !!item.projectId
            )
        )
);

export const getBoilerplateById = createSelector(
    getAllBoilerplates,
    (boilerplates, props) => {
        if (!props || (!props.id && props.id !== 0)) {
            return undefined;
        }

        return boilerplates.find((item) => item.id === props.id);
    }
);
