<!-- CONTENT -->
<ng-container *ngIf="!isPlan && !isFile">
    <div
        *ngIf="!thumb && !available"
        class="asset-not-available"
        fxLayout="column"
        fxLayoutAlign="space-between center"
    >
        <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="asset-not-available-empty"
        >
            <span class="secondary-text h3" translate
                >SYNC.ATTACHMENT_NOT_AVAILABLE_OFFLINE</span
            >
        </div>
        <div class="asset-not-available-description">&nbsp;</div>
    </div>

    <div
        class="asset-container"
        fxLayout="column"
        *ngIf="thumb"
        [style.background-image]="'url(' + thumb + ')' | safe: 'style'"
    >
        <!-- spacer -->
        <div fxFlex></div>

        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="description"
        >
            <button
                *ngIf="!hideMenuButton"
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="assetMenu"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
            <ng-content></ng-content>
            <span *ngIf="label"> {{ label }} </span>
        </div>
    </div>
    <div
        *ngIf="!thumb && available"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxFlex
    >
        <mat-progress-spinner
            color="accent"
            mode="indeterminate"
            diameter="40"
        ></mat-progress-spinner>
    </div>
</ng-container>

<!-- FILE -->
<div
    (click)="download()"
    class="asset-container"
    fxLayout="column"
    *ngIf="isFile"
>
    <ng-container *ngIf="available && fileInfos; else fileNotAvailableOffline">
        <div
            class="secondary-text"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxFlex
        >
            <mat-icon class="s-60">{{
                fileInfos.contentType | typeicon
            }}</mat-icon>
            <span>{{ fileInfos.fileName }}</span>
            <span>{{ fileInfos.fileSize | filesize }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="description">
            <button
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="assetMenu"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
            <div class="file-name" fxFlex>
                <span> {{ fileInfos.fileName }} </span>
            </div>
        </div>
    </ng-container>

    <ng-template #fileNotAvailableOffline>
        <div
            class="asset-not-available"
            fxLayout="column"
            fxLayoutAlign="space-between center"
        >
            <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="asset-not-available-empty"
            >
                <span class="secondary-text h3" translate
                    >SYNC.ATTACHMENT_NOT_AVAILABLE_OFFLINE</span
                >
            </div>
            <div class="asset-not-available-description">&nbsp;</div>
        </div>
    </ng-template>
</div>

<!-- PLAN -->
<ng-container *ngIf="isPlan">
    <div
        class="asset-container"
        fxLayout="column"
        [style.background-image]="'url(' + thumb + ')' | safe: 'style'"
        [style.background-size]="'contain'"
        [style.background-position]="'center'"
    >
        <!-- spacer -->
        <div fxFlex></div>

        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="description"
        >
            <button
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="assetMenu"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
            <span class="pr-12"> {{ label }} </span>
        </div>
    </div>
</ng-container>

<mat-menu #assetMenu="matMenu">
    <button *ngIf="downloadAllowed()" mat-menu-item (click)="download()">
        <mat-icon>save_alt</mat-icon>
        <span translate>APP.DOWNLOAD</span>
    </button>
    <button dataTest="archive-project-icon" mat-menu-item (click)="delete()">
        <mat-icon>delete</mat-icon>
        <span translate>APP.DELETE</span>
    </button>
</mat-menu>
