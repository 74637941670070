import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericInputFieldComponent } from './numeric-input-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [NumericInputFieldComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        TranslateModule
    ],
    exports: [NumericInputFieldComponent]
})
export class NumericInputFieldModule {}
