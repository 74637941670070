import { createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { Contact } from '../rest-api/model/contact';

export type EntityArray = { id: string }[];

export class ValueNotAnEntityArray extends Error {
    constructor(
        public argument: 'previous' | 'current',
        public cause: 'is not an array' | 'has no id in entry'
    ) {
        super(`the ${argument} value ${cause}`);
    }
}

export function entityArrayOrderEqual<T extends EntityArray>(
    previousArray: T,
    currentArray: T
): boolean {
    const bothMissing = !previousArray && !currentArray;
    if (bothMissing) {
        return true;
    }

    const oneMissing = !previousArray || !currentArray;
    if (oneMissing) {
        return false;
    }

    // note: explicit type checking, because this method is potentially called
    //       with values of type any
    verifyTypes(previousArray, currentArray);

    return compareArrays(previousArray, currentArray);
}

function verifyTypes<T extends EntityArray>(
    previousArray: T,
    currentArray: T
): void {
    const previousNotAnArray = !Array.isArray(previousArray);
    if (previousNotAnArray) {
        throw new ValueNotAnEntityArray('previous', 'is not an array');
    }

    const currentNotAnArray = !Array.isArray(currentArray);
    if (currentNotAnArray) {
        throw new ValueNotAnEntityArray('current', 'is not an array');
    }

    for (const previous of previousArray) {
        if (!previous.id) {
            throw new ValueNotAnEntityArray('previous', 'has no id in entry');
        }
    }

    for (const current of currentArray) {
        if (!current.id) {
            throw new ValueNotAnEntityArray('current', 'has no id in entry');
        }
    }
}

function compareArrays<T extends EntityArray>(
    previousArray: T,
    currentArray: T
): boolean {
    const lengthHasChanged = previousArray.length !== currentArray.length;
    if (lengthHasChanged) {
        return false;
    }

    for (let i = 0; i < previousArray.length; i++) {
        const differentId = previousArray[i].id !== currentArray[i].id;
        if (differentId) {
            return false;
        }
    }

    return true;
}

export const createResponsiblesIdEqualityResultSelector = createSelectorFactory<
    any,
    Contact[]
>((projectionFn) => {
    // only the result comparator is customized, thus use
    // the default memoization
    return defaultMemoize(
        projectionFn,
        // use default identity argument comparison
        (a, b) => {
            return a === b;
        },
        entityArrayOrderEqual
    );
});
