import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import {
    shareReplay,
    map,
    filter,
    switchMap,
    startWith,
    catchError,
} from 'rxjs/operators';

import { Issue } from 'app/core/rest-api/model/issue';
import { Project } from 'app/core/rest-api/model/project';
import { IssuesService } from 'app/core/rest-api/api/issues.service';

import {
    ProjectWithStatistics,
    ProjectStatistics,
} from './project-with-statistics.model';
import { IssuesUtils } from 'app/main/issues/issues-utils.service';

@Injectable({ providedIn: 'root' })
export class ProjectStatisticsService {
    constructor(private issueService: IssuesService) {}

    public projectsWithoutStatisticsFor(
        projects$: Observable<Project[]>
    ): Observable<ProjectWithStatistics[]> {
        return projects$.pipe(
            map((projects) => {
                return projects.map(
                    (project): ProjectWithStatistics => {
                        return {
                            project,
                            loading: false,
                        };
                    }
                );
            })
        );
    }

    public projectsWithStatisticsFor(
        projects$: Observable<Project[]>
    ): Observable<ProjectWithStatistics[]> {
        return projects$.pipe(
            filter((projects) => {
                const projectsAvailable = !!projects;
                return projectsAvailable;
            }),
            // if the project list changes, we want to update the issues used and
            // cancel all currently running requests as well, thus switchMap
            switchMap((projects) => {
                // TODO: this is highly inefficient!
                const requests = projects.map((project) => {
                    return this.createStatisticsForProject(project);
                });

                return combineLatest(requests);
            }),
            // cache last statistics until no one is subscribed anymore
            // to prevent redownloading and recalculating the statistics
            // on subsequent subscriptions
            shareReplay({
                bufferSize: 1,
                refCount: true,
            })
        );
    }

    private createStatisticsForProject(
        project: Project
    ): Observable<ProjectWithStatistics> {
        const projectId = project.id;

        if (!projectId) {
            const message = 'project without id, no statistics available';
            console.error(message);
            return of({
                project,
                loading: false,
                error: new Error(message),
            });
        }

        console.warn(
            'loading issues for project (for statistics purposes)',
            projectId
        );
        return this.issueService
            .projectsByProjectIdIssuesGet(projectId, false)
            .pipe(
                map((response) => {
                    return response.data;
                }),
                map(
                    (projectIssues): ProjectWithStatistics => {
                        const statistics = this.createStatistics(projectIssues);

                        return {
                            project,
                            statistics,
                            loading: false,
                        };
                    }
                ),
                // provide at least basic project data before the request was completed
                startWith({
                    project,
                    loading: true,
                }),
                catchError(
                    (error): Observable<ProjectWithStatistics> => {
                        console.error(
                            'failed to get statistics for',
                            projectId,
                            error
                        );
                        // the current request failed => loading is complete, basic
                        // project information is available, but statistics are
                        // missing
                        return of({
                            project,
                            loading: false,
                            error,
                        });
                    }
                )
            );
    }

    private createStatistics(projectIssues: Issue[]): ProjectStatistics {
        const issueStatistics = IssuesUtils.generateIssueStatistics(
            projectIssues
        );

        return {
            totalIssues: issueStatistics.total,
            openIssues: issueStatistics.open,
            acceptedIssues: issueStatistics.accepted,
            extendedDeadlineIssues: issueStatistics.extendedDeadline,
            doneIssues: issueStatistics.done,
        };
    }
}
