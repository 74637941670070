import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from 'app/shared/shared.module';
import { ConflictEffects } from './conflict.effects';

@NgModule({
    imports: [SharedModule, EffectsModule.forFeature([ConflictEffects])],
    exports: [EffectsModule],
    declarations: [],
    providers: [],
})
export class ConflictEffectsModule {}
